import React from 'react';
import { PickerTimeProps } from 'antd/lib/date-picker/generatePicker';
import { DayjsDatePicker } from './DayjsDatePicker';
import { AppDate } from 'constants/Dates';

type DayjsDatePickerRef = React.Component<PickerTimeProps<AppDate>>;
type TimePickerProps = Omit<PickerTimeProps<AppDate>, 'picker'> & { picker?: 'time' };

export const TimePicker = React.forwardRef<DayjsDatePickerRef, TimePickerProps>(
  ({ className, format = 'HH:ss', ...props }, ref) => (
    <DayjsDatePicker picker="time" ref={ref} className={className} format={format} {...props} />
  ),
);
