import React from 'react';
import * as S from './GoogleMapItems.styles';
import { ReactComponent as DeleteIcon } from '@app/assets/icons/delete.svg';
import { ReactComponent as RedoIcon } from '@app/assets/icons/redo.svg';
import { ReactComponent as UndoIcon } from '@app/assets/icons/undo.svg';

type UndoRedoDeleteButtonsProps = {
  undo: () => void;
  redo: () => void;
  delete: () => void;
};

export const UndoRedoDeleteButtons: React.FC<UndoRedoDeleteButtonsProps> = ({ undo, redo, delete: del }) => {
  return (
    <S.ButtonsWrapper>
      <S.SvgWrapper onClick={() => undo()}>
        <UndoIcon />
      </S.SvgWrapper>
      <S.ButtonDivisor />
      <S.SvgWrapper>
        <RedoIcon onClick={() => redo()} />
      </S.SvgWrapper>
      <S.ButtonDivisor />
      <S.SvgWrapper onClick={() => del()}>
        <DeleteIcon />
      </S.SvgWrapper>
    </S.ButtonsWrapper>
  );
};
