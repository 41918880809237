import { ApiServiceBase } from '@app/services/base/apiServiceBase';
import { FirmwareVersionModel } from '@app/domain/firmwareVersion/firmwareVersionModel';
import { VersionStatusModel } from '@app/domain/firmwareVersion/versionStatusModel';

export class FirmwareVersionService extends ApiServiceBase<FirmwareVersionModel, FirmwareVersionModel> {
  constructor() {
    super('VersaoFirmware');
  }
  async getStatusOptions(): Promise<VersionStatusModel[]> {
    try {
      return (await super.get('Status')) as unknown as Promise<VersionStatusModel[]>;
    } catch (error) {
      throw error;
    }
  }
}
