import React, { FC, useState } from 'react';
import { FenceModel, PathPoint } from '@app/domain/fence/fenceModel';
import { hasAccessByRoles } from '@app/controllers/accessController';
import { UserType } from '@app/constants/enums/userType';
import { Table } from '@app/components/common/Table/Table';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { IconMore } from '@app/assets/slump-icons';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { Button } from '@app/components/common/buttons/Button/Button';
import { notificationController } from '@app/controllers/notificationController';
import { ColumnsType } from 'antd/lib/table';
import { Tag } from '@app/components/common/Tag/Tag';
import { useNavigate } from 'react-router-dom';
import { FenceService } from '@app/services/fenceService';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Modal } from '@app/components/common/Modal/Modal';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const fenceService = new FenceService();

interface FencesDashboardProps {
  fenceGroupId: number;
  fences: FenceModel[];
  setFences: React.Dispatch<React.SetStateAction<FenceModel[]>>;
}

const FencesDashboardView: FC<FencesDashboardProps> = ({ fenceGroupId, fences, setFences }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalDisableVisible, setModalDisableVisible] = useState(false);
  const [selectedFence, setSelectedFence] = useState<FenceModel | null>(null);
  const handleActivateDeactivateRowClick = (id: number) => {
    setLoading(true);
    fenceService
      .delete(`/${id}/ativar-desativar`)
      .then(() => {
        setFences((prevState) => {
          const fence = prevState.find((fence) => fence.id === id);
          if (fence) {
            fence.ativo = !fence.ativo;
          }
          notificationController.success({
            message: fence?.ativo ? 'Cerca ativada com sucesso!' : 'Cerca desativada com sucesso!',
          });
          return [...prevState];
        });
        setModalDisableVisible(false);
        setSelectedFence(null);
      })
      .catch((err) => {
        notificationController.error({ message: `Erro ao desativar cerca. ${err}` });
      })
      .finally(() => setLoading(false));
  };

  const handleDisableClick = (fence: FenceModel) => {
    setSelectedFence(fence);
    setModalDisableVisible(true);
  };
  // locally disable (need to refactor this to save on footer button click)
  // const handleActivateDeactivateRowClick = useCallback(
  //   (id: number) => {
  //     setFences((prevState) => {
  //       const fence = prevState.find((fence) => fence.id === id);
  //       if (fence) {
  //         fence.ativo = !fence.ativo;
  //       }
  //       return [...prevState];
  //     });
  //   },
  //   [setFences],
  // );

  const handleVisualizationRowClick = (id: number) => {
    navigate(`/cercas/${fenceGroupId}/cerca/${id}`);
  };

  const renderButtonActions = (_fence: FenceModel) => {
    if (_fence.id)
      return (
        <>
          {hasAccessByRoles([UserType.FactoryAdmin]) && (
            <MenuItem key="ativar-desativar" onClick={() => handleDisableClick(_fence)}>
              <Button type="text">{_fence.ativo ? 'Desativar' : 'Ativar'}</Button>
            </MenuItem>
          )}
          <MenuItem key="view" onClick={() => handleVisualizationRowClick(_fence.id || 0)}>
            <Button type="text">Visualizar</Button>
          </MenuItem>
        </>
      );
  };

  const columns: ColumnsType<FenceModel> = [
    {
      title: 'Cod.',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => <>{id || ' - '}</>,
    },
    {
      title: 'Cerca',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Tipo da cerca',
      dataIndex: 'tipoCerca',
      key: 'idTipoCerca',
    },
    {
      title: 'Quantidade de pontos',
      dataIndex: 'pontos',
      key: 'pontos',
      render: (pontos: PathPoint[]) => <>{pontos?.length > 0 ? pontos.length : ' - '}</>,
    },
    {
      title: 'Raio',
      dataIndex: 'raio',
      key: 'raio',
      render: (raio: FenceModel['raio']) => <>{raio ? `${raio}m` : ' - '}</>,
    },
    {
      title: 'Largura da banda',
      dataIndex: 'largura',
      key: 'largura',
      render: (largura: FenceModel['largura']) => <>{largura ? `${largura}m` : ' - '}</>,
    },
    {
      title: 'Evento Entrada/Saída',
      dataIndex: 'eventos',
      key: 'eventoEntradaSaida',
      render: (eventos: FenceModel['eventos']) => <>{!!eventos?.entradaSaida ? 'Sim' : 'Não'}</>,
    },
    {
      title: 'Evento Limite de velocidade',
      dataIndex: 'eventos',
      key: 'eventoLimiteVelocidade',
      render: (eventos: FenceModel['eventos']) => <>{!!eventos?.limiteVelocidade ? 'Sim' : 'Não'}</>,
    },
    {
      title: 'Ativo',
      dataIndex: 'ativo',
      key: 'ativo',
      render: (ativo: boolean) => {
        return (
          <>
            {ativo ? (
              <Tag color="#E9F4EE" style={{ color: '#083F18', width: '70px', textAlign: 'center' }}>
                Ativo
              </Tag>
            ) : (
              <Tag color="#FEE9EA" style={{ color: '#620E12', width: '70px', textAlign: 'center' }}>
                Inativo
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      showSorterTooltip: false,
      sortDirections: [],
      defaultSortOrder: 'descend',
      render: (_, fence) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <DropdownTable
              buttonText=""
              iconD={
                <div style={{ color: '#545454' }}>
                  <IconMore />
                </div>
              }
              trigger={['click']}
              placement="bottomRight"
              overlay={<Menu>{renderButtonActions(fence)}</Menu>}
            ></DropdownTable>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Spinner spinning={loading}>
        <Modal
          title={selectedFence?.ativo ? 'Desativar cerca' : 'Ativar cerca'}
          open={modalDisableVisible}
          onOk={() => handleActivateDeactivateRowClick(selectedFence?.id || 0)}
          onCancel={() => setModalDisableVisible(false)}
          cancelText="Cancelar"
          okText="Confirmar"
        >
          {selectedFence?.ativo ? (
            <>
              <CloseCircleOutlined style={{ color: 'var(--error-color)', marginRight: '1rem' }} />
              Deseja realmente desativar a cerca <strong>{selectedFence?.nome}</strong> selecionada?
            </>
          ) : (
            <>
              <CheckCircleOutlined style={{ color: 'var(--success-color)', marginRight: '1rem' }} />
              Deseja realmente ativar a cerca <strong>{selectedFence?.nome}</strong> selecionada?
            </>
          )}
        </Modal>
        <Table
          bordered={true}
          columns={columns}
          dataSource={fences}
          loading={false}
          scroll={{ x: 'max-content' }}
          style={{
            width: '100%',
            paddingLeft: 0,
            paddingRight: 0,
          }}
        />
      </Spinner>
    </>
  );
};

export default FencesDashboardView;
