import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Select } from '@app/components/common/selects/Select/Select';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { ClientModel } from '@app/domain/client/clientModel';
import { notificationController } from '@app/controllers/notificationController';
import * as S from '../Cards.styles';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';
import IClientService, { ClientService } from '@app/services/clientService';

interface ICardSelectClient {
  onSelectClient: (id: number) => void;
  selectedClientId: number | undefined;
}

const clientService: IClientService = new ClientService();

export const CardSelectClient: React.FC<ICardSelectClient> = ({ onSelectClient, selectedClientId }) => {
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const fetch = useCallback(() => {
    clientService
      .getArray('')
      .then((res: ClientModel[]) => {
        setClients(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });
  }, []);

  const handleClientChange = (value: unknown) => {
    const client = clients.find((client) => client.id === value);
    if (client) {
      onSelectClient(client.id);
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <S.Card>
        <HelperNotification>
          Os dispositivos desse bloqueio serão atualizados baseados nos parâmetros selecionados.
        </HelperNotification>
        <S.Title>Parâmetros para o bloqueio</S.Title>
        <Row>
          <Col xs={24} md={24}>
            <BaseFormInputItem label="Cliente">
              <Select
                value={selectedClientId}
                showArrow
                showSearch
                placeholder="Selecione o cliente"
                onChange={handleClientChange}
                options={clients.map((d) => ({ value: d.id, label: d.razaoSocial }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
                disabled={isDisabled}
              />
            </BaseFormInputItem>
          </Col>
        </Row>
      </S.Card>
    </>
  );
};
