import { Row } from 'antd';
import styled from 'styled-components';

export const AuthWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 4rem 10rem 12rem 10rem;

  @media only screen and (max-width: 921px) {
    padding: 2rem;
  }
`;

export const AuthCard = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-top: 2rem;
  border-radius: 2.5rem;
  padding: 4rem;
`;

export const AuthCarouselContainer = styled.div`
  height: 100%;
  padding: 2.5rem 0rem 2rem 0rem;
`;

export const AuthTitle = styled.div`
  font-family: Mulish;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3.138rem;
  text-align: left;
  color: var(--primary-color);
`;
export const AuthSubtitle = styled.div`
  font-family: Mulish;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.569rem;
  text-align: left;
  color: var(--text-label);
`;
export const AuthRow = styled(Row)`
  /* flex-direction: row-reverse; */
  height: 100%;
`;
export const AuthDivider = styled.div`
  width: 1px;
  background-color: #d9e1f3;
  height: 100%;
  margin: 0 10px;
`;
