import React, { useEffect, useState } from 'react';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { DeviceProfilePropertyModel, PropertyValue } from '@app/domain/deviceProfile/deviceProfileModel';
import * as S from '../ModulesProfileConfig.styles';
import * as ST from './PerfilEnvio.styles';
import ShouldRenderInput from '@app/components/common/ModulesProfileConfig/ShouldRenderInput';
import { Col, Form, Row, Space } from 'antd';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import TooltipInfoIcon from '@app/components/common/TooltipInfoIcon/TooltipInfoIcon';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';
import { googlecode } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
  codeStringExample,
  valoresDefaultPorId,
} from '@app/components/common/ModulesProfileConfig/PerfilEnvio/PerfilEnvioUtils';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Panel } from '@app/components/common/Collapse/Collapse';
import { Button } from '@app/components/common/buttons/Button/Button';
import ModalParametersPerfilEnvio, { ocasionalParameterEmbarcado } from './Modal/ModalParametersPerfilEnvio';
import { CardInfoTitle } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { encode } from '@app/components/common/ModulesProfileConfig/PerfilEnvio/encodeDecode';
import { SendParameter } from '@app/store/slices/sendParametersSlice';

interface PerfilEnvioProps {
  properties: PropertyValue[];
  setProperties: React.Dispatch<React.SetStateAction<PropertyValue[]>>;
  moduleData: DeviceProfilePropertyModel[];
  disableAllFields?: boolean;
  setPropertyValues?: (data: PropertyValue[]) => void;
  parameters: SendParameter[];
}

const PerfilEnvio: React.FC<PerfilEnvioProps> = ({
  properties,
  setProperties,
  moduleData,
  setPropertyValues,
  disableAllFields = false,
  parameters,
}) => {
  const [parameterJustSeted, setParameterJustSeted] = useState<boolean>(false);
  // const { parameters } = useAppSelector((state) => state.sendParameters);
  const [idMapping, setIdMapping] = useState<{ [key: number]: number }>({});
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedParams, setSelectedParams] = useState<number[]>([]);

  useEffect(() => {
    const newIdMapping: { [key: number]: number } = {};
    moduleData.forEach((module) => {
      if (module.idEmbarcado && module.idPropriedade) {
        newIdMapping[module.idEmbarcado] = module.idPropriedade;
      }
    });
    setIdMapping(newIdMapping);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(idMapping).length > 0 && properties.length === 0) {
      const newProperties = Object.keys(idMapping).map((idEmbarcado) => {
        const idStr = idEmbarcado.toString(); // Converte o ID para string
        const valorPadrao = idStr in valoresDefaultPorId ? valoresDefaultPorId[idStr] : '';

        return {
          idPropriedade: idMapping[Number(idEmbarcado)],
          valorPropriedade: valorPadrao,
          idEmbarcado: Number(idEmbarcado),
        };
      });
      setProperties((prevState) => [...prevState, ...newProperties]);
    }
  }, [idMapping, setProperties, properties.length]);

  const handleInputChange = (idEmbarcado: number, value: string) => {
    const idPropriedade = idMapping[idEmbarcado];
    if (idPropriedade !== undefined) {
      setProperties((prevValue) => {
        const newProperties = [...prevValue];
        const index = newProperties.findIndex((property) => property.idPropriedade === idPropriedade);
        if (index !== -1) newProperties[index].valorPropriedade = value;
        if (setPropertyValues) {
          setPropertyValues(newProperties);
        }
        return newProperties;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const propertyValue = (idEmbarcado: number) => {
    const idPropriedade = idMapping[idEmbarcado];
    return properties.find((property) => property.idPropriedade === idPropriedade)?.valorPropriedade || '';
  };

  useEffect(() => {
    if (parameters.length > 0 && !parameterJustSeted) {
      setParameterJustSeted(true);
      setSelectedParams(
        parameters
          .filter((p) => !ocasionalParameterEmbarcado.includes(p.identificadorEmbarcado))
          .map((param) => param.identificadorEmbarcado),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  useEffect(() => {
    handleInputChange(69, encode(selectedParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParams]);

  useEffect(() => {
    setProperties([
      ...properties.map((p) => {
        if (p.idEmbarcado === 68 && p.valorPropriedade.length <= 0) {
          p.valorPropriedade = '1';
          return p;
        }

        if (p.idEmbarcado === 49 || p.idEmbarcado === 51) {
          p.valorPropriedade = '';
          return p;
        }

        if ([2, 3, 4, 68].findIndex((x) => x === p.idEmbarcado) > -1 && Number(p.valorPropriedade) === 0) {
          p.valorPropriedade = valoresDefaultPorId[p.idEmbarcado.toString()];
          return p;
        }

        return p;
      }),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalParametersPerfilEnvio
        visible={modalVisible}
        setVisible={setModalVisible}
        selectedParams={selectedParams}
        setSelectedParams={setSelectedParams}
      />

      <Form style={{ width: '100%' }} layout="vertical">
        <S.Row>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={2}>
            <BaseFormInputItem label="Intervalo ignição ligada (s)" style={{ width: '17rem' }}>
              <>
                <Input
                  key={2}
                  type="text"
                  className="ant-input"
                  placeholder="Digite o intervalo"
                  value={propertyValue(2)}
                  onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                    const char = e.data;
                    if (char && !char.match(/[0-9]/)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || (/^\d+$/.test(value) && Number(value) <= 172800)) {
                      handleInputChange(2, value);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (Number(value) < 10 || Number(value) > 172800) {
                      handleInputChange(2, valoresDefaultPorId['2']);
                    }
                  }}
                  disabled={disableAllFields}
                />
                <S.InputObs>Entre 10 e 172800 segundos</S.InputObs>
              </>
            </BaseFormInputItem>
          </ShouldRenderInput>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={2}>
            <BaseFormInputItem label="Intervalo bateria externa (s)" style={{ width: '17rem' }}>
              <>
                <Input
                  key={3}
                  type="text"
                  className="ant-input"
                  placeholder="Digite o intervalo"
                  value={propertyValue(3)}
                  onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                    const char = e.data;
                    if (char && !char.match(/[0-9]/)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || (/^\d+$/.test(value) && Number(value) <= 172800)) {
                      handleInputChange(3, value);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (Number(value) < 10 || Number(value) > 172800) {
                      handleInputChange(3, valoresDefaultPorId['3']);
                    }
                  }}
                  disabled={disableAllFields}
                />
                <S.InputObs>Entre 10 e 172800 segundos</S.InputObs>
              </>
            </BaseFormInputItem>
          </ShouldRenderInput>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={2}>
            <BaseFormInputItem label="Intervalo bateria interna (s)" style={{ width: '17rem' }}>
              <>
                <Input
                  key={4}
                  type="text"
                  className="ant-input"
                  placeholder="Digite o intervalo"
                  value={propertyValue(4)}
                  onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                    const char = e.data;
                    if (char && !char.match(/[0-9]/)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || (/^\d+$/.test(value) && Number(value) <= 172800)) {
                      handleInputChange(4, value);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (Number(value) < 10 || Number(value) > 172800) {
                      handleInputChange(4, valoresDefaultPorId['4']);
                    }
                  }}
                  disabled={disableAllFields}
                />
                <S.InputObs>Entre 10 e 172800 segundos</S.InputObs>
              </>
            </BaseFormInputItem>
          </ShouldRenderInput>
        </S.Row>
        <S.Row style={{ columnGap: '10rem' }}>
          <S.FieldCheckboxValue>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={48}>
              <Checkbox
                key={48}
                checked={propertyValue(48) === 'true'}
                onChange={(e) => {
                  const value = e.target.checked ? 'true' : 'false';
                  handleInputChange(48, value);
                  if (value === 'false') handleInputChange(49, '');
                }}
                style={{ width: '13rem' }}
                disabled={disableAllFields}
              >
                <S.CheckboxLabel>Envio por distância (m)</S.CheckboxLabel>
                <S.InputObs>50 a 1000</S.InputObs>
              </Checkbox>
            </ShouldRenderInput>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={49}>
              <Input
                key={3}
                type="text"
                className="ant-input"
                placeholder="Digite o valor"
                value={propertyValue(49)}
                disabled={disableAllFields || propertyValue(48) !== 'true'}
                onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                  const char = e.data;
                  if (char && !char.match(/[0-9]/)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || (/^\d+$/.test(value) && Number(value) <= 1000)) {
                    handleInputChange(49, value);
                  }
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (Number(value) < 50 || Number(value) > 1000) {
                    handleInputChange(49, valoresDefaultPorId['49']);
                  }
                }}
                style={{ width: '8rem' }}
              />
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
          <S.FieldCheckboxValue>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={50}>
              <Checkbox
                key={50}
                checked={propertyValue(50) === 'true'}
                onChange={(e) => {
                  const value = e.target.checked ? 'true' : 'false';
                  handleInputChange(50, value);
                  if (value === 'false') handleInputChange(51, '');
                }}
                style={{ width: '13rem' }}
                disabled={disableAllFields}
              >
                <S.CheckboxLabel>Envio por ângulo (°)</S.CheckboxLabel>
                <S.InputObs>10 a 179</S.InputObs>
              </Checkbox>
            </ShouldRenderInput>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={51}>
              <Input
                key={51}
                type="text"
                className="ant-input"
                placeholder="Digite o valor"
                value={propertyValue(51)}
                disabled={disableAllFields || propertyValue(50) !== 'true'}
                onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                  const char = e.data;
                  if (char && !char.match(/[0-9]/)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || (/^\d+$/.test(value) && Number(value) <= 179)) {
                    handleInputChange(51, value);
                  }
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (Number(value) < 10 || Number(value) > 179) {
                    handleInputChange(51, valoresDefaultPorId['51']);
                  }
                }}
                style={{ width: '8rem' }}
              />
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
        </S.Row>
        <S.Row>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={68}>
            <HelperNotification style={{ marginTop: '1rem' }}>
              Não é possível selecionar os parâmetros de envio no formato JSON. A seleção dos parâmetros só está
              disponível para tipo String e .Zip
            </HelperNotification>
            <BaseFormInputItem label="Tipo de envio">
              <RadioGroup
                key={68}
                defaultValue={'1'}
                value={propertyValue(68)}
                onChange={(e) => {
                  const tipo = e.target.value;
                  handleInputChange(68, tipo);

                  if (tipo === '0') {
                    handleInputChange(69, '');
                  } else {
                    setSelectedParams(
                      parameters
                        .filter((p) => !ocasionalParameterEmbarcado.includes(p.identificadorEmbarcado))
                        .map((param) => param.identificadorEmbarcado),
                    );
                  }
                }}
              >
                <Space direction="vertical">
                  <Radio value={'0'}>
                    JSON{' '}
                    <TooltipInfoIcon
                      title={'Tipo de mensagem altamente estruturada. Alto consumo de dados por envio'}
                    />
                  </Radio>
                  <Radio value={'1'}>
                    String <TooltipInfoIcon title={'Tipo de mensagem estruturada. Médio consumo de dados por envio.'} />
                  </Radio>
                  <Radio value={'2'}>
                    .Zip{' '}
                    <TooltipInfoIcon title={'Tipo de mensagem não estruturada. Baixo consumo de dados por envio.'} />
                  </Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </ShouldRenderInput>
        </S.Row>
        <S.Row>
          <ST.Collapse
            bordered={false}
            style={{
              marginBottom: '1rem',
              width: '100%',
            }}
          >
            <ST.Panel key={'pre-visualization'} header={'Pré-visualização do envio da mensagem'}>
              <HelperNotification>Exemplo de como a mensagem será enviada ao dispositivo.</HelperNotification>
              <SyntaxHighlighter
                language="json"
                style={googlecode}
                customStyle={{
                  backgroundColor: 'transparent',
                }}
                wrapLines={true}
                wrapLongLines={false}
              >
                {codeStringExample[propertyValue(68)] ?? ''}
              </SyntaxHighlighter>
            </ST.Panel>
          </ST.Collapse>
        </S.Row>
        {(propertyValue(68).length === 0 || (propertyValue(68).length > 0 && propertyValue(68) !== '0')) && (
          <S.Row style={{ margin: '1rem 0' }}>
            <Col xs={24}>
              <S.Collapse activeKey={'1'}>
                <Panel
                  key="1"
                  header={
                    <Row align={'middle'} justify={'space-between'}>
                      <Col>
                        <ST.CollapseHeaderName>Parâmetros de envio</ST.CollapseHeaderName>
                      </Col>
                      <Button type="ghost" onClick={() => setModalVisible(true)}>
                        Selecionar parâmetros
                      </Button>
                    </Row>
                  }
                >
                  <S.Row>
                    <CardInfoTitle>Obrigatórios</CardInfoTitle>
                  </S.Row>
                  <S.Row style={{ columnGap: '0.8rem' }}>
                    <strong>{parameters.filter((p) => p.isObrigatorio).length}</strong> parâmetros
                  </S.Row>
                  <S.Row style={{ margin: '0.9rem 0' }}>
                    {parameters
                      .filter((p) => p.isObrigatorio)
                      .map((param) => (
                        <Col key={param.identificadorEmbarcado} xs={24} md={4} lg={4} style={{ minHeight: '32px' }}>
                          {param.nome}
                        </Col>
                      ))}
                  </S.Row>

                  <S.Row>
                    <CardInfoTitle>Opcionais</CardInfoTitle>
                  </S.Row>
                  <S.Row>
                    <strong>
                      {selectedParams.filter((p) => !ocasionalParameterEmbarcado.includes(p)).length -
                        parameters.filter((p) => p.isObrigatorio).length}
                    </strong>{' '}
                    parâmetros
                  </S.Row>
                  <S.Row style={{ margin: '0.9rem 0' }}>
                    {parameters
                      .filter(
                        (p) =>
                          !p.isObrigatorio &&
                          !ocasionalParameterEmbarcado.includes(p.identificadorEmbarcado) &&
                          selectedParams.includes(p.identificadorEmbarcado),
                      )
                      .map((param) => (
                        <Col key={param.identificadorEmbarcado} xs={24} md={4} lg={4} style={{ minHeight: '32px' }}>
                          {param.nome}
                        </Col>
                      ))}
                  </S.Row>
                </Panel>
              </S.Collapse>
            </Col>
          </S.Row>
        )}
      </Form>
    </>
  );
};

export default PerfilEnvio;
