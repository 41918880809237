import { Modal } from '@app/components/common/Modal/Modal';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input, TextArea } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { Col, Row, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FirmwareGroupModel, FirmwareGroupVersionsModel } from '@app/domain/firmwareGroup/firmwareGroupModel';
import MaskedInput from 'react-text-mask';
import { maskIdVersionProps } from '@app/utils/utils';
import { Select } from '@app/components/common/selects/Select/Select';
import { VersionStatusModel } from '@app/domain/firmwareVersion/versionStatusModel';
import { FirmwareVersionService } from '@app/services/firmwareVersionService';

interface IModalFirmwareVersionProps {
  firmwareGroup: FirmwareGroupModel;
  setFirmwareGroup?: React.Dispatch<React.SetStateAction<FirmwareGroupModel>>;
  firmwareVersion?: FirmwareGroupVersionsModel | null;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  modalVisible: boolean;
  editing: boolean;
  fetchGroups?: () => Promise<void>;
}

const firmwareVersionService = new FirmwareVersionService();

export const ModalFirmwareVersion: React.FC<IModalFirmwareVersionProps> = ({
  firmwareGroup,
  firmwareVersion,
  modalVisible,
  setModalVisible,
  editing,
  setFirmwareGroup,
  fetchGroups,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [version, setVersion] = useState<string>('');
  const [versionStatus, setVersionStatus] = useState<number | null>(null);
  const [versionObs, setVersionObs] = useState<string>('');
  const [versionSuffix, setVersionSuffix] = useState<string>('');
  const [statusOptions, setStatusOptions] = useState<VersionStatusModel[]>([]);
  const fetchStatusOptions = useCallback(() => {
    return firmwareVersionService
      .getStatusOptions()
      .then((res: VersionStatusModel[]) => {
        setStatusOptions(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });
  }, []);

  useEffect(() => {
    fetchStatusOptions();

    if (editing && firmwareVersion) {
      const versionSplited = firmwareVersion.versao.split('-');
      setVersion(`${versionSplited[0].split('.')[2]}.${versionSplited[0].split('.')[3]}`);
      setVersionSuffix(versionSplited[1]);
      setVersionObs(firmwareVersion.observacao ?? '');
      setVersionStatus(firmwareVersion.idStatus ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmwareVersion, editing]);

  const handleSaveFirmVersion = async () => {
    const fullVersion = `${(firmwareGroup?.nome ?? '').replace(/_|\bx.x$/, '')}${version}${
      versionSuffix.length > 0 ? '-' + versionSuffix : ''
    }`;
    const existVersionIndex = firmwareGroup.versoes.findIndex((v) => v.versao === fullVersion);

    if (existVersionIndex >= 0) {
      notificationController.error({ message: 'Já existe uma versão igual cadastrada.' });
      return;
    }

    if (editing) {
      if (setFirmwareGroup !== undefined) {
        setFirmwareGroup((prevState) => ({
          ...prevState,
          versoes: [
            ...prevState.versoes.map((v, index) => {
              if (index === existVersionIndex) {
                return {
                  versao: fullVersion,
                  idStatus: versionStatus ?? 0,
                  observacao: versionObs,
                  equipamento: firmwareVersion?.equipamento,
                  idModelo: firmwareVersion?.idModelo,
                  modelo: firmwareVersion?.modelo,
                };
              } else {
                return v;
              }
            }),
          ],
        }));
      }
    } else {
      setLoading(true);
      firmwareVersionService
        .post('', {
          versao: fullVersion,
          idModelo: firmwareGroup.idModelo,
          idStatus: versionStatus ?? 0,
          observacao: versionObs,
          idGrupo: firmwareGroup.id,
          dataHora: new Date().toLocaleDateString(),
          equipamento: firmwareGroup.equipamento ?? '-',
          existeAgendamento: false,
          idEquipamento: 0,
          modelo: firmwareGroup.modelo ?? '-',
          status: statusOptions.find((s) => s.id === versionStatus)?.nome ?? '-',
          id: 0,
        })
        .then(() => {
          if (fetchGroups !== undefined) {
            fetchGroups();
          }
          notificationController.success({ message: 'Cadastro da versão de firmware realizada com sucesso!' });
          setModalVisible(false);
        })
        .catch((error) => {
          notificationController.error({
            message: `Ocorreu um erro ao tentar cadastrar a versão de firmware.\n${error}`,
          });
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <Modal
        title={`${editing ? 'Editar' : 'Adicionar'} versão de firmware`}
        open={modalVisible}
        okButtonProps={{ disabled: !version || !versionStatus, loading: loading }}
        onOk={handleSaveFirmVersion}
        onCancel={() => setModalVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
        size="large"
      >
        <BaseForm>
          <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col span={8}>
              <BaseFormInputItem label="Equipamento" style={{ width: '100%' }}>
                <Input placeholder="Digite o nome do equipamento" value={firmwareGroup?.equipamento ?? '-'} disabled />
              </BaseFormInputItem>
            </Col>
            <Col span={8}>
              <BaseFormInputItem label="Modelo" style={{ width: '100%' }}>
                <Input placeholder="Digite o nome do modelo" value={firmwareGroup?.modelo ?? '-'} disabled />
              </BaseFormInputItem>
            </Col>
            <Col span={8}>
              <BaseFormInputItem label="Grupo" style={{ width: '100%' }}>
                <Input placeholder="Digite o nome do grupo" value={firmwareGroup?.nome ?? '-'} disabled />
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <BaseFormInputItem label="Versão de firmware" style={{ width: '100%' }}>
                <Space.Compact>
                  <Input
                    style={{
                      borderBottomRightRadius: 0,
                      borderTopRightRadius: 0,
                      width: '37%',
                    }}
                    prefix={(firmwareGroup?.nome ?? '').replace(/_|\bx.x$/, '')}
                    value={version}
                    disabled
                  />
                  <MaskedInput
                    {...maskIdVersionProps}
                    style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0, width: '63%' }}
                    className="ant-input"
                    placeholder="Digite a versão do firmware"
                    value={version}
                    onChange={(e) => setVersion(e.target.value)}
                    onBlur={(e) => {
                      let value = e.target.value;
                      value = value.replace(/_/g, '');
                      setVersion(value);
                    }}
                  />
                </Space.Compact>
              </BaseFormInputItem>
            </Col>
            {statusOptions.find((o) => o.id == versionStatus)?.nome == 'Teste' && (
              <Col span={8}>
                <BaseFormInputItem label="Sufixo" supportText="Opcional">
                  <Input value={versionSuffix} onChange={(e) => setVersionSuffix(e.target.value)} maxLength={10} />
                </BaseFormInputItem>
              </Col>
            )}
            <Col span={8}>
              <BaseFormInputItem label="Status" style={{ width: '100%' }}>
                <Select
                  showArrow
                  disabled={version?.length <= 0}
                  value={versionStatus ?? 0}
                  placeholder="Selecione o status"
                  onChange={(value) => setVersionStatus(value as number | null)}
                  options={statusOptions.map((s) => ({ value: s.id, label: s.nome }))}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <BaseFormInputItem label="Observações" supportText="Opcional" style={{ width: '100%' }}>
                <TextArea value={versionObs} onChange={(e) => setVersionObs(e.target.value)} />
              </BaseFormInputItem>
            </Col>
          </Row>
        </BaseForm>
      </Modal>
    </>
  );
};
