import styled from 'styled-components';
import { TableCollapse as AntTable } from '@app/components/common/TableCollapse/TableCollapse';

export const Container = styled.div`
  width: inherit;
  margin: 1rem;

  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

export const InsideContainer = styled.div`
  width: 100%;

  .ant-collapse {
    border-radius: 0;
  }
`;

export const TableContainer = styled(AntTable)`
  .ant-table-tbody {
    background-color: #f5f5f5;
    color: #383838;
  }

  .ant-table-thead {
    tr {
      th {
        background-color: var(--background-color);
      }
    }
  }
`;
