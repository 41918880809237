import React, { useEffect, useState } from 'react';
import { DeviceProfilePropertyModel, PropertyValue } from '@app/domain/deviceProfile/deviceProfileModel';
import ShouldRenderInput from '@app/components/common/ModulesProfileConfig/ShouldRenderInput';
import { Col, Form, Row } from 'antd';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { CardInfoProperty } from '../../Card/CardInfo/CardDeviceInformation.styles';
import * as S from '../ModulesProfileConfig.styles';
import { Input } from '../../inputs/Input/Input';

interface EventosProps {
  properties: PropertyValue[];
  setProperties: React.Dispatch<React.SetStateAction<PropertyValue[]>>;
  moduleData: DeviceProfilePropertyModel[];
  disableAllFields?: boolean;
  setPropertyValues?: (data: PropertyValue[]) => void;
}

const EventosView: React.FC<EventosProps> = ({ properties, setProperties, moduleData }) => {
  const [idMapping, setIdMapping] = useState<{ [key: number]: number }>({});
  const valoresDefaultPorId: { [key: string]: string } = {
    '21': 'false',
    '16': 'false',
    '17': '80',
    '52': 'false',
    '60': '0.3',
    '53': 'false',
    '23': '0.3',
    '54': 'false',
    '61': '0.3',
    '18': 'false',
    '20': '4000',
    '19': '3000',
    '55': 'false',
    '25': 'false',
    '27': 'false',
    '28': '10',
    '56': 'false',
    '57': 'false',
    '58': '3.5',
    '59': 'false',
  };
  useEffect(() => {
    const newIdMapping: { [key: number]: number } = {};
    moduleData.forEach((module) => {
      if (module.idEmbarcado && module.idPropriedade) {
        newIdMapping[module.idEmbarcado] = module.idPropriedade;
      }
    });
    setIdMapping(newIdMapping);
  }, [moduleData]);

  useEffect(() => {
    if (Object.keys(idMapping).length > 0 && properties.length === 0) {
      const newProperties = Object.keys(idMapping).map((idEmbarcado) => {
        const idStr = idEmbarcado.toString();
        const valorPadrao = idStr in valoresDefaultPorId ? valoresDefaultPorId[idStr] : '';

        return {
          idPropriedade: idMapping[Number(idEmbarcado)],
          valorPropriedade: valorPadrao,
          idEmbarcado: Number(idEmbarcado),
        };
      });
      setProperties((prevState) => [...prevState, ...newProperties]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMapping, setProperties, properties.length]);

  const propertyValue = (idEmbarcado: number) => {
    const idPropriedade = idMapping[idEmbarcado];
    return properties.find((property) => property.idPropriedade === idPropriedade)?.valorPropriedade || '';
  };

  return (
    <Form layout="vertical">
      <Row gutter={[18, 18]} align={'middle'}>
        <Col xs={24} md={12} xxl={8}>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={21}>
            <Checkbox key={21} checked={propertyValue(21) === 'true'} disabled={true}>
              <CardInfoProperty>Ignição</CardInfoProperty>
            </Checkbox>
          </ShouldRenderInput>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={16}>
                <Checkbox key={16} checked={propertyValue(16) === 'true'} disabled>
                  <S.CheckboxLabel>Limite de velocidade (km/h)</S.CheckboxLabel>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={17}>
                {propertyValue(16) === 'true' && (
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={propertyValue(17)}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="km/h"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={12} xxl={8}>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={59}>
            <Checkbox key={59} checked={propertyValue(59) === 'true'} disabled>
              <S.CheckboxLabel>Detecção de movimento</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </Col>
        <Col xs={24} md={12} xxl={8}>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={25}>
            <Checkbox key={25} checked={propertyValue(25) === 'true'} disabled>
              <S.CheckboxLabel>Entrada digital 1</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </Col>
        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={27}>
                <Checkbox key={27} checked={propertyValue(27) === 'true'} disabled>
                  <S.CheckboxLabel>Limite da entrada analógica (mA)</S.CheckboxLabel>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={28}>
                {propertyValue(27) === 'true' && (
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={propertyValue(28)}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="mA"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={12} xxl={8}>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={56}>
            <Checkbox key={56} checked={propertyValue(56) === 'true'} disabled>
              <S.CheckboxLabel>Mensagem recebida p/ RS232</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={52}>
                <Checkbox key={52} checked={propertyValue(52) === 'true'} disabled>
                  <S.CheckboxLabel>Sensibilidade frenagem brusca (g)</S.CheckboxLabel>
                  <S.InputObs>0,25 a 2</S.InputObs>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={23}>
                {propertyValue(52) === 'true' && (
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={propertyValue(23).toString().replace('.', ',')}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="g"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={53}>
                <Checkbox key={53} checked={propertyValue(53) === 'true'} disabled>
                  <S.CheckboxLabel>Sensibilidade aceleração brusca (g)</S.CheckboxLabel>
                  <S.InputObs>0,25 a 2</S.InputObs>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={60}>
                {propertyValue(53) === 'true' && (
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={propertyValue(60).toString().replace('.', ',')}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="g"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={54}>
                <Checkbox key={54} checked={propertyValue(54) === 'true'} disabled>
                  <S.CheckboxLabel>Sensibilidade curva acentuada (g)</S.CheckboxLabel>
                  <S.InputObs>0,25 a 2</S.InputObs>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={61}>
                {propertyValue(54) === 'true' && (
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={propertyValue(61).toString().replace('.', ',')}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="g"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={55}>
            <Checkbox key={55} checked={propertyValue(55) === 'true'} disabled>
              <S.CheckboxLabel>Modo bateria interna</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={57}>
                <Checkbox key={57} checked={propertyValue(57) === 'true'} disabled>
                  <S.CheckboxLabel>Limite bateria interna baixa (V)</S.CheckboxLabel>
                  <S.InputObs>3,4 a 3,6</S.InputObs>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={58}>
                {propertyValue(57) === 'true' && (
                  <Input
                    key={58}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={propertyValue(58).toString().replace('.', ',')}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="V"
                  />
                )}
              </ShouldRenderInput>
            </Col>
            <Col xs={24} md={12}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={85}>
                <Checkbox key={85} checked={propertyValue(85) === 'true'} style={{ width: '13rem' }} disabled>
                  <S.CheckboxLabel>Mensagem recebida p/ Serial</S.CheckboxLabel>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={18}>
                <Checkbox key={18} checked={propertyValue(18) === 'true'} disabled>
                  <S.CheckboxLabel>Limite de rpm (alto e médio)</S.CheckboxLabel>
                  <S.InputObs>1000 a 7000 rpm</S.InputObs>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={6}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={20}>
                {propertyValue(18) === 'true' && (
                  <Input
                    key={20}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={propertyValue(20).toString().replace('.', ',')}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="rpm"
                  />
                )}
              </ShouldRenderInput>
            </Col>
            <Col span={6}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={19}>
                {propertyValue(18) === 'true' && (
                  <Input
                    key={19}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={propertyValue(19).toString().replace('.', ',')}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="rpm"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default EventosView;
