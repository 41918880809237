/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import IconMore from '@app/assets/slump-icons/IconMore';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Modal } from '@app/components/common/Modal/Modal';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import { Button } from '@app/components/common/buttons/Button/Button';
import Dashboard from '@app/components/dashboard/Dashboard';
import { ClientModel } from '@app/domain/client/clientModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeader } from '@app/store/slices/headerSlice';
import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IClientService, { ClientService } from '@app/services/clientService';
import { notificationController } from '@app/controllers/notificationController';
import { useMounted } from '@app/hooks/useMounted';
import { hasAccessByRole } from '@app/controllers/accessController';
import { UserType } from '@app/constants/enums/userType';
import { genericExportToExcel } from '@app/utils/exportToExcel';

const clientService: IClientService = new ClientService();

export const ClientDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isMounted } = useMounted();

  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [client, setClient] = useState<ClientModel>({} as ClientModel);
  const [searchClientFiltered, setSearchClientFiltered] = useState<ClientModel[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idClient, setIdClient] = useState(0);

  const columns: ColumnsType<ClientModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: true,
      sorter: (a: ClientModel, b: ClientModel) => a.id - b.id,
      defaultSortOrder: 'descend',
      ellipsis: true,
      width: '87px',
    },
    {
      title: 'Razão Social',
      dataIndex: 'razaoSocial',
      showSorterTooltip: true,
      sorter: (a, b) => (a.razaoSocial ?? '').localeCompare(b.razaoSocial ?? ''),
      ellipsis: true,
      width: '35%',
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      showSorterTooltip: true,
      sorter: (a, b) => (a.cnpj ?? '').localeCompare(b.cnpj ?? ''),
      ellipsis: true,
      width: '20%',
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      showSorterTooltip: true,
      sorter: (a, b) => (a.telefone ?? '').localeCompare(b.telefone ?? ''),
      ellipsis: true,
      width: '20%',
    },
    {
      title: 'Endereço',
      dataIndex: 'endereco',
      showSorterTooltip: true,
      render: (logradouro, row) => {
        return (
          <span>{`${row.logradouro}, ${row.numero}, ${row.complemento}. ${row.bairro}, ${row.cidade}-${row.estado}. ${row.cep}`}</span>
        );
      },
      sorter: (a, b) => (a.logradouro ?? '').localeCompare(b.logradouro ?? ''),
      ellipsis: true,
      width: '50%',
    },
    {
      title: '',
      dataIndex: 'id',
      width: '5%',
      render: (id, client) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
            }}
          >
            {hasAccessByRole(UserType.FactoryAdmin) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ color: 'var(--text-label)' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <MenuItem>
                      <Button type="text" onClick={() => handleEditClick(client)}>
                        Editar
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        type="text"
                        onClick={() => {
                          setIsModalVisible(true);
                          setIdClient(id);
                        }}
                      >
                        Deletar
                      </Button>
                    </MenuItem>
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate('/clientes/cadastrar');
  };

  const handleEditClick = (client: ClientModel) => {
    if (client.id !== null && client.id !== undefined) {
      navigate(`/clientes/editar/${client.id}`);
    }
  };

  const handleExportToExcel = () => {
    genericExportToExcel(
      'clientes',
      columns,
      searchClientFiltered?.map((u) => ({
        ...u,
        endereco: `${u.logradouro}, ${u.numero}, ${u.complemento}. ${u.bairro}, ${u.cidade}-${u.estado}. ${u.cep}`,
      })) ?? [],
    );
  };

  const handleDeleteClick = (e: React.MouseEvent, id: number) => {
    e.preventDefault();
    setLoading(true);
    setIsModalVisible(false);
    clientService
      .delete(`${id}`)
      .then(() => {
        setLoading(false);
        notificationController.success({ message: 'Cliente excluído com sucesso!' });
        setClients(clients?.filter((client) => client.id != id));
        setSearchClientFiltered(searchClientFiltered?.filter((client) => client.id != id));
      })
      .catch((err: Error) => {
        setLoading(false);
        console.error(err.message);
        notificationController.error({ message: 'Erro ao deletar o cliente, verificar se está ativo.' });
      });
  };

  const handleSearchClientFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();

      setSearchClientFiltered(
        clients.filter(
          (item) =>
            item.cnpj
              ?.toLowerCase()
              .replaceAll('.', '')
              .replace('/', '')
              .includes(
                inputValue
                  .replaceAll('.', '')
                  .replace('/', '')
                  .replace(/^\s+|\s+$/g, ''),
              ) ||
            item.razaoSocial?.toLowerCase().includes(inputValue.replace(/^\s+|\s+$/g, '')) ||
            item.id?.toString()?.toLowerCase()?.includes(inputValue) ||
            item.telefone?.includes(inputValue) ||
            `${item.logradouro}, ${item.numero}, ${item.complemento}. ${item.bairro}, ${item.cidade}-${item.estado}. ${item.cep}`
              .toLowerCase()
              .includes(inputValue),
        ),
      );
    } else {
      setSearchClientFiltered(clients);
    }
  };

  const fetch = useCallback(() => {
    setLoading(true);
    clientService
      .getArray(``)
      .then((res: ClientModel[]) => {
        if (isMounted.current) {
          setClients(res);
          setSearchClientFiltered(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({ message: `Erro ao obter um cliente. ${err}` });
      });
  }, [isMounted]);

  useEffect(() => {
    fetch();
    dispatch(
      setHeader({
        title: 'Clientes',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <Modal
        title="Deletar "
        open={isModalVisible}
        onOk={(e: React.MouseEvent) => handleDeleteClick(e, idClient)}
        onCancel={() => setIsModalVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <CloseCircleOutlined size={20} style={{ color: '#C41B24', marginRight: ' 1rem' }} />
            Tem certeza que deseja deletar o cliente selecionado?
          </Col>
        </Row>
      </Modal>
      <PageTitle>Clientes</PageTitle>
      <Spinner spinning={loading}></Spinner>
      <Dashboard
        buttonText={hasAccessByRole(UserType.FactoryAdmin) ? 'Cadastrar novo cliente' : ''}
        handleButtonClick={handleClick}
        table={<Table bordered={true} columns={columns} dataSource={searchClientFiltered} loading={loading} />}
        placeholderTextSearch="Pesquisar"
        handleSearchOnChange={handleSearchClientFilter}
        exportToExcel={handleExportToExcel}
      />
    </>
  );
};
