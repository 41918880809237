import { ApiServiceBase, IApiServiceBase } from './base/apiServiceBase';
import { SectorModel } from '@app/domain/sectorModel/sectorModel';

export default interface ISectorService extends IApiServiceBase<SectorModel, SectorModel> {}

export class SectorService extends ApiServiceBase<SectorModel, SectorModel> {
  constructor() {
    super('Setor');
  }
}
