import { IconMore } from '@app/assets/slump-icons';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import { Tag } from '@app/components/common/Tag/Tag';
import { Button } from '@app/components/common/buttons/Button/Button';
import Dashboard from '@app/components/dashboard/Dashboard';
import { UserType } from '@app/constants/enums/userType';
import { hasAccessByRoles } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import { ConfigUpdateModel } from '@app/domain/deviceConfigUpdate/deviceConfigUpdate';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IDeviceConfigUpdateService, { DeviceConfigUpdateService } from '@app/services/deviceConfigUpdateService';
import { setHeader } from '@app/store/slices/headerSlice';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { exportDeviceConfigUpdateToExcel } from './functions/excel-functions';
import { formatarData } from '@app/utils/utils';

const deviceConfigService: IDeviceConfigUpdateService = new DeviceConfigUpdateService();

export const DeviceConfigUpdateDashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  // const [configUpdateSelected, setConfigUpdateSelected] = useState<ConfigUpdateModel>();
  const [configUpdates, setConfigUpdates] = useState<ConfigUpdateModel[]>();
  const [shearchConfigUpdates, setShearchConfigUpdates] = useState<ConfigUpdateModel[]>();

  const handleNewScheduleConfigUpdateClick = () => navigate('/atualizacao-configuracao-dispositivo/agendar');

  const handleViewClick = (configUpdate: ConfigUpdateModel) => {
    navigate(`/atualizacao-configuracao-dispositivo/visualizar/${configUpdate.id}`);
  };

  const handleExportExcelClick = async (configUpdateId: number) => {
    try {
      setLoading(true);
      const configUpdate = await deviceConfigService.get(`${configUpdateId}`);
      if (configUpdate) {
        await exportDeviceConfigUpdateToExcel(configUpdate);
      } else {
        notificationController.error({
          message: 'Erro ao buscar atualização configuração.',
        });
      }
    } catch (error) {
      notificationController.error({
        message: `Erro ao exportar para Excel: ${error}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleExportToExcel = () => {
    genericExportToExcel('atualizacoes_config', columns, shearchConfigUpdates ?? []);
  };

  const handleSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setShearchConfigUpdates(
        configUpdates?.filter(
          (item) =>
            item.id?.toString().toLowerCase().includes(inputValue) ||
            item.tipoEquipamento?.toString().toLowerCase().includes(inputValue) ||
            item.modelo?.toLowerCase().includes(inputValue) ||
            item.usuario?.toLowerCase().includes(inputValue) ||
            moment(item.dataHora).format('DD/MM/yyyy hh:mm').toLowerCase().includes(inputValue) ||
            item.status.toLowerCase().includes(inputValue) ||
            item.grupoFirmware.includes(inputValue),
        ),
      );
    } else {
      setShearchConfigUpdates(configUpdates);
    }
  };

  const renderStatus = useCallback((status: string) => {
    if (status === 'Expirado') {
      return (
        <Tag color="#F4EAFF" style={{ color: '#52025F' }}>
          Expirado
        </Tag>
      );
    }
    if (status === 'Atualizado')
      return (
        <Tag color="#E9F4EE" style={{ color: '#083F18' }}>
          Atualizado
        </Tag>
      );
    if (status === 'Pendente')
      return (
        <Tag color="#FFF3E6" style={{ color: '#975A16' }}>
          Pendente
        </Tag>
      );
  }, []);

  const columns: ColumnsType<ConfigUpdateModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: true,
      defaultSortOrder: 'descend',
      sorter: (a: ConfigUpdateModel, b: ConfigUpdateModel) => a.id - b.id,
      width: '87px',
    },
    {
      title: 'Equipamento',
      dataIndex: 'tipoEquipamento',
      showSorterTooltip: true,
      sorter: (a, b) => (a.tipoEquipamento ?? '').localeCompare(b.tipoEquipamento ?? ''),
      width: '150px',
    },
    {
      title: 'Modelo',
      dataIndex: 'modelo',
      showSorterTooltip: true,
      sorter: (a, b) => (a.modelo ?? '').localeCompare(b.modelo ?? ''),
      width: '100px',
    },
    {
      title: 'Grupo de firmware',
      dataIndex: 'grupoFirmware',
      showSorterTooltip: false,
      sorter: (a, b) => (a.grupoFirmware ?? '').localeCompare(b.grupoFirmware ?? ''),
      width: '150px',
    },
    {
      title: 'Usuário',
      dataIndex: 'usuario',
      showSorterTooltip: true,
      sorter: (a, b) => (a.usuario ?? '').localeCompare(b.usuario ?? ''),
      width: '150px',
    },
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: true,
      width: '140px',
      sorter: (a, b) =>
        (a.dataHora ? new Date(a.dataHora).getTime() : Infinity) -
        (b.dataHora ? new Date(b.dataHora).getTime() : Infinity),
      render: (dataHora: string) => formatarData(new Date(dataHora)),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      showSorterTooltip: true,
      width: '100px',
      // sorter: (a: ConfigUpdateModel, b: ConfigUpdateModel) => a.status.localeCompare(b.status),
      sorter: (a, b) => (a.status ?? '').localeCompare(b.status ?? ''),
      render: (status: string) => renderStatus(status),
    },
    {
      title: '',
      dataIndex: 'id',
      width: '10px',
      render: (_, configUpdate: ConfigUpdateModel) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {hasAccessByRoles([UserType.FactoryAdmin, UserType.Client]) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ color: '#545454' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <MenuItem onClick={() => handleViewClick(configUpdate)}>
                      <Button type="text">Visualizar</Button>
                    </MenuItem>
                    {configUpdate.status !== 'Cancelado' && (
                      <MenuItem onClick={() => handleExportExcelClick(configUpdate.id)}>
                        <Button type="text">Exportar excel</Button>
                      </MenuItem>
                    )}
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  const fetchConfigUpdates = useCallback(async () => {
    try {
      setLoading(true);
      const configUpdatesResponse = await deviceConfigService.getArray('');
      setConfigUpdates(configUpdatesResponse);
      // setConfigUpdates(scheduleConfigUpdates);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um problema ao buscar as atualizações de configuração',
      });
    }
  }, []);

  useEffect(() => {
    dispatch(
      setHeader({
        title: 'Configuração remota',
      }),
    );
    fetchConfigUpdates();
  }, [fetchConfigUpdates, dispatch]);

  useEffect(() => {
    setShearchConfigUpdates(configUpdates);
  }, [configUpdates]);

  return (
    <>
      <PageTitle>Configuração remota</PageTitle>
      <Spinner spinning={loading}>
        <Dashboard
          title=""
          buttonText={hasAccessByRoles([UserType.FactoryAdmin, UserType.Client]) ? 'Nova configuração' : ''}
          handleButtonClick={handleNewScheduleConfigUpdateClick}
          placeholderTextSearch="Pesquisar"
          handleSearchOnChange={handleSearchFilter}
          table={<Table columns={columns} dataSource={shearchConfigUpdates} bordered />}
          exportToExcel={handleExportToExcel}
        />
      </Spinner>
    </>
  );
};
