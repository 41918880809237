import * as React from 'react';
const SvgIconDeleteOutlined = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" width="1em" height="1em" role="img">
    <path
      fill="#000"
      fillOpacity={0.85}
      d="M11.892 4.818h-.321a.322.322 0 0 0 .321-.322v.322h12.215v-.322c0 .177.144.322.321.322h-.321V7.71h2.892V4.496a2.574 2.574 0 0 0-2.571-2.571H11.571a2.574 2.574 0 0 0-2.572 2.571v3.215h2.893V4.818Zm20.25 2.893H3.857c-.712 0-1.286.574-1.286 1.285v1.286c0 .177.144.321.321.321H5.32l.992 21.014a2.574 2.574 0 0 0 2.568 2.45h18.24a2.568 2.568 0 0 0 2.568-2.45l.993-21.014h2.427a.322.322 0 0 0 .321-.32V8.995c0-.71-.575-1.285-1.286-1.285Zm-5.331 23.464H9.188l-.972-20.572h19.567l-.972 20.572Z"
    />
  </svg>
);
export default SvgIconDeleteOutlined;
