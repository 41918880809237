import axios, { Axios, AxiosError, AxiosRequestHeaders } from 'axios';
import appSettings from '@app/config/appsettings';
import { deleteToken, readToken, readUser } from '../localStorage.service';
import { hasBetaAccess } from '@app/controllers/accessController';

export interface IApiServiceBase<TRequest, TResponse> {
  api: Axios;
  post(endpoint: string, entity: TRequest): Promise<TResponse>;
  postArray(endpoint: string, entity: TRequest[]): Promise<TResponse[]>;
  update(endpoint: string, entity: TRequest): Promise<TResponse>;
  updateArray(endpoint: string, entity: TRequest[]): Promise<TResponse[]>;
  get(endpoint: string): Promise<TResponse>;
  getArray(endpoint: string): Promise<TResponse[]>;
  delete(endpoint: string): Promise<boolean>;
}

export abstract class ApiServiceBase<TRequest, TResponse> implements IApiServiceBase<TRequest, TResponse> {
  public api: Axios;
  private version: string;
  private base_url: string;
  private base_url_beta: string;
  private service_endpoint: string;
  constructor(service_endpoint: string) {
    this.service_endpoint = service_endpoint;
    this.base_url = appSettings().APIs.JMakAdm.UrlBase;
    this.base_url_beta = appSettings().APIs.JMakAdm.UrlBaseBeta;
    this.version = appSettings().APIs.JMakAdm.Version;

    const user = readUser();
    const beta = hasBetaAccess(user?.claims ?? []);

    this.api = axios.create({
      baseURL: `${beta ? this.base_url_beta : this.base_url}/v${this.version}/${this.service_endpoint}`,
      headers: { 'Content-Type': 'application/json' },
    });

    if (beta) {
      this.api.defaults.headers.common['Beta'] = '1';
    }

    this.api.interceptors.request.use((config) => {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${readToken()}`,
      } as AxiosRequestHeaders;
      return config;
    });

    this.api.interceptors.response.use(undefined, (error: AxiosError) => {
      if (error.response?.status === 401) {
        deleteToken();
        window.location.reload();
      }

      if (error.response?.status === 403) {
        window.location.href = '/403';
      }

      // if (error.response?.status === 404) {
      //   window.location.href = '/404';
      // }

      // if (error.response?.status === 500) {
      //   window.location.href = '/500';
      // }

      console.error(error.message);
      if (error.message.indexOf('Timeout') > -1) {
        throw new Error('Limite de tempo para execução do comando excedido');
      }

      throw Error(
        typeof error.response?.data === 'string'
          ? error.response?.data
          : JSON.stringify(error.response?.data) || 'Erro ao consultar a API, por favor, consulte um administrador',
      );
    });
  }

  async post(endpoint: string, entity: TRequest): Promise<TResponse> {
    try {
      return (await this.api.post(endpoint, JSON.stringify(entity)))?.data;
    } catch (error) {
      throw error;
    }
  }

  async postArray(endpoint: string, entity: TRequest[]): Promise<TResponse[]> {
    try {
      return (await this.api.post(endpoint, JSON.stringify(entity))).data;
    } catch (error) {
      throw error;
    }
  }

  async update(endpoint: string, entity: TRequest): Promise<TResponse> {
    try {
      return (await this.api.put(endpoint, JSON.stringify(entity)))?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateArray(endpoint: string, entity: TRequest[]): Promise<TResponse[]> {
    try {
      return (await this.api.post(endpoint, JSON.stringify(entity)))?.data;
    } catch (error) {
      throw error;
    }
  }

  async get(endpoint: string): Promise<TResponse> {
    try {
      const response = await this.api.get(endpoint);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async getArray(endpoint: string): Promise<TResponse[]> {
    try {
      const response = await this.api.get(endpoint);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async delete(endpoint: string): Promise<boolean> {
    try {
      return (await this.api.delete(endpoint)).data;
    } catch (error) {
      throw error;
    }
  }
}
