import React from 'react';
import { Col, Row } from 'antd';
import { Panel } from '@app/components/common/Collapse/Collapse';
import * as S from '../../create.styles';
import { CardInfoValue } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { DeviceBlockModelDevices } from '@app/domain/deviceBlock/device-block-model';
import { ReactComponent as EditIcon } from '@app/assets/icons/edit.svg';
import { Button } from '@app/components/common/buttons/Button/Button';

interface IBlockDeviceTableProps {
  devices: DeviceBlockModelDevices[];
  onEditSelected: () => void;
}

export const BlockDeviceTable: React.FC<IBlockDeviceTableProps> = ({ devices, onEditSelected }) => {
  const groupedDevices = devices.reduce(
    (acc, device) => {
      if (!acc[device.modelo]) {
        acc[device.modelo] = [];
      }
      acc[device.modelo].push(device);
      return acc;
    },
    {} as Record<string, DeviceBlockModelDevices[]>,
  );

  return (
    <>
      {devices.length > 0 && (
        <Row justify="end" style={{ marginBottom: '1rem' }}>
          <Col>
            <Button type="link" onClick={onEditSelected} icon={<EditIcon style={{ width: '24px' }} />}>
              Editar
            </Button>
          </Col>
        </Row>
      )}
      {Object.keys(groupedDevices).length > 0 ? (
        Object.keys(groupedDevices).map((modelo) => (
          <>
            <div key={modelo} style={{ marginBottom: '0.5rem' }}>
              <S.Collapse>
                <Panel
                  key={modelo}
                  header={
                    <Row align={'middle'} justify={'space-between'}>
                      <Col>
                        <S.CollapseHeaderName>{modelo}</S.CollapseHeaderName>
                      </Col>
                    </Row>
                  }
                >
                  <Row gutter={[18, 18]}>
                    <Col xs={24} md={24} lg={24} xxl={24}>
                      <div style={{ paddingBottom: '1rem' }}>
                        <Row gutter={[18, 18]} style={{ overflowY: 'auto', maxHeight: '30rem', width: '100%' }}>
                          {groupedDevices[modelo].map((item, index) => (
                            <Col key={index} xs={12} xl={6}>
                              <CardInfoValue style={{ display: 'flex' }}>
                                <S.TableQuantity>{item.numeroSerie}</S.TableQuantity>
                              </CardInfoValue>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Panel>
              </S.Collapse>
            </div>
          </>
        ))
      ) : (
        <Row justify="center" align="middle" style={{ marginTop: '15rem' }}>
          <Col xs={24} md={24} style={{ color: '#707070', fontSize: '1rem', fontWeight: 700, textAlign: 'center' }}>
            {'Nenhum cadastro realizado'}
          </Col>
          <Col xs={24} md={24} style={{ color: '#707070', fontSize: '0.875rem', fontWeight: 400, textAlign: 'center' }}>
            {'Adicione o(s) dispositivo(s) para exibir os resultados'}
          </Col>
        </Row>
      )}
    </>
  );
};
