import moment from 'moment';
import { addData, createWorkbook, saveToFile, setColumnWidths, setHeaders } from '@app/utils/exportToExcel';
import { ScheduleFactoryModel } from '@app/domain/scheduleFactory/scheduleFactoryModel';

const getEquipmentStatus = (schedule: ScheduleFactoryModel, atualizado: boolean) => {
  if (schedule?.status === 'Cancelado') return 'Cancelado';

  return atualizado ? 'Atualizado' : 'Pendente';
};

const getMaxColumnWidth = (schedule: ScheduleFactoryModel, headers: string[], scheduleRow: string[]) => {
  const firstColumnWidth = 24;

  const secondColumnWidth = Math.max(
    ...(schedule?.dispositivos?.map((device) => (device.cliente || '').length) || [0]),
    'Cliente:'.length,
  );

  const maxColumnWidths = headers.map((header, index) => {
    if (index === 0) return firstColumnWidth;
    if (index === 1) return secondColumnWidth;
    return Math.max(header.length, (scheduleRow[index]?.toString() || '').length);
  });

  return maxColumnWidths.map((width) => width + 4);
};

export const exportScheduleToExcel = async (fileName: string, schedule: ScheduleFactoryModel) => {
  const { workbook, worksheet } = createWorkbook('Agendamento');

  // Primeira linha: cabeçalho
  const headers = ['Equipamento', 'Modelo', 'Versão', 'Status', 'Data/Hora', 'Usuário Responsável'];
  setHeaders(worksheet, headers);

  // Segunda linha: informações do agendamento
  const scheduleRow = [
    schedule.tipoEquipamento,
    schedule.modelo,
    schedule.versaoFirmware,
    schedule.status,
    moment(schedule.dataHoraProgramada).format('DD/MM/YYYY HH:mm') + 'h',
    schedule.usuarioResponsavel,
  ];
  addData(worksheet, [scheduleRow]);

  // Loop por cada cliente e seus dispositivos
  schedule?.dispositivos?.forEach((device) => {
    // Linha cliente: Cliente: <nome do cliente>
    const clientRow = ['Cliente:', device.cliente];
    addData(worksheet, [[]]);
    addData(worksheet, [clientRow]);

    // Linha escrito 'Equipamentos: '
    const equipmentHeader = ['Equipamentos:'];
    addData(worksheet, [equipmentHeader]);

    // Linha de cada equipamento do cliente no formato <lote> <status>
    device.dispositivos.forEach((equipment) => {
      const equipmentRow = [equipment.numeroSerial, getEquipmentStatus(schedule, equipment.atualizado)];
      addData(worksheet, [equipmentRow]);
    });
  });

  // Define a largura de cada coluna
  const computedMaxColumnWidths = getMaxColumnWidth(schedule, headers, scheduleRow);
  const columnWidths = headers.map((header, index) => ({
    key: header,
    width: computedMaxColumnWidths[index],
  }));
  setColumnWidths(worksheet, columnWidths);

  // Faz download do arquivo
  await saveToFile(workbook, fileName);
};
