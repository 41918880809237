import { Tag } from 'antd';
import styled from 'styled-components';

export const TagWrapper = styled(Tag)`
  border-radius: 8px;
  padding: 6px 8px;
  width: auto;
  white-space: pre-line;
  border: none;
  background-color: var(--text-header);
  text-transform: capitalize;

  font-size: 0.875rem;
  color: var(--input-placeholder-color);
  line-height: 1.25rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;
