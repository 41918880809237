export enum SendCommandEnum {
  SET_GRAD_OUT = 'setGradOut',
  REBOOT = 'Reboot',
  CLEAN_CALIB = 'cleanCalib',
  GET_LAST_JSON = 'getLastJson',
  SET_ODO_UPDATE = 'odoUpdate',
  SET_HOUR_UPDATE = 'hourUpdate',
  SET_NICKNAME_UPDATE = 'deviceNickname',
  GET_HOUR = 'getHour',
  GET_ODO = 'getOdo',
  GET_NICKNAME = 'getDeviceNickname',
  GET_CAN = 'getCan',
  SET_CAN = 'cfgCan',
  REMOVE_CAN = 'cfgCanRemove',
}
