import { FC } from 'react';
import * as SC from '../../components.styles';
import { Row } from 'antd';
import * as S from './news-container.styles';
import { CarouselNews } from '@app/components/common/CarouselNews/CarouselNews';

export const NewsContainer: FC = ({}) => {
  return (
    <Row>
      <SC.TitleCardHeader>Novidades</SC.TitleCardHeader>
      <S.CarouselNewsContainerWrapper>
        <CarouselNews showDescription={false} />
      </S.CarouselNewsContainerWrapper>
    </Row>
  );
};
