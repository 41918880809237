import { ConfigUpdateModel } from '@app/domain/deviceConfigUpdate/deviceConfigUpdate';
import { ApiServiceBase, IApiServiceBase } from './base/apiServiceBase';

export default interface IDeviceConfigUpdateService extends IApiServiceBase<ConfigUpdateModel, ConfigUpdateModel> {}

export class DeviceConfigUpdateService extends ApiServiceBase<ConfigUpdateModel, ConfigUpdateModel> {
  constructor() {
    super('AtualizacaoConfiguracao');
  }
}
