import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Table } from '@app/components/common/Table/Table';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeader } from '@app/store/slices/headerSlice';
import type { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { notificationController } from '@app/controllers/notificationController';
import { Col, Row } from 'antd';
import { Modal } from '@app/components/common/Modal/Modal';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { IconMore } from '@app/assets/slump-icons';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import Dashboard from '@app/components/dashboard/Dashboard';
import { useNavigate } from 'react-router-dom';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import { InfoCircleOutlined } from '@ant-design/icons';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { formatarData, isDateMatching, isInputMatching } from '@app/utils/utils';
import { ScheduleFactoryService } from '@app/services/scheduleFactoryService';
import { ScheduleFactoryModel } from '@app/domain/scheduleFactory/scheduleFactoryModel';
import { Tag } from '@app/components/common/Tag/Tag';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { hasAccessByRoles } from '@app/controllers/accessController';
import { UserType } from '@app/constants/enums/userType';
import { exportScheduleToExcel } from '@app/pages/factory-schedule/view/exportSchedule';

const scheduleFactoryService = new ScheduleFactoryService();

export const FactoryScheduleDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [schedule, setSchedule] = useState<ScheduleFactoryModel>({} as ScheduleFactoryModel);
  const [schedulesFactory, setSchedulesFactory] = useState<ScheduleFactoryModel[]>();
  const [loading, setLoading] = useState(false);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [tableData, setTableData] = useState<ScheduleFactoryModel[]>();

  const fetchSchedules = useCallback(() => {
    setLoading(true);
    scheduleFactoryService
      .getArray('')
      .then((response) => {
        setSchedulesFactory(response);
        setTableData(response);
      })
      .catch((error) => {
        notificationController.error({
          message: `Erro ao buscar verões de firmware: ${error.message}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleVisualizationClick = (scheduleId: number) => {
    navigate(`/agendamento-versao/${scheduleId}`);
  };

  const handleExportToExcel = () => {
    genericExportToExcel('agendamento_versao', columns, tableData ?? []);
  };

  const handleCancelScheduleClick = useCallback((scheduleFactory: ScheduleFactoryModel) => {
    setSchedule(scheduleFactory);
    setModalCancelVisible(true);
  }, []);

  const renderStatus = useCallback((status: string) => {
    if (status === 'Cancelado') {
      return (
        <Tag color="#FEE9EA" style={{ color: '#620E12' }}>
          {status}
        </Tag>
      );
    }
    if (status === 'Expirado') {
      return (
        <Tag color="#F4EAFF" style={{ color: '#52025F' }}>
          {status}
        </Tag>
      );
    }
    if (status === 'Atualizado')
      return (
        <Tag color="#E9F4EE" style={{ color: '#083F18' }}>
          Atualizado
        </Tag>
      );
    if (status === 'Pendente')
      return (
        <Tag color="#FFF3E6" style={{ color: '#975A16' }}>
          Pendente
        </Tag>
      );
  }, []);

  const handleExportExcelRowClick = (schedule: ScheduleFactoryModel) => {
    setLoading(true);
    scheduleFactoryService
      .get(`${schedule.id}`)
      .then((response) => {
        return exportScheduleToExcel(`agendamento_versao_${schedule.id}`, response).finally(() => setLoading(false));
      })
      .catch((error) => {
        notificationController.error({
          message: `Erro ao buscar verões de firmware: ${error.message}`,
        });
        setLoading(false);
      });
  };

  const columns: ColumnsType<ScheduleFactoryModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: true,
      sorter: (a, b) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
      defaultSortOrder: 'descend',
      width: '90px',
    },
    {
      title: 'Equipamento',
      dataIndex: 'tipoEquipamento',
      showSorterTooltip: true,
      sorter: (a, b) =>
        a.tipoEquipamento && b.tipoEquipamento ? a.tipoEquipamento.localeCompare(b.tipoEquipamento) : 0,
      width: '160px',
    },
    {
      title: 'Modelo',
      dataIndex: 'modelo',
      showSorterTooltip: true,
      sorter: (a, b) => (a.modelo && b.modelo ? a.modelo.localeCompare(b.modelo) : 0),
      width: '115px',
    },
    {
      title: 'Versão de firmware',
      dataIndex: 'versaoFirmware',
      showSorterTooltip: true,
      sorter: (a, b) => (a.versaoFirmware && b.versaoFirmware ? a.versaoFirmware.localeCompare(b.versaoFirmware) : 0),
      width: '185px',
      render: (firmwareVersion: string) => <>{firmwareVersion}</>,
    },
    {
      title: 'Usuário',
      dataIndex: 'usuarioResponsavel',
      showSorterTooltip: true,
      sorter: (a, b) =>
        a.usuarioResponsavel && b.usuarioResponsavel ? a.usuarioResponsavel.localeCompare(b.usuarioResponsavel) : 0,
      width: '150px',
      render: (firmwareVersion: string) => <>{firmwareVersion}</>,
    },
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: true,
      sorter: (a, b) =>
        (a.dataHora ? new Date(a.dataHora).getTime() : Infinity) -
        (b.dataHora ? new Date(b.dataHora).getTime() : Infinity),
      width: '140px',
      render: (dataHora: string) => formatarData(new Date(dataHora)),
    },
    {
      title: 'Horário programado',
      dataIndex: 'dataHoraProgramada',
      showSorterTooltip: true,
      sorter: (a, b) =>
        a.dataHoraProgramada && b.dataHoraProgramada
          ? new Date(a.dataHoraProgramada).getTime() - new Date(b.dataHoraProgramada).getTime()
          : 0,
      width: '195px',
      render: (dataHoraProgramada: string) => formatarData(new Date(dataHoraProgramada)),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => (a.status && b.status ? a.status.localeCompare(b.status) : 0),
      showSorterTooltip: true,
      width: '100px',
      render: (status: string) => renderStatus(status),
    },
    {
      title: '',
      dataIndex: 'id',
      width: '5px',
      showSorterTooltip: false,
      sortDirections: [],
      defaultSortOrder: 'descend',
      render: (_, schedule) => {
        const isAtualizado = schedule.status === 'Atualizado';
        const isCancelado = schedule.status === 'Cancelado';

        const currentDate = new Date();
        const scheduleDate = new Date(schedule.dataHoraProgramada);

        const canCancel = !isAtualizado && !isCancelado && currentDate < scheduleDate;

        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <DropdownTable
              buttonText=""
              iconD={
                <div style={{ color: '#545454' }}>
                  <IconMore />
                </div>
              }
              trigger={['click']}
              placement="bottomRight"
              overlay={
                <Menu>
                  <MenuItem key="edit" onClick={() => handleVisualizationClick(schedule.id)}>
                    <Button type="text">Visualizar</Button>
                  </MenuItem>
                  <MenuItem key="delete" onClick={() => handleExportExcelRowClick(schedule)}>
                    <Button type="text">Exportar excel</Button>
                  </MenuItem>
                  {canCancel && hasAccessByRoles([UserType.FactoryAdmin]) && (
                    <MenuItem key="delete" onClick={() => handleCancelScheduleClick(schedule)}>
                      <Button type="text">Cancelar</Button>
                    </MenuItem>
                  )}
                </Menu>
              }
            ></DropdownTable>
          </div>
        );
      },
    },
  ];

  const handleSearchOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const inputValue = event.currentTarget.value.toLowerCase();

    if (inputValue.length > 0)
      return setTableData(
        schedulesFactory?.filter(
          (item) =>
            isInputMatching(item.id?.toString(), inputValue) ||
            isInputMatching(item.modelo, inputValue) ||
            isInputMatching(item.tipoEquipamento, inputValue) ||
            isInputMatching(item.versaoFirmware, inputValue) ||
            isInputMatching(item.usuarioResponsavel, inputValue) ||
            isDateMatching(item.dataHora, inputValue) ||
            isDateMatching(item.dataHoraProgramada, inputValue) ||
            isInputMatching(item.status, inputValue),
        ),
      );

    return setTableData(schedulesFactory);
  };

  const cancelSchedule = useCallback(() => {
    setLoading(true);
    scheduleFactoryService
      .delete(`${schedule.id}`)
      .then(() => {
        notificationController.success({ message: 'Agendamento deletado com sucesso' });
        fetchSchedules();
        setModalCancelVisible(false);
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
        setModalCancelVisible(false);
      })
      .finally(() => setLoading(false));
  }, [fetchSchedules, schedule.id]);

  useEffect(() => {
    fetchSchedules();
    dispatch(setHeader({ title: 'Atualização de firmware' }));
  }, [dispatch, fetchSchedules]);

  return (
    <>
      <Modal
        title="Cancelar agendamento"
        open={modalCancelVisible}
        onOk={() => cancelSchedule()}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            <span>
              Deseja realmente <strong> cancelar o agendamento? </strong>
            </span>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Editar versão"
        open={modalEditVisible}
        onOk={() => navigate(`/versao-firmware/${schedule.id}`)}
        onCancel={() => setModalEditVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>
            <span>
              Essa versão está sendo utilizada no agendamento, deseja realmente <strong> editar essa versão </strong>
              selecionada?
            </span>
          </Col>
        </Row>
      </Modal>

      <PageTitle>Atualização de firmware</PageTitle>
      <Spinner spinning={loading}>
        <Dashboard
          buttonText={hasAccessByRoles([UserType.FactoryAdmin]) ? 'Nova atualização' : ''}
          handleButtonClick={() => navigate('/agendamento-versao/cadastrar')}
          placeholderTextSearch="Pesquisar"
          handleSearchOnChange={handleSearchOnChange}
          exportToExcel={handleExportToExcel}
          table={<Table columns={columns} dataSource={tableData} scroll={{ x: 'max-content' }} bordered />}
        />
      </Spinner>
    </>
  );
};
