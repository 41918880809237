import styled from 'styled-components';
import { Popover as AntTooltip } from 'antd';

export const Tooltip = styled(AntTooltip)`
  &.ant-popover-title {
    display: none;
  }

  &.ant-popover-inner-content {
    color: red;
    background-color: blue;
    border-radius: 4px;
  }
`;
