import React, { useEffect, useState } from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Col, Row } from 'antd';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { ClientModel } from '@app/domain/client/clientModel';
import { DeviceProfileModel } from '@app/domain/deviceProfile/deviceProfileModel';
import IClientService, { ClientService } from '@app/services/clientService';
import IDeviceProfileService, { DeviceProfileService } from '@app/services/deviceProfileService';
import { notificationController } from '@app/controllers/notificationController';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';

type ClientAndProfileProps = {
  clientSelected: number;
  setClientSelected: React.Dispatch<React.SetStateAction<number>>;
  profileSelected: DeviceProfileModel | null;
  setProfileSelected: React.Dispatch<React.SetStateAction<DeviceProfileModel | null>>;
  clientBlocked: boolean;
  newDevice: FactoryDeviceModel;
};

const clientService: IClientService = new ClientService();
const deviceProfileService: IDeviceProfileService = new DeviceProfileService();

const ClientAndProfileSelector: React.FC<ClientAndProfileProps> = ({
  clientSelected,
  profileSelected,
  setClientSelected,
  setProfileSelected,
  clientBlocked,
  newDevice,
}) => {
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [profiles, setProfiles] = useState<DeviceProfileModel[]>([]);

  const fetchClients = async () => {
    try {
      const clientsResponse = await clientService.getArray('');
      setClients(clientsResponse);
    } catch (error) {
      notificationController.error({ message: 'Erro ao buscar a lista de clientes' });
    }
  };

  const fetchProfile = async () => {
    try {
      const profilesResponse = await deviceProfileService.getByModelAndClient(newDevice.idModelo ?? 0, clientSelected);
      setProfiles(profilesResponse);
    } catch (error) {
      notificationController.error({ message: 'Erro ao buscar a lista de clientes' });
    }
  };

  useEffect(() => {
    if (clientSelected > 0 && newDevice.idModelo) {
      fetchProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSelected, newDevice.idModelo]);

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <>
      <BaseForm layout="vertical">
        <Row gutter={18}>
          <Col xs={24} md={24}>
            <BaseFormInputItem label="Cliente" errorText="Campo obrigatório">
              <Select
                showArrow
                showSearch
                disabled={clientBlocked || clients.length <= 0}
                value={clientSelected > 0 ? clientSelected : null}
                placeholder="Selecione o cliente"
                onChange={(value) => setClientSelected(Number(value))}
                options={clients.map((c) => ({ value: c.id, label: c.razaoSocial }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              ></Select>
            </BaseFormInputItem>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col xs={24} md={24}>
            <BaseFormInputItem label="Perfil de configuração" errorText="Campo obrigatório">
              <Select
                showArrow
                showSearch
                disabled={clientSelected <= 0 || newDevice.idModelo === undefined}
                value={profileSelected !== null ? profileSelected.id : null}
                placeholder="Selecione o perfil de configuração"
                onChange={(value) => setProfileSelected(profiles.find((p) => p.id === Number(value)) ?? null)}
                options={profiles.map((p) => ({ value: p.id, label: p.nome }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              ></Select>
            </BaseFormInputItem>
          </Col>
        </Row>
      </BaseForm>
    </>
  );
};

export default ClientAndProfileSelector;
