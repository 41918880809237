import React from 'react';

import { IDashboardWithoutTable } from '@app/constants/interfaces/Dashboard/IDashboard';
import { HeaderTable } from '../header/components/HeaderTable/HeaderTable';
import * as S from './DashboardWithoutTable.styles';
import { Container } from './DashboardWithoutTable.styles';

const DashboardWithoutTable: React.FC<IDashboardWithoutTable> = ({
  buttonText,
  title,
  filter,
  tags,
  icon,
  handleButtonClick,
  handleSearchOnChange,
  placeholderTextSearch,
  valueSearch,
  exportToExcel,
  children,
}) => {
  return (
    <Container>
      <S.Header>
        <HeaderTable
          buttonText={buttonText}
          title={title}
          filter={filter}
          tags={tags}
          icon={icon}
          valueSearch={valueSearch}
          placeholderTextSearch={placeholderTextSearch}
          handleButtonClick={handleButtonClick}
          handleSearchOnChange={handleSearchOnChange}
          exportToExcel={exportToExcel}
        />
      </S.Header>
      {children}
    </Container>
  );
};

export default DashboardWithoutTable;
