import { IconMore } from '@app/assets/slump-icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Modal } from '@app/components/common/Modal/Modal';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import { Tag } from '@app/components/common/Tag/Tag';
import Dashboard from '@app/components/dashboard/Dashboard';
import env from '../../config/env.config';
import { UserType } from '@app/constants/enums/userType';
import { hasAccessByRole } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import {
  DeviceBlockModel,
  DeviceBlockTypeAction,
  DeviceBlockTypeStatus,
} from '@app/domain/deviceBlock/device-block-model';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IDeviceBlockService, { DeviceBlockService } from '@app/services/deviceService';
import { readUser } from '@app/services/localStorage.service';
import { setHeader } from '@app/store/slices/headerSlice';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as S from './index.styles';
import { Col, Row } from 'antd';

const blockService: IDeviceBlockService = new DeviceBlockService();

export const DeviceBlockDashboard: FC = () => {
  const user = readUser();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalResend, setModalResend] = useState<boolean>(false);
  const [modalApprove, setModalApprove] = useState<boolean>(false);
  const [modalUnblock, setModalUnblock] = useState<boolean>(false);
  const [blocks, setBlocks] = useState<DeviceBlockModel[]>([]);
  const [blocksFiltered, setBlocksFiltered] = useState<DeviceBlockModel[]>([]);
  const [blockSelected, setBlockSelected] = useState<DeviceBlockModel>();

  const handleNewBlockClick = () => navigate('/dispositivos-bloqueio/bloquear');

  useEffect(() => {
    if (id) {
      const block = blocks.find((b) => b.id === Number(id));
      if (block) {
        setBlockSelected(block);
        setModalApprove(true);
      }
    }
  }, [id, blocks]);

  const handleSearchOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const inputValue = event.currentTarget.value.toLowerCase();

    if (inputValue.length > 0) {
      setBlocksFiltered(
        blocks.filter(
          (item) =>
            item.id?.toString().toLowerCase().includes(inputValue) ||
            item.cliente?.toString().toLowerCase().includes(inputValue) ||
            item.modelos?.toLowerCase().includes(inputValue) ||
            item.tentativas?.toString().toLowerCase().includes(inputValue) ||
            item.usuarioResponsavel?.toLowerCase().includes(inputValue) ||
            item.concluidos?.toString().toLowerCase().includes(inputValue) ||
            item.totalDispositivos?.toString().toLowerCase().includes(inputValue) ||
            item.dataHoraAcao?.toString().toLowerCase().includes(inputValue) ||
            item.dataHoraAprovacao?.toString().toLowerCase().includes(inputValue) ||
            item.acao?.toString().toLowerCase().includes(inputValue) ||
            item.statusNome?.toLowerCase().includes(inputValue),
        ),
      );
    } else {
      setBlocksFiltered(blocks);
    }
  };
  const handleResendClick = (block: DeviceBlockModel) => {
    setBlockSelected(block);
    setModalResend(true);
  };
  const handleApproveClick = (block: DeviceBlockModel) => {
    setBlockSelected(block);
    setModalApprove(true);
  };
  const handleUnblockClick = (block: DeviceBlockModel) => {
    setBlockSelected(block);
    setModalUnblock(true);
  };
  const handleApprove = (approved: boolean) => {
    if (blockSelected?.id) {
      setModalApprove(false);
      setLoading(true);

      blockService
        .Aprovar(blockSelected.id, approved)
        .then(() => {
          notificationController.success({
            message: 'Sucesso!',
            description: `${DeviceBlockTypeAction[blockSelected?.acao ?? 1]} ${approved ? 'aprovado' : 'reprovado'}.`,
          });
          fetch();
          if (id) {
            navigate('/dispositivos-bloqueio');
          }
        })
        .catch(() => {
          notificationController.error({
            message: 'Erro!',
            description: `Houve um problema ao ${approved ? 'aprovar' : 'reprovar'} o ${DeviceBlockTypeAction[
              blockSelected?.acao ?? 1
            ].toLowerCase()}.`,
          });
          setLoading(false);
        });
    }
  };
  const handleResend = () => {
    if (blockSelected?.id) {
      setModalResend(false);
      setLoading(true);

      blockService
        .Reenviar(blockSelected.id)
        .then(() => {
          notificationController.success({
            message: 'Sucesso!',
            description: `Comando de ${DeviceBlockTypeAction[blockSelected?.acao ?? 1].toLowerCase()} reenviada.`,
          });
          fetch();
        })
        .catch(() => {
          notificationController.error({
            message: 'Erro!',
            description: `Houve um problema ao reenviar o ${DeviceBlockTypeAction[
              blockSelected?.acao ?? 1
            ].toLowerCase()}`,
          });
          setLoading(false);
        });
    }
  };
  const handleUnblock = () => {
    if (blockSelected?.id) {
      setModalUnblock(false);
      setLoading(true);

      blockService
        .Desbloquear(blockSelected.id)
        .then(() => {
          notificationController.success({
            message: 'Sucesso!',
            description: `Desbloqueio enviado para os dispositivos.`,
          });
          fetch();
        })
        .catch(() => {
          notificationController.error({
            message: 'Erro!',
            description: `Houve um problema ao criar o desbloqueio.`,
          });
          setLoading(false);
        });
    }
  };

  const columns: ColumnsType<DeviceBlockModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: false,
      sortDirections: [],
      align: 'center',
      sorter: (a, b) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
      defaultSortOrder: 'descend',
      width: '8%',
    },
    {
      title: 'Cliente',
      dataIndex: 'cliente',
      ellipsis: true,
      width: '15%',
    },
    {
      title: 'Modelos',
      dataIndex: 'modelos',
      ellipsis: true,
      width: '12%',
    },
    {
      title: 'Tentativas',
      dataIndex: 'tentativas',
      width: '12%',
    },
    {
      title: 'Usuário Reponsável',
      dataIndex: 'usuarioResponsavel',
      width: '18%',
    },
    {
      title: 'Andamento',
      dataIndex: 'concluidos',
      width: '15%',
      render: (_, block: DeviceBlockModel) => `${block.concluidos}/${block.totalDispositivos}`,
    },
    {
      title: 'Data/Hora ação',
      dataIndex: 'dataHoraAcao',
      width: '18%',
      render: (dataHora: Date) => (dataHora ? moment(dataHora).format('DD/MM/YYYY HH:mm') + 'h' : '-'),
    },
    {
      title: 'Data/Hora aprovação',
      dataIndex: 'dataHoraAprovacao',
      width: '20%',
      render: (dataHora: Date) => (dataHora ? moment(dataHora).format('DD/MM/YYYY HH:mm') + 'h' : '-'),
    },
    {
      title: 'Ação',
      dataIndex: 'acao',
      width: '12%',
      render: (acao: number) => DeviceBlockTypeAction[acao],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '20%',
      render: (status: DeviceBlockTypeStatus, block: DeviceBlockModel) => {
        let bgColor = '';
        let txtColor = '';

        switch (status) {
          case DeviceBlockTypeStatus.AguardandoAprovacao:
          case DeviceBlockTypeStatus.Pendente:
            bgColor = '#FEF7E7';
            txtColor = '#644508';
            break;
          case DeviceBlockTypeStatus.Parcial:
          case DeviceBlockTypeStatus.Reprovado:
            bgColor = '#FEE9EA';
            txtColor = '#620E12';
            break;
          case DeviceBlockTypeStatus.Concluido:
            bgColor = '#E9F4EE';
            txtColor = '#083F18';
            break;
          case DeviceBlockTypeStatus.Expirado:
            bgColor = '#F4EAFF';
            txtColor = '#52025F';
            break;
          default:
            break;
        }

        return (
          <Tag color={bgColor} style={{ color: txtColor, width: '100%', textAlign: 'center' }}>
            {block.statusNome}
          </Tag>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      width: '7%',
      render: (id: number, block: DeviceBlockModel) => {
        return {
          props: {
            style: { background: 'white' },
          },
          children: (
            <DropdownTable
              buttonText=""
              iconD={
                <div style={{ color: '#545454', display: 'flex', alignItems: 'center' }}>
                  <IconMore />
                </div>
              }
              trigger={['click']}
              align={{ offset: [40, -10] }}
              overlay={
                <Menu>
                  <MenuItem key="view" onClick={() => navigate(`visualizar/${id}`)}>
                    <Button type="text">Visualizar</Button>
                  </MenuItem>
                  {((block.status == DeviceBlockTypeStatus.AguardandoAprovacao &&
                    (env.ENVIRONMENT == 'loc' || env.ENVIRONMENT == 'dev') &&
                    (user?.userName == 'rodrigo.magalhaes' || user?.userName == 'usuario.fabrica')) ||
                    user?.userName == 'rodrigo.magalhaes') && (
                    <>
                      <MenuItem key="view">
                        <Button type="text" onClick={() => handleApproveClick(block)}>
                          Aprovar/Reprovar
                        </Button>
                      </MenuItem>
                    </>
                  )}
                  {block.status != DeviceBlockTypeStatus.AguardandoAprovacao &&
                    block.acao == DeviceBlockTypeAction.Bloqueio && (
                      <MenuItem key="view">
                        <Button type="text" onClick={() => handleUnblockClick(block)}>
                          Desbloquear
                        </Button>
                      </MenuItem>
                    )}
                  {block.status == DeviceBlockTypeStatus.Expirado && (
                    <MenuItem key="view">
                      <Button type="text" onClick={() => handleResendClick(block)}>
                        Reenviar
                      </Button>
                    </MenuItem>
                  )}
                </Menu>
              }
            />
          ),
        };
      },
    },
  ];

  const fetch = useCallback(async () => {
    setLoading(true);

    blockService
      .getArray('')
      .then((res) => {
        setBlocks(res);
        setLoading(false);
      })
      .catch(() => {
        notificationController.error({
          message: 'Erro!',
          description: 'Erro ao buscar os dados de bloqueios de dispositivos.',
        });
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(setHeader({ title: `Bloqueio de dispositivos` }));
    fetch();
  }, [dispatch, fetch]);

  useEffect(() => {
    setBlocksFiltered(blocks);
  }, [blocks]);
  return (
    <>
      <Spinner spinning={loading}>
        <Modal
          open={modalApprove}
          title={`Aprovar ${DeviceBlockTypeAction[blockSelected?.acao ?? 1].toLowerCase()}`}
          okText="Aprovar"
          cancelText="Reprovar"
          onOk={() => handleApprove(true)}
          onCancel={() => setModalApprove(false)}
          footer={[
            <Row key={id} justify="end" gutter={16}>
              <Col>
                <Button key="disapprove" type="ghost" onClick={() => handleApprove(false)}>
                  Reprovar
                </Button>
              </Col>
              <Col>
                <Button key="approve" type="primary" onClick={() => handleApprove(true)}>
                  Aprovar
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <S.ExclamationIcon />
          Deseja realmente <strong>aprovar ou reprovar</strong> o bloqueio dos dispositivos?
        </Modal>
        <Modal
          open={modalResend}
          title="Reenviar"
          okText="Sim"
          cancelText="Não"
          onOk={() => handleResend()}
          onCancel={() => setModalResend(false)}
        >
          <S.ExclamationIcon />
          Deseja realmente <strong>reenviar</strong> o {DeviceBlockTypeAction[blockSelected?.acao ?? 1].toLowerCase()}{' '}
          dos dispositivos?
        </Modal>
        <Modal
          open={modalUnblock}
          title="Desbloquear dispositivos"
          okText="Sim"
          cancelText="Não"
          onOk={() => handleUnblock()}
          onCancel={() => setModalUnblock(false)}
          size="large"
        >
          <S.ExclamationIcon />
          Deseja realmente <strong>solicitar a aprovação para desbloquear os dispositivos?</strong>
        </Modal>
        <Dashboard
          buttonText={hasAccessByRole(UserType.FactoryAdmin) ? 'Novo bloqueio' : ''}
          handleButtonClick={handleNewBlockClick}
          placeholderTextSearch="Pesquisar"
          handleSearchOnChange={handleSearchOnChange}
          table={<Table columns={columns} dataSource={blocksFiltered} bordered />}
        />
      </Spinner>
    </>
  );
};
