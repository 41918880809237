import React, { FC, useCallback } from 'react';

import { Description } from '@app/components/common/Description/Description';
import { FenceModel, PathPoint } from '@app/domain/fence/fenceModel';
import { Table } from '@app/components/common/Table/Table';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { IconMore } from '@app/assets/slump-icons';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { Button } from '@app/components/common/buttons/Button/Button';
import { notificationController } from '@app/controllers/notificationController';
import { ColumnsType } from 'antd/lib/table';
import { Tag } from '@app/components/common/Tag/Tag';
import { useNavigate } from 'react-router-dom';

interface FencesDashboardProps {
  fences: FenceModel[];
  setFences: React.Dispatch<React.SetStateAction<FenceModel[]>>;
  maxFencesQuantity: number;
  isCloning?: boolean;
}

const FencesDashboard: FC<FencesDashboardProps> = ({ fences, setFences, maxFencesQuantity, isCloning }) => {
  const navigate = useNavigate();

  const handleActivateDeactivateRowClick = useCallback(
    (id: number) => {
      setFences((prevState) => {
        const fence = prevState.find((fence) => fence.id === id);
        if (fence) {
          fence.ativo = !fence.ativo;
        }
        return [...prevState];
      });
    },
    [setFences],
  );

  const handleVisualizationRowClick = (id: number) => {
    navigate(`/cerca/visualizar/${id}`);
  };

  const handleDeleteRowClick = (fenceName: FenceModel['nome']) => {
    setFences((prevState) => {
      const fence = prevState.find((fence) => fence.nome === fenceName);
      if (fence) {
        prevState.splice(prevState.indexOf(fence), 1);
      }
      return [...prevState];
    });
    notificationController.success({ message: 'Cerca excluída com sucesso' });
  };

  const renderButtonActions = (_fence: FenceModel) => {
    if (isCloning)
      return (
        <>
          <MenuItem key="delete-clone" onClick={() => handleDeleteRowClick(_fence.nome)}>
            <Button type="text">Excluir</Button>
          </MenuItem>
        </>
      );

    if (_fence.id)
      return (
        <>
          <MenuItem key="ativar-desativar" onClick={() => handleActivateDeactivateRowClick(_fence.id || 0)}>
            <Button type="text">{_fence.ativo ? 'Desativar' : 'Ativar'}</Button>
          </MenuItem>
          <MenuItem key="view" onClick={() => handleVisualizationRowClick(_fence.id || 0)}>
            <Button type="text">Visualizar</Button>
          </MenuItem>
        </>
      );

    return (
      <MenuItem key="delete" onClick={() => handleDeleteRowClick(_fence.nome)}>
        <Button type="text">Excluir</Button>
      </MenuItem>
    );
  };

  const columns: ColumnsType<FenceModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => <>{id || ' - '}</>,
    },
    {
      title: 'Cerca',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Tipo da cerca',
      dataIndex: 'tipoCerca',
      key: 'idTipoCerca',
    },
    {
      title: 'Quantidade de pontos',
      dataIndex: 'pontos',
      key: 'pontos',
      render: (pontos: PathPoint[]) => <>{pontos?.length > 0 ? pontos.length : ' - '}</>,
    },
    {
      title: 'Raio',
      dataIndex: 'raio',
      key: 'raio',
      render: (raio: FenceModel['raio']) => <>{raio ? `${raio}m` : ' - '}</>,
    },
    {
      title: 'Largura da banda',
      dataIndex: 'largura',
      key: 'largura',
      render: (largura: FenceModel['largura']) => <>{largura ? `${largura}m` : ' - '}</>,
    },
    {
      title: 'Evento Entrada/Saída',
      dataIndex: 'eventos',
      key: 'eventoEntradaSaida',
      render: (eventos: FenceModel['eventos']) => <>{!!eventos?.entradaSaida ? 'Sim' : 'Não'}</>,
    },
    {
      title: 'Evento Limite de velocidade',
      dataIndex: 'eventos',
      key: 'eventoLimiteVelocidade',
      render: (eventos: FenceModel['eventos']) => <>{!!eventos?.limiteVelocidade ? 'Sim' : 'Não'}</>,
    },
    {
      title: 'Ativo',
      dataIndex: 'ativo',
      key: 'ativo',
      render: (ativo: boolean) => {
        return (
          <>
            {ativo ? (
              <Tag color="#E9F4EE" style={{ color: '#083F18', width: '70px', textAlign: 'center' }}>
                Ativo
              </Tag>
            ) : (
              <Tag color="#FEE9EA" style={{ color: '#620E12', width: '70px', textAlign: 'center' }}>
                Inativo
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      showSorterTooltip: false,
      sortDirections: [],
      defaultSortOrder: 'descend',
      render: (_, fence) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <DropdownTable
              buttonText=""
              iconD={
                <div style={{ color: '#545454' }}>
                  <IconMore />
                </div>
              }
              trigger={['click']}
              placement="bottomRight"
              overlay={<Menu>{renderButtonActions(fence)}</Menu>}
            ></DropdownTable>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Description title="Cercas cadastradas" subtitle={`Permitido cadastrar no máximo ${maxFencesQuantity} cercas.`} />
      <Table
        bordered={true}
        columns={columns}
        dataSource={fences}
        loading={false}
        scroll={{ x: 'max-content' }}
        style={{
          width: '100%',
          padding: 0,
        }}
      />
    </>
  );
};

export default FencesDashboard;
