import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Description } from '@app/components/common/Description/Description';
import { Col, Divider, Row } from 'antd';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setFooter } from '@app/store/slices/footerSlice';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { notificationController } from '@app/controllers/notificationController';
import IDeviceBlockService, { DeviceBlockService } from '@app/services/deviceService';
import { DeviceBlockModel, DeviceBlockModelDevices } from '@app/domain/deviceBlock/device-block-model';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './create.styles';
import { CardSelectClient } from './components/CardSelectClient/CardSelectClient';
import { CardAddDevices } from './components/CardAddDevices/CardAddDevices';
import { BlockDeviceTable } from './components/BlockDeviceTable/BlockDeviceTable';
import { ModalAddDevices } from './components/modal/modal-add-devices';
import IFactoryDeviceService, { FactoryDeviceService } from '@app/services/factoryDeviceService';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from '@app/components/common/Modal/Modal';

const blockService: IDeviceBlockService = new DeviceBlockService();
const deviceService: IFactoryDeviceService = new FactoryDeviceService();

export const DeviceBlockCreate: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [block, setBlock] = useState<DeviceBlockModel>({} as DeviceBlockModel);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [devices, setDevices] = useState<FactoryDeviceModel[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<DeviceBlockModelDevices[]>([]);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [modalRequestVisible, setModalRequestVisible] = useState(false);
  const [idCliente, setIdCliente] = useState<number | undefined>(undefined);
  const [isEdit, setIsEdit] = useState(false);

  const fetchDevices = useCallback(async () => {
    try {
      setLoading(true);
      const response = await deviceService.getArray('');
      setDevices(response);
      setLoading(false);
    } catch (error) {
      notificationController.error({ message: 'Erro!', description: 'Houve um erro ao buscar os dispositivos' });
      setLoading(false);
    }
  }, []);

  const handleBlock = async () => {
    setModalRequestVisible(true);
  };

  const handleEditSelected = () => {
    setModalVisible(true);
    setIsEdit(true);
  };

  const confirmBlock = async () => {
    try {
      setLoading(true);
      const response = await blockService.api.post('', {
        ...block,
        idCliente: idCliente,
        idsDipositivo: selectedDevices.map((d) => d.idDispositivo),
      });
      setBlock(response.data);
      notificationController.success({ message: 'Agendamento de bloqueio dos dispositivos realizado com sucesso!' });
      setLoading(false);
      navigate('/dispositivos-bloqueio');
    } catch (error) {
      setLoading(false);
      console.error('error', error);
      notificationController.error({
        message: 'Erro!',
        description: 'Falha ao realizar agendamento do bloqueio dos dispositivos. Tente novamente mais tarde!',
      });
    }
  };

  useEffect(() => {
    dispatch(setFooter({}));
    dispatch(
      setHeaderRegister({
        title: 'Novo bloqueio de dispositivos',
        handleBackClick: () => setModalCancelVisible(true),
      }),
    );
    fetchDevices();
  }, [dispatch, navigate, fetchDevices]);

  useEffect(() => {
    dispatch(
      setFooter({
        confirmButtonText: 'Bloquear dispositivos',
        cancelButtonText: 'Cancelar',
        confirmButtonDisabled: selectedDevices.length === 0,
        handleCancelButtonClick: () => setModalCancelVisible(true),
        handleConfirmButtonClick: handleBlock,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedDevices]);

  return (
    <>
      <ModalAddDevices
        setIdsDevice={setSelectedDevices}
        devices={devices}
        visible={modalVisible}
        setVisible={setModalVisible}
        blockDevices={!isEdit ? block.dispositivos ?? [] : selectedDevices}
        idClient={idCliente ?? 0}
        isEdit={isEdit}
      />
      <Modal
        title={`Solicitar bloqueio`}
        open={modalRequestVisible}
        onOk={confirmBlock}
        onCancel={() => setModalRequestVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
        Deseja realmente <strong>&nbsp;solicitar a aprovação para bloquear os dispositivos?</strong>
      </Modal>
      <Modal
        title={`Cancelar agendamento`}
        open={modalCancelVisible}
        onOk={() => navigate('/dispositivos-bloqueio')}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            Deseja realmente <strong>&nbsp;cancelar o bloqueio de equipamentos?</strong>
          </Col>
        </Row>
      </Modal>

      <BaseForm layout="vertical" style={{ width: '100%' }}>
        <Spinner spinning={loading}>
          <Row>
            <S.ContainerNewDevice>
              <CardSelectClient onSelectClient={setIdCliente} selectedClientId={idCliente} />
              <CardAddDevices setModalVisible={setModalVisible} idCliente={idCliente ?? 0} />
            </S.ContainerNewDevice>
            <Divider orientation="center" type="vertical" style={{ backgroundColor: '#D9D9D9', height: '70vh' }} />
            <S.ContainerListDevices>
              <Description
                title={`Dispositivos`}
                subtitle={'Após o cadastro dos agendamentos, clique em bloquear dispositivos para finalizar'}
              >
                <BlockDeviceTable devices={selectedDevices} onEditSelected={handleEditSelected} />
              </Description>
            </S.ContainerListDevices>
          </Row>
        </Spinner>
      </BaseForm>
    </>
  );
};
