import { TagGroupModel } from '@app/domain/tagGroup/TagGroupModel';
import { ApiServiceBase, IApiServiceBase } from './base/apiServiceBase';

export default interface ITagsGroupService extends IApiServiceBase<TagGroupModel, TagGroupModel> {}

export class TagsGroupService extends ApiServiceBase<TagGroupModel, TagGroupModel> {
  constructor() {
    super('TagGrupo');
  }
}
