import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 2rem 2rem;
  flex: 0 0 auto;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  height: 100%;
`;

export const ContainerBorderRight = styled.div`
  padding: 0 2rem 2rem;
  flex: 0 0 auto;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  border-right: 1px solid var(--disabled-bg-color);
  height: 100%;
`;
