import styled from 'styled-components';
import { Collapse as AntdCollapse } from 'antd';

export const Collapse = styled(AntdCollapse)`
  background-color: #f2f2f2;
  //padding-left: -16px;
  padding: 10px;

  && .ant-collapse {
    border: none;
  }

  && .ant-collapse-header-text {
    padding-left: 8px;
    color: var(--Neutral-Gray-Gray800, #383838);
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const Panel = styled(AntdCollapse.Panel)``;

export const CollapseHeaderName = styled.span`
  color: var(--Neutral-Gray-Gray800, #383838);
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
