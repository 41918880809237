import styled from 'styled-components';
import { media } from '@app/styles/themes/constants';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  /* padding: 3.75rem 2.25rem; */

  background-color: var(--background-color);

  @media only screen and ${media.md} {
    padding: 2.5rem 5.25rem 5.25rem;
  }

  @media only screen and ${media.xl} {
    flex-direction: row-reverse;
    justify-content: center;
    padding: 12.75rem 6.75rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin-right: 1rem;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 37rem;
  margin-left: 5.125rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 3.5rem;

  @media only screen and ${media.md} {
    align-items: center;
    margin-top: 0rem;
  }
`;

export const Title = styled.span`
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 80px;
  text-align: start;
  color: var(--primary-color);

  @media only screen and ${media.md} {
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }

  @media only screen and ${media.xl} {
    font-size: 4rem;
    margin-bottom: 0.5rem;
  }
`;
export const Subtitle = styled.span`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0rem;
  line-height: 40px;

  color: var(--input-placeholder-color);

  @media only screen and ${media.md} {
    font-size: 1.5rem;
    margin-bottom: 1.75rem;
  }

  @media only screen and ${media.xl} {
    font-size: 2rem;
    margin-bottom: 0.625rem;
  }
`;

export const Text = styled.span`
  font-size: 2.5rem;
  margin-bottom: 1.25rem;
  font-weight: 800;
  letter-spacing: 0.025em;
  color: var(--text-label);

  @media only screen and ${media.md} {
    font-size: 2rem;
    margin-bottom: 1.45rem;
  }

  @media only screen and ${media.xl} {
    font-size: 2.5rem;
    margin-bottom: 1.8rem;
  }
`;

export const TextLink = styled.span`
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 23px;
  color: var(--input-placeholder-color);

  margin-bottom: 0.125rem;
  margin-left: 4rem;

  @media only screen and ${media.md} {
    font-size: 1rem;
    margin-bottom: 0.125rem;
  }

  @media only screen and ${media.xl} {
    font-size: 1.125rem;
    margin-bottom: 0.125rem;
  }
`;
