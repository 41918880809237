import React from 'react';
import * as S from './Error.styles';
import { Link } from 'react-router-dom';

interface ErrorProps {
  img?: string;
  msg: string;
  title: string;
  subtitle: string;
  textLink: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Error: React.FC<ErrorProps> = ({ img, msg, title, subtitle, textLink }) => {
  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <S.ContainerWrapper>
          <S.Title>{title}</S.Title>
          <S.Subtitle>{subtitle}</S.Subtitle>
          <S.Text>{msg}</S.Text>
        </S.ContainerWrapper>
        {/*TODO make common component */}
        <S.Container>
          <S.TextLink>{textLink}</S.TextLink>
          <Link to="#" style={{ marginLeft: ' 4rem' }}>
            suporte@jmak.com.br
          </Link>
        </S.Container>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};
