import { useAppDispatch } from '@app/hooks/reduxHooks';
import { readUser } from '@app/services/localStorage.service';
import { setHeader } from '@app/store/slices/headerSlice';
import { formatUsername } from '@app/utils/utils';
import React, { FC, useEffect, useState } from 'react';
import * as S from './index.styles';
import { Col, Row } from 'antd';
import { UpdatesContainer } from './components/containers/updates/updates-container';
import { NewsContainer } from './components/containers/news/news-container';
import { PerformanceContainer } from './components/containers/performance/performance-container';
import { EquipmentsContainer } from './components/containers/equipments/equipments-container';
import { ModalLogPerformanceHistory } from './components/modals/modal-log-performance-history';
import { UserType } from '@app/constants/enums/userType';

export const HomeDashboard: FC = () => {
  const user = readUser();
  const dispatch = useAppDispatch();

  const [filter, setFilter] = useState<number | undefined>();
  const [modalPerformaceConfig, setModalPerformaceConfig] = useState({
    isOpen: false,
    id: 0,
  });
  useEffect(() => {
    dispatch(setHeader({ title: `Olá, ${formatUsername(user?.userName ?? '')}!` }));
  }, [dispatch, user]);

  return (
    <S.HomeContainer>
      <ModalLogPerformanceHistory
        open={modalPerformaceConfig.isOpen}
        setOpen={() => setModalPerformaceConfig({ ...modalPerformaceConfig, isOpen: !modalPerformaceConfig.isOpen })}
        id={modalPerformaceConfig.id}
      />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={11}>
              <UpdatesContainer />
            </Col>
            <Col xs={24} md={13}>
              <NewsContainer />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={11}>
              {user?.type === UserType.Client ? (
                <></>
              ) : (
                <PerformanceContainer filterSelected={filter} setModalPerformaceConfig={setModalPerformaceConfig} />
              )}
            </Col>
            <Col xs={24} md={13}>
              <EquipmentsContainer setIdFilterSelected={setFilter} />
            </Col>
          </Row>
        </Col>
      </Row>
    </S.HomeContainer>
  );
};
