import { SendCommandModelRequest, SendCommandModelResponse } from '@app/domain/sendCommand/sendCommandModel';
import { ApiServiceBase, IApiServiceBase } from './base/apiServiceBase';

export default interface ISendCommandService
  extends IApiServiceBase<SendCommandModelRequest, SendCommandModelResponse> {
  getPowerCutStatus(deviceImei: string): Promise<number>;
  getNickname(deviceImei: string): Promise<string>;
  sendCommand(endpoint: string, entity: SendCommandModelRequest): Promise<SendCommandModelResponse>;
}

export class SendCommandService extends ApiServiceBase<SendCommandModelRequest, SendCommandModelResponse> {
  constructor() {
    super('EnviarComando');
  }

  async getPowerCutStatus(deviceImei: string): Promise<number> {
    return (await this.api.get(`estado-corta-corrente/${deviceImei}`))?.data;
  }

  async getNickname(deviceImei: string): Promise<string> {
    return (await this.api.get(`apelido-atual/${deviceImei}`))?.data;
  }

  async sendCommand(endpoint: string, entity: SendCommandModelRequest): Promise<SendCommandModelResponse> {
    return (await this.api.post(endpoint, entity, { timeout: 20000, timeoutErrorMessage: 'Timeout' }))?.data;
  }
}
