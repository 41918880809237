import { Col, Row } from 'antd';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType } from 'antd/lib/table/Table';
import { ViewFirmwareUpdateHistoryModel } from '@app/domain/device/viewFirmwareUpdateHistoryModel';
import * as S from './viewDevice.styles';
import moment from 'moment';
import { Tag } from '@app/components/common/Tag/Tag';

interface IViewFirmwareUpdateHistory {
  updateFirmwareHistory: ViewFirmwareUpdateHistoryModel[];
}

export const ViewFirmwareUpdateHistory: React.FC<IViewFirmwareUpdateHistory> = ({ updateFirmwareHistory }) => {
  const columns: ColumnsType<ViewFirmwareUpdateHistoryModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: false,
      width: '5%',
    },
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      width: '12%',
      render: (_, updateFirmware) =>
        updateFirmware.dataHora ? moment(updateFirmware.dataHora).format('DD/MM/yyyy HH:mm') : '-',
    },
    {
      title: 'Usuário',
      dataIndex: 'usuarioResponsavel',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: 'Versão anterior',
      dataIndex: 'versaoAnterior',
      showSorterTooltip: false,
      width: '15%',
      render: (_, updateFirmware) => updateFirmware.versaoAnterior ?? '-',
    },
    {
      title: 'Versão atualizada',
      dataIndex: 'versaoAtualizada',
      showSorterTooltip: false,
      width: '18%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      showSorterTooltip: false,
      width: '13%',
      render: (_, updateFirmware) => {
        if (updateFirmware.status === 'Pendente') {
          return (
            <Tag color="#FEF7E7" style={{ color: '#644508', width: 'auto', textAlign: 'center' }}>
              {updateFirmware.status}
            </Tag>
          );
        } else if (updateFirmware.status === 'Atualizado') {
          return (
            <Tag color="#E9F4EE" style={{ color: '#083F18', width: 'auto', textAlign: 'center' }}>
              {updateFirmware.status}
            </Tag>
          );
        } else {
          return '-';
        }
      },
    },
  ];

  return (
    <>
      {updateFirmwareHistory.length > 0 ? (
        <S.Container>
          <h1>Histórico</h1>
          <Table columns={columns} dataSource={updateFirmwareHistory} scroll={{ x: 800 }} bordered />
        </S.Container>
      ) : (
        <Row justify="center" align="middle">
          <Col xs={24} md={24} style={{ color: '#707070', fontSize: '1rem', fontWeight: 700, textAlign: 'center' }}>
            Nenhuma atualização de firmware realizada
          </Col>
        </Row>
      )}
    </>
  );
};
