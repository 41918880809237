/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import * as S from './MainSider/MainSider.styles';
import { useResponsive } from 'hooks/useResponsive';
import { ReactComponent as WhiteLogo } from '@app/assets/horizontal-white-logo.svg';
import { ReactComponent as WhiteSymbol } from '@app/assets/white-symbol.svg';
import { RightOutlined } from '@ant-design/icons';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
  const { tabletOnly, isDesktop } = useResponsive();

  return (
    <S.SiderLogoDiv>
      {/* TODO: ajustar logo do menu lateral */}

      <S.SiderLogoLink onClick={toggleSider} $isCollapsed={isSiderCollapsed}>
        {/* {isSiderCollapsed && tabletOnly ? <WhiteSymbol /> : <WhiteLogo />} */}
        {isSiderCollapsed ? (
          <WhiteSymbol style={{ marginLeft: '1rem' }} />
        ) : (
          <WhiteLogo style={{ marginLeft: '1.5rem' }} />
        )}
      </S.SiderLogoLink>

      <S.CollapseButton
        size="small"
        $isCollapsed={isSiderCollapsed}
        icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
        onClick={toggleSider}
      />
    </S.SiderLogoDiv>
  );
};
