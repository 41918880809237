import styled from 'styled-components';

export const ReadDevice = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0 1.5rem 0;
`;

export const BottomInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // Two items side by side
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const InfoTitle = styled.div`
  color: var(--neutral-gray-gray-500, #8c8c8c);
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const InfoValue = styled.div`
  color: var(--neutral-gray-gray-700, #545454);
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ConnectedContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
