import React from 'react';
import * as S from './GoogleMapItems.styles';

type PointCountProps = {
  maxValue: number;
  currentValue: number;
};

export const PointCount: React.FC<PointCountProps> = ({ maxValue, currentValue }) => {
  return (
    <S.PointCounterWrapper>
      <S.PointCounterLabelLeft>{currentValue}</S.PointCounterLabelLeft>
      <S.PointCounterLabelRight>{maxValue}</S.PointCounterLabelRight>
    </S.PointCounterWrapper>
  );
};
