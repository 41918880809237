import { Table } from '@app/components/common/Table/Table';
import { Tag } from '@app/components/common/Tag/Tag';
import { Col } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    margin-left: 1rem;
    color: var(--tooltip-background);
    font-family: 'Mulish';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const TableWithoutPagination = styled(Table)`
  .ant-pagination {
    visibility: hidden;
    min-height: 0;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 7rem;

  .ant-pagination {
    visibility: visible;
    min-height: none;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RedTag = styled(Tag)`
  width: 100%;
  text-align: center;
  color: #620e12;
  background-color: #fee9ea;
`;

export const TableNoContentCol = styled(Col)`
  color: #707070;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
`;
