import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import * as S from './modal-send-email.styles';
import ISystemParameterService, { SystemParameterService } from '@app/services/systemParameterService';
import { Col, Row } from 'antd';
import {
  CardInfoContainer,
  CardInfoProperty,
  CardInfoTitle,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Select } from '@app/components/common/selects/Select/Select';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormItem } from '@app/components/common/forms/components/BaseFormItem/BaseFormItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { notificationController } from '@app/controllers/notificationController';
import { generateLogDevicePdfFile } from '../../functions/home-functions';
import { DeviceLogDetailedClientModel } from '@app/domain/deviceLog/DeviceLogModel';
import IDeviceLogService, { DeviceLogService } from '@app/services/deviceLogService';

const logService: IDeviceLogService = new DeviceLogService();
const parameterService: ISystemParameterService = new SystemParameterService();

interface ModalSendSupportEmailProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  logPerformance?: DeviceLogDetailedClientModel;
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'link',
  'color',
  'image',
  'background',
  'align',
];
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline'],
    [{ align: ['right', 'center', 'justify'] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    [{ color: ['#263668', '#8C8C8C', '#707070', '#545454'] }],
  ],
};

export const ModalSendSupportEmail: FC<ModalSendSupportEmailProps> = ({ open, setOpen, logPerformance }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [body, setBody] = useState<string>();
  const [subject, setSubject] = useState<string>();
  const [emailSelected, setEmailSelected] = useState<string>();

  const handleSend = async () => {
    if (!body) {
      notificationController.error({
        message: 'Erro!',
        description: 'Corpo do e-mail não pode ser vazio.',
      });
      return;
    }

    if (!emailSelected) {
      notificationController.error({
        message: 'Erro!',
        description: 'E-mail destinátario não pode ser vazio.',
      });
      return;
    }

    if (logPerformance?.cliente.razaoSocial && logPerformance?.logs) {
      const reportPdfFile = await generateLogDevicePdfFile(logPerformance.cliente.razaoSocial, logPerformance.logs);
      setLoading(true);

      logService
        .sendEmailDeviceLog(
          {
            cliente: logPerformance?.cliente.razaoSocial,
            corpo: body,
            emailDestinatario: emailSelected,
          },
          reportPdfFile,
        )
        .then(() => {
          notificationController.success({ message: 'Sucesso!', description: 'E-mail enviado.' });
          setOpen(false);
        })
        .catch(() => {
          setLoading(false);
          notificationController.error({
            message: 'Erro!',
            description: 'Não foi possível enviar o e-mail para o cliente.',
          });
        });
    }
  };

  const fetch = useCallback(async (cliente: string) => {
    const subjectResponse = await parameterService.get(`obter-por-chave/assuntoEmailSuporteLogDispositivo`);
    const bodyResponse = await parameterService.get(`obter-por-chave/corpoEmailSuporteLogDispositivo`);
    setSubject(subjectResponse.valor);
    setBody(bodyResponse.valor.replace('[cliente]', cliente));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (logPerformance?.cliente.razaoSocial && open) fetch(logPerformance?.cliente.razaoSocial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, logPerformance?.cliente.razaoSocial]);

  useEffect(() => {
    setEmailSelected(logPerformance?.cliente.contatos?.[0].email);
  }, [logPerformance?.cliente.contatos]);

  return (
    <S.ModalSendSupport
      okText="Enviar"
      onOk={handleSend}
      width={'60%'}
      open={open}
      onCancel={() => setOpen(!open)}
      title="Enviar e-mail"
      confirmLoading={loading}
    >
      <CardInfoContainer>
        <Row>
          <CardInfoTitle>Informações do cliente</CardInfoTitle>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={8}>
            <CardInfoProperty>Razão social</CardInfoProperty>
            <CardInfoValue>{logPerformance?.cliente.razaoSocial ?? '-'}</CardInfoValue>
          </Col>
          <Col xs={24} md={6}>
            <CardInfoProperty>CNPJ</CardInfoProperty>
            <CardInfoValue>{logPerformance?.cliente.cnpj ?? '-'}</CardInfoValue>
          </Col>
          <Col xs={24} md={4}>
            <CardInfoProperty>Telefone</CardInfoProperty>
            <CardInfoValue>{logPerformance?.cliente.contatos?.[0].telefone ?? '-'}</CardInfoValue>
          </Col>
          <Col xs={24} md={4}>
            <CardInfoProperty>E-mail</CardInfoProperty>
            <CardInfoValue>{logPerformance?.cliente.contatos?.[0].email ?? '-'}</CardInfoValue>
          </Col>
          <Col xs={24} md={4}>
            <CardInfoProperty>CEP</CardInfoProperty>
            <CardInfoValue>{logPerformance?.cliente.cep ?? '-'}</CardInfoValue>
          </Col>
          <Col xs={24} md={10}>
            <CardInfoProperty>Endereço</CardInfoProperty>
            <CardInfoValue>{logPerformance?.cliente.logradouro ?? '-'}</CardInfoValue>
          </Col>
          <Col xs={24} md={4}>
            <CardInfoProperty>Número</CardInfoProperty>
            <CardInfoValue>{logPerformance?.cliente.numero ?? '-'}</CardInfoValue>
          </Col>
          <Col xs={24} md={4}>
            <CardInfoProperty>Complemento</CardInfoProperty>
            <CardInfoValue>{logPerformance?.cliente.complemento ?? '-'}</CardInfoValue>
          </Col>
        </Row>
      </CardInfoContainer>
      <Row>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Row>
            <Col span={24}>
              <BaseFormItem label="E-mail">
                <Select
                  value={emailSelected}
                  onChange={(value) => setEmailSelected(value as string)}
                  options={logPerformance?.cliente.contatos?.map((c) => ({ label: c.email, value: c.email }))}
                />
              </BaseFormItem>
            </Col>
            <Col span={24}>
              <BaseFormItem label="Assunto">
                <Input type="text" value={subject} disabled />
              </BaseFormItem>
            </Col>
            <Col span={24}>
              <BaseFormItem label="Mensagem">
                <ReactQuill theme="snow" value={body} onChange={setBody} modules={modules} formats={formats} />
              </BaseFormItem>
            </Col>
          </Row>
        </BaseForm>
      </Row>
    </S.ModalSendSupport>
  );
};
