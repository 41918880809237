import React, { useCallback, useEffect, useState } from 'react';
import { Description } from '@app/components/common/Description/Description';
import {
  ConfigUpdateModel,
  ConfigUpdatePropertyModel,
  PropertyConfigUpdateGroupedData,
} from '@app/domain/deviceConfigUpdate/deviceConfigUpdate';
import { Col, Row } from 'antd';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { Button } from '@app/components/common/buttons/Button/Button';
import { notificationController } from '@app/controllers/notificationController';
import { groupBy } from 'lodash';
import { ModuleModel } from '@app/domain/module/moduleModel';
import { CardTitle } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import IDeviceConfigUpdateService, { DeviceConfigUpdateService } from '@app/services/deviceConfigUpdateService';
import { DeviceProfilePropertyModel, PropertyValue } from '@app/domain/deviceProfile/deviceProfileModel';
import ModulesComponent from '../Modules/ModulesComponent';
import { readUser } from '@app/services/localStorage.service';
import { useParams } from 'react-router-dom';
import * as S from './create-step-two.styles';
import IFirmwareGroupService, { FirmwareGroupService } from '@app/services/firmwareGroupService';
import { SendParameter, setParameters } from '@app/store/slices/sendParametersSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';

interface ICreateStepTwo {
  deviceConfigUpdate: ConfigUpdateModel;
  setDeviceConfigUpdate: React.Dispatch<React.SetStateAction<ConfigUpdateModel>>;
}

const firmwareGroupService: IFirmwareGroupService = new FirmwareGroupService();
const configUpdateService: IDeviceConfigUpdateService = new DeviceConfigUpdateService();

export const CreateDeviceConfigUpdateStepTwo: React.FC<ICreateStepTwo> = ({
  deviceConfigUpdate,
  setDeviceConfigUpdate,
}) => {
  const { id } = useParams();
  const user = readUser();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [module, setModule] = useState<ModuleModel>();
  const [modules, setModules] = useState<ModuleModel[]>([]);
  const [properties, setProperties] = useState<PropertyValue[]>([]);
  const [data, setData] = useState<ConfigUpdatePropertyModel[]>([]);
  const [idGroup, setIdGroup] = useState<number>();
  const [sendParameters, setSendParameters] = useState<SendParameter[]>([]);

  const groupedData = data.reduce((acc: PropertyConfigUpdateGroupedData, item: ConfigUpdatePropertyModel) => {
    const key = item.modulo || 'unknown';
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {} as PropertyConfigUpdateGroupedData);

  const handleSaveConfigUpdate = async (): Promise<number> => {
    try {
      const response = await configUpdateService.api.post('', {
        ...deviceConfigUpdate,
        usuario: user?.userName ?? '',
        dataHora: new Date(),
        idCliente: user?.idCliente ?? 1,
      });

      if (response.status != 200) throw Error(response.data);

      const idConfigUpdate = response.data as number;

      setDeviceConfigUpdate({
        ...deviceConfigUpdate,
        id: idConfigUpdate,
      });

      return idConfigUpdate;
    } catch (error) {
      throw error;
    }
  };

  const handleSendFences = async () => {
    try {
      setLoading(true);

      const idConfigUpdate = deviceConfigUpdate.id ?? (await handleSaveConfigUpdate());

      const response = await configUpdateService.api.post(`${idConfigUpdate}/grupo-cercas`, {
        idGrupoCercas: idGroup,
        dispositivos: deviceConfigUpdate.dispositivos.map((d) => d.id),
      });

      if (response.status != 200) throw Error(response.data);

      setModule({} as ModuleModel);
      setLoading(false);
      setIdGroup(undefined);
      notificationController.success({
        message: `Envio das configurações de "${module?.nome}" realizado com sucesso!`,
      });
    } catch (error) {
      setLoading(false);
      notificationController.error({
        message: 'Erro!',
        description: `Houve um erro ao enviar as propriedades. ${error}`,
      });
    }
  };

  const handleSendProperties = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    const idConfigUpdate = deviceConfigUpdate.id ?? (await handleSaveConfigUpdate());

    if (module?.nome == 'Cercas') {
      await handleSendFences();
      return;
    }

    if (properties.find((p) => p.idEmbarcado === 26)?.valorPropriedade == 'false') {
      setProperties([
        ...properties.map((p) => {
          if (p.idEmbarcado === 27 || p.idEmbarcado === 28) {
            p.valorPropriedade = '';
          }

          return p;
        }),
      ]);
    }

    let propertiesToSend = properties.filter(
      (p) =>
        (groupedData[module?.nome ?? ''].findIndex((gd) => gd.idPropriedade === p.idPropriedade) > -1 &&
          !(p.valorPropriedade.length <= 0 && [8, 9].findIndex((x) => x === p.idEmbarcado) === -1) &&
          p.idEmbarcado !== 34) ??
        [],
    );

    propertiesToSend = propertiesToSend.map((p) => {
      if (p.idEmbarcado === 11 && Number(p.valorPropriedade) === 0) {
        p.valorPropriedade = '1';
        return p;
      }

      if ((p.idEmbarcado === 8 || p.idEmbarcado === 9) && p.valorPropriedade.length <= 0) {
        p.valorPropriedade = 'null';
        return p;
      }

      return p;
    });

    try {
      setLoading(true);

      const response = await configUpdateService.api.post(`${idConfigUpdate}/propriedades`, {
        propriedades: propertiesToSend,
        dispositivos: deviceConfigUpdate.dispositivos,
      });

      if (response.status != 200) throw Error();

      setModule({} as ModuleModel);
      setLoading(false);
      notificationController.success({
        message: `Envio das configurações de "${module?.nome}" realizado com sucesso!`,
      });
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Houve um erro ao enviar as propriedades. ${error}`,
      });
      setLoading(false);
    }
  };

  const fecthModules = async (idModelo: number, idGrupoFirmware: number) => {
    firmwareGroupService
      .getArray(`/${idGrupoFirmware}/${idModelo}/propriedades`)
      .then((res: DeviceProfilePropertyModel[]) => {
        //todo: validar
        setData(res);
        setProperties(
          res.map((r) => {
            return {
              idPropriedade: r.idPropriedade,
              idEmbarcado: r.idEmbarcado,
              valorPropriedade: 0,
            };
          }) as unknown as PropertyValue[],
        );
        const modulesGroup = groupBy(res, (m) => m.idModulo);
        setModules(
          Object.keys(modulesGroup).map(
            (module) =>
              ({
                id: modulesGroup[module][0].id,
                nome: modulesGroup[module][0].modulo,
                nomeExibicao: modulesGroup[module][0].moduloExibicao,
              }) as ModuleModel,
          ),
        );
        fetchSendProfileProperties(idGrupoFirmware);
      })
      .catch(() => {
        notificationController.error({ message: 'Erro!', description: 'Erro ao buscar os tipos de configurações' });
      });
  };

  const fetchSendProfileProperties = useCallback(
    async (idVersaoFirmware: number) => {
      setLoading(true);
      return firmwareGroupService
        .getArray(`obter-propriedades-perfil-envio/${idVersaoFirmware}`)
        .then((res) => {
          dispatch(setParameters(res as unknown as SendParameter[]));
          setSendParameters(res as unknown as SendParameter[]);
        })
        .catch((error) => {
          notificationController.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch],
  );

  useEffect(() => {
    console.log(deviceConfigUpdate);
    deviceConfigUpdate.idModelo &&
      deviceConfigUpdate.idGrupoFirmware &&
      fecthModules(deviceConfigUpdate.idModelo, deviceConfigUpdate.idGrupoFirmware);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceConfigUpdate.idModelo, deviceConfigUpdate.idGrupoFirmware]);

  const handleDisableSendButton = useCallback((): boolean => {
    if (module?.nome === 'Rede') {
      const apn = properties.find((p) => p.idEmbarcado === 7)?.valorPropriedade;
      const portaServidor = properties.find((p) => p.idEmbarcado === 12)?.valorPropriedade;
      const ipServidorPrimario = properties.find((p) => p.idEmbarcado === 13)?.valorPropriedade;
      const dnsServidorPrimario = properties.find((p) => p.idEmbarcado === 43)?.valorPropriedade;

      if (!apn || apn.length <= 0) return true;
      if (!portaServidor || portaServidor.length <= 0) return true;
      if (
        (!ipServidorPrimario || ipServidorPrimario.length <= 0) &&
        (!dnsServidorPrimario || dnsServidorPrimario.length <= 0)
      )
        return true;

      return false;
    } else if (module?.nome === 'Evento') {
      const cfg16 = properties.find((p) => p.idEmbarcado === 16)?.valorPropriedade;
      const cfg17 = properties.find((p) => p.idEmbarcado === 17)?.valorPropriedade;

      if (cfg16 && cfg16 == 'true' && (!cfg17 || cfg17.length <= 0)) return true;

      const cfg26 = properties.find((p) => p.idEmbarcado === 26)?.valorPropriedade;
      const cfg27 = properties.find((p) => p.idEmbarcado === 27)?.valorPropriedade;
      const cfg28 = properties.find((p) => p.idEmbarcado === 28)?.valorPropriedade;

      if (cfg26 && cfg26 == 'true') {
        if (cfg27 && cfg27 == 'true' && (!cfg28 || cfg28.length <= 0)) return true;
      }

      const cfg52 = properties.find((p) => p.idEmbarcado === 52)?.valorPropriedade;
      const cfg23 = properties.find((p) => p.idEmbarcado === 23)?.valorPropriedade;

      if (cfg52 && cfg52 == 'true' && (!cfg23 || cfg23.length <= 0)) return true;

      const cfg53 = properties.find((p) => p.idEmbarcado === 53)?.valorPropriedade;
      const cfg60 = properties.find((p) => p.idEmbarcado === 60)?.valorPropriedade;

      if (cfg53 && cfg53 == 'true' && (!cfg60 || cfg60.length <= 0)) return true;

      const cfg54 = properties.find((p) => p.idEmbarcado === 54)?.valorPropriedade;
      const cfg61 = properties.find((p) => p.idEmbarcado === 61)?.valorPropriedade;

      if (cfg54 && cfg54 == 'true' && (!cfg61 || cfg61.length <= 0)) return true;

      const cfg57 = properties.find((p) => p.idEmbarcado === 57)?.valorPropriedade;
      const cfg58 = properties.find((p) => p.idEmbarcado === 58)?.valorPropriedade;

      if (cfg57 && cfg57 == 'true' && (!cfg58 || cfg58.length <= 0)) return true;

      const cfg18 = properties.find((p) => p.idEmbarcado === 18)?.valorPropriedade;
      const cfg19 = properties.find((p) => p.idEmbarcado === 19)?.valorPropriedade;
      const cfg20 = properties.find((p) => p.idEmbarcado === 20)?.valorPropriedade;

      if (cfg18 && cfg18 == 'true' && (!cfg19 || cfg19.length <= 0 || !cfg20 || cfg20.length <= 0)) return true;
    }

    return false;
  }, [properties, module]);

  return (
    <>
      <S.ConfigUpdateCardStepTwoWrapper>
        <S.ConfigUpdateCardStepTwoForm layout="vertical">
          <S.ConfigUpdateCardStepTwoRow>
            <S.ConfigUpdateCardStepTwoCol span={24}>
              <Description
                title={id ? 'Envio de Parâmetros' : 'Etapa 2 de 2'}
                subtitle="Após preencher os campos, clique em finalizar."
              />
              <Row justify={'start'} style={{ marginBottom: '1rem' }}>
                <CardTitle>Configurações</CardTitle>
              </Row>
              <Row gutter={6}>
                <Col xs={24}>
                  <BaseFormInputItem label="Tipo de configuração" errorText="Campo obrigatório">
                    <Select
                      showArrow
                      showSearch
                      placeholder="Selecione o tipo"
                      value={module?.id}
                      onChange={(value) => {
                        setModule(modules.find((e) => e.id == value));
                      }}
                      options={modules
                        .map((c) => ({
                          value: c.id,
                          label: `${c.nomeExibicao}`,
                        }))
                        .filter((c) => c.label != 'CAN')}
                      filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24}>
                  {Object.keys(groupedData).map((moduleName) => (
                    <ModulesComponent
                      key={moduleName}
                      properties={properties}
                      setProperties={setProperties}
                      moduleData={groupedData[moduleName]}
                      moduleName={moduleName}
                      setPropertyValues={() => {}}
                      show={moduleName === module?.nome ? true : false}
                      idGroup={idGroup}
                      setIdGroup={setIdGroup}
                      idModel={deviceConfigUpdate.idModelo}
                      sendParameters={sendParameters}
                    />
                  ))}
                </Col>
              </Row>
            </S.ConfigUpdateCardStepTwoCol>
          </S.ConfigUpdateCardStepTwoRow>
        </S.ConfigUpdateCardStepTwoForm>
      </S.ConfigUpdateCardStepTwoWrapper>
      <Row align={'bottom'} justify={'end'}>
        <Col xs={24} sm={12} md={6}>
          <Button
            block
            type="primary"
            onClick={handleSendProperties}
            loading={loading}
            disabled={handleDisableSendButton()}
          >
            Enviar
          </Button>
        </Col>
      </Row>
    </>
  );
};
