import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CardInfoTagBgBlue,
  CardInfoTitle,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Modal } from '@app/components/common/Modal/Modal';
import { RadioGroup } from '@app/components/common/Radio/Radio';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import { Button } from '../common/buttons/Button/Button';
import * as S from './SendCommandToDeviceModal.styles';
import { Tag } from '../common/Tag/Tag';
import { InfoCircleOutlined } from '@ant-design/icons';
import { notificationController } from '@app/controllers/notificationController';
import ISendCommandService, { SendCommandService } from '@app/services/sendCommandService';
import LastTransmission from './LastTransmission/LastTransmission';
import { handleEncryptText } from '@app/utils/encryptText';
import { SendCommandEnum } from '@app/constants/enums/device/sendCommand';
import { Input } from '../common/inputs/Input/Input';
import { BaseFormInputItem } from '../common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '../common/selects/Select/Select';
import {
  CanVehicleManufacturerModel,
  CanVehicleModel,
  CanVehicleParametersCalculatedModel,
  CanVehicleParametersOnOffModel,
} from '@app/domain/canVehicle/canVehicle';
import ICanVehicleService, { CanVehicleService } from '@app/services/canVehicleService';
import IFactoryDeviceService, { FactoryDeviceService } from '@app/services/factoryDeviceService';
import IModelDeviceService, { ModelDeviceService } from '@app/services/modelDeviceService';
import { CommandModel } from '@app/domain/modelCommand/commandModel';
import { Checkbox } from '../common/Checkbox/Checkbox';
import IDeviceProfileService, { DeviceProfileService } from '@app/services/deviceProfileService';

const canVehicleService: ICanVehicleService = new CanVehicleService();
const sendCommandService: ISendCommandService = new SendCommandService();
const deviceService: IFactoryDeviceService = new FactoryDeviceService();
const modelService: IModelDeviceService = new ModelDeviceService();
const deviceProfileService: IDeviceProfileService = new DeviceProfileService();

export interface LastTransmissionDataModel {
  gps: {
    lat: number;
    lng: number;
  } | null;
  payload: string;
}

interface ISendCommandToDeviceModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  imei: string;
  idModelo: number;
  deviceId: number;
  connectionState: boolean;
}

const SendCommandToDeviceModal: React.FC<ISendCommandToDeviceModalProps> = ({
  visible,
  setVisible,
  imei,
  idModelo,
  deviceId,
  connectionState,
}) => {
  const [commandSelected, setCommandSelected] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingLastTransmission, setLoadingLastTransmission] = useState<boolean>(false);
  const [modalSendPowerCutVisible, setModalSendPowerCutVisible] = useState<boolean>(false);
  const [lastTransmissionData, setLastTransmissionData] = useState<LastTransmissionDataModel | null>(null);
  const [powerCut, setPowerCut] = useState<number | null>(null);
  const [powerCutStatus, setPowerCutStatus] = useState<number>(-1);
  const [changeHourOdoNickOldLabel, setChangeHourOdoNickOldLabel] = useState<string>('');
  const [changeHourOdoNickNewLabel, setChangeHourOdoNickNewLabel] = useState<string>('');
  const [changeHourOdoNickLastValue, setChangeHourOdoNickLastValue] = useState<string>('');
  const [changeHourOdoNickNewValue, setChangeHourOdoNickNewValue] = useState<string>('');
  const [vehicle, setVehicle] = useState<CanVehicleModel>();
  const [vehicles, setVehicles] = useState<CanVehicleModel[]>([]);
  const [manufacturer, setManufacturer] = useState<CanVehicleManufacturerModel>();
  const [manufacturers, setManufacturers] = useState<CanVehicleManufacturerModel[]>([]);
  const [modelCommand, setModelCommand] = useState<CommandModel[]>([]);
  const [lastManufacturer, setLastManufacturer] = useState<string>();
  const [lastVehicle, setLastVehicle] = useState<string>();
  const [parametersCalculated, setParametersCalculated] = useState<CanVehicleParametersCalculatedModel[]>([]);
  const [parametersOnOff, setParametersOnOff] = useState<CanVehicleParametersOnOffModel[]>([]);
  const [parametersCalculatedSelected, setParametersCalculatedSelected] = useState<
    CanVehicleParametersCalculatedModel[]
  >([]);
  const [parametersOnOffSelected, setParametersOnOffSelected] = useState<CanVehicleParametersOnOffModel[]>([]);

  const handleSelectAllOnOff = (checked: boolean) => {
    if (checked) {
      setParametersOnOffSelected(parametersOnOff);
    } else {
      setParametersOnOffSelected([]);
    }
  };

  const handleSelectAllCalculated = (checked: boolean) => {
    if (checked) {
      setParametersCalculatedSelected(parametersCalculated);
    } else {
      setParametersCalculatedSelected([]);
    }
  };

  const fetchCommandsByIdModel = useCallback(
    async (idModel: number) => {
      try {
        const response = await modelService.getCommandsByIdModel(idModel);
        setModelCommand(response);
      } catch (error) {
        notificationController.error({
          message: 'Erro!',
          description: 'Erro ao buscar os tipos de configurações',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const fecthCalculatedParameters = async (id: number) => {
    const calculatedParameters = await canVehicleService.getCanVehicleParametersCalculated(id);
    setParametersCalculated(calculatedParameters);
  };

  const fecthOnOffParameters = async (id: number) => {
    const onOffParameters = await canVehicleService.getCanVehicleParametersOnOff(id);
    setParametersOnOff(onOffParameters);
  };

  useEffect(() => {
    if (idModelo) {
      fetchCommandsByIdModel(idModelo);
    }
  }, [fetchCommandsByIdModel, idModelo]);

  useEffect(() => {
    if (vehicle?.id) {
      fecthCalculatedParameters(vehicle.id);
      fecthOnOffParameters(vehicle.id);
    }
  }, [vehicle]);

  const handleCleanLastCommand = () => {
    setLastTransmissionData(null);
    setPowerCut(null);
    setChangeHourOdoNickLastValue('');
    setChangeHourOdoNickNewLabel('');
    setChangeHourOdoNickNewValue('');
    setChangeHourOdoNickOldLabel('');
    setCommandSelected('');
    setManufacturer(undefined);
    setVehicle(undefined);
  };

  useEffect(() => {
    !visible && handleCleanLastCommand();
  }, [visible]);

  const handleGetPowerCutStatus = async () => {
    setLoading(true);
    sendCommandService
      .getPowerCutStatus(imei)
      .then((res) => {
        setPowerCut(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({ message: `${err}` });
      });
  };

  const handleGetLastOdo = async () => {
    if (connectionState) {
      setChangeHourOdoNickOldLabel('Odômetro atual');
      setChangeHourOdoNickNewLabel('Novo odômetro');
      const data = await handleSendCommandToDevice(SendCommandEnum.GET_ODO);
      setChangeHourOdoNickLastValue(data?.payload ?? '-');
    } else {
      notificationController.error({
        message: 'Não é possível enviar comando, pois o dispositivo está off-line, tente novamente mais tarde!',
      });
      return;
    }
  };

  const handleGetLastHour = async () => {
    if (connectionState) {
      setChangeHourOdoNickOldLabel('Horímetro atual');
      setChangeHourOdoNickNewLabel('Novo horímetro');
      const data = await handleSendCommandToDevice(SendCommandEnum.GET_HOUR);
      setChangeHourOdoNickLastValue(data?.payload ?? '-');
    } else {
      notificationController.error({
        message: 'Não é possível enviar comando, pois o dispositivo está off-line, tente novamente mais tarde!',
      });
      return;
    }
  };

  const handleGetLastNickname = async () => {
    setLoading(true);
    setChangeHourOdoNickOldLabel('Apelido atual');
    setChangeHourOdoNickNewLabel('Novo apelido');
    sendCommandService
      .getNickname(imei)
      .then((res) => {
        setChangeHourOdoNickLastValue(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({ message: `${err}` });
      });
  };

  const handleGetLastVechicleData = async () => {
    try {
      setLoading(true);

      const device = await deviceService.get(`obter-por-imei/${imei}`);
      const manufacturersResponse = await canVehicleService.getCanVehicleManufacturer();

      if (!device?.id || !manufacturersResponse?.length) throw Error();

      setManufacturers(manufacturersResponse);

      if (device.idVeiculoCan) {
        const vehicleResponse = await canVehicleService.get(`${device.idVeiculoCan}`);
        setLastManufacturer(manufacturersResponse.find((m) => m.id == vehicleResponse.fabricanteId)?.nome);
        setLastVehicle(`${vehicleResponse.nome} | ${vehicleResponse.ano}`);
      }

      setLoading(false);
    } catch (error) {
      notificationController.error({ message: 'Erro!', description: 'Problema ao obter os dados do veículo.' });
      setLoading(false);
    }
  };

  const handleCheckboxCalculatedChange = (checked: boolean, item: CanVehicleParametersCalculatedModel) => {
    if (checked) {
      setParametersCalculatedSelected([...parametersCalculatedSelected, item]);
    } else {
      setParametersCalculatedSelected(parametersCalculatedSelected.filter((p) => p.nomeId !== item.nomeId));
    }
    setParametersCalculated(parametersCalculated);
  };

  const handleCheckboxOnOffChange = (checked: boolean, item: CanVehicleParametersOnOffModel) => {
    if (checked) {
      setParametersOnOffSelected([...parametersOnOffSelected, item]);
    } else {
      setParametersOnOffSelected(parametersOnOffSelected.filter((p) => p.nomeId !== item.nomeId));
    }
    setParametersOnOff(parametersOnOff);
  };

  const fetchVehicles = useCallback((idManufacturer: number) => {
    canVehicleService
      .getArray(`obter-veiculos-por-id-fabricante/${idManufacturer}`)
      .then((response) => setVehicles(response.filter((m) => m.status)))
      .catch(() => notificationController.error({ message: 'Erro ao buscar os veículo' }));
  }, []);

  useMemo(() => {
    if (manufacturer?.id) fetchVehicles(manufacturer.id);
  }, [manufacturer, fetchVehicles]);

  const handleSendCommand = async () => {
    switch (commandSelected) {
      case SendCommandEnum.SET_GRAD_OUT:
        setVisible(false);
        setModalSendPowerCutVisible(true);
        break;

      case SendCommandEnum.REBOOT:
        setVisible(false);
        handleSendRebootCommand();
        break;

      case SendCommandEnum.CLEAN_CALIB:
        handleSendCleanCalibCommand();
        break;

      case SendCommandEnum.GET_LAST_JSON:
        handleSendLastTransmissionCommand();
        break;

      case SendCommandEnum.SET_HOUR_UPDATE:
        handleSendHourUpdateCommand();
        break;

      case SendCommandEnum.SET_ODO_UPDATE:
        handleSendOdoUpdateCommand();
        break;

      case SendCommandEnum.SET_NICKNAME_UPDATE:
        handleSendDeviceNicknameCommand();
        break;

      case SendCommandEnum.SET_CAN:
        handleSendCfgCanCommand();
        break;
      case SendCommandEnum.REMOVE_CAN:
        handleSendCfgCanRemoveCommand();
        break;

      default:
        handleCleanLastCommand();
        break;
    }

    // handleCleanLastCommand();
  };

  const handleSendCleanCalibCommand = async () => {
    setLoading(true);

    try {
      await handleSendCommandToDevice(SendCommandEnum.CLEAN_CALIB);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSendRebootCommand = async () => {
    setLoading(true);

    try {
      await handleSendCommandToDevice(SendCommandEnum.REBOOT);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSendPowerCutCommand = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setLoading(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const data = await handleSendCommandToDevice(
        SendCommandEnum.SET_GRAD_OUT,
        `${powerCutStatus.toString()}`,
        'comando',
        powerCut === 1 ? '0' : '1',
      );
      setModalSendPowerCutVisible(false);
      setPowerCutStatus(-1);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSendLastTransmissionCommand = async () => {
    setLoading(true);

    try {
      setLoadingLastTransmission(true);
      const data = await handleSendCommandToDevice(SendCommandEnum.GET_LAST_JSON);
      if (data !== null) {
        setLastTransmissionData({
          gps: data.gps
            ? {
                lat: data.gps[0],
                lng: data.gps[1],
              }
            : null,
          payload: data.payload,
        });
      }
      setLoadingLastTransmission(false);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSendOdoUpdateCommand = async () => {
    setLoading(true);
    try {
      const data = await handleSendCommandToDevice(
        SendCommandEnum.SET_ODO_UPDATE,
        changeHourOdoNickNewValue,
        '',
        changeHourOdoNickLastValue,
      );
      if (data?.status === 200) {
        await handleGetLastOdo();
        setChangeHourOdoNickNewValue('');
      }
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSendHourUpdateCommand = async () => {
    setLoading(true);

    try {
      const data = await handleSendCommandToDevice(
        SendCommandEnum.SET_HOUR_UPDATE,
        changeHourOdoNickNewValue,
        '',
        changeHourOdoNickLastValue,
      );
      if (data?.status === 200) {
        await handleGetLastHour();
        setChangeHourOdoNickNewValue('');
      }
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSendDeviceNicknameCommand = async () => {
    setLoading(true);
    try {
      const data = await handleSendCommandToDevice(
        SendCommandEnum.SET_NICKNAME_UPDATE,
        changeHourOdoNickNewValue,
        '',
        changeHourOdoNickLastValue,
      );
      if (data?.status === 200) {
        await handleGetLastNickname();
        setChangeHourOdoNickNewValue('');
      }
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSendCfgCanRemoveCommand = async () => {
    setLoading(true);

    try {
      const response = await handleSendCommandToDevice(SendCommandEnum.REMOVE_CAN, JSON.stringify(''));
      if (response?.status == 200) setCommandSelected('');
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSendCfgCanCommand = async () => {
    setLoading(true);

    try {
      if (!vehicle?.id) {
        notificationController.error({ message: 'Erro!', description: 'Nenhum veículo selecionado.' });
        return;
      }

      await deviceService.api.put(`${deviceId}/atualizar-veiculo-can/${vehicle?.id ?? 0}`);

      await deviceProfileService.insertSelectedCanParameters(
        vehicle.id,
        parametersCalculatedSelected,
        parametersOnOffSelected,
        undefined,
        deviceId,
      );

      const parametrosSelecionados = {
        parametroCalculado: parametersCalculatedSelected,
        parametroLigadoDesligado: parametersOnOffSelected,
      };

      const response = await handleSendCommandToDevice(SendCommandEnum.SET_CAN, JSON.stringify(parametrosSelecionados));

      if (response?.status == 200) {
        setCommandSelected('');
      }
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Erro ao enviar comando para o dispositivo`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSendCommandToDevice = async (command: string, message?: string, endpoint?: string, oldValue?: string) => {
    setLoading(true);
    const response = await sendCommandService
      .sendCommand(endpoint ? endpoint : '', {
        idDispositivo: imei,
        metodo: await handleEncryptText(command),
        mensagem: message ? await handleEncryptText(message) : '',
        valorAntigo: await handleEncryptText(oldValue ?? ''),
      })
      .catch((err: Error) => {
        setLoading(false);
        setLoadingLastTransmission(false);
        if (err.message.indexOf('429') > -1) {
          notificationController.error({
            message: `O limite de requisições foi ultrapassado para o endpoint solicitado.`,
          });
        } else {
          notificationController.error({
            message: `Ocorreu um erro durante a execução do comando.\n${err}`,
          });
        }
        return null;
      });

    if (response?.status === 200) notificationController.success({ message: 'Comando enviado com sucesso.' });

    setLoading(false);
    return response;
  };

  const handleSelectOnChange = async (value: unknown) => {
    handleCleanLastCommand();
    setCommandSelected(value as string);
    // const selectedCommand = modelCommand.find((command) => command.idComando === value);
    // setCommandSelected(selectedCommand ?? null);

    switch (value) {
      case SendCommandEnum.SET_GRAD_OUT:
        handleGetPowerCutStatus();
        break;
      case SendCommandEnum.SET_ODO_UPDATE:
        handleGetLastOdo();
        break;
      case SendCommandEnum.SET_HOUR_UPDATE:
        handleGetLastHour();
        break;
      case SendCommandEnum.SET_NICKNAME_UPDATE:
        handleGetLastNickname();
        break;
      case SendCommandEnum.SET_CAN:
      case SendCommandEnum.REMOVE_CAN:
        handleGetLastVechicleData();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Modal
        title={`${powerCutStatus === 1 ? 'Ligar' : 'Desligar'} corta corrente`}
        open={modalSendPowerCutVisible}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '0.25rem' }}>
            <Button
              type="ghost"
              onClick={() => {
                setModalSendPowerCutVisible(false);
                setVisible(true);
              }}
            >
              Cancelar
            </Button>
            <Button type="primary" onClick={handleSendPowerCutCommand} disabled={loading} loading={loading}>
              Confirmar
            </Button>
          </div>
        }
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            Deseja realmente <strong>&nbsp;{powerCutStatus === 1 ? 'ligar' : 'desligar'} o corta corrente?</strong>
          </Col>
        </Row>
      </Modal>
      <Modal
        open={visible}
        onCancel={() => {
          handleCleanLastCommand();
          setVisible(false);
          setCommandSelected('');
        }}
        title="Envio de comando"
        size="large"
        footer={null}
      >
        <CardInfoTagBgBlue style={{ marginTop: '1rem', height: '3rem' }}>
          <Row>
            <Col style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <InfoCircleOutlined />
              Escolha apenas um comando por vez para enviar.
            </Col>
          </Row>
        </CardInfoTagBgBlue>
        <BaseForm layout="vertical">
          <BaseFormInputItem label="Selecione o comando" style={{ width: '100%', marginTop: '1rem' }}>
            <Row align="middle" gutter={18}>
              <Col xs={24}>
                <S.Select
                  showArrow
                  style={{
                    width: '100%',
                  }}
                  loading={loading}
                  disabled={loading}
                  value={commandSelected}
                  placeholder="Selecione um comando por vez para enviar"
                  onChange={handleSelectOnChange}
                  options={modelCommand.map((m) => ({
                    label: m.nome,
                    value: m.comando,
                  }))}
                />
              </Col>
            </Row>
          </BaseFormInputItem>
          {powerCut !== null && (
            <>
              <Row style={{ marginTop: '1rem' }}>
                <div>
                  <span>Estado atual: </span>
                  <Tag style={{ backgroundColor: '#E8E8E8', color: '#383838' }}>
                    {
                      {
                        0: 'Desligado',
                        1: 'Ligado',
                        2: 'Em andamento',
                      }[powerCut]
                    }
                  </Tag>
                </div>
              </Row>
              <Row style={{ marginTop: '0.5rem' }}>
                {powerCut === 0 && (
                  <CardInfoTagBgBlue style={{ height: '4rem' }}>
                    <Row>
                      <Col style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <InfoCircleOutlined />
                        Leva em torno de 60 segundos para finalizar o ciclo de corta corrente. Se enviou o comando
                        recentemente, ele ainda pode estar em processamento.
                      </Col>
                    </Row>
                  </CardInfoTagBgBlue>
                )}
                <RadioGroup
                  defaultValue={powerCutStatus}
                  value={powerCutStatus}
                  onChange={(e: RadioChangeEvent) => setPowerCutStatus(e.target.value)}
                >
                  {powerCut === 0 && <Radio value={1}>Ligar</Radio>}
                  {powerCut === 1 && <Radio value={0}>Desligar</Radio>}
                </RadioGroup>
              </Row>
            </>
          )}
          {(commandSelected === SendCommandEnum.SET_CAN || commandSelected === SendCommandEnum.REMOVE_CAN) && (
            <BaseForm layout="vertical" style={{ width: '100%', marginTop: '1rem' }}>
              <Row gutter={[18, 48]}>
                <Col xs={24}>
                  <S.ContainerChangeValue>
                    <Row gutter={[18, 48]} style={{ width: '100%' }}>
                      <Col xs={12}>
                        <BaseFormInputItem label="Fabricante atual" style={{ width: '100%' }}>
                          <Input disabled value={lastManufacturer} />
                        </BaseFormInputItem>
                      </Col>
                      {commandSelected === SendCommandEnum.SET_CAN && (
                        <Col xs={12}>
                          <BaseFormInputItem label="Novo fabricante" style={{ width: '100%' }}>
                            <Select
                              showArrow
                              showSearch
                              value={manufacturer?.id}
                              placeholder="Selecione o fabricante"
                              onChange={(value) => {
                                setManufacturer(manufacturers.find((m) => m.id.toString() == value));
                                setVehicle(undefined);
                                setParametersCalculated([]);
                                setParametersOnOff([]);
                              }}
                              options={manufacturers.map((m) => ({ label: m.nome, value: m.id }))}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            ></Select>
                          </BaseFormInputItem>
                        </Col>
                      )}
                      <Col xs={12}>
                        <BaseFormInputItem label="Modelo atual" style={{ width: '100%' }}>
                          <Input disabled value={lastVehicle} />
                        </BaseFormInputItem>
                      </Col>
                      {commandSelected === SendCommandEnum.SET_CAN && (
                        <Col xs={12}>
                          <BaseFormInputItem label="Novo modelo de veículo" style={{ width: '100%' }}>
                            <Select
                              showArrow
                              showSearch
                              placeholder="Selecione o veículo"
                              value={vehicle?.id}
                              onChange={(value) => setVehicle(vehicles.find((v) => v.id?.toString() == value))}
                              disabled={!manufacturer?.id}
                              options={vehicles.map((m) => ({ label: `${m.nome} | ${m.ano}`, value: m.id }))}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            ></Select>
                          </BaseFormInputItem>
                        </Col>
                      )}
                      {commandSelected === SendCommandEnum.REMOVE_CAN && (
                        <Col xs={24}>
                          <CardInfoTagBgBlue style={{ height: '4rem' }}>
                            <Row>
                              <Col style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                <InfoCircleOutlined />
                                Leva em torno de 60 segundos para finalizar o ciclo de remoção de dados do Veículo Can.
                                <br /> Se enviou o comando recentemente, ele ainda pode estar em processamento.
                              </Col>
                            </Row>
                          </CardInfoTagBgBlue>
                        </Col>
                      )}
                    </Row>
                  </S.ContainerChangeValue>
                </Col>
                {(parametersCalculated.length > 0 || parametersOnOff.length > 0) &&
                  commandSelected === SendCommandEnum.SET_CAN && (
                    <Col xs={24}>
                      <Row>
                        <Col xs={24} sm={24} md={24}>
                          <CardInfoTitle style={{ marginTop: '0.625rem' }}>Selecione os parâmetros</CardInfoTitle>
                        </Col>

                        {parametersCalculated.length > 0 && (
                          <Row gutter={[10, 10]} style={{ marginBottom: '1rem' }}>
                            <Col xs={24} sm={24} md={24}>
                              <CardInfoValue>Calculado</CardInfoValue>
                            </Col>
                            <Col xs={24} md={24} lg={24}>
                              <Checkbox
                                checked={parametersCalculated.length === parametersCalculatedSelected.length}
                                onChange={(e) => handleSelectAllCalculated(e.target.checked)}
                              >
                                Selecionar tudo
                              </Checkbox>
                            </Col>
                            {parametersCalculated.map((item) => (
                              <Col key={`${item.id}-${item.nomeId}`} xs={24} md={8} lg={8}>
                                <Checkbox
                                  checked={parametersCalculatedSelected.findIndex((p) => p.nomeId === item.nomeId) > -1}
                                  onChange={(value) => handleCheckboxCalculatedChange(value.target.checked, item)}
                                >
                                  {item.nomeCAN}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        )}
                        {parametersOnOff.length > 0 && (
                          <Row gutter={[10, 10]}>
                            <Col xs={24} sm={24} md={24}>
                              <CardInfoValue>On/Off</CardInfoValue>
                            </Col>
                            <Col xs={24} md={24} lg={24}>
                              <Checkbox
                                checked={parametersOnOff.length === parametersOnOffSelected.length}
                                onChange={(e) => handleSelectAllOnOff(e.target.checked)}
                              >
                                Selecionar tudo
                              </Checkbox>
                            </Col>
                            {parametersOnOff.map((item) => (
                              <Col key={`${item.id}-${item.nomeId}`} xs={24} md={8} lg={8}>
                                <Checkbox
                                  checked={parametersOnOffSelected.findIndex((p) => p.nomeId === item.nomeId) > -1}
                                  onChange={(value) => handleCheckboxOnOffChange(value.target.checked, item)}
                                >
                                  {item.nomeCAN}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        )}
                      </Row>
                    </Col>
                  )}
              </Row>
            </BaseForm>
          )}
          {commandSelected != SendCommandEnum.SET_CAN && changeHourOdoNickNewLabel.length > 0 && (
            <BaseForm layout="vertical" style={{ width: '100%', marginTop: '1rem' }}>
              <S.ContainerChangeValue>
                <BaseFormInputItem label={changeHourOdoNickOldLabel}>
                  <Input disabled value={changeHourOdoNickLastValue} />
                </BaseFormInputItem>
                <BaseFormInputItem label={changeHourOdoNickNewLabel}>
                  <Input
                    value={changeHourOdoNickNewValue}
                    placeholder="Digite o novo valor"
                    maxLength={commandSelected === SendCommandEnum.SET_NICKNAME_UPDATE ? 12 : undefined}
                    onChange={(event) => setChangeHourOdoNickNewValue(event.target.value)}
                  />
                </BaseFormInputItem>
              </S.ContainerChangeValue>
            </BaseForm>
          )}
        </BaseForm>
        <S.Container>
          <S.FooterButtonsContainer>
            <Button
              type="ghost"
              onClick={() => {
                handleCleanLastCommand();
                setVisible(false);
                setCommandSelected('');
              }}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              onClick={handleSendCommand}
              disabled={
                commandSelected === SendCommandEnum.SET_GRAD_OUT
                  ? powerCutStatus < 0
                  : changeHourOdoNickNewLabel.length > 0
                  ? changeHourOdoNickNewValue.length <= 0
                  : loading
                  ? loading
                  : commandSelected.length <= 0
              }
            >
              Enviar comando
            </Button>
          </S.FooterButtonsContainer>
          {(loadingLastTransmission || lastTransmissionData !== null) && (
            <LastTransmission lastTransmissionData={lastTransmissionData} />
          )}
        </S.Container>
      </Modal>
    </>
  );
};

export default SendCommandToDeviceModal;
