import { Spinner } from '@app/components/common/Spinner/Spinner';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import * as S from '../index.styles';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { setFooter } from '@app/store/slices/footerSlice';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Button, Col, Input, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ReactComponent as ExportCsvIcon } from '@app/assets/icons/export-csv.svg';
import { TableData } from '../components/TableData/TableData';
import { TagGroupModel } from '@app/domain/tagGroup/TagGroupModel';
import { TagsGroupService } from '@app/services/tagsGroupService';
import { genericExportToExcel } from '@app/utils/exportToExcel';

const tagGroupService = new TagsGroupService();

export const TagsGroupView: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [tagGroup, setTagGroup] = useState<TagGroupModel>({} as TagGroupModel);
  const [searchValue, setSearchValue] = useState('');

  const handleSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    const inputValue = event.currentTarget.value.toLowerCase();
    setSearchValue(inputValue);
  };
  const fetchScheduleById = useCallback(() => {
    setLoading(true);
    tagGroupService
      .get(`${id}`)
      .then((response) => {
        setTagGroup(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error(error);
      });
  }, [id]);

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: 'Visualizar tags',
        handleBackClick: () => navigate('/grupo-tags'),
      }),
    );
    dispatch(
      setFooter({
        confirmButtonText: '',
        cancelButtonText: '',
      }),
    );
    fetchScheduleById();
  }, [dispatch, fetchScheduleById, navigate]);

  const handleCloneRowClick = () => {
    navigate(`/grupo-tags/clonar/${id}`);
  };

  const handleExportDataExcel = async () => {
    const columns = [
      { title: 'Nome', dataIndex: 'nome' },
      { title: 'Tag ID', dataIndex: 'tagId' },
    ];

    const data = tagGroup.tags
      ? tagGroup.tags.map((tag) => ({
          nome: tagGroup.nome,
          tagId: tag,
        }))
      : [];

    await genericExportToExcel('grupo_tags', columns, data);
  };

  return (
    <Spinner spinning={loading}>
      <PageContainer>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Row gutter={[10, 10]} align="middle" justify="space-between">
            <Col span={12}>
              <Row gutter={[10, 10]} align="middle">
                <Col span={11}>
                  <BaseFormInputItem label="Pesquisa">
                    <Input
                      suffix={<SearchOutlined style={{ marginRight: '1rem', color: '#545454' }} />}
                      placeholder="Pesquisar por tag ID"
                      onChange={handleSearchFilter}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col span={1} style={{ marginBottom: '1.25rem' }}>
                  <Button type="link" onClick={handleExportDataExcel}>
                    <S.ExportButtonContainer>
                      <ExportCsvIcon />
                      Exportar dados excel
                    </S.ExportButtonContainer>
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button type="primary" onClick={handleCloneRowClick}>
                Clonar grupo
              </Button>
            </Col>
          </Row>
          <TableData
            key="1"
            header={
              <Row align={'middle'} justify={'space-between'}>
                <Col>{tagGroup.nome}</Col>
              </Row>
            }
            data={
              searchValue ? tagGroup?.tags?.filter((tag) => tag.toLowerCase().includes(searchValue)) : tagGroup.tags
            }
          />
        </BaseForm>
      </PageContainer>
    </Spinner>
  );
};
