import { Modal } from '@app/components/common/Modal/Modal';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { ConfigUpdateDeviceModel } from '@app/domain/deviceConfigUpdate/deviceConfigUpdate';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';

interface IModalAddDevice {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  configDevices: ConfigUpdateDeviceModel[];
  setIdsDevice: (devices: ConfigUpdateDeviceModel[]) => void;
  devices: FactoryDeviceModel[];
}

export const ModalAddDevice: React.FC<IModalAddDevice> = (props) => {
  const { visible, setVisible, configDevices, setIdsDevice, devices } = props;
  const [localDevices, setLocalDevices] = useState<ConfigUpdateDeviceModel[]>([]);

  const handleSave = () => {
    setIdsDevice(localDevices);
    setVisible(false);
    setLocalDevices([]);
  };
  const handleCancel = () => {
    setVisible(false);
    setLocalDevices([]);
  };

  const handlerOnDeselectDevice = async (value: number) => setLocalDevices(localDevices.filter((d) => d.id != value));
  const handlerOnSelectDevice = async (value: number) => {
    if (value === -1) {
      setLocalDevices(
        devices.map(
          (d) =>
            ({
              id: d.id,
              numeroSerie: d.numeroSerie,
              idGrupoFirmware: d.idGrupoFirmware,
              idGrupo: d.idGrupo,
              versaoFirmware: d.versaoFirmwareAtualizada,
            }) as ConfigUpdateDeviceModel,
        ),
      );
    } else {
      const device = devices.find((d) => d.id == value);
      setLocalDevices([
        ...localDevices,
        {
          id: device?.id,
          numeroSerie: device?.numeroSerie,
          idGrupoFirmware: device?.idGrupoFirmware,
          idGrupo: device?.idGrupo,
          versaoFirmware: device?.versaoFirmwareAtualizada,
        } as ConfigUpdateDeviceModel,
      ]);
    }
  };

  const getDeviceOptions = () => {
    return [
      { value: -1, label: 'Todos' },
      ...devices.map((device) => ({
        value: device.id,
        label: device.numeroSerie,
      })),
    ];
  };

  useEffect(() => {
    if (visible) setLocalDevices(configDevices ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, setLocalDevices]);

  return (
    <>
      <Modal
        key={'modal-add-device'}
        open={visible}
        title={configDevices?.length > 0 ? 'Editar dispositivos' : 'Adicionar dispositivos'}
        onOk={handleSave}
        onCancel={handleCancel}
        cancelText="Cancelar"
        okText="Salvar"
      >
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Row gutter={6}>
            <Col xs={24}>
              <BaseFormInputItem
                style={{ width: '100%' }}
                label="Dispositivos"
                errorText="Campo obrigatório"
                supportText="Pesquise e selecione um ou mais dispositivos"
              >
                <Select
                  showArrow
                  showSearch
                  mode="multiple"
                  allowClear
                  onClear={() => setLocalDevices([])}
                  maxTagCount={'responsive'}
                  placeholder="Selecione os dispositivos"
                  value={localDevices?.map((d) => d.id)}
                  onSelect={(value: unknown) => handlerOnSelectDevice(value as number)}
                  onDeselect={(value: unknown) => handlerOnDeselectDevice(value as number)}
                  options={getDeviceOptions()}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
        </BaseForm>
      </Modal>
    </>
  );
};
