import React, { ReactNode } from 'react';
import * as S from './CollapseCard.styles';

interface ICollapseCard {
  children: ReactNode;
}

const CollapseCard: React.FC<ICollapseCard> = ({ children }) => {
  return (
    <S.Card accordion defaultActiveKey={['1']} expandIconPosition="end" bordered={false}>
      {children}
    </S.Card>
  );
};

export default CollapseCard;
