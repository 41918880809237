import React, { useState, useRef, useCallback, useEffect, FC } from 'react';
import * as S from './MapComponentCreate.styles';
import {
  GoogleMap,
  LoadScript,
  Polygon,
  Circle,
  StandaloneSearchBox,
  StandaloneSearchBoxProps,
  GoogleMapProps,
  Marker,
  Polyline,
} from '@react-google-maps/api';
import { PointCount } from '@app/components/common/GoogleMapItems/PointCount';
import { Input } from '@app/components/common/inputs/Input/Input';
import { FenceModel, LatLngLiteral, PathPoint } from '@app/domain/fence/fenceModel';
import { ZoomButtons } from '@app/components/common/GoogleMapItems/ZoomButtons';
import { UndoRedoDeleteButtons } from '@app/components/common/GoogleMapItems/UndoRedoDeleteButtons';
import { SavedFences } from '@app/pages/fence/create/components/MapComponentCreate/SavedFences/SavedFences';
import appSettings from '@app/config/appsettings';
import { notificationController } from '@app/controllers/notificationController';

const containerStyle = {
  width: '100%',
  height: '37rem',
};

// used to center the map if no fences have points
const supermixLocation = {
  lat: -19.878877423869252,
  lng: -43.97931295928024,
};

interface MapComponentProps {
  fence: FenceModel;
  setFence: React.Dispatch<React.SetStateAction<FenceModel>>;
  showInactiveFences: boolean;
  fences: FenceModel[];
  maxFencesQuantity: number;
  maxPointsPerFence: number;
}

const MapComponentCreate: FC<MapComponentProps> = ({
  fence,
  setFence,
  showInactiveFences,
  fences,
  maxFencesQuantity,
  maxPointsPerFence,
}) => {
  const [undoStack, setUndoStack] = useState<PathPoint[][]>([]);
  const [redoStack, setRedoStack] = useState<PathPoint[][]>([]);

  const mapRef = useRef<google.maps.Map | null>(null);
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const polygonRef = useRef<any>(null);
  const [dragStart, setDragStart] = useState<LatLngLiteral | null>(null);

  const controlsRef = useRef<HTMLDivElement | null>(null);

  const getMapInitialPoint = useCallback(() => {
    if (fence.pontos.length > 0) return undefined;

    const registeringFences = fences.find((fence) => !fence.id);
    if (registeringFences) return undefined;

    // Find the first active fence with points
    const activeFenceWithPoints = fences.find((fence) => fence.ativo && fence.pontos?.length > 0);
    if (activeFenceWithPoints) {
      return {
        lat: activeFenceWithPoints.pontos[0].lat,
        lng: activeFenceWithPoints.pontos[0].lng,
      };
    }

    // If no active fences, find the first inactive fence with points
    const inactiveFenceWithPoints = fences.find((fence) => !fence.ativo && fence.pontos?.length > 0);
    if (inactiveFenceWithPoints) {
      return {
        lat: inactiveFenceWithPoints.pontos[0].lat,
        lng: inactiveFenceWithPoints.pontos[0].lng,
      };
    }

    // Default location if no fences have points
    return supermixLocation;
  }, [fences, fence.pontos.length]);

  const onMapClick = (e: google.maps.MapMouseEvent) => {
    if (!maxFencesQuantity || !maxPointsPerFence) {
      notificationController.error({
        message: 'Selecione um modelo para continuar.',
      });
      return;
    }

    if (fence.pontos.length >= maxPointsPerFence) {
      notificationController.error({
        message: `A cerca pode ter no máximo ${maxPointsPerFence} pontos.`,
      });
      return;
    }

    switch (fence.idTipoCerca) {
      case 1:
        onPolygonClick(e);
        break;
      case 2:
        onCircleClick(e);
        break;
      case 3:
        onPolylineClick(e);
        break;
      default:
        break;
    }
  };

  const handlePathChange = (fenceUpdated: FenceModel | ((prevState: FenceModel) => FenceModel)) => {
    setUndoStack((prevState) => [...prevState, fence.pontos]);
    // setPath(newPath);
    setFence(fenceUpdated);
    setRedoStack([]);
  };

  const undo = useCallback(() => {
    if (undoStack.length === 0) return;
    const newUndoStack = [...undoStack];
    const previousPath = newUndoStack.pop();
    // setPath(previousPath || []);
    setFence((prevState) => ({ ...prevState, pontos: previousPath || [] }));
    setUndoStack(newUndoStack);
    setRedoStack((prevState) => [...prevState, fence.pontos]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fence.pontos, undoStack]);

  const redo = useCallback(() => {
    if (redoStack.length === 0) return;
    const newRedoStack = [...redoStack];
    const newPath = newRedoStack.pop();
    // setPath(newPath || []);
    setFence((prevState) => ({ ...prevState, pontos: newPath || [] }));
    setUndoStack((prevState) => [...prevState, fence.pontos]);
    setRedoStack(newRedoStack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fence.pontos, redoStack]);

  const deleteAll = () => {
    setUndoStack([...undoStack, fence.pontos]);
    // setPath([]);
    setFence((prevState) => ({ ...prevState, pontos: [] }));
    setRedoStack([]);
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const mapDiv = mapRef.current?.getDiv();
      if (!mapDiv || !mapDiv.contains(document.activeElement)) return;

      if (event.ctrlKey && event.key === 'z') {
        undo();
      }
      if (event.ctrlKey && event.key === 'y') {
        redo();
      }
      if (event.ctrlKey && event.shiftKey && (event.key === 'Z' || event.key === 'z')) {
        redo();
      }
    },
    [redo, undo],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const onPolygonLoad = (polygon: google.maps.Polygon) => {
    polygonRef.current = polygon;
  };

  const onPolygonClick = (e: google.maps.MapMouseEvent) => {
    if (e.domEvent instanceof MouseEvent && !e.domEvent.ctrlKey && e.latLng && mapRef.current) {
      const newPoint = { lat: e.latLng!.lat(), lng: e.latLng!.lng() };
      handlePathChange((prevState) => ({ ...prevState, pontos: [...prevState.pontos, newPoint] }));
    }
  };

  const onPolygonDragEnd = () => {
    if (polygonRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newPolygonPath = (polygonRef.current as any)
        .getPath()
        .getArray()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((latlng: any) => {
          return { lat: latlng.lat(), lng: latlng.lng() };
        });
      handlePathChange((prevState) => ({ ...prevState, pontos: newPolygonPath }));
    }
  };

  const onMarkerDragEnd = (index: number, event: google.maps.MapMouseEvent) => {
    const newLatLng = event.latLng;
    if (!newLatLng) return;
    handlePathChange((prevState) => ({
      ...prevState,
      pontos: prevState.pontos.map((p, idx) => {
        if (idx === index) {
          return { lat: newLatLng.lat(), lng: newLatLng.lng() };
        }
        return p;
      }),
    }));
  };

  const onCircleClick = (e: google.maps.MapMouseEvent) => {
    if (e.domEvent instanceof MouseEvent && !e.domEvent.ctrlKey && e.latLng && mapRef.current) {
      // setCircleCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      setFence((prevState) => ({ ...prevState, pontos: [{ lat: e.latLng!.lat(), lng: e.latLng!.lng() }] }));
    }
  };

  const onPolylineClick = (e: google.maps.MapMouseEvent) => {
    if (e.domEvent instanceof MouseEvent && !e.domEvent.ctrlKey && e.latLng && mapRef.current) {
      const newPoint = { lat: e.latLng!.lat(), lng: e.latLng!.lng() };
      // handlePathChange((prevState) => [...prevState, pontos: newPoint]);
      handlePathChange((prevState) => ({ ...prevState, pontos: [...prevState.pontos, newPoint] }));
    }
  };

  const onPlacesChanged: StandaloneSearchBoxProps['onPlacesChanged'] = () => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();
      const place = places?.[0];

      if (place && place.geometry && place.geometry.location && mapRef.current) {
        mapRef.current.panTo(place.geometry.location);
        mapRef.current.setZoom(15);
      }
    }
  };

  const onMapLoad: GoogleMapProps['onLoad'] = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    map.setOptions({
      gestureHandling: 'cooperative',
      fullscreenControl: true,
      zoomControl: false,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      },
    });
    if (map && controlsRef.current) {
      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlsRef.current);
    }
  }, []);

  useEffect(() => {
    handlePathChange((prevState) => ({ ...prevState, pontos: [] }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fence.idTipoCerca]);

  return (
    <LoadScript googleMapsApiKey={appSettings().MAPS_API_KEY || ''} libraries={['places', 'geometry']}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={getMapInitialPoint()}
        zoom={14}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        <StandaloneSearchBox
          onLoad={(ref) => {
            if (ref) searchBoxRef.current = ref;
          }}
          onPlacesChanged={onPlacesChanged}
        >
          <Input
            type="text"
            placeholder="Pesquisar no Google Maps"
            style={{
              position: 'absolute',
              top: '10px',
              left: '10px',
              width: '400px',
              borderRadius: '2.5rem',
              paddingLeft: '20px',
            }}
          />
        </StandaloneSearchBox>
        {fence?.pontos?.length > 0 && fence.idTipoCerca === 1 && (
          <Polygon
            ref={polygonRef}
            path={fence.pontos}
            key={1}
            onClick={onPolygonClick}
            onLoad={onPolygonLoad}
            draggable={true}
            onDragEnd={onPolygonDragEnd}
            options={{
              fillColor: '#e99e9b',
              fillOpacity: 0.5,
              strokeColor: 'red',
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
          />
        )}
        {fence?.pontos?.length > 0 && fence.idTipoCerca === 3 && (
          <Polyline
            path={fence.pontos}
            key={1}
            onClick={onPolylineClick}
            draggable={false}
            options={{
              strokeColor: 'red',
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
          />
        )}
        {fence.pontos.map((vertex, index) => (
          <Marker
            key={index}
            position={{ lat: vertex.lat, lng: vertex.lng }}
            draggable={true}
            onDragEnd={(e) => onMarkerDragEnd(index, e)}
            icon={{
              path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0', // Cria um círculo
              fillColor: 'red',
              fillOpacity: 1.0,
              scale: 1,
              strokeColor: 'red',
              strokeWeight: 1,
            }}
          />
        ))}
        {fence.idTipoCerca === 2 && (
          <Circle
            center={fence.pontos[0]}
            radius={fence.raio}
            onClick={onCircleClick}
            draggable={true}
            onDragStart={(e) => {
              // Store the starting position
              setDragStart({
                lat: e.latLng!.lat(),
                lng: e.latLng!.lng(),
              });
            }}
            onDragEnd={(e) => {
              // Ending position
              const endPos = {
                lat: e.latLng!.lat(),
                lng: e.latLng!.lng(),
              };

              // Calculate the offset
              const offset = {
                lat: endPos.lat - dragStart!.lat,
                lng: endPos.lng - dragStart!.lng,
              };

              // Update the center position of the circle
              setFence((prevState) => ({
                ...prevState,
                pontos: [
                  {
                    lat: prevState.pontos[0].lat + offset.lat,
                    lng: prevState.pontos[0].lng + offset.lng,
                  },
                ],
              }));
            }}
            options={{
              fillColor: '#e99e9b',
              fillOpacity: 0.5,
              strokeColor: 'red',
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
          />
        )}
        {/* Fim Cerca atual */}

        <SavedFences fences={fences} showInactiveFences={showInactiveFences} />

        <S.ButtonsMapWrapper>
          {fence.idTipoCerca === 3 && (
            <>
              <Input
                style={{ height: '2.5rem', width: '11rem' }}
                placeholder="Digite a largura (m)"
                value={fence.largura === undefined ? '' : fence.largura}
                onChange={(event) => {
                  const value = event.target.value;
                  const sanitizedValue = value.replace(/[^\d]/g, '');

                  setFence((prevState) => ({
                    ...prevState,
                    largura: sanitizedValue === '' ? undefined : Number(sanitizedValue),
                  }));
                }}
              />
            </>
          )}
          {(fence.idTipoCerca === 1 || fence.idTipoCerca === 3) && (
            <>
              <UndoRedoDeleteButtons undo={undo} redo={redo} delete={deleteAll} />
              <PointCount maxValue={maxPointsPerFence || 0} currentValue={fence?.pontos?.length || 0} />
              <ZoomButtons mapRef={mapRef} />
            </>
          )}
          {fence.idTipoCerca === 2 && (
            <>
              <Input
                style={{ height: '2.5rem', width: '9.5rem' }}
                placeholder="Digite o raio (m)"
                value={fence.raio === undefined ? '' : fence.raio}
                onChange={(event) => {
                  const value = event.target.value;
                  const sanitizedValue = value.replace(/[^\d]/g, '');

                  setFence((prevState) => ({
                    ...prevState,
                    raio: sanitizedValue === '' ? undefined : Number(sanitizedValue),
                  }));
                }}
              />
              <UndoRedoDeleteButtons undo={undo} redo={redo} delete={deleteAll} />
              <ZoomButtons mapRef={mapRef} />
            </>
          )}
        </S.ButtonsMapWrapper>
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponentCreate;
