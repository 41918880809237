/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Modal } from '@app/components/common/Modal/Modal';
import { Col, Row } from 'antd';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import {
  CanVehicleModel,
  CanVehicleParametersCalculatedModel,
  CanVehicleParametersOnOffModel,
} from '@app/domain/canVehicle/canVehicle';
import { Button, ButtonGroup } from '@app/components/common/buttons/Button/Button';
import React from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { Radio, RadioChangeEvent, RadioGroup } from '@app/components/common/Radio/Radio';
import { Input } from '@app/components/common/inputs/Input/Input';
import { CanVehicleBitsSize, CanVehicleInitialBit } from '@app/constants/canVehicleData';
import { CanVehicleParameterType } from '@app/constants/enums/canVehicle/can-vehicle-parameter-type';

interface ModalEditCanVehicleProps {
  editParametersCalculated?: CanVehicleParametersCalculatedModel;
  setEditParametersCalculated?: React.Dispatch<React.SetStateAction<CanVehicleParametersCalculatedModel>>;
  editParametersOnOff?: CanVehicleParametersOnOffModel;
  setEditParametersOnOff?: React.Dispatch<React.SetStateAction<CanVehicleParametersOnOffModel>>;
  modalEditVisible: boolean;
  setModalEditVisible: React.Dispatch<React.SetStateAction<boolean>>;
  canVehicle: CanVehicleModel;
  setCanVehicle: React.Dispatch<React.SetStateAction<CanVehicleModel>>;
  parameterType: CanVehicleParameterType;
  nameId: string;
  measureUnit: string;
}

export const ModalEditCanVehicle: React.FC<ModalEditCanVehicleProps> = ({
  editParametersCalculated,
  editParametersOnOff,
  modalEditVisible,
  setModalEditVisible,
  parameterType,
  nameId,
  measureUnit,
  setCanVehicle,
  canVehicle,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [parameterTypeEdited, setParameterTypeEdited] = useState<CanVehicleParameterType | null>(null);
  const [calculated, setCalculated] = useState<CanVehicleParametersCalculatedModel>(
    {} as CanVehicleParametersCalculatedModel,
  );
  const [onOff, setOnOff] = useState<CanVehicleParametersOnOffModel>({} as CanVehicleParametersOnOffModel);

  const handleUpdateFieldsCalculated = () => {
    setCalculated(editParametersCalculated ?? ({} as CanVehicleParametersCalculatedModel));
  };

  const handleUpdateFieldsOnOff = () => {
    setOnOff(editParametersOnOff ?? ({} as CanVehicleParametersOnOffModel));
  };

  const handleFinish = () => {
    setLoading(true);
    if (parameterType === CanVehicleParameterType.Calculado) {
      const index = canVehicle.parametroCalculado.findIndex((pc) => pc.nomeId === editParametersCalculated?.nomeId);

      const newCalculated = canVehicle.parametroCalculado;
      if (index > -1) {
        newCalculated[index] = calculated;
        setCanVehicle({
          ...canVehicle,
          parametroCalculado: [...newCalculated],
        });
      }
    } else {
      const index = canVehicle.parametroLigadoDesligado.findIndex((pc) => pc.nomeId === editParametersOnOff?.nomeId);

      const newOnOff = canVehicle.parametroLigadoDesligado;
      if (index > -1) {
        newOnOff[index] = onOff;
        setCanVehicle({
          ...canVehicle,
          parametroLigadoDesligado: [...newOnOff],
        });
      }
    }

    setLoading(false);
    setModalEditVisible(false);
  };

  useEffect(() => {
    if (editParametersCalculated !== null) {
      handleUpdateFieldsCalculated();
    }
    if (editParametersOnOff !== null) {
      handleUpdateFieldsOnOff();
    }
  }, [editParametersCalculated, editParametersOnOff]);

  return (
    <>
      <Modal
        title="Editar parâmetros"
        open={modalEditVisible}
        onOk={handleFinish}
        onCancel={() => setModalEditVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Row gutter={6}>
            <Col xs={12}>
              <BaseFormInputItem label="Nome ID" errorText="Campo obrigatório">
                <Select
                  showArrow
                  showSearch
                  disabled={true}
                  placeholder="Selecione o tipo"
                  value={nameId}
                  // onChange={(value) =>
                  //   setNameIdSelected(nameIds.find((n) => n.id == value) ?? ({} as CanVehicleNameId))
                  // }
                  // options={nameIds.map((c) => ({
                  //   value: c.id,
                  //   label: `${c.nome}`,
                  // }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
            </Col>
            <Col xs={12}>
              <BaseFormInputItem label=" " errorText="" style={{ marginTop: '1.25rem' }}>
                <ButtonGroup>
                  <Button key={measureUnit} type="primary">
                    {measureUnit}
                  </Button>
                </ButtonGroup>
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col xs={24} md={12}>
              <BaseFormInputItem label="Tipo de dado">
                <RadioGroup
                  disabled={true}
                  name="parameterType"
                  onChange={(e: RadioChangeEvent) => setParameterTypeEdited(e.target.value)}
                  value={
                    parameterTypeEdited ?? parameterType === CanVehicleParameterType.LigadoDesligado
                      ? 'OnOff'
                      : parameterType
                  }
                  style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                >
                  <Radio value="Calculado">Calculado</Radio>
                  <Radio value="OnOff">ON/OFF</Radio>
                </RadioGroup>
              </BaseFormInputItem>
            </Col>
          </Row>
          {parameterType === 'Calculado' ? (
            <>
              <Row gutter={6}>
                <Col xs={6}>
                  <BaseFormInputItem label="ID" errorText="Campo obrigatório">
                    <Input
                      placeholder="Digite o ID"
                      value={calculated.parametroId}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setCalculated({
                          ...calculated,
                          parametroId: event.currentTarget.value,
                        })
                      }
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={9}>
                  <BaseFormInputItem label="Offset" errorText="Campo obrigatório">
                    <Input
                      placeholder="Digite o offset"
                      value={calculated.offset}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setCalculated({
                          ...calculated,
                          offset: event.currentTarget.value,
                        })
                      }
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={9}>
                  <BaseFormInputItem label="Resolução" errorText="Campo obrigatório">
                    <Input
                      placeholder="Digite a resolução"
                      value={calculated.resolucao}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setCalculated({
                          ...calculated,
                          resolucao: event.currentTarget.value,
                        })
                      }
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
              <Row gutter={6}>
                <Col xs={12}>
                  <BaseFormInputItem label="Bit inicial" errorText="Campo obrigatório">
                    <Select
                      showArrow
                      showSearch
                      placeholder="0 a 63"
                      value={calculated.bitInicial}
                      onChange={(value) => setCalculated({ ...calculated, bitInicial: Number(value as string) })}
                      options={CanVehicleInitialBit.map((c) => ({
                        value: c,
                        label: `${c}`,
                      }))}
                      filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={12}>
                  <BaseFormInputItem label="Tamanho em bits" errorText="Campo obrigatório">
                    <Select
                      showArrow
                      showSearch
                      placeholder="1 a 63"
                      value={calculated.tamanhoBits}
                      onChange={(value) => setCalculated({ ...calculated, tamanhoBits: Number(value as string) })}
                      options={CanVehicleBitsSize.map((c) => ({
                        value: c,
                        label: `${c}`,
                      }))}
                      filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row gutter={6}>
                <Col xs={6}>
                  <BaseFormInputItem label="ID" errorText="Campo obrigatório">
                    <Input
                      placeholder="Digite o ID"
                      value={onOff.parametroId}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setOnOff({
                          ...onOff,
                          parametroId: event.currentTarget.value,
                        })
                      }
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={9}>
                  <BaseFormInputItem label="Bit inicial" errorText="Campo obrigatório">
                    <Select
                      showArrow
                      showSearch
                      placeholder="0 a 63"
                      value={onOff.bitInicial}
                      onChange={(value) => setOnOff({ ...onOff, bitInicial: Number(value as string) })}
                      options={CanVehicleInitialBit.map((c) => ({
                        value: c,
                        label: `${c}`,
                      }))}
                      filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={9}>
                  <BaseFormInputItem label="Tamanho em bits" errorText="Campo obrigatório">
                    <Select
                      showArrow
                      showSearch
                      placeholder="1 a 63"
                      value={onOff.tamanhoBits}
                      onChange={(value) => setOnOff({ ...onOff, tamanhoBits: Number(value as string) })}
                      options={CanVehicleBitsSize.map((c) => ({
                        value: c,
                        label: `${c}`,
                      }))}
                      filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
              <Row gutter={6}>
                <Col xs={6}>
                  <BaseFormInputItem label="Ligado" errorText="Campo obrigatório">
                    <RadioGroup
                      name="ligado"
                      onChange={(e: RadioChangeEvent) =>
                        setOnOff((prevState) => ({
                          ...prevState,
                          ligado: e.target.value ? 1 : 0,
                          desligado: e.target.value ? 0 : 1,
                        }))
                      }
                      value={onOff.ligado === 1 ? true : false}
                      style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                    >
                      <Radio value={true}>1</Radio>
                      <Radio value={false}>0</Radio>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
                <Col xs={6}>
                  <BaseFormInputItem label="Desligado" errorText="Campo obrigatório">
                    <RadioGroup
                      name="desligado"
                      onChange={(e: RadioChangeEvent) =>
                        setOnOff((prevState) => ({
                          ...prevState,
                          desligado: e.target.value ? 1 : 0,
                          ligado: e.target.value ? 0 : 1,
                        }))
                      }
                      value={onOff.desligado === 1 ? true : false}
                      style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                    >
                      <Radio value={true}>1</Radio>
                      <Radio value={false}>0</Radio>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
              </Row>
            </>
          )}
        </BaseForm>
      </Modal>
      <Spinner spinning={loading}></Spinner>
    </>
  );
};
