import React, { FC } from 'react';
import * as S from './modules.styles';
import { DeviceProfilePropertyModel, PropertyValue } from '@app/domain/deviceProfile/deviceProfileModel';
import Rede from '@app/components/common/ModulesProfileConfig/Rede/Rede';
import PerfilEnvio from '@app/components/common/ModulesProfileConfig/PerfilEnvio/PerfilEnvio';
import Eventos from '@app/components/common/ModulesProfileConfig/Eventos/Eventos';
import Entrada from '@app/components/common/ModulesProfileConfig/Entrada/Entrada';
import { CercaConfigUpdate } from '@app/components/common/ModulesProfileConfig/Cerca/CercaConfigUpdate';
import { SendParameter } from '@app/store/slices/sendParametersSlice';

interface ModuleComponentProps {
  properties: PropertyValue[];
  setProperties: React.Dispatch<React.SetStateAction<PropertyValue[]>>;
  moduleData: DeviceProfilePropertyModel[];
  moduleName: string;
  setPropertyValues: (data: PropertyValue[]) => void;
  show?: boolean;
  idGroup?: number;
  idModel: number;
  setIdGroup: React.Dispatch<React.SetStateAction<number | undefined>>;
  sendParameters: SendParameter[];
}

const ModulesComponent: FC<ModuleComponentProps> = ({
  properties,
  setProperties,
  moduleData,
  moduleName,
  setPropertyValues,
  show = true,
  idGroup,
  idModel,
  setIdGroup,
  sendParameters,
}) => {
  const renderModuleContent = (name: string) => {
    switch (name) {
      case 'Rede':
        return (
          <Rede
            properties={properties}
            setProperties={setProperties}
            moduleData={moduleData}
            setPropertyValues={setPropertyValues}
          />
        );
      case 'Entrada':
        return (
          <Entrada
            properties={properties}
            setProperties={setProperties}
            moduleData={moduleData}
            setPropertyValues={setPropertyValues}
          />
        );
      case 'PerfilEnvio':
        return (
          <PerfilEnvio
            properties={properties}
            setProperties={setProperties}
            moduleData={moduleData}
            setPropertyValues={setPropertyValues}
            parameters={sendParameters}
          />
        );
      case 'Evento':
        return (
          <Eventos
            properties={properties}
            setProperties={setProperties}
            moduleData={moduleData}
            setPropertyValues={setPropertyValues}
          />
        );
      case 'Cercas':
        return <CercaConfigUpdate idGroup={idGroup} setIdGroup={setIdGroup} idModel={idModel} />;
      // case 'CAN':
      //   return <></>;
      default:
        return <></>;
    }
  };

  return (
    <S.Container style={{ display: `${!show ? 'none' : 'block'}` }}>
      <S.Wrapper>
        {/* <div key={moduleName} style={{ paddingLeft: '0px', width: '100%', overflowX: 'hidden' }}> */}
        {renderModuleContent(moduleName)}
        {/* </div> */}
      </S.Wrapper>
    </S.Container>
  );
};

export default ModulesComponent;
