import { InfoCircleOutlined } from '@ant-design/icons';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input, TextArea } from '@app/components/common/inputs/Input/Input';
import { Modal } from '@app/components/common/Modal/Modal';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { notificationController } from '@app/controllers/notificationController';
import { SystemParameterModel, TypeSystemParameterModel } from '@app/domain/systemParameters/systemParameterModel';
import ISystemParameterService, { SystemParameterService } from '@app/services/systemParameterService';
import { setFooter } from '@app/store/slices/footerSlice';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Col, Row, Select } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const parameterService: ISystemParameterService = new SystemParameterService();

export const SystemParametersCreate: FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalCancel, setModalCancel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [types, setTypes] = useState<TypeSystemParameterModel[]>([]);
  const [parameter, setParameter] = useState<SystemParameterModel>({} as SystemParameterModel);
  const [typeSelected, setTypeSelected] = useState<string | number[]>([]);

  const handleSave = () => {
    setLoading(true);

    // eslint-disable-next-line prefer-const
    let parameterToSave = parameter;

    typeof typeSelected[0] == 'number'
      ? (parameterToSave.idTipo = typeSelected[0])
      : (parameterToSave.tipo = typeSelected[0]);

    if (id) {
      parameterService
        .update('', parameterToSave)
        .then(() => {
          setLoading(false);
          notificationController.success({ message: 'Sucesso!', description: 'Parâmetro salvo.' });
          navigate('/parametros-sistema');
        })
        .catch((err) => {
          console.error(err);

          setLoading(false);
          notificationController.error({
            message: 'Erro!',
            description: err?.message ?? 'Houve um problema ao cadastrar o parâmetro.',
          });
        });
      return;
    }

    parameterService
      .post('', parameterToSave)
      .then(() => {
        setLoading(false);
        notificationController.success({ message: 'Sucesso!', description: 'Parâmetro cadastrado.' });
        navigate('/parametros-sistema');
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({
          message: 'Erro!',
          description: err?.message ?? 'Houve um problema ao cadastrar o parâmetro.',
        });
      });
  };

  const fetch = useCallback(async () => {
    setLoading(true);

    if (id) {
      try {
        const parameterResponse = await parameterService.get(id);
        setParameter(parameterResponse);
        parameterResponse.idTipo && setTypeSelected([parameterResponse.idTipo]);
      } catch (error) {
        setLoading(false);
        notificationController.error({
          message: 'Erro!',
          description: 'Não foi possível o parâmetro.',
        });
        return;
      }
    }

    parameterService
      .getTypes()
      .then((res) => {
        setLoading(false);
        setTypes(res);
      })
      .catch(() => {
        setLoading(false);
        notificationController.error({
          message: 'Erro!',
          description: 'Não foi possível obter os tipos dos parâmetros.',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetch();
    dispatch(
      setHeaderRegister({
        title: !id ? 'Novo Parâmetro' : 'Editar Parâmetro',
        handleBackClick: () => setModalCancel(true),
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      setFooter({
        confirmButtonDisabled: !typeSelected.length || !parameter.chave || !parameter.valor,
        confirmButtonText: id ? 'Salvar' : 'Cadastrar',
        handleCancelButtonClick: () => setModalCancel(true),
        handleConfirmButtonClick: handleSave,
        cancelButtonText: 'Cancelar',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameter, typeSelected]);

  return (
    <PageContainer>
      <Spinner spinning={loading}></Spinner>
      <Modal
        title={`Cancelar ${id ? 'edição' : 'cadastro'} do parâmetro`}
        open={modalCancel}
        onOk={() => navigate('/parametros-sistema')}
        onCancel={() => setModalCancel(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            Deseja realmente<strong>&nbsp;cancelar {id ? 'a edição' : 'o cadastro'} do parâmetro?</strong>
          </Col>
        </Row>
      </Modal>
      <BaseForm layout="vertical" style={{ width: '100%' }}>
        <Row>
          <Col xs={24} md={8}>
            <BaseFormInputItem
              label="Tipo do parâmetro"
              supportText="Lembre de pesquisar o tipo antes de cadastrar um novo"
            >
              <Select
                disabled={!!id}
                mode="tags"
                showSearch
                showArrow
                allowClear
                onClear={() => setTypeSelected([])}
                onChange={(value) => (typeSelected.length == 0 || !value.length) && setTypeSelected(value)}
                placeholder="Selecione ou escreva o tipo"
                value={typeSelected}
                options={types.map((c) => ({
                  value: c.id,
                  label: `${c.nome}`,
                }))}
                filterOption={(input, option) => (option?.label.toLowerCase().indexOf(input.toLowerCase()) ?? 0) > 0}
              />
            </BaseFormInputItem>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={8}>
            <BaseFormInputItem
              label="Chave"
              supportText="Valor de identificação do parâmetro"
              errorText="Campo inválido"
            >
              <Input
                placeholder="Digite a chave"
                value={parameter.chave}
                onChange={(e) => setParameter({ ...parameter, chave: e.target.value })}
              />
            </BaseFormInputItem>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={8}>
            <BaseFormInputItem label="Valor" supportText="Valor a ser retornado" errorText="Campo inválido">
              <TextArea
                placeholder="Digite a valor"
                value={parameter.valor}
                onChange={(e) => setParameter({ ...parameter, valor: e.target.value })}
              />
            </BaseFormInputItem>
          </Col>
        </Row>
      </BaseForm>
    </PageContainer>
  );
};
