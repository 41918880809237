import { IconMore } from '@app/assets/slump-icons';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Modal } from '@app/components/common/Modal/Modal';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import { Tag } from '@app/components/common/Tag/Tag';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import Dashboard from '@app/components/dashboard/Dashboard';
import { notificationController } from '@app/controllers/notificationController';
import { UserModel } from '@app/domain/identity/userModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IUserIdentityService, { UserIdentityService } from '@app/services/identity/userIdentityService';
import { setHeader } from '@app/store/slices/headerSlice';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import InitialsAvatar from 'react-initials-avatar';
import { Row } from 'antd';
import { UserIdentityModel } from '@app/domain/identity/responseModels/userIdentityModel';
import IAuthService, { AuthService } from '@app/services/authService';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const userService: IUserIdentityService = new UserIdentityService();
const authService: IAuthService = new AuthService();

export const UserDashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [userSelected, setUserSelected] = useState<UserModel>();
  const [users, setUsers] = useState<UserModel[]>([]);
  const [usersFiltered, setUsersFiltered] = useState<UserModel[]>([]);
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);

  const handleEditClick = (user: UserModel) => navigate(`/usuarios/editar/${user.id}`);
  const handleDeleteClick = (user: UserModel) => {
    setModalDeleteVisible(true);
    setUserSelected(user);
  };
  const handleNewUserClick = () => navigate(`/usuarios/cadastrar`);
  const handleExportToExcel = () => {
    genericExportToExcel(
      'usuarios',
      columns,
      usersFiltered?.map((u) => ({ ...u, lockoutEnabled: u.lockoutEnabled ? 'Ativo' : 'Inativo' })) ?? [],
    );
  };
  const handleSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setUsersFiltered(
        users?.filter(
          (item) =>
            item.userName?.toString().toLowerCase()?.includes(inputValue) ||
            item.email?.toString().toLowerCase()?.includes(inputValue) ||
            item.phoneNumber?.includes(inputValue),
        ),
      );
    } else {
      setUsersFiltered(users);
    }
  };
  const handleDeleteUser = async () => {
    try {
      setLoading(true);
      setModalDeleteVisible(false);

      await userService.api.put<UserModel>(``, {
        ...userSelected,
        lockoutEnabled: !userSelected?.lockoutEnabled,
      });

      if (!userSelected?.lockoutEnabled) {
        await authService.api.post(`confirmar-acesso/${userSelected?.id}`);
      }

      notificationController.success({
        message: `${
          userSelected?.lockoutEnabled
            ? 'Desativação realizada com sucesso!'
            : 'Ativação realizada com sucesso! E-mail enviado com sucesso! Por favor peça ao usuário verificar a caixa de Spam.'
        }`,
        duration: userSelected?.lockoutEnabled ? 2 : 20,
        style: { height: '2rem' },
      });

      await fetch();

      setUserSelected(undefined);
      setLoading(false);
    } catch (error) {
      notificationController.error({
        message: 'Falha ao deletar usuário!',
        description: `Usuário: ${userSelected?.userName}`,
      });
      setLoading(false);
    }
  };

  const fetch = async () => {
    try {
      setLoading(true);

      const usersResponse = await userService.api.get<UserIdentityModel>('?pageSize=1000000');
      setUsers(usersResponse?.data?.users);

      setLoading(false);
    } catch (error) {
      console.error(error);
      notificationController.error({ message: 'Erro!', description: 'Ocorreu um erro ao buscar os usuários.' });
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(
      setHeader({
        title: 'Usuários',
      }),
    );
    fetch();
  }, [dispatch]);

  useEffect(() => {
    const usersSorted = users.sort((a, b) => (a.userName > b.userName ? 1 : a.userName < b.userName ? -1 : 0));
    setUsersFiltered(usersSorted);
  }, [users]);

  const columns: ColumnsType<UserModel> = [
    // {
    //   title: 'id',
    //   dataIndex: 'id',
    //   showSorterTooltip: false,
    // },
    {
      title: 'Usuário',
      dataIndex: 'userName',
      showSorterTooltip: true,
      width: '200px',
      sorter: (a, b) => (a.userName ?? '').localeCompare(b.userName ?? ''),
      render: (userName) => (
        <Row gutter={[18, 18]} style={{ marginLeft: '.2rem' }} align="middle">
          <InitialsAvatar name={userName.replace('.', ' ')} className="avatarUser" />
          <div style={{ marginLeft: '1rem' }}>{userName}</div>
        </Row>
      ),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      showSorterTooltip: true,
      sorter: (a, b) => (a.email ?? '').localeCompare(b.email ?? ''),
      width: '40%',
    },
    {
      title: 'Telefone',
      dataIndex: 'phoneNumber',
      showSorterTooltip: true,
      sorter: (a, b) => (a.phoneNumber ?? '').localeCompare(b.phoneNumber ?? ''),
      width: '150px',
    },
    // {
    //   title: 'Perfil',
    //   dataIndex: 'imei',
    //   showSorterTooltip: false,
    // },
    {
      title: 'Status',
      dataIndex: 'lockoutEnabled',
      showSorterTooltip: true,
      sorter: (a, b) => (a.lockoutEnabled ? 1 : 0) - (b.lockoutEnabled ? 1 : 0),
      width: '100px',
      render: (status) => (
        <>
          {status ? (
            <Tag color="#E9F4EE" style={{ color: '#083F18' }}>
              Ativo
            </Tag>
          ) : (
            <Tag color="#FEE9EA" style={{ color: '#620E12' }}>
              Inativo
            </Tag>
          )}
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      width: '5%',
      render: (_, user) => (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
          <DropdownTable
            buttonText=""
            iconD={
              <div style={{ color: '#545454' }}>
                <IconMore />
              </div>
            }
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu>
                <MenuItem onClick={() => handleEditClick(user)}>
                  <Button type="text">Editar</Button>
                </MenuItem>
                <MenuItem onClick={() => handleDeleteClick(user)}>
                  <Button type="text">{user.lockoutEnabled ? 'Desativar' : 'Ativar'}</Button>
                </MenuItem>
              </Menu>
            }
          ></DropdownTable>
        </div>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="Deletar usuário"
        open={modalDeleteVisible}
        onOk={() => handleDeleteUser()}
        onCancel={() => setModalDeleteVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <SpinnerSlump spinning={loading}>
          {userSelected?.lockoutEnabled ? (
            <>
              <CloseCircleOutlined style={{ color: 'var(--error-color)', marginRight: '1rem' }} />
              Deseja realmente desativar o usuário <strong>{userSelected?.userName}</strong> selecionado?
            </>
          ) : (
            <>
              <CheckCircleOutlined style={{ color: 'var(--success-color)', marginRight: '1rem' }} />
              Deseja realmente ativar o usuário <strong>{userSelected?.userName}</strong> selecionado?
            </>
          )}
        </SpinnerSlump>
      </Modal>
      <PageTitle>Usuários</PageTitle>
      <Spinner spinning={loading}>
        <Dashboard
          buttonText="Cadastrar novo usuário"
          handleButtonClick={handleNewUserClick}
          placeholderTextSearch="Pesquisar"
          handleSearchOnChange={handleSearchFilter}
          table={<Table columns={columns} dataSource={usersFiltered} bordered />}
          exportToExcel={handleExportToExcel}
        />
      </Spinner>
    </>
  );
};
