import styled from 'styled-components';
import { Checkbox } from '../Checkbox/Checkbox';
import { Collapse as AntdCollapse } from 'antd';

export const Collapse = styled(AntdCollapse)`
  background-color: transparent !important;
  border-bottom: 1px solid var(--border-base);
  border-radius: 8px;
  border: 1px solid var(--Neutral-Gray-Gray300, #bababa);
  background: #fff;

  && .ant-collapse-header {
    align-items: center !important;
    border-bottom: 0 !important;
  }
  && .ant-collapse-content {
    border-top: 0 !important;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1.5rem;
  //gap: 1.5rem;
  width: 100%;
`;

export const InputObs = styled.span`
  font-size: 0.75rem;
  color: #999;
`;

export const FieldCheckboxValue = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2.7rem;
  margin-bottom: 0.5rem;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 0.5rem;
  width: 16rem;
`;

export const CheckboxDisabled = styled(Checkbox)`
  pointer-events: none;
  opacity: 0.5;
`;

export const CheckboxLabel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
  white-space: nowrap;

  color: var(--neutral-gray-gray-700, #545454);
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
