import { RoleModel } from '@app/domain/identity/roleModel';
import { ApiIdentityServiceBase, IApiIdentityServiceBase } from '../base/apiIdentityServiceBase';

export default interface IRoleIdentityService extends IApiIdentityServiceBase<RoleModel, RoleModel> {}

export class RoleIdentityService extends ApiIdentityServiceBase<RoleModel, RoleModel> {
  constructor() {
    super('roles');
  }
}
