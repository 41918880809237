import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 0.5rem;
  border: 1px solid var(--neutral-gray-gray-300, #bababa);
  padding: 2rem;
`;

export const Title = styled.div`
  color: var(--neutral-gray-gray-800, #383838);
  font-family: Mulish;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.125rem;
  background: #d9d9d9;
  margin: 1.5rem 0;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  margin-top: 1.5rem;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoItemTitle = styled.div`
  color: var(--neutral-gray-gray-500, #8c8c8c);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const InfoItemValue = styled.div`
  color: var(--neutral-gray-gray-700, #545454);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ClientContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.5rem;
`;

export const ClientTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const ClientLabel = styled.span`
  color: var(--neutral-gray-gray-500, #8c8c8c);
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const ClientName = styled.div`
  color: var(--neutral-gray-gray-700, #545454);
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const EquipmentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

export const EquipmentQuantity = styled.span`
  color: var(--neutral-gray-gray-700, #545454);
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
`;

export const EquipmentLabel = styled.span`
  color: var(--neutral-gray-gray-500, #8c8c8c);
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const EquipmentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;
  column-gap: 4rem;
  row-gap: 0.8rem;
`;

export const EquipmentItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

export const EquipmentVersion = styled.div`
  color: #545454;
  font-family: Mulish;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const EquipmentStatus = styled.div``;
