import React from 'react';
import * as S from './CardDetails.styles';

import { CardProps as AntCardProps } from 'antd';

interface ICardDetailsProps extends AntCardProps {
  title: string;
}

const CardDetails: React.FC<ICardDetailsProps> = ({ title, children }) => {
  return (
    <>
      <S.CardDetails>
        <S.Title>{title}</S.Title>
        {children}
      </S.CardDetails>
    </>
  );
};

export default CardDetails;
