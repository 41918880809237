import { Spinner } from '@app/components/common/Spinner/Spinner';
import React, { useEffect, useState } from 'react';
import { notificationController } from '@app/controllers/notificationController';
// import * as S from '../index.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Button, Col, Input, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ReactComponent as ExportCsvIcon } from '@app/assets/icons/export-csv.svg';
import { TagGroupModel } from '@app/domain/tagGroup/TagGroupModel';
import { TagsGroupService } from '@app/services/tagsGroupService';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { ExportButtonContainer } from '@app/pages/tags-group/index.styles';
import { TableData } from '@app/pages/tags-group/components/TableData/TableData';

interface GrupoTagProps {
  idGroupTag?: number;
}

const tagGroupService = new TagsGroupService();

export const GrupoTagView: React.FC<GrupoTagProps> = ({ idGroupTag }) => {
  const [loading, setLoading] = useState(false);
  const [tagGroup, setTagGroup] = useState<TagGroupModel>({} as TagGroupModel);
  const [searchValue, setSearchValue] = useState('');

  const handleSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    const inputValue = event.currentTarget.value.toLowerCase();
    setSearchValue(inputValue);
  };

  const fetchGroupTagId = (id: number) => {
    tagGroupService
      .get(`${id}`)
      .then((response) => {
        setTagGroup(response);
        setLoading(false);
      })
      .catch((err) => {
        notificationController.error({ message: `Erro ao buscar grupo de tags. ${err}` });
      });
  };

  useEffect(() => {
    if (idGroupTag && idGroupTag != 0) {
      fetchGroupTagId(idGroupTag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idGroupTag]);

  const handleExportDataExcel = async () => {
    const columns = [
      { title: 'Nome', dataIndex: 'nome' },
      { title: 'Tag ID', dataIndex: 'tagId' },
    ];

    const data = tagGroup.tags
      ? tagGroup.tags.map((tag) => ({
          nome: tagGroup.nome,
          tagId: tag,
        }))
      : [];

    await genericExportToExcel('grupo_tags', columns, data);
  };

  return (
    <Spinner spinning={loading}>
      <BaseForm layout="vertical" style={{ width: '100%' }}>
        <Row gutter={[10, 10]} align="middle">
          <Col span={24}>
            <Row gutter={[10, 10]} align="middle" justify="space-between">
              <Col xl={4} md={4} xs={6} sm={24}>
                <BaseFormInputItem label="Pesquisa">
                  <Input
                    suffix={<SearchOutlined style={{ marginRight: '1rem', color: '#545454' }} />}
                    placeholder="Pesquisar por tag ID"
                    onChange={handleSearchFilter}
                  />
                </BaseFormInputItem>
              </Col>
              <Col xl={3} md={3} xs={4} sm={24} style={{ marginBottom: '1.25rem' }}>
                <Button type="link" onClick={handleExportDataExcel}>
                  <ExportButtonContainer>
                    <ExportCsvIcon />
                    Exportar dados excel
                  </ExportButtonContainer>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <TableData
          key="1"
          header={
            <Row align={'middle'} justify={'space-between'}>
              <Col>{tagGroup.nome}</Col>
            </Row>
          }
          data={searchValue ? tagGroup?.tags?.filter((tag) => tag.toLowerCase().includes(searchValue)) : tagGroup.tags}
        />
      </BaseForm>
    </Spinner>
  );
};
