import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './SiderMenu.styles';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { hasAccessByRoles } from '@app/controllers/accessController';
import { RoutesSlumpMix } from '@app/router/routes';

interface SiderContentProps {
  isCollapsed: boolean;
}

const SiderMenu: React.FC<SiderContentProps> = ({ isCollapsed }) => {
  const { t } = useTranslation();
  const routes = RoutesSlumpMix?.filter((nav) => nav.isSideMenu);

  return (
    <S.Menu mode="inline">
      {routes?.map((nav) => {
        if (nav.children && nav.children.length > 0) {
          if (hasAccessByRoles(nav.roles)) {
            return (
              <Menu.SubMenu key={nav.key} title={isCollapsed ? '' : t(nav.title)} icon={nav.icon} popupClassName="">
                {nav.children
                  .filter((childNav) => childNav.isSideMenu)
                  .map(
                    (childNav) =>
                      childNav.roles &&
                      hasAccessByRoles(childNav.roles) && (
                        <Menu.Item key={childNav.key} title="">
                          <Link to={childNav.path || ''}>{t(childNav.title)}</Link>
                        </Menu.Item>
                      ),
                  )}
              </Menu.SubMenu>
            );
          }
        } else {
          if (nav.roles && hasAccessByRoles(nav.roles)) {
            return (
              <Menu.Item key={nav.key} title="" icon={nav.icon}>
                <Link to={nav.path || ''}>{t(nav.title)}</Link>
              </Menu.Item>
            );
          } else if (nav.roles?.length && hasAccessByRoles(nav.roles)) {
            return (
              <Menu.Item key={nav.key} title="" icon={nav.icon}>
                <Link to={nav.path || ''}>{t(nav.title)}</Link>
              </Menu.Item>
            );
          }
        }
      })}
    </S.Menu>
  );
};

export default SiderMenu;
