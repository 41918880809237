import { LAYOUT, media } from '@app/styles/themes/constants';
import { Button, Layout } from 'antd';
import styled, { css } from 'styled-components';

export const Sider = styled(Layout.Sider)`
  position: fixed;
  overflow: visible;
  right: 0;
  z-index: 5;
  min-height: 100vh;
  max-height: 100vh;

  /* color: var(--text-secondary-color); */
  color: '#1C213C';

  @media only screen and ${media.md} {
    right: unset;
    left: 0;
  }

  @media only screen and ${media.xl} {
    position: unset;
  }
`;

export const SiderContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - ${LAYOUT.mobile.headerHeight});

  @media only screen and ${media.md} {
    max-height: calc(100vh - ${LAYOUT.desktop.headerHeight});
  }
`;

export const SiderLogoLink = styled.div<{ $isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;

  ${(props) =>
    props.$isCollapsed &&
    css`
      transition: all 0.2s ease;
      right: 0rem;
    `}

  &:focus {
    color: var(--text-secondary-color);
    background: var(--collapse-background-color);
    border: 1px solid var(--border-color);
  }
`;

export const SiderLogoDiv = styled.div`
  height: ${LAYOUT.mobile.headerHeight};
  padding: ${LAYOUT.mobile.headerPadding};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;

  @media only screen and ${media.md} {
    height: ${LAYOUT.desktop.headerHeight};
    padding-top: ${LAYOUT.desktop.paddingVertical};
    padding-bottom: ${LAYOUT.desktop.paddingVertical};
  }
`;

export const BrandSpan = styled.span`
  margin: 0 1rem;
  font-weight: 700;
  font-size: 1.125rem;
  color: var(--white);
`;

export const CollapseButton = styled(Button)<{ $isCollapsed: boolean }>`
  border-radius: 0rem 0.375rem 0.375rem 0rem;
  border-top: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  background: var(--primary-color);
  border: var(--primary-color);

  transition: all 0.2s ease;
  position: relative;
  right: -3.875rem;

  &.ant-btn-icon-only.ant-btn-sm {
    width: 40px;
  }

  ${(props) =>
    props.$isCollapsed &&
    css`
      right: -2.25rem;
      position: relative;
      &.ant-btn-icon-only.ant-btn-sm {
        width: 60px;
      }
    `}

  color: var(--text-secondary-color);

  &:hover {
    color: var(--text-secondary-color);
    background: var(--primary-color);
  }

  &:focus {
    color: var(--text-secondary-color);
    background: var(--primary-color);
  }
`;
