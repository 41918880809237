import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 4px;
  width: 100%;
`;

export const InputLabel = styled.label`
  color: var(--neutral-gray-gray-700, #545454);
  font-family: Mulish;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;
export const InputHelperText = styled.span`
  color: var(--neutral-gray-gray-700, #545454);
  font-family: Mulish;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
