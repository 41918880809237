import styled from 'styled-components';

interface TitleCardHeaderProps {
  spanCount?: number;
}

export const TitleCardHeader = styled.div<TitleCardHeaderProps>`
  width: 100%;
  font-family: Mulish;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.875rem;
  text-align: left;
  color: #383838;
  margin-bottom: 1.5rem;

  span {
    width: Hug (50px) px;
    height: Hug (43px) px;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    gap: 0.5rem;
    border-radius: 0.125rem;
    opacity: 0px;
    background-color: #e6ecf1;
    color: #263668;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 2.188rem;
    text-align: left;
  }
`;

interface LegendIndicatorProps {
  color: string;
}
export const LegendIndicator = styled.div<LegendIndicatorProps>`
  width: 1.5rem;
  height: 0.75rem;
  border-radius: 0.375rem;
  background-color: ${(props) => props.color};
`;
