import styled, { css, keyframes } from 'styled-components';

const slideInRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOutLeft = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const slideInLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOutRight = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

export const CarouselNewsWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-around;
`;

export const CarouselNewsCard = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  border: 0.063rem solid #bababa;
  padding: 2rem;
`;

interface CarouselNewsSlideProps {
  isActive: boolean;
  isExiting: boolean;
  slideDirection: 'right' | 'left';
}

export const CarouselNewsSlide = styled.div<CarouselNewsSlideProps>`
  width: 100%;
  height: 10rem;
  display: none;
  transition: opacity 1s ease-in-out;

  img {
    max-height: 100%;
    width: auto;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  ${(props) =>
    props.isActive &&
    css`
      display: block;
      animation: ${props.slideDirection === 'right' ? slideInRight : slideInLeft} 1s forwards;
    `}

  ${(props) =>
    props.isExiting &&
    css`
      display: none;
      animation: ${props.slideDirection === 'right' ? slideOutLeft : slideOutRight} 1s forwards;
    `}
`;

export const CarouselNewsSlideDescription = styled.div`
  font-family: Mulish;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
  text-align: left;
  color: #263668;
  margin-bottom: 2rem;
`;

export const CarouselNewsSlideOptions = styled.div`
  font-family: Mulish;
  font-size: 1.5rem;
  color: var(--primary-color);
  cursor: pointer;
  padding-top: 1rem;
`;

export const CarouselNewsIndicatorContainer = styled.div`
  display: flex;
  gap: 0.625rem;
  justify-content: center;
  align-items: center;
`;

interface CarouselNewsIndicatorProps {
  isActive: boolean;
}

export const CarouselNewsIndicator = styled.div<CarouselNewsIndicatorProps>`
  width: 0.625rem;
  height: 0.625rem;
  background-color: var(--primary-color);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  backface-visibility: hidden;
  transform: translateZ(0);

  ${({ isActive }) =>
    isActive &&
    css`
      width: 1.875rem;
      border-radius: 1rem;
      background-color: #861412;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    `}
`;
