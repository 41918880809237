import { ApiServiceBase, IApiServiceBase } from '@app/services/base/apiServiceBase';
import { ModelDeviceModel } from '@app/domain/modelDevice/modelDeviceModel';
import { PropertyModel } from '@app/domain/property/propertyModel';

export default interface IModelDeviceService extends IApiServiceBase<ModelDeviceModel, ModelDeviceModel> {
  getPropertiesByIdModel(idModel: number): Promise<PropertyModel[]>;
  getCommandsByIdModel(idModel: number): Promise<PropertyModel[]>;
}

export class ModelDeviceService
  extends ApiServiceBase<ModelDeviceModel, ModelDeviceModel>
  implements IModelDeviceService
{
  constructor() {
    super('Modelos');
  }

  async getPropertiesByIdModel(idModel: number): Promise<PropertyModel[]> {
    return (await this.api.get(`${idModel}/propriedades`))?.data;
  }
  async getCommandsByIdModel(idModel: number): Promise<PropertyModel[]> {
    return (await this.api.get(`${idModel}/comandos`))?.data;
  }
}
