import { FC, useEffect, useState } from 'react';
import * as S from './CarouselNews.styles';
import { Col, Row } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const slides = [
  {
    description: 'J-R12: Rastreador',
    src: 'https://slxbrsodevstgcdn.blob.core.windows.net/public/jmak/JR12.png',
  },
  {
    description: 'J-R11: Rastreador',
    src: 'https://slxbrsodevstgcdn.blob.core.windows.net/public/jmak/JR11.png',
  },
  {
    description: 'J-M15: Identificador de motoristas',
    src: 'https://slxbrsodevstgcdn.blob.core.windows.net/public/jmak/JM15.png',
  },
  {
    description: 'Mapeamento de cercas',
    src: 'https://slxbrsodevstgcdn.blob.core.windows.net/public/jmak/plataforma.png',
  },
];

interface CarouselNewsProps {
  showDescription?: boolean;
}

export const CarouselNews: FC<CarouselNewsProps> = ({ showDescription = true }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState<number | null>(null);
  const [slideDirection, setSlideDirection] = useState<'right' | 'left'>('right');

  useEffect(() => {
    const timer = setInterval(() => {
      setPrevIndex(currentIndex);
      setSlideDirection('right');
      setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
    }, 5000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides, currentIndex]);

  const prevSlide = () => {
    setPrevIndex(currentIndex);
    setSlideDirection('left');
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
  };

  const nextSlide = () => {
    setPrevIndex(currentIndex);
    setSlideDirection('right');
    setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <S.CarouselNewsWrapper>
      <S.CarouselNewsCard>
        {slides.map((slide, index) => (
          <S.CarouselNewsSlide
            key={index}
            isActive={index === currentIndex}
            isExiting={index === prevIndex}
            slideDirection={slideDirection}
          >
            {showDescription && (
              <Row>
                <S.CarouselNewsSlideDescription>{slide.description}</S.CarouselNewsSlideDescription>
              </Row>
            )}
            <Row style={{ height: '100%' }}>
              <img src={slide.src} alt={`Slide ${index}`} />
            </Row>
          </S.CarouselNewsSlide>
        ))}
      </S.CarouselNewsCard>
      <Row justify={'center'}>
        <S.CarouselNewsSlideOptions>
          <Row align={'middle'} gutter={10}>
            <Col>
              <LeftOutlined onClick={() => prevSlide()} />
            </Col>
            <Col>
              <S.CarouselNewsIndicatorContainer>
                {slides.map((_, index) => (
                  <S.CarouselNewsIndicator key={index} isActive={index === currentIndex} />
                ))}
              </S.CarouselNewsIndicatorContainer>
            </Col>
            <Col>
              <RightOutlined onClick={() => nextSlide()} />
            </Col>
          </Row>
        </S.CarouselNewsSlideOptions>
      </Row>
    </S.CarouselNewsWrapper>
  );
};
