/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableProps } from 'antd';
import React from 'react';
import './TableCollapse.less';
import * as S from './TableCollapse.styles';

interface NewTableProps extends TableProps<any> {}

// TODO make generic!
export const TableCollapse: React.FC<TableProps<any>> = (props) => {
  return (
    <S.Table
      {...props}
      //@ts-ignore
      pagination={{ ...props.pagination, showSizeChanger: false, defaultPageSize: 8, position: ['bottomCenter'] }}
    />
  );
};
