import React, { useEffect, useState } from 'react';
import { DeviceProfilePropertyModel, PropertyValue } from '@app/domain/deviceProfile/deviceProfileModel';
import ShouldRenderInput from '@app/components/common/ModulesProfileConfig/ShouldRenderInput';
import { Col, Form, Row, Space } from 'antd';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { CardInfoProperty, CardInfoTitle } from '../../Card/CardInfo/CardDeviceInformation.styles';
4;
import * as S from '../ModulesProfileConfig.styles';
import { Input } from '../../inputs/Input/Input';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import TooltipInfoIcon from '@app/components/common/TooltipInfoIcon/TooltipInfoIcon';
import * as ST from '@app/components/common/ModulesProfileConfig/PerfilEnvio/PerfilEnvio.styles';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { googlecode } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
  codeStringExample,
  valoresDefaultPorId,
} from '@app/components/common/ModulesProfileConfig/PerfilEnvio/PerfilEnvioUtils';
import { Panel } from '@app/components/common/Collapse/Collapse';
import { FirmwareGroupService } from '@app/services/firmwareGroupService';
import { SendParameter } from '@app/store/slices/sendParametersSlice';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { decode } from '@app/components/common/ModulesProfileConfig/PerfilEnvio/encodeDecode';
import { ocasionalParameterEmbarcado } from './Modal/ModalParametersPerfilEnvio';

interface PerfilEnvioProps {
  properties: PropertyValue[];
  setProperties: React.Dispatch<React.SetStateAction<PropertyValue[]>>;
  moduleData: DeviceProfilePropertyModel[];
  true?: boolean;
  device?: FactoryDeviceModel | null;
}

const firmwareGroupService = new FirmwareGroupService();

const PerfilEnvioView: React.FC<PerfilEnvioProps> = ({ device, properties, setProperties, moduleData }) => {
  const [idMapping, setIdMapping] = useState<{ [key: number]: number }>({});
  const [parameters, setParameters] = useState<SendParameter[]>([]);
  const [selectedParamsObrigatorios, setSelectedParamsObrigatorios] = useState<SendParameter[]>([]);
  const [selectedParamsOpcionais, setSelectedParamsOpcionais] = useState<SendParameter[]>([]);
  // const [selectedParams, setSelectedParams] = useState<number[]>([]);
  useEffect(() => {
    const newIdMapping: { [key: number]: number } = {};
    moduleData.forEach((module) => {
      if (module.idEmbarcado && module.idPropriedade) {
        newIdMapping[module.idEmbarcado] = module.idPropriedade;
      }
    });
    setIdMapping(newIdMapping);
  }, [moduleData]);

  useEffect(() => {
    if (Object.keys(idMapping).length > 0 && properties.length === 0) {
      const newProperties = Object.keys(idMapping).map((idEmbarcado) => {
        const idStr = idEmbarcado.toString(); // Converte o ID para string
        const valorPadrao = idStr in valoresDefaultPorId ? valoresDefaultPorId[idStr] : '';

        return {
          idPropriedade: idMapping[Number(idEmbarcado)],
          valorPropriedade: valorPadrao,
          idEmbarcado: Number(idEmbarcado),
        };
      });
      setProperties((prevState) => [...prevState, ...newProperties]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMapping, setProperties, properties.length]);

  const propertyValue = (idEmbarcado: number) => {
    const idPropriedade = idMapping[idEmbarcado];
    return properties.find((property) => property.idPropriedade === idPropriedade)?.valorPropriedade || '';
  };

  const fetchSendProfileProperties = async (idVersaoFirmware: number) => {
    return firmwareGroupService
      .getArray(`obter-propriedades-perfil-envio/${idVersaoFirmware}`)
      .then((res) => {
        setParameters(res as unknown as SendParameter[]);
      })
      .catch((error) => {
        // notificationController.error(error);
        console.error('Error fetching send profile properties: ', error);
      });
  };

  useEffect(() => {
    if (parameters.length > 0) {
      const selectedParams = decode(propertyValue(69));

      setSelectedParamsObrigatorios(
        parameters.filter((p) => p.isObrigatorio && selectedParams.includes(p.identificadorEmbarcado)),
      );

      setSelectedParamsOpcionais(
        parameters.filter((p) => !p.isObrigatorio && selectedParams.includes(p.identificadorEmbarcado)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  useEffect(() => {
    if (device?.idGrupoFirmware) fetchSendProfileProperties(device?.idGrupoFirmware);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device?.idGrupoFirmware]);

  return (
    <Form layout="vertical">
      <Row>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={2}>
          <Col xs={24} xl={12} xxl={8}>
            <Col xs={24} md={12}>
              <CardInfoProperty>Intervalo ignição ligada (s)</CardInfoProperty>
            </Col>
            <Col span={8}>
              <Input
                key={3}
                type="text"
                className="ant-input"
                placeholder="Digite o valor"
                value={propertyValue(2)}
                disabled
                style={{ maxWidth: '7rem' }}
                suffix="s"
              />
            </Col>
          </Col>
        </ShouldRenderInput>

        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={3}>
          <Col xs={24} xl={12} xxl={8}>
            <Col xs={24} md={12}>
              <CardInfoProperty>Intervalo bateria externa (s)</CardInfoProperty>
            </Col>
            <Col span={8}>
              <Input
                key={3}
                type="text"
                className="ant-input"
                placeholder="Digite o valor"
                value={propertyValue(3)}
                disabled
                style={{ maxWidth: '7rem' }}
                suffix="s"
              />
            </Col>
          </Col>
        </ShouldRenderInput>

        <Col xs={24} xl={12} xxl={8}>
          <Col xs={24} md={12}>
            <CardInfoProperty>Intervalo bateria interna (s)</CardInfoProperty>
          </Col>
          <Col span={8}>
            <Input
              key={3}
              type="text"
              className="ant-input"
              placeholder="Digite o valor"
              value={propertyValue(4)}
              disabled
              style={{ maxWidth: '7rem' }}
              suffix="s"
            />
          </Col>
        </Col>
      </Row>
      <Row gutter={[18, 18]} style={{ marginTop: '2rem' }}>
        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={48}>
                <Checkbox key={48} checked={propertyValue(48) === 'true'} disabled>
                  <S.CheckboxLabel>Envio por distância (m)</S.CheckboxLabel>
                  <S.InputObs>50 a 1000</S.InputObs>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={49}>
                {propertyValue(48) === 'true' && (
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={propertyValue(49)}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="m"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>

        <Col xs={24} xl={12} xxl={8}>
          <Row justify={'space-between'}>
            <Col xs={24} md={12}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={50}>
                <Checkbox key={50} checked={propertyValue(50) === 'true'} disabled>
                  <S.CheckboxLabel>Envio por ângulo (°)</S.CheckboxLabel>
                  <S.InputObs>10 a 179</S.InputObs>
                </Checkbox>
              </ShouldRenderInput>
            </Col>
            <Col span={8}>
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={51}>
                {propertyValue(50) === 'true' && (
                  <Input
                    key={51}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={propertyValue(51)}
                    disabled
                    style={{ maxWidth: '7rem' }}
                    suffix="°"
                  />
                )}
              </ShouldRenderInput>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={68}>
          <BaseFormInputItem label="Tipo de envio">
            <RadioGroup key={68} value={propertyValue(68)} disabled>
              <Space direction="vertical">
                <Radio value={'0'}>
                  JSON{' '}
                  <TooltipInfoIcon title={'Tipo de mensagem altamente estruturada. Alto consumo de dados por envio'} />
                </Radio>
                <Radio value={'1'}>
                  String <TooltipInfoIcon title={'Tipo de mensagem estruturada. Médio consumo de dados por envio.'} />
                </Radio>
                <Radio value={'2'}>
                  .Zip <TooltipInfoIcon title={'Tipo de mensagem não estruturada. Baixo consumo de dados por envio.'} />
                </Radio>
              </Space>
            </RadioGroup>
          </BaseFormInputItem>
          <ST.Collapse bordered={false} style={{ width: '100%', marginBottom: '1rem' }}>
            <ST.Panel key={'pre-visualization'} header={'Pré-visualização do envio da mensagem'}>
              <HelperNotification>Exemplo de como a mensagem será enviada ao dispositivo.</HelperNotification>
              <SyntaxHighlighter
                language="json"
                style={googlecode}
                customStyle={{
                  backgroundColor: 'transparent',
                }}
              >
                {codeStringExample[propertyValue(68)] ?? ''}
              </SyntaxHighlighter>
            </ST.Panel>
          </ST.Collapse>
        </ShouldRenderInput>
      </Row>
      {propertyValue(68) !== '0' && (
        <S.Row style={{ margin: '1rem 0' }}>
          <Col xs={24}>
            <S.Collapse defaultActiveKey={'1'}>
              <Panel
                key="1"
                header={
                  <Row align={'middle'} justify={'space-between'}>
                    <Col>
                      <ST.CollapseHeaderName>Parâmetros de envio</ST.CollapseHeaderName>
                    </Col>
                  </Row>
                }
              >
                <S.Row>
                  <CardInfoTitle>Obrigatórios</CardInfoTitle>
                </S.Row>
                <S.Row style={{ columnGap: '0.8rem' }}>
                  <strong>{selectedParamsObrigatorios.length}</strong> parâmetros
                </S.Row>
                <S.Row style={{ margin: '0.9rem 0' }}>
                  {selectedParamsObrigatorios.map((param) => (
                    <Col key={param.identificadorEmbarcado} xs={24} md={4} lg={4} style={{ minHeight: '32px' }}>
                      {param.nome}
                    </Col>
                  ))}
                </S.Row>

                <S.Row>
                  <CardInfoTitle>Opcionais</CardInfoTitle>
                </S.Row>
                <S.Row>
                  <strong>
                    {
                      selectedParamsOpcionais.filter(
                        (p) => !ocasionalParameterEmbarcado.includes(p.identificadorEmbarcado),
                      ).length
                    }
                  </strong>{' '}
                  parâmetros
                </S.Row>
                <S.Row style={{ margin: '0.9rem 0' }}>
                  {selectedParamsOpcionais
                    .filter((p) => !ocasionalParameterEmbarcado.includes(p.identificadorEmbarcado))
                    .map((param) => (
                      <Col key={param.identificadorEmbarcado} xs={24} md={4} lg={4} style={{ minHeight: '32px' }}>
                        {param.nome}
                      </Col>
                    ))}
                </S.Row>
              </Panel>
            </S.Collapse>
          </Col>
        </S.Row>
      )}
    </Form>
  );
};

export default PerfilEnvioView;
