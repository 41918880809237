import React, { FC } from 'react';
import { Collapse } from 'antd';
import * as S from '../styles';
import { DeviceProfilePropertyModel, PropertyValue } from '@app/domain/deviceProfile/deviceProfileModel';
import RedeView from '@app/components/common/ModulesProfileConfig/Rede/RedeView';
import EntradaView from '@app/components/common/ModulesProfileConfig/Entrada/EntradaView';
import PerfilEnvioView from '@app/components/common/ModulesProfileConfig/PerfilEnvio/PerfilEnvioView';
import EventosView from '@app/components/common/ModulesProfileConfig/Eventos/EventosView';
import { DividerLine } from '@app/components/common/divider/DividerLine.styles';
import { CANView } from '@app/components/common/ModulesProfileConfig/CAN/CANView';
import { CercaView } from '@app/components/common/ModulesProfileConfig/Cerca/CercaView';
import { CanVehicleParametersCalculatedModel, CanVehicleParametersOnOffModel } from '@app/domain/canVehicle/canVehicle';
import IdentificadorMotorista from '@app/components/common/ModulesProfileConfig/IdentificadorMotorista/IdentificadorMotorista';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';

const { Panel } = Collapse;

interface ModuleViewComponentProps {
  properties: PropertyValue[];
  setProperties: React.Dispatch<React.SetStateAction<PropertyValue[]>>;
  moduleData: DeviceProfilePropertyModel[];
  moduleName: string;
  hasBorderBottom?: boolean;
  idGroup?: number;
  idVehicle?: number;
  idManufacturer?: number;
  parametersCalculated?: CanVehicleParametersCalculatedModel[];
  setParametersCalculated?: React.Dispatch<React.SetStateAction<CanVehicleParametersCalculatedModel[]>>;
  parametersOnOff?: CanVehicleParametersOnOffModel[];
  setParametersOnOff?: React.Dispatch<React.SetStateAction<CanVehicleParametersOnOffModel[]>>;
  device?: FactoryDeviceModel | null;
}
// Componente ModuleComponent
const ModuleViewComponent: FC<ModuleViewComponentProps> = ({
  properties,
  setProperties,
  moduleData,
  moduleName,
  idGroup,
  idManufacturer,
  idVehicle,
  hasBorderBottom = true,
  parametersCalculated,
  setParametersCalculated,
  parametersOnOff,
  setParametersOnOff,
  device,
}) => {
  const renderModuleContent = (name: string) => {
    switch (name) {
      case 'Rede':
        return <RedeView properties={properties} setProperties={setProperties} moduleData={moduleData} />;
      case 'Entrada':
        return <EntradaView properties={properties} setProperties={setProperties} moduleData={moduleData} />;
      case 'PerfilEnvio':
        return (
          <PerfilEnvioView
            device={device}
            properties={properties}
            setProperties={setProperties}
            moduleData={moduleData}
          />
        );
      case 'Evento':
        return <EventosView properties={properties} setProperties={setProperties} moduleData={moduleData} />;
      case 'CAN':
        return (
          <CANView
            idManufacturer={idManufacturer}
            idVehicle={idVehicle}
            parametersCalculated={parametersCalculated}
            setParametersCalculated={setParametersCalculated}
            parametersOnOff={parametersOnOff}
            setParametersOnOff={setParametersOnOff}
          />
        );
      case 'Cercas':
        return <CercaView idGroup={idGroup} />;
      case 'IdentificadorMotorista':
        return (
          <IdentificadorMotorista
            properties={properties}
            setProperties={setProperties}
            moduleData={moduleData}
            disableAllFields={true}
          />
        );
      default:
        return <></>;
    }
  };

  const renderModuleTitle = (name: string) => {
    switch (name) {
      // case 'Rede':
      //   return 'Rede';
      // case 'Entrada':
      //   return 'Entrada';
      case 'PerfilEnvio':
        return 'Perfil de envio';
      // case 'Eventos':
      //   return 'Eventos';
      case 'CAN':
        return 'Veículos CAN';
      case 'IdentificadorMotorista':
        return 'Configurações';
      // case 'Cercas':
      //   return 'Cercas';
      default:
        return name;
    }
  };

  return (
    <S.CollapseContainer>
      <S.Collapse bordered={false} defaultActiveKey={moduleName} style={{ paddingLeft: '-16px' }}>
        <Panel header={renderModuleTitle(moduleName)} key={moduleName} style={{ paddingLeft: '-16px' }}>
          {renderModuleContent(moduleName)}
        </Panel>
        {hasBorderBottom && (
          <div style={{ paddingLeft: '16px' }}>
            <DividerLine />
          </div>
        )}
      </S.Collapse>
    </S.CollapseContainer>
  );
};

export default ModuleViewComponent;
