import { BaseForm } from '../../forms/BaseForm/BaseForm';
import { Col, Row } from 'antd';
import { BaseFormInputItem } from '../../forms/components/BaseFormInputItem/BaseFormInputItem';
import { Checkbox } from '../../Checkbox/Checkbox';
import { Select } from '../../selects/Select/Select';
import { useCallback, useEffect, useState } from 'react';
import IFenceService, { FenceService } from '@app/services/fenceService';
import { FenceGroupModel } from '@app/domain/fence/fenceGroupModel';
import { FenceModel } from '@app/domain/fence/fenceModel';
import { notificationController } from '@app/controllers/notificationController';
import MapComponentView from '@app/pages/fence/view/components/MapComponentView/MapComponentView';
import { Button } from '../../buttons/Button/Button';
import { IconMap } from '@app/assets/slump-icons';
import { Panel } from '../../Collapse/Collapse';
import * as S from './Cerca.styles';
import { groupBy } from 'lodash';

interface CercaProps {
  idModel: number;
  idGroup?: number;
  setIdGroup: React.Dispatch<React.SetStateAction<number | undefined>>;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const fenceService: IFenceService = new FenceService();

export const Cerca: React.FC<CercaProps> = ({ idGroup, setIdGroup, setIsValid, idModel }) => {
  const [enableGroup, setEnableGroup] = useState<boolean>(false);
  const [enableViewMap, setEnableViewMap] = useState<boolean>(false);
  const [groups, setGroups] = useState<FenceGroupModel[]>([]);
  const [fences, setFences] = useState<FenceModel[]>([]);

  const handleOnChangeGroup = (id: number) => {
    fetchGroupId(id);
    setIdGroup(id);
    setIsValid(true);
  };

  const handleChangeEnableGroup = (newEnableGroup: boolean) => {
    if (newEnableGroup) {
      fetchGroups();
      !idGroup ? setIsValid(false) : fetchGroupId(idGroup);
    } else {
      setIdGroup(undefined);
      setEnableViewMap(false);
      setIsValid(true);
    }
    setEnableGroup(newEnableGroup);
  };

  const fetchGroupId = (id: number) => {
    fenceService
      .get(`/grupos/${id}`)
      .then((response) => {
        setFences(response.cercas ?? []);
      })
      .catch((err) => {
        notificationController.error({ message: `Erro ao buscar cerca. ${err}` });
      });
  };

  const fetchGroups = useCallback(() => {
    fenceService
      .getArray(`/grupos-por-modelo/${idModel}`)
      .then((res: FenceGroupModel[]) => setGroups(res.filter((g) => g.ativo)))
      .catch(() => notificationController.error({ message: `Erro ao obter cercas.` }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (idGroup) {
      setEnableGroup(true);
      fetchGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseForm layout="vertical">
      <Row>
        <BaseFormInputItem>
          <Checkbox checked={enableGroup} onChange={() => handleChangeEnableGroup(!enableGroup)}>
            Adicionar grupo
          </Checkbox>
        </BaseFormInputItem>
      </Row>
      <Row gutter={18}>
        <Col xs={24} md={5}>
          <BaseFormInputItem
            label="Grupo de cercas"
            errorText="Campo obrigatório"
            isSuccess={!enableGroup || idGroup ? undefined : false}
          >
            <Select
              showArrow
              showSearch
              disabled={!enableGroup}
              value={idGroup}
              placeholder="Selecione o grupo"
              onChange={(value) => handleOnChangeGroup(Number(value))}
              options={groups.map((g) => ({ value: g.id, label: g.nome }))}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
            ></Select>
          </BaseFormInputItem>
        </Col>
      </Row>
      {idGroup && (
        <Row gutter={[18, 18]}>
          <Col xs={24}>
            <Row style={{ marginBottom: '1rem' }}>
              {enableViewMap && <MapComponentView showInactiveFences={false} fences={fences} />}
            </Row>
            <S.CollapseMap>
              <Panel
                key="1"
                header={
                  <Row align={'middle'} justify={'space-between'}>
                    <Col>
                      <S.CollapseHeaderName>Cercas do grupo</S.CollapseHeaderName>
                    </Col>
                    <Button type="ghost" icon={<IconMap />} onClick={() => setEnableViewMap(!enableViewMap)}>
                      Visualizar mapa
                    </Button>
                  </Row>
                }
              >
                {Object.keys(groupBy(fences, (f) => f.tipoCerca)).map((f) => {
                  const fencesFiltered = fences.filter((fence) => fence.tipoCerca == f);
                  return (
                    <div key={f} style={{ paddingBottom: '1rem' }}>
                      <Row>
                        <S.TableTypeName>{f}</S.TableTypeName>
                      </Row>
                      <Row>
                        <S.TableFenceQuantity>{fencesFiltered?.length}</S.TableFenceQuantity>&nbsp;
                        <S.TableFenceDescription>
                          {fencesFiltered?.length > 1 ? 'Cercas' : 'Cerca'}
                        </S.TableFenceDescription>
                      </Row>
                      <Row gutter={[18, 18]} style={{ overflowY: 'auto', maxHeight: '30rem', width: '100%' }}>
                        {fencesFiltered?.map((item, index) => {
                          return (
                            <Col key={index} xs={12} xl={6}>
                              <S.TableFenceName>{item.nome}</S.TableFenceName>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  );
                })}
              </Panel>
            </S.CollapseMap>
          </Col>
        </Row>
      )}
    </BaseForm>
  );
};
