import { InfoCircleOutlined } from '@ant-design/icons';
import { IconMore } from '@app/assets/slump-icons';
import { Description } from '@app/components/common/Description/Description';
import { Modal } from '@app/components/common/Modal/Modal';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { EquipmentModel } from '@app/domain/equipment/equipmentModel';
import { ModelDeviceModel } from '@app/domain/modelDevice/modelDeviceModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IEquipmentService, { EquipmentService } from '@app/services/equipmentService';
import { setFooter } from '@app/store/slices/footerSlice';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Col, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType } from 'antd/lib/table';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { H4 } from '@app/components/common/typography/H4/H4';
import { hasAccessByRoles } from '@app/controllers/accessController';
import { UserType } from '@app/constants/enums/userType';
import { ArgsProps } from 'antd/lib/notification';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import { Tag } from '@app/components/common/Tag/Tag';

const equipmentService: IEquipmentService = new EquipmentService();

export const EquipmentCreate: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [equipment, setEquipment] = useState<EquipmentModel>({} as EquipmentModel);
  const [model, setModel] = useState<ModelDeviceModel>({} as ModelDeviceModel);
  const [newNameModelUpdating, setNewNameModelUpdating] = useState<string>();
  const [modalEdit, setModalEdit] = useState(false);
  const [modelEdit, setModelEdit] = useState<ModelDeviceModel>({} as ModelDeviceModel);

  const handleBackClick = () => {
    setModalCancelVisible(true);
  };

  const handleSaveClick = async () => {
    try {
      setLoading(true);

      if (id) {
        await equipmentService.update(``, equipment);
      } else {
        await equipmentService.post(``, equipment);
      }

      notificationController.success({
        message: 'Sucesso!',
        description: `${id ? 'Edição do' : 'Cadastro de novo'}  equipamento realizado  com sucesso!`,
      });
      navigate('/equipamentos');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notificationController.error({ message: 'Erro!', description: error.message });
      setLoading(false);
    }
  };

  const isValidModel = (model: ModelDeviceModel) => {
    return (
      model.nome &&
      model.anatel &&
      ((model.permiteCerca && model.quantidadeMaxCercas && model.quantidadeMaxPontosPorCerca) || !model.permiteCerca)
    );
  };

  const isValidNewModelName = (newName: string, models: ModelDeviceModel[]) => {
    return models.find((m) => m.nome === newName) === undefined;
  };

  const handleAddModelClick = () => {
    if (!isValidNewModelName(model.nome, equipment.modelos ?? [])) {
      notificationController.error({ message: 'Erro!', description: 'Já existe um modelo cadastrado com esse nome' });
      return;
    }

    if (!isValidModel(model)) {
      notificationController.error({ message: 'Erro!', description: 'Preencha todos os campos' });
      return;
    }

    setEquipment({ ...equipment, modelos: [...(equipment.modelos ?? []), model] });
    setModel({} as ModelDeviceModel);

    const notification: ArgsProps = { message: 'Modelo adicionado á lista' };
    if (Number(id)) notification.description = 'Finalize a edição do equipamento para salvar as alterações.';

    notificationController.success(notification);
  };

  const handleEditClick = (modelUpdate: ModelDeviceModel) => {
    setModalEdit(true);
    setModelEdit(modelUpdate);
    setNewNameModelUpdating(modelUpdate.nome);
  };

  const handleSaveModelClick = () => {
    if (
      newNameModelUpdating &&
      newNameModelUpdating !== modelEdit.nome &&
      !isValidNewModelName(newNameModelUpdating, equipment.modelos ?? [])
    ) {
      notificationController.error({
        message: 'Erro!',
        description: 'Nome do modelo vazio ou igual a um já existente.',
      });
      return;
    }

    if (!isValidModel(modelEdit)) {
      notificationController.error({ message: 'Erro!', description: 'Preencha todos os campos' });
      return;
    }

    setEquipment({
      ...equipment,
      modelos: equipment.modelos?.map((m) =>
        m.nome === modelEdit.nome
          ? {
              ...m,
              ...modelEdit,
              nome: newNameModelUpdating!,
              quantidadeMaxCercas: modelEdit.permiteCerca ? modelEdit.quantidadeMaxCercas : undefined,
              quantidadeMaxPontosPorCerca: modelEdit.permiteCerca ? modelEdit.quantidadeMaxPontosPorCerca : undefined,
            }
          : m,
      ),
    });
    setModalEdit(false);
    setModelEdit({} as ModelDeviceModel);
    setNewNameModelUpdating(undefined);

    notificationController.success({
      message: 'Modelo atualizado',
      description: 'Finalize a edição do equipamento para salvar as alterações.',
    });
  };

  const handleOnChangeModel = (name: string, value: unknown) => {
    if (name === 'quantidadeMaxCercas' || name === 'quantidadeMaxPontosPorCerca') {
      let newValue = typeof value === 'string' ? value.replace(/\D/g, '') : value;

      newValue = Number(newValue) > 1000 ? '' : newValue; // Limite 1000 cercas
      setModel({ ...model, [name]: newValue });
    } else {
      setModel({ ...model, [name]: value });
    }
  };

  const handleOnChangeModelEdit = (name: string, value: unknown) => {
    if (name === 'quantidadeMaxCercas' || name === 'quantidadeMaxPontosPorCerca') {
      let newValue = typeof value === 'string' ? value.replace(/\D/g, '') : value;

      newValue = Number(newValue) > 1000 ? '' : newValue;
      setModelEdit({ ...modelEdit, [name]: newValue });
    } else {
      setModelEdit({ ...modelEdit, [name]: value });
    }
  };

  const fetch = async (id: number) => {
    try {
      setLoading(true);
      const response = await equipmentService.get(`${id}`);
      setEquipment(response);
      setLoading(false);
    } catch (error) {
      notificationController.error({ message: 'Erro!', description: 'Erro ao buscar os dados do equipamento' });
      setLoading(false);
    }
  };

  const handleDeleteClick = (modelDelete: ModelDeviceModel) => {
    setEquipment({
      ...equipment,
      modelos: equipment.modelos?.filter((m) => m.nome !== modelDelete.nome),
    });
    notificationController.success({
      message: 'Modelo removido da lista',
      description: 'Finalize a edição do equipamento para salvar as alterações.',
    });
  };

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: !id ? 'Novo equipamento' : 'Editar equipamento',
        handleBackClick: handleBackClick,
      }),
    );

    if (id) fetch(parseInt(id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      setFooter({
        confirmButtonText: id ? 'Salvar equipamento' : 'Cadastrar equipamento',
        confirmButtonDisabled: !equipment.nome || equipment.modelos?.length === 0,
        handleConfirmButtonClick: handleSaveClick,
        cancelButtonText: 'Cancelar',
        handleCancelButtonClick: () => setModalCancelVisible(true),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipment, dispatch]);

  const renderTag = (isTrue: boolean) =>
    isTrue ? (
      <Tag color="#E9F4EE" style={{ color: '#083F18', width: '70px', textAlign: 'center' }}>
        Sim
      </Tag>
    ) : (
      <Tag color="#FEE9EA" style={{ color: '#620E12', width: '70px', textAlign: 'center' }}>
        Não
      </Tag>
    );

  const columns: ColumnsType<ModelDeviceModel> = [
    {
      title: 'Modelo',
      dataIndex: 'nome',
      showSorterTooltip: false,
      sorter: (a: ModelDeviceModel, b: ModelDeviceModel) => (a.id ?? 0) - (b.id ?? 0),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Anatel',
      dataIndex: 'anatel',
      showSorterTooltip: false,
    },
    {
      title: 'Conexão',
      dataIndex: 'permiteConexao',
      showSorterTooltip: false,
      render: (value: boolean) => renderTag(value),
    },
    {
      title: 'Envio comandos',
      dataIndex: 'permiteEnvioComando',
      showSorterTooltip: false,
      render: (value: boolean) => renderTag(value),
    },
    {
      title: 'Configuração remota',
      dataIndex: 'permiteConfiguracaoRemota',
      showSorterTooltip: false,
      render: (value: boolean) => renderTag(value),
    },
    {
      title: 'Delta OTA',
      dataIndex: 'permiteDeltaOta',
      showSorterTooltip: false,
      render: (value: boolean) => renderTag(value),
    },
    {
      title: 'Cercas',
      dataIndex: 'permiteCerca',
      showSorterTooltip: false,
      render: (value: boolean) => renderTag(value),
    },
    {
      title: 'Qtde máxima de cercas',
      dataIndex: 'quantidadeMaxCercas',
      showSorterTooltip: false,
    },
    {
      title: 'Qtde máxima de pontos por cercas',
      dataIndex: 'quantidadeMaxPontosPorCerca',
      showSorterTooltip: false,
    },
    {
      title: '',
      dataIndex: 'id',
      showSorterTooltip: false,
      sorter: (a, b) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
      defaultSortOrder: 'descend',
      render: (_, rowData) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {hasAccessByRoles([UserType.FactoryAdmin, UserType.Client]) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ display: 'flex', color: '#545454', alignItems: 'center' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <MenuItem onClick={() => handleEditClick(rowData)}>
                      <Button type="text">Editar</Button>
                    </MenuItem>
                    {!rowData.id && (
                      <MenuItem onClick={() => handleDeleteClick(rowData)}>
                        <Button type="text">Excluir</Button>
                      </MenuItem>
                    )}
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title={`Cancelar ${id ? 'edição' : 'cadastro'} do equipamento`}
        open={modalCancelVisible}
        onOk={() => navigate('/equipamentos')}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            Deseja realmente <strong>&nbsp;cancelar {id ? 'a edição' : 'o cadastro'} do equipamento?</strong>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Editar modelo"
        open={modalEdit}
        onOk={() => handleSaveModelClick()}
        onCancel={() => setModalEdit(false)}
        cancelText="Cancelar"
        okText="Confirmar"
        size="large"
      >
        <BaseForm>
          <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col span={12}>
              <BaseFormInputItem label="Modelo" style={{ width: '100%' }}>
                <Input
                  placeholder="Digite o nome do modelo"
                  value={newNameModelUpdating}
                  maxLength={50}
                  onChange={(e) => setNewNameModelUpdating(e.currentTarget.value)}
                  disabled={!!modelEdit.id}
                />
              </BaseFormInputItem>
            </Col>
            <Col span={12}>
              <BaseFormInputItem label="Anatel" style={{ width: '100%' }}>
                <Input
                  placeholder="Digite o certificado anatel"
                  value={modelEdit?.anatel}
                  maxLength={20}
                  onChange={(e) => handleOnChangeModelEdit('anatel', e.currentTarget.value)}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Row gutter={16} justify={'space-between'} style={{ width: '100%' }}>
                <Col>
                  <BaseFormInputItem label="Conexão">
                    <RadioGroup
                      defaultValue={false}
                      value={modelEdit.permiteConexao}
                      onChange={(e) => handleOnChangeModelEdit('permiteConexao', e.target.value)}
                    >
                      <Space size={0} direction="vertical">
                        <Radio value={true}>Sim</Radio>
                        <Radio value={false}>Não</Radio>
                      </Space>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
                <Col>
                  <BaseFormInputItem label="Envio de Comandos">
                    <RadioGroup
                      defaultValue={false}
                      value={modelEdit.permiteEnvioComando}
                      onChange={(e) => handleOnChangeModelEdit('permiteEnvioComando', e.target.value)}
                    >
                      <Space size={0} direction="vertical">
                        <Radio value={true}>Sim</Radio>
                        <Radio value={false}>Não</Radio>
                      </Space>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
                <Col>
                  <BaseFormInputItem label="Configuração remota">
                    <RadioGroup
                      defaultValue={false}
                      value={modelEdit.permiteConfiguracaoRemota}
                      onChange={(e) => handleOnChangeModelEdit('permiteConfiguracaoRemota', e.target.value)}
                    >
                      <Space size={0} direction="vertical">
                        <Radio value={true}>Sim</Radio>
                        <Radio value={false}>Não</Radio>
                      </Space>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
                <Col>
                  <BaseFormInputItem label="Delta OTA">
                    <RadioGroup
                      defaultValue={false}
                      value={modelEdit.permiteDeltaOta}
                      onChange={(e) => handleOnChangeModelEdit('permiteDeltaOta', e.target.value)}
                    >
                      <Space size={0} direction="vertical">
                        <Radio value={true}>Sim</Radio>
                        <Radio value={false}>Não</Radio>
                      </Space>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
                <Col>
                  <BaseFormInputItem label="Cercas">
                    <RadioGroup
                      defaultValue={false}
                      value={modelEdit.permiteCerca}
                      onChange={(e) => handleOnChangeModelEdit('permiteCerca', e.target.value)}
                    >
                      <Space size={0} direction="vertical">
                        <Radio value={true}>Sim</Radio>
                        <Radio value={false}>Não</Radio>
                      </Space>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Col>
          </Row>
          {modelEdit.permiteCerca && (
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={12}>
                <BaseFormInputItem
                  label="Quantidade máxima de cercas"
                  errorText="Campo inválido"
                  successText=""
                  style={{ width: '100%' }}
                >
                  <Input
                    placeholder="Digite a quantidade máxima de cercas"
                    value={modelEdit?.quantidadeMaxCercas}
                    maxLength={4}
                    onChange={(e) => handleOnChangeModelEdit('quantidadeMaxCercas', e.currentTarget.value)}
                    onBlur={(e) => {
                      const valor = e.currentTarget.value;
                      handleOnChangeModelEdit('quantidadeMaxCercas', valor === '' || Number(valor) > 1000 ? '' : valor);
                    }}
                  />
                </BaseFormInputItem>
              </Col>
              <Col span={12}>
                <BaseFormInputItem
                  label="Quantidade máxima de pontos por cercas"
                  errorText="Campo inválido"
                  successText=""
                  style={{ width: '100%' }}
                >
                  <Input
                    placeholder="Digite a quantidade máxima de pontos por cercas"
                    value={modelEdit?.quantidadeMaxPontosPorCerca}
                    maxLength={4}
                    onChange={(e) => handleOnChangeModelEdit('quantidadeMaxPontosPorCerca', e.currentTarget.value)}
                    onBlur={(e) => {
                      const valor = e.currentTarget.value;
                      handleOnChangeModelEdit(
                        'quantidadeMaxPontosPorCerca',
                        valor === '' || Number(valor) > 1000 ? '' : valor,
                      );
                    }}
                  />
                </BaseFormInputItem>
              </Col>
            </Row>
          )}
        </BaseForm>
      </Modal>

      <Spinner spinning={loading}>
        <PageContainer>
          <Description
            title="Informações do equipamento"
            subtitle="Preencha o campo para cadastrar um novo equipamento"
          />
          <BaseForm layout="vertical" style={{ width: '100%' }}>
            <Row gutter={6}>
              <Col xs={24} md={12} xl={8}>
                <BaseFormInputItem label="Equipamento">
                  <Input
                    placeholder="Digite o nome do equipamento"
                    value={equipment?.nome}
                    onChange={(e) => setEquipment({ ...equipment, nome: e.currentTarget.value })}
                    disabled={!!id}
                  />
                </BaseFormInputItem>
              </Col>
            </Row>
          </BaseForm>

          <Description title="Informações do modelo" subtitle="Preencha os campos para cadastrar um novo modelo" />
          <BaseForm layout="vertical" style={{ width: '100%', marginBottom: '2rem' }}>
            <Row gutter={16}>
              <Col xs={24} sm={12} xl={4}>
                <BaseFormInputItem label="Modelo">
                  <Input
                    placeholder="Digite o nome do modelo"
                    value={model?.nome}
                    maxLength={50}
                    onChange={(e) => handleOnChangeModel('nome', e.currentTarget.value)}
                  />
                </BaseFormInputItem>
              </Col>
              <Col xs={24} sm={12} xl={4}>
                <BaseFormInputItem label="Anatel">
                  <Input
                    placeholder="Digite o certificado anatel"
                    value={model?.anatel}
                    maxLength={20}
                    onChange={(e) => handleOnChangeModel('anatel', e.currentTarget.value)}
                  />
                </BaseFormInputItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} xl={10}>
                <Row gutter={16} justify={'space-between'}>
                  <Col>
                    <BaseFormInputItem label="Conexão">
                      <RadioGroup
                        defaultValue={false}
                        value={model.permiteConexao}
                        onChange={(e) => handleOnChangeModel('permiteConexao', e.target.value)}
                      >
                        <Space size={0} direction="vertical">
                          <Radio value={true}>Sim</Radio>
                          <Radio value={false}>Não</Radio>
                        </Space>
                      </RadioGroup>
                    </BaseFormInputItem>
                  </Col>
                  <Col>
                    <BaseFormInputItem label="Envio de Comandos">
                      <RadioGroup
                        defaultValue={false}
                        value={model.permiteEnvioComando}
                        onChange={(e) => handleOnChangeModel('permiteEnvioComando', e.target.value)}
                      >
                        <Space size={0} direction="vertical">
                          <Radio value={true}>Sim</Radio>
                          <Radio value={false}>Não</Radio>
                        </Space>
                      </RadioGroup>
                    </BaseFormInputItem>
                  </Col>
                  <Col>
                    <BaseFormInputItem label="Configuração remota">
                      <RadioGroup
                        defaultValue={false}
                        value={model.permiteConfiguracaoRemota}
                        onChange={(e) => handleOnChangeModel('permiteConfiguracaoRemota', e.target.value)}
                      >
                        <Space size={0} direction="vertical">
                          <Radio value={true}>Sim</Radio>
                          <Radio value={false}>Não</Radio>
                        </Space>
                      </RadioGroup>
                    </BaseFormInputItem>
                  </Col>
                  <Col>
                    <BaseFormInputItem label="Delta OTA">
                      <RadioGroup
                        defaultValue={false}
                        value={model.permiteDeltaOta}
                        onChange={(e) => handleOnChangeModel('permiteDeltaOta', e.target.value)}
                      >
                        <Space size={0} direction="vertical">
                          <Radio value={true}>Sim</Radio>
                          <Radio value={false}>Não</Radio>
                        </Space>
                      </RadioGroup>
                    </BaseFormInputItem>
                  </Col>
                  <Col>
                    <BaseFormInputItem label="Cercas">
                      <RadioGroup
                        defaultValue={false}
                        value={model.permiteCerca}
                        onChange={(e) => handleOnChangeModel('permiteCerca', e.target.value)}
                      >
                        <Space size={0} direction="vertical">
                          <Radio value={true}>Sim</Radio>
                          <Radio value={false}>Não</Radio>
                        </Space>
                      </RadioGroup>
                    </BaseFormInputItem>
                  </Col>
                </Row>
              </Col>
              {model.permiteCerca && (
                <>
                  <Col xs={24} sm={12} xl={5}>
                    <BaseFormInputItem
                      label="Quantidade máxima de cercas"
                      successText=""
                      supportText="Digite a quantidade máxima de cercas"
                    >
                      <Input
                        value={model?.quantidadeMaxCercas}
                        maxLength={4}
                        onChange={(e) => handleOnChangeModel('quantidadeMaxCercas', e.currentTarget.value)}
                        onBlur={(e) => {
                          const valor = e.currentTarget.value;
                          handleOnChangeModel('quantidadeMaxCercas', valor === '' || Number(valor) > 1000 ? '' : valor);
                        }}
                      />
                    </BaseFormInputItem>
                  </Col>
                  <Col xs={24} sm={12} xl={5}>
                    <BaseFormInputItem
                      label="Quantidade máxima de pontos por cercas"
                      errorText="Campo inválido"
                      supportText="Digite a quantidade máxima de pontos por cercas"
                    >
                      <Input
                        value={model?.quantidadeMaxPontosPorCerca}
                        maxLength={4}
                        onChange={(e) => handleOnChangeModel('quantidadeMaxPontosPorCerca', e.currentTarget.value)}
                        onBlur={(e) => {
                          const valor = e.currentTarget.value;
                          handleOnChangeModel(
                            'quantidadeMaxPontosPorCerca',
                            valor === '' || Number(valor) > 1000 ? '' : valor,
                          );
                        }}
                      />
                    </BaseFormInputItem>
                  </Col>
                </>
              )}
              <Col xs={24} sm={12} xl={4}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    onClick={handleAddModelClick}
                    type="primary"
                    block
                    style={{ marginTop: '2rem', width: '100%' }}
                    disabled={!isValidModel(model)}
                  >
                    Adicionar modelo
                  </Button>
                </div>
              </Col>
            </Row>
          </BaseForm>

          {equipment.modelos && equipment.modelos.length > 0 && (
            <>
              <H4>Modelos adicionados</H4>
              <Table
                columns={columns}
                dataSource={equipment.modelos?.map((m) => ({
                  ...m,
                  tipoEquipamento: equipment.nome,
                }))}
                style={{ padding: 0, width: '100%' }}
                bordered
                // scroll={{ x: 2000, y: 500 }}
              />
            </>
          )}
        </PageContainer>
      </Spinner>
    </>
  );
};
