import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ContainerNewDevice = styled.div`
  padding: 0 2rem 2rem;
  width: 35rem;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--disabled-bg-color);

  @media (max-width: 90rem) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--disabled-bg-color);
  }
`;

export const USBContainer = styled.div`
  margin-bottom: 2rem;
`;

export const QRCodeContainer = styled.div``;

export const ContainerListDevices = styled.div`
  flex: 1;
  min-width: 35rem;
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ButtonContainer = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: flex-end;
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
`;

export const WapperModal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

export const TitleModal = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;

  color: var(--brand-colors-blue-blue-500, #263668);
  font-family: 'Mulish';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-left: 0.5rem;
`;

export const SubtitleModal = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;

  color: var(--neutral-gray-gray-700, #545454);
  font-family: 'Mulish';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 0.5rem;
`;

export const TimeModal = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;

  color: var(--neutral-gray-gray-700, #545454);
  font-family: 'Mulish';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-left: 0.5rem;
`;

export const CheckboxModal = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;

  color: var(--neutral-gray-gray-700, #545454);
  font-family: 'Mulish';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.5rem;
`;

export const ClientTag = styled.span`
  display: flex;
  padding: 0.375rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.375rem;
  background: var(--neutral-bluish-bluish-100, #e6ecf1);

  color: var(--brand-colors-blue-blue-800, #0f162a);
  font-family: Mulish;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
