import moment from 'moment';
import { Col, Row } from 'antd';
import { FC, useState } from 'react';
import * as S from './collapsible-card.styles';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

interface CollapsibleCardProps {
  date: Date;
  title: string;
  subtitle: string;
  description: string;
}

export const CollapsibleCard: FC<CollapsibleCardProps> = ({ date, title, subtitle, description }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return (
    <S.CollapsibleCardWrapper>
      <S.CollapsibleCardHeader isCollapsed={isCollapsed} onClick={() => setIsCollapsed(!isCollapsed)}>
        <Col span={2}>
          <S.CollapsibleCardHeaderDateDay>{moment(date).format('DD')}</S.CollapsibleCardHeaderDateDay>
          <S.CollapsibleCardHeaderDateMonth>
            {moment(date).format('MMM').toUpperCase()}
          </S.CollapsibleCardHeaderDateMonth>
        </Col>
        <Col span={19}>
          <Row gutter={8}>
            <Col span={24}>
              <S.CollapsibleCardHeaderTitle>{title}</S.CollapsibleCardHeaderTitle>
            </Col>
            <Col span={24}>
              <S.CollapsibleCardHeaderSubtitle>{subtitle}</S.CollapsibleCardHeaderSubtitle>
            </Col>
          </Row>
        </Col>
        <Col span={3}>
          <S.CollapsibleCardHeaderIcon>
            {isCollapsed ? <PlusOutlined /> : <MinusOutlined />}
          </S.CollapsibleCardHeaderIcon>
        </Col>
      </S.CollapsibleCardHeader>
      <S.CollapsibleCardContent isCollapsed={isCollapsed}>{description}</S.CollapsibleCardContent>
    </S.CollapsibleCardWrapper>
  );
};
