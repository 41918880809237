import styled from 'styled-components';
import { Collapse as AntCollapse } from 'antd';
import { Panel as AntPanel } from '@app/components/common/Collapse/Collapse';

export const Card = styled(AntCollapse)`
  margin: 0;
  background-color: #fff;
  padding: 0;

  .ant-collapse-item {
    border: 1px solid black;
    border-radius: 7px;
    /* display: flex; */
    /* justify-content: space-between; */
    margin: 1rem auto;
    width: 100%;
  }

  .ant-collapse-item:last-child {
    border: 1px solid black;
    border-radius: 7px;
    /* display: flex; */
    /* justify-content: space-between; */
    margin: 1rem auto;
    width: 100%;
  }

  .ant-collapse-header {
    margin: 0.5rem 0.5rem;
    font-size: 1.25rem;

    svg {
      font-size: 1.5rem;
      rotate: 180deg;
      transform: rotate(270deg);
    }
  }
`;

export const CardContent = styled(AntPanel)``;
