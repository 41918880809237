import React, { useEffect } from 'react';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { Navigate } from 'react-router-dom';
import { doLogout } from '@app/store/slices/authSlice';
import { limparCookie } from '@app/utils/utils';

const Logout: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    limparCookie();
    dispatch(doLogout());
  }, [dispatch]);

  return <Navigate to="/auth" replace />;
};

export default Logout;
