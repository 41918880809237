import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';

export interface FooterSlice {
  confirmButtonText: string;
  handleConfirmButtonClick: () => void;
  cancelButtonText: string;
  handleCancelButtonClick: () => void;
  tagAButtonText: string;
  handleTagAButtonClick: () => void;
  confirmButtonDisabled: boolean;
}

const initialState: FooterSlice = {
  confirmButtonDisabled: false,
  confirmButtonText: '',
  handleConfirmButtonClick: () => {
    // event.preventDefault();
  },
  cancelButtonText: '',
  handleCancelButtonClick: () => {
    // event.preventDefault();
  },
  tagAButtonText: '',
  handleTagAButtonClick: () => {
    // event.preventDefault();
  },
};

export const setFooter = createAction<PrepareAction<FooterSlice>>('footer/setFooter', (newFooter: FooterSlice) => {
  return {
    payload: newFooter,
  };
});

export const footerSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setFooter, (state, action) => {
      state.confirmButtonDisabled = action.payload.confirmButtonDisabled;
      state.confirmButtonText = action.payload.confirmButtonText;
      state.handleConfirmButtonClick = action.payload.handleConfirmButtonClick;
      state.cancelButtonText = action.payload.cancelButtonText;
      state.handleCancelButtonClick = action.payload.handleCancelButtonClick;
      state.tagAButtonText = action.payload.tagAButtonText;
      state.handleTagAButtonClick = action.payload.handleTagAButtonClick;
    });
  },
});

export default footerSlice.reducer;
