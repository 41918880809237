import { ApiServiceBase, IApiServiceBase } from '@app/services/base/apiServiceBase';
import { DeviceProfileModel, DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import { CanVehicleParametersCalculatedModel, CanVehicleParametersOnOffModel } from '@app/domain/canVehicle/canVehicle';

export default interface IDeviceProfileService extends IApiServiceBase<DeviceProfileModel, DeviceProfileModel> {
  getPropertiesByIdProfile(id: number): Promise<DeviceProfileProperty[]>;
  getByModelAndClient(idModel: number, idClient: number): Promise<DeviceProfileModel[]>;
  getSelectedCalculatedParameters(
    idDispositivo: number,
    idPerfilDispositivo: number,
  ): Promise<CanVehicleParametersCalculatedModel[]>;
  getSelectedOnOffParameters(
    idDispositivo: number,
    idPerfilDispositivo: number,
  ): Promise<CanVehicleParametersOnOffModel[]>;
  insertSelectedCanParameters(
    idCanVehicle: number,
    selectedParametersCalculated: CanVehicleParametersCalculatedModel[],
    selectedParametersOnOff: CanVehicleParametersOnOffModel[],
    idProfile?: number,
    idDevice?: number,
  ): Promise<void>;
}

export class DeviceProfileService extends ApiServiceBase<DeviceProfileModel, DeviceProfileModel> {
  constructor() {
    super('PerfilDispositivo');
  }

  async getPropertiesByIdProfile(id: number): Promise<DeviceProfileProperty[]> {
    try {
      return (await this.api.get(`${id}/propriedades`))?.data;
    } catch (error) {
      throw error;
    }
  }

  async getByModelAndClient(idModel: number, idClient: number): Promise<DeviceProfileModel[]> {
    try {
      return (await this.api.get(`obter-por-modelo-e-cliente/${idModel}/${idClient}`))?.data;
    } catch (error) {
      throw error;
    }
  }

  async getSelectedCalculatedParameters(
    idDispositivo: number,
    idPerfilDispositivo: number,
  ): Promise<CanVehicleParametersCalculatedModel[]> {
    try {
      return (
        await this.api.post(`parametro-calculado-selecionado`, {
          idDispositivo,
          idPerfilDispositivo,
        })
      )?.data;
    } catch (error) {
      throw error;
    }
  }

  async getSelectedOnOffParameters(
    idDispositivo: number,
    idPerfilDispositivo: number,
  ): Promise<CanVehicleParametersOnOffModel[]> {
    try {
      return (
        await this.api.post(`parametro-ligado-desligado-selecionado`, {
          idDispositivo,
          idPerfilDispositivo,
        })
      )?.data;
    } catch (error) {
      throw error;
    }
  }

  async thereIsFirmwareVersionre(idGrupoFirmware: number, versaoFirmware: string): Promise<boolean> {
    try {
      const response = await this.api.get(`existe-grupo-versao-firmware/${idGrupoFirmware}/${versaoFirmware}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async insertSelectedCanParameters(
    idCanVehicle: number,
    selectedParametersCalculated: CanVehicleParametersCalculatedModel[],
    selectedParametersOnOff: CanVehicleParametersOnOffModel[],
    idProfile?: number,
    idDevice?: number,
  ): Promise<void> {
    try {
      const requestData = {
        idPerfilDispositivo: idProfile,
        parametrosCalculado: selectedParametersCalculated,
        parametrosLigadoDesligado: selectedParametersOnOff,
        idVeiculoCAN: idCanVehicle,
        idDispositivo: idDevice,
      };

      await this.api.post('inserir-parametros-can-selecionados', requestData);
    } catch (error) {
      throw error;
    }
  }
}
