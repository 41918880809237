import { Panel } from '@app/components/common/Collapse/Collapse';
import styled from 'styled-components';

export const TableHeader = styled(Panel)`
  && .ant-collapse-header-text,
  .ant-collapse-expand-icon {
    color: var(--text-label);
    font-family: 'Mulish';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }
`;

export const NoContent = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;
  height: 100%;

  .title {
    color: var(--text-main-color);
    text-align: center;
    font-family: 'Mulish';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .subtitle {
    color: var(--input-placeholder-color);
    font-family: 'Mulish';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;
