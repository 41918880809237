import { Row } from 'antd';
import * as S from './TableNoContent.styles';

interface ITableNoContent {
  title?: string;
  subTitleBlue?: string;
  subTitleGray?: string;
}

export const TableNoContent: React.FC<ITableNoContent> = ({ title, subTitleBlue, subTitleGray }) => {
  return (
    <S.Container>
      <Row>
        <S.Title>{title}</S.Title>
      </Row>
      <Row>
        <S.SubTitleBlue>{subTitleBlue}</S.SubTitleBlue>
        <S.SubTitleGray>{subTitleGray}</S.SubTitleGray>
      </Row>
    </S.Container>
  );
};
