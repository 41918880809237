import { Col, Row, Tooltip } from 'antd';
import styled from 'styled-components';

export const PerformanceContainerTooltip = styled(Tooltip)``;
export const PerformanceContainerTooltipContent = styled(Row)`
  min-height: 3rem;
  font-family: Mulish;
  font-size: 0.75rem;
  text-align: left;
  line-height: 1.5rem;
  color: #545454;
  display: flex;
`;
export const PerformanceContainerTooltipTitle = styled(Col)`
  font-weight: 600;

  & span {
    display: block;
    font-weight: 400;
    color: #707070;
  }
`;
export const PerformanceContainerTooltipSubtitle = styled(Col)``;
