import React, { FC, useEffect, useState } from 'react';
import { Modal } from '@app/components/common/Modal/Modal';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';
import * as S from '../components.styles';
import CardDetails from '@app/components/common/Card/CardDetails/CardDetails';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Col, Radio, RadioChangeEvent, Row, Space, Spin } from 'antd';
import { RadioGroup } from '@app/components/common/Radio/Radio';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { Button } from '@app/components/common/buttons/Button/Button';
import { IconDeleteOutlined } from '@app/assets/slump-icons';
import { LoadingOutlined } from '@ant-design/icons';
import { isJM15 } from '@app/utils/utils';
import DeviceSerialPort from '@app/services/deviceSerialPortClass';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const deviceSerialPortClass = DeviceSerialPort.getInstance();

interface IModalIndividualTag {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  device: FactoryDeviceModel;
  setDevice: React.Dispatch<React.SetStateAction<FactoryDeviceModel>>;
}

export const ModalIndividualTag: FC<IModalIndividualTag> = ({ visible, setVisible, device, setDevice }) => {
  const [type, setType] = useState<0 | 1>(0);
  const [tag, setTag] = useState<string>('');
  const [hasStartedReading, setHasStartedReading] = useState(false);

  const handleTypeChange = (e: RadioChangeEvent) => {
    setType(e.target.value);
    setTag('');
  };

  const handleAddTag = (tag: string) => {
    const newTag: string = tag;

    setDevice((prevState) => {
      if (prevState?.tags?.find((t) => t === newTag)) {
        notificationController.error({ message: 'Já existe uma tag igual cadastrada' });
        return prevState;
      }

      notificationController.success({ message: 'Tag adicionada na listagem com sucesso!' });
      setTag('');

      const tagsToSendToDevice = [] as string[];

      tagsToSendToDevice.push(newTag);

      // remove duplicates
      const uniqueTags = Array.from(new Set(tagsToSendToDevice));

      return {
        ...prevState,
        tags: [...(prevState.tags || []), newTag],
        tagsToSendToDevice: uniqueTags,
      };
    });
  };

  const handleValidateInputTag = (value: string, setTag: React.Dispatch<React.SetStateAction<string>>) => {
    const validatedTag = value.replace(/\s/g, '').replace(/[^a-zA-Z0-9]/g, '');
    setTag(validatedTag);
  };

  const handleTagsOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && e.currentTarget.value.trim() !== '') {
      e.preventDefault();
      handleAddTag(e.currentTarget.value);
    }
  };

  const handleDeleteTag = (tagToDelete: string) => {
    setDevice((prevState) => ({
      ...prevState,
      tags: prevState.tags?.filter((tag) => tag !== tagToDelete) || [],
    }));
    notificationController.success({ message: 'Tag deletada da listagem com sucesso!' });
  };

  const startRead = async () => {
    if (!hasStartedReading) {
      setHasStartedReading(true);
      await deviceSerialPortClass.startContinuousRead(handleAddTag);
      setHasStartedReading(false);
    }
  };

  useEffect(() => {
    if (visible && isJM15(device?.modelo) && !hasStartedReading) {
      startRead();
    }

    if (!visible && isJM15(device?.modelo)) {
      deviceSerialPortClass.stopContinuousRead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, device?.modelo]);

  const closeModal = () => {
    setDevice((prevState) => {
      const tagsToSendToDevice = [] as string[];
      if (prevState.tagsToSendToDevice) tagsToSendToDevice.push(...prevState.tagsToSendToDevice);
      if (prevState.tags) tagsToSendToDevice.push(...prevState.tags);
      const uniqueTags = Array.from(new Set(tagsToSendToDevice));

      return {
        ...prevState,
        tags: [],
        tagsToSendToDevice: uniqueTags,
      };
    });
    setVisible(false);
  };

  return (
    <Modal
      open={visible}
      title="Vincular tag individualmente"
      onOk={closeModal}
      onCancel={closeModal}
      cancelText="Cancelar"
      okText="Vincular"
      size="large"
      style={{ minWidth: '70%' }}
      okButtonProps={{ disabled: device?.tags?.length === 0 }}
    >
      <HelperNotification>
        Para vincular as tags é uma boa prática vincular por aproximação para evitar falhas no cadastro.
      </HelperNotification>

      <CardDetails title="Cadastro da tag">
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Row gutter={10}>
            <Col span={8} style={{ marginRight: '1rem' }}>
              <Row>
                <Col span={24} style={{ maxHeight: '2rem' }}>
                  <RadioGroup value={type} defaultValue={null} onChange={handleTypeChange}>
                    <Space size={0} direction="vertical">
                      <Radio value={0}>Aproximação</Radio>
                    </Space>
                  </RadioGroup>
                </Col>
                <Col span={24} style={{ marginTop: '.5rem' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    {type == 0 && (
                      <>
                        <Row style={{ display: 'flex', gap: '.5rem' }}>
                          <Spin indicator={antIcon} />
                          Aguardando a aproximação da tag ao dispositivo
                        </Row>
                      </>
                    )}
                  </div>
                </Col>
                <Col span={24} style={{ marginTop: '.5rem' }}></Col>
                <Col span={24}></Col>
              </Row>
            </Col>

            <Col span={10} style={{ borderLeft: '1px solid #D9D9D9', paddingLeft: '1.5rem' }}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <RadioGroup value={type} defaultValue={0} onChange={handleTypeChange}>
                    <Radio value={1}>Manual</Radio>
                  </RadioGroup>
                </Col>
                <Col span={24}>
                  <Row gutter={8}>
                    <Col span={18}>
                      <BaseFormInputItem
                        label=""
                        supportText="Pressione a tecla “enter” ou clique no botão adicionar."
                        style={{ width: '100%' }}
                      >
                        <Input
                          placeholder="Digite o ID da tag"
                          maxLength={17}
                          value={tag}
                          onChange={(e) => handleValidateInputTag(e.target.value, setTag)}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleTagsOnKeyDown(e)}
                          disabled={type !== 1}
                        />
                      </BaseFormInputItem>
                    </Col>
                    <Col span={6}>
                      <Button type="primary" onClick={() => handleAddTag(tag)} disabled={type !== 1}>
                        Adicionar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </BaseForm>
      </CardDetails>

      <CardDetails title="Tags vinculadas">
        {device?.tags && device?.tags.length > 0 ? (
          <Row gutter={[8, 8]} style={{ maxHeight: '30rem', width: '100%' }}>
            {device?.tags.map((tag, index) => (
              <Col key={index} xs={4} xl={4}>
                <Row align={'middle'} style={{ gap: '.5rem' }}>
                  <S.TagValue>{tag}</S.TagValue>
                  <Button type="link" style={{ marginLeft: '-0.875rem' }} onClick={() => handleDeleteTag(tag)}>
                    <IconDeleteOutlined />
                  </Button>
                </Row>
              </Col>
            ))}
          </Row>
        ) : (
          <>
            <S.NoContainer>
              <S.NoTagsTitle>Nenhum cadastro realizado</S.NoTagsTitle>
              <span>Cadastre a(s) tag(s) para exibir os resultados</span>
            </S.NoContainer>
          </>
        )}
      </CardDetails>
    </Modal>
  );
};
