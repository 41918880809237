import { SystemParameterModel, TypeSystemParameterModel } from '@app/domain/systemParameters/systemParameterModel';
import { ApiServiceBase, IApiServiceBase } from './base/apiServiceBase';

export default interface ISystemParameterService extends IApiServiceBase<SystemParameterModel, SystemParameterModel> {
  getTypes(): Promise<TypeSystemParameterModel[]>;
}

export class SystemParameterService extends ApiServiceBase<SystemParameterModel, SystemParameterModel> {
  constructor() {
    super('ParametroSistema');
  }

  async getTypes(): Promise<TypeSystemParameterModel[]> {
    return (await this.api.get('obter-tipos')).data;
  }
}
