export enum DeviceBlockTypeAction {
  Bloqueio = 1,
  Desbloqueio = 2,
}

export enum DeviceBlockTypeStatus {
  AguardandoAprovacao = 1,
  Pendente = 2,
  Parcial = 3,
  Concluido = 4,
  Expirado = 5,
  Reprovado = 6,
}

export interface DeviceBlockModel {
  id: number;
  idCliente: number;
  cliente: string;
  tentativas: number;
  usuarioResponsavel: string;
  dataHoraAcao: Date;
  dataHoraAprovacao: Date;
  acao: DeviceBlockTypeAction;
  status: DeviceBlockTypeStatus;
  statusNome: string;
  modelos: string;
  concluidos: number;
  totalDispositivos: number;
  dispositivos?: DeviceBlockModelDevices[];
}

export interface DeviceBlockModelDevices {
  id: number;
  idBloqueio: number;
  idDispositivo: number;
  numeroSerie: string;
  finalizado: boolean;
  modelo: string;
}
