import styled from 'styled-components';
import { Collapse as AntdCollapse } from 'antd';

export const CollapseMap = styled(AntdCollapse)`
  background-color: transparent !important;
  border-bottom: 1px solid var(--border-base);
  border-radius: 8px;
  border: 1px solid var(--Neutral-Gray-Gray300, #bababa);
  background: #fff;

  && .ant-collapse-header {
    align-items: center !important;
    border-bottom: 0 !important;
    padding: 1.5rem;
  }
  && .ant-collapse-content {
    border-top: 0 !important;
  }

  && .ant-collapse-content-box {
    padding: 0rem 1.5rem 1.5rem 1.5rem;
  }
`;

export const CollapseHeaderName = styled.div`
  color: var(--Neutral-Gray-Gray800, #383838);
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const TableFenceQuantity = styled.div`
  color: var(--Neutral-Gray-Gray700, #545454);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
`;

export const TableFenceDescription = styled.div`
  color: var(--Neutral-Gray-Gray500, #8c8c8c);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TableFenceName = styled.div`
  color: #545454;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const TableTypeName = styled.div`
  color: var(--Neutral-Gray-Gray700, #545454);
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1rem;
`;
