import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import userReducer from '@app/store/slices/userSlice';
import authReducer from '@app/store/slices/authSlice';
import headerReducer from '@app/store/slices/headerSlice';
import headerRegisterSlice from '@app/store/slices/headerRegisterSlice';
import footerSlice from '@app/store/slices/footerSlice';
import viewDeviceSlice from './viewDeviceSlice';
import sendParametersSlice from '@app/store/slices/sendParametersSlice';

export default {
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  user: userReducer,
  auth: authReducer,
  header: headerReducer,
  headerRegister: headerRegisterSlice,
  footer: footerSlice,
  viewDevice: viewDeviceSlice,
  sendParameters: sendParametersSlice,
};
