/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Tag } from 'antd';
import React from 'react';
import * as S from '../../view.styles';
import moment from 'moment';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import CopyToClipboard from '@app/components/common/CopyToClipboard/inde';

interface IViewDeviceHeader {
  device: FactoryDeviceModel;
}

export const ViewDeviceHeader: React.FC<IViewDeviceHeader> = ({ device }) => {
  return (
    <>
      <div style={{ marginLeft: '-1rem', marginBottom: '1.5rem' }}>
        <Tag
          color="#E6ECF1"
          style={{ color: '#0F162A', fontWeight: '600', width: 'auto', textAlign: 'center', padding: '0.5rem' }}
        >
          Cliente: {device?.cliente ?? '-'}
        </Tag>
      </div>
      <S.CollapseContainer>
        <S.Collapse bordered={false} defaultActiveKey={1}>
          <h1>Informações do dispositivo</h1>
          <S.DisplayContainer>
            <S.ImageContainer>
              <img width={180} src={device?.imagemUrl ?? ''} alt="Rastreador" />
            </S.ImageContainer>
            <S.DeviceInfoContainer>
              <Row gutter={24} justify={'start'}>
                <Col xs={24} md={12} lg={5} xxl={5}>
                  <S.DeviceCardInfoProperty>Equipamento</S.DeviceCardInfoProperty>
                  <S.DeviceCardInfoValue>{device?.tipoEquipamento ?? '-'}</S.DeviceCardInfoValue>
                </Col>
                <Col xs={24} md={12} lg={4} xxl={4}>
                  <S.DeviceCardInfoProperty>Modelo</S.DeviceCardInfoProperty>
                  <S.DeviceCardInfoValue>{device?.modelo ?? '-'}</S.DeviceCardInfoValue>
                </Col>
                <Col xs={24} md={12} lg={5} xxl={5}>
                  <S.DeviceCardInfoProperty>Apelido</S.DeviceCardInfoProperty>
                  <S.DeviceCardInfoValue>{device?.apelido ?? '-'}</S.DeviceCardInfoValue>
                </Col>
                <Col xs={24} md={12} lg={5} xxl={5}>
                  <S.DeviceCardInfoProperty>Grupo de dispositivo</S.DeviceCardInfoProperty>
                  <S.DeviceCardInfoValue>{device?.grupo ?? '-'}</S.DeviceCardInfoValue>
                </Col>
                <Col xs={24} md={12} lg={5} xxl={5}>
                  <S.DeviceCardInfoProperty>SN</S.DeviceCardInfoProperty>
                  <S.DeviceCardInfoValue>
                    {device?.numeroSerie ?? '-'}
                    <CopyToClipboard
                      textToCopy={device?.numeroSerie ?? '-'}
                      tooltip={'Copiar'}
                      style={{ width: '20px' }}
                    />
                  </S.DeviceCardInfoValue>
                </Col>
              </Row>
              <br />
              <Row gutter={24} justify={'start'}>
                <Col xs={24} md={12} lg={5} xxl={5}>
                  <S.DeviceCardInfoProperty>IMEI</S.DeviceCardInfoProperty>
                  <S.DeviceCardInfoValue>
                    {device?.imei ?? '-'}
                    <CopyToClipboard textToCopy={device?.imei ?? '-'} tooltip={'Copiar'} style={{ width: '20px' }} />
                  </S.DeviceCardInfoValue>
                </Col>
                <Col xs={24} md={12} lg={4} xxl={4}>
                  <S.DeviceCardInfoProperty>Perfil de configuração</S.DeviceCardInfoProperty>
                  <S.DeviceCardInfoValue>
                    {device?.perfil ? device.perfil : device.testeRapido ? 'Teste Rápido' : '-'}
                  </S.DeviceCardInfoValue>
                </Col>
                <Col xs={24} md={12} lg={5} xxl={5}>
                  <S.DeviceCardInfoProperty>Status do perfil</S.DeviceCardInfoProperty>
                  <S.DeviceCardInfoValue>
                    {device?.perfilStatus === true || device.testeRapido ? (
                      <Tag color="#E9F4EE" style={{ color: '#083F18', width: '70px', textAlign: 'center' }}>
                        Ativo
                      </Tag>
                    ) : (
                      <Tag color="#FEE9EA" style={{ color: '#620E12', width: '70px', textAlign: 'center' }}>
                        Inativo
                      </Tag>
                    )}
                  </S.DeviceCardInfoValue>
                </Col>

                {device?.modelo === 'J-M15' && (
                  <Col xs={24} md={12} lg={5} xxl={5}>
                    <S.DeviceCardInfoProperty>Grupo de tags</S.DeviceCardInfoProperty>
                    <S.DeviceCardInfoValue>{device?.grupoTag ?? '-'}</S.DeviceCardInfoValue>
                  </Col>
                )}

                {device.connectionState && device.permiteConexao && (
                  <Col xs={24} md={12} lg={5} xxl={5}>
                    <S.DeviceCardInfoProperty>Conexão</S.DeviceCardInfoProperty>
                    <S.DeviceCardInfoValue>
                      {device?.connectionState === 'Connected' ? (
                        <Tag color="#E9F4EE" style={{ color: '#083F18', width: '70px', textAlign: 'center' }}>
                          Online
                        </Tag>
                      ) : (
                        <Tag color="#FEE9EA" style={{ color: '#620E12', width: '70px', textAlign: 'center' }}>
                          Offline
                        </Tag>
                      )}
                    </S.DeviceCardInfoValue>
                  </Col>
                )}
                {device.ultimaConexao && (
                  <Col xs={24} md={12} lg={5} xxl={5}>
                    <S.DeviceCardInfoProperty>Última conexão</S.DeviceCardInfoProperty>
                    <S.DeviceCardInfoValue>
                      {device?.ultimaConexao ? moment(device.ultimaConexao).format('DD/MM/yyyy HH:mm') : '-'}
                    </S.DeviceCardInfoValue>
                  </Col>
                )}
              </Row>
              <br />
              <Row gutter={24} justify={'start'}>
                <Col xs={24} md={12} lg={5} xxl={5}>
                  <S.DeviceCardInfoProperty>Versão firmware</S.DeviceCardInfoProperty>
                  <S.DeviceCardInfoValue>{device?.versaoFirmware ?? '-'}</S.DeviceCardInfoValue>
                </Col>
                <Col xs={24} md={12} lg={4} xxl={4}>
                  <S.DeviceCardInfoProperty>Anatel</S.DeviceCardInfoProperty>
                  <S.DeviceCardInfoValue>{device?.anatel ?? '-'}</S.DeviceCardInfoValue>
                </Col>
                {device.iccid && (
                  <Col xs={24} md={12} lg={5} xxl={5}>
                    <S.DeviceCardInfoProperty>ICCID</S.DeviceCardInfoProperty>
                    <S.DeviceCardInfoValue>
                      {device?.iccid ?? '-'}
                      <CopyToClipboard textToCopy={device?.iccid ?? '-'} tooltip={'Copiar'} style={{ width: '20px' }} />
                    </S.DeviceCardInfoValue>
                  </Col>
                )}
                {device.imsi && (
                  <Col xs={24} md={12} lg={5} xxl={5}>
                    <S.DeviceCardInfoProperty>IMSI</S.DeviceCardInfoProperty>
                    <S.DeviceCardInfoValue>
                      {device?.imsi ?? '-'}
                      <CopyToClipboard textToCopy={device?.imsi ?? '-'} tooltip={'Copiar'} style={{ width: '20px' }} />
                    </S.DeviceCardInfoValue>
                  </Col>
                )}

                {device?.idVeiculoCan ? (
                  <Col xs={24} md={12} lg={5} xxl={5}>
                    <S.DeviceCardInfoProperty>Veículo CAN</S.DeviceCardInfoProperty>
                    <S.DeviceCardInfoValue>
                      <img width={40} src={device.fabricanteVeiculoImagem} />
                      {`${device.fabricanteVeiculo ?? '-'} | ${device?.modeloVeiculo ?? '-'} | ${
                        device?.anoVeiculo ?? '-'
                      } `}
                    </S.DeviceCardInfoValue>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </S.DeviceInfoContainer>
          </S.DisplayContainer>
        </S.Collapse>
      </S.CollapseContainer>
    </>
  );
};
