import React, { FC, useCallback, useEffect, useRef } from 'react';
import * as S from './MapComponentView.styles';
import {
  GoogleMap,
  GoogleMapProps,
  LoadScript,
  StandaloneSearchBox,
  StandaloneSearchBoxProps,
} from '@react-google-maps/api';
import { Input } from '@app/components/common/inputs/Input/Input';
import { FenceModel } from '@app/domain/fence/fenceModel';
import { ZoomButtons } from '@app/components/common/GoogleMapItems/ZoomButtons';
import { SavedFences } from '@app/pages/fence/create/components/MapComponentCreate/SavedFences/SavedFences';
import appSettings from '@app/config/appsettings';
import { Button } from '@app/components/common/buttons/Button/Button';
import { FileExcelOutlined } from '@ant-design/icons';
import { addData, createWorkbook, saveToFile, setColumnWidths, setHeaders } from '@app/utils/exportToExcel';

const containerStyle = {
  width: '100%',
  height: '37rem',
};

// used to center the map if no fences have points
const supermixLocation = {
  lat: -43.97931295928024,
  lng: -19.878877423869252,
};

interface MapComponentProps {
  fences: FenceModel[];
  showInactiveFences: boolean;
  isUniqueFenceView?: boolean;
}

const MapComponentView: FC<MapComponentProps> = ({ fences, showInactiveFences, isUniqueFenceView = false }) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);
  const controlsRef = useRef<HTMLDivElement | null>(null);

  const getMapInitialPoint = useCallback(() => {
    const activeFenceWithPoints = fences.find((fence) => fence.ativo && fence.pontos?.length > 0);
    if (activeFenceWithPoints) {
      return {
        lat: activeFenceWithPoints.pontos[0].lat,
        lng: activeFenceWithPoints.pontos[0].lng,
      };
    }

    const inactiveFenceWithPoints = fences.find((fence) => !fence.ativo && fence.pontos?.length > 0);
    if (inactiveFenceWithPoints) {
      return {
        lat: inactiveFenceWithPoints.pontos[0].lat,
        lng: inactiveFenceWithPoints.pontos[0].lng,
      };
    }

    return supermixLocation;
  }, [fences]);

  const onPlacesChanged: StandaloneSearchBoxProps['onPlacesChanged'] = () => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();
      const place = places?.[0];

      if (place && place.geometry && place.geometry.location && mapRef.current) {
        mapRef.current.panTo(place.geometry.location);
        mapRef.current.setZoom(15);
      }
    }
  };

  const onMapLoad: GoogleMapProps['onLoad'] = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    map.setOptions({
      gestureHandling: 'cooperative',
      fullscreenControl: true,
      zoomControl: false,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      },
    });
    if (map && controlsRef.current) {
      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlsRef.current);
    }
  }, []);

  const handleExportToExcel = async () => {
    const fence = fences[0];

    const { workbook, worksheet } = createWorkbook('FenceDetails');

    setHeaders(worksheet, ['ID', 'Nome', 'Tipo']);
    worksheet.addRow([fence.id, fence.nome, fence.tipoCerca]);

    const pontosRow = worksheet.addRow(['Pontos:']);
    pontosRow.getCell(1).merge(pontosRow.getCell(3));

    setHeaders(worksheet, ['Latitude', 'Longitude']);

    const data = fence.pontos.map((point) => [point.lat, point.lng]);
    addData(worksheet, data);

    const columnWidths = ['ID', 'Nome', 'Tipo', 'Latitude', 'Longitude'].map((header) => ({
      key: header,
      width: header.length + 5,
    }));
    setColumnWidths(worksheet, columnWidths);

    await saveToFile(workbook, `cerca_${fence.id}_pontos`);
  };

  useEffect(() => {
    const fence = fences.find((fence) => fence.ativo && fence.pontos?.length > 0);

    if (fence && mapRef.current && fence.pontos[0]?.lat && fence.pontos[0]?.lng) {
      mapRef.current.panTo(new google.maps.LatLng(fence.pontos[0].lat, fence.pontos[0].lng));
      mapRef.current.setZoom(13);
    }
  }, [fences]);

  return (
    fences?.length > 0 && (
      <LoadScript googleMapsApiKey={appSettings().MAPS_API_KEY || ''} libraries={['places', 'geometry']}>
        <GoogleMap mapContainerStyle={containerStyle} center={getMapInitialPoint()} zoom={14} onLoad={onMapLoad}>
          <StandaloneSearchBox
            onLoad={(ref) => {
              if (ref) searchBoxRef.current = ref;
            }}
            onPlacesChanged={onPlacesChanged}
          >
            <Input
              type="text"
              placeholder="Pesquisar no Google Maps"
              style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                width: '400px',
                borderRadius: '2.5rem',
                paddingLeft: '20px',
              }}
            />
          </StandaloneSearchBox>

          <SavedFences fences={fences} showInactiveFences={showInactiveFences} />

          <S.ButtonsMapWrapper>
            {isUniqueFenceView && (
              <Button style={{ height: '2.5rem' }} onClick={() => handleExportToExcel()}>
                <FileExcelOutlined /> Exportar pontos Excel
              </Button>
            )}
            <ZoomButtons mapRef={mapRef} />
          </S.ButtonsMapWrapper>
        </GoogleMap>
      </LoadScript>
    )
  );
};

export default MapComponentView;
