import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Table } from '@app/components/common/Table/Table';
import {
  CanVehicleModel,
  CanVehicleParametersCalculatedModel,
  CanVehicleParametersOnOffModel,
} from '@app/domain/canVehicle/canVehicle';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Button } from '@app/components/common/buttons/Button/Button';
import { IconMore } from '@app/assets/slump-icons';
import { hasAccessByRoles } from '@app/controllers/accessController';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { UserType } from '@app/constants/enums/userType';
import { CanVehicleParameterType } from '@app/constants/enums/canVehicle/can-vehicle-parameter-type';

interface ICreateCanVehicleParametersTableProps {
  canVehicle: CanVehicleModel;
  setParameterType: React.Dispatch<React.SetStateAction<CanVehicleParameterType>>;
  setEditParametersCalculated: React.Dispatch<React.SetStateAction<CanVehicleParametersCalculatedModel>>;
  setEditParametersOnOff: React.Dispatch<React.SetStateAction<CanVehicleParametersOnOffModel>>;
  setModalEditVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setModalDeleteParameterVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setParameterToDelete: React.Dispatch<React.SetStateAction<number>>;
}

export const CreateCanVehicleParametersTable: React.FC<ICreateCanVehicleParametersTableProps> = ({
  canVehicle,
  setParameterType,
  setEditParametersCalculated,
  setEditParametersOnOff,
  setModalEditVisible,
  setModalDeleteParameterVisible,
  setParameterToDelete,
}) => {
  const [calculated, setCalculated] = useState<CanVehicleParametersCalculatedModel[]>([]);
  const [onOff, setOnOff] = useState<CanVehicleParametersOnOffModel[]>([]);

  const calculatedColumns: ColumnsType<CanVehicleParametersCalculatedModel> = [
    {
      title: 'Nome ID',
      dataIndex: 'nomeCAN',
      showSorterTooltip: false,
      sortDirections: [],
      defaultSortOrder: 'descend',
      width: '10%',
    },
    {
      title: 'ID',
      dataIndex: 'parametroId',
      showSorterTooltip: false,
      width: '8%',
    },
    {
      title: 'Resolução',
      dataIndex: 'resolucao',
      showSorterTooltip: false,
      width: '18%',
    },
    {
      title: 'Offset',
      dataIndex: 'offset',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: 'Bit inicial',
      dataIndex: 'bitInicial',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: 'Tamanho em bits',
      dataIndex: 'tamanhoBits',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: 'Tipo da informação',
      dataIndex: 'tipoInformacao',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: '',
      dataIndex: 'id',
      width: '1%',
      render: (_, canVehicle: CanVehicleParametersCalculatedModel) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {hasAccessByRoles([UserType.FactoryAdmin, UserType.Factory]) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ color: '#545454' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <MenuItem>
                      <Button
                        type="text"
                        onClick={() => {
                          setParameterType(CanVehicleParameterType.Calculado);
                          setEditParametersCalculated(canVehicle);
                          setModalEditVisible(true);
                        }}
                      >
                        Editar
                      </Button>
                    </MenuItem>

                    <MenuItem>
                      <Button
                        type="text"
                        onClick={() => {
                          setModalDeleteParameterVisible(true);
                          setParameterToDelete(canVehicle.nomeId);
                          setParameterType(CanVehicleParameterType.Calculado);
                        }}
                      >
                        Excluir
                      </Button>
                    </MenuItem>
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  const onOffColumns: ColumnsType<CanVehicleParametersOnOffModel> = [
    {
      title: 'Nome ID',
      dataIndex: 'nomeCAN',
      showSorterTooltip: false,
      sortDirections: [],
      defaultSortOrder: 'descend',
      width: '10%',
    },
    {
      title: 'ID',
      dataIndex: 'parametroId',
      showSorterTooltip: false,
      width: '8%',
    },
    {
      title: 'Bit inicial',
      dataIndex: 'bitInicial',
      showSorterTooltip: false,
      width: '18%',
    },
    {
      title: 'Tamanho em bits',
      dataIndex: 'tamanhoBits',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: 'Tipo da informação',
      dataIndex: 'tipoInformacao',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: 'Ligado',
      dataIndex: 'ligado',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: 'Desligado',
      dataIndex: 'desligado',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: '',
      dataIndex: 'id',
      width: '1%',
      render: (_, canVehicle: CanVehicleParametersOnOffModel) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {hasAccessByRoles([UserType.FactoryAdmin, UserType.Client]) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ color: '#545454' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <MenuItem
                      onClick={() => {
                        setParameterType(CanVehicleParameterType.LigadoDesligado);
                        setEditParametersOnOff(canVehicle);
                        setModalEditVisible(true);
                      }}
                    >
                      <Button type="text">Editar</Button>
                    </MenuItem>

                    <MenuItem>
                      <Button
                        type="text"
                        onClick={() => {
                          setModalDeleteParameterVisible(true);
                          setParameterToDelete(canVehicle.nomeId);
                          setParameterType(CanVehicleParameterType.LigadoDesligado);
                        }}
                      >
                        Excluir
                      </Button>
                    </MenuItem>
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setCalculated(canVehicle.parametroCalculado);
    setOnOff(canVehicle.parametroLigadoDesligado);
  }, [canVehicle]);

  return (
    <>
      {calculated.length > 0 && (
        <>
          <h2>Calculados</h2>
          <Table columns={calculatedColumns} dataSource={calculated} scroll={{ x: 800 }} bordered />
        </>
      )}
      {onOff.length > 0 && (
        <>
          <h2>On/Off</h2>
          <Table columns={onOffColumns} dataSource={onOff} scroll={{ x: 800 }} bordered />
        </>
      )}
    </>
  );
};
