import React from 'react';
import * as S from './DescriptionDetails.syles';
import { Row } from 'antd';

export interface DescriptionDetailsProps {
  title?: string;
  children?: React.ReactNode;
}
export const DescriptionDetails: React.FC<DescriptionDetailsProps> = ({ title, children }) => {
  return (
    <>
      <S.Container>
        <S.DescriptionWrap>
          <S.DescriptionWrapper>
            <S.Title>{title}</S.Title>
          </S.DescriptionWrapper>
        </S.DescriptionWrap>
        <Row style={{ marginBottom: '1.5rem' }}>{children}</Row>
      </S.Container>
    </>
  );
};
