import React, { useRef, useState } from 'react';
import Overlay from '../../../../common/Overlay';
import { useResponsive } from 'hooks/useResponsive';
import * as S from './MainSider.styles';
import { SiderLogo } from '../SiderLogo';
import SiderMenu from '../SiderMenu/SiderMenu';

interface MainSiderProps {
  forceCollapse?: boolean;
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<MainSiderProps> = ({ forceCollapse = false, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isDesktop, mobileOnly, tabletOnly } = useResponsive();
  const [menuCollapse, setMenuCollapse] = useState(localStorage.getItem('menuCollapse') == 'true');

  const toggleSider = () => {
    localStorage.setItem('menuCollapse', menuCollapse ? 'false' : 'true');
    setMenuCollapse(!menuCollapse);
  };

  return (
    <>
      <S.Sider
        ref={ref}
        trigger={null}
        collapsed={menuCollapse}
        collapsedWidth={forceCollapse ? 0 : tabletOnly ? 80 : isDesktop ? 90 : 0}
        collapsible={true}
        width={260}
        {...props}
      >
        <SiderLogo isSiderCollapsed={menuCollapse} toggleSider={toggleSider} />
        <S.SiderContent>
          <SiderMenu isCollapsed={menuCollapse} />
        </S.SiderContent>
      </S.Sider>

      {mobileOnly && <Overlay onClick={() => toggleSider()} show={!menuCollapse} />}
    </>
  );
};

export default MainSider;
