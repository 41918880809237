import * as React from 'react';
const SvgIconSignalInfo = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5694_17260)">
      <path d="M20 18H22V10H20V18ZM20 22H22V20H20V22ZM2 22H18V8H22V2L2 22Z" fill="current" />
    </g>
    <defs>
      <clipPath id="clip0_5694_17260">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconSignalInfo;
