import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Table } from '@app/components/common/Table/Table';
import { Col, Row } from 'antd';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { useParams } from 'react-router-dom';

interface IDeviceProps {
  devices: FactoryDeviceModel[];
}

export const CreateDeviceTable: React.FC<IDeviceProps> = ({ devices }) => {
  const { id } = useParams();

  const columns: ColumnsType<FactoryDeviceModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: false,
      // sorter: (a, b) => a.id - b.id,
      width: '5%',
    },
    {
      title: 'Equipamento',
      dataIndex: 'tipoEquipamento',
      showSorterTooltip: false,
      // sorter: (a, b) => a.serial.localeCompare(b.serial),
      width: '12%',
    },
    {
      title: 'SN',
      dataIndex: 'numeroSerie',
      showSorterTooltip: false,
      // sorter: (a, b) => a.serial.localeCompare(b.serial),
      width: '12%',
    },
    {
      title: 'Modelo',
      dataIndex: 'modelo',
      showSorterTooltip: false,
      width: '15%',
      // render: (modelos: ModelsModel) => modelos.nome,
    },
    {
      title: 'Perfil de config.',
      dataIndex: 'perfil',
      showSorterTooltip: false,
      render: (perfil, device) => (device.testeRapido ? 'Teste Rápido' : perfil),
    },
    {
      title: 'Versão de firmware',
      dataIndex: 'versaoFirmware',
      showSorterTooltip: false,
      // sorter: (a, b) => a.manufacturingBatch.localeCompare(b.manufacturingBatch),
      width: '15%',
    },
    {
      title: 'IMEI',
      dataIndex: 'imei',
      showSorterTooltip: false,
      // sorter: (a, b) => a.IMEI.localeCompare(b.IMEI),
      width: '18%',
    },
    // {
    //   title: 'Anatel',
    //   dataIndex: 'anatel',
    //   showSorterTooltip: false,
    //   width: '15%',
    //   // render: (modelos: ModelsModel) => modelos.anatel,
    // },
  ];

  return (
    <>
      {devices.length > 0 ? (
        <>
          <Table columns={columns} dataSource={devices} scroll={{ x: 800 }} bordered />
        </>
      ) : (
        <Row justify="center" align="middle" style={{ marginTop: '20rem' }}>
          <Col xs={24} md={24} style={{ color: '#707070', fontSize: '1rem', fontWeight: 700, textAlign: 'center' }}>
            {id ? 'Nenhuma alteração realizada' : 'Nenhum cadastro realizado'}
          </Col>
          <Col xs={24} md={24} style={{ color: '#707070', fontSize: '0.875rem', fontWeight: 400, textAlign: 'center' }}>
            {id
              ? 'Realize a alteração para exibir os resultados'
              : 'Cadastre o(s) dispositivo(s) para exibir os resultados'}
          </Col>
        </Row>
      )}
    </>
  );
};
