import styled from 'styled-components';

export const FenceMapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 3rem;
  width: 100%;
`;

export const TitleInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
`;

export const Title = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;

  color: #8c8c8c;
  font-family: Mulish;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const FenceGroupName = styled.span`
  color: #383838;
  font-family: Mulish;
  font-weight: 600;
`;
