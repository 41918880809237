import styled from 'styled-components';
import { Collapse as AntdCollapse } from 'antd';
import { media } from '@app/styles/themes/constants';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 0rem;
  padding-bottom: 1rem;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
`;
export const CollapseContainer = styled.div`
  margin-left: -16px;

  && .ant-collapse-item {
    border: none;
  }

  box-sizing: border-box;
  width: 100%;
  border: 0.063rem solid var(--disabled-bg-color);
  border-radius: 0.5rem;
  flex: none;
  order: 0;
  flex-grow: 1;
  padding: 1.5rem;
  margin-bottom: 2rem;
`;

export const Collapse = styled(AntdCollapse)`
  width: 100%;
`;
export const CollapseHeaderName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.125rem;
  color: var(--Neutral-Gray-Gray700, #545454);
  font-family: 'Mulish';
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
`;
export const DisplayContainer = styled.div`
  display: grid;
  grid-template-columns: 14.375rem 1fr;
  gap: 2rem;
  align-items: center;
  margin-top: 1.5rem;

  @media only screen and (${media.md}) {
    grid-template-columns: 12rem 1fr;
  }
`;
export const TableTypeName = styled.div`
  color: var(--Neutral-Gray-Gray500, #8c8c8c);
  font-family: 'Mulish';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.875rem;
`;
export const TableQuantity = styled.div`
  color: #545454;
  font-family: 'Mulish';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal; /* 137.5% */
`;
export const TableName = styled.div`
  color: #545454;
  font-family: 'Mulish';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const ImageContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 0.063rem solid var(--disabled-bg-color);
  border-radius: 0.5rem;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeviceInfoContainer = styled.div`
  margin-bottom: 2.5rem;
`;

export const DeviceCardInfoProperty = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1.563rem;
  color: var(--input-placeholder-color);
`;

export const DeviceCardInfoValue = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.563rem;
  color: var(--text-label);
  margin-top: -0.1;
  overflow-wrap: break-word;
`;

export const DeviceTypeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;
