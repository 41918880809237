/* eslint-disable react-hooks/exhaustive-deps */
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useCallback, useEffect, useState } from 'react';
import { Description } from '@app/components/common/Description/Description';
import {
  CardInfoContainer,
  CardInfoProperty,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Col, Row, Tag } from 'antd';
import { DividerLine } from '@app/components/common/divider/DividerLine.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setFooter } from '@app/store/slices/footerSlice';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { notificationController } from '@app/controllers/notificationController';
import { ReactComponent as IconConcluded } from '@app/assets/icons/check_circle.svg';
import { ReactComponent as IconUpdating } from '@app/assets/icons/autorenew.svg';
import { ReactComponent as IconReport } from '@app/assets/icons/report_off.svg';
import moment from 'moment';
import * as S from '../device-config-update/components/Modules/modules.styles';
import { Panel } from '@app/components/common/Collapse/Collapse';
import { DescriptionDetails } from '../device-config-update/components/description/DescriptionDetails';
import IDeviceBlockService, { DeviceBlockService } from '@app/services/deviceService';
import {
  DeviceBlockModel,
  DeviceBlockModelDevices,
  DeviceBlockTypeAction,
  DeviceBlockTypeStatus,
} from '@app/domain/deviceBlock/device-block-model';
import { ReactComponent as ExportCsvIcon } from '@app/assets/icons/export-csv.svg';
import { Button } from '@app/components/common/buttons/Button/Button';
import { ExportButtonContainer } from '@app/components/header/components/HeaderTable/HeaderTable.styles';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { StopOutlined } from '@ant-design/icons/lib/icons';

const blockService: IDeviceBlockService = new DeviceBlockService();

export const DeviceBlockView: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [block, setBlock] = useState<DeviceBlockModel>({} as DeviceBlockModel);

  const fetchDeviceBlock = useCallback(async () => {
    try {
      setLoading(true);
      const response = await blockService.get(`${id}`);
      setBlock(response);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um erro ao tentar buscar os dados.',
      });
    }
  }, []);

  useEffect(() => {
    dispatch(setFooter({}));
    dispatch(
      setHeaderRegister({
        title: 'Visualizar dispositivos bloqueados',
        handleBackClick: () => navigate('/dispositivos-bloqueio'),
      }),
    );
    fetchDeviceBlock();
  }, [dispatch, navigate]);

  const renderStatusNameColors = (status: DeviceBlockTypeStatus) => {
    switch (status) {
      case DeviceBlockTypeStatus.AguardandoAprovacao:
      case DeviceBlockTypeStatus.Pendente:
        return { background: '#FEF7E7', text: '#644508' };
      case DeviceBlockTypeStatus.Parcial:
      case DeviceBlockTypeStatus.Reprovado:
        return { background: '#FEE9EA', text: '#620E12' };
      case DeviceBlockTypeStatus.Concluido:
        return { background: '#E9F4EE', text: '#083F18' };
      case DeviceBlockTypeStatus.Expirado:
        return { background: '#F4EAFF', text: '#52025F' };
      default:
        return { background: '', text: '' };
    }
  };

  const groupedData: { [key: string]: DeviceBlockModelDevices[] } =
    block.dispositivos?.reduce(
      (acc, item) => {
        const key = item.modelo || 'unknown';
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      },
      {} as { [key: string]: DeviceBlockModelDevices[] },
    ) || {};

  const handleExportToExcel = () => {
    const columns = [
      { title: 'Cliente', dataIndex: 'cliente' },
      { title: 'Usuário responsável', dataIndex: 'usuarioResponsavel' },
      { title: 'Data/Hora ação', dataIndex: 'dataHoraAcao' },
      { title: 'Data/Hora aprovação', dataIndex: 'dataHoraAprovacao' },
      { title: 'Ação', dataIndex: 'acao' },
      { title: 'Status', dataIndex: 'statusNome' },
      { title: 'Aprovação', dataIndex: 'aprovacao' },
      { title: 'Modelo', dataIndex: 'modeloDispositivo' },
      { title: 'SN', dataIndex: 'numeroSerie' },
    ];

    const data =
      block.dispositivos?.map((dispositivo) => ({
        cliente: block.cliente,
        modelos: block.modelos,
        usuarioResponsavel: block.usuarioResponsavel,
        dataHoraAcao: moment(block.dataHoraAcao).format('DD/MM/YYYY HH:mm') + 'h',
        dataHoraAprovacao: moment(block.dataHoraAprovacao).format('DD/MM/YYYY HH:mm') + 'h',
        acao: DeviceBlockTypeAction[block.acao],
        statusNome: block.statusNome,
        modeloDispositivo: dispositivo.modelo,
        numeroSerie: dispositivo.numeroSerie,
        aprovacao: dispositivo.finalizado,
      })) || [];

    genericExportToExcel('dispositivos_bloqueados', columns, data);
  };

  const renderStatusName = (status: string): DeviceBlockTypeStatus => {
    switch (status) {
      case 'AguardandoAprovacao':
        return DeviceBlockTypeStatus.AguardandoAprovacao;
      case 'Pendente':
        return DeviceBlockTypeStatus.Pendente;
      case 'Parcial':
        return DeviceBlockTypeStatus.Parcial;
      case 'Reprovado':
        return DeviceBlockTypeStatus.Reprovado;
      case 'Concluido':
        return DeviceBlockTypeStatus.Concluido;
      case 'Expirado':
        return DeviceBlockTypeStatus.Expirado;
      default:
        return DeviceBlockTypeStatus.AguardandoAprovacao;
    }
  };

  const legend = (
    <DescriptionDetails title="Legenda">
      <Row gutter={18}>
        <Col>
          <Row>
            <Col>
              <IconConcluded style={{ marginRight: '0.5rem' }} />
            </Col>
            <Col>
              <S.TableQuantity style={{ marginTop: '0.180rem' }}>Desbloqueado</S.TableQuantity>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <StopOutlined width={35} style={{ marginRight: '0.5rem', color: '#A71916' }} />
            </Col>
            <Col>
              <S.TableQuantity style={{ marginTop: '0.180rem' }}>Bloqueado</S.TableQuantity>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <IconUpdating style={{ marginRight: '0.5rem' }} />
            </Col>
            <Col>
              <S.TableQuantity style={{ marginTop: '0.180rem' }}>Pendente</S.TableQuantity>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <IconReport style={{ marginRight: '0.5rem' }} />
            </Col>
            <Col>
              <S.TableQuantity style={{ marginTop: '0.180rem' }}>Expirado</S.TableQuantity>
            </Col>
          </Row>
        </Col>
      </Row>
    </DescriptionDetails>
  );

  return (
    <>
      <Spinner spinning={loading}>
        <PageContainer>
          <Row justify={'end'}>
            <Col>
              <Button type="link" onClick={handleExportToExcel}>
                <ExportButtonContainer>
                  <ExportCsvIcon />
                  Exportar dados excel
                </ExportButtonContainer>
              </Button>
            </Col>
          </Row>
          <CardInfoContainer style={{ marginBottom: '3rem' }}>
            <Description title="Informações do bloqueio" />
            <Row gutter={[24, 24]}>
              <Col xs={12} md={10} lg={8} xxl={6}>
                <CardInfoProperty>Cliente</CardInfoProperty>
                <CardInfoValue>{block.cliente}</CardInfoValue>
              </Col>
              <Col xs={12} md={11} lg={7} xxl={5}>
                <CardInfoProperty>Modelos</CardInfoProperty>
                <CardInfoValue>{block.modelos}</CardInfoValue>
              </Col>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Usuário responsável</CardInfoProperty>
                <CardInfoValue>{block.usuarioResponsavel}</CardInfoValue>
              </Col>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Data/Hora ação</CardInfoProperty>
                <CardInfoValue>{moment(block.dataHoraAcao).format('DD/MM/YYYY HH:mm') + 'h'}</CardInfoValue>
              </Col>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Data/Hora aprovação</CardInfoProperty>
                <CardInfoValue>{moment(block.dataHoraAprovacao).format('DD/MM/YYYY HH:mm') + 'h'}</CardInfoValue>
              </Col>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Ação</CardInfoProperty>
                <CardInfoValue>{DeviceBlockTypeAction[block.acao]}</CardInfoValue>
              </Col>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Status</CardInfoProperty>
                <Tag
                  color={renderStatusNameColors(renderStatusName(block.statusNome)).background}
                  style={{
                    color: renderStatusNameColors(renderStatusName(block.statusNome)).text,
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  {block.statusNome}
                </Tag>
              </Col>
            </Row>
            <DividerLine />
            <Description title="Dispositivos" />
            {Object.keys(groupedData).map((modelo) => {
              const completedDevices = groupedData[modelo].reduce((acc, item) => acc + (item.finalizado ? 1 : 0), 0);
              const totalDevices = groupedData[modelo].length;
              return (
                <div key={modelo} style={{ marginBottom: '1rem' }}>
                  <S.Collapse>
                    <Panel
                      key={modelo}
                      header={
                        <Row align={'middle'} justify={'space-between'}>
                          <Col>
                            <S.CollapseHeaderName>{modelo}</S.CollapseHeaderName>
                          </Col>
                        </Row>
                      }
                    >
                      <Row gutter={[18, 18]}>
                        <Col xs={24} md={24} lg={24} xxl={24}>
                          {legend}
                        </Col>
                        <Col xs={24} md={24} lg={24} xxl={24}>
                          <strong>
                            {completedDevices}/{totalDevices}&nbsp;
                          </strong>
                          dispositivos
                        </Col>
                        <Col xs={24} md={24} lg={24} xxl={24}>
                          <div style={{ paddingBottom: '1rem' }}>
                            <Row gutter={[18, 18]} style={{ overflowY: 'auto', maxHeight: '30rem', width: '100%' }}>
                              {groupedData[modelo].map((item, index) => (
                                <Col key={index} xs={12} xl={6}>
                                  <CardInfoValue style={{ display: 'flex' }}>
                                    <S.TableQuantity>{item.numeroSerie}</S.TableQuantity>
                                    {block.statusNome === 'Expirado' ? (
                                      <IconReport style={{ marginLeft: '1rem' }} />
                                    ) : item.finalizado ? (
                                      DeviceBlockTypeAction.Bloqueio ? (
                                        <StopOutlined style={{ marginLeft: '1rem', color: '#A71916' }} />
                                      ) : (
                                        <IconConcluded style={{ marginLeft: '1rem' }} />
                                      )
                                    ) : (
                                      <IconUpdating style={{ marginLeft: '1rem' }} />
                                    )}
                                  </CardInfoValue>
                                </Col>
                              ))}
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Panel>
                  </S.Collapse>
                </div>
              );
            })}
          </CardInfoContainer>
        </PageContainer>
      </Spinner>
    </>
  );
};
