import { useState } from 'react';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import { LabelModel } from '@app/domain/label/labelModel';
import { QrCodeModel } from '@app/domain/label/qrCodeModel';
import { renderPDF, renderUiniqueLabelPDF } from './PDF';
import QRCode from 'qrcode';
import { LabelModelPrint } from '@app/domain/label/labelModelPrint';
import JSZip from 'jszip';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export function generateSerials(label: LabelModel): string[] {
  const serials: string[] = [];

  if (!label.faixaSerial) {
    return serials;
  }

  const { sequencialInicio, quantidade, lote } = label;
  const serialYearMonth = label.primeiroSerial.slice(0, 6);

  for (let i = 0; i < quantidade; i++) {
    const serialSeq = (sequencialInicio + i).toString().padStart(7, '0');
    const serial = `${serialYearMonth}-${lote}-${serialSeq}`;
    serials.push(serial);
  }

  return serials;
}

export async function generateLabelsForPrinting(label: LabelModel): Promise<LabelModelPrint[]> {
  // Create an array of serial numbers based on quantity
  const serialNumbers = generateSerials(label);

  const objectToQRCode: QrCodeModel = {
    id: label.id,
    numeroSerie: '',
    modelo: label.modelo ?? '',
    lote: label.lote ?? '',
    anatel: label.certificadoAnatel ?? '',
    tipoEquipamento: label.equipamento ?? '',
  };

  // Create labels with QR Code and add the serial number
  return await Promise.all(
    serialNumbers.map(async (numeroSerie) => {
      const _objectToQRCode = { ...objectToQRCode, numeroSerie } as QrCodeModel;
      const qrCodeURL = await QRCode.toDataURL(JSON.stringify(_objectToQRCode), { width: 18 });
      // const qrCodeURL = await generateQR(JSON.stringify(_objectToQRCode)); // with web worker
      return {
        ...label,
        numeroSerie,
        qrCodeURL,
      } as LabelModelPrint;
    }),
  );
}

export function useDownloadLabels() {
  const [downloading, setDownloading] = useState(false);

  const handleDownloadLabels = async (label: LabelModel) => {
    if (!label.quantidade || label.quantidade <= 0) return;

    setDownloading(true);
    try {
      const labelsToPrint = await generateLabelsForPrinting(label);

      const blobURL = await renderPDF(labelsToPrint, label.modelo);
      const pdfFileName = `etiquetas_${label.id}_${label.modelo}_${label.primeiroSerial}_${label.ultimoSerial.slice(
        -7,
      )}.pdf`;

      saveAs(blobURL, pdfFileName);
    } catch (error) {
      throw error;
    } finally {
      setDownloading(false);
    }
  };

  const handleDownloadLabelsZip = async (label: LabelModel) => {
    setDownloading(true);
    try {
      const labelsToPrint = await generateLabelsForPrinting(label);

      const zip = new JSZip();
      labelsToPrint.forEach((labels) => {
        const pdfName = `SN_${labels.numeroSerie}.pdf`;
        const blobURL = renderUiniqueLabelPDF(labels);
        zip.file(pdfName, blobURL);
      });

      const content = await zip.generateAsync({ type: 'blob' });
      const fileIdentifier =
        label.id + '_' + label.modelo + '_' + label.primeiroSerial + '_' + label.ultimoSerial.slice(-7);

      const zipFileName = `etiquetas_${fileIdentifier}_.zip`;

      saveAs(content, zipFileName);
    } catch (error) {
      throw error;
    } finally {
      setDownloading(false);
    }
  };

  const handleDownloadSerialNumbersPdf = async (label: LabelModel) => {
    const labelsToPrint = await generateSerials(label);

    const image = new Image();
    const doc = new jsPDF({ orientation: 'landscape', unit: 'mm', format: 'a4' });

    image.src = `${window.location.origin}/jmak-logo.png`;

    image.onload = async () => {
      const imgWidth = 20;
      const imgHeight = (image.height * imgWidth) / image.width;
      const pageWidth = doc.internal.pageSize.getWidth();

      doc.addImage(image, 'PNG', 14, 5, imgWidth, imgHeight);

      doc.setFontSize(8);
      doc.setTextColor(56, 56, 56);

      const text = 'NÚMEROS SERIAL - ETIQUETAS';
      const textWidth = doc.getTextWidth(text);
      const textX = (pageWidth - textWidth) / 2 - 4;
      doc.text(text, textX, 10, {});

      doc.setDrawColor(186, 186, 186);
      doc.setLineWidth(0.1);
      doc.roundedRect(14, 18, doc.internal.pageSize.getWidth() - 28, 27, 3, 3, 'S');

      doc.setFontSize(8);
      doc.setTextColor(140, 140, 140);
      doc.text(`Equipamento:`, 20, 25, {});
      doc.text(`Modelo:`, 90, 25, {});
      doc.text(`Quantidade:`, 150, 25, {});
      doc.text(`Sequência início:`, 200, 25, {});
      doc.text(`Sequência fim:`, 20, 35, {});
      doc.text(`Lote:`, 90, 35, {});

      doc.setTextColor(84, 84, 84);
      doc.text(`${label.equipamento ?? '-'}`, 20, 30, { maxWidth: 60 });
      doc.text(`${label.modelo ?? '-'}`, 90, 30, { maxWidth: 60 });
      doc.text(`${label.quantidade ?? '-'}`, 150, 30, { maxWidth: 60 });
      doc.text(`${label.sequencialInicio ?? '-'}`, 200, 30, { maxWidth: 60 });
      doc.text(`${label.sequencialFim ?? '-'}`, 20, 40, { maxWidth: 60 });
      doc.text(`${label.lote ?? '-'}`, 90, 40, { maxWidth: 60 });

      const rows = labelsToPrint.map((serialNumber) => [serialNumber]);

      // Criar tabela com os dados
      autoTable(doc, {
        startY: 50,
        theme: 'plain',
        head: [['Número Serial']],
        body: rows,
        styles: {
          fontSize: 8,
        },
        columnStyles: {
          0: { cellWidth: 'auto' },
        },
        headStyles: {
          fillColor: [245, 245, 245],
          textColor: [0, 0, 0],
          valign: 'middle',
        },
        tableLineColor: [232, 232, 232],
        tableLineWidth: 0.05,
        tableWidth: 'auto',
      });

      doc.save(`etiquetas_${label.lote}.pdf`);
    };
  };
  const handleDownloadSerialNumbersExcel = async (label: LabelModel) => {
    const workbook = new ExcelJS.Workbook();
    const labelsToPrint = await generateSerials(label);
    const worksheet = workbook.addWorksheet('data');

    worksheet.addRow(['Equipamento', 'Modelo', 'Quantidade', 'Sequência início', 'Sequência fim', 'Lote']);
    worksheet.addRow([
      label.equipamento,
      label.modelo,
      label.quantidade,
      label.sequencialInicio,
      label.sequencialFim,
      label.lote,
    ]);

    worksheet.addRow(['Número Serial']);

    labelsToPrint.map((numeroSerial) => {
      worksheet.addRow([numeroSerial]);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    saveAs(blob, `etiquetas_${label.lote}.xlsx`);
  };

  return {
    handleDownloadLabels,
    handleDownloadLabelsZip,
    handleDownloadSerialNumbersPdf,
    handleDownloadSerialNumbersExcel,
    downloading,
  };
}
