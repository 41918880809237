import React, { useState } from 'react';
import { Tooltip } from 'antd';
import ContentCopyIcon from '@app/assets/icons/content_copy.svg';

interface CopyToClipboardProps {
  textToCopy: string;
  tooltip?: string;
  style?: React.CSSProperties;
  onCopy?: () => void;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  textToCopy,
  tooltip = 'Copiar para a área de transferência',
  style,
  onCopy,
}) => {
  const [tooltipTitle, setTooltipTitle] = useState(tooltip);

  const copyTextToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      onCopy?.();
      setTooltipTitle('Copiado!');
      setTimeout(() => setTooltipTitle(tooltip), 3000);
    } catch (err) {
      setTooltipTitle('Erro ao copiar');
    }
  };

  return (
    <Tooltip title={tooltipTitle}>
      <img src={ContentCopyIcon} alt="Copiar" onClick={copyTextToClipboard} style={{ cursor: 'pointer', ...style }} />
    </Tooltip>
  );
};

export default CopyToClipboard;
