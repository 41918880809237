import React, { useEffect, useState } from 'react';

import { IDashboard } from '@app/constants/interfaces/Dashboard/IDashboard';
import { HeaderTable } from '../header/components/HeaderTable/HeaderTable';
import * as S from './Dashboard.styles';
import { Container } from './Dashboard.styles';
import { readUser } from '@app/services/localStorage.service';
import { hasBetaAccess } from '@app/controllers/accessController';

const Dashboard: React.FC<IDashboard> = ({
  buttonText,
  title,
  filter,
  tags,
  icon,
  table,
  handleButtonClick,
  handleSearchOnChange,
  placeholderTextSearch,
  valueSearch,
  exportToExcel,
}) => {
  const [isBeta, setIsBeta] = useState<boolean>(false);

  useEffect(() => {
    const user = readUser();
    const beta = hasBetaAccess(user?.claims ?? []);

    setIsBeta(beta);
  }, []);

  return (
    <Container>
      <S.Header>
        {isBeta && <h3>BETA</h3>}
        <HeaderTable
          buttonText={buttonText}
          title={title}
          filter={filter}
          tags={tags}
          icon={icon}
          valueSearch={valueSearch}
          placeholderTextSearch={placeholderTextSearch}
          handleButtonClick={handleButtonClick}
          handleSearchOnChange={handleSearchOnChange}
          exportToExcel={exportToExcel}
        />
      </S.Header>
      {table}
    </Container>
  );
};

export default Dashboard;
