import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';

export interface HeaderRegisterSlice {
  title: string;
  handleBackClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const initialState: HeaderRegisterSlice = {
  title: '',
  handleBackClick: (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  },
};

export const setHeaderRegister = createAction<PrepareAction<HeaderRegisterSlice>>(
  'headerRegister/setHeaderRegister',
  (newHeaderRegister: HeaderRegisterSlice) => {
    return {
      payload: newHeaderRegister,
    };
  },
);

export const headerRegisterSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setHeaderRegister, (state, action) => {
      state.title = action.payload.title;
      state.handleBackClick = action.payload.handleBackClick;
    });
  },
});

export default headerRegisterSlice.reducer;
