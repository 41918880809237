import { graphic } from 'echarts';
import { BASE_COLORS } from '../constants';
import { ITheme } from '../types';

const chartColors = {
  chartTooltipLabel: '#6A7985',
  chartColor1: '#01509A',
  chartColor1Tint: '#2983D8',
  chartColor2: '#35A0DC',
  chartColor2Tint: '#67C5FA',
  chartColor3: '#FFB155',
  chartColor3Tint: '#FFA800',
  chartColor4: '#31A652',
  chartColor4Tint: '#89DCA0',
  chartColor5: '#FF5252',
  chartColor5Tint: '#FFC1C1',
  chartPrimaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(0, 110, 211, 0.5)',
    },
    {
      offset: 1,
      color: 'rgba(255, 225, 255, 0)',
    },
  ]),
  chartSecondaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(255, 82, 82, 0.5)',
    },
    {
      offset: 1,
      color: 'rgba(255, 255, 255, 0)',
    },
  ]),
  chartSecondaryGradientSpecular: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(255, 255, 255, 0)',
    },
    {
      offset: 1,
      color: 'rgba(255, 82, 82, 0.5)',
    },
  ]),
};

export const lightColorsTheme: ITheme = {
  primary: '#1C213C',
  secondary: '#263668',
  backgroundMain: '#FFF',
  backgroundCard: BASE_COLORS.white,
  backgroundColorBase: '#F6F6F6',
  background: BASE_COLORS.white,
  headerMain: 'transparent',
  textMain: '#707070',
  textDark: '#0F162A',
  alertTextColor: BASE_COLORS.white,
  textSiderPrimary: '#A71916',
  textSiderSecondary: BASE_COLORS.white,
  textPagination: '#374450',
  layoutSiderBg: '#263668',
  borderCollapse: '#475582',
  collapseBackground: '#475582',
  textSecondary: BASE_COLORS.white,
  textLabel: '#545454',
  textHeader: '#F5F5F5',
  borderPagination: '#B3C5D4',
  disablePlaceholder: '#707070',
  inputPlaceholder: '#8C8C8C',
  disabledInput: '#E8E8E8',
  borderDrawer: '#E8E8E8',
  backgroundDisabledInput: '#BABABA',
  borderInput: '#A3A3A3',
  inputError: '#A71916',
  inputSuccess: '#117E2F',
  inputWarning: '#FBBD43',
  buttonHover: '#E6ECF1',
  newAlertHover: '#FDDEA1',
  confirmedHover: '#8ED5A2',
  reportedHover: '#FBA7AB',
  newAlert: '#FBBD43',
  confirmed: '#3DB05D',
  reported: '#F74E57',
  notificationError: '#861412',
  notificationSuccess: '#DFFBE7',
  notificationWarning: '#FEF7E7',

  textTagError: '#620E12',
  textTagSuccess: '#083F18',
  textTagWarning: '#644508',
  bgTagError: '#FEE9EA',
  bgTagSuccess: '#E9F4EE',
  bgTagWarning: '#FEF7E7',

  buttonTabColor: '#CAD2EC',
  footerColor: '#4F6372',
  icon: '#0F162A',
  iconHover: 'rgba(0, 0, 0, 0.85)',
  boxShadowHover: '0 4px 16px 0 rgba(0, 0, 0, 0.2)',
  boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.07)',
  shadow: 'rgba(0, 0, 0, 0.07)',
  scroll: '#263668',
  spinnerBase: '#1E2B53',
  additionalBackground: '#ffffff',
  timelineBackground: '#',
  heading: '#13264d',
  borderBase: '#A3A3A3',
  tooltipBackground: '#383838',
  ...chartColors,
  avatarUser: '#DDDDDD',
};
