import { FC } from 'react';
import * as S from './tooltip.styles';
import { Col, Row } from 'antd';

interface TooltipProps {
  children: React.JSX.Element;
  icon: React.ReactElement;
  title: string;
  subtitle: string;
}

export const Tooltip: FC<TooltipProps> = ({ icon, title, subtitle, children }) => {
  return (
    <S.PerformanceContainerTooltip
      color="white"
      showArrow={false}
      autoAdjustOverflow
      overlayStyle={{ maxWidth: '11rem' }}
      overlayInnerStyle={{ color: '#545454', border: '1px solid #bababa' }}
      overlay={
        <S.PerformanceContainerTooltipContent>
          <Col span={24}>
            <Row gutter={16}>
              <S.PerformanceContainerTooltipTitle span={5}>{icon}</S.PerformanceContainerTooltipTitle>
              <S.PerformanceContainerTooltipTitle span={19}>
                {title}
                <span> {subtitle}</span>
              </S.PerformanceContainerTooltipTitle>
            </Row>
          </Col>
        </S.PerformanceContainerTooltipContent>
      }
    >
      <div>{children}</div>
    </S.PerformanceContainerTooltip>
  );
};
