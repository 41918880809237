import { FC, useState } from 'react';
import logo from '@app/assets/images/j-logo.png';
import { Form, Row } from 'antd';
import * as S from './login.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { InputPassword } from '@app/components/common/inputs/InputPassword/InputPassword';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doLogin } from '@app/store/slices/authSlice';
import { readUser } from '@app/services/localStorage.service';
import { getFirstUrlWithAccess } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import { Button } from '@app/components/common/buttons/Button/Button';
import { AuthComponentEnum } from '@app/constants/enums/auth-components';
import { limparCookie } from '@app/utils/utils';

interface LoginFormData {
  username: string;
  password: string;
}

interface LoginProps {
  handleShowComponent: (index: AuthComponentEnum) => void;
}

export const Login: FC<LoginProps> = ({ handleShowComponent }) => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const handleOnPaste = (e: React.ClipboardEvent) => {
    form.setFieldValue('password', e.clipboardData.getData('text').trim());
    // e.preventDefault();
  };

  const handleSubmit = (values: LoginFormData) => {
    const { username, password } = values;
    setLoading(true);
    dispatch(doLogin({ username: username, password: password }))
      .unwrap()
      .then(() => {
        limparCookie();
        const user = readUser();

        if (!user?.emailConfirmed) {
          handleShowComponent(AuthComponentEnum.FirstAccess);
        } else {
          window.location.href = getFirstUrlWithAccess() ?? '/';
        }
      })
      .catch(() => {
        notificationController.error({
          message: 'Usuário ou senha incorreto.',
        });
        setLoading(false);
      });
  };

  return (
    <S.LoginWrapper>
      <Row justify={'center'} style={{ marginBottom: '2.875rem' }}>
        <img src={logo} width={31} height={72} />
      </Row>
      <Row>
        <BaseForm style={{ width: '100%' }} form={form} layout="vertical" onFinish={handleSubmit}>
          <BaseForm.Item name="username" rules={[{ required: true, message: 'Campo obrigatório' }]} label="Usuário">
            <Input placeholder="Digite seu usuário" />
          </BaseForm.Item>
          <BaseForm.Item name="password" rules={[{ required: true, message: 'Campo obrigatório' }]} label="Senha">
            <div>
              <InputPassword type="password" placeholder="Digite sua senha" onPaste={handleOnPaste} />
              <S.LoginSpan>
                Esqueceu sua senha?{' '}
                <label onClick={() => handleShowComponent(AuthComponentEnum.ForgotPassword)}>Clique aqui</label>
              </S.LoginSpan>
            </div>
          </BaseForm.Item>
          <div style={{ marginTop: '3.125rem' }}>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Entrar
            </Button>
          </div>
        </BaseForm>
      </Row>
    </S.LoginWrapper>
  );
};
