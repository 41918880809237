import { UserModel } from '@app/domain/identity/userModel';
import { ApiIdentityServiceBase, IApiIdentityServiceBase } from '../base/apiIdentityServiceBase';

export default interface IUserIdentityService extends IApiIdentityServiceBase<UserModel, UserModel> {}

export class UserIdentityService extends ApiIdentityServiceBase<UserModel, UserModel> {
  constructor() {
    super('users');
  }
}
