import styled from 'styled-components';
import { Panel } from '@app/components/common/Collapse/Collapse';
import { media } from '@app/styles/themes/constants';

export const CustomPanel = styled(Panel)`
  .ant-collapse-header-text {
    margin-left: 0.5rem;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FileNameText = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 8rem;
`;

export const FileUploadCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  gap: 1rem;
`;

export const FileUploadCard = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid var(--bordergray);
  border-radius: 6px;
  height: 3.5rem;
  width: 14rem;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 1rem;
`;

export const FileUploadLabelButton = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 1px solid var(--bordergray);
  border-radius: 6px;
  width: 14.75rem;
  height: 3.5rem;
  cursor: pointer;
`;

export const Title = styled.span`
  font-family: 'Mulish';
  font-size: 1.5rem !important;
  font-weight: 600;
  line-height: 30px;
  color: var(--tooltip-background);
  margin-top: 1.5rem;

  @media only screen and (${media.md}) {
    font-family: 'Mulish';
    font-size: 1.125rem;
    flex-wrap: wrap;
    word-wrap: break-word;
  }
  @media only screen and (${media.xs}) {
    font-size: 1.325rem;
  }
  @media only screen and (${media.xxl}) {
    font-size: 1.5rem;
  }
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const FilesExportAll = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  color: var(--secondary-color);
  cursor: pointer;
`;
