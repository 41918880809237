import styled from 'styled-components';
import { Panel } from '@app/components/common/Collapse/Collapse';

export const CustomPanel = styled(Panel)`
  .ant-collapse-header-text {
    margin-left: 0.5rem;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FileNameText = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 8rem;
`;

export const FileUploadCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  gap: 1rem;
`;

export const FileUploadCard = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid var(--bordergray);
  border-radius: 6px;
  height: 3.5rem;
  width: 14rem;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 1rem;
`;

export const FileUploadLabelButton = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 1px solid var(--bordergray);
  border-radius: 6px;
  width: 14.75rem;
  height: 3.5rem;
  cursor: pointer;
`;

export const FileSizeContainer = styled.section`
  color: var(--bordergray);
  font-size: 0.9rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 1.25rem;
`;
