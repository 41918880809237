import * as S from './ListDevicesDashboards.styles';
import React from 'react';
import { GroupedDevicesByClient, IModalClientEquipment } from '@app/domain/scheduleFactory/scheduleParams';
import { Description } from '@app/components/common/Description/Description';
import { Collapse, Panel } from '@app/components/common/Collapse/Collapse';

interface IListDevicesDashboards {
  factoryDevicesByClient: GroupedDevicesByClient;
  setModalClientEquipment: React.Dispatch<React.SetStateAction<IModalClientEquipment>>;
}

export const ListDevicesDashboards: React.FC<IListDevicesDashboards> = ({
  factoryDevicesByClient,
  setModalClientEquipment,
}) => {
  return (
    <S.ContainerListDevices>
      <Description
        title="Agendamentos cadastrados"
        subtitle="Após o cadastro dos agendamentos, clique em agendar atualização para finalizar"
      >
        {Object.entries(factoryDevicesByClient).map(([clientId, devices]) => (
          <Collapse
            key={clientId}
            defaultActiveKey={Object.keys(factoryDevicesByClient)}
            style={{ marginBottom: '1rem' }}
            collapsible={'header'}
          >
            <Panel
              header={devices.length > 0 ? devices[0].clienteNome : 'Nome não disponível'}
              key={clientId}
              extra={
                <S.EditLink
                  onClick={() =>
                    setModalClientEquipment({
                      visible: true,
                      title: 'Editar clientes e equipamentos',
                      selectedClientId: Number(clientId),
                    })
                  }
                >
                  Editar
                </S.EditLink>
              }
            >
              <S.PanelContainer>
                {devices.map((device) => (
                  <S.DeviceInfo key={device.dispositivoNumeroSerie}>{device.dispositivoNumeroSerie}</S.DeviceInfo>
                ))}
              </S.PanelContainer>
            </Panel>
          </Collapse>
        ))}
      </Description>
    </S.ContainerListDevices>
  );
};
