import React from 'react';
import { Col, Modal as AntdModal, ModalProps as AntModalProps, Row } from 'antd';
import { ReactComponent as SmallLogo } from '@app/assets/icons/jmak-icon.svg';
import * as S from './ModalTutorial.styles';
import { Button } from '../buttons/Button/Button';
import { modalSizes } from '@app/constants/modalSizes';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';

export const { info: InfoModal, success: SuccessModal, warning: WarningModal, error: ErrorModal } = AntdModal;

interface ModalTutorialProps extends AntModalProps {
  size?: 'medium' | 'large';
}

export const ModalTutorial: React.FC<ModalTutorialProps> = ({
  size = 'large',
  children,
  title,
  onOk,
  okButtonProps,
  okText,
  ...props
}) => {
  const modalSize = Object.entries(modalSizes).find((sz) => sz[0] === size)?.[1];

  return (
    <S.Modal
      getContainer={false}
      width={modalSize}
      title={
        <Row gutter={16} align="middle">
          <Col>
            <SmallLogo width="1.5rem" height="1.5rem" style={{ marginTop: '0.4rem' }} />
          </Col>
          <Col>{title}</Col>
        </Row>
      }
      footer={
        <Row gutter={16} align="middle" justify="end">
          <Col span={6}>
            <Button type="primary" block onClick={onOk} {...okButtonProps}>
              {okText ?? 'Sim'}
            </Button>
          </Col>
        </Row>
      }
      {...props}
    >
      <HelperNotification>
        Para o correto funcionamento, utilize sistema operacional Windows e os navegadores Chrome (a partir da versão
        89) ou Edge.
      </HelperNotification>
      {children}
    </S.Modal>
  );
};
