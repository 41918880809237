import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as CheckIcon } from '@app/assets/icons/check_circle.svg';
import { ReactComponent as QrCodeIcon } from '@app/assets/icons/qr-code-blue.svg';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import * as S from './QRCodeReader.styles';
import * as CS from '../components.styles';
import React from 'react';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { Modal } from '@app/components/common/Modal/Modal';
import { QrReader } from 'react-qr-reader';
import { notificationController } from '@app/controllers/notificationController';

type QRCodeReaderProps = {
  newFactoryDevice: FactoryDeviceModel;
  setNewFactoryDevice: React.Dispatch<React.SetStateAction<FactoryDeviceModel>>;
};

export const QRCodeReader = ({ newFactoryDevice, setNewFactoryDevice }: QRCodeReaderProps) => {
  const [qrCodeLink, setQrCodeLink] = useState('');
  const [openQrcodeModal, setOpenQrcodeModal] = useState(false);

  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(function () {})
      .catch(function (error) {
        notificationController.error({ message: 'Erro ao tentar acessar a câmera.' });
        console.error('Erro ao tentar acessar a câmera: ', error);
      });
  } else {
    notificationController.error({ message: 'Seu navegador não suporta a API de mídia.' });
  }

  useEffect(() => {
    if (!qrCodeLink) return;

    const qrCodeSplited = qrCodeLink.split('/');
    const numeroSerie = qrCodeSplited[qrCodeSplited.length - 1];
    const lote = numeroSerie?.split('-')[1];

    setNewFactoryDevice((prevState) => ({
      ...prevState,
      numeroSerie,
      lote,
    }));
  }, [qrCodeLink, setNewFactoryDevice]);

  useEffect(() => {
    if (!newFactoryDevice.numeroSerie) setQrCodeLink('');
  }, [newFactoryDevice.numeroSerie]);

  return (
    <>
      {openQrcodeModal && (
        <Modal title="Leitura QRCode" footer={null} open={openQrcodeModal} onCancel={() => setOpenQrcodeModal(false)}>
          <QrReader
            constraints={{
              facingMode: 'environment',
            }}
            onResult={(result, error) => {
              if (!!result) {
                setQrCodeLink(result?.getText());
                setOpenQrcodeModal(false);
              }

              if (!!error) {
                console.info(error);
              }
            }}
          />
        </Modal>
      )}
      {newFactoryDevice.imei && (
        <>
          <CS.LoadingContainer>
            {!!newFactoryDevice.numeroSerie ? (
              <>
                <CheckIcon />
                QR Code identificado com sucesso!
              </>
            ) : (
              <>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                Aguardando a leitura do QR Code.
              </>
            )}
          </CS.LoadingContainer>
          <S.ReadQrCode>
            {!newFactoryDevice.numeroSerie && (
              <S.Button type="default" icon={<QrCodeIcon />} onClick={() => setOpenQrcodeModal(true)}>
                QR Code
              </S.Button>
            )}
          </S.ReadQrCode>
          <S.InfoContainer>
            <S.TopInfo>
              <S.InfoItem>
                <S.InfoTitle>Equipamento</S.InfoTitle>
                <S.InfoValue>{newFactoryDevice.tipoEquipamento ?? '-'}</S.InfoValue>
              </S.InfoItem>
              <S.InfoItem>
                <S.InfoTitle>Modelo</S.InfoTitle>
                <S.InfoValue>{newFactoryDevice.modelo ?? '-'}</S.InfoValue>
              </S.InfoItem>
              <S.InfoItem>
                <S.InfoTitle>SN</S.InfoTitle>
                <S.InfoValue>{newFactoryDevice.numeroSerie ?? '-'}</S.InfoValue>
              </S.InfoItem>
            </S.TopInfo>
            <S.BottomInfo>
              <S.InfoItem>
                <S.InfoTitle>Lote</S.InfoTitle>
                <S.InfoValue>{newFactoryDevice.lote ?? '-'}</S.InfoValue>
              </S.InfoItem>
              <S.InfoItem>
                <S.InfoTitle>Anatel</S.InfoTitle>
                <S.InfoValue>{newFactoryDevice.anatel ?? '-'}</S.InfoValue>
              </S.InfoItem>
            </S.BottomInfo>
          </S.InfoContainer>
        </>
      )}
    </>
  );
};
