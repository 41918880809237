import { FC } from 'react';
import * as S from './forgot-password.styles';
import logo from '@app/assets/images/j-logo.png';
import { Divider, Row } from 'antd';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { AuthComponentEnum } from '@app/constants/enums/auth-components';

interface ForgotPasswordProps {
  handleShowComponent: (index: AuthComponentEnum) => void;
}

export const ForgotPasswordSuccess: FC<ForgotPasswordProps> = ({ handleShowComponent }) => {
  return (
    <S.ForgotPasswordWrapper>
      <Row justify={'center'} style={{ marginBottom: '2.875rem' }}>
        <img src={logo} width={31} height={72} />
      </Row>
      <Row>
        <BaseForm layout="vertical" requiredMark="optional">
          <BaseForm.Item>
            <S.ForgotPasswordFormTitle style={{ marginBottom: '1rem' }}>E-mail enviado!</S.ForgotPasswordFormTitle>
            <S.ForgotPasswordFormSubtitle>
              Se o e-mail estiver cadastrado no <S.BoldItem>Jmak</S.BoldItem>, você receberá uma mensagem em{' '}
              <S.BoldItem>contato@email.com</S.BoldItem> com as instruções para trocar por uma nova senha.
            </S.ForgotPasswordFormSubtitle>
            <Divider type="horizontal" style={{ backgroundColor: '#D9D9D9' }} />
            <S.ForgotPasswordFormSubtitle>
              Caso não tenha recebido as instruções, verifique se digitou o endereço de e-mail corretamente ou se caiu
              na caixa de spam
            </S.ForgotPasswordFormSubtitle>
          </BaseForm.Item>
          <BaseForm.Item style={{ marginTop: '3.125rem' }}>
            <Row justify={'end'}>
              <S.ForgotPasswordFormLabelBack onClick={() => handleShowComponent(AuthComponentEnum.Login)}>
                Voltar
              </S.ForgotPasswordFormLabelBack>
            </Row>
          </BaseForm.Item>
        </BaseForm>
      </Row>
    </S.ForgotPasswordWrapper>
  );
};
