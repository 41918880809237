import styled from 'styled-components';

export const ForgotPasswordWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const ForgotPasswordFormTitle = styled.div`
  font-family: Mulish;
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.138rem;
  text-align: left;
  color: var(--primary-color);
`;

export const ForgotPasswordFormSubtitle = styled.div`
  font-family: Mulish;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.569rem;
  text-align: left;
  color: var(--text-label);
`;

export const ForgotPasswordFormLabelBack = styled.div`
  margin-top: 0.5rem;
  font-family: Mulish;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.569rem;
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
`;

export const BoldItem = styled.span`
  color: var(--primary-color);
  font-weight: 800;
`;
