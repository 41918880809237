import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SendParameter {
  identificadorEmbarcado: number;
  nome: string;
  isObrigatorio: boolean;
}

interface SendParametersState {
  parameters: SendParameter[];
}

const initialState: SendParametersState = {
  parameters: [],
};

export const setParameters = createAction<SendParameter[]>('sendParameters/setParameters');
export const clearParameters = createAction('sendParameters/clearParameters');

export const sendParametersSlice = createSlice({
  name: 'sendParameters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setParameters, (state, action: PayloadAction<SendParameter[]>) => {
        state.parameters = action.payload;
      })
      .addCase(clearParameters, (state) => {
        state.parameters = [];
      });
  },
});

export default sendParametersSlice.reducer;
