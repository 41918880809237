import { Modal } from '@app/components/common/Modal/Modal';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { ConfigUpdateDeviceModel } from '@app/domain/deviceConfigUpdate/deviceConfigUpdate';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { DeviceGroupModel } from '@app/domain/deviceGroup/deviceGroupModel';

interface IModalAddGroupDevice {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIdsDevice: (devices: ConfigUpdateDeviceModel[]) => void;
  groupDevices: DeviceGroupModel[];
  selectedsDeviceGroup: number[];
  setSelectedsDeviceGroup: React.Dispatch<React.SetStateAction<number[]>>;
}

export const ModalAddGroupDevice: React.FC<IModalAddGroupDevice> = (props) => {
  const { visible, setVisible, setIdsDevice, groupDevices, selectedsDeviceGroup, setSelectedsDeviceGroup } = props;
  const [localGroups, setLocalGroups] = useState<number[]>([]);

  useEffect(() => {
    if (visible) {
      setLocalGroups(selectedsDeviceGroup);
    }
  }, [visible, selectedsDeviceGroup]);

  const handleSave = () => {
    const selectedGroupObjects = groupDevices.filter((g) => localGroups.includes(g.id ?? 0));
    const idsDevice: ConfigUpdateDeviceModel[] = [];

    selectedGroupObjects.forEach(
      (g) => g.dispositivos?.forEach((d) => idsDevice.push(d as unknown as ConfigUpdateDeviceModel)),
    );
    setSelectedsDeviceGroup(localGroups);
    setIdsDevice(idsDevice);
    setVisible(false);
    setLocalGroups([]);
  };

  const handleCancel = () => {
    setVisible(false);
    setLocalGroups([]);
  };

  return (
    <Modal
      key="modal-add-group-device"
      open={visible}
      title={!selectedsDeviceGroup ? 'Adicionar grupos' : 'Editar grupos'}
      onOk={handleSave}
      onCancel={handleCancel}
      cancelText="Cancelar"
      okText="Salvar"
    >
      <BaseForm layout="vertical" style={{ width: '100%' }}>
        <Row gutter={6}>
          <Col xs={24}>
            <BaseFormInputItem
              style={{ width: '100%' }}
              label="Grupos"
              errorText="Campo obrigatório"
              supportText="Pesquise e selecione um ou mais grupo de dispositivos"
            >
              <Select
                showArrow
                showSearch
                mode="multiple"
                allowClear
                onClear={() => setLocalGroups([])}
                maxTagCount={'responsive'}
                placeholder="Adicionar grupos"
                value={localGroups}
                onChange={(value) => setLocalGroups(value as number[])}
                options={groupDevices.map((d) => ({ value: d.id, label: d.nome }))}
                filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              />
            </BaseFormInputItem>
          </Col>
        </Row>
      </BaseForm>
    </Modal>
  );
};
