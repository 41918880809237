import { Modal } from '@app/components/common/Modal/Modal';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { notificationController } from '@app/controllers/notificationController';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { FastTestModel } from '@app/domain/device/fastTestModel';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import IFactoryDeviceService, { FactoryDeviceService } from '@app/services/factoryDeviceService';
import { readUser } from '@app/services/localStorage.service';
import { isValidIP, maskIdProps, onKeyDownOnlyNumber } from '@app/utils/utils';
import { Col, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';

interface IModalFastTest {
  visible: boolean;
  device: FactoryDeviceModel;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  saveDeviceFasTest: (properties: DeviceProfileProperty[]) => Promise<void>;
}

const deviceService: IFactoryDeviceService = new FactoryDeviceService();
const testProperties = [
  {
    idEmbarcado: DevicePropertyType.Apn,
    valorPropriedade: '',
    tipoComando: 's',
  },
  {
    idEmbarcado: DevicePropertyType.IpServidor,
    valorPropriedade: '',
    tipoComando: 's',
  },
  {
    idEmbarcado: DevicePropertyType.Dns,
    valorPropriedade: '',
    tipoComando: 's',
  },
  {
    idEmbarcado: DevicePropertyType.TipoProtocolo,
    valorPropriedade: '1',
    tipoComando: 's',
  },
  {
    idEmbarcado: DevicePropertyType.PortaServidor,
    valorPropriedade: '',
    tipoComando: 's',
  },
  {
    idEmbarcado: DevicePropertyType.IntervaloEnvioCarroLigado,
    valorPropriedade: '30',
    tipoComando: 's',
  },
  {
    idEmbarcado: DevicePropertyType.IntervaloEnvioBateriaExterna,
    valorPropriedade: '21600',
    tipoComando: 's',
  },
  {
    idEmbarcado: DevicePropertyType.IntervaloEnvioBateriaInterna,
    valorPropriedade: '86400',
    tipoComando: 's',
  },
];

export const ModalFastTest: React.FC<IModalFastTest> = ({ device, visible, setVisible, saveDeviceFasTest }) => {
  const [primaryServer, setPrimaryServer] = useState<'IP' | 'DNS'>('IP');
  const [properties, setProperties] = useState(testProperties);

  const getPropertyValue = (idEmbarcado: DevicePropertyType) =>
    properties.find((p) => p.idEmbarcado == idEmbarcado)?.valorPropriedade;

  const validateProperties = () => {
    const hasEmptyProperties =
      properties.filter(
        (p) =>
          !p.valorPropriedade &&
          p.valorPropriedade.length <= 0 &&
          (primaryServer === 'IP'
            ? p.idEmbarcado !== DevicePropertyType.Dns
            : p.idEmbarcado !== DevicePropertyType.IpServidor),
      )?.length > 0;

    if (hasEmptyProperties) return false;

    const hasInvalidNumberProperties =
      properties.filter(
        (p) =>
          (p.idEmbarcado === DevicePropertyType.IntervaloEnvioCarroLigado ||
            p.idEmbarcado === DevicePropertyType.IntervaloEnvioBateriaExterna ||
            p.idEmbarcado === DevicePropertyType.IntervaloEnvioBateriaInterna) &&
          (Number(p.valorPropriedade) < 10 || Number(p.valorPropriedade) > 172800),
      )?.length > 0;

    if (hasInvalidNumberProperties) return false;

    if (primaryServer === 'IP') {
      const hasIpInvalidProperty =
        properties.filter(
          (p) => p.idEmbarcado === DevicePropertyType.IpServidor && !isValidIP(p.valorPropriedade.replace('_', '')),
        )?.length > 0;
      if (hasIpInvalidProperty) return false;
    }

    return true;
  };

  const handleChangeProperties = (idEmbarcado: DevicePropertyType, value: string) => {
    setProperties(properties.map((p) => (p.idEmbarcado == idEmbarcado ? { ...p, valorPropriedade: value } : p)));
  };
  const handleChangePrimaryServer = (value: string) => {
    switch (value) {
      case 'IP':
        setPrimaryServer(value);
        handleChangeProperties(DevicePropertyType.Dns, '');
        break;
      case 'DNS':
        setPrimaryServer(value);
        handleChangeProperties(DevicePropertyType.IpServidor, '');
        break;
    }
  };

  const handleSave = async () => {
    try {
      const fastTest = {
        idDispositivo: device.id,
        idCliente: device.idCliente,
        usuarioResponsavel: readUser()?.userName,
        propriedades: properties,
      } as FastTestModel;

      await deviceService.postFastTest(fastTest);
      await saveDeviceFasTest(properties as DeviceProfileProperty[]);

      setVisible(false);
    } catch (error) {
      notificationController.error({ message: 'Erro ao salvar as informações no dispositivo.' });
    }
  };

  useEffect(() => {
    if (visible && device.idModelo) {
      setProperties(testProperties);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
      <Modal
        open={visible}
        title="Configuração simplificada"
        onOk={() => handleSave()}
        onCancel={() => setVisible(false)}
        cancelText="Cancelar"
        okText="Salvar"
        size="large"
        okButtonProps={{ disabled: !validateProperties() }}
      >
        <HelperNotification>
          Informe os campos para vincular ao perfil de configurações de teste rápido do equipamento.
        </HelperNotification>
        <BaseForm layout="vertical" style={{ width: '100%', marginTop: '1rem' }}>
          <Row gutter={[18, 18]} style={{ marginTop: '0.5rem' }}>
            <Col xs={24}>
              <BaseFormInputItem style={{ width: '100%' }} label="APN">
                <Input
                  placeholder="Informe o APN"
                  value={getPropertyValue(DevicePropertyType.Apn)}
                  onChange={(e) => handleChangeProperties(DevicePropertyType.Apn, e.target.value)}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row gutter={[18, 18]} style={{ marginTop: '0.5rem' }}>
            <Col xs={24} md={6}>
              <BaseFormInputItem label="Servidor primário">
                <RadioGroup
                  defaultValue={'IP'}
                  value={primaryServer}
                  onChange={(e) => handleChangePrimaryServer(e.target.value)}
                >
                  <Space size={0} direction="vertical">
                    <Radio value="IP">IP</Radio>
                    <Radio value="DNS">DNS</Radio>
                  </Space>
                </RadioGroup>
              </BaseFormInputItem>
            </Col>
            <Col xs={24} md={18}>
              {
                {
                  IP: (
                    <BaseFormInputItem style={{ width: '100%' }} label="IP do servidor primário">
                      {/* <Input
                        placeholder="Informe o IP do servidor primário"
                        value={getPropertyValue(DevicePropertyType.IpServidor)}
                        onChange={(e) => handleChangeProperties(DevicePropertyType.IpServidor, e.target.value)}
                      /> */}
                      <MaskedInput
                        {...maskIdProps}
                        className="ant-input"
                        placeholder="Informe o IP do servidor primário"
                        value={getPropertyValue(DevicePropertyType.IpServidor)}
                        onChange={(e) => handleChangeProperties(DevicePropertyType.IpServidor, e.target.value)}
                        onBlur={(e) =>
                          handleChangeProperties(DevicePropertyType.IpServidor, e.target.value.replaceAll('_', ''))
                        }
                      />
                    </BaseFormInputItem>
                  ),
                  DNS: (
                    <BaseFormInputItem style={{ width: '100%' }} label="DNS do servidor primário">
                      <Input
                        placeholder="Informe o DNS do servidor primário"
                        value={getPropertyValue(DevicePropertyType.Dns)}
                        onChange={(e) => handleChangeProperties(DevicePropertyType.Dns, e.target.value)}
                      />
                    </BaseFormInputItem>
                  ),
                }[primaryServer]
              }
            </Col>
          </Row>
          <Row gutter={[18, 18]} style={{ marginTop: '0.5rem' }}>
            <Col xs={24} md={18}>
              <BaseFormInputItem style={{ width: '100%' }} label="Porta do servidor primário ">
                <Input
                  placeholder="Informe a porta do servidor primário"
                  value={getPropertyValue(DevicePropertyType.PortaServidor)}
                  onKeyDown={onKeyDownOnlyNumber}
                  onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                    if (e.data && !e.data.match(/[0-9]/)) e.preventDefault();
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || (/^\d+$/.test(value) && Number(value) <= 65535)) {
                      handleChangeProperties(DevicePropertyType.PortaServidor, e.target.value);
                    }
                  }}
                />
              </BaseFormInputItem>
            </Col>
            <Col xs={24} md={6}>
              <BaseFormInputItem label="Tipo servidor primário">
                <RadioGroup
                  value={getPropertyValue(DevicePropertyType.TipoProtocolo)}
                  onChange={(e) => handleChangeProperties(DevicePropertyType.TipoProtocolo, e.target.value)}
                  defaultValue={'1'}
                >
                  <Space size={0} direction="vertical">
                    <Radio value="1">TCP</Radio>
                    <Radio value="2">UDP</Radio>
                  </Space>
                </RadioGroup>
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row gutter={[18, 18]} style={{ marginTop: '0.5rem' }}>
            <Col xs={24} md={8}>
              <BaseFormInputItem label="Intervalo ignição ligada (s)" supportText="Entre 10 a 172800 segundos">
                <Input
                  placeholder="Digite o intervalo"
                  value={getPropertyValue(DevicePropertyType.IntervaloEnvioCarroLigado)}
                  onChange={(e) => handleChangeProperties(DevicePropertyType.IntervaloEnvioCarroLigado, e.target.value)}
                  onKeyDown={onKeyDownOnlyNumber}
                />
              </BaseFormInputItem>
            </Col>
            <Col xs={24} md={8}>
              <BaseFormInputItem label="Intervalo bateria externa (s)" supportText="Entre 10 a 172800 segundos">
                <Input
                  placeholder="Digite o intervalo"
                  value={getPropertyValue(DevicePropertyType.IntervaloEnvioBateriaExterna)}
                  onChange={(e) =>
                    handleChangeProperties(DevicePropertyType.IntervaloEnvioBateriaExterna, e.target.value)
                  }
                  onKeyDown={onKeyDownOnlyNumber}
                />
              </BaseFormInputItem>
            </Col>
            <Col xs={24} md={8}>
              <BaseFormInputItem label="Intervalo bateria interna (s)" supportText="Entre 10 a 172800 segundos">
                <Input
                  placeholder="Digite o intervalo"
                  value={getPropertyValue(DevicePropertyType.IntervaloEnvioBateriaInterna)}
                  onChange={(e) =>
                    handleChangeProperties(DevicePropertyType.IntervaloEnvioBateriaInterna, e.target.value)
                  }
                  onKeyDown={onKeyDownOnlyNumber}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
        </BaseForm>
      </Modal>
    </>
  );
};
