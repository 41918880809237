import { FirmwareGroupListModel, FirmwareGroupModel } from '@app/domain/firmwareGroup/firmwareGroupModel';
import { ApiServiceBase, IApiServiceBase } from '@app/services/base/apiServiceBase';

export default interface IFirmwareGroupService extends IApiServiceBase<FirmwareGroupModel, FirmwareGroupModel> {
  getListAll(): Promise<FirmwareGroupListModel[]>;
  getGroupByIdModelAndVersion(idModel?: number, version?: string): Promise<FirmwareGroupModel>;
}

export class FirmwareGroupService extends ApiServiceBase<FirmwareGroupModel, FirmwareGroupModel> {
  constructor() {
    super('GrupoFirmware');
  }

  async getListAll(): Promise<FirmwareGroupListModel[]> {
    return (await this.api.get(''))?.data;
  }
  async getGroupByIdModelAndVersion(idModel?: number, version?: string): Promise<FirmwareGroupModel> {
    return await this.get(`obter-por-id-modelo-versao?idModelo=${idModel}&versao=${version}`);
  }
}
