import React from 'react';
import * as S from './GoogleMapItems.styles';

type ZoomButtonsProps = {
  mapRef: React.MutableRefObject<google.maps.Map | null>;
};

export const ZoomButtons: React.FC<ZoomButtonsProps> = ({ mapRef }) => {
  const increaseZoom = () => {
    if (mapRef.current) {
      const currentZoom = mapRef.current.getZoom();
      mapRef.current.setZoom(Math.min(Number(currentZoom) + 1, 22)); // máximo é 22
    }
  };

  const decreaseZoom = () => {
    if (mapRef.current) {
      const currentZoom = mapRef.current.getZoom();
      mapRef.current.setZoom(Math.max(Number(currentZoom) - 1, 1)); // mínimo é 1
    }
  };

  return (
    <S.ButtonsWrapper>
      <S.ZoomButton onClick={increaseZoom}>+</S.ZoomButton>
      <S.ButtonDivisor />
      <S.ZoomButton onClick={decreaseZoom}>-</S.ZoomButton>
    </S.ButtonsWrapper>
  );
};
