import React, { useEffect, useState } from 'react';
import * as S from './driverDownload.styles';
import { Select } from '@app/components/common/selects/Select/Select';
import { Button } from '@app/components/common/buttons/Button/Button';
import { ReactComponent as IconDownload } from '@app/assets/icons/download.svg';
import { DriverModel } from '@app/domain/driver/driverModel';
import IDriverService, { DriverService } from '@app/services/driverService';

const driverService: IDriverService = new DriverService();

const DriverDownload: React.FC = () => {
  const [driver, setDriver] = useState<string>('');
  const [drivers, setDrivers] = useState<DriverModel[]>([]);

  const fetchDrivers = async () => {
    const data = await driverService.getArray('');
    setDrivers(data);
  };

  const downloadFromLink = (link: string) => {
    if (link.length > 0) {
      window.open(link);
    }
  };

  useEffect(() => {
    fetchDrivers();
  }, []);

  return (
    <>
      <S.InfoBox>
        <S.TextInfo>
          Para iniciar a sincronização de <strong color="#263668">alguns equipamentos</strong> é preciso realizar o
          download e a instalação do driver. Caso a instalação ja tenha sido feita, não é necessário realizar o download
          novamente.{' '}
          <strong color="#263668">
            Se o equipamento não estiver listado, não se preocupe, ele não precisa da instalação do driver.
          </strong>
        </S.TextInfo>
      </S.InfoBox>
      <p>Selecione o driver referente ao modelo que deseja para realizar o download.</p>
      <S.SelectContainer>
        <Select
          width="100%"
          showArrow
          value={driver}
          placeholder="Selecione o fabricante"
          onChange={(value) => setDriver(value as string)}
          options={drivers.map((m) => ({ label: m.modelo, value: m.driver }))}
        ></Select>
        <Button icon={<IconDownload />} onClick={() => downloadFromLink(driver)}>
          Baixar
        </Button>
      </S.SelectContainer>
    </>
  );
};

export default DriverDownload;
