import { LabelModelPrint } from '@app/domain/label/labelModelPrint';
import { Document, Font, Page, StyleSheet } from '@react-pdf/renderer';
import Roboto from '@app/assets/fonts/Roboto-Regular.ttf';
import GlacialIndifference from '@app/assets/fonts/GlacialIndifference-Regular.otf';
import React from 'react';
import { LabelTemplates } from '@app/constants/enums/label/label';
import { J_R11, styles as J_R11_styles } from '@app/pages/label/components/LabelTemplates/J_R11';
import { J_R12, styles as J_R12_styles } from '@app/pages/label/components/LabelTemplates/J_R12';
import { J_M15, styles as J_M15_styles } from '@app/pages/label/components/LabelTemplates/J_M15';

Font.register({
  family: 'Roboto',
  format: 'truetype',
  src: Roboto,
});

Font.register({
  family: 'GlacialIndifference',
  src: GlacialIndifference,
});

const chunkArray = (array: LabelModelPrint[], size: number) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result;
};

interface LabelConfig {
  Label: React.FC<LabelModelPrint>;
  styles: ReturnType<typeof StyleSheet.create>;
  quantityPerPage: number;
  orientation?: 'portrait' | 'landscape';
}

export const getLabelByModelName = (modelName: string): LabelConfig => {
  const upperCaseModelName = modelName.toUpperCase();

  switch (upperCaseModelName) {
    case LabelTemplates.J_R11:
      return { Label: J_R11, styles: J_R11_styles, quantityPerPage: 33, orientation: 'landscape' };
    case LabelTemplates.J_R12:
      return { Label: J_R12, styles: J_R12_styles, quantityPerPage: 12, orientation: 'landscape' };
    case LabelTemplates.J_M15:
      return { Label: J_M15, styles: J_M15_styles, quantityPerPage: 28, orientation: 'portrait' };
    default:
      throw new Error(`Template para modelo ${modelName} não encontrado para impressão.`);
  }
};

export const renderPDF = async (labelsToPrint: LabelModelPrint[], modelName: string) => {
  try {
    const { pdf } = await import('@react-pdf/renderer');

    const { Label, styles, quantityPerPage, orientation } = getLabelByModelName(modelName);

    const groupedLabels = chunkArray(labelsToPrint, quantityPerPage);

    const pdfElement = (
      <Document>
        {groupedLabels.map((labels, index) => (
          <Page key={index} size="A4" orientation={orientation as 'portrait' | 'landscape'} style={{ ...styles.page }}>
            {labels.map((labelToPrint, index) => (
              <Label key={index} {...labelToPrint} />
            ))}
          </Page>
        ))}
      </Document>
    );

    return pdf(pdfElement).toBlob();
  } catch (error) {
    throw error;
  }
};

export const renderUiniqueLabelPDF = async (labelToPrint: LabelModelPrint) => {
  const { pdf } = await import('@react-pdf/renderer');

  const { Label, styles } = getLabelByModelName(labelToPrint.modelo);

  const pdfElement = (
    <Document>
      <Page size="A4" orientation={'portrait'} style={{ ...styles.page, margin: 20 }}>
        <Label {...labelToPrint} />
      </Page>
    </Document>
  );

  return pdf(pdfElement).toBlob();
};
