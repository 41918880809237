import React from 'react';
import { Col } from 'antd';
import { IconDevices } from '@app/assets/slump-icons';
import * as S from '../Cards.styles';

interface ICardAddDevice {
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  idCliente: number;
}

export const CardAddDevices: React.FC<ICardAddDevice> = ({ setModalVisible, idCliente }) => {
  const isDisabled = idCliente === 0 || idCliente === undefined;

  return (
    <>
      <S.Card>
        <S.Title>Adicionar dispositivos</S.Title>
        <Col xs={24} md={25}>
          <S.Sincronizar>
            <S.Button icon={<IconDevices />} disabled={isDisabled} onClick={() => setModalVisible(true)}>
              Selecionar dispositivos
            </S.Button>
          </S.Sincronizar>
        </Col>
      </S.Card>
    </>
  );
};
