import React from 'react';
import { Col, Modal as AntdModal, ModalProps as AntModalProps, Row } from 'antd';
import { modalSizes } from 'constants/modalSizes';
import { ReactComponent as SmallLogo } from '@app/assets/icons/jmak-icon.svg';
import * as S from './Modal.styles';
import { Button } from '../buttons/Button/Button';

export const { info: InfoModal, success: SuccessModal, warning: WarningModal, error: ErrorModal } = AntdModal;

interface ModalProps extends AntModalProps {
  size?: 'small' | 'medium' | 'large';
}

export const Modal: React.FC<ModalProps> = ({
  size = 'medium',
  children,
  title,
  onCancel,
  onOk,
  okButtonProps,
  cancelButtonProps,
  okText,
  cancelText,
  ...props
}) => {
  const modalSize = Object.entries(modalSizes).find((sz) => sz[0] === size)?.[1];

  return (
    <S.Modal
      onCancel={onCancel}
      getContainer={false}
      width={modalSize}
      title={
        <Row gutter={16} align="middle">
          <Col>
            <SmallLogo width="1.5rem" height="1.5rem" style={{ marginTop: '0.4rem', marginLeft: '0.5rem' }} />
          </Col>
          <Col>{title}</Col>
        </Row>
      }
      footer={
        <Row gutter={16} align="middle" justify="end">
          <Col span={6}>
            <Button type="ghost" block onClick={onCancel} {...cancelButtonProps}>
              {cancelText ?? 'Cancelar'}
            </Button>
          </Col>
          <Col span={6}>
            <Button type="primary" block onClick={onOk} {...okButtonProps}>
              {okText ?? 'Sim'}
            </Button>
          </Col>
        </Row>
      }
      {...props}
    >
      {children}
    </S.Modal>
  );
};
