import { SpinProps } from 'antd';
import * as S from './Spinner.styles';
import React from 'react';

interface SpinnerSlumpProps extends SpinProps {
  text?: string;
}

export const Spinner: React.FC<SpinnerSlumpProps> = ({ text, children, ...props }) => {
  {
    return props.spinning ? (
      <>
        <S.SpinnerSlumpContainer>
          <S.Spinner {...props} />
          {!!text ? text : 'Carregando...'}
        </S.SpinnerSlumpContainer>
        {children}
      </>
    ) : (
      <>{children}</>
    );
  }
};
