import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import * as S from './ModalClienteEquipamento.styles';
import { Select } from '@app/components/common/selects/Select/Select';
import { Modal } from '@app/components/common/Modal/Modal';
import React, { useCallback, useEffect, useState } from 'react';
import { GroupedDevice, GroupedDevicesByClient, ScheduleParams } from '@app/domain/scheduleFactory/scheduleParams';
import { ClientModel } from '@app/domain/client/clientModel';
import { notificationController } from '@app/controllers/notificationController';
import { ClientService } from '@app/services/clientService';
import { FactoryDeviceService } from '@app/services/factoryDeviceService';
import axios from 'axios';

interface IModalClienteEquipamento {
  title: string;
  open: boolean;
  selectedClientId?: number;
  onCancel: () => void;
  scheduleParams: ScheduleParams;
  setFactoryDevicesByClient: React.Dispatch<React.SetStateAction<GroupedDevicesByClient>>;
  setScheduleParams: React.Dispatch<React.SetStateAction<ScheduleParams>>;
}

const clientService = new ClientService();
const deviceService = new FactoryDeviceService();

export const ModalClienteEquipamento: React.FC<IModalClienteEquipamento> = ({
  title,
  open,
  selectedClientId,
  onCancel,
  setFactoryDevicesByClient,
  scheduleParams,
  setScheduleParams,
}) => {
  const isEditingSingleClient = !!selectedClientId;
  const [clientsOptions, setClientsOptions] = useState<ClientModel[]>([]);
  const [devicesOptions, setDevicesOptions] = useState<FactoryDeviceModel[]>([]);

  const [loadingClients, setLoadingClients] = useState(false);
  const [loadingDevices, setLoadingDevices] = useState(false);

  const fetchClientsOptions = useCallback(() => {
    return clientService
      .getArray(``)
      .then((res) => {
        setClientsOptions(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });
  }, []);

  const fetchDevicesOptionsByClientsIdModelo = useCallback(() => {
    const source = axios.CancelToken.source();
    setLoadingDevices(true);
    deviceService
      .postWithCancelToken(
        `/obter-por-clientes-id-modelo`,
        {
          idsClientes: scheduleParams.idsClientes,
          idModelo: scheduleParams.idModelo,
        } as unknown as FactoryDeviceModel,
        source.token,
      ) // Pass the token here
      .then((res) => {
        setDevicesOptions(res as unknown as FactoryDeviceModel[]);
        setScheduleParams((prevState) => ({
          ...prevState,
          dispositivos: [],
        }));
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.error('Request was canceled', error.message);
        } else {
          notificationController.error(error);
        }
      })
      .finally(() => setLoadingDevices(false));

    return () => {
      source.cancel('Parameters changed, canceling request');
    };
  }, [scheduleParams.idModelo, scheduleParams.idsClientes, setScheduleParams]);

  const addFactoryDeviceToTable = () => {
    const groupedByClient: GroupedDevicesByClient = {};

    scheduleParams.dispositivos?.forEach((device) => {
      const correspondingDevice = devicesOptions.find((d) => d.id === device.idDispositivo);
      const correspondingClient = clientsOptions.find((c) => c.id === correspondingDevice?.idCliente);

      const groupedDevice: GroupedDevice = {
        clienteNome: correspondingClient?.razaoSocial ?? 'Desconhecido',
        dispositivoNumeroSerie: correspondingDevice?.numeroSerie ?? 'Desconhecido',
      };

      const clientId = correspondingDevice?.idCliente ?? -1;

      if (clientId in groupedByClient) {
        groupedByClient[clientId].push(groupedDevice);
      } else {
        groupedByClient[clientId] = [groupedDevice];
      }
    });

    setFactoryDevicesByClient(groupedByClient);
    onCancel();
  };

  const filteredClientsOptions = React.useMemo(() => {
    if (isEditingSingleClient) {
      const selectedClientOption = clientsOptions.find((c) => c.id === selectedClientId);
      return selectedClientOption ? [{ value: selectedClientOption.id, label: selectedClientOption.razaoSocial }] : [];
    }

    return [
      { value: -1, label: 'Todos' },
      ...clientsOptions.map((c) => ({
        value: c.id,
        label: c.razaoSocial,
      })),
    ];
  }, [clientsOptions, isEditingSingleClient, selectedClientId]);

  const filteredClientsValue = () => {
    if (isEditingSingleClient) {
      return [selectedClientId];
    }

    return scheduleParams.idsClientes;
  };

  const getDeviceValues = () => {
    if (isEditingSingleClient) {
      // Return only devices that belong to the selected client
      return scheduleParams.dispositivos
        ?.filter((device) => {
          const correspondingDevice = devicesOptions.find((d) => d.id === device.idDispositivo);
          return correspondingDevice?.idCliente === selectedClientId;
        })
        .map((device) => device.idDispositivo);
    }

    // When editing multiple clients, return all selected devices
    return scheduleParams.dispositivos?.map((device) => device.idDispositivo);
  };

  const getDeviceOptions = () => {
    let filteredDevices;

    if (isEditingSingleClient) {
      // If editing for a single client, filter devices that belong to this client
      filteredDevices = devicesOptions.filter((device) => device.idCliente === selectedClientId);
    } else {
      // If editing for multiple clients, filter devices that belong to any of the selected clients
      filteredDevices = devicesOptions.filter(
        (device) => scheduleParams.idsClientes?.includes(Number(device.idCliente)),
      );
    }

    return [
      { value: -1, label: 'Todos' },
      ...filteredDevices.map((device) => ({
        value: device.id,
        label: device.numeroSerie,
      })),
    ];
  };

  useEffect(() => {
    if (scheduleParams.idModelo) {
      setLoadingClients(true);
      fetchClientsOptions().finally(() => setLoadingClients(false));
      setScheduleParams((prevState) => ({
        ...prevState,
        idsClientes: [],
        dispositivos: [],
      }));
    }
  }, [fetchClientsOptions, scheduleParams.idModelo, setScheduleParams]);

  useEffect(() => {
    if (scheduleParams.idsClientes?.length && scheduleParams.idModelo) {
      const cancelFetch = fetchDevicesOptionsByClientsIdModelo();
      return () => {
        cancelFetch();
      };
    }
  }, [fetchDevicesOptionsByClientsIdModelo, scheduleParams.idModelo, scheduleParams.idsClientes?.length]);

  return (
    <>
      <Modal
        title={title}
        open={open}
        onOk={addFactoryDeviceToTable}
        onCancel={onCancel}
        okButtonProps={{
          disabled:
            !scheduleParams.idsClientes ||
            !scheduleParams.idsClientes.length ||
            !scheduleParams.dispositivos ||
            !scheduleParams.dispositivos.length,
        }}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <S.ModalContainer>
          <S.InputWrapper>
            <S.InputLabel>Cliente</S.InputLabel>
            <Select
              showArrow
              showSearch
              mode="multiple"
              allowClear
              placeholder="Selecione os clientes"
              disabled={!!selectedClientId}
              value={filteredClientsValue()}
              maxTagCount={'responsive'}
              loading={loadingClients}
              onChange={(value: unknown) => {
                let selectedValues = Array.isArray(value) ? value : [value];

                if (selectedValues.includes(-1)) {
                  selectedValues = clientsOptions.map((c) => c.id);
                } else if (scheduleParams?.idsClientes?.includes(-1)) {
                  selectedValues = selectedValues.filter((v) => v !== -1);
                }
                setScheduleParams((prevState) => ({
                  ...prevState,
                  idsClientes: selectedValues as number[],
                  dispositivos: [],
                }));
              }}
              options={filteredClientsOptions}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
            />
            <S.InputHelperText>Pesquise e selecione um ou mais clientes</S.InputHelperText>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.InputLabel>Equipamento</S.InputLabel>
            <Select
              showArrow
              showSearch
              mode="multiple"
              allowClear
              placeholder="Selecione os equipamentos"
              disabled={!scheduleParams.idsClientes || !scheduleParams.idsClientes.length}
              value={getDeviceValues()}
              maxTagCount={'responsive'}
              loading={loadingDevices}
              onChange={(value: unknown) => {
                let selectedDeviceValues = Array.isArray(value) ? value : [value];

                if (selectedDeviceValues.includes(-1)) {
                  if (selectedClientId) {
                    // If editing for a specific client
                    selectedDeviceValues = devicesOptions
                      .filter((device) => device.idCliente === selectedClientId)
                      .map((device) => device.id);
                  } else {
                    // If editing for selected clients
                    selectedDeviceValues = devicesOptions
                      .filter((device) => scheduleParams.idsClientes?.includes(Number(device.idCliente)))
                      .map((device) => device.id);
                  }
                }

                const updatedDevices = selectedClientId
                  ? [
                      ...(scheduleParams.dispositivos?.filter((device) => {
                        const correspondingDevice = devicesOptions.find((d) => d.id === device.idDispositivo);
                        return correspondingDevice?.idCliente !== selectedClientId;
                      }) || []),
                      ...selectedDeviceValues.map((id: number) => ({ idDispositivo: id })),
                    ]
                  : selectedDeviceValues.map((id: number) => ({ idDispositivo: id }));

                setScheduleParams((prevState) => ({
                  ...prevState,
                  dispositivos: updatedDevices,
                }));
              }}
              options={getDeviceOptions()}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
            />

            <S.InputHelperText>Pesquise e selecione um ou mais equipamentos</S.InputHelperText>
          </S.InputWrapper>
        </S.ModalContainer>
      </Modal>
    </>
  );
};
