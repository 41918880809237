import React from 'react';
import * as S from './createFlow.styles';
import { FlowTypes } from '../../create';
import { notificationController } from '@app/controllers/notificationController';

type CreateFlowProps = {
  activeFlow: string;
  setActiveFlow: React.Dispatch<React.SetStateAction<string>>;
  flowBlocked: boolean;
};

const CreateFlow: React.FC<CreateFlowProps> = ({ activeFlow, setActiveFlow, flowBlocked }) => {
  const handleChangeActiveFlow = (flow: string) => {
    if (flowBlocked) {
      notificationController.warning({
        message: 'Não é possível alterar o tipo de cadastro após iniciar no tipo anterior',
      });
      return;
    } else {
      setActiveFlow(flow);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', marginBottom: '1rem' }}>
        <S.FlowTypeButton
          blocked={flowBlocked}
          disabled={flowBlocked}
          active={activeFlow === FlowTypes.factory}
          onClick={() => handleChangeActiveFlow(FlowTypes.factory)}
        >
          Fábrica
        </S.FlowTypeButton>
        <S.FlowTypeButton
          blocked={flowBlocked}
          disabled={flowBlocked}
          active={activeFlow === FlowTypes.factoryClient}
          onClick={() => handleChangeActiveFlow(FlowTypes.factoryClient)}
        >
          Fábrica + Cliente
        </S.FlowTypeButton>
      </div>
    </>
  );
};

export default CreateFlow;
