import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { LabelModelPrint } from '@app/domain/label/labelModelPrint';
import AnatelWhiteIcon from '@app/assets/icons/anatel-white.png';
import JmakWhiteIcon from '@app/assets/icons/jmak-white.png';
import RadioWaveIcon from '@app/assets/icons/radio-wave.png';

export const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '0.52mm',
    alignSelf: 'center',
    padding: '5mm',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '52.5mm',
    height: '45.74mm',
    padding: '1.32mm 1.59mm 1.06mm 1.59mm',
    backgroundColor: 'black',
    color: 'white',
  },
  title: {
    top: '2mm',
    left: '1.2mm',
    fontFamily: 'GlacialIndifference',
    fontSize: '8.2mm',
  },
  subTitle: {
    top: '0.8mm',
    bottom: '1.58mm',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '1.35mm',
  },
  firstColumn: {
    display: 'flex',
    width: '28mm',
    top: '2rem',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: '1.5mm',
    bottom: '1mm',
    width: '100%',
  },
  secondColumn: {
    display: 'flex',
    width: '18mm',
    fontSize: '1mm',
    alignItems: 'center',
    left: '5mm',
    top: '2.5rem',
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1.2mm',
    marginLeft: '0.7mm',
  },
  infoFirstColumn: {
    top: '5mm',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.26mm',
    // border: '1px solid green',
  },
  infoSecondColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.26mm',
  },
  deviceMapContainer: {
    top: '-1mm',
    width: '9.79mm',
    height: '5.35mm',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    padding: '0.26mm',
    gap: '0.26mm',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  deviceMapItem: {
    backgroundColor: 'black',
    width: '1.59mm',
    height: '2.12mm',
    fontSize: '1.06mm',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.7mm',
    fontSize: '1.06mm',
  },
  colorItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.2mm',
    alignItems: 'center',
  },
  radioWaveIcon: {
    width: '18mm',
    top: '1mm',
    left: '5.5mm',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1.32mm',
    justifyContent: 'space-between',
    marginTop: '0.85mm',
    marginBottom: '1mm',
  },
  circle: {
    width: '0.53mm',
    height: '0.53mm',
    borderRadius: '100%',
    marginRight: '0.26mm',
    backgroundColor: 'white',
    alignSelf: 'center',
  },
  eletricInfo: {
    top: '0.8mm',
    bottom: '1mm',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.8mm',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
  eletricInfoItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '0.9mm',
  },
  infoContainerColorsColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.26mm',
  },
  infoContainerBatteryColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.26mm',
  },
  triangleContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '0.26mm',
    alignItems: 'center',
    width: '6.61mm',
    marginTop: '6.35mm',
    marginLeft: '1.85mm',
    padding: '0.26mm',
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderLeftWidth: '2.65mm',
    borderRightWidth: '2.65mm',
    borderBottomWidth: '3.17mm',
    borderLeftColor: 'white',
    borderRightColor: 'white',
    borderBottomColor: 'black',
    marginBottom: '-1.06mm',
  },
  rectangle: {
    paddingTop: '-1.06mm',
    width: '2.65mm',
    height: '4.23mm',
    backgroundColor: 'black',
    alignSelf: 'center',
  },
  triangleText: {
    marginLeft: '2.38mm',
  },
  qrcode: {
    top: '0.85mm',
    alignSelf: 'center',
    width: '10.7mm',
    right: '0.85mm',
  },
  serial: {
    fontSize: '1.2mm',
    marginTop: '1.5mm',
    alignSelf: 'center',
    marginRight: '1mm',
  },
  jmakWhiteIcon: {
    width: '19.55mm',
    marginLeft: '0.2mm',
    alignSelf: 'center',
  },
  jmakLink: {
    fontSize: '1.2mm',
    alignSelf: 'center',
    letterSpacing: '0.2mm',
    color: 'white',
  },
  anatelImage: {
    marginTop: '22mm',
    width: '9mm',
    alignSelf: 'center',
    marginBottom: '0.95mm',
  },
  analteText: {
    fontSize: '1mm',
    alignSelf: 'center',
    bottom: '0.53mm',
    letterSpacing: '0.1mm',
  },
  pentagon: {
    width: '52.5mm',
    top: '-0.2mm',
    borderBottomWidth: '18mm',
    borderBottomColor: 'black',
    borderLeftWidth: '11.25mm',
    borderLeftColor: 'white',
    borderRightWidth: '11.25mm',
    borderRightColor: 'white',
    transform: 'rotate(180deg)',
  },
  titleJmakRow: {
    display: 'flex',
    bottom: '-14mm',
    flexDirection: 'column',
    transform: 'rotate(-180deg)',
  },
});

// Modelo J-R12: LabelTemplate.J_M15
export const J_M15: React.FC<LabelModelPrint> = (label: LabelModelPrint) => {
  return (
    <View>
      <View style={styles.container}>
        <View style={styles.firstColumn}>
          <View style={styles.titleRow}>
            <Text style={styles.title}>{label.modelo}</Text>

            <View style={styles.eletricInfo}>
              <View style={styles.eletricInfoItem}>
                <Text style={styles.subTitle}>Frequência</Text>
                <Text style={styles.subTitle}>125 kHz-13,56 MHz</Text>
              </View>
              <View style={styles.eletricInfoItem}>
                <Text style={styles.subTitle}>Tensão:</Text>
                <Text style={styles.subTitle}>9-30 VCC</Text>
              </View>
            </View>
          </View>
          <View style={styles.infoRow}>
            <View style={styles.infoFirstColumn}>
              <View style={{ ...styles.deviceMapContainer }}>
                <View style={styles.deviceMapItem}>
                  <Text>10</Text>
                </View>
                <View style={styles.deviceMapItem}>
                  <Text>9</Text>
                </View>
                <View style={styles.deviceMapItem}>
                  <Text>8</Text>
                </View>
                <View style={styles.deviceMapItem}>
                  <Text>7</Text>
                </View>
                <View style={styles.deviceMapItem}>
                  <Text>6</Text>
                </View>
                <View style={styles.deviceMapItem}>
                  <Text>5</Text>
                </View>
                <View style={styles.deviceMapItem}>
                  <Text>4</Text>
                </View>
                <View style={styles.deviceMapItem}>
                  <Text>3</Text>
                </View>
                <View style={styles.deviceMapItem}>
                  <Text>2</Text>
                </View>
                <View style={styles.deviceMapItem}>
                  <Text>1</Text>
                </View>
              </View>
              <View style={styles.colorsContainer}>
                <View style={styles.colorItem}>
                  <View style={styles.circle} />
                  <Text>Vermelho(1) VIN</Text>
                </View>
                <View style={styles.colorItem}>
                  <View style={styles.circle} />
                  <Text>Marrom(2) Relé </Text>
                </View>
                <View style={styles.colorItem}>
                  <View style={styles.circle} />
                  <Text>Roxo(3) One wire</Text>
                </View>
                <View style={styles.colorItem}>
                  <View style={styles.circle} />
                  <Text>Branco(4) TTL TX</Text>
                </View>
                <View style={styles.colorItem}>
                  <View style={styles.circle} />
                  <Text>Laranja(5) TTL RX</Text>
                </View>
                <View style={styles.colorItem}>
                  <View style={styles.circle} />
                  <Text>Ciano(6) NC</Text>
                </View>
                <View style={styles.colorItem}>
                  <View style={styles.circle} />
                  <Text>Preto(7) GND</Text>
                </View>
                <View style={styles.colorItem}>
                  <View style={styles.circle} />
                  <Text>Amarelo(8) IGN</Text>
                </View>
                <View style={styles.colorItem}>
                  <View style={styles.circle} />
                  <Text>Azul(9) RS232 RX</Text>
                </View>
                <View style={styles.colorItem}>
                  <View style={styles.circle} />
                  <Text>Cinza(10) RS232 TX</Text>
                </View>
              </View>
            </View>
            <View style={styles.infoSecondColumn}>
              <Image src={RadioWaveIcon} style={styles.radioWaveIcon} />
            </View>
          </View>
        </View>
        <View style={styles.secondColumn}>
          <Image src={label.qrCodeURL} style={styles.qrcode} />
          <Text style={styles.serial}>{`SN: ${label.numeroSerie}`}</Text>

          <Image src={AnatelWhiteIcon} style={styles.anatelImage} />
          <Text style={styles.analteText}>{label.certificadoAnatel}</Text>
          <Text style={styles.analteText}>Fabricado no Brasil</Text>
        </View>
      </View>
      <View style={styles.pentagon}>
        <View style={styles.titleJmakRow}>
          <Image src={JmakWhiteIcon} style={styles.jmakWhiteIcon} />
          <Text style={styles.jmakLink}>www.jmak.com.br</Text>
        </View>
      </View>
    </View>
  );
};
