import axios, { Axios, AxiosError, AxiosRequestHeaders } from 'axios';
import appSettings from '@app/config/appsettings';
import qs from 'qs';

export interface IApiIdentityServiceBase<TRequest, TResponse> {
  api: Axios;
  post(endpoint: string, entity: TRequest): Promise<TResponse>;
  postArray(endpoint: string, entity: TRequest[]): Promise<TResponse[]>;
  update(endpoint: string, entity: TRequest): Promise<TResponse>;
  updateArray(endpoint: string, entity: TRequest[]): Promise<TResponse[]>;
  get(endpoint: string): Promise<TResponse>;
  getArray(endpoint: string): Promise<TResponse[]>;
  delete(endpoint: string): Promise<boolean>;
}

export abstract class ApiIdentityServiceBase<TRequest, TResponse>
  implements IApiIdentityServiceBase<TRequest, TResponse>
{
  public api: Axios;
  private token: string;
  private base_url: string;
  private service_endpoint: string;

  constructor(service_endpoint: string) {
    this.token = '';
    this.service_endpoint = service_endpoint;
    this.base_url = appSettings().APIs.IdentityServer.UrlBaseAdmin;

    this.api = axios.create({
      baseURL: `${this.base_url}/api/${this.service_endpoint}`,
      headers: { 'Content-Type': 'application/json' },
    });

    this.api.interceptors.request.use(async (config) => {
      if (!this.token) await this.getToken();

      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${this.token}`,
      } as AxiosRequestHeaders;
      return config;
    });

    this.api.interceptors.response.use(undefined, (error: AxiosError) => {
      if (error.response?.status === 401) {
        this.token = '';
        window.location.reload();
      }

      throw Error(
        typeof error.response?.data === 'string'
          ? error.response?.data
          : JSON.stringify(error.response?.data) || 'Erro ao consultar a API, por favor, consulte um administrador',
      );
    });
  }

  private async getToken() {
    try {
      const { data } = await axios.create().post(
        `${appSettings().APIs.IdentityServer.UrlBase}/connect/token`,
        qs.stringify({
          client_id: appSettings().APIs.IdentityServer.ClientId,
          client_secret: appSettings().APIs.IdentityServer.ClientSecret,
          grant_type: 'client_credentials',
          scope: 'jmak_identity_admin_api',
          token_name: 'IS4',
        }),
      );

      this.token = data.access_token;
    } catch (error) {
      throw Error('Ocorreu um erro ao obter as credenciais');
    }
  }

  async post(endpoint: string, entity: TRequest): Promise<TResponse> {
    try {
      return (await this.api.post(endpoint, JSON.stringify(entity)))?.data;
    } catch (error) {
      throw error;
    }
  }

  async postArray(endpoint: string, entity: TRequest[]): Promise<TResponse[]> {
    try {
      return (await this.api.post(endpoint, JSON.stringify(entity))).data;
    } catch (error) {
      throw error;
    }
  }

  async update(endpoint: string, entity: TRequest): Promise<TResponse> {
    try {
      return (await this.api.put(endpoint, JSON.stringify(entity)))?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateArray(endpoint: string, entity: TRequest[]): Promise<TResponse[]> {
    try {
      return (await this.api.post(endpoint, JSON.stringify(entity)))?.data;
    } catch (error) {
      throw error;
    }
  }

  async get(endpoint: string): Promise<TResponse> {
    try {
      const response = await this.api.get(endpoint);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async getArray(endpoint: string): Promise<TResponse[]> {
    try {
      const response = await this.api.get(endpoint);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async delete(endpoint: string): Promise<boolean> {
    try {
      return (await this.api.delete(endpoint)).data;
    } catch (error) {
      throw error;
    }
  }
}
