import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { FenceService } from '@app/services/fenceService';
import { FenceGroupModel } from '@app/domain/fence/fenceGroupModel';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { FenceModel } from '@app/domain/fence/fenceModel';
import MapComponentView from '@app/pages/fence/view/components/MapComponentView/MapComponentView';
import { Checkbox, CheckboxChangeEvent } from '@app/components/common/Checkbox/Checkbox';
import FencesDashboardView from '@app/pages/fence/view/components/FencesDashboard/FencesDashboardView';
import * as S from '@app/pages/fence/view/view.styles';
import { setFooter } from '@app/store/slices/footerSlice';
import { propertyFenceValueToEventos } from '@app/pages/fence/commonFenceFunctions';
import { Button } from '@app/components/common/buttons/Button/Button';

const fenceService = new FenceService();

export const FenceView: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const [fenceGroup, setFenceGroup] = useState<FenceGroupModel>();
  const [fences, setFences] = useState<FenceModel[]>([]);

  const [showInactiveFences, setShowInactiveFences] = useState(false);

  const fetchGroupData = useCallback(() => {
    setLoading(true);
    fenceService
      .get(`/grupos/${id}`)
      .then((response) => {
        const parsedFences = response.cercas?.map((cerca) => ({
          ...cerca,
          eventos: propertyFenceValueToEventos(cerca.propriedade?.valorPropriedade || ''),
        }));
        setFenceGroup({ ...response, cercas: parsedFences || [] });
        setFences(parsedFences || []);
      })
      .catch((err) => {
        notificationController.error({ message: `Erro ao buscar cerca. ${err}` });
      })
      .finally(() => setLoading(false));
  }, [id]);

  const handleShowInactiveFences = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;

    const inactiveFences = fences.filter((fence) => !fence.ativo);
    if (isChecked && inactiveFences.length === 0) {
      notificationController.warning({ message: 'Não existem cercas inativas para esse grupo.' });
    } else {
      setShowInactiveFences(isChecked);
    }
  };

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: 'Visualizar grupo',
        handleBackClick: () => navigate('/cercas'),
      }),
    );
    dispatch(setFooter({}));

    if (Number(id)) fetchGroupData();
  }, [dispatch, fetchGroupData, id, navigate]);

  const handleCloneGroupClick = () => {
    navigate(`/cercas/clonar/${id}`);
  };

  return (
    <>
      <PageTitle>Visualizar grupo</PageTitle>
      <Spinner spinning={loading}>
        <PageContainer>
          <S.TitleContainer>
            <S.TitleInfo>
              <S.Title>
                <span>Grupo</span>
                <S.FenceGroupName>{fenceGroup?.nome}</S.FenceGroupName>
              </S.Title>
              <S.Title>
                <span>Modelo</span>
                <S.FenceGroupName>{fenceGroup?.modelo?.nome}</S.FenceGroupName>
              </S.Title>
            </S.TitleInfo>
            <Button type="primary" onClick={handleCloneGroupClick}>
              Clonar grupo
            </Button>
          </S.TitleContainer>
          <S.FenceMapWrapper>
            <Checkbox
              key={26}
              checked={showInactiveFences}
              value={showInactiveFences}
              onChange={handleShowInactiveFences}
              style={{ width: '13rem' }}
            >
              Mostrar cercas inativas
            </Checkbox>
            <MapComponentView showInactiveFences={showInactiveFences} fences={fences} />
          </S.FenceMapWrapper>

          <FencesDashboardView fenceGroupId={fenceGroup?.id || 0} fences={fences} setFences={setFences} />
        </PageContainer>
        <div style={{ height: '100px', width: '100%' }} />
      </Spinner>
    </>
  );
};
