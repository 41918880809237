import { FenceModel, LatLngLiteral } from '@app/domain/fence/fenceModel';

const isOverlap = (fence1: FenceModel, fence2: FenceModel) => {
  // Assuming idTipoCerca: 1 is Polygon, 2 is Circle, 3 is Route (Polyline)
  switch (fence1.idTipoCerca) {
    case 1: // Polygon
      if (fence2.idTipoCerca === 1) {
        return checkPolygonOverlap(fence1, fence2);
      } else if (fence2.idTipoCerca === 2) {
        return checkPolygonCircleOverlap(fence1, fence2);
      } else if (fence2.idTipoCerca === 3) {
        return checkPolygonPolylineOverlap(fence1, fence2);
      }
      break;
    case 2: // Circle
      if (fence2.idTipoCerca === 1) {
        return checkPolygonCircleOverlap(fence2, fence1);
      } else if (fence2.idTipoCerca === 2) {
        return checkCircleOverlap(fence1, fence2);
      } else if (fence2.idTipoCerca === 3) {
        return checkCirclePolylineOverlap(fence1, fence2);
      }
      break;
    case 3: // Route (Polyline)
      if (fence2.idTipoCerca === 1) {
        return checkPolygonPolylineOverlap(fence2, fence1);
      } else if (fence2.idTipoCerca === 2) {
        return checkCirclePolylineOverlap(fence2, fence1);
      } else if (fence2.idTipoCerca === 3) {
        return checkPolylineOverlap(fence1, fence2);
      }
      break;
  }

  return false;
};

// Convert pontos to LatLng google map's objects
const convertToLatLng = (points: LatLngLiteral[]): google.maps.LatLng[] => points.map((p) => new google.maps.LatLng(p));

const crossProduct = (p1: google.maps.LatLng, p2: google.maps.LatLng, p3: google.maps.LatLng) => {
  return (p2.lng() - p1.lng()) * (p3.lat() - p1.lat()) - (p2.lat() - p1.lat()) * (p3.lng() - p1.lng());
};

const doLinesIntersect = (
  a1: google.maps.LatLng,
  a2: google.maps.LatLng,
  b1: google.maps.LatLng,
  b2: google.maps.LatLng,
) => {
  const side1 = crossProduct(a1, a2, b1);
  const side2 = crossProduct(a1, a2, b2);
  const side3 = crossProduct(b1, b2, a1);
  const side4 = crossProduct(b1, b2, a2);

  return side1 * side2 < 0 && side3 * side4 < 0;
};

const checkPolygonOverlap = (polygon1: FenceModel, polygon2: FenceModel) => {
  // Convert pontos to LatLng objects
  const points1 = convertToLatLng(polygon1.pontos);
  const points2 = convertToLatLng(polygon2.pontos);

  // Function to check if line segments intersect

  // Check every edge of polygon1 against every edge of polygon2
  for (let i = 0; i < points1.length; i++) {
    const a1 = points1[i];
    const a2 = points1[(i + 1) % points1.length];

    for (let j = 0; j < points2.length; j++) {
      const b1 = points2[j];
      const b2 = points2[(j + 1) % points2.length];

      if (doLinesIntersect(a1, a2, b1, b2)) {
        return true; // Edges intersect
      }
    }
  }

  return false; // No intersection found
};

const checkCircleOverlap = (circle1: FenceModel, circle2: FenceModel) => {
  const google = window.google;

  if (!circle1.raio || !circle2.raio) return false;
  const center1 = new google.maps.LatLng(circle1.pontos[0]);
  const center2 = new google.maps.LatLng(circle2.pontos[0]);

  const distance = google.maps.geometry.spherical.computeDistanceBetween(center1, center2);

  return distance < circle1.raio + circle2.raio; // True if circles overlap
};

const checkPolygonCircleOverlap = (polygon: FenceModel, circle: FenceModel): boolean => {
  if (!circle.raio) return false;
  const google = window.google;
  const circleCenter = new google.maps.LatLng(circle.pontos[0]);

  // Check if any point of the polygon is inside the circle
  for (const point of polygon.pontos) {
    const pointLatLng = new google.maps.LatLng(point);
    if (google.maps.geometry.spherical.computeDistanceBetween(circleCenter, pointLatLng) < circle.raio) {
      return true; // A point of the polygon is inside the circle
    }
  }

  // Check if any edge of the polygon intersects with the circle
  for (let i = 0; i < polygon.pontos.length; i++) {
    const startLatLng = new google.maps.LatLng(polygon.pontos[i]);
    const endLatLng = new google.maps.LatLng(polygon.pontos[(i + 1) % polygon.pontos.length]);

    if (isSegmentIntersectCircle(startLatLng, endLatLng, circleCenter, circle.raio)) {
      return true; // An edge of the polygon intersects with the circle
    }
  }

  return false; // No overlap found
};

// Check overlap for polygon and polyline
const checkPolygonPolylineOverlap = (polygon: FenceModel, polyline: FenceModel): boolean => {
  const pointsPolygon = convertToLatLng(polygon.pontos);
  const pointsPolyline = convertToLatLng(polyline.pontos);

  for (let i = 0; i < pointsPolygon.length; i++) {
    const a1 = pointsPolygon[i];
    const a2 = pointsPolygon[(i + 1) % pointsPolygon.length];

    for (let j = 0; j < pointsPolyline.length - 1; j++) {
      const b1 = pointsPolyline[j];
      const b2 = pointsPolyline[j + 1];

      if (doLinesIntersect(a1, a2, b1, b2)) {
        return true; // An edge of the polygon intersects with a segment of the polyline
      }
    }
  }

  return false;
};

// Check overlap for circle and polyline
const checkCirclePolylineOverlap = (circle: FenceModel, polyline: FenceModel): boolean => {
  if (!circle.raio) return false;
  const center = new google.maps.LatLng(circle.pontos[0]);
  const pointsPolyline = convertToLatLng(polyline.pontos);

  for (let i = 0; i < pointsPolyline.length - 1; i++) {
    if (isSegmentIntersectCircle(pointsPolyline[i], pointsPolyline[i + 1], center, circle.raio)) {
      return true; // A segment of the polyline intersects the circle
    }
  }

  return false;
};

const isSegmentIntersectCircle = (
  p1: google.maps.LatLng,
  p2: google.maps.LatLng,
  center: google.maps.LatLng,
  radius: number,
): boolean => {
  // Check if either end of the line segment is inside the circle
  if (
    google.maps.geometry.spherical.computeDistanceBetween(p1, center) <= radius ||
    google.maps.geometry.spherical.computeDistanceBetween(p2, center) <= radius
  ) {
    return true;
  }

  // Find the closest point on the segment to the center of the circle
  const u =
    ((center.lng() - p1.lng()) * (p2.lng() - p1.lng()) + (center.lat() - p1.lat()) * (p2.lat() - p1.lat())) /
    ((p2.lng() - p1.lng()) * (p2.lng() - p1.lng()) + (p2.lat() - p1.lat()) * (p2.lat() - p1.lat()));

  // The closest point falls outside the segment, return false
  if (u < 0 || u > 1) {
    return false;
  }

  // Compute the closest point
  const closestPoint = new google.maps.LatLng(
    p1.lat() + u * (p2.lat() - p1.lat()),
    p1.lng() + u * (p2.lng() - p1.lng()),
  );

  // Check if the closest point is within the radius of the circle
  return google.maps.geometry.spherical.computeDistanceBetween(closestPoint, center) <= radius;
};

// Check overlap for two polylines
const checkPolylineOverlap = (polyline1: FenceModel, polyline2: FenceModel): boolean => {
  const pointsPolyline1 = convertToLatLng(polyline1.pontos);
  const pointsPolyline2 = convertToLatLng(polyline2.pontos);

  for (let i = 0; i < pointsPolyline1.length - 1; i++) {
    const a1 = pointsPolyline1[i];
    const a2 = pointsPolyline1[i + 1];

    for (let j = 0; j < pointsPolyline2.length - 1; j++) {
      const b1 = pointsPolyline2[j];
      const b2 = pointsPolyline2[j + 1];

      if (doLinesIntersect(a1, a2, b1, b2)) {
        return true; // A segment of polyline1 intersects with a segment of polyline2
      }
    }
  }

  return false;
};

export const doFencesOverlap = (fences: FenceModel[]) => {
  for (let i = 0; i < fences.length; i++) {
    for (let j = i + 1; j < fences.length; j++) {
      if (isOverlap(fences[i], fences[j])) {
        return true; // Overlap found
      }
    }
  }
  return false; // No overlap
};

export const isFenceOverridden = (exclusiveFence: FenceModel, otherFences: FenceModel[]) => {
  for (let i = 0; i < otherFences.length; i++) {
    if (isOverlap(exclusiveFence, otherFences[i])) {
      return true; // The exclusive fence is being overridden
    }
  }
  return false; // No overlap with the exclusive fence
};
