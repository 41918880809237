import styled from 'styled-components';
import { Button as AntButton } from '@app/components/common/buttons/Button/Button';
import { ButtonProps } from '@app/components/common/Toolbar/Toolbar';

export const Card = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid var(--neutral-gray-gray-300, #bababa);
  padding: 1rem;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
`;

export const Title = styled.h2`
  margin-bottom: 1rem;

  color: var(--neutral-gray-gray-800, #383838);
  font-family: Mulish;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const TitleDescription = styled.span`
  color: var(--neutral-gray-gray-500, #8c8c8c);
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Description = styled.span`
  color: var(--neutral-gray-gray-700, #545454);
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-bottom: 1rem;
`;

export const Row = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Sincronizar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 2rem;
`;

export const Button = styled(AntButton)<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14.75rem;

  border-radius: 0.5rem;
  border: 1px solid var(--neutral-gray-gray-500, #8c8c8c);
  background: var(--neutral-gray-pure-white, #fff);

  color: var(--neutral-gray-gray-700, #545454);
  font-family: 'Mulish';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;

  &[disabled],
  &[disabled]:active,
  &[disabled]:focus,
  &[disabled]:hover {
    border-radius: 0.5rem;
    border: 1px solid var(--neutral-gray-gray-500, #8c8c8c);
    opacity: 0.6;
    background: var(--neutral-gray-pure-white, #fff);

    color: var(--neutral-gray-gray-700, #545454);
    /* Titles/Titles-H5 */
    font-family: Mulish;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
  }

  /* cursor: default; */
`;
