import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  width: 100%;
  min-height: 20rem;
  padding: var(--Spacing-00, 0px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.063rem;
`;

export const Title = styled.div`
  color: var(--Neutral-Gray-Gray600, #707070);
  text-align: center;
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
`;

export const SubTitleBlue = styled.div`
  color: var(--Brand-Colors-Blue-Blue500, #263668);
  text-align: center;
  font-family: Mulish;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-right: 0.063rem;
`;

export const SubTitleGray = styled.div`
  color: var(--Neutral-Gray-Gray500, #8c8c8c);
  font-family: Mulish;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
`;
