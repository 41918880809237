import { FC, useCallback, useEffect, useState } from 'react';
import * as SC from '../../components.styles';
import * as S from './updates-container.styles';
import { Col, Row } from 'antd';
import { SkeletonButton } from '@app/components/common/Skeleton/Skeleton';
import { CollapsibleCard } from '../../collapsible-card/collapsible-card';
import { UpdateModel } from '@app/domain/home/updates';
import IHomeService, { HomeService } from '@app/services/homeService';
import { notificationController } from '@app/controllers/notificationController';

const homeService: IHomeService = new HomeService();

export const UpdatesContainer: FC = ({}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [updates, setUpdates] = useState<UpdateModel[]>([]);

  const fetch = useCallback(async () => {
    homeService
      .getUpdates()
      .then((res) => {
        setUpdates(res.sort((a, b) => new Date(b.dataHora).getTime() - new Date(a.dataHora).getTime()));
        setLoading(false);
      })
      .catch(() => {
        notificationController.error({
          message: 'Erro!',
          description: 'Erro ao obter os dados de equipamentos e indicadores.',
        });
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return loading ? (
    <Row>
      <SC.TitleCardHeader>
        Atualizações <SkeletonButton active size="small" shape="square" />
      </SC.TitleCardHeader>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <SkeletonButton active size="large" shape="default" block />
          </Col>
          <Col span={24}>
            <SkeletonButton active size="large" shape="default" block />
          </Col>
          <Col span={24}>
            <SkeletonButton active size="large" shape="default" block />
          </Col>
        </Row>
      </Col>
    </Row>
  ) : (
    <Row>
      <Col span={24}>
        <SC.TitleCardHeader>
          Atualizações <span>{updates.length}</span>
        </SC.TitleCardHeader>
      </Col>
      <Col span={24}>
        <S.UpdatesContainerRow gutter={[0, 8]}>
          {updates.map((u, i) => (
            <Col key={i} span={24}>
              <CollapsibleCard date={u.dataHora} title={u.titulo} subtitle={u.subtitulo} description={u.descricao} />
            </Col>
          ))}
        </S.UpdatesContainerRow>
      </Col>
    </Row>
  );
};
