import styled from 'styled-components';
import { Collapse as AntCollapse } from 'antd';
import { Panel as AntPanel } from '@app/components/common/Collapse/Collapse';

export const Card = styled(AntCollapse)`
  margin-bottom: 1rem;
  background-color: #fff;
  border: 1px solid var(--neutral-gray-gray-300, #bababa);

  .ant-collapse-header {
    margin: 0.5rem 0.5rem;
    font-size: 1.25rem;

    svg {
      font-size: 1.5rem;
      rotate: 180deg;
      transform: rotate(270deg);
    }
  }
`;

export const CardContent = styled(AntPanel)``;

export const Container = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  width: 400px;
`;

export const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const InfoBox = styled.div`
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--brand-colors-blue-blue-500, #263668);
  background: rgba(202, 210, 236, 0.6);
`;

export const TextInfo = styled.span`
  color: var(--brand-colors-blue-blue-800, #0f162a);
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  gap: 1rem;
`;
