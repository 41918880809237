import React, { FC, useCallback } from 'react';
import { FenceModel } from '@app/domain/fence/fenceModel';
import { Circle, Marker, Polygon, Polyline } from '@react-google-maps/api';

interface SavedFencesProps {
  fences: FenceModel[];
  showInactiveFences?: boolean;
}

const activeColor = {
  fillColor: '#a0e99b',
  strokeColor: '#12720e',
};

const inactiveColor = {
  fillColor: 'rgba(162,157,157,0.99)',
  strokeColor: '#808080',
};

export const SavedFences: FC<SavedFencesProps> = ({ fences, showInactiveFences }) => {
  const fencesToRender = showInactiveFences ? fences : fences.filter((fence) => fence.ativo);

  const renderPolygon = useCallback((_fence: FenceModel) => {
    return (
      <>
        <Polygon
          key={_fence.id}
          path={_fence.pontos}
          options={{
            fillColor: _fence.ativo ? activeColor.fillColor : inactiveColor.fillColor,
            fillOpacity: 0.5,
            strokeColor: _fence.ativo ? activeColor.strokeColor : inactiveColor.strokeColor,
            strokeOpacity: 1,
            strokeWeight: 2,
          }}
        />
        {/* Marks from Polygon */}
        {_fence.pontos.map((ponto, index) => {
          return (
            <Marker
              key={index}
              position={ponto}
              icon={{
                path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
                fillColor: _fence.ativo ? activeColor.strokeColor : inactiveColor.strokeColor,
                fillOpacity: 1.0,
                scale: 1,
                strokeColor: _fence.ativo ? activeColor.strokeColor : inactiveColor.strokeColor,
                strokeWeight: 1,
              }}
            />
          );
        })}
      </>
    );
  }, []);

  const renderCircle = useCallback((_fence: FenceModel) => {
    return (
      <>
        <Circle
          key={_fence.id}
          center={_fence.pontos[0]}
          radius={_fence.raio}
          options={{
            fillColor: _fence.ativo ? activeColor.fillColor : inactiveColor.fillColor,
            fillOpacity: 0.5,
            strokeColor: _fence.ativo ? activeColor.strokeColor : inactiveColor.strokeColor,
            strokeOpacity: 1,
            strokeWeight: 2,
          }}
        />
        {/* Marks from Circle */}
        {_fence.pontos.map((ponto, index) => {
          return (
            <Marker
              key={index}
              position={ponto}
              icon={{
                path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
                fillColor: _fence.ativo ? activeColor.strokeColor : inactiveColor.strokeColor,
                fillOpacity: 1.0,
                scale: 1,
                strokeColor: _fence.ativo ? activeColor.strokeColor : inactiveColor.strokeColor,
                strokeWeight: 1,
              }}
            />
          );
        })}
      </>
    );
  }, []);

  const renderPolyline = useCallback((_fence: FenceModel) => {
    return (
      <>
        <Polyline
          key={_fence.id}
          path={_fence.pontos}
          options={{
            strokeColor: _fence.ativo ? activeColor.strokeColor : inactiveColor.strokeColor,
            strokeOpacity: 1,
            strokeWeight: 2,
          }}
        />
        {/* Marks from Polyline */}
        {_fence.pontos.map((ponto, index) => {
          return (
            <Marker
              key={index}
              position={ponto}
              icon={{
                path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
                fillColor: _fence.ativo ? activeColor.strokeColor : inactiveColor.strokeColor,
                fillOpacity: 1.0,
                scale: 1,
                strokeColor: _fence.ativo ? activeColor.strokeColor : inactiveColor.strokeColor,
                strokeWeight: 1,
              }}
            />
          );
        })}
      </>
    );
  }, []);

  const renderFence = () => {
    return fencesToRender.map((_fence) => {
      if (_fence.idTipoCerca === 1) return renderPolygon(_fence);

      if (_fence.idTipoCerca === 2) return renderCircle(_fence);

      if (_fence.idTipoCerca === 3) return renderPolyline(_fence);

      return null;
    });
  };

  return <>{renderFence()}</>;
};
