/* eslint-disable react-hooks/exhaustive-deps */
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useCallback, useEffect, useState } from 'react';
import { Description } from '@app/components/common/Description/Description';
import {
  CardInfoContainer,
  CardInfoProperty,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Col, Row } from 'antd';
import { DividerLine } from '@app/components/common/divider/DividerLine.styles';
// import { dispositivosImeis, scheduleConfigUpdates } from './mocks';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setFooter } from '@app/store/slices/footerSlice';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import IDeviceConfigUpdateService, { DeviceConfigUpdateService } from '@app/services/deviceConfigUpdateService';
import { notificationController } from '@app/controllers/notificationController';
import { ConfigUpdateModel } from '@app/domain/deviceConfigUpdate/deviceConfigUpdate';
// import { DeviceConfigUpdateStatus } from '@app/constants/enums/device/device-config-update-status';
import { ReactComponent as IconConcluded } from '@app/assets/icons/check_circle.svg';
import { ReactComponent as IconUpdating } from '@app/assets/icons/autorenew.svg';
import { ReactComponent as IconReport } from '@app/assets/icons/report_off.svg';
import moment from 'moment';
import { Tooltip } from '@app/components/common/Tooltip/Tooltip';
import { DescriptionDetails } from './components/description/DescriptionDetails';
import * as S from '../device-config-update/components/Modules/modules.styles';
import { Panel } from '@app/components/common/Collapse/Collapse';
import { groupBy } from 'lodash';

const configUpdateService: IDeviceConfigUpdateService = new DeviceConfigUpdateService();

export const DeviceConfigUpdateView: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [configUpdate, setConfigUpdate] = useState<ConfigUpdateModel>({} as ConfigUpdateModel);

  const fetchDevice = useCallback(async () => {
    try {
      setLoading(true);
      const response = await configUpdateService.get(`${id}`);
      setConfigUpdate(response);
      // setConfigUpdate(scheduleConfigUpdates.find((c) => c.id === parseInt(id ?? '0')) ?? ({} as ConfigUpdateModel));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um erro ao tentar buscar os dados da atualização.',
      });
    }
  }, []);

  useEffect(() => {
    dispatch(setFooter({}));
    dispatch(
      setHeaderRegister({
        title: 'Visualizar configuração remota',
        handleBackClick: () => navigate('/atualizacao-configuracao-dispositivo'),
      }),
    );
    fetchDevice();
  }, [dispatch, navigate]);

  const renderModuleUpdateTitle = (name: string) => {
    switch (name) {
      case 'PerfilEnvio':
        return 'Perfil de envio';
      case 'CAN':
        return 'Veículos CAN';
      default:
        return name;
    }
  };

  const legend = (
    <DescriptionDetails title="Legenda">
      <Row gutter={18}>
        <Col>
          <Row>
            <Col>
              <IconConcluded style={{ marginRight: '0.5rem' }} />
            </Col>
            <Col>
              <S.TableQuantity style={{ marginTop: '0.180rem' }}>Atualizado</S.TableQuantity>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <IconUpdating style={{ marginRight: '0.5rem' }} />
            </Col>
            <Col>
              <S.TableQuantity style={{ marginTop: '0.180rem' }}>Pendente</S.TableQuantity>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <IconReport style={{ marginRight: '0.5rem' }} />
            </Col>
            <Col>
              <S.TableQuantity style={{ marginTop: '0.180rem' }}>Expirado</S.TableQuantity>
            </Col>
          </Row>
        </Col>
      </Row>
    </DescriptionDetails>
  );

  return (
    <>
      <Spinner spinning={loading}>
        <PageContainer>
          <CardInfoContainer style={{ marginBottom: '3rem' }}>
            <Description title="Informações do agendamento" />
            <Row gutter={24}>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Equipamento</CardInfoProperty>
                <CardInfoValue>{configUpdate.tipoEquipamento}</CardInfoValue>
              </Col>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Modelo</CardInfoProperty>
                <CardInfoValue>{configUpdate.modelo}</CardInfoValue>
              </Col>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Status</CardInfoProperty>
                <CardInfoValue>{configUpdate.status}</CardInfoValue>
              </Col>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Data/Hora</CardInfoProperty>
                <CardInfoValue>{moment(configUpdate.dataHora).format('DD/MM/YYYY HH:mm') + 'h'}h</CardInfoValue>
              </Col>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Usuário responsável</CardInfoProperty>
                <CardInfoValue>{configUpdate.usuario}</CardInfoValue>
              </Col>
            </Row>
            <DividerLine />
            <Description title="Dispositivos" />
            {configUpdate?.dispositivos?.map((device) => (
              <div key={configUpdate.dispositivos?.map((d) => d.id ?? '').join('_')} style={{ marginBottom: '0.5rem' }}>
                <S.Collapse>
                  <Panel
                    key={device.id}
                    header={
                      <Row align={'middle'} justify={'space-between'}>
                        <Col>
                          <S.CollapseHeaderName>{`SN  ${device.numeroSerie}`}</S.CollapseHeaderName>
                        </Col>
                      </Row>
                    }
                  >
                    <Row>
                      <Col xs={24} md={24} lg={24} xxl={24}>
                        {legend}
                      </Col>
                      <Col xs={24} md={24} lg={24} xxl={24}>
                        {Object.keys(groupBy(configUpdate.modulosAtualizados)).map((name) => {
                          const configUpdateFiltered = device.propriedadesAtualizadas?.filter(
                            (property) => property.modulo === name,
                          );
                          return (
                            <div key={name} style={{ paddingBottom: '1rem' }}>
                              <Row>
                                <S.TableTypeName>{`Parâmetros de ${renderModuleUpdateTitle(name)}`}</S.TableTypeName>
                              </Row>
                              <Row gutter={[18, 18]} style={{ overflowY: 'auto', maxHeight: '30rem', width: '100%' }}>
                                {configUpdateFiltered?.map((item, index) => (
                                  <Col key={index} xs={12} xl={6}>
                                    <CardInfoValue style={{ display: 'flex' }}>
                                      <Tooltip content={`Valor da Propriedade: ${item.valorPropriedade}`}>
                                        <S.TableQuantity>{item.parametro}</S.TableQuantity>
                                      </Tooltip>
                                      {configUpdate.status === 'Expirado' ? (
                                        <IconReport style={{ marginLeft: '1rem' }} />
                                      ) : item.atualizado ? (
                                        <IconConcluded style={{ marginLeft: '1rem' }} />
                                      ) : (
                                        <IconUpdating style={{ marginLeft: '1rem' }} />
                                      )}
                                    </CardInfoValue>
                                  </Col>
                                ))}
                              </Row>
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                  </Panel>
                </S.Collapse>
              </div>
            ))}
          </CardInfoContainer>
        </PageContainer>
      </Spinner>
    </>
  );
};
