import { InfoCircleOutlined } from '@ant-design/icons';
import { Description } from '@app/components/common/Description/Description';
import { Modal } from '@app/components/common/Modal/Modal';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { Select } from '@app/components/common/selects/Select/Select';
import { UserType } from '@app/constants/enums/userType';
import { emailPattern } from '@app/constants/patterns';
import { userTypes } from '@app/constants/userTypes';
import { notificationController } from '@app/controllers/notificationController';
import { ClientModel } from '@app/domain/client/clientModel';
import { RoleModel } from '@app/domain/identity/roleModel';
import { UserClaimModel } from '@app/domain/identity/userClaimModel';
import { UserModel } from '@app/domain/identity/userModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IAuthService, { AuthService } from '@app/services/authService';
import IClientService, { ClientService } from '@app/services/clientService';
import IRoleIdentityService, { RoleIdentityService } from '@app/services/identity/roleIdentityService';
import IUserIdentityService, { UserIdentityService } from '@app/services/identity/userIdentityService';
import { setFooter } from '@app/store/slices/footerSlice';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Col, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface UserModelValid {
  username?: boolean;
  email?: boolean;
  idClient?: boolean;
}

const phoneMask = (value: string) => {
  if (!value) return '';
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
};

const userService: IUserIdentityService = new UserIdentityService();
const roleService: IRoleIdentityService = new RoleIdentityService();
const clientService: IClientService = new ClientService();
const authService: IAuthService = new AuthService();

export const UserCreate: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [oldStatusUser, setOldStatusUser] = useState(false);
  const [oldUserClaim, setOldUserClaim] = useState<UserClaimModel>();
  const [user, setUser] = useState<UserModel>({ lockoutEnabled: true } as UserModel);
  const [userValid, setUserValid] = useState<UserModelValid>({ idClient: true });
  const [userType, setUserType] = useState<string>(UserType.FactoryAdmin);
  const [isBeta, setIsBeta] = useState<boolean>(false);
  const [idClient, setIdClient] = useState<number>();
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);

  const handleBack = () => navigate('/usuarios');
  const handleOnKeyDownUsername = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code == 'Space' && (user.userName?.includes('.') || !user?.userName)) e.preventDefault();
    if (e.key == '.' && (user.userName?.includes('.') || !user?.userName)) e.preventDefault();
    if (/(?=.*[0-9]).*$/.test(e.key) || (/\W/.test(e.key) && e.code != 'Space' && e.key != '.')) e.preventDefault();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChangeUser = (name: string, value: any) => {
    switch (name) {
      case 'email':
        setUserValid({ ...userValid, email: emailPattern.test(value) ? true : false });
        setUser({ ...user, email: value });
        break;
      case 'username':
        setUserValid({ ...userValid, username: value?.length >= 8 ? true : false });
        value = value.toLowerCase().replace(' ', '.');
        if (value != '.') setUser({ ...user, userName: value });
        break;
      case 'type':
        setUserType(value);

        if (value == UserType.Client) {
          setUserValid({ ...userValid, idClient: false });
        } else {
          setUserValid({ ...userValid, idClient: true });
          setIdClient(undefined);
        }
        break;
      case 'idClient':
        setIdClient(value);
        setUserValid({ ...userValid, idClient: value ? true : false });
        break;
      default:
        setUser({ ...user, [name]: value });
        break;
    }
  };

  //user functions
  const createUser = async () => {
    let userResponse;

    try {
      userResponse = await userService.post('', user);

      await createRole(userResponse.id ?? '');

      if (userType == UserType.Client)
        idClient && (await createUserClaim(userResponse.id as string, 'idClient', idClient.toString()));
      if (isBeta) idClient && (await createUserClaim(userResponse.id as string, 'beta', '1'));
      if (user.lockoutEnabled) await authService.api.post(`confirmar-acesso/${userResponse.id}`);

      setUser(userResponse);
    } catch (error) {
      if (userResponse) {
        await userService.delete(`${userResponse.id}`);
        setUser({ ...user, id: undefined });
      }
      throw error;
    }
  };
  const updateUser = async () => {
    await userService.update('', user);
    await updateRole(user.id ?? '');

    if (!oldStatusUser && user.lockoutEnabled) await authService.api.post(`confirmar-acesso/${user.id}`);

    const isBetaClaim = await getUserClaim(user.id as string, 'beta');

    if (isBeta && !isBetaClaim) {
      await createUserClaim(user.id as string, 'beta', '1');
    } else if (!isBeta && isBetaClaim) {
      await deleteUserUserClaim(isBetaClaim.claimId);
    }

    if (userType === UserType.Client && oldUserClaim && oldUserClaim?.claimValue !== idClient?.toString()) {
      await updateUserClaim(user.id as string);
    } else if (userType === UserType.Client && !oldUserClaim) {
      idClient && (await createUserClaim(user.id as string, 'idClient', idClient.toString()));
    } else if (userType !== UserType.Client && oldUserClaim) {
      await deleteUserUserClaim(oldUserClaim.claimId);
    }
  };

  //claims functions
  const deleteUserUserClaim = async (claimId: number) => {
    await userService.api.delete(`${user.id}/claims?claimId=${claimId}`);
  };
  const createUserClaim = async (userId: string, type: string, value: string) => {
    await userService.api.post('claims', { userId: userId, claimType: type, claimValue: value });
  };
  const updateUserClaim = async (userId: string) => {
    const userClaim = await getUserClaim(userId, 'idClient');
    await userService.api.put('claims', { ...userClaim, claimValue: idClient?.toString() });
  };
  const getUserClaim = async (userId: string, type: string) => {
    const claims = (await userService.api.get(`${userId}/claims`)).data.claims as UserClaimModel[];
    return claims.find((c) => c.claimType == type);
  };
  const getUserClaims = async (userId: string): Promise<UserClaimModel[] | undefined> => {
    return (await userService.api.get(`${userId}/claims`)).data.claims as UserClaimModel[];
  };

  //role functions
  const createRole = async (userId: string) => {
    try {
      const roles = (await roleService.api.get(''))?.data.roles as RoleModel[];
      const roleSelected = roles.find((r: RoleModel) => r.name === userType);

      if (!roleSelected) throw Error('Não encontrado perfil para efetuar o vínculo');

      await userService.api.post(`roles`, { userId: userId, roleId: roleSelected.id });
    } catch (error) {
      throw error;
    }
  };
  const updateRole = async (userId: string) => {
    try {
      const userRoles = (await userService.api.get(`${user.id}/roles`))?.data.roles as RoleModel[];
      const existsRoleOnUser = userRoles.find((r: RoleModel) => r.name === userType) ? true : false;

      if (existsRoleOnUser) return;

      for (const role of userRoles as RoleModel[]) {
        await userService.api.delete(`roles`, { data: { userId: userId, roleId: role.id } });
      }

      await createRole(userId);
    } catch (error) {
      throw error;
    }
  };

  //external controllers
  const handleSave = async () => {
    try {
      setLoading(true);

      if (!id) {
        await createUser();
      } else {
        await updateUser();
      }

      notificationController.success({
        message: `${
          id
            ? 'Atualização realizada com sucesso!'
            : 'Cadastro realizado com sucesso! E-mail enviado com sucesso! Por favor peça ao usuário verificar a caixa de Spam.'
        }`,
        duration: id ? 2 : 20,
      });

      handleBack();
    } catch (error) {
      notificationController.error({
        message: `Falha ao ${id ? 'atualizar' : 'cadastrar'} o usuário!`,
        description: `Usuário: ${user.userName}`,
      });
      setLoading(false);
    }
  };
  const fetchClients = async () => {
    try {
      const clientsResponse = await clientService.getArray('');
      setClients(clientsResponse);
    } catch (error) {
      notificationController.error({ message: 'Erro ao buscar a lista de clientes' });
    }
  };
  const loadData = async () => {
    try {
      setLoading(true);

      const userResponse = await userService.get(`${id}`);
      const userRoles = (await userService.api.get(`${id}/roles`))?.data.roles as RoleModel[];
      const userRole = userRoles?.find((r: RoleModel) => userTypes.find((t) => t.value === r.name));
      const claims = await getUserClaims(`${id}`);

      if (!userResponse) throw Error('Erro ao buscar usuário');

      await fetchClients();

      const isBetaClaim = claims?.find((c) => c.claimType == 'beta') ? true : false;

      if (userRole?.name == UserType.Client) {
        const userClaim = claims?.find((c) => c.claimType == 'idClient');
        setOldUserClaim(userClaim);
        setIdClient(parseInt(userClaim?.claimValue as string));
      }

      setUser(userResponse);
      setIsBeta(isBetaClaim);
      setUserType(userRole?.name ?? '');
      setOldStatusUser(userResponse.lockoutEnabled);
      setUserValid({ username: true, email: true, idClient: true });

      setLoading(false);
    } catch (error) {
      notificationController.error({ message: 'Erro ao buscar o usuário' });
      setLoading(false);
    }
  };

  //effects
  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: !id ? 'Novo usuário' : 'Editar usuário',
        handleBackClick: handleBack,
      }),
    );

    if (id) {
      loadData();
    } else {
      fetchClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let btnSaveDisabled = !userValid?.email || !userValid?.username;
    if (userType === UserType.Client)
      btnSaveDisabled = !userValid?.email || !userValid?.username || !userValid.idClient;

    dispatch(
      setFooter({
        confirmButtonDisabled: btnSaveDisabled,
        confirmButtonText: id ? 'Salvar' : 'Cadastrar',
        handleCancelButtonClick: () => setModalCancelVisible(true),
        handleConfirmButtonClick: handleSave,
        cancelButtonText: 'Cancelar',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userValid, userType, user, isBeta]);

  return (
    <>
      <Modal
        title={`Cancelar ${id ? 'edição' : 'cadastro'} do usuário`}
        open={modalCancelVisible}
        onOk={handleBack}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            Deseja realmente cancelar {id ? 'a edição' : 'o cadastro'} do usuário?
          </Col>
        </Row>
      </Modal>
      <PageContainer>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <SpinnerSlump spinning={loading}>
            <Description title="Informações do usuário" subtitle="Preencha os campos para cadastrar um novo usuário">
              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="Usuário"
                    supportText="Utilizar “.” para separar nome e sobrenome. Exemplo: nome.sobrenome"
                    isSuccess={userValid?.username}
                    errorText="Utilizar “.” para separar nome e sobrenome. Exemplo: nome.sobrenome"
                    successText="Utilizar “.” para separar nome e sobrenome. Exemplo: nome.sobrenome"
                  >
                    <Input
                      placeholder="Informe o nome do usuário"
                      value={user?.userName}
                      disabled={id ? true : false}
                      onChange={(e) => handleOnChangeUser('username', e.currentTarget.value)}
                      onKeyDown={handleOnKeyDownUsername}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="E-mail" supportText="" errorText="Campo inválido" successText="">
                    <Input
                      disabled={id ? true : false}
                      placeholder="Informe o e-mail"
                      value={user?.email}
                      onChange={(e) => handleOnChangeUser('email', e.currentTarget.value)}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Telefone" supportText="" errorText="Campo inválido" successText="">
                    <Input
                      type="tel"
                      placeholder="Digite o telefone"
                      value={phoneMask(user.phoneNumber)}
                      maxLength={15}
                      onChange={(e) => handleOnChangeUser('phoneNumber', e.currentTarget.value)}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Escolha o tipo do usuário">
                    <RadioGroup value={userType} onChange={(e) => handleOnChangeUser('type', e.target.value)}>
                      <Space size={8} direction="vertical">
                        {userTypes.map((type, index) => (
                          <Radio key={index} value={type.value}>
                            {type.name}
                          </Radio>
                        ))}
                      </Space>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
              </Row>
              {userType === UserType.Client && (
                <Row style={{ marginBottom: '2rem' }}>
                  <Col xs={24} md={8}>
                    <Select
                      showArrow
                      showSearch
                      placeholder="Selecione o cliente"
                      value={idClient}
                      disabled={id ? true : false}
                      style={{ width: '100%' }}
                      onChange={(value) => handleOnChangeUser('idClient', value)}
                      options={clients.map((model) => ({
                        value: model.id,
                        label: model.razaoSocial,
                      }))}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Status do perfil">
                    <RadioGroup
                      value={user.lockoutEnabled}
                      onChange={(e) => handleOnChangeUser('lockoutEnabled', e.target.value)}
                    >
                      <Space size={8} direction="vertical">
                        <Radio value={true}>Ativo</Radio>
                        <Radio value={false}>Inativo</Radio>
                      </Space>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Beta">
                    <RadioGroup value={isBeta} onChange={() => setIsBeta(!isBeta)}>
                      <Space size={8} direction="vertical">
                        <Radio value={true}>Sim</Radio>
                        <Radio value={false}>Não</Radio>
                      </Space>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
          </SpinnerSlump>
        </BaseForm>
      </PageContainer>
    </>
  );
};
