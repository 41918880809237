import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from '@app/components/common/Modal/Modal';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as S from './create.styles';
import { setFooter } from '@app/store/slices/footerSlice';
import { notificationController } from '@app/controllers/notificationController';
import { ModelDeviceModel } from '@app/domain/modelDevice/modelDeviceModel';
import {
  GroupedDevicesByClient,
  IModalClientEquipment,
  ScheduleParams,
} from '@app/domain/scheduleFactory/scheduleParams';
import { EquipmentModel } from '@app/domain/equipment/equipmentModel';
import { EquipmentService } from '@app/services/equipmentService';
import { ModelDeviceService } from '@app/services/modelDeviceService';
import { FirmwareVersionService } from '@app/services/firmwareVersionService';
import { FirmwareVersionModel } from '@app/domain/firmwareVersion/firmwareVersionModel';
import { ScheduleFactoryService } from '@app/services/scheduleFactoryService';
import { ModalClienteEquipamento } from '@app/pages/factory-schedule/create/components/ModalClienteEquipamento/ModalClienteEquipamento';
import { ScheduleConfig } from '@app/pages/factory-schedule/create/components/ScheduleConfig/ScheduleConfig';
import { ListDevicesDashboards } from '@app/pages/factory-schedule/create/components/ListDevicesDashboards/ListDevicesDashboards';
import { ScheduleFactoryModel } from '@app/domain/scheduleFactory/scheduleFactoryModel';
import { readUser } from '@app/services/localStorage.service';
import dayjs from 'dayjs';

const scheduleService = new ScheduleFactoryService();
const equipmentTypeService = new EquipmentService();
const modelDeviceService = new ModelDeviceService();
const firmwareVersionService = new FirmwareVersionService();

export const FactoryScheduleCreate: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const [modalConfirmSave, setModalConfirmSave] = useState(false);
  const [modalClientEquipment, setModalClientEquipment] = useState<IModalClientEquipment>({
    visible: false,
    title: 'Adicionar clientes e equipamentos',
  });
  const [factoryDevicesByClient, setFactoryDevicesByClient] = useState<GroupedDevicesByClient>({});
  const [scheduleParams, setScheduleParams] = useState<ScheduleParams>({
    atualizarAgora: false,
  });
  const [equipmentTypesOptions, setEquipmentTypesOptions] = useState<EquipmentModel[]>([]);
  const [modelOptions, setModelOptions] = useState<ModelDeviceModel[]>([]);
  const [versionsOptions, setVersionsOptions] = useState<FirmwareVersionModel[]>([]);
  const getUser = JSON.parse(localStorage.getItem('user') ?? '{}');

  const fetchEquipmentTypesOptions = useCallback(() => {
    return equipmentTypeService
      .getArray('')
      .then((res: EquipmentModel[]) => {
        setEquipmentTypesOptions(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });
  }, []);

  const fetchModelOptions = useCallback(() => {
    return modelDeviceService
      .getArray('')
      .then((res: ModelDeviceModel[]) => {
        setModelOptions(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });
  }, []);

  const fetchFirmwareVersionByIdModelo = useCallback((idModelo: number) => {
    return firmwareVersionService
      .getArray(`/obter-versao-por-modelo/${idModelo}`)
      .then((res) => {
        setVersionsOptions(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });
  }, []);

  const fetchComboData = useCallback(async () => {
    setLoading(true);

    try {
      // fetchClientsOptions();
      await Promise.all([fetchEquipmentTypesOptions(), fetchModelOptions()]);
    } catch (error) {
      notificationController.error({
        message: 'Erro ao buscar dados',
      });
    } finally {
      setLoading(false);
    }
  }, [fetchEquipmentTypesOptions, fetchModelOptions]);

  const handleCancelRegister = () => {
    setFactoryDevicesByClient([]);
    setModalCancel(false);
    navigate(`/agendamento-versao`);
  };

  const handleBackClick = () => {
    if (!id) {
      navigate(`/agendamento-versao`);
    } else {
      navigate(`/dispositivos-fabrica`);
    }
  };

  const saveSchedule = async () => {
    const { idVersaoFirmware, dataHoraProgramada, versao, dispositivos, atualizarAgora, deltaOTA } = scheduleParams;
    const loggedUser = readUser();

    const dtoObject = {
      idVersaoFirmware,
      usuarioResponsavel: loggedUser?.userName,
      dataHoraProgramada: atualizarAgora ? dayjs() : dataHoraProgramada,
      versao,
      dispositivos,
      atualizarAgora,
      idCliente: getUser.idCliente ?? 1,
      deltaOTA,
    };

    setLoading(true);
    scheduleService
      .post('', dtoObject as unknown as ScheduleFactoryModel)
      .then(() => {
        notificationController.success({ message: 'Agendamento salvo com sucesso!' });
        navigate(`/agendamento-versao`);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: 'Nova atualização de firmware',
        handleBackClick: handleBackClick,
      }),
    );
    fetchComboData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scheduleParams.idModelo) fetchFirmwareVersionByIdModelo(scheduleParams.idModelo);

    // limpa os campos sempre que modelo selecionado mudar
    setFactoryDevicesByClient([]);
    setScheduleParams((prevState) => ({
      ...prevState,
      idVersaoFirmware: undefined,
      versao: '',
      dispositivos: [],
      idsClientes: [],
    }));
  }, [fetchFirmwareVersionByIdModelo, scheduleParams.idModelo]);

  useEffect(() => {
    // limpa os campos sempre que equipamento selecionado mudar
    setFactoryDevicesByClient([]);
    setScheduleParams((prevState) => ({
      ...prevState,
      idModelo: undefined,
      idVersaoFirmware: undefined,
      versao: '',
      dispositivos: [],
      idsClientes: [],
    }));
  }, [scheduleParams.idEquipamento]);

  const canSave = useMemo(() => {
    return (
      Number(scheduleParams?.dispositivos?.length) > 0 &&
      Object.entries(factoryDevicesByClient).length > 0 &&
      (scheduleParams.atualizarAgora || !!scheduleParams.dataHoraProgramada) &&
      (scheduleParams.dataHoraProgramada?.length === 24 || scheduleParams.atualizarAgora)
    );
  }, [
    factoryDevicesByClient,
    scheduleParams.atualizarAgora,
    scheduleParams.dataHoraProgramada,
    scheduleParams?.dispositivos?.length,
  ]);

  useEffect(() => {
    dispatch(
      setFooter({
        confirmButtonDisabled: !canSave,
        confirmButtonText: 'Salvar',
        handleCancelButtonClick: () => setModalCancel(true),
        handleConfirmButtonClick: () => setModalConfirmSave(true),
        cancelButtonText: 'Cancelar',
      }),
    );
  }, [canSave, dispatch]);

  return (
    <>
      <ModalClienteEquipamento
        title={modalClientEquipment.title}
        open={modalClientEquipment.visible}
        selectedClientId={modalClientEquipment.selectedClientId}
        setFactoryDevicesByClient={setFactoryDevicesByClient}
        onCancel={() => setModalClientEquipment({ visible: false, title: 'Adicionar clientes e equipamentos' })}
        scheduleParams={scheduleParams}
        setScheduleParams={setScheduleParams}
      />

      <Modal
        title="Cancelar agendamento"
        open={modalCancel}
        onOk={() => handleCancelRegister()}
        onCancel={() => setModalCancel(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            <span>
              Deseja realmente <strong> cancelar o agendamento? </strong>
            </span>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Agendar atualização"
        open={modalConfirmSave}
        onOk={() => saveSchedule()}
        onCancel={() => setModalConfirmSave(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            Confirmar agendamento de atualização de versão para todos os dispositivos listados?
          </Col>
        </Row>
      </Modal>

      <Spinner spinning={loading}>
        <S.Wrapper>
          <ScheduleConfig
            scheduleParams={scheduleParams}
            setScheduleParams={setScheduleParams}
            equipmentTypesOptions={equipmentTypesOptions}
            modelOptions={modelOptions}
            versionsOptions={versionsOptions}
            setModalClientEquipment={setModalClientEquipment}
          />

          <ListDevicesDashboards
            factoryDevicesByClient={factoryDevicesByClient}
            setModalClientEquipment={setModalClientEquipment}
          />
        </S.Wrapper>
      </Spinner>
    </>
  );
};
