import React, { useState } from 'react';
import { Modal } from '@app/components/common/Modal/Modal';
import { CanVehicleParametersCalculatedModel, CanVehicleParametersOnOffModel } from '@app/domain/canVehicle/canVehicle';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Col, Row } from 'antd';
import { CardInfoTitle, CardInfoValue } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';

interface ISendCommandToDeviceModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedParametersCalculated: React.Dispatch<React.SetStateAction<CanVehicleParametersCalculatedModel[]>>;
  setSelectedParametersOnOff: React.Dispatch<React.SetStateAction<CanVehicleParametersOnOffModel[]>>;
  parametersCalculated: CanVehicleParametersCalculatedModel[];
  parametersOnOff: CanVehicleParametersOnOffModel[];
}

const ModalParametersCan: React.FC<ISendCommandToDeviceModalProps> = ({
  visible,
  setVisible,
  setSelectedParametersCalculated,
  setSelectedParametersOnOff,
  parametersCalculated,
  parametersOnOff,
}) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);

  const handleCheckboxChange = (nomeId: string) => {
    if (selectedCheckboxes.includes(nomeId)) {
      setSelectedCheckboxes(selectedCheckboxes.filter((item) => item !== nomeId));
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, nomeId]);
    }
  };

  const handleConfirmButtonClick = () => {
    const selectedParametersCalculated = selectedCheckboxes
      .filter((id) => parametersCalculated.some((param) => param.nomeId.toString() === id))
      .map((id) => {
        const selectedParam = parametersCalculated.find((param) => param.nomeId.toString() === id);
        return selectedParam ? selectedParam : null;
      })
      .filter((item) => item !== null) as CanVehicleParametersCalculatedModel[];

    const selectedParametersOnOff = selectedCheckboxes
      .filter((id) => parametersOnOff.some((param) => param.nomeId.toString() === id))
      .map((id) => {
        const selectedParam = parametersOnOff.find((param) => param.nomeId.toString() === id);
        return selectedParam ? selectedParam : null;
      })
      .filter((item) => item !== null) as CanVehicleParametersOnOffModel[];

    setSelectedParametersCalculated(selectedParametersCalculated);
    setSelectedParametersOnOff(selectedParametersOnOff);
    setVisible(false);
  };

  return (
    <>
      <Modal
        title={`Parâmetros da CAN`}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '0.25rem' }}>
            <Button
              type="ghost"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              disabled={selectedCheckboxes.length === 0}
              loading={false}
              onClick={handleConfirmButtonClick}
            >
              Confirmar
            </Button>
          </div>
        }
      >
        <CardInfoTitle>Selencione os parâmetros</CardInfoTitle>
        <Row gutter={18} style={{ marginBottom: '0.5rem' }}>
          <Col xs={24} sm={24} md={24}>
            <CardInfoValue>Calculado</CardInfoValue>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {parametersCalculated.map((item) => (
            <Col key={item.nomeId} xs={24} md={8} lg={8}>
              <Checkbox
                checked={selectedCheckboxes.includes(item.nomeId.toString())}
                onChange={() => handleCheckboxChange(item.nomeId.toString())}
              >
                {item.nomeCAN}
              </Checkbox>
            </Col>
          ))}
        </Row>
        <Row gutter={18} style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
          <Col xs={24} sm={24} md={24}>
            <CardInfoValue>On/Off</CardInfoValue>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {parametersOnOff.map((item) => (
            <Col key={item.nomeId} xs={24} md={8} lg={8}>
              <Checkbox
                checked={selectedCheckboxes.includes(item.nomeId.toString())}
                onChange={() => handleCheckboxChange(item.nomeId.toString())}
              >
                {item.nomeCAN}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export default ModalParametersCan;
