import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Col, Divider, Row, Select } from 'antd';
import * as S from './modal-add-devices.styles';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';
import { Modal } from '@app/components/common/Modal/Modal';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { ModelDeviceModel } from '@app/domain/modelDevice/modelDeviceModel';
import IModelDeviceService, { ModelDeviceService } from '@app/services/modelDeviceService';
import { DeviceBlockModelDevices } from '@app/domain/deviceBlock/device-block-model';

interface IModalAddDevices {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIdsDevice: (devices: DeviceBlockModelDevices[]) => void;
  devices: FactoryDeviceModel[];
  blockDevices?: DeviceBlockModelDevices[];
  idClient: number;
  isEdit?: boolean;
}

const modelService: IModelDeviceService = new ModelDeviceService();

export const ModalAddDevices: React.FC<IModalAddDevices> = (props) => {
  const { visible, setVisible, setIdsDevice, devices, blockDevices, idClient, isEdit } = props;
  const [models, setModels] = useState<ModelDeviceModel[]>([]);
  const [devicesSelected, setDevicesSelected] = useState<DeviceBlockModelDevices[]>([]);
  const [selectedModel, setSelectedModel] = useState<number | undefined>(undefined);
  const [showAllDevices, setShowAllDevices] = useState(false);

  const handleSave = () => {
    setIdsDevice(devicesSelected);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSelectAllDevices = (checked: boolean) => {
    if (checked) {
      setDevicesSelected(
        filteredDevices.map(
          (device) =>
            ({
              idDispositivo: device.id,
              numeroSerie: device.numeroSerie,
              modelo: device.modelo,
            }) as DeviceBlockModelDevices,
        ),
      );
    } else {
      setDevicesSelected([]);
    }
  };

  const handleCheckboxDeviceChange = (checked: boolean, item: FactoryDeviceModel | DeviceBlockModelDevices) => {
    if (checked) {
      setDevicesSelected((prevSelected) => [
        ...prevSelected,
        {
          idDispositivo: item.id,
          numeroSerie: item.numeroSerie,
          modelo: item.modelo,
        } as DeviceBlockModelDevices,
      ]);
    } else {
      setDevicesSelected((prevSelected) => prevSelected.filter((p) => p.numeroSerie !== item.numeroSerie));
    }
  };

  const handlerOnDeselectDevice = async (value: number) =>
    setDevicesSelected(devicesSelected.filter((d) => d.idDispositivo != value));

  const handlerOnSelectDevice = async (value: number) => {
    const device = devices.find((d) => d.id === value);

    if (device) {
      setDevicesSelected((prevSelected) => [
        ...prevSelected,
        {
          idDispositivo: device.id,
          numeroSerie: device.numeroSerie,
          modelo: device.modelo,
        } as DeviceBlockModelDevices,
      ]);
    }
  };

  const getDeviceOptions = () => {
    return devices.map((device) => ({
      value: device.id,
      label: device.numeroSerie,
    }));
  };

  const fetchModelByClient = useCallback(async () => {
    const modelsResponse = await modelService.getArray('');
    const filteredModels = modelsResponse.filter((model) =>
      devices.some((device) => device.idModelo === model.id && device.idCliente === idClient),
    );
    setModels(filteredModels);
  }, [idClient, devices]);

  const handleDeselectAllDevices = () => {
    setDevicesSelected([]);
  };

  useEffect(() => {
    if (visible) {
      fetchModelByClient();
      if (isEdit && blockDevices) {
        setDevicesSelected(blockDevices);
        const blockDeviceModels = blockDevices.map((bd) => Number(bd.modelo));
        setSelectedModel(blockDeviceModels.length ? blockDeviceModels[0] : undefined);
      }
    }
  }, [visible, isEdit, blockDevices, fetchModelByClient]);

  const handleModelChange = (modelId: number | undefined) => {
    setSelectedModel(modelId);
  };

  const filterDevicesByModel = () => {
    return selectedModel ? devices.filter((d) => d.idModelo === selectedModel) : devices;
  };

  const filteredDevices = filterDevicesByModel().filter((d) => d.tipoEquipamento === 'Rastreador');
  const displayedDevices = showAllDevices ? filteredDevices : filteredDevices.slice(0, 20);

  return (
    <Modal
      key={'modal-add-devices'}
      open={visible}
      title={!isEdit ? 'Adicionar dispositivos' : 'Editar dispositivos'}
      onOk={handleSave}
      onCancel={handleCancel}
      cancelText="Cancelar"
      okText="Salvar"
      width={'85%'}
    >
      <BaseForm layout="vertical" style={{ width: '100%' }}>
        <Row gutter={6} style={{ marginBottom: '1rem' }}>
          <Col xs={24}>
            <HelperNotification>Selecione os dispositivos que deseja bloquear</HelperNotification>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col xs={11}>
            <Row gutter={6}>
              <Col xs={12}>
                <BaseFormInputItem style={{ width: '100%' }} label="Modelo" supportText="Opcional">
                  <Select
                    showArrow
                    placeholder="Selecione o modelo"
                    value={selectedModel}
                    onChange={handleModelChange}
                    options={models
                      .filter((model) => model.idEquipamento === 1)
                      .map((model) => ({
                        value: model.id,
                        label: model.nome,
                      }))}
                    allowClear
                  />
                </BaseFormInputItem>
              </Col>
              <Col xs={12}>
                <BaseFormInputItem style={{ width: '100%' }} label="Dispositivos" supportText="">
                  <Select
                    mode="multiple"
                    showArrow
                    showSearch
                    maxTagCount={'responsive'}
                    placeholder="Selecione os dispositivos"
                    value={devicesSelected.map((d) => d.idDispositivo)}
                    onSelect={(value: unknown) => handlerOnSelectDevice(value as number)}
                    onDeselect={(value: unknown) => handlerOnDeselectDevice(value as number)}
                    options={getDeviceOptions()}
                    filterOption={(input, option) => {
                      return option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false;
                    }}
                  />
                </BaseFormInputItem>
              </Col>
              <Row gutter={[10, 10]} style={{ maxHeight: '350px', overflowY: 'auto' }}>
                <Col xs={24} md={24} lg={24}>
                  <Checkbox
                    checked={devicesSelected.length === filteredDevices.length}
                    onChange={(e) => handleSelectAllDevices(e.target.checked)}
                  >
                    Selecionar tudo
                  </Checkbox>
                </Col>
                {displayedDevices.map((item) => (
                  <Col key={item.numeroSerie} xs={24} md={8} lg={8}>
                    <Checkbox
                      checked={devicesSelected.findIndex((p) => p.numeroSerie === item.numeroSerie) > -1}
                      onChange={(value) => handleCheckboxDeviceChange(value.target.checked, item)}
                    >
                      {item.numeroSerie}
                    </Checkbox>
                  </Col>
                ))}
                {filteredDevices.length > 5 && !showAllDevices && (
                  <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
                    <a onClick={() => setShowAllDevices(true)} style={{ textDecoration: 'underline' }}>
                      Ver mais
                    </a>
                  </Col>
                )}
                {showAllDevices && filteredDevices.length > 5 && (
                  <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
                    <a onClick={() => setShowAllDevices(false)} style={{ textDecoration: 'underline' }}>
                      Ver menos
                    </a>
                  </Col>
                )}
              </Row>
              <Row gutter={6} justify="end" style={{ marginTop: '1rem' }}>
                <Col xs={24} md={24} lg={24} xxl={24}>
                  <strong>{filteredDevices.length}&nbsp;</strong>
                  dispositivos
                </Col>
              </Row>
            </Row>
          </Col>
          <Divider orientation="center" type="vertical" style={{ backgroundColor: '#D9D9D9', height: 'auto' }} />
          <S.ContainerListDevices>
            <Row gutter={6} justify="space-between">
              <Col span={6}>
                <strong>Dispositivos Selecionados</strong>
              </Col>
              <Col span={6}>
                <a onClick={handleDeselectAllDevices} style={{ textDecoration: 'underline' }}>
                  Desmarcar todos
                </a>
              </Col>
            </Row>
            {devicesSelected.length > 0 ? (
              <>
                <Row gutter={[10, 10]} style={{ maxHeight: '280px', overflowY: 'auto', marginTop: '1.5rem' }}>
                  {devicesSelected.map((item) => (
                    <Col key={item.numeroSerie} xs={24} sm={6} md={6} lg={6}>
                      <Checkbox
                        checked={devicesSelected.findIndex((p) => p.numeroSerie === item.numeroSerie) > -1}
                        onChange={(value) => handleCheckboxDeviceChange(value.target.checked, item)}
                      >
                        {item.numeroSerie}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
                <Row gutter={6} justify="end" style={{ marginTop: '1rem' }}>
                  <Col xs={24} md={24} lg={24} xxl={24}>
                    <strong>{devicesSelected.length}&nbsp;</strong>
                    dispositivos
                  </Col>
                </Row>
              </>
            ) : (
              <S.NoRow>
                <S.NoContainer>
                  <S.NoTitle>Nenhum dispositivo selecionado</S.NoTitle>
                  <span>Selecione o(s) dispositivo(s) para exibir os resultados</span>
                </S.NoContainer>
              </S.NoRow>
            )}
          </S.ContainerListDevices>
        </Row>
      </BaseForm>
    </Modal>
  );
};
