import React, { FC } from 'react';
import { Tooltip, TooltipProps } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type TooltipInfoIconProps = TooltipProps & {
  title: string;
};

const TooltipInfoIcon: FC<TooltipInfoIconProps> = ({ title, ...props }) => (
  <Tooltip title={title} {...props}>
    <ExclamationCircleOutlined />
  </Tooltip>
);

export default TooltipInfoIcon;
