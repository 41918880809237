import jsPDF from 'jspdf';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import autoTable from 'jspdf-autotable';
import { formatarData } from '@app/utils/utils';
import { DeviceLogDetailedClientModel, DeviceLogModel } from '@app/domain/deviceLog/DeviceLogModel';

const headersEmail: string[] = [
  'Data/Hora',
  'Tipo de equipamento',
  'Modelo',
  'SN',
  'Desconexões internet',
  'Desconexões GNSS',
  'Pontos enviados',
  '% backup',
];

const headers: string[] = [
  'Data/Hora',
  'Tipo de equipamento',
  'Modelo',
  'SN',
  'Apelido',
  'Grupo',
  'Desco. internet',
  'Desco. MQTT',
  '% backup',
  'Desco. GNSS',
  'Pontos enviados',
  'Reset',
];

export const generateLogDevicePdfFile = async (client: string, data: DeviceLogModel[]): Promise<File> => {
  const image = new Image();
  const doc = new jsPDF({ orientation: 'landscape', unit: 'mm', format: 'a4' });

  image.src = `${window.location.origin}/jmak-logo.png`;

  return new Promise((resolve) => {
    image.onload = async () => {
      const imgWidth = 20;
      const imgHeight = (image.height * imgWidth) / image.width;
      const pageWidth = doc.internal.pageSize.getWidth();

      doc.addImage(image, 'PNG', 14, 5, imgWidth, imgHeight);

      doc.setFontSize(8);
      doc.setTextColor(56, 56, 56);

      const text = 'RELATÓRIO - PERFORMANCE OPERACIONAL';
      const textWidth = doc.getTextWidth(text);
      const textX = (pageWidth - textWidth) / 2 - 4;
      doc.text(text, textX, 10, {});

      doc.setDrawColor(186, 186, 186);
      doc.setLineWidth(0.1);
      doc.roundedRect(14, 18, doc.internal.pageSize.getWidth() - 28, 20, 3, 3, 'S');

      doc.setFontSize(8);
      doc.setTextColor(140, 140, 140);
      doc.text(`Cliente:`, 20, 25, {});

      doc.setTextColor(84, 84, 84);
      doc.text(`${client ?? '-'}`, 20, 30, { maxWidth: 60 });

      const rows = data.map((d) => [
        formatarData(d.dataHora ?? new Date()),
        d.tipoEquipamento ?? '-',
        d.modelo ?? '-',
        d.numeroSerial ?? '-',
        d.internet ?? '-',
        d.falhaGPS ?? '-',
        d.qtdPontos ?? '-',
        d.backupPercentual ? d.backupPercentual + '%' : '-',
      ]);

      autoTable(doc, {
        startY: 45,
        theme: 'plain',
        head: [headersEmail],
        body: rows,
        styles: {
          fontSize: 8,
        },
        columnStyles: {
          0: { cellWidth: 'auto' },
        },
        headStyles: {
          fillColor: [245, 245, 245],
          textColor: [0, 0, 0],
          valign: 'middle',
        },
        tableLineColor: [232, 232, 232],
        tableLineWidth: 0.05,
        tableWidth: 'auto',
      });

      const blob = doc.output('blob');
      const file = new File([blob], 'relatorio-performance.pdf', { type: 'application/pdf' });
      resolve(file);
    };
  });
};

export const exportLogPerformanceReportToExcel = async (data?: DeviceLogModel[]) => {
  if (!data) return;

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('data');

  worksheet.addRow(headers);

  data.map((log) => {
    worksheet.addRow([
      formatarData(log.dataHora),
      log.tipoEquipamento,
      log.modelo,
      log.numeroSerial,
      log.apelido,
      log.grupo,
      log.internet,
      log.mqtt,
      log.backupPercentual,
      log.falhaGPS,
      log.qtdPontos,
      log.qtdReset,
    ]);
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  FileSaver.saveAs(blob, `relatorio_performance_operacional.xlsx`);
};

export const exportLogPerformanceReportToPdf = async (logDetailed?: DeviceLogDetailedClientModel) => {
  if (!logDetailed) return;

  const image = new Image();
  const doc = new jsPDF({ orientation: 'landscape', unit: 'mm', format: 'a4' });
  const clientInfo = logDetailed.cliente;

  image.src = `${window.location.origin}/jmak-logo.png`;

  image.onload = async () => {
    const imgWidth = 20;
    const imgHeight = (image.height * imgWidth) / image.width;
    const pageWidth = doc.internal.pageSize.getWidth();

    doc.addImage(image, 'PNG', 14, 5, imgWidth, imgHeight);

    doc.setFontSize(8);
    doc.setTextColor(56, 56, 56);

    const text = 'RELATÓRIO - PERFORMANCE OPERACIONAL';
    const textWidth = doc.getTextWidth(text);
    const textX = (pageWidth - textWidth) / 2 - 4;
    doc.text(text, textX, 10, {});

    doc.setDrawColor(186, 186, 186);
    doc.setLineWidth(0.1);
    doc.roundedRect(14, 18, doc.internal.pageSize.getWidth() - 28, 27, 3, 3, 'S');

    doc.setFontSize(8);
    doc.setTextColor(140, 140, 140);
    doc.text(`Razão Social:`, 20, 25, {});
    doc.text(`CNPJ:`, 90, 25, {});
    doc.text(`Telefone:`, 150, 25, {});
    doc.text(`Email:`, 200, 25, {});
    doc.text(`CEP:`, 20, 35, {});
    doc.text(`Endereço:`, 90, 35, {});
    doc.text(`Número:`, 150, 35, {});
    doc.text(`Complemento:`, 200, 35, {});

    doc.setTextColor(84, 84, 84);
    doc.text(`${clientInfo.razaoSocial ?? '-'}`, 20, 30, { maxWidth: 60 });
    doc.text(`${clientInfo.cnpj ?? '-'}`, 90, 30, { maxWidth: 60 });
    doc.text(`${clientInfo.contatos?.[0]?.telefone ?? '-'}`, 150, 30, { maxWidth: 60 });
    doc.text(`${clientInfo.contatos?.[0]?.email ?? '-'}`, 200, 30, { maxWidth: 60 });
    doc.text(`${clientInfo.cep ?? '-'}`, 20, 40, { maxWidth: 60 });
    doc.text(`${clientInfo.logradouro ?? '-'}`, 90, 40, { maxWidth: 60 });
    doc.text(`${clientInfo.numero ?? '-'}`, 150, 40, { maxWidth: 60 });
    doc.text(`${clientInfo.complemento ?? '-'}`, 200, 40, { maxWidth: 60 });

    const rows = logDetailed.logs.map((log) => [
      formatarData(log.dataHora),
      log.tipoEquipamento ?? '-',
      log.modelo ?? '-',
      log.numeroSerial ?? '-',
      log.apelido ?? '-',
      log.grupo ?? '-',
      log.internet ?? '-',
      log.mqtt ?? '-',
      log.backupPercentual ?? '-',
      log.falhaGPS ?? '-',
      log.qtdPontos ?? '-',
      log.qtdReset ?? '-',
    ]);

    // Criar tabela com os dados
    autoTable(doc, {
      startY: 50,
      theme: 'plain',
      head: [headers],
      body: rows,
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 'auto' },
      },
      headStyles: {
        fillColor: [245, 245, 245],
        textColor: [0, 0, 0],
        valign: 'middle',
      },
      tableLineColor: [232, 232, 232],
      tableLineWidth: 0.05,
      tableWidth: 'auto',
    });

    doc.save(`relatorio_performance_operacional.pdf`);
  };
};
