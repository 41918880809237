/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { RouteSlumpMixConfig, RoutesSlumpMix } from './routes';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { hasAccessByRoles } from '@app/controllers/accessController';
import { useAppSelector } from '@app/hooks/reduxHooks';
import Error403Page from '@app/pages/errors/Error403';
import Error404Page from '@app/pages/errors/Error404';

export const HOME_PATH = '/';

const baseRoutes = RoutesSlumpMix.reduce((result, current) => {
  (result[current.layout?.key ?? ''] = result[current.layout?.key ?? ''] || []).push(current);
  return result;
}, Object.create(null));

export const AppRouter: React.FC = () => {
  const { user } = useAppSelector((state) => state.user);
  const [routes, setRoutes] = useState(baseRoutes);

  useEffect(() => {
    setRoutes(baseRoutes);
  }, [user]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Error404Page></Error404Page>} />
        {Object.keys(routes).map((property, index) => {
          const currentRoutes = routes[property] as RouteSlumpMixConfig[];

          return (
            <Route key={index} path={HOME_PATH} element={currentRoutes[0].layout}>
              {currentRoutes.map((route) => {
                if (route.children && route.children?.length > 0) {
                  return route.children?.map((routeChild) => {
                    return (
                      <Route
                        key={routeChild.key}
                        path={routeChild.path}
                        element={
                          hasAccessByRoles(routeChild.roles) ? routeChild.element : <Error403Page></Error403Page>
                        }
                      />
                    );
                  });
                } else if (route.element) {
                  return (
                    <Route
                      key={route.key}
                      path={route.path}
                      element={hasAccessByRoles(route.roles) ? route.element : <Error403Page></Error403Page>}
                    />
                  );
                }
              })}
            </Route>
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};
