import * as React from 'react';
const SvgIconSettingRemote = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path
        d="M15 9H9C8.45 9 8 9.45 8 10V22C8 22.55 8.45 23 9 23H15C15.55 23 16 22.55 16 22V10C16 9.45 15.55 9 15 9ZM14 21H10V11H14V21Z"
        fill="#E6ECF1"
      />
      <path
        d="M12 14C12.5523 14 13 13.5523 13 13C13 12.4477 12.5523 12 12 12C11.4477 12 11 12.4477 11 13C11 13.5523 11.4477 14 12 14Z"
        fill="#E6ECF1"
      />
      <path
        d="M7.04875 6.05L8.45875 7.46C9.36875 6.56 10.6188 6 11.9988 6C13.3787 6 14.6287 6.56 15.5387 7.46L16.9488 6.05C15.6788 4.78 13.9288 4 11.9988 4C10.0688 4 8.31875 4.78 7.04875 6.05ZM11.9988 0C8.95875 0 6.20875 1.23 4.21875 3.22L5.62875 4.63C7.25875 3.01 9.50875 2 11.9988 2C14.4888 2 16.7388 3.01 18.3588 4.64L19.7688 3.23C17.7888 1.23 15.0387 0 11.9988 0Z"
        fill="#E6ECF1"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconSettingRemote;
