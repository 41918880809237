import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { setHeader } from '@app/store/slices/headerSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { Error } from '@app/components/common/Error/Error';

const Error403Page: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setHeader({
        icon: <ArrowLeftOutlined />,
        title: 'Página não encontrada',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitle>{t('common.forbidden')}</PageTitle>
      <Error
        title="403"
        subtitle="Acesso negado"
        textLink={'Para mais informações entre em contato com a equipe de suporte'}
        msg="Desculpe, você não possuí permissão para acessar essa página."
      />
    </>
  );
};

export default Error403Page;
