import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as CheckIcon } from '@app/assets/icons/check_circle.svg';
import { ReactComponent as ErrorIcon } from '@app/assets/icons/errorIcon.svg';
import { ReactComponent as SyncIcon } from '@app/assets/icons/blue-sync.svg';

import { Col, Row, Spin } from 'antd';
import * as S from '../DeviceCards.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';

interface ICardUSB {
  loading: boolean;
  errorConnecting: boolean;
  errorConnectingMessage?: string;
  device: FactoryDeviceModel;
  synchronize: () => void;
}

export const CardUSB: React.FC<ICardUSB> = ({
  device,
  loading,
  errorConnecting,
  errorConnectingMessage,
  synchronize,
}) => {
  return (
    <>
      {/* <S.Card> */}
      <BaseForm layout="vertical" style={{ width: '100%' }}>
        {/* <S.Title>Conecte o USB</S.Title> */}
        <Row>
          <Col xs={24} md={24} style={{ marginBottom: '1.5rem' }}>
            {loading ? (
              <S.Row>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
                Aguardando reconhecimento do USB.
              </S.Row>
            ) : (
              <S.Row>
                {errorConnecting ? (
                  <>
                    <ErrorIcon />
                    {errorConnectingMessage ?? 'Falha ao conectar o USB, tente novamente!'}
                  </>
                ) : (
                  <>
                    <CheckIcon />
                    USB conectado com sucesso!
                  </>
                )}
              </S.Row>
            )}
          </Col>
        </Row>
        <Row gutter={18} style={{ marginBottom: '1rem' }}>
          <Col xs={24} md={25}>
            <S.Sincronizar>
              <S.Button onClick={synchronize} icon={<SyncIcon />}>
                Sincronizar
              </S.Button>
            </S.Sincronizar>
          </Col>
          <Col xs={24} md={12}>
            <Col xs={24} md={24}>
              <S.TitleDescription>Equipamento</S.TitleDescription>
            </Col>
            <Col xs={24} md={24}>
              <S.Description>{device.tipoEquipamento ?? '-'}</S.Description>
            </Col>
          </Col>
          <Col xs={24} md={12}>
            <Col xs={24} md={24}>
              <S.TitleDescription>Modelo</S.TitleDescription>
            </Col>
            <Col xs={24} md={24}>
              <S.Description>{device.modelo ?? '-'}</S.Description>
            </Col>
          </Col>
        </Row>
        <Row gutter={18} style={{ marginBottom: '1rem' }}>
          <Col xs={24} md={12}>
            <Col xs={24} md={24}>
              <S.TitleDescription>SN</S.TitleDescription>
            </Col>
            <Col xs={24} md={24}>
              <S.Description>{device.numeroSerie ?? '-'}</S.Description>
            </Col>
          </Col>
          <Col xs={24} md={12}>
            <Col xs={24} md={24}>
              <S.TitleDescription>Versão de firmware</S.TitleDescription>
            </Col>
            <Col xs={24} md={24}>
              <S.Description>{device.versaoFirmware ?? '-'}</S.Description>
            </Col>
          </Col>
        </Row>
        <Row gutter={18} style={{ marginBottom: '1rem' }}>
          <Col xs={24} md={12}>
            <Col xs={24} md={24}>
              <S.TitleDescription>Anatel</S.TitleDescription>
            </Col>
            <Col xs={24} md={24}>
              <S.Description>{device.anatel ?? '-'}</S.Description>
            </Col>
          </Col>
          <Col xs={24} md={12}>
            <Col xs={24} md={24}>
              <S.TitleDescription>IMEI</S.TitleDescription>
            </Col>
            <Col xs={24} md={24}>
              <S.Description>{device.imei ?? '-'}</S.Description>
            </Col>
          </Col>
        </Row>
      </BaseForm>
      {/* </S.Card> */}
    </>
  );
};
