import styled from 'styled-components';
import { Table as AntdTable } from 'antd';
import { FONT_SIZE } from '@app/styles/themes/constants';

export const Table = styled(AntdTable)`
  & table {
    border-radius: 8px;
    border-top: none !important;
  }

  td:has(table) {
    padding: 1rem !important;
  }

  & .ant-table-container {
    border-left: none !important;
  }

  & thead .ant-table-cell {
    color: var(--text-label);
    background-color: var(--text-header);
    font-size: ${FONT_SIZE.xs};
    font-weight: 700;
    line-height: 1.25rem;
    height: 3.625rem;
    border-right: none !important;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    & .anticon {
      color: var(--primary-color);
    }
  }

  & .ant-table-bordered {
    border: 1px solid var(--disabled-bg-color);
    border-radius: 6px;
  }

  & thead tr {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  & thead tr:first-child {
    th:first-child {
      border-top-left-radius: 6px !important;
    }
    th:last-child {
      border-top-right-radius: 6px !important;
    }
  }

  & tbody tr:first-child {
    td:first-child {
      border-top-left-radius: 6px;
    }
    td:last-child {
      border-top-right-radius: 6px;
    }
  }

  & tbody tr:last-child {
    td:first-child {
      border-bottom-left-radius: 6px;
    }
    td:last-child {
      border-bottom-right-radius: 6px;
    }
  }

  /* & tbody tr:first-child & thead .ant-table-column-has-sorters:hover {
    background-color: ;
    box-shadow: 1px 1px 10px 1px ;
  } */

  & tbody .ant-table-cell {
    color: var(--text-main-color);
    font-size: ${FONT_SIZE.xs};
    font-weight: 600;
    line-height: 1.25rem;
    height: 3.4375rem;
    border-right: none !important;
    padding-right: 1rem;
    padding-left: 1.625rem;
  }

  & tbody .ant-table-row-expand-icon {
    min-height: 1.25rem;
    min-width: 1.25rem;
    margin-top: 0;
  }

  // Override default antd selector
  &
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: var(--primary-color);
  }

  & .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-item {
    min-width: 2.0625rem;
    height: 2.0625rem;
    line-height: 2.0625rem;
    font-size: ${FONT_SIZE.xs};
    font-weight: 600;
  }

  & .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 0.125rem;
    border-color: var(--border-pagination) !important;
    color: var(--text-pagination-color);
  }

  .ant-pagination-item,
  .ant-pagination-item a {
    border-radius: 0.125rem !important;
    border-color: var(--border-pagination);
    color: var(--text-pagination-color);

    :hover {
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }
  }
  .ant-pagination-item:hover {
    background: var(--button-hover);
  }

  .ant-pagination-item-active,
  .ant-pagination-item-active:hover {
    border-color: var(--secondary-color);
    background-color: var(--collapse-background-color);
    a {
      color: var(--background-main-color);
    }
  }

  & .ant-checkbox-inner {
    border-radius: 0.1875rem;
    height: 1.25rem;
    width: 1.25rem;
    border-color: var(--border-pagination);
    color: var(--text-pagination-color);
  }

  & .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 0.75rem;
  }

  .ant-table-column-sort {
    background-color: transparent;
  }

  .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: var(--border-pagination);
  }

  .ant-table-pagination.ant-pagination {
    margin-top: 3.5rem;
    margin-bottom: 0rem;
  }

  .ant-pagination-disabled {
    .ant-pagination-item-link,
    .ant-pagination-item a {
      color: var(--border-pagination); //disabled arrow
    }
  }

  .ant-pagination.ant-pagination-disabled {
    .ant-pagination-item-link,
    .ant-pagination-item a {
      color: var(--text-pagination-color);
      border-color: var(--text-pagination-color);
    }
  }

  .ant-table-column-sorter-down,
  .ant-table-column-sorter-up {
    color: var(--secondary-color) !important;
  }

  .ant-table-column-sorter-down.active,
  .ant-table-column-sorter-up.active {
    color: var(--secondary-color) !important;
  }
`;
