import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import { ApiServiceBase, IApiServiceBase } from './base/apiServiceBase';
import { ViewCommandHistoryModel } from '@app/domain/device/viewCommandHistoryModel';
import { ViewTagDeviceRelationshioHistoryModel } from '@app/domain/device/viewTagDeviceRelationshioHistoryModel';
import {
  ViewConfigUpdateHistoryGroupedModel,
  ViewConfigUpdateHistoryModel,
} from '@app/domain/device/viewConfigUpdateHistoryModel';
import { ViewFirmwareUpdateHistoryModel } from '@app/domain/device/viewFirmwareUpdateHistoryModel';
import { DeviceTwinHistoryModel } from '@app/domain/device-history/device-twin-history-model';
import { DeviceProfileHistoryModel } from '@app/domain/device-history/device-profile-history-model';
import { AxiosResponse } from 'axios';

export default interface IDeviceHistoryService extends IApiServiceBase<null, null> {
  getFirmwareUpdateHistory(id: string): Promise<ViewFirmwareUpdateHistoryModel[]>;
  getConfigUpdateGroupedHistory(id: string): Promise<ViewConfigUpdateHistoryGroupedModel[]>;
  getConfigUpdateHistory(idUpdate: string): Promise<ViewConfigUpdateHistoryModel[]>;
  getCommandHistory(id: string): Promise<ViewCommandHistoryModel[]>;
  getTagHistory(idDevice: string): Promise<ViewTagDeviceRelationshioHistoryModel[]>;
  getDeviceProfileParameterHistory(idDevice: string): Promise<DeviceProfileProperty[]>;
  getDeviceTwinHistory(imei: string): Promise<DeviceTwinHistoryModel[]>;
  getDeviceProfileHistory(id: string): Promise<DeviceProfileHistoryModel[]>;
  insertDeviceProfileHistory(history: DeviceProfileHistoryModel): Promise<AxiosResponse>;
}

export class DeviceHistoryService extends ApiServiceBase<null, null> implements IDeviceHistoryService {
  constructor() {
    super('dispositivo/historico');
  }

  async getDeviceTwinHistory(imei: string): Promise<DeviceTwinHistoryModel[]> {
    try {
      return (await this.api.get(`gemeo/${imei}`))?.data;
    } catch (error) {
      throw error;
    }
  }

  async insertDeviceProfileHistory(history: DeviceProfileHistoryModel): Promise<AxiosResponse> {
    try {
      return await this.api.post(`perfil-configuracao`, JSON.stringify(history));
    } catch (error) {
      throw error;
    }
  }

  async getDeviceProfileHistory(id: string): Promise<DeviceProfileHistoryModel[]> {
    try {
      return (await this.api.get(`perfil-configuracao/${id}`))?.data;
    } catch (error) {
      throw error;
    }
  }

  async getFirmwareUpdateHistory(id: string): Promise<ViewFirmwareUpdateHistoryModel[]> {
    try {
      return (await this.api.get(`atualizacao-firmware/${id}`))?.data;
    } catch (error) {
      throw error;
    }
  }

  async getConfigUpdateGroupedHistory(id: string): Promise<ViewConfigUpdateHistoryGroupedModel[]> {
    try {
      return (await this.api.get(`atualizacao-parametros-agrupado/${id}`))?.data;
    } catch (error) {
      throw error;
    }
  }

  async getConfigUpdateHistory(idUpdate: string): Promise<ViewConfigUpdateHistoryModel[]> {
    try {
      return (await this.api.get(`atualizacao-parametros/${idUpdate}`))?.data;
    } catch (error) {
      throw error;
    }
  }

  async getCommandHistory(id: string): Promise<ViewCommandHistoryModel[]> {
    try {
      return (await this.api.get(`comandos/${id}`))?.data;
    } catch (error) {
      throw error;
    }
  }

  async getTagHistory(idDevice: string): Promise<ViewTagDeviceRelationshioHistoryModel[]> {
    try {
      return (await this.api.get(`tags-individuais/${idDevice}`))?.data;
    } catch (error) {
      throw error;
    }
  }

  async getDeviceProfileParameterHistory(idDevice: string): Promise<DeviceProfileProperty[]> {
    try {
      return (await this.api.get(`/obter-parametros-configuracao/${idDevice}`))?.data;
    } catch (error) {
      throw error;
    }
  }
}
