import styled from 'styled-components';

export const PointCounterWrapper = styled.div`
  display: flex;
  //width: 7.5rem;
  background-color: transparent;
  gap: 0.06rem;
  height: 2.5rem;
`;

const PointCounterLabel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 3.8125rem;
  height: 100%;
`;

export const PointCounterLabelLeft = styled(PointCounterLabel)`
  background: var(--brand-colors-blue-blue-500, #263668);
  color: #ffffff;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem 0rem 0rem 0.25rem;
`;

export const PointCounterLabelRight = styled(PointCounterLabel)`
  background-color: #ffffff;
  color: #545454;
  border-radius: 0rem 0.25rem 0.25rem 0rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  //gap: 10px;
  background: var(--base-colors-light-grey, #fcfcfc);
  //padding: 0.5rem 0.375rem;
  height: 2.5rem;
`;

export const ButtonDivisor = styled.div`
  width: 0.0625rem;
  height: 1.75rem;
  background: var(--neutral-gray-gray-200, #d1d1d1);
`;

export const ZoomButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.12rem;
  height: 100%;
  cursor: pointer;
  font-size: 1.8rem;
  color: #545454;
  user-select: none;
  margin-top: -4px;
`;

export const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  cursor: pointer;
`;
