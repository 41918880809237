import React, { FC, useCallback, useEffect, useState } from 'react';
import * as SC from '../../components.styles';
import { Col, Row } from 'antd';
import { SkeletonButton } from '@app/components/common/Skeleton/Skeleton';
import * as S from './performance-container.styles';
import { IconCloudUpload, IconMapMarker, IconReload, IconSignalInfo } from '@app/assets/slump-icons';
import { Tooltip } from '../../tooltip/tooltip';
import { notificationController } from '@app/controllers/notificationController';
import IDeviceLogService, { DeviceLogService } from '@app/services/deviceLogService';
import { DeviceLogResumeModel } from '@app/domain/deviceLog/DeviceLogModel';
import { hasFactoryAccess } from '@app/controllers/accessController';

const logService: IDeviceLogService = new DeviceLogService();

interface PerformanceContainerProps {
  filterSelected: number | undefined;
  setModalPerformaceConfig: (config: { isOpen: boolean; id: number }) => void;
}

export const PerformanceContainer: FC<PerformanceContainerProps> = ({ filterSelected, setModalPerformaceConfig }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [resumes, setResumes] = useState<DeviceLogResumeModel[]>([]);
  const [isFactory, setIsFactory] = useState<boolean>(false);

  const fetchAdmin = useCallback(async () => {
    logService
      .getDeviceLogResumeAdmin()
      .then((res) => {
        setResumes(res);
        setLoading(false);
      })
      .catch(() => {
        notificationController.error({
          message: 'Erro!',
          description: 'Erro ao obter os dados de equipamentos e indicadores.',
        });
        setLoading(false);
      });
  }, []);

  const fetch = useCallback(async () => {
    logService
      .getDeviceLogResume()
      .then((res) => {
        setResumes(res);
        setLoading(false);
      })
      .catch(() => {
        notificationController.error({
          message: 'Erro!',
          description: 'Erro ao obter os dados de equipamentos e indicadores.',
        });
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (hasFactoryAccess()) {
      setIsFactory(true);
      fetchAdmin();
    } else {
      fetch();
    }
  }, [fetch, fetchAdmin]);

  useEffect(() => {}, [filterSelected]);

  return (
    <>
      <Row>
        <SC.TitleCardHeader>Performance operacional</SC.TitleCardHeader>
        {loading ? (
          <Col span={24}>
            <S.PerformanceContainerWrapper gutter={[16, 8]}>
              {Array.from({ length: 14 }, (_, index) => (
                <Col span={12} key={index}>
                  <SkeletonButton active block size="default" shape="default" />
                </Col>
              ))}
            </S.PerformanceContainerWrapper>
          </Col>
        ) : (
          <Col span={24}>
            <S.PerformanceContainerWrapper gutter={[16, 16]}>
              {resumes
                .filter((r) => (filterSelected ? r.id == filterSelected : true))
                .map((r, i) => (
                  <Col key={i} xs={24} xl={12}>
                    <S.PerformanceContainerCard
                      onClick={() =>
                        setModalPerformaceConfig({
                          id: r.id,
                          isOpen: true,
                        })
                      }
                    >
                      <S.PerformanceContainerCardRow>
                        <Col style={{ marginBottom: '0.25rem' }}>
                          <Row align={'middle'} gutter={16}>
                            <Col>
                              <SC.LegendIndicator color="#A71916" className="performance-card-legend" />
                            </Col>
                            <Col className="performance-card-title">{r.nome?.split(' ')[0]}</Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row gutter={16}>
                            {r.desconexaoInternet > 0 && isFactory && (
                              <Col>
                                <Tooltip
                                  icon={<IconSignalInfo />}
                                  title="Desconexão Internet"
                                  subtitle={`${r.desconexaoInternet} equipamentos`}
                                >
                                  <IconSignalInfo />
                                </Tooltip>
                              </Col>
                            )}
                            {r.desconexaoGnss > 0 && (
                              <Col>
                                <Tooltip
                                  icon={<IconMapMarker />}
                                  title="Desconexão GNSS"
                                  subtitle={`${r.desconexaoGnss} equipamentos`}
                                >
                                  <IconMapMarker />
                                </Tooltip>
                              </Col>
                            )}
                            {r.backup > 0 && (
                              <Col>
                                <Tooltip
                                  icon={<IconCloudUpload />}
                                  title="Backup"
                                  subtitle={`${r.backup} equipamentos`}
                                >
                                  <IconCloudUpload />
                                </Tooltip>
                              </Col>
                            )}
                            {r.reset > 0 && isFactory && (
                              <Col>
                                <Tooltip icon={<IconReload />} title="Reset" subtitle={`${r.reset} equipamentos`}>
                                  <IconReload />
                                </Tooltip>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </S.PerformanceContainerCardRow>
                    </S.PerformanceContainerCard>
                  </Col>
                ))}
            </S.PerformanceContainerWrapper>
          </Col>
        )}
      </Row>
    </>
  );
};
