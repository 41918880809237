import React from 'react';
import { Col, Row } from 'antd';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import * as S from '../Header.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { PlusSquareOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {
  const { title, handleButtonClick } = useAppSelector((state) => state.header);

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <ProfileDropdown />
      </Col>
      <Col
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: '6rem',
          marginRight: '0.5rem',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '-0.5rem',
        }}
      >
        <S.HeaderTitle>{title}</S.HeaderTitle>
      </Col>
      <Col>
        <Button type="ghost" onClick={handleButtonClick}>
          <PlusSquareOutlined />
        </Button>
      </Col>

      <S.BurgerCol>{!isSiderOpened && <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />}</S.BurgerCol>
    </Row>
  );
};
