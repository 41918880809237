import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { LabelModelPrint } from '@app/domain/label/labelModelPrint';
import AnatelWhiteIcon from '@app/assets/icons/anatel-white.png';
import JmakWhiteIcon from '@app/assets/icons/jmak-white.png';

export const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '0.52mm',
    alignSelf: 'center',
    padding: '5mm',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '0.26mm',
    width: '24mm',
    height: '65mm',
    padding: '1.32mm 1.59mm 1.06mm 1.59mm',
    backgroundColor: 'black',
    color: 'white',
  },
  title: {
    top: '-1.32mm',
    fontFamily: 'GlacialIndifference',
    fontSize: '8.2mm',
    textAlign: 'center',
    width: '100%',
  },
  subTitle: {
    marginTop: '-2mm',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '1.6mm',
    paddingLeft: '2.6mm',
    width: '100%',
    // border: '1px solid blue',
  },
  deviceMapContainer: {
    marginTop: '0.9mm',
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    // border: '1px solid red',
  },
  deviceMap: {
    width: '5.90mm',
    height: '5mm',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    padding: '0.26mm 0.17mm',
    gap: '0.26mm',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  deviceMapItem: {
    backgroundColor: 'black',
    width: '1.59mm',
    height: '2.12mm',
    fontSize: '1.06mm',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoContainer: {
    marginTop: '0.9mm',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.32mm',
    justifyContent: 'space-between',
    gap: '1mm',
    // border: '1px solid green',
  },
  infoContainerFirstRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.60mm',
    // border: '1px solid yellow',
  },
  colorsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.26mm',
    // border: '1px solid pink',
  },
  infoContainerSecondRow: {
    // border: '1px solid red',
  },
  circle: {
    width: '0.53mm',
    height: '0.53mm',
    borderRadius: '100%',
    marginRight: '0.26mm',
    backgroundColor: 'white',
    alignSelf: 'center',
  },
  infoContainerColorsColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.26mm',
  },
  colorItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.2mm',
    alignItems: 'center',
  },
  arrowIconContainer: {
    display: 'flex',
    // gap: '0.26mm',
    // border: '1px solid blue',
  },
  triangleContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '0.26mm',
    alignItems: 'center',
    width: '4mm',
    padding: '0.26mm',
    alignSelf: 'flex-end',
    marginLeft: '2.5mm',
    // border: '1px solid red',
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderLeftWidth: '1.65mm',
    borderRightWidth: '1.65mm',
    borderBottomWidth: '2.17mm',
    borderLeftColor: 'white',
    borderRightColor: 'white',
    borderBottomColor: 'black',
    marginBottom: '-1.06mm',
  },
  rectangle: {
    paddingTop: '-1.06mm',
    width: '1.65mm',
    height: '3mm',
    backgroundColor: 'black',
    alignSelf: 'center',
  },
  triangleText: {
    marginTop: '0.26mm',
    marginLeft: '3.25mm',
    fontSize: '0.6mm',
  },
  qrcode: {
    marginTop: '1mm',
    alignSelf: 'center',
    width: '11mm',
  },
  serial: {
    fontSize: '1.59mm',
    marginTop: '1mm',
    alignSelf: 'center',
  },
  jmakWhiteIcon: {
    width: '14.55mm',
    marginTop: '-0.3mm',
    alignSelf: 'center',
  },
  jmakLink: {
    fontSize: '1.59mm',
    alignSelf: 'center',
  },
  anatelImage: {
    marginTop: '1.5mm',
    width: '5.29mm',
    alignSelf: 'center',
  },
  analteText: {
    fontSize: '0.79mm',
    alignSelf: 'center',
  },
});

// Modelo J-R12: LabelTemplate.J_R11
export const J_R11: React.FC<LabelModelPrint> = (label: LabelModelPrint) => {
  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>{label.modelo}</Text>
      </View>
      <View style={styles.subTitle}>
        <Text>Rastreador veicular</Text>
      </View>
      <View style={styles.deviceMapContainer}>
        <View style={{ ...styles.deviceMap }}>
          <View style={styles.deviceMapItem}>
            <Text>6</Text>
          </View>
          <View style={styles.deviceMapItem}>
            <Text>5</Text>
          </View>
          <View style={styles.deviceMapItem}>
            <Text>4</Text>
          </View>
          <View style={styles.deviceMapItem}>
            <Text>3</Text>
          </View>
          <View style={styles.deviceMapItem}>
            <Text>2</Text>
          </View>
          <View style={styles.deviceMapItem}>
            <Text>1</Text>
          </View>
        </View>
      </View>

      <View style={styles.infoContainer}>
        <View style={styles.infoContainerFirstRow}>
          <View style={styles.colorsContainer}>
            <View style={styles.colorItem}>
              <View style={styles.circle} />
              <Text>Vermelho(1) VIN</Text>
            </View>
            <View style={styles.colorItem}>
              <View style={styles.circle} />
              <Text>Marrom(2) Relé </Text>
            </View>
            <View style={styles.colorItem}>
              <View style={styles.circle} />
              <Text>Verde(3) One wire</Text>
            </View>
            <View style={styles.colorItem}>
              <View style={styles.circle} />
              <Text>Amarelo(4) TTL RX</Text>
            </View>
            <View style={styles.colorItem}>
              <View style={styles.circle} />
              <Text>Cinza(5) TTL TX</Text>
            </View>
            <View style={styles.colorItem}>
              <View style={styles.circle} />
              <Text>Branco(6) NC</Text>
            </View>
          </View>
          <View style={styles.arrowIconContainer}>
            <View style={styles.triangleContainer}>
              <View style={styles.triangle}></View>
              <View style={styles.rectangle}></View>
            </View>
            <View style={styles.triangleText}>
              <Text>Este lado</Text>
              <Text>para cima</Text>
            </View>
          </View>
        </View>
        <View style={styles.infoContainerSecondRow}>
          <View>
            <Text>Bateria interna: 400mAh</Text>
            <Text style={{ marginTop: '0.4mm' }}>Tensão: 9-30 VCC</Text>
          </View>
        </View>
      </View>

      <Image src={label.qrCodeURL} style={styles.qrcode} />
      <Text style={styles.serial}>{`SN: ${label.numeroSerie}`}</Text>
      <Image src={JmakWhiteIcon} style={styles.jmakWhiteIcon} />
      <Text style={styles.jmakLink}>www.jmak.com.br</Text>

      <Image src={AnatelWhiteIcon} style={styles.anatelImage} />
      <Text style={styles.analteText}>{label.certificadoAnatel}</Text>
      <Text style={styles.analteText}>Fabricado no Brasil</Text>
    </View>
  );
};
