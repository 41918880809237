import { notificationController } from '@app/controllers/notificationController';
import { readUser } from '@app/services/localStorage.service';
import { formatarData, uniqueById } from '@app/utils/utils';
import { ColumnsType } from 'antd/lib/table';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import * as S from './modal-log-performance-history.styles';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { ModalSendSupportEmail } from './modal-send-email';
import { Col, Row } from 'antd';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { Button } from '@app/components/common/buttons/Button/Button';
import { FileExcelOutlined, FilePdfOutlined, MailOutlined } from '@ant-design/icons';
import { Table } from '@app/components/common/Table/Table';
import { UserType } from '@app/constants/enums/userType';
import { exportLogPerformanceReportToExcel, exportLogPerformanceReportToPdf } from '../../functions/home-functions';
import { Modal } from '@app/components/common/Modal/Modal';
import {
  CardInfoContainer,
  CardInfoProperty,
  CardInfoTitle,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import IDeviceLogService, { DeviceLogService } from '@app/services/deviceLogService';
import { DeviceLogDetailedClientModel, DeviceLogModel } from '@app/domain/deviceLog/DeviceLogModel';
import { hasFactoryAccess } from '@app/controllers/accessController';

interface ModalLogPerformanceHistoryProps {
  id: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const logService: IDeviceLogService = new DeviceLogService();

export const ModalLogPerformanceHistory: FC<ModalLogPerformanceHistoryProps> = ({ id, open, setOpen }) => {
  const user = readUser();
  const [isFactory, setIsFactory] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [logDetailed, setLogDetailed] = useState<DeviceLogDetailedClientModel>();
  const [filteredLogs, setFilteredLog] = useState<DeviceLogModel[]>();
  const [modalSendEmail, setModalSendEmail] = useState<boolean>(false);
  const [idModelSelected, setIdModelSelected] = useState<number | undefined>();
  const [idGroupSelected, setIdGroupSelected] = useState<number | undefined>();

  const handleCleanFilters = () => {
    setIdGroupSelected(undefined);
    setIdModelSelected(undefined);
    setFilteredLog(logDetailed?.logs);
  };
  const handleFilter = () => {
    setFilteredLog(
      logDetailed?.logs.filter(
        (l) =>
          (idModelSelected ? l.idModelo == idModelSelected : true) &&
          (idGroupSelected ? l.idGrupo == idGroupSelected : true),
      ),
    );
  };

  const fetchAdmin = useCallback(async (id: number) => {
    setLoading(true);
    logService
      .getDeviceLogDetailedByIdClientOrIdGroupAdmin(id)
      .then((res) => {
        setLogDetailed(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({ message: `Erro ao obter histórico de LOG. ${err}` });
      });
  }, []);

  const fetch = useCallback(async (id: number) => {
    setLoading(true);
    logService
      .getDeviceLogDetailedByIdClientOrIdGroup(id)
      .then((res) => {
        setLogDetailed(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({ message: `Erro ao obter histórico de LOG. ${err}` });
      });
  }, []);

  const columns: ColumnsType<DeviceLogModel> = [
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      sorter: (a, b) => new Date(a.dataHora).getTime() - new Date(b.dataHora).getTime(),
      width: '10%',
      render: (dataHora) => formatarData(dataHora),
    },
    {
      title: 'Tipo de equipa.',
      dataIndex: 'tipoEquipamento',
      showSorterTooltip: false,
      sorter: (a, b) =>
        a.tipoEquipamento && b.tipoEquipamento ? a.tipoEquipamento.localeCompare(b.tipoEquipamento) : 0,
      width: '10%',
    },
    {
      title: 'Modelo',
      dataIndex: 'modelo',
      showSorterTooltip: false,
      sorter: (a, b) => (a.modelo && b.modelo ? a.modelo.localeCompare(b.modelo) : 0),
      width: '5%',
    },
    {
      title: 'SN',
      dataIndex: 'numeroSerial',
      showSorterTooltip: false,
      sorter: (a, b) => (a.numeroSerial && b.numeroSerial ? a.numeroSerial.localeCompare(b.numeroSerial) : 0),
      width: '12%',
    },
    {
      title: 'Apelido',
      dataIndex: 'apelido',
      showSorterTooltip: false,
      sorter: (a, b) => (a.numeroSerial && b.numeroSerial ? a.numeroSerial.localeCompare(b.numeroSerial) : 0),
      width: '8%',
    },
    {
      title: 'Grupo',
      dataIndex: 'grupo',
      showSorterTooltip: false,
      sorter: (a, b) => (a.grupo && b.grupo ? a.grupo.localeCompare(b.grupo) : 0),
      width: '8%',
    },
    {
      title: 'Desco. internet',
      dataIndex: 'internet',
      showSorterTooltip: false,
      sorter: (a, b) => (a.internet && b.internet ? a.internet - b.internet : 0),
      width: '8%',
      render: (internet) => (internet >= 10 && isFactory ? <S.RedTag>{internet}</S.RedTag> : internet),
    },
    {
      title: 'Desco. GNSS',
      dataIndex: 'falhaGPS',
      showSorterTooltip: false,
      sorter: (a, b) => (a.falhaGPS && b.falhaGPS ? a.falhaGPS - b.falhaGPS : 0),
      render: (item) => (item >= 5 && isFactory ? <S.RedTag>{item}</S.RedTag> : item),
      width: '8%',
    },
    {
      title: 'Desco. MQTT',
      dataIndex: 'mqtt',
      showSorterTooltip: false,
      sorter: (a, b) => (a.mqtt && b.mqtt ? a.mqtt - b.mqtt : 0),
      width: '8%',
    },
    {
      title: 'Pontos enviados',
      dataIndex: 'qtdPontos',
      showSorterTooltip: false,
      sorter: (a, b) => (a.qtdPontos && b.qtdPontos ? a.qtdPontos - b.qtdPontos : 0),
      width: '8%',
    },
    {
      title: '% backup',
      dataIndex: 'backupPercentual',
      showSorterTooltip: false,
      sorter: (a, b) => (a.backupPercentual && b.backupPercentual ? a.backupPercentual - b.backupPercentual : 0),
      render: (item) =>
        item >= 20 && isFactory ? <S.RedTag>{`${item.toFixed(2)}%`}</S.RedTag> : `${item.toFixed(2)}%`,
      width: '5%',
    },

    {
      title: 'Reset',
      dataIndex: 'qtdReset',
      showSorterTooltip: false,
      sorter: (a, b) => (a.qtdReset && b.qtdReset ? a.qtdReset - b.qtdReset : 0),
      render: (item) => (item >= 3 && isFactory ? <S.RedTag>{item}</S.RedTag> : item),
      width: '5%',
    },
  ];

  const modelOptions = () =>
    logDetailed?.logs
      ? uniqueById(
          logDetailed?.logs.map((l) => ({ label: l.modelo, value: l.idModelo })).filter((o) => o.value),
          'value',
        )
      : [];

  const groupOptions = () =>
    logDetailed?.logs
      ? uniqueById(logDetailed?.logs.map((l) => ({ label: l.grupo, value: l.idGrupo })).filter((o) => o.value), 'value')
      : [];

  useEffect(() => setFilteredLog(logDetailed?.logs), [logDetailed?.logs]);

  useEffect(() => {
    if (id && open) {
      if (hasFactoryAccess()) {
        setIsFactory(true);
        fetchAdmin(id);
      } else {
        fetch(id);
      }
    } else {
      handleCleanFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, open]);

  return (
    <>
      <Spinner spinning={loading}></Spinner>
      <ModalSendSupportEmail open={modalSendEmail} setOpen={setModalSendEmail} logPerformance={logDetailed} />
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title="Resumo da performance operacional"
        width={'85%'}
        footer={null}
      >
        <S.Container>
          <CardInfoContainer>
            <Row>
              <CardInfoTitle>Informações do cliente</CardInfoTitle>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={7}>
                <CardInfoProperty>Razão social</CardInfoProperty>
                <CardInfoValue>{logDetailed?.cliente.razaoSocial ?? '-'}</CardInfoValue>
              </Col>
              <Col xs={24} md={8}>
                <CardInfoProperty>CNPJ</CardInfoProperty>
                <CardInfoValue>{logDetailed?.cliente.cnpj ?? '-'}</CardInfoValue>
              </Col>
              <Col xs={24} md={5}>
                <CardInfoProperty>Telefone</CardInfoProperty>
                <CardInfoValue>{logDetailed?.cliente.contatos?.[0].telefone ?? '-'}</CardInfoValue>
              </Col>
              <Col xs={24} md={4}>
                <CardInfoProperty>E-mail</CardInfoProperty>
                <CardInfoValue>{logDetailed?.cliente.contatos?.[0].email ?? '-'}</CardInfoValue>
              </Col>
              <Col xs={24} md={7}>
                <CardInfoProperty>CEP</CardInfoProperty>
                <CardInfoValue>{logDetailed?.cliente.cep ?? '-'}</CardInfoValue>
              </Col>
              <Col xs={24} md={8}>
                <CardInfoProperty>Endereço</CardInfoProperty>
                <CardInfoValue>{logDetailed?.cliente.logradouro ?? '-'}</CardInfoValue>
              </Col>
              <Col xs={24} md={5}>
                <CardInfoProperty>Número</CardInfoProperty>
                <CardInfoValue>{logDetailed?.cliente.numero ?? '-'}</CardInfoValue>
              </Col>
              <Col xs={24} md={4}>
                <CardInfoProperty>Complemento</CardInfoProperty>
                <CardInfoValue>{logDetailed?.cliente.complemento ?? '-'}</CardInfoValue>
              </Col>
            </Row>
          </CardInfoContainer>
          <BaseForm layout={'vertical'}>
            <S.InputFilterContainer>
              <BaseFormInputItem label="Modelo" style={{ minWidth: '300px' }}>
                <Select
                  showArrow
                  showSearch
                  placeholder="Selecione o modelo"
                  value={idModelSelected}
                  onChange={(value) => setIdModelSelected(value as number)}
                  style={{ width: '100%' }}
                  options={modelOptions()}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </BaseFormInputItem>
              {user?.type != UserType.Client && (
                <BaseFormInputItem label="Grupo" style={{ minWidth: '300px' }}>
                  <Select
                    showArrow
                    showSearch
                    placeholder="Selecione o grupo"
                    value={idGroupSelected}
                    onChange={(value) => setIdGroupSelected(value as number)}
                    style={{ width: '100%' }}
                    options={groupOptions()}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </BaseFormInputItem>
              )}
            </S.InputFilterContainer>
          </BaseForm>
        </S.Container>
        <S.ButtonsContainer>
          <Button type="link" onClick={() => exportLogPerformanceReportToPdf(logDetailed)} icon={<FilePdfOutlined />}>
            Exportar dados PDF
          </Button>
          <Button
            type="link"
            onClick={() => exportLogPerformanceReportToExcel(filteredLogs)}
            icon={<FileExcelOutlined />}
          >
            Exportar dados excel
          </Button>
          {isFactory && (
            <Button type="link" onClick={() => setModalSendEmail(true)} icon={<MailOutlined />}>
              Enviar e-mail
            </Button>
          )}
          <Button type="default" style={{ minWidth: '200px' }} onClick={() => handleCleanFilters()}>
            Limpar filtros
          </Button>
          <Button type="primary" style={{ minWidth: '200px' }} onClick={() => handleFilter()}>
            Filtrar
          </Button>
        </S.ButtonsContainer>
        <Table
          columns={
            isFactory
              ? columns
              : columns.filter((c) => c.title !== 'Reset' && c.title !== 'Desco. MQTT' && c.title !== 'Desco. internet')
          }
          dataSource={filteredLogs}
          bordered
          size={'small'}
        />
      </Modal>
    </>
  );
};
