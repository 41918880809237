import { ApiServiceBase, IApiServiceBase } from '@app/services/base/apiServiceBase';
import { EquipmentModel } from '@app/domain/equipment/equipmentModel';

export default interface IEquipmentService extends IApiServiceBase<EquipmentModel, EquipmentModel> {}

export class EquipmentService extends ApiServiceBase<EquipmentModel, EquipmentModel> {
  constructor() {
    super('Equipamento');
  }
}
