import { ApiServiceBase, IApiServiceBase } from './base/apiServiceBase';
import { HomeModel } from '@app/domain/home/homeModel';
import { UpdateModel } from '@app/domain/home/updates';

export default interface IHomeService extends IApiServiceBase<HomeModel, HomeModel> {
  getUpdates(): Promise<UpdateModel[]>;
}

export class HomeService extends ApiServiceBase<HomeModel, HomeModel> {
  constructor() {
    super('');
  }

  async getUpdates(): Promise<UpdateModel[]> {
    return (await this.api.get('atualizacao'))?.data;
  }
}
