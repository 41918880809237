import styled from 'styled-components';
import { Button as AntButton } from 'antd';

export const ReadQrCode = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0 1.5rem 0;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem; // Gap between the TopInfo and BottomInfo sections
`;

export const TopInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); // Automatically fit as many as possible in a row
  gap: 0.3rem; // Gap between the InfoItem components
`;

export const BottomInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // Two items side by side
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoTitle = styled.div`
  color: var(--neutral-gray-gray-500, #8c8c8c);
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const InfoValue = styled.div`
  color: var(--neutral-gray-gray-700, #545454);
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const QRCodeInfoItem = styled(InfoItem)`
  // Inherits the styling of InfoItem but adds/overrides specific properties
  grid-column: 2 / 4; // Starts from the second column and spans to the end of the third column
`;

export const Button = styled(AntButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 14.75rem;
`;
