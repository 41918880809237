/* eslint-disable @typescript-eslint/no-unused-vars */
import { UserType } from '@app/constants/enums/userType';
import { ClaimModel } from '@app/domain/identity/claimModel';
import { RoutesSlumpMix } from '@app/router/routes';
import { readUser } from '@app/services/localStorage.service';

export const getFirstUrlWithAccess = () => {
  let urlWithPermission;

  for (const route of RoutesSlumpMix) {
    if (route.children) {
      for (const child of route.children) {
        if (hasAccessByRoles(child.roles)) {
          urlWithPermission = child.path;
          break;
        }
      }
      if (urlWithPermission) {
        break;
      }
    }

    if (route.roles && hasAccessByRoles(route.roles)) {
      urlWithPermission = route.path;
      break;
    }

    if (route.roles && hasAccessByRoles(route.roles)) {
      urlWithPermission = route.path;
      break;
    }
  }

  return urlWithPermission;
};

export const hasAccessByRole = (role: string | undefined): boolean => {
  const user = readUser();
  if (!role) return true;
  return user?.type === role ? true : false;
};

export const hasAccessByRoles = (roles: string[] | undefined): boolean => {
  const user = readUser();
  if (!roles) return true;
  return roles.includes(user?.type ?? '') ? true : false;
};

export const hasBetaAccess = (claims: ClaimModel[]): boolean => {
  if (claims.length <= 0) return false;
  const beta = claims.filter((c) => c.claimType === 'beta' && c.claimValue === '1');
  if (beta.length <= 0) return false;

  return true;
};

export const hasAccessByPermission = (role: string, permission: string): boolean => {
  return true;
  // const user = readUser();

  // return user?.claims?.find((claim) => role.includes(claim.claimType) && permission == claim.claimValue) ? true : false;
};

export const hasFactoryAccess = (): boolean => {
  const user = readUser();
  return user?.type && (user?.type === UserType.Factory || user?.type === UserType.FactoryAdmin) ? true : false;
};

export const hasAdminAccess = (): boolean => {
  const user = readUser();
  return user?.type && user?.type === UserType.FactoryAdmin ? true : false;
};
