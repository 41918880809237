import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Col, Row } from 'antd';
import styled from 'styled-components';

export const ConfigUpdateCardStepTwoWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 11.125rem;
  border: 0.063rem solid var(--disabled-bg-color);
  border-radius: 0.5rem;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 1.5rem;
  margin-bottom: 2rem;
  max-height: calc(100vh - 23rem);
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ConfigUpdateCardStepTwoForm = styled(BaseForm)`
  width: 100%;
`;

export const ConfigUpdateCardStepTwoRow = styled(Row)`
  width: 100%;
  height: 100%;
`;

export const ConfigUpdateCardStepTwoCol = styled(Col)`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;
