import {
  CanVehicleManufacturerModel,
  CanVehicleModel,
  CanVehicleNameId,
  CanVehicleParametersCalculatedModel,
  CanVehicleParametersOnOffModel,
} from '@app/domain/canVehicle/canVehicle';
import { ApiServiceBase, IApiServiceBase } from './base/apiServiceBase';

export default interface ICanVehicleService extends IApiServiceBase<CanVehicleModel, CanVehicleModel> {
  getCanVehicleManufacturer(): Promise<CanVehicleManufacturerModel[]>;
  getCanVehicleNameId(): Promise<CanVehicleNameId[]>;
  getCanVehicleParametersCalculated(idCanVehicle: number): Promise<CanVehicleParametersCalculatedModel[]>;
  getCanVehicleParametersOnOff(idCanVehicle: number): Promise<CanVehicleParametersOnOffModel[]>;
}

export class CanVehicleService extends ApiServiceBase<CanVehicleModel, CanVehicleModel> {
  constructor() {
    super('veiculoscan');
  }

  async getCanVehicleManufacturer(): Promise<CanVehicleManufacturerModel[]> {
    try {
      return (await super.get('fabricante')) as unknown as Promise<CanVehicleManufacturerModel[]>;
    } catch (error) {
      throw error;
    }
  }

  async getCanVehicleNameId(): Promise<CanVehicleNameId[]> {
    try {
      return (await super.get('nomeId')) as unknown as Promise<CanVehicleNameId[]>;
    } catch (error) {
      throw error;
    }
  }
  async getCanVehicleParametersCalculated(idCanVehicle: number): Promise<CanVehicleParametersCalculatedModel[]> {
    try {
      return (await super.get(`obter-nomeId-parametro-calculado/${idCanVehicle}`)) as unknown as Promise<
        CanVehicleParametersCalculatedModel[]
      >;
    } catch (error) {
      throw error;
    }
  }

  async getCanVehicleParametersOnOff(idCanVehicle: number): Promise<CanVehicleParametersOnOffModel[]> {
    try {
      return (await super.get(`obter-nomeId-parametro-ligado-desligado/${idCanVehicle}`)) as unknown as Promise<
        CanVehicleParametersOnOffModel[]
      >;
    } catch (error) {
      throw error;
    }
  }
}
