import React, { useState, useCallback, useEffect } from 'react';
import { ConfigUpdateDeviceModel, ConfigUpdateModel } from '@app/domain/deviceConfigUpdate/deviceConfigUpdate';
import { Description } from '@app/components/common/Description/Description';
import { Col, Row, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import IEquipmentService, { EquipmentService } from '@app/services/equipmentService';
import IModelDeviceService, { ModelDeviceService } from '@app/services/modelDeviceService';
import { notificationController } from '@app/controllers/notificationController';
import { ModelDeviceModel } from '@app/domain/modelDevice/modelDeviceModel';
import { EquipmentModel } from '@app/domain/equipment/equipmentModel';
import { Select } from '@app/components/common/selects/Select/Select';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Button } from '@app/components/common/buttons/Button/Button';
import {
  CardInfoContainer,
  CardInfoTagBgBlue,
  CardTitle,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import IFirmwareGroupService, { FirmwareGroupService } from '@app/services/firmwareGroupService';
import { FirmwareGroupModel } from '@app/domain/firmwareGroup/firmwareGroupModel';
import { ReactComponent as GroupDevicesIcon } from '@app/assets/icons/devices-icon.svg';
import { ReactComponent as SettingsCellIcon } from '@app/assets/icons/settings_cell.svg';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import { ModalAddDevice } from '../modals/modal-add-device';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import IFactoryDeviceService, { FactoryDeviceService } from '@app/services/factoryDeviceService';
import { ModalAddGroupDevice } from '../modals/modal-add-group-device';
import { DeviceGroupModel } from '@app/domain/deviceGroup/deviceGroupModel';
import IDeviceGroupService, { DeviceGroupService } from '@app/services/deviceGroupService';

interface ICreateStepOne {
  openModalEdit: boolean;
  setOpenModalEdit: React.Dispatch<React.SetStateAction<boolean>>;
  deviceConfigUpdate: ConfigUpdateModel;
  setDeviceConfigUpdate: React.Dispatch<React.SetStateAction<ConfigUpdateModel>>;
  setIncompatibleDevices: React.Dispatch<React.SetStateAction<ConfigUpdateDeviceModel[]>>;
}

const modelService: IModelDeviceService = new ModelDeviceService();
const equipmentService: IEquipmentService = new EquipmentService();
const deviceService: IFactoryDeviceService = new FactoryDeviceService();
const deviceGroupService: IDeviceGroupService = new DeviceGroupService();
const firmwareGroupService: IFirmwareGroupService = new FirmwareGroupService();

export const CreateDeviceConfigUpdateStepOne: React.FC<ICreateStepOne> = ({
  openModalEdit,
  setOpenModalEdit,
  deviceConfigUpdate,
  setDeviceConfigUpdate,
  setIncompatibleDevices,
}) => {
  const [models, setModels] = useState<ModelDeviceModel[]>([]);
  const [equipment, setEquipment] = useState<EquipmentModel>();
  const [devices, setDevices] = useState<FactoryDeviceModel[]>([]);
  const [deviceGroups, setDeviceGroups] = useState<DeviceGroupModel[]>([]);
  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState<number[]>([]);
  const [selectEquipmentsLoading, setSelectEquipmentsLoading] = useState(false);
  const [selectModelsLoading, setSelectModelsLoading] = useState(false);
  const [selectGroupFirmwareLoading, setSelectGroupFirmwareLoading] = useState(false);
  const [firmwareGroups, setFirmwareGroups] = useState<FirmwareGroupModel[]>([]);
  const [modalDeviceVisible, setModalDeviceVisible] = useState(false);
  const [modalGroupDeviceVisible, setModalGroupDeviceVisible] = useState(false);
  const [equipments, setEquipments] = useState<EquipmentModel[]>([]);
  const [selectedRadioValue, setSelectedRadioValue] = useState<string>('');

  const fetchEquipments = useCallback(async () => {
    setSelectEquipmentsLoading(true);
    equipmentService
      .getArray('')
      .then((res) => {
        setEquipments(res);
        setSelectEquipmentsLoading(false);
      })
      .catch(() => {
        setSelectEquipmentsLoading(false);
        notificationController.error({
          message: 'Erro!',
          description: 'Houve um erro ao buscar os dados de equipamentos',
        });
      });
  }, []);

  const fetchModelsByIdEquipment = useCallback(async (idEquipment: number) => {
    setSelectModelsLoading(true);
    modelService
      .getArray('')
      .then((res) => {
        setSelectModelsLoading(false);
        setModels(res.filter((m) => m.idEquipamento == idEquipment));
      })
      .catch(() => {
        setSelectModelsLoading(false);
        notificationController.error({
          message: 'Erro!',
          description: 'Houve um erro ao buscar os dados de modelos',
        });
      });
  }, []);

  const fetchFirmwareGroupsByIdModel = useCallback(async (idModel: number) => {
    setSelectGroupFirmwareLoading(true);
    firmwareGroupService
      .getArray(`obter-todos-por-id-modelo/${idModel}`)
      .then((res) => {
        setSelectGroupFirmwareLoading(false);
        setFirmwareGroups(res);
      })
      .catch(() => {
        setSelectGroupFirmwareLoading(false);
        notificationController.error({
          message: 'Erro!',
          description: 'Houve um erro ao buscar os dados de grupos de firmware',
        });
      });
  }, []);

  const fetchDevices = useCallback(async (idFirmwareGroup: number) => {
    try {
      const responseDevices = await deviceService.getArray(`obter-todos-por-id-grupo-firmware/${idFirmwareGroup}`);
      setDevices(responseDevices);
      const responseGroups = await deviceGroupService.getArray('');
      setDeviceGroups(responseGroups);
    } catch (error) {
      notificationController.error({ message: 'Erro!', description: 'Houve um erro ao buscar os dados' });
    }
  }, []);

  const handleChangeDevices = (devices: ConfigUpdateDeviceModel[]) => {
    const firmwareGroupSelected = firmwareGroups.find((g) => g.id == deviceConfigUpdate.idGrupoFirmware);
    const compatibleDevices = devices.filter(
      (d) => firmwareGroupSelected?.versoes.find((f) => f.versao == d.versaoFirmware),
    );
    const incompatibleDevices = devices.filter(
      (d) => !firmwareGroupSelected?.versoes.find((f) => f.versao == d.versaoFirmware),
    );

    setDeviceConfigUpdate({
      ...deviceConfigUpdate,
      dispositivos: compatibleDevices,
    });

    setIncompatibleDevices(incompatibleDevices);
  };

  const handleChangeModel = (value: unknown) => {
    setIncompatibleDevices([]);

    setDeviceConfigUpdate({
      ...deviceConfigUpdate,
      idModelo: models.find((m) => m.id == value)?.id ?? 0,
      idGrupoFirmware: undefined,
      dispositivos: [],
    });
  };

  const handleChangeFirmwareGroup = (value: unknown) => {
    setIncompatibleDevices([]);

    setDeviceConfigUpdate({
      ...deviceConfigUpdate,
      idGrupoFirmware: value as number,
      dispositivos: [],
    });
  };

  useEffect(() => {
    fetchEquipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (equipment?.id) fetchModelsByIdEquipment(equipment.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipment]);

  useEffect(() => {
    if (deviceConfigUpdate.idModelo) fetchFirmwareGroupsByIdModel(deviceConfigUpdate.idModelo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceConfigUpdate.idModelo]);

  useEffect(() => {
    if (deviceConfigUpdate.idGrupoFirmware) fetchDevices(deviceConfigUpdate.idGrupoFirmware);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceConfigUpdate.idGrupoFirmware]);

  useEffect(() => {
    if (openModalEdit) {
      selectedRadioValue === '0' ? setModalDeviceVisible(true) : setModalGroupDeviceVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalEdit]);

  useEffect(() => {
    setOpenModalEdit(!(!modalGroupDeviceVisible || !modalDeviceVisible));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalDeviceVisible, modalGroupDeviceVisible]);

  return (
    <>
      <ModalAddDevice
        devices={devices}
        visible={modalDeviceVisible}
        setVisible={setModalDeviceVisible}
        setIdsDevice={handleChangeDevices}
        configDevices={deviceConfigUpdate?.dispositivos}
      />
      <ModalAddGroupDevice
        groupDevices={deviceGroups}
        visible={modalGroupDeviceVisible}
        setVisible={setModalGroupDeviceVisible}
        setIdsDevice={handleChangeDevices}
        selectedsDeviceGroup={selectedDeviceGroup}
        setSelectedsDeviceGroup={setSelectedDeviceGroup}
      />

      <Description title="Etapa 1 de 2" subtitle="Após preencher os campos, clique em próxima etapa." />
      <CardInfoContainer>
        <CardInfoTagBgBlue>
          <Row align={'middle'}>
            <Col span={2}>
              <InfoCircleOutlined style={{ color: 'var(--primary-color)', fontSize: '1.5rem' }} />
            </Col>
            <Col span={22}>
              Os equipamentos desse agendamento serão atualizados baseados nos parâmetros selecionados.
            </Col>
          </Row>
        </CardInfoTagBgBlue>
        <CardTitle>Parâmetros da atualização</CardTitle>
        <BaseForm layout="vertical" style={{ width: '100%', marginTop: '1rem' }}>
          <Row gutter={6}>
            <Col xs={24} md={12}>
              <BaseFormInputItem label="Equipamento" errorText="Campo obrigatório">
                <Select
                  showArrow
                  showSearch
                  loading={selectEquipmentsLoading}
                  placeholder="Selecione o equipamento"
                  value={equipment?.id}
                  onChange={(value) => setEquipment(equipments.find((e) => e.id == value))}
                  options={equipments.map((c) => ({
                    value: c.id,
                    label: `${c.nome}`,
                  }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
            </Col>
            <Col xs={24} md={12}>
              <BaseFormInputItem label="Modelo" errorText="Campo obrigatório">
                <Select
                  showArrow
                  showSearch
                  loading={selectModelsLoading}
                  placeholder="Selecione o modelo"
                  disabled={!equipment}
                  value={deviceConfigUpdate?.idModelo}
                  onChange={handleChangeModel}
                  options={models.map((c) => ({
                    value: c.id,
                    label: `${c.nome}`,
                  }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <BaseFormInputItem label="Grupo de firmware" errorText="Campo obrigatório">
                <Select
                  showArrow
                  showSearch
                  loading={selectGroupFirmwareLoading}
                  placeholder="Selecione o grupo de firmware"
                  value={deviceConfigUpdate?.idGrupoFirmware}
                  disabled={!deviceConfigUpdate?.idModelo}
                  onChange={handleChangeFirmwareGroup}
                  options={firmwareGroups.map((c) => ({
                    value: c.id,
                    label: `${c.nome}`,
                  }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
        </BaseForm>
      </CardInfoContainer>
      {deviceConfigUpdate?.idGrupoFirmware && (
        <CardInfoContainer>
          <Row justify={'start'} style={{ width: '100%', marginBottom: '1rem' }}>
            <CardTitle>Adicionar</CardTitle>
          </Row>
          <Row>
            <RadioGroup value={selectedRadioValue} onChange={(e) => setSelectedRadioValue(e.target.value)}>
              <Space size={1} direction="vertical">
                <Radio value="0">Dispositivo(s)</Radio>
                {selectedRadioValue === '0' && (
                  <Button type="default" icon={<SettingsCellIcon />} onClick={() => setModalDeviceVisible(true)}>
                    Dispositivo
                  </Button>
                )}

                <Radio value="1" style={{ marginTop: '1rem' }}>
                  Grupo de dispositivos
                </Radio>
                {selectedRadioValue === '1' && (
                  <Button type="default" icon={<GroupDevicesIcon />} onClick={() => setModalGroupDeviceVisible(true)}>
                    Grupo de dispositivo
                  </Button>
                )}
              </Space>
            </RadioGroup>
          </Row>
        </CardInfoContainer>
      )}
    </>
  );
};
