import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ButtonsMapWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 60px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 1.12rem;
  z-index: 100;
  //margin-top: 10px;
  //margin-right: 10px;

  width: auto;
  height: auto;
`;
