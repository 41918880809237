import { styled } from 'styled-components';

export const CardDetails = styled.div`
  display: flex;
  padding: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;

  border-radius: 0.5rem;
  border: 1px solid var(--neutral-gray-gray-300, #bababa);
  background: #fff;
  margin-top: 1.125rem;
`;

export const CardProfileWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
`;

export const Title = styled.span`
  color: var(--neutral-gray-gray-800, #383838);
  font-family: 'Mulish';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
