import React from 'react';
import * as S from './HelperNotification.styles';
import { InfoCircleOutlined } from '@ant-design/icons';

interface HelperNotificationProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const HelperNotification: React.FC<HelperNotificationProps> = ({ children, style }) => {
  return (
    <S.Notification style={style}>
      <InfoCircleOutlined style={{ color: 'var(--primary-color)', fontSize: '1.5rem' }} />
      <S.TextInfo>{children}</S.TextInfo>
    </S.Notification>
  );
};
