import * as React from 'react';
const SvgIconManufacturing = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#a)">
      <path
        d="M16.93 6.34994L13.33 8.02994L11 5.69994V4.29994L13.33 1.96994L16.93 3.64994C17.31 3.82994 17.75 3.65994 17.93 3.28994C18.11 2.90994 17.94 2.46994 17.57 2.28994L13.65 0.459937C13.27 0.279937 12.82 0.359937 12.52 0.659937L10.78 2.39994C10.6 2.15994 10.32 1.99994 10 1.99994C9.45 1.99994 9 2.44994 9 2.99994V3.99994H5.82C5.4 2.83994 4.3 1.99994 3 1.99994C1.34 1.99994 0 3.33994 0 4.99994C0 6.09994 0.6 7.04994 1.48 7.57994L4.08 15.9999H3C1.9 15.9999 1 16.8999 1 17.9999V18.9999H14V17.9999C14 16.8999 13.1 15.9999 12 15.9999H10.38L5.41 6.76994C5.58 6.52994 5.72 6.27994 5.82 5.99994H9V6.99994C9 7.54994 9.45 7.99994 10 7.99994C10.32 7.99994 10.6 7.83994 10.78 7.59994L12.52 9.33994C12.82 9.63994 13.27 9.71994 13.65 9.53994L17.57 7.70994C17.95 7.52994 18.11 7.08994 17.93 6.70994C17.75 6.33994 17.31 6.16994 16.93 6.34994ZM3 5.99994C2.45 5.99994 2 5.54994 2 4.99994C2 4.44994 2.45 3.99994 3 3.99994C3.55 3.99994 4 4.44994 4 4.99994C4 5.54994 3.55 5.99994 3 5.99994ZM8.11 15.9999H6.17L3.71 7.99994H3.81L8.11 15.9999Z"
        fill="#ffff"
      />
    </g>
  </svg>
);
export default SvgIconManufacturing;
