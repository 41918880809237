import React from 'react';
import * as S from './Description.styles';
import { Tooltip } from 'antd';

export interface DescriptionProps {
  title?: string;
  subtitle?: string;
  icon?: JSX.Element;
  iconTooltipText?: string;
  iconOnClick?: () => void;
  children?: React.ReactNode;
}

export const Description: React.FC<DescriptionProps> = ({
  title,
  subtitle,
  icon,
  iconTooltipText = 'iconTooltipText',
  iconOnClick,
  children,
  ...props
}) => {
  return (
    <>
      <S.Container {...props}>
        <S.DescriptionWrap>
          <S.DescriptionWrapper>
            <S.Title>{title}</S.Title>
            <S.Subtitle>{subtitle}</S.Subtitle>
          </S.DescriptionWrapper>
          <Tooltip title={iconTooltipText}>
            <S.Button
              type="primary"
              shape="circle"
              icon={icon}
              size="small"
              onClick={() => {
                return iconOnClick ? iconOnClick() : null;
              }}
            />
          </Tooltip>
        </S.DescriptionWrap>

        {children}
      </S.Container>
    </>
  );
};
