import styled from 'styled-components';
import { Space as AntSpace, Input } from 'antd';
import { media } from '@app/styles/themes/constants';

const { Search } = Input;

export const SearchInput = styled(Search)`
  &.ant-input-prefix {
    margin: 0.5rem;
  }

  & .ant-input-search-button {
    box-shadow: none;
  }

  & input {
    font-weight: 700;
    background-color: var(--background-color);
    border: 1px solid var(--border-base);

    @media only screen and (${media.md}) {
      font-size: 1rem;
    }

    &::placeholder {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 24px;
    }
  }

  &.ant-input-group-addon {
    display: 'flex';
    align-content: 'start';
    min-width: 3.5rem;
    color: var(--primary-color);
  }

  .ant-input-search-button {
    //3 colors
    &.ant-btn {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;

      &:hover {
        background-color: var(--primary-color);
        border: 2px solid var(--primary-color);
      }
    }

    &.ant-btn .anticon {
      color: #ffffff;
    }

    width: 100%;
    background-color: var(--secondary-color);
    border: 1px solid var(--border-color);
    color: #ffffff;

    /* :hover {
      background-color: var(--primary-color);
      border: 2px solid var(--primary-color);
    } */
  }
`;

export const Space = styled(AntSpace)`
  & > .ant-space-item:last-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
