import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseForm } from '../../forms/BaseForm/BaseForm';
import { Col, Row } from 'antd';
import { BaseFormInputItem } from '../../forms/components/BaseFormInputItem/BaseFormInputItem';
import { Checkbox } from '../../Checkbox/Checkbox';
import { Select } from '../../selects/Select/Select';
import ICanVehicleService, { CanVehicleService } from '@app/services/canVehicleService';
import {
  CanVehicleManufacturerModel,
  CanVehicleModel,
  CanVehicleParametersCalculatedModel,
  CanVehicleParametersOnOffModel,
} from '@app/domain/canVehicle/canVehicle';
import { notificationController } from '@app/controllers/notificationController';
import { Panel } from '../../Collapse/Collapse';
import { Button } from '../../buttons/Button/Button';
import * as S from './CAN.styles';
import ModalParametersCan from './Modal/ModalParametersCan';

interface CanProps {
  idManufacturer?: number;
  setIdManufacturer: React.Dispatch<React.SetStateAction<number | undefined>>;
  idVehicle?: number;
  setIdVehicle: React.Dispatch<React.SetStateAction<number | undefined>>;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  selectedParametersCalculated: CanVehicleParametersCalculatedModel[];
  selectedParametersOnOff: CanVehicleParametersOnOffModel[];
  setSelectedParametersCalculated: React.Dispatch<React.SetStateAction<CanVehicleParametersCalculatedModel[]>>;
  setSelectedParametersOnOff: React.Dispatch<React.SetStateAction<CanVehicleParametersOnOffModel[]>>;
}

const canVehicleService: ICanVehicleService = new CanVehicleService();

export const CAN: React.FC<CanProps> = ({
  idManufacturer,
  setIdManufacturer,
  idVehicle,
  setIdVehicle,
  setIsValid,
  selectedParametersCalculated,
  selectedParametersOnOff,
  setSelectedParametersCalculated,
  setSelectedParametersOnOff,
}) => {
  const [canEnabled, setCanEnabled] = useState<boolean>(false);
  const [vehicles, setVehicles] = useState<CanVehicleModel[]>([]);
  const [manufacturers, setManufacturers] = useState<CanVehicleManufacturerModel[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCanVehicle, setSelectedCanVehicle] = useState<number>(0);
  const [parametersCalculated, setParametersCalculated] = useState<CanVehicleParametersCalculatedModel[]>([]);
  const [parametersOnOff, setParametersOnOff] = useState<CanVehicleParametersOnOffModel[]>([]);

  const fetchManufactures = useCallback(() => {
    canVehicleService
      .getCanVehicleManufacturer()
      .then((response) => setManufacturers(response))
      .catch(() => notificationController.error({ message: 'Erro ao buscar os fabricantes' }));
  }, []);
  const fetchVehicles = useCallback((idManufacturer: number) => {
    canVehicleService
      .getArray(`obter-veiculos-por-id-fabricante/${idManufacturer}`)
      .then((response) => setVehicles(response.filter((m) => m.status)))
      .catch(() => notificationController.error({ message: 'Erro ao buscar os veículo' }));
  }, []);
  const handleChangeCheckboxEnableCan = (newCanEnabled: boolean) => {
    if (newCanEnabled) {
      fetchManufactures();
      setIsValid(false);
    } else {
      setIsValid(true);
      setManufacturers([]);
      setVehicles([]);
      setIdManufacturer(undefined);
      setIdVehicle(undefined);
    }
    setCanEnabled(newCanEnabled);
  };

  useMemo(() => {
    if ((canEnabled && idVehicle) || !canEnabled) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idVehicle]);

  useMemo(() => {
    if (idManufacturer) fetchVehicles(idManufacturer);
  }, [idManufacturer, fetchVehicles]);

  //faz a verificar no primeiro load, para trazer os dados dos combo
  useEffect(() => {
    if (idManufacturer && idVehicle) {
      fetchManufactures();
      setCanEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCanVehicle > 0) {
      fetchCanVehiclesParametersCalculatedNameId(selectedCanVehicle);
      fetchCanVehiclesParametersOnOffNameId(selectedCanVehicle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCanVehicle]);

  const fetchCanVehiclesParametersCalculatedNameId = useCallback(async (idVehicle: number) => {
    try {
      const response = await canVehicleService.getCanVehicleParametersCalculated(idVehicle);
      setParametersCalculated(response);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um problema ao buscar a lista de parâmetros calculado.',
      });
    }
  }, []);

  const fetchCanVehiclesParametersOnOffNameId = useCallback(async (idVehicle: number) => {
    try {
      const response = await canVehicleService.getCanVehicleParametersOnOff(idVehicle);
      setParametersOnOff(response);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um problema ao buscar a lista de parâmetros On/Off.',
      });
    }
  }, []);

  return (
    <>
      <ModalParametersCan
        visible={modalVisible}
        setVisible={setModalVisible}
        setSelectedParametersCalculated={setSelectedParametersCalculated}
        setSelectedParametersOnOff={setSelectedParametersOnOff}
        parametersCalculated={parametersCalculated}
        parametersOnOff={parametersOnOff}
      />
      <BaseForm layout="vertical">
        <Row>
          <BaseFormInputItem>
            <Checkbox checked={canEnabled} onChange={() => handleChangeCheckboxEnableCan(!canEnabled)}>
              Habilitar CAN
            </Checkbox>
          </BaseFormInputItem>
        </Row>
        <Row gutter={18}>
          <Col xs={24} md={5}>
            <BaseFormInputItem label="Fabricante">
              <Select
                showArrow
                showSearch
                disabled={!canEnabled}
                value={idManufacturer}
                placeholder="Selecione o fabricante"
                onChange={(value) => setIdManufacturer(value as number | undefined)}
                options={manufacturers.map((m) => ({ label: m.nome, value: m.id }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              ></Select>
            </BaseFormInputItem>
          </Col>
          <Col xs={24} md={5}>
            <BaseFormInputItem
              label="Veículo"
              errorText="Campo obrigatório"
              isSuccess={!canEnabled ? undefined : !idVehicle && idManufacturer ? false : undefined}
            >
              <Select
                showArrow
                showSearch
                placeholder="Selecione o veículo"
                value={idVehicle}
                onChange={(value) => {
                  setIdVehicle(vehicles.find((v) => v.id == Number(value))?.id);
                  setSelectedCanVehicle(vehicles.find((v) => v.id == Number(value))?.id ?? 0);
                }}
                disabled={!idManufacturer}
                options={vehicles.map((m) => ({ label: `${m.nome} | ${m.ano}`, value: m.id }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              ></Select>
            </BaseFormInputItem>
          </Col>
        </Row>
        {canEnabled && idVehicle && (
          <Row gutter={[18, 18]}>
            <Col xs={24}>
              <S.Collapse>
                <Panel
                  key="1"
                  header={
                    <Row align={'middle'} justify={'space-between'}>
                      <Col>
                        <S.CollapseHeaderName>Parâmetros da CAN</S.CollapseHeaderName>
                      </Col>
                      <Button type="ghost" onClick={() => setModalVisible(true)}>
                        Selecionar parâmetros
                      </Button>
                    </Row>
                  }
                >
                  {selectedParametersCalculated.length > 0 && (
                    <>
                      <S.Subtitle>Calculado</S.Subtitle>
                      <Row style={{ paddingTop: '1rem' }}>
                        <S.ParametersQuantity>{selectedParametersCalculated?.length}</S.ParametersQuantity>&nbsp;
                        <S.ParametersQuantityDescription>
                          {selectedParametersCalculated?.length > 1 ? 'Parâmetros' : 'Parâmetro'}
                        </S.ParametersQuantityDescription>
                      </Row>
                      <Row gutter={[16, 16]} style={{ paddingBottom: '1rem' }}>
                        {selectedParametersCalculated.map((parameter) => (
                          <Col key={parameter.nomeId} xs={24} md={4} lg={4}>
                            <S.CanVehiclesParameterName>{parameter.nomeCAN}</S.CanVehiclesParameterName>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                  {selectedParametersOnOff.length > 0 && (
                    <>
                      <S.Subtitle>On/Off</S.Subtitle>
                      <Row style={{ paddingTop: '1rem' }}>
                        <S.ParametersQuantity>{selectedParametersOnOff?.length}</S.ParametersQuantity>&nbsp;
                        <S.ParametersQuantityDescription>
                          {selectedParametersOnOff?.length > 1 ? 'Parâmetros' : 'Parâmetro'}
                        </S.ParametersQuantityDescription>
                      </Row>
                      <Row gutter={[16, 16]}>
                        {selectedParametersOnOff.map((parameter) => (
                          <Col key={parameter.nomeId} xs={24} md={4} lg={4}>
                            <S.CanVehiclesParameterName>{parameter.nomeCAN}</S.CanVehiclesParameterName>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                </Panel>
              </S.Collapse>
            </Col>
          </Row>
        )}
      </BaseForm>
    </>
  );
};
