/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch } from '@app/hooks/reduxHooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import * as S from './components/ViewDeviceFilters/viewDevice.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useMounted } from '@app/hooks/useMounted';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Col, Row } from 'antd';
import {
  ViewConfigUpdateHistoryGroupedByModuleModel,
  ViewConfigUpdateHistoryGroupedModel,
  ViewConfigUpdateHistoryModel,
  ViewConfigUpdateModule,
} from '@app/domain/device/viewConfigUpdateHistoryModel';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { ViewDeviceModules } from '@app/constants/enums/viewDevice/viewDeviceModules';
import { Tag } from '@app/components/common/Tag/Tag';
import { ViewDeviceModuleStatus } from '@app/constants/enums/viewDevice/viewDeviceModuleStatus';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { TableWithoutPagination } from '@app/components/common/TableWithoutPagination/TableWithoutPagination';
import { Button } from '@app/components/common/buttons/Button/Button';
import IDeviceHistoryService, { DeviceHistoryService } from '@app/services/deviceHistoryService';

const deviceHistoryService: IDeviceHistoryService = new DeviceHistoryService();

export const DeviceViewConfigUpdate: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMounted } = useMounted();
  const { id, idUpdate } = useParams();
  const [loading, setLoading] = useState(false);
  const [deviceConfigUpdateProperties, setDeviceConfigUpdateProperties] = useState<ViewConfigUpdateHistoryModel[]>([]);
  const [properties, setProperties] = useState<ViewConfigUpdateHistoryModel[]>([]);
  const [updateConfigHistoryGroupedByModule, setUpdateConfigHistoryGroupedByModule] = useState<
    ViewConfigUpdateHistoryGroupedByModuleModel[]
  >([]);
  const [initialPage, setInitialPage] = useState<number>(5);

  const columnsProperties: ColumnsType<ViewConfigUpdateHistoryModel> = [
    {
      title: 'Aba',
      dataIndex: 'modulo',
      showSorterTooltip: false,
      width: '3%',
    },
    {
      title: 'Campo',
      dataIndex: 'propriedade',
      showSorterTooltip: false,
      width: '10%',
    },
    {
      title: 'Original',
      dataIndex: 'valorPropriedadeAnterior',
      showSorterTooltip: false,
      width: '10%',
    },
    {
      title: 'Alterado',
      dataIndex: 'valorPropriedade',
      showSorterTooltip: false,
      width: '10%',
    },
  ];

  const columnsHeader: ColumnsType<ViewConfigUpdateHistoryGroupedByModuleModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: false,
      width: '3%',
    },
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      width: '13%',
      render: (_, updateConfig) =>
        updateConfig.dataHora ? moment(updateConfig.dataHora).format('DD/MM/yyyy HH:mm') : '-',
    },
    {
      title: 'Usuário',
      dataIndex: 'usuario',
      showSorterTooltip: false,
      width: '10%',
    },
    {
      title: 'Rede',
      dataIndex: 'idModulo',
      showSorterTooltip: false,
      width: '12%',
      render: (_, updateConfig) => getUpdateConfigModule(updateConfig.id ?? 0, ViewDeviceModules.Rede),
    },
    {
      title: 'Entrada',
      dataIndex: 'idModulo',
      showSorterTooltip: false,
      width: '12%',
      render: (_, updateConfig) => getUpdateConfigModule(updateConfig.id ?? 0, ViewDeviceModules.Entrada),
    },
    {
      title: 'Perfil de envio',
      dataIndex: 'idModulo',
      showSorterTooltip: false,
      width: '12%',
      render: (_, updateConfig) => getUpdateConfigModule(updateConfig.id ?? 0, ViewDeviceModules.Perfil),
    },
    {
      title: 'Eventos',
      dataIndex: 'idModulo',
      showSorterTooltip: false,
      width: '12%',
      render: (_, updateConfig) => getUpdateConfigModule(updateConfig.id ?? 0, ViewDeviceModules.Eventos),
    },
    {
      title: 'Veículos CAN',
      dataIndex: 'idModulo',
      showSorterTooltip: false,
      width: '12%',
      render: (_, updateConfig) => getUpdateConfigModule(updateConfig.id ?? 0, ViewDeviceModules.CAN),
    },
    {
      title: 'Cercas',
      dataIndex: 'idModulo',
      showSorterTooltip: false,
      width: '12%',
      render: (_, updateConfig) => getUpdateConfigModule(updateConfig.id ?? 0, ViewDeviceModules.Cercas),
    },
  ];

  const getUpdateConfigModule = (id: number, modulo: string) => {
    const updateConfigHistory = updateConfigHistoryGroupedByModule.find((u) => u.id === id);
    if (updateConfigHistory === undefined) {
      return '-';
    }

    const moduleExists = updateConfigHistory?.modulos?.find((m) => m.modulo === modulo);

    if (moduleExists === undefined) {
      return (
        <Tag color="#E8E8E8" style={{ color: '#383838', width: 'auto', textAlign: 'center' }}>
          {ViewDeviceModuleStatus.SemAlteracao}
        </Tag>
      );
    }

    switch (moduleExists.status) {
      case ViewDeviceModuleStatus.Atualizado:
        return (
          <Tag color="#E9F4EE" style={{ color: '#083F18', width: 'auto', textAlign: 'center' }}>
            {ViewDeviceModuleStatus.Atualizado}
          </Tag>
        );
        break;
      case ViewDeviceModuleStatus.Pendente:
        return (
          <Tag color="#FEF7E7" style={{ color: '#644508', width: 'auto', textAlign: 'center' }}>
            {ViewDeviceModuleStatus.Pendente}
          </Tag>
        );
        break;
      case ViewDeviceModuleStatus.Expirado:
        return (
          <Tag color="#F4EAFF" style={{ color: '#52025F', width: 'auto', textAlign: 'center' }}>
            {ViewDeviceModuleStatus.Expirado}
          </Tag>
        );
        break;
      default:
        return (
          <Tag color="#E8E8E8" style={{ color: '#383838', width: 'auto', textAlign: 'center' }}>
            {ViewDeviceModuleStatus.SemAlteracao}
          </Tag>
        );
        break;
    }
  };

  const handleBackClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/meus-dispositivos/visualizar/${id}`);
  };

  const fetchDeviceConfigUpdateParameters = useCallback(async () => {
    setLoading(true);

    deviceHistoryService
      .getConfigUpdateHistory(`${idUpdate}`)
      .then((res: ViewConfigUpdateHistoryModel[]) => {
        if (isMounted.current) {
          setDeviceConfigUpdateProperties(res);
          setProperties(res.slice(0, 5));
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({
          message: `Erro ao obter os parâmetros atualizados da atualização selecionada. ${err}`,
        });
      });
  }, [isMounted]);

  const fetchUpdateConfigHistory = async () => {
    setLoading(true);

    deviceHistoryService
      .getConfigUpdateGroupedHistory(`${id}`)
      .then((res: ViewConfigUpdateHistoryGroupedModel[]) => {
        if (isMounted.current) {
          groupAndFilterConfigUpdateHistoryByModule(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({
          message: `Erro ao obter histórico de atualizações de parâmetros do perfil. ${err}`,
        });
      });
  };

  const groupAndFilterConfigUpdateHistoryByModule = (res: ViewConfigUpdateHistoryGroupedModel[]) => {
    const group: ViewConfigUpdateHistoryGroupedByModuleModel[] = [];
    res.forEach((r) => {
      if (group.findIndex((g) => g.id === r.id) === -1) {
        group.push({
          id: r.id,
          dataHora: r.dataHora,
          usuario: r.usuario,
          modulos:
            res.map((m) => {
              if (m.id === r.id) {
                const objeto = {
                  idModulo: m.idModulo ?? 0,
                  modulo: m.modulo ?? '',
                  status: m.status ?? '',
                };
                return objeto;
              } else {
                return {} as ViewConfigUpdateModule;
              }
            }) ?? [],
        });
      }
    });
    setUpdateConfigHistoryGroupedByModule(group.filter((g) => g.id == idUpdate));
  };

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: 'Histórico - Atualizações dos parâmetros do perfil',
        handleBackClick: handleBackClick,
      }),
    );
    fetchUpdateConfigHistory();
    fetchDeviceConfigUpdateParameters();
  }, []);

  return (
    <>
      <Spinner spinning={loading}>
        <PageContainer>
          <S.Container>
            <h1>Informações</h1>
            <TableWithoutPagination
              columns={columnsHeader}
              dataSource={updateConfigHistoryGroupedByModule}
              scroll={{ x: 800 }}
              bordered
            />
          </S.Container>
          <S.TableContainer>
            {properties.length > 0 ? (
              <>
                <S.Container>
                  <h1>Campos alterados</h1>
                  <TableWithoutPagination
                    columns={columnsProperties}
                    dataSource={properties}
                    scroll={{ x: 800 }}
                    bordered
                  />
                </S.Container>
                <S.ButtonContainer>
                  <Button
                    type="primary"
                    style={{ width: '14.75rem' }}
                    onClick={() => {
                      setProperties([...deviceConfigUpdateProperties.slice(0, initialPage + 5)]);
                      setInitialPage((prevState) => prevState + 5);
                    }}
                  >
                    Ver mais
                  </Button>
                </S.ButtonContainer>
              </>
            ) : (
              <Row justify="center" align="middle">
                <Col
                  xs={24}
                  md={24}
                  style={{ color: '#707070', fontSize: '1rem', fontWeight: 700, textAlign: 'center' }}
                >
                  Nenhuma campo alterado
                </Col>
              </Row>
            )}
          </S.TableContainer>
        </PageContainer>
      </Spinner>
    </>
  );
};
