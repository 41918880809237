/* eslint-disable @typescript-eslint/no-unused-vars */
import { Spinner } from '@app/components/common/Spinner/Spinner';
import React, { useState } from 'react';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Button, Col, Input, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ReactComponent as ExportCsvIcon } from '@app/assets/icons/export-csv.svg';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { ExportButtonContainer } from '@app/pages/tags-group/index.styles';
import { TableData } from '@app/pages/tags-group/components/TableData/TableData';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { ViewTagDeviceRelationshioHistoryModel } from '@app/domain/device/viewTagDeviceRelationshioHistoryModel';

interface IViewIndividualTagProfile {
  individualTagHistory: ViewTagDeviceRelationshioHistoryModel[];
  device: FactoryDeviceModel | null;
}

export const ViewTagIndividualHistory: React.FC<IViewIndividualTagProfile> = ({ device, individualTagHistory }) => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    const inputValue = event.currentTarget.value.toLowerCase();
    setSearchValue(inputValue);
  };

  const handleExportDataExcel = async () => {
    const columns = [{ title: 'Tag Serial', dataIndex: 'tagId' }];

    const data = individualTagHistory
      ? individualTagHistory.map((tag) => ({
          tagSerial: tag.tagSerial,
        }))
      : [];

    await genericExportToExcel('tag_individual', columns, data);
  };

  const filteredData = searchValue
    ? individualTagHistory.filter((tag) => tag.tagSerial?.toLowerCase().includes(searchValue))
    : individualTagHistory;

  const tableData = filteredData.map((tag) => tag.tagSerial);

  return (
    <Spinner spinning={loading}>
      <BaseForm layout="vertical" style={{ width: '100%' }}>
        <Row gutter={[10, 10]} align="middle">
          <Col span={24}>
            <Row gutter={[10, 10]} align="middle" justify="space-between">
              <Col xl={4} md={4} xs={6} sm={24}>
                <BaseFormInputItem label="Pesquisa">
                  <Input
                    suffix={<SearchOutlined style={{ marginRight: '1rem', color: '#545454' }} />}
                    placeholder="Pesquisar por tag ID"
                    onChange={handleSearchFilter}
                  />
                </BaseFormInputItem>
              </Col>
              <Col xl={3} md={3} xs={4} sm={24} style={{ marginBottom: '1.25rem' }}>
                <Button type="link" onClick={handleExportDataExcel}>
                  <ExportButtonContainer>
                    <ExportCsvIcon />
                    Exportar dados excel
                  </ExportButtonContainer>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <TableData
          key="1"
          header={
            <Row align={'middle'} justify={'space-between'}>
              <Col>{`SN  ${device?.numeroSerie}`}</Col>
            </Row>
          }
          data={tableData}
        />
      </BaseForm>
    </Spinner>
  );
};
