import styled from 'styled-components';
import { Button as AntButton } from '@app/components/common/buttons/Button/Button';
import { ButtonProps } from '@app/components/common/Toolbar/Toolbar';
export const ContainerConfigNewDevice = styled.div`
  padding: 0 2rem 2rem;
  width: 35rem;
  flex: 0 0 auto;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  border-right: 1px solid var(--disabled-bg-color);
  height: 100%;

  @media (max-width: 90rem) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--disabled-bg-color);
  }
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  padding: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
  margin-right: 10px;

  border-radius: 0.5rem;
  border: 1px solid var(--neutral-gray-gray-300, #bababa);
`;

export const Notification = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem;
  margin-bottom: 0.4rem;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--brand-colors-blue-blue-500, #263668);
  background: rgba(202, 210, 236, 0.6);
`;

export const TextInfo = styled.span`
  color: var(--brand-colors-blue-blue-800, #0f162a);
  font-family: Mulish;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
  margin-left: 1rem;
`;

export const TwoColumnsInputContainer = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
  justify-content: space-between;
  width: 100%;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 4px;
  width: 100%;
`;

export const InputLabel = styled.label`
  color: var(--neutral-gray-gray-700, #545454);
  font-family: Mulish;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Button = styled(AntButton)<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14.75rem;

  border-radius: 0.5rem;
  border: 1px solid var(--neutral-gray-gray-500, #8c8c8c);
  background: var(--neutral-gray-pure-white, #fff);

  color: var(--neutral-gray-gray-700, #545454);
  font-family: 'Mulish';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
`;
