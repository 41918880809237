import { BaseForm } from '../../forms/BaseForm/BaseForm';
import { Col, Row } from 'antd';
import { BaseFormInputItem } from '../../forms/components/BaseFormInputItem/BaseFormInputItem';
import { useEffect, useState } from 'react';
import IFenceService, { FenceService } from '@app/services/fenceService';
import { FenceGroupModel } from '@app/domain/fence/fenceGroupModel';
import { FenceModel } from '@app/domain/fence/fenceModel';
import { notificationController } from '@app/controllers/notificationController';
import MapComponentView from '@app/pages/fence/view/components/MapComponentView/MapComponentView';
import { Panel } from '../../Collapse/Collapse';
import * as S from './Cerca.styles';
import { groupBy } from 'lodash';
import { CardInfoTitle, CardInfoValue } from '../../Card/CardInfo/CardDeviceInformation.styles';
import * as MS from '../ModulesProfileConfig.styles';
import { Select } from '../../selects/Select/Select';
import { Modal } from '../../Modal/Modal';
import { Button } from '../../buttons/Button/Button';
import { IconMap } from '@app/assets/slump-icons';

interface CercaProps {
  idGroup?: number;
}

const fenceService: IFenceService = new FenceService();

export const CercaView: React.FC<CercaProps> = ({ idGroup }) => {
  const [enableGroup, setEnableGroup] = useState<boolean>(false);
  const [group, setGroup] = useState<FenceGroupModel>();
  const [fences, setFences] = useState<FenceModel[]>([]);
  const [modalMapVisible, setModalMapVisible] = useState<boolean>(false);

  const fetchGroupId = (id: number) => {
    fenceService
      .get(`/grupos/${id}`)
      .then((response) => {
        setGroup(response);
        setFences(response.cercas ?? []);
      })
      .catch((err) => {
        notificationController.error({ message: `Erro ao buscar cerca. ${err}` });
      });
  };

  useEffect(() => {
    if (idGroup && idGroup != 0) {
      setEnableGroup(true);
      fetchGroupId(idGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idGroup]);

  return (
    <BaseForm layout="vertical">
      <Modal
        title="Visualizar mapa"
        open={modalMapVisible}
        width={'100%'}
        onCancel={() => setModalMapVisible(false)}
        footer
      >
        <Row>
          <Col xs={24} sm={12} md={6}>
            <CardInfoValue>Grupo</CardInfoValue>
            &nbsp;<CardInfoTitle>{group?.nome}</CardInfoTitle>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <CardInfoValue>Quantidade de cercas</CardInfoValue>
            &nbsp;<CardInfoTitle>{fences?.length}</CardInfoTitle>
          </Col>
        </Row>
        <MapComponentView showInactiveFences={false} fences={fences} />
      </Modal>

      <Row>
        <BaseFormInputItem>
          <MS.CheckboxDisabled checked={enableGroup}>
            <MS.CheckboxLabel>Adicionar grupo</MS.CheckboxLabel>
          </MS.CheckboxDisabled>
        </BaseFormInputItem>
      </Row>

      {idGroup && group && (
        <>
          <Row gutter={18} style={{ marginBottom: '1rem' }}>
            <Col xs={24} md={5}>
              <BaseFormInputItem
                label="Grupo de cercas"
                errorText="Campo obrigatório"
                isSuccess={!enableGroup || idGroup ? undefined : false}
              >
                <Select
                  disabled
                  value={idGroup}
                  placeholder="Selecione o grupo"
                  options={[{ value: group?.id, label: group?.nome }]}
                ></Select>
              </BaseFormInputItem>
            </Col>
          </Row>

          <Row gutter={[18, 18]}>
            <Col xs={24}>
              <S.CollapseMap defaultActiveKey={1}>
                <Panel
                  key="1"
                  header={
                    <Row align={'middle'} justify={'space-between'}>
                      <Col>
                        <S.CollapseHeaderName>Cercas do grupo</S.CollapseHeaderName>
                      </Col>
                      <Col>
                        <Button
                          style={{ width: '14.75rem' }}
                          type="ghost"
                          icon={<IconMap />}
                          onClick={() => setModalMapVisible(true)}
                        >
                          Visualizar mapa
                        </Button>
                      </Col>
                    </Row>
                  }
                >
                  {Object.keys(groupBy(fences, (f) => f.tipoCerca)).map((f) => {
                    const fencesFiltered = fences.filter((fence) => fence.tipoCerca == f);
                    return (
                      <div key={f} style={{ paddingBottom: '1rem' }}>
                        <Row>
                          <S.TableTypeName>{f}</S.TableTypeName>
                        </Row>
                        <Row>
                          <S.TableFenceQuantity>{fencesFiltered?.length}</S.TableFenceQuantity>&nbsp;
                          <S.TableFenceDescription>
                            {fencesFiltered?.length > 1 ? 'Cercas' : 'Cerca'}
                          </S.TableFenceDescription>
                        </Row>
                        <Row gutter={[18, 18]} style={{ overflowY: 'auto', maxHeight: '30rem', width: '100%' }}>
                          {fencesFiltered?.map((item, index) => {
                            return (
                              <Col key={index} xs={12} xl={6}>
                                <S.TableFenceName>{item.nome}</S.TableFenceName>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    );
                  })}
                </Panel>
              </S.CollapseMap>
            </Col>
          </Row>
        </>
      )}
    </BaseForm>
  );
};
