import { Col, Divider, Row } from 'antd';
import SkeletonButton from 'antd/lib/skeleton/Button';
import styled, { keyframes } from 'styled-components';
import { LegendIndicator } from '../../components.styles';

export const IndicatorCardSkeleton = styled(SkeletonButton)`
  height: 16rem;

  & .ant-skeleton-button {
    height: 100%;
  }
`;

export const EquipmentsContainerHeader = styled(Row)`
  width: 100%;
  margin-top: 1.25rem;
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #d1d1d1;
  justify-content: space-between;
  font-family: Mulish;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: left;
`;

export const EquipmentsContainerHeaderCol = styled(Col)`
  padding: 0.715rem 0rem 0.715rem 0rem;
`;
export const EquipmentsContainerHeaderDivider = styled(Divider)`
  height: 100%;
`;

export const EquipmentsContainerHeaderTitle = styled.div`
  font-weight: 600;
  color: #545454;
`;
export const EquipmentsContainerHeaderSubtitle = styled.div`
  font-weight: 500;
  color: #8c8c8c;
`;

export const EquipmentsContainerContentCard = styled.div`
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #bababa;
  height: 15rem;
  max-height: 15rem;
`;
export const EquipmentsContainerContentCardHeader = styled(Row)`
  font-family: Mulish;
  line-height: 1.5rem;
  text-align: left;
  align-items: baseline;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 0 1.5rem;
`;
export const EquipmentsContainerContentCardHeaderTitle = styled.div`
  font-size: 1.5rem;
  color: #263668;
  font-weight: 600;
`;
export const EquipmentsContainerContentCardHeaderSubtitle = styled.div`
  font-size: 1rem;
  color: #545454;
  font-weight: 600;

  & span {
    font-size: 1rem;
    color: #8c8c8c;
    font-weight: 500;
  }
`;
export const EquipmentsContainerContentCardList = styled(Row)`
  width: 100%;
  height: 9rem;
  margin-top: 1.5rem;
  padding: 0rem 0.75rem 0rem 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: Mulish;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-align: left;
  color: #545454;
  row-gap: 0.375rem;

  & span {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
    color: #8c8c8c;
  }
`;

interface EquipmentsContainerContentProgressProps {
  onlinePercent: number;
  offlinePercent: number;
  acessoryPercent: number;
}
const fillAnimation = (concludedPercent: number, leftPercent?: number) => keyframes`
  0% {
    width: 0;
    left: 0;
  }
  100% {
    width: ${concludedPercent}%;
    left: ${leftPercent ?? 0}%;
  }
`;

export const EquipmentsContainerContentProgressBarOnline = styled.div<EquipmentsContainerContentProgressProps>`
  content: '';
  height: 100%;
  background-color: #159d3b;
  animation: ${(props) => fillAnimation(props.onlinePercent ?? 0)} 4s ease forwards;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
`;

export const EquipmentsContainerContentProgressBarOffline = styled.div<EquipmentsContainerContentProgressProps>`
  content: '';
  height: 100%;
  background-color: #a71916;
  animation: ${(props) => fillAnimation(props.offlinePercent ?? 0, props.onlinePercent)} 4s ease forwards;
  position: absolute;
  top: 0;
  left: ${(props) => props.onlinePercent}%;
  width: 0;
`;

export const EquipmentsContainerContentProgressBarAcessory = styled.div<EquipmentsContainerContentProgressProps>`
  content: '';
  height: 100%;
  background-color: #263668;
  animation: ${(props) => fillAnimation(props.acessoryPercent ?? 0, props.onlinePercent + props.offlinePercent)} 4s ease
    forwards;
  position: absolute;
  top: 0;
  left: ${(props) => props.onlinePercent + props.offlinePercent}%;
  width: 0;
`;

export const EquipmentsContainerContentProgressContainer = styled.div`
  width: 100%;
  height: 1rem;
  border-radius: 0.375rem;
  overflow: hidden;
  position: relative;
`;

export const EquipmentsContainerContentProgressLegendTooltip = styled(LegendIndicator)`
  margin-top: 0.4rem;
`;
