import React, { useEffect, useMemo, useState } from 'react';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { DeviceProfilePropertyModel, PropertyValue } from '@app/domain/deviceProfile/deviceProfileModel';
import * as S from '../ModulesProfileConfig.styles';
import ShouldRenderInput from '@app/components/common/ModulesProfileConfig/ShouldRenderInput';
import { RadioGroup, Radio } from '@app/components/common/Radio/Radio';
import { Col, Form, Row, Space } from 'antd';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { CardInfoProperty } from '../../Card/CardInfo/CardDeviceInformation.styles';
import { Input } from '../../inputs/Input/Input';

interface RedeProps {
  properties: PropertyValue[];
  setProperties: React.Dispatch<React.SetStateAction<PropertyValue[]>>;
  moduleData: DeviceProfilePropertyModel[];
  true?: boolean;
  setPropertyValues?: (data: PropertyValue[]) => void;
}

const RedeView: React.FC<RedeProps> = ({ properties, setProperties, moduleData }) => {
  const [idMapping, setIdMapping] = useState<{ [key: number]: number }>({});
  const [primaryServer, setPrimaryServer] = useState<'IP' | 'DNS'>('IP');
  const [secondaryServer, setSecondaryServer] = useState<'IP' | 'DNS'>('IP');
  const [existsSecondaryServer, setExistsSecondaryServer] = useState<boolean>(true);

  const valoresDefaultPorId: { [key: string]: string } = useMemo(
    () => ({
      '7': 'm2m.pc.br',
      '8': '',
      '9': '',
      '13': '0.0.0.0',
      '43': '',
      '12': '0',
      '11': '1',
      '44': '0.0.0.0',
      '45': '',
      '46': '0',
      '47': '1',
    }),
    [],
  );

  useEffect(() => {
    const newIdMapping: { [key: number]: number } = {};
    moduleData.forEach((module) => {
      if (module.idEmbarcado && module.idPropriedade) {
        newIdMapping[module.idEmbarcado] = module.idPropriedade;
      }
    });
    setIdMapping(newIdMapping);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(idMapping).length > 0 && properties.length === 0) {
      const newProperties = Object.keys(idMapping).map((idEmbarcado) => {
        const idStr = idEmbarcado.toString(); // Converte o ID para string
        const valorPadrao = idStr in valoresDefaultPorId ? valoresDefaultPorId[idStr] : '';

        return {
          idPropriedade: idMapping[Number(idEmbarcado)],
          valorPropriedade: valorPadrao,
          idEmbarcado: Number(idEmbarcado),
        };
      });
      setProperties((prevState) => [...prevState, ...newProperties]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMapping]);

  useEffect(() => {
    setExistsSecondaryServer(!(!(propertyValue(44) || propertyValue(45)) && !propertyValue(46) && !propertyValue(47)));
    setPrimaryServer(!propertyValue(13) && propertyValue(43) ? 'DNS' : 'IP');
    setSecondaryServer(!propertyValue(44) && propertyValue(45) ? 'DNS' : 'IP');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMapping]);

  const propertyValue = (idEmbarcado: number) => {
    const idPropriedade = idMapping[idEmbarcado];
    const valorPropriedade = properties.find((property) => property.idPropriedade === idPropriedade)?.valorPropriedade;
    return valorPropriedade ? (valorPropriedade != 'null' ? valorPropriedade : '') : '';
  };

  return (
    <Form layout="vertical">
      <Row gutter={[18, 18]}>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={7}>
          <Col xs={24} md={8}>
            <CardInfoProperty>APN</CardInfoProperty>
            <Input
              key={3}
              type="text"
              className="ant-input"
              placeholder="Digite o valor"
              value={propertyValue(7)}
              disabled
            />
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={8}>
          <Col xs={24} md={8}>
            <CardInfoProperty>Usuário APN</CardInfoProperty>
            <Input
              key={3}
              type="text"
              className="ant-input"
              placeholder="Digite o valor"
              value={propertyValue(8).length > 0 ? propertyValue(8) : ' '}
              disabled
            />
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={9}>
          <Col xs={24} md={8}>
            <CardInfoProperty>Senha APN</CardInfoProperty>
            <Input
              key={3}
              type="text"
              className="ant-input"
              placeholder="Digite o valor"
              value={propertyValue(9).length > 0 ? propertyValue(9) : ' '}
              disabled
            />
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={13}>
          <Col xs={24} md={8}>
            <CardInfoProperty>Servidor primário</CardInfoProperty>
            <BaseFormInputItem>
              <RadioGroup value={primaryServer} disabled={true}>
                <Space direction="vertical">
                  <Radio value={'IP'}>IP</Radio>
                  <Radio value={'DNS'}>DNS</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        {
          {
            IP: (
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={13}>
                <Col xs={24} md={8}>
                  <CardInfoProperty>IP do servidor primário</CardInfoProperty>
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={propertyValue(13)}
                    disabled
                  />
                </Col>
              </ShouldRenderInput>
            ),
            DNS: (
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={43}>
                <Col xs={24} md={8}>
                  <CardInfoProperty>DNS do servidor primário</CardInfoProperty>
                  <Input
                    key={3}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={propertyValue(43)}
                    disabled
                  />
                </Col>
              </ShouldRenderInput>
            ),
          }[primaryServer]
        }
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={12}>
          <Col xs={24} md={8}>
            <CardInfoProperty>Porta do servidor primário</CardInfoProperty>
            <Input
              key={3}
              type="text"
              className="ant-input"
              placeholder="Digite o valor"
              value={propertyValue(12)}
              disabled
            />
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={11}>
          <Col xs={24} md={8}>
            <CardInfoProperty>Tipo do servidor primário</CardInfoProperty>
            <BaseFormInputItem>
              <RadioGroup key={11} disabled={true} value={propertyValue(11)}>
                <Space direction="vertical">
                  <Radio value={'1'}>TCP</Radio>
                  <Radio value={'2'}>UDP</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
      </Row>
      <Row>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={44}>
          <BaseFormInputItem style={{ minWidth: '400px', width: 'fit-content' }}>
            <Checkbox value={existsSecondaryServer} checked={existsSecondaryServer} disabled={true}>
              <S.CheckboxLabel>Servidor secundário</S.CheckboxLabel>
            </Checkbox>
          </BaseFormInputItem>
        </ShouldRenderInput>
      </Row>
      {existsSecondaryServer && (
        <Row gutter={[18, 18]}>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={44}>
            <Col xs={24} md={8}>
              <CardInfoProperty>Servidor secundário</CardInfoProperty>
              <BaseFormInputItem style={{ width: '10rem' }}>
                <RadioGroup value={secondaryServer} disabled={true}>
                  <Space direction="vertical">
                    <Radio value={'IP'}>IP</Radio>
                    <Radio value={'DNS'}>DNS</Radio>
                  </Space>
                </RadioGroup>
              </BaseFormInputItem>
            </Col>
          </ShouldRenderInput>
          {
            {
              IP: (
                <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={44}>
                  <Col xs={24} md={8}>
                    <CardInfoProperty>IP do servidor secundário</CardInfoProperty>
                    <Input
                      key={3}
                      type="text"
                      className="ant-input"
                      placeholder="Digite o valor"
                      value={propertyValue(44)}
                      disabled
                    />
                  </Col>
                </ShouldRenderInput>
              ),
              DNS: (
                <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={45}>
                  <Col xs={24} md={8}>
                    <CardInfoProperty>DNS do servidor secundário</CardInfoProperty>
                    <Input
                      key={3}
                      type="text"
                      className="ant-input"
                      placeholder="Digite o valor"
                      value={propertyValue(45)}
                      disabled
                    />
                  </Col>
                </ShouldRenderInput>
              ),
            }[secondaryServer]
          }
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={46}>
            <Col xs={24} md={8}>
              <CardInfoProperty>Porta do servidor secundário</CardInfoProperty>
              <Input
                key={3}
                type="text"
                className="ant-input"
                placeholder="Digite o valor"
                value={propertyValue(12)}
                disabled
              />
            </Col>
          </ShouldRenderInput>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={47}>
            <BaseFormInputItem label="Tipo servidor secundário">
              <RadioGroup key={47} disabled={true} value={propertyValue(47)}>
                <Space direction="vertical">
                  <Radio value={'1'}>TCP</Radio>
                  <Radio value={'2'}>UDP</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </ShouldRenderInput>
        </Row>
      )}
    </Form>
  );
};

export default RedeView;
