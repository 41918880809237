import { Col, Row } from 'antd';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType } from 'antd/lib/table/Table';
import * as S from './viewDevice.styles';
import { formatarData } from '@app/utils/utils';
import React from 'react';
import { DeviceLogModel } from '@app/domain/deviceLog/DeviceLogModel';

interface IViewLogHistory {
  logHistory: DeviceLogModel[];
  isFactory: boolean;
}

export const ViewLogHistory: React.FC<IViewLogHistory> = ({ logHistory, isFactory }) => {
  const columns: ColumnsType<DeviceLogModel> = [
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      sorter: (a, b) => new Date(a.dataHora ?? '').getTime() - new Date(b.dataHora ?? '').getTime(),
      render: (dataHora) => formatarData(dataHora),
    },
    {
      title: 'Desconexões internet',
      dataIndex: 'internet',
      showSorterTooltip: false,
      sorter: (a, b) => (a.internet ?? 0) - (b.internet ?? 0),
      render: (internet) => {
        if (internet >= 10 && isFactory) return <S.RedTag>{internet}</S.RedTag>;
        return internet;
      },
    },
    {
      title: 'Desconexões GNSS',
      dataIndex: 'falhaGPS',
      showSorterTooltip: false,
      sorter: (a, b) => (a.falhaGPS ?? 0) - (b.falhaGPS ?? 0),
      render: (falhaGPS) => {
        if (falhaGPS >= 5 && isFactory) return <S.RedTag>{falhaGPS}</S.RedTag>;
        return falhaGPS;
      },
    },
    {
      title: 'Desconexões MQTT',
      dataIndex: 'mqtt',
      showSorterTooltip: false,
      sorter: (a, b) => (a.mqtt ?? 0) - (b.mqtt ?? 0),
    },
    {
      title: 'Pontos enviados',
      dataIndex: 'qtdPontos',
      showSorterTooltip: false,
      sorter: (a, b) => (a.qtdPontos ?? 0) - (b.qtdPontos ?? 0),
    },
    {
      title: '% backup',
      dataIndex: 'backupPercentual',
      showSorterTooltip: false,
      sorter: (a, b) => (a.backupPercentual ?? 0) - (b.backupPercentual ?? 0),
      render: (backupPercentual: number) => {
        if (backupPercentual >= 20 && isFactory) return <S.RedTag>{backupPercentual.toFixed(2) + '%'}</S.RedTag>;
        return backupPercentual.toFixed(2) + '%';
      },
    },
    {
      title: 'Reset',
      dataIndex: 'qtdReset',
      showSorterTooltip: false,
      sorter: (a, b) => (a.qtdReset ?? 0) - (b.qtdReset ?? 0),
      render: (qtdReset) => {
        if (qtdReset >= 3 && isFactory) return <S.RedTag>{qtdReset}</S.RedTag>;
        return qtdReset;
      },
    },
  ];

  return (
    <>
      {logHistory.length > 0 ? (
        <S.Container>
          <h1>Histórico</h1>
          <Table
            columns={
              isFactory
                ? columns
                : columns.filter(
                    (c) => c.title !== 'Reset' && c.title !== 'Desconexões MQTT' && c.title !== 'Desconexões internet',
                  )
            }
            dataSource={logHistory}
            scroll={{ x: 800 }}
            bordered
          />
        </S.Container>
      ) : (
        <Row justify="center" align="middle">
          <Col xs={24} md={24} style={{ color: '#707070', fontSize: '1rem', fontWeight: 700, textAlign: 'center' }}>
            Nenhum log realizado
          </Col>
        </Row>
      )}
    </>
  );
};
