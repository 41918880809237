import { Panel } from '@app/components/common/Collapse/Collapse';
import styled from 'styled-components';

export const TableHeader = styled(Panel)`
  && .ant-collapse-header-text,
  .ant-collapse-expand-icon {
    color: var(--neutral-gray-gray-700, #545454);
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 137.5% */
  }
`;

export const NoContent = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;
  height: 10rem;
  width: 100%;

  .title {
    color: var(--neutral-gray-gray-600, #707070);
    text-align: center;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }

  .subtitle {
    color: var(--neutral-gray-gray-500, #8c8c8c);
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;
