import { IconDown, IconRight } from '@app/assets/slump-icons';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { Col, Collapse } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InsideTable } from '@app/components/tables/InsideTable/InsideTable.styles';
import { setHeader } from '@app/store/slices/headerSlice';
import DashboardWithoutTable from '@app/components/dashboardWithoutTable/DashboardWithoutTable';
import { Panel } from '@app/components/common/Collapse/Collapse';
import * as S from './index.styles';
import { PlusSquareOutlined } from '@ant-design/icons';
import { FirmwareGroupListModel, FirmwareGroupModel } from '@app/domain/firmwareGroup/firmwareGroupModel';
import { ModalFirmwareVersion } from './components/ModalFirmwareVersion';
import { notificationController } from '@app/controllers/notificationController';
import { FirmwareGroupService } from '@app/services/firmwareGroupService';
import { formatarData } from '@app/utils/utils';

const firmwareGroupService = new FirmwareGroupService();

export const FirmwareGroupDashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [modalAddFirmVersion, setModalAddFirmVersion] = useState(false);
  const [firmwareGroups, setFirmwareGroups] = useState<FirmwareGroupListModel[]>([]);
  const [firmwareGroupsFiltered, setFirmwareGroupsFiltered] = useState<FirmwareGroupListModel[]>([]);
  const [firmwareGroupSelected, setFirmwareGroupSelected] = useState<FirmwareGroupModel | null>(null);

  const handleSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();

      let filtered = firmwareGroups?.filter(
        (item) =>
          item.modelo?.toString().toLowerCase().includes(inputValue) ||
          item.grupos?.find(
            (m) =>
              m.nome?.toString().toLowerCase().includes(inputValue) ||
              m.equipamento?.toLowerCase().includes(inputValue) ||
              m.id?.toString().includes(inputValue) ||
              m.dataHora.toString().includes(inputValue) ||
              m.versoes.find(
                (v) =>
                  v.id?.toString().includes(inputValue) ||
                  v.versao.includes(inputValue) ||
                  v.observacao?.toLowerCase().includes(inputValue) ||
                  v.status?.toLowerCase().includes(inputValue),
              ),
          ),
      ) as FirmwareGroupListModel[];

      filtered = filtered.map((f) => ({
        ...f,
        grupos: f.grupos?.filter(
          (g) =>
            g.nome?.toString().toLowerCase().includes(inputValue) ||
            g.equipamento?.toLowerCase().includes(inputValue) ||
            g.id?.toString().includes(inputValue) ||
            g.dataHora.toString().includes(inputValue) ||
            g.versoes.find(
              (v) =>
                v.id?.toString().includes(inputValue) ||
                v.versao.includes(inputValue) ||
                v.observacao?.toLowerCase().includes(inputValue) ||
                v.status?.toLowerCase().includes(inputValue),
            ),
        ),
      }));

      filtered = filtered.map((f) => ({
        ...f,
        grupos: f.grupos?.map((g) => ({
          ...g,
          versoes: g.versoes.filter(
            (v) =>
              v.id?.toString().includes(inputValue) ||
              v.equipamento?.toLowerCase().includes(inputValue) ||
              v.versao.includes(inputValue) ||
              v.observacao?.toLowerCase().includes(inputValue) ||
              v.status?.toLowerCase().includes(inputValue),
          ),
        })),
      }));

      setFirmwareGroupsFiltered(filtered);
    } else {
      setFirmwareGroupsFiltered(firmwareGroups);
    }
  };

  const addGroupButton = (nome: string) => (
    <div
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
        navigate(`create/${nome}`);
      }}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '0.5rem',
        color: '#263668',
      }}
    >
      <PlusSquareOutlined color="#263668" />
      <span>Adicionar grupo</span>
    </div>
  );

  const columns = (): ColumnsType<FirmwareGroupModel> => [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: false,
      render: (id) => ({
        props: {
          style: {
            fontFamily: 'Mulish',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#545454 !important',
            background: '#F5F5F5 !important',
          },
        },
        children: id,
      }),
    },
    {
      title: 'Grupo',
      dataIndex: 'nome',
      showSorterTooltip: false,
      render: (grupo) => ({
        props: {
          style: {
            fontFamily: 'Mulish',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#545454 !important',
            background: '#F5F5F5 !important',
          },
        },
        children: grupo,
      }),
    },
    {
      title: 'Equipamento',
      dataIndex: 'equipamento',
      showSorterTooltip: false,
      render: (equipamento) => ({
        props: {
          style: {
            fontFamily: 'Mulish',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#545454 !important',
            background: '#F5F5F5 !important',
          },
        },
        children: equipamento,
      }),
    },
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      render: (dataHora: Date) => ({
        props: {
          style: {
            fontFamily: 'Mulish',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#545454 !important',
            background: '#F5F5F5 !important',
          },
        },
        children: dataHora ? formatarData(dataHora) : '-',
      }),
    },
    {
      title: '',
      dataIndex: 'id',
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      render: (id) => {
        return (
          <div
            onClick={(event) => {
              // If you don't want click extra trigger collapse, you can prevent this:
              event.stopPropagation();
              firmwareGroupsFiltered.forEach((f) => {
                const selectedGroup = f.grupos.find((g) => g.id === id);
                if (selectedGroup !== undefined) {
                  setFirmwareGroupSelected(selectedGroup);
                  return;
                }
              });
              setModalAddFirmVersion(true);
            }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '0.5rem',
              color: '#263668',
              cursor: 'pointer',
            }}
          >
            <PlusSquareOutlined color="#263668" />
            <span>Adicionar versão</span>
          </div>
        );
      },
    },
  ];
  const expandableGroup: ExpandableConfig<FirmwareGroupModel> = {
    expandIcon: ({ expanded, onExpand, record }) => {
      const hasModels = record.versoes?.length ?? 0 > 0;
      return (
        <Col
          span={12}
          onClick={(e) => onExpand(record, e)}
          style={{
            display: 'flex',
            justifyContent: 'start',
            cursor: 'pointer',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '64px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {hasModels ? expanded ? <IconDown /> : <IconRight /> : ''}
          </div>
        </Col>
      );
    },
    expandedRowRender: (group) => {
      return (
        <InsideTable>
          <tr>
            <td className="td-title">Cód.</td>
            <td className="td-title">Equipamento</td>
            <td className="td-title">Modelo</td>
            <td className="td-title">Versão de firmware</td>
            <td className="td-title">Observações</td>
            <td className="td-title">Status</td>
          </tr>
          {group.versoes?.map((versao) => (
            <tr key={versao.id}>
              <td width={'10%'}>{versao.id}</td>
              <td>{versao.equipamento ?? '-'}</td>
              <td>{versao.modelo ?? '-'}</td>
              <td>{versao.versao}</td>
              <td>{versao.observacao ?? '-'}</td>
              <td>{versao.status ?? '-'}</td>
            </tr>
          ))}
        </InsideTable>
      );
    },
  };

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const data = await firmwareGroupService.getListAll();
      setFirmwareGroups(data);
      setLoading(false);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um problema ao buscar os grupos de firmware',
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    dispatch(setHeader({ title: 'Grupos de firmware' }));
    fetch();
  }, [fetch, dispatch]);

  useEffect(() => {
    setFirmwareGroupsFiltered(firmwareGroups);
  }, [firmwareGroups]);

  return (
    <>
      <ModalFirmwareVersion
        firmwareGroup={firmwareGroupSelected ?? ({} as FirmwareGroupModel)}
        modalVisible={modalAddFirmVersion}
        setModalVisible={setModalAddFirmVersion}
        editing={false}
        fetchGroups={fetch}
      />
      <Spinner spinning={loading}>
        <DashboardWithoutTable
          title=""
          placeholderTextSearch="Pesquisar"
          handleSearchOnChange={handleSearchFilter}
          // exportToExcel={handleExportToExcel}
        >
          <S.Container>
            <Collapse accordion expandIconPosition="start" style={{ fontWeight: 'bolder' }}>
              {firmwareGroupsFiltered.length > 0 &&
                firmwareGroupsFiltered.map((f) => (
                  <Panel header={f.modelo} key={f.idModelo} extra={addGroupButton(f.modelo)}>
                    <S.InsideContainer>
                      <S.TableContainer
                        showHeader={true}
                        columns={columns()}
                        dataSource={f.grupos}
                        expandable={expandableGroup}
                        rowKey="id"
                        scroll={{ x: 'max-content', y: 4000 }}
                        bordered
                      />
                    </S.InsideContainer>
                  </Panel>
                ))}
            </Collapse>
          </S.Container>
        </DashboardWithoutTable>
      </Spinner>
    </>
  );
};
