import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { setHeader } from '@app/store/slices/headerSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { Error } from '@app/components/common/Error/Error';

const Error500Page: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setHeader({
        icon: <ArrowLeftOutlined />,
        title: 'Erro interno de servidor',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitle>{t('common.serverError')}</PageTitle>
      <Error
        title="500"
        subtitle="Erro interno de servidor"
        textLink="Para mais informações entre em contato com a equipe de suporte"
        msg="Desculpe, erro inesperado. Nós estamos trabalhando para consertar."
      />
    </>
  );
};

export default Error500Page;
