import { useAppSelector } from '@app/hooks/reduxHooks';
import React from 'react';
import * as S from './FooterRegister.styles';
import { References } from '@app/components/common/References/References';

export const FooterReagister: React.FC = () => {
  const {
    confirmButtonText,
    handleConfirmButtonClick,
    cancelButtonText,
    handleCancelButtonClick,
    confirmButtonDisabled,
    tagAButtonText,
    handleTagAButtonClick,
  } = useAppSelector((state) => state.footer);

  return (
    <>
      {!confirmButtonText && !cancelButtonText && !tagAButtonText ? (
        <S.FooterContainer>
          <References />
        </S.FooterContainer>
      ) : (
        <S.FooterReagister>
          {tagAButtonText && (
            <S.FooterTagAButton onClick={() => handleTagAButtonClick()}>{tagAButtonText}</S.FooterTagAButton>
          )}
          <S.FooterCancelButton type="ghost" onClick={() => handleCancelButtonClick()}>
            {cancelButtonText}
          </S.FooterCancelButton>
          <S.FooterConfirmButton
            type="primary"
            onClick={() => handleConfirmButtonClick()}
            disabled={confirmButtonDisabled}
          >
            {confirmButtonText}
          </S.FooterConfirmButton>
        </S.FooterReagister>
      )}
    </>
  );
};
