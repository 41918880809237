import { Description } from '@app/components/common/Description/Description';
import { Col } from 'antd';
import styled from 'styled-components';

export const ContainerStepOne = styled(Col)`
  border-right: 1px solid #bababa;
  padding-right: 2rem !important;
`;
export const ContainerStepTwo = styled(Col)`
  padding-left: 2rem !important;
`;

export const DeviceConfigUpdateDescription = styled(Description)`
  height: 100% !important;
`;
