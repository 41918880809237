import React, { useEffect, useState } from 'react';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { DeviceProfilePropertyModel, PropertyValue } from '@app/domain/deviceProfile/deviceProfileModel';
import * as S from '../ModulesProfileConfig.styles';
import * as SE from './Eventos.styles';
import ShouldRenderInput from '@app/components/common/ModulesProfileConfig/ShouldRenderInput';
import { Form } from 'antd';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';

interface EventosProps {
  properties: PropertyValue[];
  setProperties: React.Dispatch<React.SetStateAction<PropertyValue[]>>;
  moduleData: DeviceProfilePropertyModel[];
  disableAllFields?: boolean;
  setPropertyValues?: (data: PropertyValue[]) => void;
}

const valoresDefaultPorId: { [key: string]: string } = {
  '21': 'false',
  '16': 'false',
  '17': '80',
  '52': 'false',
  '60': '0.6',
  '53': 'false',
  '23': '0.6',
  '54': 'false',
  '61': '0.6',
  '18': 'false',
  '20': '4000',
  '19': '3000',
  '55': 'false',
  '25': 'false',
  '27': 'false',
  '28': '10',
  '56': 'false',
  '57': 'false',
  '58': '3.5',
  '59': 'false',
  '68': 'false',
};

const Eventos: React.FC<EventosProps> = ({
  properties,
  setProperties,
  moduleData,
  disableAllFields = false,
  setPropertyValues,
}) => {
  const [idMapping, setIdMapping] = useState<{ [key: number]: number }>({});
  useEffect(() => {
    const newIdMapping: { [key: number]: number } = {};
    moduleData.forEach((module) => {
      if (module.idEmbarcado && module.idPropriedade) {
        newIdMapping[module.idEmbarcado] = module.idPropriedade;
      }
    });
    setIdMapping(newIdMapping);
  }, [moduleData]);

  useEffect(() => {
    if (Object.keys(idMapping).length > 0 && properties.length === 0) {
      const newProperties = Object.keys(idMapping).map((idEmbarcado) => {
        const idStr = idEmbarcado.toString();
        const valorPadrao = idStr in valoresDefaultPorId ? valoresDefaultPorId[idStr] : '';

        return {
          idPropriedade: idMapping[Number(idEmbarcado)],
          valorPropriedade: valorPadrao,
          idEmbarcado: Number(idEmbarcado),
        };
      });
      setProperties((prevState) => [...prevState, ...newProperties]);
    }
  }, [idMapping, setProperties, properties.length]);

  useEffect(() => {
    setProperties([
      ...properties.map((p) => {
        if ([17, 23, 60, 61, 58, 20, 19].findIndex((x) => x == p.idEmbarcado) > -1) {
          p.valorPropriedade = '';
        }

        return p;
      }),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (idEmbarcado: number, value: string) => {
    const idPropriedade = idMapping[idEmbarcado];
    if (idPropriedade !== undefined) {
      setProperties((prevValue) => {
        const newProperties = [...prevValue];
        const index = newProperties.findIndex((property) => property.idPropriedade === idPropriedade);
        if (index !== -1) newProperties[index].valorPropriedade = value;
        if (setPropertyValues) {
          setPropertyValues(newProperties);
        }
        return newProperties;
      });
    }
  };

  const propertyValue = (idEmbarcado: number) => {
    const idPropriedade = idMapping[idEmbarcado];
    return properties.find((property) => property.idPropriedade === idPropriedade)?.valorPropriedade || '';
  };

  return (
    <Form layout="vertical">
      <SE.Container>
        <SE.EventsItem>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={21}>
            <Checkbox
              key={21}
              checked={propertyValue(21) === 'true'}
              onChange={(e) => {
                const value = e.target.checked ? 'true' : 'false';
                handleInputChange(21, value);
              }}
              style={{ width: '13rem' }}
              disabled={disableAllFields}
            >
              <S.CheckboxLabel>Ignição</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </SE.EventsItem>
        <SE.EventsItem>
          <S.FieldCheckboxValue>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={16}>
              <S.CheckboxContainer>
                <Checkbox
                  key={16}
                  checked={propertyValue(16) === 'true'}
                  onChange={(e) => {
                    const value = e.target.checked ? 'true' : 'false';
                    handleInputChange(16, value);
                    if (value === 'false') handleInputChange(17, '');
                  }}
                  style={{ width: '13rem' }}
                  disabled={disableAllFields}
                >
                  <S.CheckboxLabel>Limite de velocidade (km/h)</S.CheckboxLabel>
                  <S.InputObs>40 a 120 km/h</S.InputObs>
                </Checkbox>
              </S.CheckboxContainer>
            </ShouldRenderInput>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={17}>
              <BaseFormInputItem>
                <Input
                  key={3}
                  type="text"
                  className="ant-input"
                  placeholder="Digite o valor"
                  value={propertyValue(17)}
                  disabled={disableAllFields || propertyValue(16) !== 'true'}
                  maxLength={3}
                  onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                    const char = e.data;
                    if (char && !char.match(/[0-9]/)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || (/^\d+$/.test(value) && Number(value) <= 120)) {
                      handleInputChange(17, value);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (Number(value) < 40 || Number(value) > 120) {
                      handleInputChange(17, valoresDefaultPorId['17']);
                    }
                  }}
                  style={{ width: '8rem' }}
                />
              </BaseFormInputItem>
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
        </SE.EventsItem>
        <SE.EventsItem>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={59}>
            <Checkbox
              key={59}
              checked={propertyValue(59) === 'true'}
              onChange={(e) => {
                const value = e.target.checked ? 'true' : 'false';
                handleInputChange(59, value);
              }}
              style={{ width: '13rem' }}
              disabled={disableAllFields}
            >
              <S.CheckboxLabel>Detecção de movimento</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </SE.EventsItem>
        <SE.EventsItem>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={25}>
            <Checkbox
              key={25}
              checked={propertyValue(25) === 'true'}
              onChange={(e) => {
                const value = e.target.checked ? 'true' : 'false';
                handleInputChange(25, value);
              }}
              style={{ width: '13rem' }}
              disabled={disableAllFields}
            >
              <S.CheckboxLabel>Entrada digital 1</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </SE.EventsItem>
        <SE.EventsItem>
          <S.FieldCheckboxValue>
            {properties.find((property) => property.idEmbarcado === 26)?.valorPropriedade == 'true' && (
              <>
                <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={27}>
                  <S.CheckboxContainer>
                    <Checkbox
                      key={27}
                      checked={propertyValue(27) === 'true'}
                      onChange={(e) => {
                        const value = e.target.checked ? 'true' : 'false';
                        handleInputChange(27, value);
                        if (value === 'false') handleInputChange(28, '');
                      }}
                      style={{ width: '13rem' }}
                      disabled={disableAllFields}
                    >
                      <S.CheckboxLabel>Limite da entrada analógica (mA)</S.CheckboxLabel>
                      <S.InputObs>4 a 20 km/h</S.InputObs>
                    </Checkbox>
                  </S.CheckboxContainer>
                </ShouldRenderInput>
                <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={28}>
                  <BaseFormInputItem>
                    <Input
                      key={3}
                      type="text"
                      className="ant-input"
                      placeholder="Digite o valor"
                      value={propertyValue(28)}
                      disabled={disableAllFields || propertyValue(27) !== 'true'}
                      maxLength={3}
                      onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                        const char = e.data;
                        if (char && !char.match(/[0-9]/)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || (/^\d+$/.test(value) && Number(value) <= 20)) {
                          handleInputChange(28, value);
                        }
                      }}
                      onBlur={(e) => {
                        const value = e.target.value;
                        if (Number(value) < 4 || Number(value) > 20) {
                          handleInputChange(28, valoresDefaultPorId['28']);
                        }
                      }}
                      style={{ width: '8rem' }}
                    />
                  </BaseFormInputItem>
                </ShouldRenderInput>
              </>
            )}
          </S.FieldCheckboxValue>
        </SE.EventsItem>
        <SE.EventsItem>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={56}>
            <Checkbox
              key={56}
              checked={propertyValue(56) === 'true'}
              onChange={(e) => {
                const value = e.target.checked ? 'true' : 'false';
                handleInputChange(56, value);
              }}
              style={{ width: '13rem' }}
              disabled={disableAllFields}
            >
              <S.CheckboxLabel>Mensagem recebida p/ RS232</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </SE.EventsItem>
        <SE.EventsItem>
          <S.FieldCheckboxValue>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={52}>
              <S.CheckboxContainer>
                <Checkbox
                  key={52}
                  checked={propertyValue(52) === 'true'}
                  onChange={(e) => {
                    const value = e.target.checked ? 'true' : 'false';
                    handleInputChange(52, value);
                    if (value === 'false') handleInputChange(23, '');
                  }}
                  style={{ width: '13rem' }}
                  disabled={disableAllFields}
                >
                  <S.CheckboxLabel>Sensibilidade frenagem brusca (g)</S.CheckboxLabel>
                  <S.InputObs>0,25 a 2</S.InputObs>
                </Checkbox>
              </S.CheckboxContainer>
            </ShouldRenderInput>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={60}>
              <BaseFormInputItem>
                <Input
                  key={3}
                  type="text"
                  className="ant-input"
                  placeholder="Digite o valor"
                  value={propertyValue(60).toString().replace('.', ',')} // Exibe com vírgula
                  disabled={disableAllFields || propertyValue(52) !== 'true'}
                  maxLength={5}
                  onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                    const char = e.data;
                    if (char && !char.match(/[0-9,]/)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value.replace(',', '.');

                    if (value === '' || /^\d*(,\d*)?$/.test(value.replace('.', ','))) {
                      handleInputChange(60, value);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.value.replace(',', '.');

                    if (Number(value) < 0.25 || Number(value) > 2) {
                      handleInputChange(60, valoresDefaultPorId['60']);
                    }
                  }}
                  style={{ width: '8rem' }}
                />
              </BaseFormInputItem>
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
        </SE.EventsItem>
        <SE.EventsItem>
          <S.FieldCheckboxValue>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={53}>
              <S.CheckboxContainer>
                <Checkbox
                  key={53}
                  checked={propertyValue(53) === 'true'}
                  onChange={(e) => {
                    const value = e.target.checked ? 'true' : 'false';
                    handleInputChange(53, value);
                    if (value === 'false') handleInputChange(60, '');
                  }}
                  style={{ width: '13rem' }}
                  disabled={disableAllFields}
                >
                  <S.CheckboxLabel>Sensibilidade aceleração brusca (g)</S.CheckboxLabel>
                  <S.InputObs>0,25 a 2</S.InputObs>
                </Checkbox>
              </S.CheckboxContainer>
            </ShouldRenderInput>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={23}>
              <BaseFormInputItem>
                <Input
                  key={3}
                  type="text"
                  className="ant-input"
                  placeholder="Digite o valor"
                  value={propertyValue(23).toString().replace('.', ',')} // Exibe com vírgula
                  disabled={disableAllFields || propertyValue(53) !== 'true'}
                  maxLength={5}
                  onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                    const char = e.data;
                    if (char && !char.match(/[0-9,]/)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value.replace(',', '.');

                    if (value === '' || /^\d*(,\d*)?$/.test(value.replace('.', ','))) {
                      handleInputChange(23, value);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.value.replace(',', '.');

                    if (Number(value) < 0.25 || Number(value) > 2) {
                      handleInputChange(23, valoresDefaultPorId['23']);
                    }
                  }}
                  style={{ width: '8rem' }}
                />
              </BaseFormInputItem>
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
        </SE.EventsItem>
        <SE.EventsItem>
          <S.FieldCheckboxValue>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={54}>
              <S.CheckboxContainer>
                <Checkbox
                  key={54}
                  checked={propertyValue(54) === 'true'}
                  onChange={(e) => {
                    const value = e.target.checked ? 'true' : 'false';
                    handleInputChange(54, value);
                    if (value === 'false') handleInputChange(61, '');
                  }}
                  style={{ width: '13rem' }}
                  disabled={disableAllFields}
                >
                  <S.CheckboxLabel>Sensibilidade curva acentuada (g)</S.CheckboxLabel>
                  <S.InputObs>0,25 a 2</S.InputObs>
                </Checkbox>
              </S.CheckboxContainer>
            </ShouldRenderInput>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={61}>
              <BaseFormInputItem>
                <Input
                  key={3}
                  type="text"
                  className="ant-input"
                  placeholder="Digite o valor"
                  value={propertyValue(61).toString().replace('.', ',')} // Exibe com vírgula
                  disabled={disableAllFields || propertyValue(54) !== 'true'}
                  maxLength={5}
                  onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                    const char = e.data;
                    if (char && !char.match(/[0-9,]/)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value.replace(',', '.');

                    if (value === '' || /^\d*(,\d*)?$/.test(value.replace('.', ','))) {
                      handleInputChange(61, value);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.value.replace(',', '.');

                    if (Number(value) < 0.25 || Number(value) > 2) {
                      handleInputChange(61, valoresDefaultPorId['61']);
                    }
                  }}
                  style={{ width: '8rem' }}
                />
              </BaseFormInputItem>
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
        </SE.EventsItem>
        <SE.EventsItem>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={55}>
            <Checkbox
              key={55}
              checked={propertyValue(55) === 'true'}
              onChange={(e) => {
                const value = e.target.checked ? 'true' : 'false';
                handleInputChange(55, value);
              }}
              style={{ width: '13rem' }}
              disabled={disableAllFields}
            >
              <S.CheckboxLabel>Modo bateria interna</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </SE.EventsItem>
        <SE.EventsItem>
          <S.FieldCheckboxValue>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={57}>
              <S.CheckboxContainer>
                <Checkbox
                  key={57}
                  checked={propertyValue(57) === 'true'}
                  onChange={(e) => {
                    const value = e.target.checked ? 'true' : 'false';
                    handleInputChange(57, value);
                    if (value === 'false') handleInputChange(58, '');
                  }}
                  style={{ width: '13rem' }}
                  disabled={disableAllFields}
                >
                  <S.CheckboxLabel>Limite bateria interna baixa (V)</S.CheckboxLabel>
                  <S.InputObs>3,4 a 3,6</S.InputObs>
                </Checkbox>
              </S.CheckboxContainer>
            </ShouldRenderInput>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={58}>
              <BaseFormInputItem>
                <Input
                  key={3}
                  type="text"
                  className="ant-input"
                  placeholder="Digite o valor"
                  value={propertyValue(58).toString().replace('.', ',')} // Exibe com vírgula
                  disabled={disableAllFields || propertyValue(57) !== 'true'}
                  maxLength={4}
                  onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                    const char = e.data;
                    if (char && !char.match(/[0-9,]/)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value.replace(',', '.');

                    if (value === '' || /^\d*(,\d*)?$/.test(value.replace('.', ','))) {
                      handleInputChange(58, value);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.value.replace(',', '.');

                    if (Number(value) < 3.4 || Number(value) > 3.6) {
                      handleInputChange(58, valoresDefaultPorId['58']);
                    }
                  }}
                  style={{ width: '8rem' }}
                />
              </BaseFormInputItem>
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
        </SE.EventsItem>
        <SE.EventsItem>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={85}>
            <Checkbox
              key={85}
              checked={propertyValue(85) === 'true'}
              onChange={(e) => {
                const value = e.target.checked ? 'true' : 'false';
                handleInputChange(85, value);
              }}
              style={{ width: '13rem' }}
              disabled={disableAllFields}
            >
              <S.CheckboxLabel>Mensagem recebida p/ Serial</S.CheckboxLabel>
            </Checkbox>
          </ShouldRenderInput>
        </SE.EventsItem>
        <SE.EventsItem>
          <S.FieldCheckboxValue style={{ gap: '.3rem' }}>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={18}>
              <S.CheckboxContainer>
                <Checkbox
                  key={18}
                  checked={propertyValue(18) === 'true'}
                  onChange={(e) => {
                    const value = e.target.checked ? 'true' : 'false';
                    handleInputChange(18, value);
                    if (value === 'false') {
                      handleInputChange(20, '');
                      handleInputChange(19, '');
                    }
                  }}
                  style={{ width: '13rem' }}
                  disabled={disableAllFields}
                >
                  <S.CheckboxLabel>Limite de rpm (alto e médio)</S.CheckboxLabel>
                  <S.InputObs>1000 a 7000 rpm</S.InputObs>
                </Checkbox>
              </S.CheckboxContainer>
            </ShouldRenderInput>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={20}>
              <BaseFormInputItem>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Input
                    key={20}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={propertyValue(20).toString().replace('.', ',')} // Exibe com vírgula
                    disabled={disableAllFields || propertyValue(18) !== 'true'}
                    maxLength={4}
                    onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                      const char = e.data;
                      if (char && !char.match(/[0-9,]/)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value.replace(',', '.');

                      if (value === '' || (/^\d*(,\d*)?$/.test(value.replace('.', ',')) && Number(value) <= 7000)) {
                        handleInputChange(20, value);
                      }
                    }}
                    onBlur={(e) => {
                      const value = e.target.value.replace(',', '.');

                      if (Number(value) < 1000 || Number(value) > 7000) {
                        handleInputChange(20, valoresDefaultPorId['20']);
                      }
                    }}
                    style={{ width: '8rem' }}
                  />
                  <S.InputObs style={{ fontSize: '0.875rem' }}>Limite rpm alto</S.InputObs>
                </div>
              </BaseFormInputItem>
            </ShouldRenderInput>
            <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={19}>
              <BaseFormInputItem>
                <SE.InputsContainer>
                  <Input
                    key={19}
                    type="text"
                    className="ant-input"
                    placeholder="Digite o valor"
                    value={propertyValue(19).toString().replace('.', ',')} // Exibe com vírgula
                    disabled={disableAllFields || propertyValue(18) !== 'true'}
                    maxLength={4}
                    onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                      const char = e.data;
                      if (char && !char.match(/[0-9,]/)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value.replace(',', '.');

                      if (value === '' || (/^\d*(,\d*)?$/.test(value.replace('.', ',')) && Number(value) <= 7000)) {
                        handleInputChange(19, value);
                      }
                    }}
                    onBlur={(e) => {
                      const value = e.target.value.replace(',', '.');

                      if (Number(value) < 1000 || Number(value) > 7000) {
                        handleInputChange(19, valoresDefaultPorId['19']);
                      }
                    }}
                    style={{ width: '8rem' }}
                  />
                  <S.InputObs style={{ fontSize: '0.875rem' }}>Limite rpm médio</S.InputObs>
                </SE.InputsContainer>
              </BaseFormInputItem>
            </ShouldRenderInput>
          </S.FieldCheckboxValue>
        </SE.EventsItem>
      </SE.Container>
    </Form>
  );
};

export default Eventos;
