import * as S from '../DeviceCards.styles';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import React, { FC } from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { ReactComponent as TagIcon } from '@app/assets/icons/tag-icon.svg';
import { Col, Row, Spin } from 'antd';
import { Divider } from '@app/pages/factory-schedule/view/view.styles';
import { ReactComponent as CheckIcon } from '@app/assets/icons/check_circle.svg';
import { LoadingOutlined } from '@ant-design/icons';

interface ICardTags {
  newDevice: FactoryDeviceModel;
  openModalIndividualTag: () => void;
  openModalGroupTag: () => void;
}

export const CardTags: FC<ICardTags> = ({ newDevice, openModalGroupTag, openModalIndividualTag }) => {
  return (
    // <S.Card>
    // <S.Title>Vincular</S.Title>
    <>
      <S.Row style={{ marginBottom: '1rem' }}>
        {newDevice.tagsToSendToDevice && newDevice.tagsToSendToDevice?.length > 0 ? (
          <>
            <CheckIcon />
            Tags cadastradas com sucesso!
          </>
        ) : (
          <>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
            Aguardando o cadastro da(s) tag(s) ou grupo de tags
          </>
        )}
      </S.Row>
      <Row justify={'center'} style={{ width: '100%' }}>
        <Button style={{ width: '14.75rem' }} icon={<TagIcon />} onClick={openModalIndividualTag}>
          Tag individual
        </Button>
      </Row>
      <Row align={'middle'} gutter={[18, 18]}>
        <Col xs={11}>
          <Divider />
        </Col>
        <Col xs={2}>OU</Col>
        <Col xs={11}>
          <Divider />
        </Col>
      </Row>
      <Row justify={'center'} style={{ width: '100%' }}>
        <Button style={{ width: '14.75rem' }} icon={<TagIcon />} onClick={openModalGroupTag}>
          Grupo de tags
        </Button>
      </Row>
    </>
    // </S.Card>
  );
};
