import { InfoCircleOutlined } from '@ant-design/icons';
import { IconMore } from '@app/assets/slump-icons';
import { Description } from '@app/components/common/Description/Description';
import { Modal } from '@app/components/common/Modal/Modal';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input, TextArea } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setFooter } from '@app/store/slices/footerSlice';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Col, Row, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType } from 'antd/lib/table';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { H4 } from '@app/components/common/typography/H4/H4';
import { hasAccessByRoles } from '@app/controllers/accessController';
import { UserType } from '@app/constants/enums/userType';
import { FirmwareGroupModel, FirmwareGroupVersionsModel } from '@app/domain/firmwareGroup/firmwareGroupModel';
import { ModalFirmwareVersion } from './components/ModalFirmwareVersion';
import { VersionStatusModel } from '@app/domain/firmwareVersion/versionStatusModel';
import { FirmwareVersionService } from '@app/services/firmwareVersionService';
import MaskedInput from 'react-text-mask';
import { maskIdAndLetterProps, maskIdVersionProps } from '@app/utils/utils';
import { Select } from '@app/components/common/selects/Select/Select';
import { ModelDeviceService } from '@app/services/modelDeviceService';
import { ModelDeviceModel } from '@app/domain/modelDevice/modelDeviceModel';
import { FirmwareGroupService } from '@app/services/firmwareGroupService';

const firmwareVersionService = new FirmwareVersionService();
const firmwareGroupService = new FirmwareGroupService();
const modelService = new ModelDeviceService();

export const FirmwareGroupCreate: React.FC = () => {
  const { nomeModelo } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [version, setVersion] = useState<string>('');
  const [versionSuffix, setVersionSuffix] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [preVersion, setPreVersion] = useState<string>('');
  const [versionStatus, setVersionStatus] = useState<number | null>(null);
  const [versionObs, setVersionObs] = useState<string>('');
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [modalAddFirmVersion, setModalAddFirmVersion] = useState(false);
  const [modalDeleteFirmVersion, setModalDeleteFirmVersion] = useState(false);
  const [statusOptions, setStatusOptions] = useState<VersionStatusModel[]>([]);
  const [firmwareGroup, setFirmwareGroup] = useState<FirmwareGroupModel>({
    versoes: [] as FirmwareGroupVersionsModel[],
  } as FirmwareGroupModel);
  const [models, setModels] = useState<ModelDeviceModel | null>();
  const [versionSelected, setVersionSelected] = useState<FirmwareGroupVersionsModel | null>(null);

  const columns: ColumnsType<FirmwareGroupVersionsModel> = [
    {
      title: 'Equipamento',
      dataIndex: 'equipamento',
      showSorterTooltip: false,
      width: '24%',
    },
    {
      title: 'Modelo',
      dataIndex: 'modelo',
      showSorterTooltip: false,
      width: '24%',
    },
    {
      title: 'Versão de firmware',
      dataIndex: 'versao',
      showSorterTooltip: false,
      width: '24%',
    },
    {
      title: 'Observações',
      dataIndex: 'observacao',
      showSorterTooltip: false,
      width: '24%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      showSorterTooltip: false,
      width: '24%',
      render: (_, rowData) => {
        return <span>{statusOptions.find((s) => s.id === rowData.idStatus)?.nome ?? '-'}</span>;
      },
    },
    {
      title: '',
      dataIndex: 'id',
      showSorterTooltip: false,
      render: (_, rowData) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {hasAccessByRoles([UserType.FactoryAdmin, UserType.Client]) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ display: 'flex', color: '#545454', alignItems: 'center' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    {!rowData.id && (
                      <>
                        <MenuItem
                          key={rowData.id}
                          onClick={() => {
                            setVersionSelected(rowData);
                            setModalAddFirmVersion(true);
                          }}
                        >
                          <Button type="text">Editar</Button>
                        </MenuItem>
                        <MenuItem
                          key={rowData.id}
                          onClick={() => {
                            setVersionSelected(rowData);
                            setModalDeleteFirmVersion(true);
                          }}
                        >
                          <Button type="text">Excluir</Button>
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (firmwareGroup.nome) {
      setPreVersion(firmwareGroup.nome.split('.')[0]);
    }
  }, [firmwareGroup.nome]);
  const handleBackClick = () => setModalCancelVisible(true);

  const handleSaveClick = async () => {
    setLoading(true);
    firmwareGroupService
      .post('', firmwareGroup)
      .then(() => {
        notificationController.success({
          message: 'Sucesso!',
          description: 'Cadastro do grupo de firmware realizado.',
        });
        navigate('/grupo-firmware');
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => setLoading(false));
  };

  const handleDeleteVersion = () => {
    setFirmwareGroup((prevState) => ({
      ...prevState,
      versoes: [...prevState.versoes.filter((v) => v !== versionSelected)],
    }));
    setModalDeleteFirmVersion(false);
  };

  const handleAddVersion = () => {
    const fullVersion = `${(firmwareGroup?.nome ?? '').replace(/_|\bx.x$/, '')}${version}-${versionSuffix}`;
    const indexExists = firmwareGroup.versoes.findIndex((v) => v.versao === fullVersion);

    if (indexExists >= 0) {
      notificationController.error({ message: 'Já existe uma versão igual cadastrada' });
      return;
    }

    setFirmwareGroup((prevState) => ({
      ...prevState,
      versoes: [
        ...prevState.versoes,
        {
          idModelo: models?.id,
          versao: fullVersion,
          idStatus: versionStatus ?? 0,
          observacao: versionObs,
          equipamento: models?.equipamento,
          modelo: models?.nome,
        },
      ],
    }));
    notificationController.success({
      message: 'Versão de firmware adicionado na listagem com sucesso!',
    });
    cleanVersionData();
  };

  const cleanVersionData = () => {
    setVersion('');
    setVersionStatus(null);
    setVersionObs('');
    setVersionSuffix('');
  };

  const fetchStatusOptions = useCallback(() => {
    return firmwareVersionService
      .getStatusOptions()
      .then((res: VersionStatusModel[]) => {
        setStatusOptions(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });
  }, []);

  const fetchModels = useCallback(() => {
    return modelService
      .get(`obter-por-nome/${nomeModelo}`)
      .then((res: ModelDeviceModel) => {
        setModels(res);
        setFirmwareGroup((prevState) => ({
          ...prevState,
          idModelo: res.id,
          modelo: res.nome,
          equipamento: res.equipamento,
        }));
      })
      .catch((error) => {
        notificationController.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: 'Novo grupo de firmware',
        handleBackClick: handleBackClick,
      }),
    );

    fetchStatusOptions();
    fetchModels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, dispatch]);

  useEffect(() => {
    dispatch(
      setFooter({
        confirmButtonText: 'Cadastrar grupo',
        confirmButtonDisabled: !firmwareGroup?.nome,
        handleConfirmButtonClick: handleSaveClick,
        cancelButtonText: 'Cancelar',
        handleCancelButtonClick: () => setModalCancelVisible(true),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmwareGroup, dispatch]);

  return (
    <>
      <Modal
        title={`Cancelar cadastro do grupo`}
        open={modalCancelVisible}
        onOk={() => navigate('/grupo-firmware')}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            Deseja realmente <strong>&nbsp;cancelar o cadastro do grupo?</strong>
          </Col>
        </Row>
      </Modal>
      <Modal
        title={`Deletar versão de firmware`}
        open={modalDeleteFirmVersion}
        onOk={handleDeleteVersion}
        onCancel={() => setModalDeleteFirmVersion(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#C41B24', marginRight: '1rem' }} />
            Deseja realmente <strong>&nbsp;deletar a versão de firmware selecionada?</strong>
          </Col>
        </Row>
      </Modal>
      <ModalFirmwareVersion
        firmwareGroup={firmwareGroup ?? ({} as FirmwareGroupModel)}
        modalVisible={modalAddFirmVersion}
        setModalVisible={setModalAddFirmVersion}
        editing={true}
        firmwareVersion={versionSelected}
        setFirmwareGroup={setFirmwareGroup}
      />
      <Spinner spinning={loading}>
        <PageContainer>
          <Description title="Informações do grupo" subtitle="Preencha os campos para cadastrar um novo grupo" />
          <BaseForm layout="vertical" style={{ width: '100%' }}>
            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={6}>
                <BaseFormInputItem label="Equipamento" style={{ width: '100%' }}>
                  <Input placeholder="Digite o nome do equipamento" value={models?.equipamento ?? '-'} disabled />
                </BaseFormInputItem>
              </Col>
              <Col span={6}>
                <BaseFormInputItem label="Modelo" style={{ width: '100%' }}>
                  <Input placeholder="Digite o nome do modelo" value={models?.nome ?? '-'} disabled />
                </BaseFormInputItem>
              </Col>
              <Col span={6}>
                <BaseFormInputItem label="Grupo" supportText="Exemplo 001.001.x.x" style={{ width: '100%' }}>
                  <MaskedInput
                    {...maskIdAndLetterProps}
                    className="ant-input"
                    placeholder="Digite o nome do grupo"
                    value={firmwareGroup?.nome}
                    onChange={(e) => setFirmwareGroup((prevState) => ({ ...prevState, nome: e.target.value }))}
                    onBlur={(e) => {
                      let value = e.target.value;
                      value = value.replace(/_/g, '');
                      setFirmwareGroup((prevState) => ({ ...prevState, nome: value }));
                      const letterIndex = value.lastIndexOf('.');
                      setPreVersion(value.substring(0, letterIndex + 1));
                    }}
                  />
                </BaseFormInputItem>
              </Col>
            </Row>
          </BaseForm>

          <Description
            title="Informações das versões de firmware"
            subtitle="Preencha os campos para adicionar as verões de firmware no grupo"
          />
          <BaseForm layout="vertical" style={{ width: '100%', marginBottom: '2rem' }}>
            <Row gutter={16}>
              <Col>
                <BaseFormInputItem label="Versão de firmware" style={{ width: '100%' }}>
                  <Space.Compact>
                    <Input
                      style={{
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                        width: '20%',
                      }}
                      prefix={firmwareGroup?.nome?.replace(/_|\bx.x$/, '')}
                      value={version.substring(firmwareGroup?.nome?.length)}
                      disabled
                    />
                    <MaskedInput
                      {...maskIdVersionProps}
                      style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0, width: '80%' }}
                      className="ant-input"
                      placeholder="Digite a versão do firmware"
                      value={version}
                      onChange={(e) => setVersion(e.target.value)}
                      onBlur={(e) => {
                        let value = e.target.value;
                        value = value.replace(/_/g, '');
                        setVersion(value);
                      }}
                    />
                  </Space.Compact>
                </BaseFormInputItem>
              </Col>
              {/* Feito assim para que não dependa do id da tabela */}
              {statusOptions.find((o) => o.id == versionStatus)?.nome == 'Teste' && (
                <Col span={4}>
                  <BaseFormInputItem label="Sufixo" supportText="Opcional">
                    <Input value={versionSuffix} onChange={(e) => setVersionSuffix(e.target.value)} maxLength={10} />
                  </BaseFormInputItem>
                </Col>
              )}
              <Col span={6}>
                <BaseFormInputItem label="Status" style={{ width: '100%' }}>
                  <Select
                    showArrow
                    disabled={version.length <= 0}
                    value={versionStatus}
                    placeholder="Selecione o status"
                    onChange={(value) => setVersionStatus(value as number | 0)}
                    options={statusOptions.map((s) => ({ label: s.nome, value: s.id }))}
                  />
                </BaseFormInputItem>
              </Col>
              <Col span={8}>
                <BaseFormInputItem label="Observações" supportText="Opcional" style={{ width: '100%' }}>
                  <TextArea value={versionObs} onChange={(e) => setVersionObs(e.target.value)} />
                </BaseFormInputItem>
              </Col>

              <Col span={4}>
                <BaseFormInputItem label=" " style={{ width: '100%' }}>
                  <Button type="primary" onClick={handleAddVersion} disabled={!versionStatus || !version}>
                    Adicionar versão
                  </Button>
                </BaseFormInputItem>
              </Col>
            </Row>
          </BaseForm>

          {firmwareGroup?.versoes && firmwareGroup.versoes.length > 0 && (
            <>
              <H4>Versões de firmware adicionadas</H4>
              <Table
                columns={columns}
                dataSource={firmwareGroup.versoes}
                style={{ padding: 0, width: '100%' }}
                bordered
                // scroll={{ x: 2000, y: 500 }}
              />
            </>
          )}
        </PageContainer>
      </Spinner>
    </>
  );
};
