import React from 'react';
import * as S from './DropdownTable.styles';
import { Button } from '../buttons/Button/Button';
import { DropDownProps as AntDropdownProps } from 'antd';

interface IDropdown extends AntDropdownProps {
  iconD?: JSX.Element;
  buttonText?: string;
  text?: string;
  iconSupermix?: JSX.Element;
}

const DropdownTable: React.FC<IDropdown> = ({ iconSupermix, iconD, ...props }) => {
  return (
    <S.DropdownTable getPopupContainer={(triggerNode) => triggerNode} {...props}>
      <Button type="text" onClick={(e) => e.preventDefault()}>
        {iconSupermix}
        {iconD}
      </Button>
    </S.DropdownTable>
  );
};
export default DropdownTable;
