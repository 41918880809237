import { IconDown, IconMore, IconRight } from '@app/assets/slump-icons';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { UserType } from '@app/constants/enums/userType';
import { hasAccessByRoles } from '@app/controllers/accessController';
import { DeviceGroupModel } from '@app/domain/deviceGroup/deviceGroupModel';
import { Button } from '@app/components/common/buttons/Button/Button';
import { ColumnsType } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useCallback, useEffect, useState } from 'react';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { Col, Row } from 'antd';
import { InsideTable } from '@app/components/tables/InsideTable/InsideTable.styles';
import { setHeader } from '@app/store/slices/headerSlice';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import Dashboard from '@app/components/dashboard/Dashboard';
import { notificationController } from '@app/controllers/notificationController';
import IDeviceGroupService, { DeviceGroupService } from '@app/services/deviceGroupService';
import { CloseCircleOutlined } from '@ant-design/icons';
import * as S from './index.styles';
import { Modal } from '@app/components/common/Modal/Modal';

const deviceGroupService: IDeviceGroupService = new DeviceGroupService();

export const DeviceGroupDashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [groups, setGroups] = useState<DeviceGroupModel[]>([]);
  const [idGroupSelected, setIdGroupSelected] = useState<number>();
  const [groupsFiltered, setGroupsFiltered] = useState<DeviceGroupModel[]>([]);

  const handleNewGroupClick = () => navigate('/dispositivos/grupos/cadastrar');
  const handleEditGroupClick = (id: number) => navigate(`/dispositivos/grupos/editar/${id}`);
  const handleDeleteGroupClick = (id: number) => {
    setIsModalVisible(true);
    setIdGroupSelected(id);
  };
  const handleConfirmDeleteClick = () => {
    if (idGroupSelected) {
      setLoading(true);
      deviceGroupService
        .delete(`${idGroupSelected}`)
        .then(() => {
          setGroups(groups.filter((g) => g.id !== idGroupSelected));
          notificationController.success({ message: 'Grupo removido com sucesso.' });
          setIsModalVisible(false);
          setLoading(false);
        })
        .catch((e) => {
          notificationController.error(e);
          setIsModalVisible(false);
          setLoading(false);
        });
    }
  };
  const handleSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setGroupsFiltered(
        groups?.filter(
          (item) =>
            item.nome?.toString().toLowerCase().includes(inputValue) ||
            item.dispositivos?.find(
              (m) =>
                m.apelido?.toString().toLowerCase().includes(inputValue) ||
                m.modelo?.toString().toLowerCase().includes(inputValue) ||
                m.tipoEquipamento?.toString().toLowerCase().includes(inputValue),
            ),
        ),
      );
    } else {
      setGroupsFiltered(groups);
    }
  };

  const handleExportToExcel = useCallback(async (_groupsFiltered: typeof groupsFiltered): Promise<void> => {
    const _columns: ColumnsType = [
      {
        title: 'Grupo',
        dataIndex: 'grupo',
      },
      {
        title: 'Cod.',
        dataIndex: 'id',
      },
      {
        title: 'Equipamento',
        dataIndex: 'tipoEquipamento',
      },
      {
        title: 'Modelo',
        dataIndex: 'modelo',
      },
      {
        title: 'Apelido',
        dataIndex: 'apelido',
      },
    ];

    const emptyGroup = {
      grupo: undefined,
      id: undefined,
      tipoEquipamento: undefined,
      modelo: undefined,
      apelido: undefined,
    };

    const data = _groupsFiltered.flatMap((group) => {
      if (!!group?.dispositivos && group?.dispositivos?.length === 0) {
        return [{ ...emptyGroup, grupo: group.nome }];
      }

      return group?.dispositivos?.map((device) => ({
        grupo: group.nome,
        id: device.id,
        tipoEquipamento: device.tipoEquipamento,
        modelo: device.modelo,
        apelido: device.apelido,
      }));
    });

    await genericExportToExcel('grupos_dispositivos', _columns, data);
  }, []);

  const columns = (): ColumnsType<DeviceGroupModel> => [
    {
      title: '',
      dataIndex: 'nome',
      showSorterTooltip: false,
      render: (nome) => ({
        props: {
          style: {
            fontFamily: 'Mulish',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '24px',
          },
        },
        children: nome,
      }),
    },
    {
      title: '',
      dataIndex: 'id',
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      render: (id) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {hasAccessByRoles([UserType.FactoryAdmin, UserType.Client]) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ display: 'flex', color: '#545454', alignItems: 'center' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <MenuItem onClick={() => handleEditGroupClick(id)}>
                      <Button type="text">Editar</Button>
                    </MenuItem>
                    <MenuItem onClick={() => handleDeleteGroupClick(id)}>
                      <Button type="text">Deletar</Button>
                    </MenuItem>
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  const expandableGroup: ExpandableConfig<DeviceGroupModel> = {
    expandIcon: ({ expanded, onExpand, record }) => {
      const hasModels = record.dispositivos?.length ?? 0 > 0;
      return (
        <Col
          span={12}
          onClick={(e) => onExpand(record, e)}
          style={{
            display: 'flex',
            justifyContent: 'start',
            cursor: 'pointer',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '64px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {hasModels ? expanded ? <IconDown /> : <IconRight /> : ''}
          </div>
        </Col>
      );
    },
    expandedRowRender: (group) => {
      return (
        <InsideTable>
          <tr>
            <td className="td-title">Cód.</td>
            <td className="td-title">Equipamento</td>
            <td className="td-title">Modelo</td>
            <td className="td-title">Apelido</td>
          </tr>
          {group.dispositivos?.map((dispositivo) => (
            <tr key={dispositivo.id}>
              <td width={'10%'}>{dispositivo.id}</td>
              <td>{dispositivo.tipoEquipamento}</td>
              <td>{dispositivo.modelo}</td>
              <td>{dispositivo.apelido}</td>
            </tr>
          ))}
        </InsideTable>
      );
    },
  };

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      setGroups(await deviceGroupService.getArray(''));
      setLoading(false);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um problema ao buscar os grupos de dispositivo',
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    dispatch(setHeader({ title: 'Grupos de dispositivos' }));
    fetch();
  }, [fetch, dispatch]);

  useEffect(() => {
    setGroupsFiltered(groups);
  }, [groups]);

  return (
    <Spinner spinning={loading}>
      <Modal
        title="Deletar grupo"
        open={isModalVisible}
        onOk={() => handleConfirmDeleteClick()}
        onCancel={() => setIsModalVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <CloseCircleOutlined size={20} style={{ color: '#C41B24', marginRight: '1rem' }} />
            Tem certeza que deseja <strong>deletar o grupo</strong> selecionado?
          </Col>
        </Row>
      </Modal>
      <Dashboard
        title=""
        buttonText={
          hasAccessByRoles([UserType.FactoryAdmin, UserType.Factory, UserType.Client]) ? 'Cadastrar novo grupo' : ''
        }
        handleButtonClick={handleNewGroupClick}
        placeholderTextSearch="Pesquisar por apelido, modelo ou equipamento"
        handleSearchOnChange={handleSearchFilter}
        table={
          <S.TableContainer
            showHeader={false}
            columns={columns()}
            dataSource={groupsFiltered}
            expandable={expandableGroup}
            rowKey="id"
            bordered
          />
        }
        exportToExcel={() => handleExportToExcel(groupsFiltered)}
      />
    </Spinner>
  );
};
