import { IconDown, IconMore, IconRight } from '@app/assets/slump-icons';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Button } from '@app/components/common/buttons/Button/Button';
import Dashboard from '@app/components/dashboard/Dashboard';
import { UserType } from '@app/constants/enums/userType';
import { hasAccessByRoles } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import { EquipmentModel } from '@app/domain/equipment/equipmentModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IEquipmentService, { EquipmentService } from '@app/services/equipmentService';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { Col } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InsideTable } from '@app/components/tables/InsideTable/InsideTable.styles';
import { setHeader } from '@app/store/slices/headerSlice';
import { TableCollapse } from '@app/components/common/TableCollapse/TableCollapse';

const equipmentService: IEquipmentService = new EquipmentService();

export const EquipmentDashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [equipments, setEquipments] = useState<EquipmentModel[]>([]);
  const [equipmentsFiltered, setEquipmentsFiltered] = useState<EquipmentModel[]>([]);

  const handleNewEquipmentClick = () => navigate('/equipamentos/criar');
  const handleEditEquipmentClick = (id: number) => navigate(`/equipamentos/editar/${id}`);
  const handleSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setEquipmentsFiltered(
        equipments?.filter(
          (item) =>
            item.nome?.toString().toLowerCase().includes(inputValue) ||
            item.modelos?.find((m) => m.nome?.toString().toLowerCase().includes(inputValue)),
        ),
      );
    } else {
      setEquipmentsFiltered(equipments);
    }
  };
  const handleExportToExcel = () => {
    genericExportToExcel('equipamentos', columns(), equipmentsFiltered ?? []);
  };

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await equipmentService.getArray('');
      setEquipments(response);
      setLoading(false);
    } catch (error) {
      notificationController.error({ message: 'Erro!', description: 'Houve um problema ao buscar os equipamentos' });
      setLoading(false);
    }
  }, []);
  const columns = (): ColumnsType<EquipmentModel> => [
    {
      title: 'Equipamento',
      dataIndex: 'nome',
      showSorterTooltip: true,
      sorter: (a, b) => (a.nome ?? '').localeCompare(b.nome ?? ''),
      render: (nome) => ({
        props: {
          style: {
            fontFamily: 'Mulish',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '24px',
            color: 'var(--neutral-gray-gray-700, #545454) !important',
            background: '#F5F5F5 !important',
          },
        },
        children: nome,
      }),
    },
    {
      title: '',
      dataIndex: 'id',
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      render: (id) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {hasAccessByRoles([UserType.FactoryAdmin, UserType.Client]) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ display: 'flex', color: '#545454', alignItems: 'center' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <MenuItem onClick={() => handleEditEquipmentClick(id)}>
                      <Button type="text">Editar</Button>
                    </MenuItem>
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];
  const expandableGroup: ExpandableConfig<EquipmentModel> = {
    expandIcon: ({ expanded, onExpand, record }) => {
      const hasModels = record.modelos?.length ?? 0 > 0;
      return (
        <Col
          span={12}
          onClick={(e) => onExpand(record, e)}
          style={{
            display: 'flex',
            justifyContent: 'start',
            cursor: 'pointer',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '64px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {hasModels ? expanded ? <IconDown /> : <IconRight /> : ''}
          </div>
        </Col>
      );
    },
    expandedRowRender: (equipment) => {
      return (
        <InsideTable>
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>Cód.</th>
              <th>Modelo</th>
              <th>Anatel</th>
              <th>Qtde máxima de cercas</th>
              <th>Qtde máxima de pontos por cercas</th>
            </tr>
          </thead>
          <tbody>
            {equipment.modelos?.map((modelo) => (
              <tr key={modelo.id}>
                <td width={'10%'}>
                  <div style={{ display: 'flex', justifyContent: 'left' }}>{modelo.id}</div>
                </td>
                <td>{modelo.nome}</td>
                <td>{modelo.anatel ?? '-'}</td>
                <td>{modelo.quantidadeMaxCercas ?? '-'}</td>
                <td>{modelo.quantidadeMaxPontosPorCerca ?? '-'}</td>
              </tr>
            ))}
          </tbody>
        </InsideTable>
      );
    },
  };

  useEffect(() => {
    dispatch(setHeader({ title: 'Equipamentos' }));
    fetch();
  }, [fetch, dispatch]);

  useEffect(() => {
    setEquipmentsFiltered(equipments);
  }, [equipments]);

  return (
    <>
      <Spinner spinning={loading}>
        <Dashboard
          title=""
          buttonText={hasAccessByRoles([UserType.FactoryAdmin, UserType.Factory]) ? 'Cadastrar novo equipamento' : ''}
          handleButtonClick={handleNewEquipmentClick}
          placeholderTextSearch="Pesquisar"
          handleSearchOnChange={handleSearchFilter}
          table={
            <TableCollapse
              columns={columns()}
              dataSource={equipmentsFiltered}
              expandable={expandableGroup}
              rowKey="id"
              scroll={{ x: 800 }}
              bordered
            />
          }
          exportToExcel={handleExportToExcel}
        />
      </Spinner>
    </>
  );
};
