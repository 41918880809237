import { Modal } from '@app/components/common/Modal/Modal';
import { RadioGroup } from '@app/components/common/Radio/Radio';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { Col, Divider, Radio, Row, Space } from 'antd';
import React from 'react';
import * as S from './ModalEventConfigurationComponent.styles';
import { FenceModel } from '@app/domain/fence/fenceModel';
import { notificationController } from '@app/controllers/notificationController';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';

interface IModalEventConfigurationProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  fence: FenceModel;
  setFence: React.Dispatch<React.SetStateAction<FenceModel>>;
}
export const validateEventFields = (fence: FenceModel) => {
  if (fence.eventos?.entradaSaida === undefined) {
    notificationController.warning({
      message: 'É obrigatório o preenchimento dos eventos',
    });
    return false;
  }

  if (!fence.eventos?.entradaSaida && !fence.eventos?.limiteVelocidade) {
    notificationController.warning({
      message: 'É obrigatório selecionar pelo menos um evento: entrada/saída e/ou limite de velocidade.',
    });
    return false;
  }

  if (fence.eventos?.entradaSaida === 1 && fence.eventos?.entradaSaidaPeriferico === undefined) {
    notificationController.warning({
      message: 'É obrigatório selecionar uma opção em periférico no evento de entrada/saída.',
    });
    return false;
  }

  if (fence.eventos?.limiteVelocidade === undefined) {
    notificationController.warning({
      message: 'É obrigatório selecionar pelo menos um evento em limite de velocidade.',
    });
    return false;
  }

  if (fence.eventos?.limiteVelocidadePulsado === undefined) {
    notificationController.warning({
      message: 'É obrigatório selecionar uma opção em periférico no evento de limite de velocidade.',
    });
    return false;
  }

  if (
    !!fence.eventos.entradaSaida &&
    !fence.eventos.entradaSaidaPeriferico &&
    fence.eventos.entradaSaidaPeriferico !== 0
  ) {
    notificationController.warning({
      message: 'É obrigatório selecionar uma opção em periférico no evento de entrada/saída.',
    });
    return false;
  }

  return true;
};

const ModalEventConfigurationComponent: React.FC<IModalEventConfigurationProps> = ({
  visible,
  setVisible,
  fence,
  setFence,
}) => {
  const handleConfirm = () => {
    if (!validateEventFields(fence)) return;
    setVisible(false);
  };

  const handleChangeInputNumber = (value: string) => {
    const newValue = value.replace(',', '.');
    return parseFloat(newValue);
  };

  return (
    <>
      <Modal
        open={visible}
        title="Configuração dos eventos"
        onOk={() => handleConfirm()}
        onCancel={() => setVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
        size="large"
      >
        <S.Text>
          Cerca <S.Bold>{fence.nome}</S.Bold>
        </S.Text>
        <HelperNotification style={{ marginTop: '1rem' }}>
          <div>Obrigatório selecionar pelo menos um evento e essa opção precisa ser diferente de “Não se aplica”.</div>
          <div>O preenchimento do periférico é opcional.</div>
        </HelperNotification>
        <BaseForm layout="vertical" style={{ width: '100%', marginTop: '1rem' }}>
          <Row align="middle">
            <S.Subtitle>Evento de entrada/saída da cerca</S.Subtitle>
            <Col span={35} xs={24}>
              <BaseFormInputItem label="Tipo">
                <RadioGroup
                  value={fence?.eventos?.entradaSaida}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === 0) {
                      setFence((prev) => ({
                        ...prev,
                        eventos: {
                          ...prev.eventos,
                          entradaSaida: value,
                          entradaSaidaPeriferico: 0,
                          entradaSaidaCiclos: 0,
                          entradaSaidaTempoDesligado: 0,
                          entradaSaidaTempoLigado: 0,
                        },
                      }));
                      return;
                    }
                    setFence((prev) => ({ ...prev, eventos: { ...prev.eventos, entradaSaida: value } }));
                  }}
                >
                  <Space size={0} direction="vertical">
                    <Radio value={1}>Entrada</Radio>
                    <Radio value={2}>Saída</Radio>
                    <Radio value={3}>Entrada e Saída</Radio>
                    <Radio value={0}>Não se aplica</Radio>
                  </Space>
                </RadioGroup>
              </BaseFormInputItem>
            </Col>
            <Col xs={24}>
              <BaseFormInputItem label="Periferico" style={{ color: '#383838', fontSize: '1rem', fontWeight: 600 }}>
                <RadioGroup
                  value={fence.eventos?.entradaSaidaPeriferico}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== 1) {
                      return setFence((prev) => ({
                        ...prev,
                        eventos: {
                          ...prev.eventos,
                          entradaSaidaPeriferico: value,
                          entradaSaidaCiclos: 0,
                          entradaSaidaTempoLigado: 0,
                          entradaSaidaTempoDesligado: 0,
                        },
                      }));
                    }

                    setFence((prev) => ({
                      ...prev,
                      eventos: {
                        ...prev.eventos,
                        entradaSaidaPeriferico: value,
                        entradaSaidaCiclos: 30,
                        entradaSaidaTempoLigado: 10,
                        entradaSaidaTempoDesligado: 5,
                      },
                    }));
                  }}
                  disabled={fence.eventos?.entradaSaida === 0}
                >
                  <Space size={0} direction="vertical" style={{ textAlign: 'left' }}>
                    <Radio value={1}>Pulsado</Radio>
                    <Row gutter={18} style={{ marginTop: '0.5rem' }}>
                      <Col xs={8}>
                        <BaseFormInputItem label="Quantidade de ciclos" supportText="Entre 1 a 100 ciclos">
                          <Input
                            placeholder="Informe a quantidade de ciclos"
                            defaultValue={0}
                            value={fence.eventos?.entradaSaidaCiclos}
                            onChange={(e) => {
                              const value = e.target.value.replace(/\D/g, '');
                              setFence((prev) => ({
                                ...prev,
                                eventos: { ...prev.eventos, entradaSaidaCiclos: Number(value) },
                              }));
                            }}
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (Number(value) < 1 || Number(value) > 100) {
                                notificationController.warning({
                                  message: 'Quantidade de ciclos deve ser informado entre 1 até 100 ciclos.',
                                });
                                setFence((prev) => ({
                                  ...prev,
                                  eventos: { ...prev.eventos, entradaSaidaCiclos: 30 },
                                }));
                              }
                            }}
                            disabled={fence.eventos?.entradaSaidaPeriferico !== 1 || fence.eventos?.entradaSaida === 0}
                          />
                        </BaseFormInputItem>
                      </Col>
                      <Col xs={8}>
                        <BaseFormInputItem label="Tempo Ligado(s)" supportText="Entre 0,5 a 50 segundos">
                          <Input
                            type="number"
                            placeholder="Informe a quantidade de ciclos"
                            defaultValue={0}
                            value={fence.eventos?.entradaSaidaTempoLigado}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFence((prev) => ({
                                ...prev,
                                eventos: {
                                  ...prev.eventos,
                                  entradaSaidaTempoLigado: handleChangeInputNumber(value),
                                },
                              }));
                            }}
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (Number(value) < 0.5 || Number(value) > 50) {
                                notificationController.warning({
                                  message: 'Tempo ligado deve ser informado entre 0,5 até 50 segundos.',
                                });
                                setFence((prev) => ({
                                  ...prev,
                                  eventos: { ...prev.eventos, entradaSaidaTempoLigado: 10 },
                                }));
                              }
                            }}
                            disabled={fence.eventos?.entradaSaidaPeriferico !== 1 || fence.eventos?.entradaSaida === 0}
                          />
                        </BaseFormInputItem>
                      </Col>
                      <Col xs={8}>
                        <BaseFormInputItem label="Tempo Desligado(s)" supportText="Entre 1 a 50 segundos">
                          <Input
                            type="number"
                            placeholder="Informe a quantidade de ciclos"
                            defaultValue={0}
                            value={fence.eventos?.entradaSaidaTempoDesligado}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFence((prev) => ({
                                ...prev,
                                eventos: {
                                  ...prev.eventos,
                                  entradaSaidaTempoDesligado: handleChangeInputNumber(value),
                                },
                              }));
                            }}
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (Number(value) < 1 || Number(value) > 50) {
                                notificationController.warning({
                                  message: 'Tempo desligado deve ser informado entre 1 até 50 segundos.',
                                });
                                setFence((prev) => ({
                                  ...prev,
                                  eventos: { ...prev.eventos, entradaSaidaTempoDesligado: 5 },
                                }));
                              }
                            }}
                            disabled={fence.eventos?.entradaSaidaPeriferico !== 1 || fence.eventos?.entradaSaida === 0}
                          />
                        </BaseFormInputItem>
                      </Col>
                    </Row>
                    <Radio value={2}>Corta corrente</Radio>
                    <Radio value={0}>Não se aplica</Radio>
                  </Space>
                </RadioGroup>
              </BaseFormInputItem>
            </Col>
          </Row>
          <Divider />
          <Row align="middle">
            <S.Subtitle>Evento de limite de velocidade</S.Subtitle>
            <Col xs={24}>
              <BaseFormInputItem label="">
                <RadioGroup
                  value={fence.eventos?.limiteVelocidade}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === 0) {
                      return setFence((prev) => ({
                        ...prev,
                        eventos: {
                          ...prev.eventos,
                          limiteVelocidade: value,
                          limiteVelocidadeValor: 0,
                          limiteVelocidadePulsado: 0,
                          limiteVelocidadeCiclos: 0,
                          limiteVelocidadeTempoLigado: 0,
                          limiteVelocidadeTempoDesligado: 0,
                        },
                      }));
                    }

                    setFence((prev) => ({
                      ...prev,
                      eventos: { ...prev.eventos, limiteVelocidade: value, limiteVelocidadeValor: 70 },
                    }));
                  }}
                >
                  <Space size={0} direction="vertical">
                    <Row>
                      <Col xs={12}>
                        <Radio value={1}>{`Limite de velocidade (km/h)`}</Radio>
                      </Col>
                      <Col xs={5}>
                        <BaseFormInputItem label="" supportText="Entre 1 a 200 Km/h">
                          <Input
                            placeholder=""
                            defaultValue={0}
                            value={fence.eventos?.limiteVelocidadeValor}
                            onChange={(e) => {
                              const value = e.target.value.replace(/\D/g, '');
                              setFence((prev) => ({
                                ...prev,
                                eventos: { ...prev.eventos, limiteVelocidadeValor: Number(value) },
                              }));
                            }}
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (Number(value) < 1 || Number(value) > 200) {
                                notificationController.warning({
                                  message: 'Limite de velocidade deve ser informado entre 1 até 200 Km/h.',
                                });
                                setFence((prev) => ({
                                  ...prev,
                                  eventos: { ...prev.eventos, limiteVelocidadeValor: 70 },
                                }));
                              }
                            }}
                            disabled={fence.eventos?.limiteVelocidade !== 1}
                          />
                        </BaseFormInputItem>
                      </Col>
                    </Row>
                    <Radio value={0}>Não se aplica</Radio>
                  </Space>
                </RadioGroup>
              </BaseFormInputItem>
            </Col>
            <Col xs={24}>
              <BaseFormInputItem label="Periferico">
                <RadioGroup
                  value={fence.eventos?.limiteVelocidadePulsado}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== 1) {
                      return setFence((prev) => ({
                        ...prev,
                        eventos: {
                          ...prev.eventos,
                          limiteVelocidadePulsado: value,
                          limiteVelocidadeCiclos: 0,
                          limiteVelocidadeTempoLigado: 0,
                          limiteVelocidadeTempoDesligado: 0,
                        },
                      }));
                    }
                    setFence((prev) => ({
                      ...prev,
                      eventos: {
                        ...prev.eventos,
                        limiteVelocidadePulsado: value,
                        limiteVelocidadeCiclos: 30,
                        limiteVelocidadeTempoLigado: 10,
                        limiteVelocidadeTempoDesligado: 5,
                      },
                    }));
                  }}
                  disabled={fence.eventos?.limiteVelocidade === 0}
                >
                  <Space size={0} direction="vertical">
                    <Radio value={1}>Pulsado</Radio>
                    <Row gutter={18} style={{ marginTop: '0.5rem' }}>
                      <Col xs={8}>
                        <BaseFormInputItem label="Quantidade de ciclos" supportText="Entre 1 a 100 ciclos">
                          <Input
                            placeholder="Informe a quantidade de ciclos"
                            defaultValue={0}
                            value={fence.eventos?.limiteVelocidadeCiclos}
                            onChange={(e) => {
                              const value = e.target.value.replace(/\D/g, '');
                              setFence((prev) => ({
                                ...prev,
                                eventos: { ...prev.eventos, limiteVelocidadeCiclos: Number(value) },
                              }));
                            }}
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (Number(value) < 1 || Number(value) > 100) {
                                notificationController.warning({
                                  message: 'Quantidade de ciclos deve ser informado entre 1 até 100 ciclos.',
                                });
                                setFence((prev) => ({
                                  ...prev,
                                  eventos: { ...prev.eventos, limiteVelocidadeCiclos: 30 },
                                }));
                              }
                            }}
                            disabled={
                              fence.eventos?.limiteVelocidadePulsado !== 1 || fence.eventos?.limiteVelocidade === 0
                            }
                          />
                        </BaseFormInputItem>
                      </Col>
                      <Col xs={8}>
                        <BaseFormInputItem label="Tempo Ligado(s)" supportText="Entre 1 a 50 segundos">
                          <Input
                            type="number"
                            placeholder="Informe a quantidade de ciclos"
                            defaultValue={0}
                            value={fence.eventos?.limiteVelocidadeTempoLigado}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFence((prev) => ({
                                ...prev,
                                eventos: {
                                  ...prev.eventos,
                                  limiteVelocidadeTempoLigado: handleChangeInputNumber(value),
                                },
                              }));
                            }}
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (Number(value) < 1 || Number(value) > 50) {
                                notificationController.warning({
                                  message: 'Tempo ligado deve ser informado entre 1 até 50 segundos.',
                                });
                                setFence((prev) => ({
                                  ...prev,
                                  eventos: { ...prev.eventos, limiteVelocidadeTempoLigado: 10 },
                                }));
                              }
                            }}
                            disabled={
                              fence.eventos?.limiteVelocidadePulsado !== 1 || fence.eventos?.limiteVelocidade === 0
                            }
                          />
                        </BaseFormInputItem>
                      </Col>
                      <Col xs={8}>
                        <BaseFormInputItem label="Tempo Desligado(s)" supportText="Entre 1 a 50 segundos">
                          <Input
                            type="number"
                            placeholder="Informe a quantidade de ciclos"
                            defaultValue={0}
                            value={fence.eventos?.limiteVelocidadeTempoDesligado}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFence((prev) => ({
                                ...prev,
                                eventos: {
                                  ...prev.eventos,
                                  limiteVelocidadeTempoDesligado: handleChangeInputNumber(value),
                                },
                              }));
                            }}
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (Number(value) < 1 || Number(value) > 50) {
                                notificationController.warning({
                                  message: 'Tempo desligado deve ser informado entre 1 até 50 segundos.',
                                });
                                setFence((prev) => ({
                                  ...prev,
                                  eventos: { ...prev.eventos, limiteVelocidadeTempoDesligado: 5 },
                                }));
                              }
                            }}
                            disabled={
                              fence.eventos?.limiteVelocidadePulsado !== 1 || fence.eventos?.limiteVelocidade === 0
                            }
                          />
                        </BaseFormInputItem>
                      </Col>
                    </Row>
                    <Radio value={0}>Não se aplica</Radio>
                  </Space>
                </RadioGroup>
              </BaseFormInputItem>
            </Col>
          </Row>
        </BaseForm>
      </Modal>
    </>
  );
};

export default ModalEventConfigurationComponent;
