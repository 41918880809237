import * as React from 'react';
const SvgIconClose = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" width="1em" height="1em" role="img">
    <path
      fill="#383838"
      d="M20.08 17.998 30.629 5.426a.32.32 0 0 0-.246-.526h-3.206a.654.654 0 0 0-.494.229l-8.699 10.37-8.698-10.37a.642.642 0 0 0-.495-.23H5.584a.32.32 0 0 0-.245.527l10.547 12.572L5.339 30.57a.32.32 0 0 0 .245.526H8.79c.19 0 .37-.084.495-.229l8.698-10.37 8.699 10.37a.64.64 0 0 0 .494.23h3.206a.32.32 0 0 0 .245-.527L20.082 17.998Z"
    />
  </svg>
);
export default SvgIconClose;
