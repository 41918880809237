import { ApiServiceBase, IApiServiceBase } from './base/apiServiceBase';
import { FenceGroupModel } from '@app/domain/fence/fenceGroupModel';

export default interface IFenceService extends IApiServiceBase<FenceGroupModel, FenceGroupModel> {}

export class FenceService extends ApiServiceBase<FenceGroupModel, FenceGroupModel> {
  constructor() {
    super('Cerca');
  }
}
