import { PrepareAction, createAction, createSlice } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

export interface HeaderSlice {
  loadingState: boolean;
  icon: ReactNode;
  title: string;
  buttonText: string;
  handleButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
  drawerVisible: boolean;
}

const initialState: HeaderSlice = {
  loadingState: false,
  icon: '',
  title: '',
  buttonText: '',
  handleButtonClick: (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  },
  drawerVisible: false,
};

export const setDrawerVisible = createAction<PrepareAction<boolean>>('header/setDrawerVisible', (visible: boolean) => {
  return {
    payload: visible,
  };
});

export const setHeader = createAction<PrepareAction<HeaderSlice>>('header/setHeader', (newHeader: HeaderSlice) => {
  return {
    payload: newHeader,
  };
});

export const setAlertTextPredicate = createAction<PrepareAction<string>>(
  'header/setAlertTextPredicate',
  (newAlertTextPredicate: string) => {
    return {
      payload: newAlertTextPredicate,
    };
  },
);

export const headerSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setHeader, (state, action) => {
      state.loadingState = true;
      state.title = action.payload.title;
      state.icon = action.payload.icon;
      state.buttonText = action.payload.buttonText;
      state.handleButtonClick = action.payload.handleButtonClick;
      state.loadingState = false;
    });

    builder.addCase(setDrawerVisible, (state, action) => {
      state.drawerVisible = action.payload;
    });
  },
});

export default headerSlice.reducer;
