import styled from 'styled-components';

export const InsideTable = styled.table`
  background-color: #ffffff;

  thead {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #545454;
    flex: none;
    order: 1;
    flex-grow: 0;
    background-color: #ffffff;
  }
  th {
    padding: 16px 24px;
    border-bottom: 1px solid #bababa;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #545454;
  }

  tbody .ant-table-cell {
    padding: 0rem !important;
  }

  td {
    padding: 16px 32px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: 1px solid #bababa;
  }

  tbody tr td {
    background-color: #f8f8f8;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #707070;
  }

  tbody tr:last-child td {
    border-bottom: none !important;
    border-bottom-left-radius: 8 !important;
    border-bottom-right-radius: 8 !important;
  }

  .td-title {
    color: var(--Neutral-Gray-Gray700, #545454);
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
`;
