import { ApiServiceBase, IApiServiceBase } from './base/apiServiceBase';
import { DriverModel } from '@app/domain/driver/driverModel';

export default interface IDriverService extends IApiServiceBase<DriverModel, DriverModel> {}

export class DriverService extends ApiServiceBase<DriverModel, DriverModel> {
  constructor() {
    super('Drivers');
  }
}
