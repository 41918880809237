import { Spinner } from '@app/components/common/Spinner/Spinner';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';

import * as S from './view.styles';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { ScheduleFactoryService } from '@app/services/scheduleFactoryService';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { ScheduleFactoryModel } from '@app/domain/scheduleFactory/scheduleFactoryModel';
import { ReactComponent as IconCanceled } from '@app/assets/icons/highlight_off.svg';
import { ReactComponent as IconConcluded } from '@app/assets/icons/check_circle.svg';
import { ReactComponent as IconUpdating } from '@app/assets/icons/autorenew.svg';
import moment from 'moment/moment';
import { CardInfoContainer } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { setFooter } from '@app/store/slices/footerSlice';

const scheduleService = new ScheduleFactoryService();

export const FactoryScheduleView: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState<ScheduleFactoryModel>();

  const fetchScheduleById = useCallback(() => {
    setLoading(true);
    scheduleService
      .get(`${id}`)
      .then((response) => {
        setSchedule(response);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: 'Visualizar atualização de firmware',
        handleBackClick: () => navigate('/agendamento-versao'),
      }),
    );
    dispatch(
      setFooter({
        confirmButtonText: 'Sair',
        handleConfirmButtonClick: () => navigate('/agendamento-versao'),
      }),
    );
    fetchScheduleById();
  }, [dispatch, fetchScheduleById, navigate]);

  const getEquipmentStatus = useCallback(
    (atualizado: boolean) => {
      if (schedule?.status === 'Cancelado') return <IconCanceled />;

      return atualizado ? <IconConcluded /> : <IconUpdating />;
    },
    [schedule],
  );

  return (
    <Spinner spinning={loading}>
      <PageContainer>
        <CardInfoContainer style={{ marginBottom: '3rem' }}>
          <S.Title>Informações do agendamento </S.Title>
          <S.InfoContainer>
            <S.InfoItem>
              <S.InfoItemTitle>Equipamento</S.InfoItemTitle>
              <S.InfoItemValue>{schedule?.tipoEquipamento}</S.InfoItemValue>
            </S.InfoItem>
            <S.InfoItem>
              <S.InfoItemTitle>Modelo</S.InfoItemTitle>
              <S.InfoItemValue>{schedule?.modelo}</S.InfoItemValue>
            </S.InfoItem>
            <S.InfoItem>
              <S.InfoItemTitle>Versão</S.InfoItemTitle>
              <S.InfoItemValue>{schedule?.versaoFirmware}</S.InfoItemValue>
            </S.InfoItem>
            <S.InfoItem>
              <S.InfoItemTitle>Status</S.InfoItemTitle>
              <S.InfoItemValue>{schedule?.status}</S.InfoItemValue>
            </S.InfoItem>
            <S.InfoItem>
              <S.InfoItemTitle>Data/Hora</S.InfoItemTitle>
              <S.InfoItemValue>{moment(schedule?.dataHoraProgramada).format('DD/MM/YYYY HH:mm') + 'h'}</S.InfoItemValue>
            </S.InfoItem>
            <S.InfoItem>
              <S.InfoItemTitle>Usuário responsável</S.InfoItemTitle>
              <S.InfoItemValue>{schedule?.usuarioResponsavel}</S.InfoItemValue>
            </S.InfoItem>
          </S.InfoContainer>
          <S.Divider />
          {/*{JSON.stringify(schedule)}*/}
          {schedule?.dispositivos?.map((device, index) => (
            <S.ClientContainer key={device.cliente}>
              <S.ClientTitleWrapper>
                <S.ClientLabel>Cliente</S.ClientLabel>
                <S.ClientName>{device.cliente}</S.ClientName>
              </S.ClientTitleWrapper>
              <S.EquipmentWrapper>
                <S.EquipmentQuantity>{device.dispositivos.length}</S.EquipmentQuantity>
                <S.EquipmentLabel>{device.dispositivos.length >= 1 ? 'Equipamentos' : 'Equipamento'}</S.EquipmentLabel>
              </S.EquipmentWrapper>
              <S.EquipmentsContainer>
                {device.dispositivos.map((equipment) => (
                  <S.EquipmentItem key={equipment.id}>
                    <S.EquipmentVersion>{equipment.numeroSerial}</S.EquipmentVersion>
                    <S.EquipmentStatus>{getEquipmentStatus(equipment.atualizado)}</S.EquipmentStatus>
                  </S.EquipmentItem>
                ))}
              </S.EquipmentsContainer>
              {schedule?.dispositivos?.length && schedule?.dispositivos?.length - 1 !== index && <S.Divider />}
            </S.ClientContainer>
          ))}
        </CardInfoContainer>
      </PageContainer>
    </Spinner>
  );
};
