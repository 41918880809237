import { FONT_SIZE, media } from '@app/styles/themes/constants';
import { Dropdown as AntDropdown } from 'antd';
import styled from 'styled-components';

export const DropdownTable = styled(AntDropdown)`
  display: flex;
  justify-content: start;
  align-items: flex-start;

  font-size: 1.5rem;
  border-radius: 0.25rem;
  height: 2.5rem;
  background: white;
  color: #263668;
  font-weight: 700;
  background-color: transparent;

  &:hover {
    background-color: transparent;
    color: #374450;
  }

  .ant-dropdown-menu-item-only-child {
    height: 2.5rem;

    &:hover {
      background-color: #e6ecf1;
    }
  }
  .ant-btn .ant-btn-text {
    color: #374450;
    font-family: 'Mulish';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    text-decoration: none;

    display: flex;
    justify-content: start;
    align-items: flex-start;

    &:hover {
      color: #374450;
    }

    @media only screen and (${media.xs}) {
      font-size: ${FONT_SIZE.xxs};
    }

    @media only screen and (${media.md}) {
      font-size: 0.875rem;
    }
  }

  @media only screen and (${media.xs}) {
    font-size: ${FONT_SIZE.xs};
  }
  @media only screen and (${media.md}) {
    font-size: ${FONT_SIZE.md};
  }
  @media only screen and (${media.xxl}) {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    font-size: 1.25rem;
  }
`;
