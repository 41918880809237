import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

export const FenceTypeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

interface FenceTypeButtonProps {
  active?: boolean;
}

export const FenceTypeButton = styled.div<FenceTypeButtonProps>`
  display: flex;
  height: 3rem;
  padding: 1rem 1.5rem;
  align-items: center;
  border-radius: 2.5rem;
  cursor: pointer;

  background: ${({ active }) => (active ? 'var(--brand-colors-blue-blue-500, #263668)' : 'white')};
  color: ${({ active }) => (active ? 'white' : 'black')};
  border: ${({ active }) => (active ? 'none' : '1px solid #475582')};

  &:hover {
    background: ${({ active }) => (active ? '#1a2948' : '#f8f8f8')};
    color: ${({ active }) => (active ? '#f0f0f0' : '#2d2d2d')};
    border: ${({ active }) => (active ? 'none' : '1px solid #3d5077')};
  }
`;
export const ConfigButtonWraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
  height: 108px;
`;

export const FenceMapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
`;

export const AddFenceButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;

  width: 100%;
  height: auto;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.125rem;
  background: #d9d9d9;
  margin: 1.5rem 0;
`;
