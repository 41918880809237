import React from 'react';
import * as S from './DevicesToSaveTable.styles';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { ColumnsType } from 'antd/lib/table';
// import Dashboard from '@app/components/dashboard/Dashboard';
import { Table } from '@app/components/common/Table/Table';
import { Col, Row, Tooltip } from 'antd';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';

type DevicesToSaveProps = {
  factoryDevices: FactoryDeviceModel[];
  setFactoryDevices: React.Dispatch<React.SetStateAction<FactoryDeviceModel[]>>;
  showDelete?: boolean;
};

export const DevicesToSaveTable = ({ factoryDevices, setFactoryDevices, showDelete = true }: DevicesToSaveProps) => {
  const columns: ColumnsType<FactoryDeviceModel> = [
    // {
    //   title: 'Cód.',
    //   dataIndex: 'id',
    //   showSorterTooltip: false,
    //   // sorter: (a, b) => a.serial.localeCompare(b.serial),
    //   width: '8%',
    // },
    {
      title: 'Equipamento',
      dataIndex: 'tipoEquipamento',
      showSorterTooltip: false,
      // sorter: (a, b) => a.serial.localeCompare(b.serial),
      width: '18%',
    },
    {
      title: 'Modelo',
      dataIndex: 'modelo',
      showSorterTooltip: false,
      // sorter: (a, b) => a.model.localeCompare(b.model),
      width: '12%',
    },
    {
      title: 'SN',
      dataIndex: 'numeroSerie',
      showSorterTooltip: false,
      // sorter: (a, b) => a.manufacturingBatch.localeCompare(b.manufacturingBatch),
      width: '18%',
    },
    {
      title: 'Versão de firmware',
      dataIndex: 'versaoFirmware',
      showSorterTooltip: false,
      // sorter: (a, b) => a.manufacturingBatch.localeCompare(b.manufacturingBatch),
      width: '8%',
    },
    {
      title: 'IMEI',
      dataIndex: 'imei',
      showSorterTooltip: false,
      // sorter: (a, b) => a.IMEI.localeCompare(b.IMEI),
      width: '10%',
    },
    {
      title: 'Anatel',
      dataIndex: 'anatel',
      showSorterTooltip: false,
      // sorter: (a, b) => a.anatelCertificate.localeCompare(b.anatelCertificate),
      width: '10%',
    },
    {
      title: '',
      dataIndex: 'imei',
      showSorterTooltip: false,
      // sorter: (a, b) => a.serial.localeCompare(b.serial),
      width: '3%',
      render: (imei: string, record: FactoryDeviceModel) => {
        return showDelete ? (
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Tooltip placement="left" title="Remover dispositivo">
                <DeleteOutlined
                  onClick={() => setFactoryDevices(factoryDevices.filter((device) => device.imei != imei))}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </Col>
            {record.messageError && (
              <Col span={12}>
                <Tooltip placement="left" title={record.messageError}>
                  <InfoCircleOutlined style={{ cursor: 'pointer', color: 'red' }} />
                </Tooltip>
              </Col>
            )}
          </Row>
        ) : (
          <></>
        );
      },
    },
  ];
  return (
    <>
      {factoryDevices.length > 0 ? (
        <>
          <Table columns={columns} dataSource={factoryDevices} scroll={{ x: 800 }} bordered />
        </>
      ) : (
        <S.NoDevicesContainer>
          <S.NoDevicesTitle>Nenhum cadastro realizado</S.NoDevicesTitle>
          <span>Cadastre o(s) dispositivo(s) para exibir os resultados</span>
        </S.NoDevicesContainer>
      )}
    </>
  );
};
