import styled, { css } from 'styled-components';

interface CollapsibleCardProps {
  isCollapsed: boolean;
}

export const CollapsibleCardWrapper = styled.div`
  width: 100%;
`;
export const CollapsibleCardHeader = styled.div<CollapsibleCardProps>`
  font-family: Mulish;
  width: 100%;
  min-height: 4rem;
  padding: 1rem 1.5rem 1rem 1.5rem;
  gap: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.isCollapsed
      ? css`
          border-bottom: 1px solid #d1d1d1;
        `
      : css`
          border-radius: 0.5rem;
          background-color: #e6ecf1;
          border: 1px solid #263668;
        `}
`;
export const CollapsibleCardHeaderDateDay = styled.div`
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.25rem;
  text-align: center;
  color: #263668;
`;

export const CollapsibleCardHeaderDateMonth = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  text-align: center;
  color: #263668;
`;
export const CollapsibleCardHeaderTitle = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
`;
export const CollapsibleCardHeaderSubtitle = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
`;
export const CollapsibleCardHeaderIcon = styled.div`
  color: #545454;
  stroke-width: 20;
  stroke: black;
`;

export const CollapsibleCardContent = styled.div<CollapsibleCardProps>`
  font-weight: 300;
  line-height: 1.25rem;
  color: #707070;
  padding: 1rem;
  height: auto;
  display: ${(props) => (props.isCollapsed ? 'none' : 'block')};
  overflow: hidden;
  transition: display 1s ease-in-out;
  padding: 1.5rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #d1d1d1;
  border-top: none;
  margin-top: -0.25rem;
`;
