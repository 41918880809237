import React from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { IHeaderTable } from '@app/constants/interfaces/Dashboard/IDashboard';
import * as S from './HeaderTable.styles';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import { ReactComponent as ExportCsvIcon } from '@app/assets/icons/export-csv.svg';

import { Col } from 'antd';

export const HeaderTable: React.FC<IHeaderTable> = ({
  title,
  filter,
  tags,
  buttonText,
  handleButtonClick,
  placeholderTextSearch,
  handleSearchOnChange,
  valueSearch,
  icon,
  exportToExcel,
}) => (
  <S.Container>
    <S.HeaderTable>
      {(title?.length ?? 0) > 0 ? <S.HeaderTableTitle>{title}</S.HeaderTableTitle> : null}
      <S.HeaderFilterContainer>
        {!!filter && <S.HeaderFilter>{filter}</S.HeaderFilter>}
        {!!tags && <S.Tag>{tags}</S.Tag>}
      </S.HeaderFilterContainer>
      <S.FlexContainer>
        {!!exportToExcel && (
          <Col>
            <Button type="link" onClick={() => exportToExcel()}>
              <S.ExportButtonContainer>
                {<ExportCsvIcon />}
                Exportar dados excel
              </S.ExportButtonContainer>
            </Button>
          </Col>
        )}
        {handleSearchOnChange && (
          <Col xl={10} xxl={10} style={{ marginTop: '1rem' }}>
            {(placeholderTextSearch?.length ?? 0) > 0 ? (
              <BaseFormInputItem label="">
                <SearchInput value={valueSearch} placeholder={placeholderTextSearch} onChange={handleSearchOnChange} />
              </BaseFormInputItem>
            ) : null}
          </Col>
        )}
        <Col>
          {buttonText && (
            <Button type="primary" style={{ marginLeft: '1.5rem' }} onClick={handleButtonClick}>
              <S.ButtonContainer>
                {icon}
                {buttonText}
              </S.ButtonContainer>
            </Button>
          )}
        </Col>
      </S.FlexContainer>
    </S.HeaderTable>
  </S.Container>
);
