import { styled } from 'styled-components';
import { Checkbox } from '../../Checkbox/Checkbox';
import { Collapse as ModulesCollapse } from '../ModulesProfileConfig.styles';

export const Collapse = ModulesCollapse;

export const CollapseHeaderName = styled.span`
  color: var(--Neutral-Gray-Gray800, #383838);
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Subtitle = styled.span`
  color: #545454;
  font-family: 'Mulish';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const CanVehiclesParameterName = styled.span`
  color: #545454;
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
`;

export const ParametersQuantity = styled.div`
  color: var(--Neutral-Gray-Gray700, #545454);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
`;

export const ParametersQuantityDescription = styled.div`
  color: var(--Neutral-Gray-Gray500, #8c8c8c);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1.5rem;
  //gap: 1.5rem;
  width: 100%;
`;

export const InputObs = styled.span`
  font-size: 0.75rem;
  color: #999;
`;

export const FieldCheckboxValue = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2.7rem;
  margin-bottom: 0.5rem;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 0.5rem;
  width: 16rem;
`;

export const CheckboxLabel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
  white-space: nowrap;

  color: var(--neutral-gray-gray-700, #545454);
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CheckboxDisabled = styled(Checkbox)`
  pointer-events: none;
  opacity: 0.5;
`;
