import styled from 'styled-components';

export const ContainerListDevices = styled.div`
  flex: 1;
  min-width: 35rem;
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

export const PanelContainer = styled.div`
  display: flex;
  column-gap: 3.5rem;
  row-gap: 1rem;
  flex-wrap: wrap;
`;

export const DeviceInfo = styled.div`
  white-space: nowrap;

  color: #545454;
  font-family: Mulish;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-wrap: avoid;
`;

export const EditLink = styled.a`
  color: var(--base-colors-primary-blue, #263668);
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
`;
