import { DeviceTwinHistoryModel } from '@app/domain/device-history/device-twin-history-model';
import { formatarData } from '@app/utils/utils';
import * as S from './viewDevice.styles';
import { ColumnsType } from 'antd/lib/table';
import { FC } from 'react';
import { Row } from 'antd';
import { Table } from '@app/components/common/Table/Table';

interface ViewDeviceTwinHistoryProps {
  twinHistory: DeviceTwinHistoryModel[];
}

export const ViewDeviceTwinHistory: FC<ViewDeviceTwinHistoryProps> = ({ twinHistory }) => {
  const columns: ColumnsType<DeviceTwinHistoryModel> = [
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      sorter: (a, b) => new Date(a.dataHora ?? '').getTime() - new Date(b.dataHora ?? '').getTime(),
      render: (dataHora) => formatarData(dataHora),
    },
    {
      title: 'Propriedade',
      dataIndex: 'propriedade',
      showSorterTooltip: false,
      sorter: (a, b) => a.propriedade.localeCompare(b.propriedade),
    },
    {
      title: 'Valor atualizado',
      dataIndex: 'valorPropriedadeAtualizado',
      showSorterTooltip: false,
      sorter: (a, b) => a.valorPropriedadeAtualizado.localeCompare(b.valorPropriedadeAtualizado),
    },
  ];

  return (
    <>
      {twinHistory.length > 0 ? (
        <S.Container>
          <h1>Histórico</h1>
          <Table columns={columns} dataSource={twinHistory} scroll={{ x: 800 }} bordered />
        </S.Container>
      ) : (
        <Row justify="center" align="middle">
          <S.TableNoContentCol xs={24} md={24}>
            Nenhuma alteração de propriedade realizada
          </S.TableNoContentCol>
        </Row>
      )}
    </>
  );
};
