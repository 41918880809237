/* eslint-disable react-hooks/exhaustive-deps */
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { notificationController } from '@app/controllers/notificationController';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import {
  DeviceProfileModel,
  DeviceProfileProperty,
  DeviceProfilePropertyModel,
  PropertyGroupedData,
  PropertyValue,
} from '@app/domain/deviceProfile/deviceProfileModel';
import ModuleViewComponent from '@app/pages/device-profile/components/ModuleViewComponent';

import IDeviceProfileService, { DeviceProfileService } from '@app/services/deviceProfileService';
import IFirmwareGroupService, { FirmwareGroupService } from '@app/services/firmwareGroupService';
import { Col, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';

interface IViewDeviceProfile {
  deviceProfileHistory: DeviceProfileProperty[];
  device: FactoryDeviceModel | null;
  // setLoading: Dispatch<SetStateAction<boolean>>;
  // loading: boolean;
}

const firmwareGroupService: IFirmwareGroupService = new FirmwareGroupService();
const profileService: IDeviceProfileService = new DeviceProfileService();

export const ViewDeviceProfile: React.FC<IViewDeviceProfile> = ({ deviceProfileHistory, device }) => {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<DeviceProfileModel>();
  const [data, setData] = useState<DeviceProfilePropertyModel[]>([]);
  const [properties, setProperties] = useState<PropertyValue[]>([]);
  const [idGroupFence, setIdGroupFence] = useState<number>();

  const fetchPropertiesByModelAndFirmwareGroup = useCallback((idModelo: number, idGrupoFirmware: number) => {
    setLoading(true);
    return firmwareGroupService
      .getArray(`/${idGrupoFirmware}/${idModelo}/propriedades`)
      .then((res: DeviceProfilePropertyModel[]) => {
        setData(res);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const fetchProfile = useCallback((idProfile: number) => {
    setLoading(true);
    return profileService
      .get(`/${idProfile}`)
      .then((res) => {
        setProfile(res);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    setProperties(
      deviceProfileHistory.map((d) => {
        return {
          idEmbarcado: d.idEmbarcado,
          idPropriedade: d.idPropriedade,
          valorPropriedade: d.valorPropriedade,
        };
      }),
    );

    const idGroupProperty = deviceProfileHistory.find((d) => d.idEmbarcado == DevicePropertyType.IdGrupoCercas)
      ?.valorPropriedade;
    setIdGroupFence(idGroupProperty ? Number(idGroupProperty) : profile?.idGrupoCerca);

    if (deviceProfileHistory.length > 0 && device !== null) {
      fetchPropertiesByModelAndFirmwareGroup(device.idModelo ?? 0, device.idGrupoFirmware ?? 0);
    }

    if (device?.idPerfilDispositivo) {
      fetchProfile(device?.idPerfilDispositivo);
    }

    setLoading(false);
  }, [deviceProfileHistory]);

  const groupedData = data.reduce((acc: PropertyGroupedData, item: DeviceProfilePropertyModel) => {
    const key = item.modulo || 'unknown';
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {} as PropertyGroupedData);

  return (
    <>
      <Spinner spinning={loading}>
        {deviceProfileHistory.length > 0 ? (
          <>
            <h1>Informações dos parâmetros do perfil</h1>
            <BaseForm layout="vertical" style={{ width: '100%' }}>
              {Object.keys(groupedData).map((moduleName, index) => {
                if (moduleName.toLowerCase() == 'can' || moduleName.toLowerCase() == 'cercas') return;
                return (
                  <ModuleViewComponent
                    key={moduleName}
                    properties={properties}
                    setProperties={setProperties}
                    moduleData={groupedData[moduleName]}
                    moduleName={moduleName}
                    hasBorderBottom={Object.keys(groupedData).length !== index + 1}
                    // idManufacturer={profile?.idFabricante}
                    // idVehicle={profile?.idVeiculoCan}
                    idGroup={idGroupFence}
                    device={device}
                  />
                );
              })}
            </BaseForm>
          </>
        ) : (
          <Row justify="center" align="middle">
            <Col xs={24} md={24} style={{ color: '#707070', fontSize: '1rem', fontWeight: 700, textAlign: 'center' }}>
              Nenhum perfil de configuração associado
            </Col>
          </Row>
        )}
      </Spinner>
    </>
  );
};
