import { BurgerIcon } from '@app/components/common/Burger/BurgerIcon';
import { BORDER_RADIUS, FONT_WEIGHT, LAYOUT, media } from '@app/styles/themes/constants';
import { Col, Collapse, Menu } from 'antd';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const DropdownMenu = styled(Menu)`
  line-height: 1.5715;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: ${BORDER_RADIUS};

  &:hover {
    color: #374450;
  }

  &.ant-dropdown-menu {
    box-shadow: var(--box-shadow);
  }
  &.ant-dropdown-menu-title-content {
    font-size: 12px;
  }
`;

export const HeaderActionWrapper = styled.div`
  cursor: pointer;

  & > .ant-btn > span[role='img'],
  .ant-badge {
    font-size: 1.25rem;
    @media only screen and ${media.md} {
      font-size: 1.625rem;
    }
  }

  & .ant-badge {
    display: inline-block;
  }
`;

export const DropdownCollapse = styled(Collapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    font-weight: 600;
    font-size: 0.875rem;

    color: var(--secondary-color);

    @media only screen and ${media.md} {
      font-size: 0.875rem;
    }
  }

  & > .ant-collapse-item-disabled > .ant-collapse-header {
    cursor: default;

    & > span[role='img'] {
      display: none;
    }
  }
`;

export const BurgerCol = styled(Col)`
  z-index: 999;
  display: flex;
`;

export const MobileBurger = styled(BurgerIcon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: -0.5rem;
  color: var(--text-main-color);

  ${(props) =>
    props.isCross &&
    css`
      color: var(--text-secondary-color);
    `};
`;

// export const SearchColumn = styled(Col)`
//   padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
// `;

interface ProfileColumn {
  $isTwoColumnsLayout: boolean;
}

export const ProfileColumn = styled(Col)<ProfileColumn>`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};

  @media only screen and ${media.md} {
    ${(props) =>
      props?.$isTwoColumnsLayout &&
      css`
        background-color: var(--sider-background-color);
        padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
      `}
  }
`;

export const IconBack = styled(Link)`
  /* padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal}; */
  margin-left: 2rem;
`;

export const HeaderTitle = styled.h1`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};

  font-family: 'Mulish';
  font-size: 2rem !important;
  font-weight: ${FONT_WEIGHT.bold};
  color: var(--tooltip-background);

  @media only screen and ${media.md} {
    font-family: 'Mulish';
    font-size: 1.5rem;
    flex-wrap: wrap;
    word-wrap: break-word;
  }
  @media only screen and ${media.xs} {
    font-size: 1.4rem;
  }
  @media only screen and ${media.xxl} {
    font-size: 2rem;
  }
`;
