import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import IFenceService, { FenceService } from '@app/services/fenceService';
import { Row, Col, Select } from 'antd';
import { IconMap } from '@app/assets/slump-icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { FenceGroupModel } from '@app/domain/fence/fenceGroupModel';
import { FenceModel } from '@app/domain/fence/fenceModel';
import { useCallback, useEffect, useState } from 'react';
import { notificationController } from '@app/controllers/notificationController';
import { Modal } from '@app/components/common/Modal/Modal';
import MapComponentView from '@app/pages/fence/view/components/MapComponentView/MapComponentView';
import { CardInfoTitle, CardInfoValue } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Panel } from '@app/components/common/Collapse/Collapse';
import { groupBy } from 'lodash';
import * as S from './Cerca.styles';

interface CercaProps {
  idModel: number;
  idGroup?: number;
  setIdGroup: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const fenceService: IFenceService = new FenceService();

export const CercaConfigUpdate: React.FC<CercaProps> = ({ idGroup, setIdGroup, idModel }) => {
  const [group, setGroup] = useState<FenceGroupModel>();
  const [groups, setGroups] = useState<FenceGroupModel[]>([]);
  const [fences, setFences] = useState<FenceModel[]>([]);
  const [modalMapVisible, setModalMapVisible] = useState<boolean>(false);

  const handleChangeGroup = (idGroup: number) => {
    setIdGroup(idGroup);
    setGroup(groups.find((g) => g.id == idGroup));
  };

  const fetchGroupId = (id: number) => {
    fenceService
      .get(`/grupos/${id}`)
      .then((response) => {
        setFences(response.cercas ?? []);
      })
      .catch((err) => {
        notificationController.error({ message: `Erro ao buscar cerca. ${err}` });
      });
  };

  const fetchGroups = useCallback(() => {
    fenceService
      .getArray(`/grupos-por-modelo/${idModel}`)
      .then((res: FenceGroupModel[]) => setGroups(res.filter((g) => g.ativo)))
      .catch(() => notificationController.error({ message: `Erro ao obter cercas.` }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  useEffect(() => {
    if (idGroup) fetchGroupId(idGroup);
  }, [idGroup, fetchGroups]);

  return (
    <>
      <BaseForm layout="vertical" style={{ width: '100%', overflowX: 'hidden' }}>
        <Row gutter={18}>
          <Col xs={24}>
            <BaseFormInputItem label="Grupo de cercas">
              <Select
                showArrow
                showSearch
                value={idGroup}
                placeholder="Selecione o grupo"
                onChange={(value) => handleChangeGroup(Number(value))}
                options={groups.map((g) => ({ value: g.id, label: g.nome }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              ></Select>
            </BaseFormInputItem>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}>
            <Button type="primary" icon={<IconMap />} onClick={() => setModalMapVisible(true)} disabled={!idGroup}>
              Visualizar mapa
            </Button>
          </Col>
        </Row>
        <Modal
          title="Visualizar mapa"
          open={modalMapVisible}
          width={'100%'}
          onCancel={() => setModalMapVisible(false)}
          footer
        >
          <Row>
            <Col xs={24} sm={12} md={6}>
              <CardInfoValue>Grupo</CardInfoValue>
              &nbsp;<CardInfoTitle>{group?.nome}</CardInfoTitle>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <CardInfoValue>Quantidade de cercas</CardInfoValue>
              &nbsp;<CardInfoTitle>{fences?.length}</CardInfoTitle>
            </Col>
          </Row>
          <MapComponentView showInactiveFences={false} fences={fences} />
          <S.CollapseMap activeKey={1} style={{ marginTop: '1rem' }}>
            <Panel
              key="1"
              header={
                <Row align={'middle'} justify={'space-between'}>
                  <Col>
                    <S.CollapseHeaderName>Cercas do grupo</S.CollapseHeaderName>
                  </Col>
                </Row>
              }
            >
              {Object.keys(groupBy(fences, (f) => f.tipoCerca)).map((f) => {
                const fencesFiltered = fences.filter((fence) => fence.tipoCerca == f);
                return (
                  <div key={f} style={{ paddingBottom: '1rem' }}>
                    <Row>
                      <S.TableTypeName>{f}</S.TableTypeName>
                    </Row>
                    <Row>
                      <S.TableFenceQuantity>{fencesFiltered?.length}</S.TableFenceQuantity>&nbsp;
                      <S.TableFenceDescription>
                        {fencesFiltered?.length > 1 ? 'Cercas' : 'Cerca'}
                      </S.TableFenceDescription>
                    </Row>
                    <Row gutter={[18, 18]} style={{ overflowY: 'auto', maxHeight: '30rem', width: '100%' }}>
                      {fencesFiltered?.map((item, index) => {
                        return (
                          <Col key={index} xs={12} xl={6}>
                            <S.TableFenceName>{item.nome}</S.TableFenceName>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                );
              })}
            </Panel>
          </S.CollapseMap>
        </Modal>
      </BaseForm>
    </>
  );
};
