import { CloseCircleOutlined } from '@ant-design/icons';
import { IconMore } from '@app/assets/slump-icons';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Modal } from '@app/components/common/Modal/Modal';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import { Tag } from '@app/components/common/Tag/Tag';
import { Button } from '@app/components/common/buttons/Button/Button';
import Dashboard from '@app/components/dashboard/Dashboard';
import { CanVehicleStatus } from '@app/constants/enums/canVehicle/can-vehicle-status';
import { UserType } from '@app/constants/enums/userType';
import { hasAccessByRoles } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import { CanVehicleModel } from '@app/domain/canVehicle/canVehicle';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import ICanVehicleService, { CanVehicleService } from '@app/services/canVehicleService';
import { setHeader } from '@app/store/slices/headerSlice';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { exportDeviceConfigUpdateToExcel } from './functions/excel-functions';

const canVehicleService: ICanVehicleService = new CanVehicleService();

export const CanVehicleDashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [canVehicles, setCanVehicles] = useState<CanVehicleModel[]>([]);
  const [searchCanVehicles, setSearchCanVehicles] = useState<CanVehicleModel[]>([]);
  const [modalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);
  const [canVehicleDeleteId, setCanVehicleDeleteId] = useState<number | null>(null);

  const handleNewCanVehicleClick = () => navigate('/veiculos-can/criar');

  const handleViewClick = (canVehicle: CanVehicleModel) => {
    navigate(`/veiculos-can/visualizar/${canVehicle.id}`);
  };

  const handleActiveDeactiveCanVehicle = async (canVehicle: CanVehicleModel) => {
    try {
      setLoading(true);
      await canVehicleService.delete(`${canVehicle.id}/ativar-desativar`);
      notificationController.success({
        message: `Veículo CAN ${canVehicle.status ? 'desativado' : 'ativado'} com sucesso!`,
      });
      fetchCanVehicles();
    } catch (error) {
      setLoading(false);
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um erro ao ativar/desativar o veículo CAN',
      });
    }
  };

  const handleDeleteCanVehicle = async () => {
    try {
      setLoading(true);
      if (canVehicleDeleteId) {
        await canVehicleService.delete(`${canVehicleDeleteId}`);
        setModalDeleteVisible(false);
        notificationController.success({
          message: `Veículo CAN deletado com sucesso!`,
        });
        fetchCanVehicles();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um erro ao remover o veículo CAN',
      });
    }
  };

  const handleEditClick = (canVehicle: CanVehicleModel) => {
    navigate(`/veiculos-can/editar/${canVehicle.id}`);
  };

  const handleExportToExcel = () => {
    genericExportToExcel(
      'can_vehicle',
      columns,
      searchCanVehicles.map((c) => ({
        ...c,
        status: c.status ? 'Ativo' : 'Inativo',
      })) ?? [],
    );
  };

  const handleSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setSearchCanVehicles(
        canVehicles.filter(
          (item) =>
            item.id?.toString()?.toLowerCase()?.includes(inputValue) ||
            item.nome.toLowerCase().includes(inputValue) ||
            item.fabricante?.toLowerCase()?.includes(inputValue) ||
            item.ano?.toLowerCase()?.includes(inputValue) ||
            CanVehicleStatus[item.status]?.toLowerCase().includes(inputValue),
        ),
      );
    } else {
      setSearchCanVehicles(canVehicles);
    }
  };

  const columns: ColumnsType<CanVehicleModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: true,
      sorter: (a: CanVehicleModel, b: CanVehicleModel) => (a.id ?? 0) - (b.id ?? 0),
      defaultSortOrder: 'descend',
      width: '8%',
    },
    {
      title: 'Nome do veículo',
      dataIndex: 'nome',
      showSorterTooltip: true,
      sorter: (a: CanVehicleModel, b: CanVehicleModel) => (a.nome ?? '').localeCompare(b.nome ?? ''),
      width: '18%',
    },
    {
      title: 'Fabricante',
      dataIndex: 'fabricante',
      showSorterTooltip: true,
      width: '18%',
      sorter: (a: CanVehicleModel, b: CanVehicleModel) => (a.fabricante ?? '').localeCompare(b.fabricante ?? ''),
      render: (fabricante: string) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <img width={40} src={canVehicles.find((c) => c.fabricante === fabricante)?.fabricanteImagemUrl} />{' '}
            <span>{fabricante}</span>
          </div>
        );
      },
    },
    {
      title: 'Ano',
      dataIndex: 'ano',
      showSorterTooltip: true,
      sorter: (a: CanVehicleModel, b: CanVehicleModel) => (a.ano ?? '').localeCompare(b.ano ?? ''),
      width: '15%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      showSorterTooltip: true,
      width: '7%',
      sorter: (a: CanVehicleModel, b: CanVehicleModel) => (a.status ? 1 : 0) - (b.status ? 1 : 0),
      render: (status: boolean) => {
        const color = status === false ? 'var(--text-tag-warning-color)' : 'var(--text-tag-success-color)';
        const colorTag = status === false ? 'var(--bg-tag-warning-color)' : 'var(--bg-tag-success-color)';

        return (
          <Tag color={colorTag} style={{ color: color, textAlign: 'center' }}>
            {status ? 'Ativo' : 'Inativo'}
          </Tag>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      width: '1%',
      render: (_, canVehicle: CanVehicleModel) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {hasAccessByRoles([UserType.FactoryAdmin, UserType.Client]) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ color: '#545454' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <MenuItem onClick={() => handleEditClick(canVehicle)}>
                      <Button type="text">Editar</Button>
                    </MenuItem>
                    <MenuItem onClick={() => handleViewClick(canVehicle)}>
                      <Button type="text">Visualizar</Button>
                    </MenuItem>
                    <MenuItem onClick={() => handleActiveDeactiveCanVehicle(canVehicle)}>
                      <Button type="text">{canVehicle.status ? 'Desativar' : 'Ativar'}</Button>
                    </MenuItem>
                    {(canVehicle.parametroCalculado === undefined || canVehicle.parametroCalculado.length <= 0) &&
                      (canVehicle.parametroLigadoDesligado === undefined ||
                        canVehicle.parametroLigadoDesligado.length <= 0) && (
                        <MenuItem
                          onClick={() => {
                            setCanVehicleDeleteId(canVehicle.id ?? null);
                            setModalDeleteVisible(true);
                          }}
                        >
                          <Button type="text">Deletar</Button>
                        </MenuItem>
                      )}
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  const fetchCanVehicles = useCallback(async () => {
    try {
      setLoading(true);
      const response = await canVehicleService.getArray('');
      setCanVehicles(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um problema ao buscar a lista de veículos CAN',
      });
    }
  }, []);

  useEffect(() => {
    dispatch(
      setHeader({
        title: 'Veículos CAN',
      }),
    );
    fetchCanVehicles();
  }, [fetchCanVehicles, dispatch]);

  useEffect(() => {
    setSearchCanVehicles(canVehicles);
  }, [canVehicles]);

  return (
    <>
      <Modal
        title={`Deletar veículo CAN`}
        open={modalDeleteVisible}
        onOk={handleDeleteCanVehicle}
        onCancel={() => setModalDeleteVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <CloseCircleOutlined size={20} style={{ color: '#C41B24', marginRight: '1rem' }} />
            Deseja realmente <strong>&nbsp;remover o veículo CAN?</strong>
          </Col>
        </Row>
      </Modal>
      <PageTitle>Veículos CAN</PageTitle>
      <Spinner spinning={loading}>
        <Dashboard
          title=""
          buttonText={hasAccessByRoles([UserType.FactoryAdmin]) ? 'Cadastrar novo veículo' : ''}
          handleButtonClick={handleNewCanVehicleClick}
          placeholderTextSearch="Pesquisar"
          handleSearchOnChange={handleSearchFilter}
          table={<Table columns={columns} dataSource={searchCanVehicles} bordered />}
          exportToExcel={handleExportToExcel}
        />
      </Spinner>
    </>
  );
};
