import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import Dashboard from '@app/components/dashboard/Dashboard';
import { UserType } from '@app/constants/enums/userType';
import { hasAccessByRoles } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import { setHeader } from '@app/store/slices/headerSlice';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router';
import { Tag } from '@app/components/common/Tag/Tag';
import { FenceService } from '@app/services/fenceService';
import { FenceGroupModel } from '@app/domain/fence/fenceGroupModel';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { IconMore } from '@app/assets/slump-icons';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Button } from '@app/components/common/buttons/Button/Button.styles';

const fenceService = new FenceService();

export const FenceDashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMounted } = useMounted();
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState<FenceGroupModel[]>();
  const [searchDeviceFiltered, setSearchDeviceFiltered] = useState<FenceGroupModel[]>();

  const handleVisualizationRowClick = (id: number) => {
    navigate(`/cercas/${id}`);
  };

  const handleActivateDeactivateRowClick = (id: number) => {
    fenceService
      .delete(`/grupos/${id}/ativar-desativar`)
      .then(() => {
        notificationController.success({ message: 'Cerca ativada/desativada com sucesso' });
        fetchFencesGroup();
      })
      .catch((err) => {
        notificationController.error({ message: `Erro ao ativar/desativar cerca. ${err}` });
      });
  };

  const columns: ColumnsType<FenceGroupModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: true,
      sorter: (a: FenceGroupModel, b: FenceGroupModel) => (a.id ?? 0) - (b.id ?? 0),
      defaultSortOrder: 'descend',
      // width: '6%',
    },
    {
      title: 'Grupo',
      dataIndex: 'nome',
      showSorterTooltip: true,
      sorter: (a, b) => (a.nome ?? '').localeCompare(b.nome ?? ''),
      // width: '13%',
    },
    {
      title: 'Nº de cercas',
      dataIndex: 'qtdCercas',
      showSorterTooltip: true,
      sorter: (a: FenceGroupModel, b: FenceGroupModel) => (a.qtdCercas ?? 0) - (b.qtdCercas ?? 0),
      // width: '9%',
    },
    {
      title: 'Tipos de cercas',
      dataIndex: 'tiposCercas',
      showSorterTooltip: true,
      sorter: (a, b) => (a.tiposCercas ?? []).join(', ').localeCompare((b.tiposCercas ?? []).join(', ')),
      render: (tiposCercas: string[]) => tiposCercas.join(', '),
      // width: '15%',
    },
    {
      title: 'Status',
      dataIndex: 'ativo',
      showSorterTooltip: true,
      sorter: (a: FenceGroupModel, b: FenceGroupModel) => (a.ativo ? 1 : 0) - (b.ativo ? 1 : 0),
      // width: '4%',
      render: (ativo: boolean) => {
        return (
          <>
            {ativo ? (
              <Tag color="#E9F4EE" style={{ color: '#083F18', width: '70px', textAlign: 'center' }}>
                Ativo
              </Tag>
            ) : (
              <Tag color="#FEE9EA" style={{ color: '#620E12', width: '70px', textAlign: 'center' }}>
                Inativo
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      width: '3%',
      showSorterTooltip: false,
      render: (_, fenceGroup) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <DropdownTable
              buttonText=""
              iconD={
                <div style={{ color: '#545454' }}>
                  <IconMore />
                </div>
              }
              trigger={['click']}
              placement="bottomRight"
              overlay={
                <Menu>
                  {hasAccessByRoles([UserType.FactoryAdmin]) && (
                    <MenuItem
                      key="ativar-desativar"
                      onClick={() => handleActivateDeactivateRowClick(fenceGroup.id || 0)}
                    >
                      <Button type="text">{fenceGroup.ativo ? 'Desativar' : 'Ativar'}</Button>
                    </MenuItem>
                  )}
                  <MenuItem key="view" onClick={() => handleVisualizationRowClick(fenceGroup.id || 0)}>
                    <Button type="text">Visualizar</Button>
                  </MenuItem>
                </Menu>
              }
            ></DropdownTable>
          </div>
        );
      },
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate('/cercas/cadastrar');
  };

  const handleSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setSearchDeviceFiltered(
        devices?.filter(
          (item) =>
            item.id?.toString().toLowerCase().includes(inputValue) ||
            item.nome?.toLowerCase().includes(inputValue) ||
            item.qtdCercas?.toString().toLowerCase().includes(inputValue) ||
            item.tiposCercas?.toString().toLowerCase().includes(inputValue),
        ),
      );
    } else {
      setSearchDeviceFiltered(devices);
    }
  };

  const handleExportDataExcel = () => {
    const dataToExport = searchDeviceFiltered?.map((item) => {
      return {
        ...item,
        ativo: item.ativo ? 'Ativo' : 'Inativo',
      };
    });
    genericExportToExcel('cercas', columns, dataToExport ?? []);
  };

  const fetchFencesGroup = useCallback(async () => {
    setLoading(true);

    fenceService
      .getArray(`/grupos`)
      .then((res: FenceGroupModel[]) => {
        if (isMounted.current) {
          setDevices(res);
          setSearchDeviceFiltered(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({ message: `Erro ao obter cercas. ${err}` });
      });
  }, [isMounted]);

  useEffect(() => {
    fetchFencesGroup();
    dispatch(setHeader({ title: 'Cercas' }));
  }, [dispatch, fetchFencesGroup]);

  useEffect(() => {
    setSearchDeviceFiltered(devices);
  }, [devices]);

  return (
    <>
      <PageTitle>Cercas</PageTitle>
      <Spinner spinning={loading}>
        <Dashboard
          title=""
          buttonText={hasAccessByRoles([UserType.FactoryAdmin, UserType.Client]) ? 'Cadastrar novo grupo' : ''}
          handleButtonClick={handleClick}
          placeholderTextSearch="Pesquisar"
          handleSearchOnChange={handleSearchFilter}
          exportToExcel={handleExportDataExcel}
          table={<Table columns={columns} dataSource={searchDeviceFiltered} scroll={{ x: 600 }} bordered />}
        />
      </Spinner>
    </>
  );
};
