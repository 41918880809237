import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { LabelModelPrint } from '@app/domain/label/labelModelPrint';
import AnatelWhiteIcon from '@app/assets/icons/anatel-white.png';
import JmakWhiteIcon from '@app/assets/icons/jmak-white.png';

export const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '0.52mm',
    alignSelf: 'center',
    padding: '5mm',
  },
  container: {
    width: '43.85mm',
    height: '75.91mm',
    padding: '1.32mm 1.59mm 1.06mm 1.59mm',
    backgroundColor: 'black',
    color: 'white',
  },
  title: {
    fontFamily: 'GlacialIndifference',
    fontSize: '8.2mm',
    textAlign: 'center',
  },
  subTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '1.85mm',
    paddingLeft: '8.2mm',
    paddingTop: '-1.06mm',
  },
  deviceMapContainer: {
    width: '11.38mm',
    height: '5.03mm',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    padding: '0.26mm',
    gap: '0.26mm',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  deviceMapItem: {
    backgroundColor: 'black',
    width: '1.59mm',
    height: '2.12mm',
    fontSize: '1.06mm',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1.32mm',
    justifyContent: 'space-between',
    marginTop: '1.32mm',
  },
  circle: {
    width: '0.53mm',
    height: '0.53mm',
    borderRadius: '100%',
    marginRight: '0.26mm',
    backgroundColor: 'white',
    alignSelf: 'center',
  },
  infoContainerColorsColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.26mm',
  },
  infoContainerBatteryColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.26mm',
  },
  infoContainerBatteryColumnItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.26mm',
  },
  triangleContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '0.26mm',
    alignItems: 'center',
    width: '6.61mm',
    marginTop: '6.35mm',
    marginLeft: '1.85mm',
    padding: '0.26mm',
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderLeftWidth: '2.65mm',
    borderRightWidth: '2.65mm',
    borderBottomWidth: '3.17mm',
    borderLeftColor: 'white',
    borderRightColor: 'white',
    borderBottomColor: 'black',
    marginBottom: '-1.06mm',
  },
  rectangle: {
    paddingTop: '-1.06mm',
    width: '2.65mm',
    height: '4.23mm',
    backgroundColor: 'black',
    alignSelf: 'center',
  },
  triangleText: {
    marginLeft: '2.38mm',
  },
  qrcode: {
    marginTop: '1.85mm',
    alignSelf: 'center',
    width: '12.7mm',
  },
  serial: {
    fontSize: '1.59mm',
    marginTop: '2mm',
    alignSelf: 'center',
  },
  jmakWhiteIcon: {
    width: '14.55mm',
    marginTop: '2mm',
    alignSelf: 'center',
  },
  jmakLink: {
    fontSize: '1.59mm',
    alignSelf: 'center',
  },
  anatelImage: {
    marginTop: '3.17mm',
    width: '5.29mm',
    alignSelf: 'center',
    marginBottom: '0.53mm',
  },
  analteText: {
    fontSize: '0.79mm',
    alignSelf: 'center',
  },
});

// Modelo J-R12: LabelTemplate.J_R12
export const J_R12: React.FC<LabelModelPrint> = (label: LabelModelPrint) => {
  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>{label.modelo}</Text>
        <Text style={styles.subTitle}>Rastreador Veicular premium</Text>
      </View>
      <View style={{ ...styles.deviceMapContainer }}>
        <View style={styles.deviceMapItem}>
          <Text>12</Text>
        </View>
        <View style={styles.deviceMapItem}>
          <Text>11</Text>
        </View>
        <View style={styles.deviceMapItem}>
          <Text>10</Text>
        </View>
        <View style={styles.deviceMapItem}>
          <Text>9</Text>
        </View>
        <View style={styles.deviceMapItem}>
          <Text>8</Text>
        </View>
        <View style={styles.deviceMapItem}>
          <Text>7</Text>
        </View>
        <View style={styles.deviceMapItem}>
          <Text>6</Text>
        </View>
        <View style={styles.deviceMapItem}>
          <Text>5</Text>
        </View>
        <View style={styles.deviceMapItem}>
          <Text>4</Text>
        </View>
        <View style={styles.deviceMapItem}>
          <Text>3</Text>
        </View>
        <View style={styles.deviceMapItem}>
          <Text>2</Text>
        </View>
        <View style={styles.deviceMapItem}>
          <Text>1</Text>
        </View>
      </View>
      <View style={styles.infoContainer}>
        <View style={styles.infoContainerBatteryColumn}>
          <View style={styles.infoContainerBatteryColumnItem}>
            <View style={styles.circle} />
            <Text>Preto(1) GND</Text>
          </View>
          <View style={styles.infoContainerBatteryColumnItem}>
            <View style={styles.circle} />
            <Text>Laranja(2) CANL </Text>
          </View>
          <View style={styles.infoContainerBatteryColumnItem}>
            <View style={styles.circle} />
            <Text>Marrom(3) Relé</Text>
          </View>
          <View style={styles.infoContainerBatteryColumnItem}>
            <View style={styles.circle} />
            <Text>Azul claro(4) NC</Text>
          </View>
          <View style={styles.infoContainerBatteryColumnItem}>
            <View style={styles.circle} />
            <Text>Amarelo(5) IGN</Text>
          </View>
          <View style={styles.infoContainerBatteryColumnItem}>
            <View style={styles.circle} />
            <Text>Rosa(6) Entrada digital</Text>
          </View>
          <View style={styles.infoContainerBatteryColumnItem}>
            <View style={styles.circle} />
            <Text>Vermelho(7) VIN</Text>
          </View>
          <View style={styles.infoContainerBatteryColumnItem}>
            <View style={styles.circle} />
            <Text>Cinza(8) RS232 TX</Text>
          </View>
          <View style={styles.infoContainerBatteryColumnItem}>
            <View style={styles.circle} />
            <Text>Verde(9) Saída digital</Text>
          </View>
          <View style={styles.infoContainerBatteryColumnItem}>
            <View style={styles.circle} />
            <Text>Roxo(10) Entrada analógica</Text>
          </View>
          <View style={styles.infoContainerBatteryColumnItem}>
            <View style={styles.circle} />
            <Text>Branco(11) CAN H</Text>
          </View>
          <View style={styles.infoContainerBatteryColumnItem}>
            <View style={styles.circle} />
            <Text>Azul(12) RS232 RX</Text>
          </View>
        </View>
        <View style={styles.infoContainerBatteryColumn}>
          <View>
            <Text>Bateria interna: 400mAh</Text>
            <Text>Tensão: 9-30 VCC</Text>
          </View>
          <View>
            <View style={styles.triangleContainer}>
              <View style={styles.triangle}></View>
              <View style={styles.rectangle}></View>
            </View>
            <View style={styles.triangleText}>
              <Text>Este lado</Text>
              <Text>para cima</Text>
            </View>
          </View>
        </View>
      </View>

      <Image src={label.qrCodeURL} style={styles.qrcode} />
      <Text style={styles.serial}>{`SN: ${label.numeroSerie}`}</Text>
      <Image src={JmakWhiteIcon} style={styles.jmakWhiteIcon} />
      <Text style={styles.jmakLink}>www.jmak.com.br</Text>

      <Image src={AnatelWhiteIcon} style={styles.anatelImage} />
      <Text style={styles.analteText}>{label.certificadoAnatel}</Text>
      <Text style={styles.analteText}>Fabricado no Brasil</Text>
    </View>
  );
};
