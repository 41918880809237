import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import Dashboard from '@app/components/dashboard/Dashboard';
import { UserType } from '@app/constants/enums/userType';
import { hasAccessByRoles } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import { setHeader } from '@app/store/slices/headerSlice';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { IconMore } from '@app/assets/slump-icons';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { TagGroupModel } from '@app/domain/tagGroup/TagGroupModel';
import { TagsGroupService } from '@app/services/tagsGroupService';

const tagGroupService = new TagsGroupService();

export const TagGroupDashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMounted } = useMounted();
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState<TagGroupModel[]>();
  const [searchGroupFiltered, setSearchGroupFiltered] = useState<TagGroupModel[]>();

  const handleVisualizationRowClick = (id: number) => {
    navigate(`/grupo-tags/visualizar/${id}`);
  };

  const handleEditionRowClick = (id: number) => {
    navigate(`/grupo-tags/editar/${id}`);
  };

  const handleActivateDeactivateRowClick = (id: number) => {
    tagGroupService
      .update(`${id}`, tags as unknown as TagGroupModel)
      .then(() => {
        notificationController.success({
          message: `Grupo de tags ativada/desativada com sucesso`,
        });
        fetchTagsGroup();
      })
      .catch((err) => {
        notificationController.error({ message: `Erro ao ativar/desativar grupo de tags. ${err}` });
      });
  };

  const columns: ColumnsType<TagGroupModel> = [
    {
      title: 'Grupo',
      dataIndex: 'nome',
      showSorterTooltip: true,
      sorter: (a, b) => (a.nome ?? '').localeCompare(b.nome ?? ''),
    },
    {
      title: '',
      dataIndex: 'id',
      width: '3%',
      showSorterTooltip: false,
      render: (_, tagGroup) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <DropdownTable
              buttonText=""
              iconD={
                <div style={{ color: '#545454' }}>
                  <IconMore />
                </div>
              }
              trigger={['click']}
              placement="bottomRight"
              overlay={
                <Menu>
                  <MenuItem key="view" onClick={() => handleVisualizationRowClick(tagGroup.id || 0)}>
                    <Button type="text">Visualizar</Button>
                  </MenuItem>
                  <MenuItem key="view" onClick={() => handleEditionRowClick(tagGroup.id || 0)}>
                    <Button type="text">Editar</Button>
                  </MenuItem>
                  {hasAccessByRoles([UserType.FactoryAdmin]) && (
                    <MenuItem key="ativar-desativar" onClick={() => handleActivateDeactivateRowClick(tagGroup.id || 0)}>
                      <Button type="text">{tagGroup.ativo ? 'Desativar' : 'Ativar'}</Button>
                    </MenuItem>
                  )}
                </Menu>
              }
            ></DropdownTable>
          </div>
        );
      },
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate('/grupo-tags/cadastrar');
  };

  const handleSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setSearchGroupFiltered(tags?.filter((item) => item.nome?.toLowerCase().includes(inputValue)));
    } else {
      setSearchGroupFiltered(tags);
    }
  };

  const handleExportDataExcel = () => {
    const dataToExport = searchGroupFiltered?.map((item) => {
      return {
        ...item,
        ativo: item.ativo ? 'Ativo' : 'Inativo',
      };
    });
    genericExportToExcel('grupos_tags', columns, dataToExport ?? []);
  };

  const fetchTagsGroup = useCallback(async () => {
    setLoading(true);
    tagGroupService
      .getArray(``)
      .then((res: TagGroupModel[]) => {
        if (isMounted.current) {
          setTags(res);
          setSearchGroupFiltered(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({ message: `Erro ao obter tags. ${err}` });
      });
  }, [isMounted]);

  useEffect(() => {
    fetchTagsGroup();
    dispatch(setHeader({ title: 'Grupo de tags' }));
  }, [dispatch, fetchTagsGroup]);

  useEffect(() => {
    setSearchGroupFiltered(tags);
  }, [tags]);

  return (
    <>
      <PageTitle>Grupo de tags</PageTitle>
      <Spinner spinning={loading}>
        <Dashboard
          title=""
          buttonText={hasAccessByRoles([UserType.FactoryAdmin, UserType.Client]) ? 'Cadastrar novo grupo' : ''}
          handleButtonClick={handleClick}
          placeholderTextSearch="Pesquisar por grupo"
          handleSearchOnChange={handleSearchFilter}
          exportToExcel={handleExportDataExcel}
          table={<Table columns={columns} dataSource={searchGroupFiltered} bordered style={{ marginTop: '1rem' }} />}
        />
      </Spinner>
    </>
  );
};
