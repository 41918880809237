import { ApiServiceBase, IApiServiceBase } from '@app/services/base/apiServiceBase';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { FactoryDeviceErrorModel } from '@app/domain/device/factoryDeviceErrorModel';
import { CancelToken } from 'axios';
import { FastTestModel } from '@app/domain/device/fastTestModel';

export default interface IFactoryDeviceService extends IApiServiceBase<FactoryDeviceModel, FactoryDeviceModel> {
  inserirMuitos(devices: FactoryDeviceModel[]): Promise<FactoryDeviceErrorModel>;
  inserir(device: FactoryDeviceModel): Promise<FactoryDeviceErrorModel>;
  postFastTest(teste: FastTestModel): Promise<void>;
  postWithCancelToken(
    endpoint: string,
    entity: FactoryDeviceModel,
    cancelToken?: CancelToken,
  ): Promise<FactoryDeviceModel>;
}

export class FactoryDeviceService
  extends ApiServiceBase<FactoryDeviceModel, FactoryDeviceModel>
  implements IFactoryDeviceService
{
  constructor() {
    super('Dispositivo');
  }

  async postFastTest(teste: FastTestModel): Promise<void> {
    await this.api.post('teste-rapido', teste);
  }

  async inserirMuitos(devices: FactoryDeviceModel[]): Promise<FactoryDeviceErrorModel> {
    return (await this.api.post('inserir-muitos', devices))?.data;
  }

  async inserir(device: FactoryDeviceModel): Promise<FactoryDeviceErrorModel> {
    return (await this.api.post('inserir', device))?.data;
  }

  async postWithCancelToken(
    endpoint: string,
    entity: FactoryDeviceModel,
    cancelToken?: CancelToken,
  ): Promise<FactoryDeviceModel> {
    try {
      return (await this.api.post(endpoint, entity, { cancelToken }))?.data;
    } catch (error) {
      throw error;
    }
  }
}
