import styled from 'styled-components';

export const LoginWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const LoginSpan = styled.span`
  font-family: Mulish;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  text-align: left;
  color: #8c8c8c;
  margin-top: -2rem;

  && label {
    color: #263668;
    text-decoration: underline !important;
    font-size: 0.75rem;
    cursor: pointer;
  }
`;
