import styled from 'styled-components';

export const CardInfoContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 11.125rem;
  border: 0.063rem solid var(--disabled-bg-color);
  border-radius: 0.5rem;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 1.5rem;
  margin-bottom: 2rem;
`;

export const CardTitle = styled.div`
  color: var(--neutral-gray-gray-800, #383838);
  font-family: Mulish;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const CardInfoTitle = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 120%;
  color: var(--text-label);
  padding-bottom: 1rem;
`;

export const CardInfoProperty = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.563rem;
  color: var(--input-placeholder-color);
`;

export const CardInfoValue = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.563rem;
  color: var(--text-label);
  margin-top: -0.1;
  overflow-wrap: break-word;
`;

export const CardInfoTagBgBlue = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--brand-colors-blue-blue-500, #263668);
  background: rgba(202, 210, 236, 0.6);
  color: var(--brand-colors-blue-blue-700, #17203e);
  font-family: Mulish;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const CardInfoTag = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;
  background: #e6ecf1;
  border-radius: 2024px;
  margin-bottom: 2rem;

  span {
    font-weight: 800;
  }
`;
