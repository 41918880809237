/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from '@app/components/common/Modal/Modal';
import { Col, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { notificationController } from '@app/controllers/notificationController';
import { Description } from '@app/components/common/Description/Description';
import { setFooter } from '@app/store/slices/footerSlice';
import { CreateCanVehicleStepOne } from './components/steps/create-step-one';
import { CreateCanVehicleStepThree } from './components/steps/create-step-three';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { ContainerBorderRight } from '@app/components/common/Container/Container.styles';
import ICanVehicleService, { CanVehicleService } from '@app/services/canVehicleService';
import {
  CanVehicleModel,
  CanVehicleParametersCalculatedModel,
  CanVehicleParametersOnOffModel,
} from '@app/domain/canVehicle/canVehicle';
import { CanVehicleStatus } from '@app/constants/enums/canVehicle/can-vehicle-status';
import React from 'react';
import { ModalEditCanVehicle } from './components/edit/modal-edit';
import { CanVehicleParameterType } from '@app/constants/enums/canVehicle/can-vehicle-parameter-type';
import { CreateCanVehicleParametersTable } from './components/table/CreateCanVehicleParametersTable';
import { CreateCanVehicleStepTwo } from './components/steps/create-step-two';

enum StepCreateCanVehicle {
  CanVehicle = 0,
  CanVehicleObs = 1,
  CanVehicleParameters = 2,
}

const canVehicleService: ICanVehicleService = new CanVehicleService();

export const CanVehicleCreate: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<StepCreateCanVehicle>(StepCreateCanVehicle.CanVehicle);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [modalDeleteParameterVisible, setModalDeleteParameterVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [editParametersCalculated, setEditParametersCalculated] = useState<CanVehicleParametersCalculatedModel>(
    {} as CanVehicleParametersCalculatedModel,
  );
  const [editParametersOnOff, setEditParametersOnOff] = useState<CanVehicleParametersOnOffModel>(
    {} as CanVehicleParametersOnOffModel,
  );
  const [canVehicle, setCanVehicle] = useState<CanVehicleModel>({
    ano: '',
    nome: '',
    parametroCalculado: [],
    parametroLigadoDesligado: [],
    status: CanVehicleStatus.Ativo,
    parametroCalculadoSelecionado: [],
    parametroLigadoDesligadoSelecionado: [],
  });
  const [parameterType, setParameterType] = useState<CanVehicleParameterType>(CanVehicleParameterType.Calculado);
  const [files, setFiles] = useState<File[]>([]);
  const [saveOptional, setSaveOptional] = useState<boolean>(false);
  const [parameterToDelete, setParameterToDelete] = useState<number>(0);
  // const [editParameterIndex, setEditParameterIndex] = useState<number | null>(null);
  const { id } = useParams();

  const handleFetchCanVehicle = async () => {
    try {
      const response = await canVehicleService.get(`${id}`);
      setCanVehicle(response);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: `Houve um erro ao  cancelar ${!id ? 'o cadastro' : 'a edição'} do veículo CAN`,
      });
    }
  };

  const handleBackClick = async () => {
    navigate(`/veiculos-can`);
  };

  const handleChangeStep = (step: StepCreateCanVehicle) => setCurrentStep(step);

  const handleFinish = async () => {
    try {
      setLoading(true);
      if (!id) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await canVehicleService.api.post('etapa-tres', {
          id: canVehicle.id,
          parametrosCalculado: canVehicle.parametroCalculado,
          parametrosLigadoDesligado: canVehicle.parametroLigadoDesligado,
        });
        notificationController.success({ message: 'Cadastro de novo veículo CAN realizado com sucesso!' });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await canVehicleService.update(`${id}`, canVehicle);
        if (files.length > 0) {
          await handleUpdateCanVehicleObsAndFiles();
        }
        notificationController.success({ message: 'Edição do veículo CAN realizado com sucesso!' });
      }
      setLoading(false);
      handleBackClick();
    } catch (error) {
      setLoading(false);
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um erro ao salvar os parâmetros do veículo CAN',
      });
    }
  };

  const handleCreateCanVehicle = async () => {
    try {
      setLoading(true);

      const response = await canVehicleService.api.post('etapa-um', canVehicle);
      setCanVehicle({ ...canVehicle, id: response.data });

      dispatch(
        setFooter({
          confirmButtonDisabled: !(
            canVehicle.parametroCalculado.length > 0 || canVehicle.parametroLigadoDesligado.length > 0
          ),
          confirmButtonText: 'Finalizar',
          handleCancelButtonClick: () => setModalCancelVisible(true),
          cancelButtonText: 'Cancelar',
          handleConfirmButtonClick: () => handleFinish(),
        }),
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setCurrentStep(StepCreateCanVehicle.CanVehicle);
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um erro ao salvar os dados do veículo CAN',
      });
    }
  };

  const handleUpdateCanVehicleObsAndFiles = async () => {
    try {
      setLoading(true);

      const formData = new FormData();

      [...files].map((f) => {
        formData.append('files', f, f.name);
      });
      formData.append('observacoes', canVehicle.observacoes ?? '');
      formData.append('id', canVehicle.id?.toString() ?? '');

      const response = await canVehicleService.api.post(`etapa-dois`, formData, {
        headers: {
          'Content-Type': 'mulipart/form-data',
        },
      });
      if (!response) throw new Error('Ocorreu um erro ao tentar atualizar a observação e os arquivos do veículo CAN');

      setLoading(false);
      setSaveOptional(true);
    } catch (error) {
      setLoading(false);
      setCurrentStep(StepCreateCanVehicle.CanVehicleObs);
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um erro ao atualizar a observação e os arquivos do veículo CAN',
      });
    }
  };

  const handleDeleteClick = async () => {
    try {
      setLoading(true);
      if (!id && currentStep === StepCreateCanVehicle.CanVehicleParameters) {
        await canVehicleService.delete(`${canVehicle.id}`);
      }
      setLoading(false);
      setModalCancelVisible(false);
      handleBackClick();
    } catch (error) {
      setLoading(false);
      notificationController.error({
        message: 'Erro!',
        description: `Houve um erro ao  cancelar ${!id ? 'o cadastro' : 'a edição'} do veículo CAN`,
      });
    }
  };

  const handleDeleteParameter = async () => {
    try {
      if (id) {
        if (parameterType === CanVehicleParameterType.Calculado) {
          const response = await canVehicleService.api.delete(`parametro-calculado/${parameterToDelete}/${id}`);
          if (response.status !== 204) throw new Error();
        } else {
          const response = await canVehicleService.api.delete(`parametro-ligado-desligado/${parameterToDelete}/${id}`);
          if (response.status !== 204) throw new Error();
        }

        await handleFetchCanVehicle();
      } else {
        if (parameterType === CanVehicleParameterType.Calculado) {
          setCanVehicle({
            ...canVehicle,
            parametroCalculado: [...canVehicle.parametroCalculado.filter((c) => c.nomeId !== parameterToDelete)],
          });
        } else {
          setCanVehicle({
            ...canVehicle,
            parametroLigadoDesligado: [
              ...canVehicle.parametroLigadoDesligado.filter((c) => c.nomeId !== parameterToDelete),
            ],
          });
        }
      }
    } catch (error) {
      setLoading(false);
      notificationController.error({
        message: 'Erro!',
        description: `Houve um erro ao tentar excluir o parâmetro do veículo CAN`,
      });
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      handleFetchCanVehicle();
    }
    dispatch(
      setHeaderRegister({
        title: !id ? 'Novo veículo CAN' : 'Editar veículo CAN',
        handleBackClick: () => setModalCancelVisible(true),
      }),
    );
  }, []);

  useEffect(() => {
    switch (currentStep) {
      case StepCreateCanVehicle.CanVehicle:
        dispatch(
          setFooter({
            confirmButtonDisabled: id
              ? false
              : !(
                  canVehicle.nome.length > 0 &&
                  canVehicle.fabricanteId !== undefined &&
                  canVehicle.fabricanteId > 0 &&
                  canVehicle.ano.length > 0 &&
                  canVehicle.velocidadeCAN !== undefined &&
                  canVehicle.velocidadeCAN > 0 &&
                  canVehicle.quantidadeBits !== undefined &&
                  canVehicle.quantidadeBits > 0
                ),
            confirmButtonText: 'Próxima etapa',
            handleCancelButtonClick: () => setModalCancelVisible(true),
            handleConfirmButtonClick: () => handleChangeStep(StepCreateCanVehicle.CanVehicleObs),
            cancelButtonText: 'Cancelar',
          }),
        );

        break;

      case StepCreateCanVehicle.CanVehicleObs:
        if (!canVehicle.id) {
          handleCreateCanVehicle();
          return;
        }

        dispatch(
          setFooter({
            confirmButtonDisabled: false,
            confirmButtonText: 'Próxima etapa',
            handleCancelButtonClick: () => setModalCancelVisible(true),
            handleConfirmButtonClick: () => handleChangeStep(StepCreateCanVehicle.CanVehicleParameters),
            cancelButtonText: 'Cancelar',
          }),
        );

        break;

      case StepCreateCanVehicle.CanVehicleParameters:
        if (!id && !saveOptional && (canVehicle.observacoes || files.length > 0)) {
          handleUpdateCanVehicleObsAndFiles();
          return;
        }

        dispatch(
          setFooter({
            confirmButtonDisabled: !(
              canVehicle.parametroCalculado.length > 0 || canVehicle.parametroLigadoDesligado.length > 0
            ),
            confirmButtonText: 'Finalizar',
            handleCancelButtonClick: () => setModalCancelVisible(true),
            cancelButtonText: 'Cancelar',
            handleConfirmButtonClick: () => handleFinish(),
            tagAButtonText: id ? 'Voltar' : null,
            handleTagAButtonClick: canVehicle.id ? () => setCurrentStep(StepCreateCanVehicle.CanVehicle) : null,
          }),
        );

        break;
      default:
        break;
    }
  }, [dispatch, canVehicle, currentStep]);

  const handleRenderSteps = () => {
    switch (currentStep) {
      case StepCreateCanVehicle.CanVehicle:
        return <CreateCanVehicleStepOne canVehicle={canVehicle} setCanVehicle={setCanVehicle} />;
        break;
      case StepCreateCanVehicle.CanVehicleObs:
        return (
          <>
            <CreateCanVehicleStepTwo
              canVehicle={canVehicle}
              setCanVehicle={setCanVehicle}
              fileList={files}
              setFileList={setFiles}
            />
          </>
        );
        break;
      case StepCreateCanVehicle.CanVehicleParameters:
        return <CreateCanVehicleStepThree canVehicle={canVehicle} setCanVehicle={setCanVehicle} />;
        break;
    }
  };

  return (
    <>
      <Spinner spinning={loading}></Spinner>
      <ModalEditCanVehicle
        canVehicle={canVehicle}
        setCanVehicle={setCanVehicle}
        editParametersCalculated={editParametersCalculated ?? undefined}
        setEditParametersCalculated={setEditParametersCalculated ?? undefined}
        editParametersOnOff={editParametersOnOff ?? undefined}
        setEditParametersOnOff={setEditParametersOnOff ?? undefined}
        parameterType={parameterType}
        modalEditVisible={modalEditVisible}
        setModalEditVisible={setModalEditVisible}
        nameId={
          parameterType === CanVehicleParameterType.Calculado
            ? editParametersCalculated.nomeCAN ?? ''
            : editParametersOnOff.nomeCAN ?? ''
        }
        measureUnit={
          parameterType === CanVehicleParameterType.Calculado
            ? editParametersCalculated.unidadeMedida
            : editParametersOnOff.unidadeMedida
        }
      />
      <Modal
        title={`Cancelar ${!id ? 'cadastro' : 'edição'} veículo CAN`}
        open={modalCancelVisible}
        onOk={!id ? handleDeleteClick : handleBackClick}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>
            Deseja realmente <strong>&nbsp;cancelar {!id ? 'o cadastro' : 'a edição'} do veículo CAN?</strong>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Excluir parâmetro"
        open={modalDeleteParameterVisible}
        onOk={handleDeleteParameter}
        onCancel={() => setModalDeleteParameterVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>
            Deseja realmente <strong>&nbsp;excluir o parâmetro do veículo CAN?</strong>
          </Col>
        </Row>
      </Modal>

      <Row style={{ height: '100%' }}>
        <Col xs={24} md={12} xl={8}>
          <ContainerBorderRight>{handleRenderSteps()}</ContainerBorderRight>
        </Col>
        <Col xs={24} md={12} xl={16}>
          {/* <Container> */}
          <div style={{ marginLeft: '3rem' }}>
            <Description
              title="Parâmetros do veículo"
              subtitle="Após preencher todas as informações, verifique se os itens selecionados estão corretos ."
            >
              <CreateCanVehicleParametersTable
                canVehicle={canVehicle}
                setEditParametersCalculated={setEditParametersCalculated}
                setEditParametersOnOff={setEditParametersOnOff}
                setModalEditVisible={setModalEditVisible}
                setParameterType={setParameterType}
                setModalDeleteParameterVisible={setModalDeleteParameterVisible}
                setParameterToDelete={setParameterToDelete}
              />
            </Description>
          </div>
          {/* </Container> */}
        </Col>
      </Row>
    </>
  );
};
