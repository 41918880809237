import { FC, useEffect, useState } from 'react';
import * as S from './new-password.styles';
import logo from '@app/assets/images/j-logo.png';
import { Row } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import IAuthService, { AuthService } from '@app/services/authService';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Button } from '@app/components/common/buttons/Button/Button';
import { AuthComponentEnum } from '@app/constants/enums/auth-components';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { InputPassword } from '@app/components/common/inputs/InputPassword/InputPassword';
import { useParams } from 'react-router-dom';

interface NewPasswordFormData {
  password: string;
  confirmPassword: string;
}

const initStates = {
  password: 'new-password',
  confirmPassword: 'new-password',
};

const authService: IAuthService = new AuthService();

interface NewPasswordProps {
  handleShowComponent: (index: AuthComponentEnum) => void;
}

export const NewPassword: FC<NewPasswordProps> = ({ handleShowComponent }) => {
  const [isLoading, setLoading] = useState(false);
  const [passErrorText, setPassErrorText] = useState('');
  const [validPass, setValidPass] = useState<boolean | undefined>();
  const [validConfirmPass, setValidConfirmPass] = useState<boolean | undefined>();
  const [newPassModel, setNewPassModel] = useState<NewPasswordFormData>({} as NewPasswordFormData);
  const { token } = useParams();

  const handleSubmit = () => {
    setLoading(true);
    if (!token) {
      notificationController.error({ message: 'Não é possível completar a solicitação' });
      setLoading(false);
      return;
    }

    authService
      .setNewPassword({
        token: token ?? '',
        password: newPassModel.password,
        confirmPassword: newPassModel.confirmPassword,
      })
      .then(() => {
        handleShowComponent(AuthComponentEnum.Login);
        notificationController.success({
          message: 'Nova senha criada',
        });
      })
      .catch((error) => {
        notificationController.error(error);
        setLoading(false);
      });
  };

  const validatePassword = (pass: string): boolean => {
    if (pass?.length < 6) {
      setValidPass(false);
      setPassErrorText('Senha pequena');
      return false;
    }

    if (!/(?=.*[A-Z]).*$/.test(pass)) {
      setValidPass(false);
      setPassErrorText('Senha deve conter letras maiúsculas');
      return false;
    }

    if (!/(?=.*[a-z]).*$/.test(pass)) {
      setValidPass(false);
      setPassErrorText('Senha deve conter letras minúsculas');
      return false;
    }

    if (!/(?=.*[0-9]).*$/.test(pass)) {
      setValidPass(false);
      setPassErrorText('Senha deve conter números');
      return false;
    }

    if (!/\W/.test(pass)) {
      setValidPass(false);
      setPassErrorText('Senha deve conter caracteres especiais');
      return false;
    }

    setValidPass(undefined);
    return true;
  };

  useEffect(() => {
    if (newPassModel.password) {
      validatePassword(newPassModel.password);
    }
  }, [newPassModel.password]);

  useEffect(() => {
    if (newPassModel.password != newPassModel.confirmPassword) {
      setValidConfirmPass(false);
    } else {
      setValidConfirmPass(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassModel.confirmPassword]);

  return (
    <S.NewPasswordWrapper>
      <Row justify={'center'} style={{ marginBottom: '2.875rem' }}>
        <img src={logo} width={31} height={72} />
      </Row>
      <Row>
        <BaseForm layout="vertical" style={{ width: '100%' }} onFinish={handleSubmit} initialValues={initStates}>
          <BaseForm.Item>
            <S.NewPasswordFormTitle>Crie sua nova senha</S.NewPasswordFormTitle>
          </BaseForm.Item>
          <BaseFormInputItem
            label="Nova senha"
            name="password"
            supportText="Ex: mínimo de 6 caracteres com letras e números."
            isSuccess={validPass}
            errorText={passErrorText}
            style={{ margin: '0 0 2rem; 0.5rem !important' }}
          >
            <InputPassword
              placeholder="Digite sua senha"
              type="password"
              onChange={(e) => setNewPassModel({ ...newPassModel, password: e.currentTarget.value })}
            />
          </BaseFormInputItem>
          <BaseFormInputItem
            label="Confirme sua nova senha"
            name="confirmPassword"
            isSuccess={validConfirmPass}
            supportText="Ex: mínimo de 6 caracteres com letras e números."
            errorText="Senhas diferentes"
          >
            <InputPassword
              placeholder="Digite sua senha"
              type="password"
              onChange={(e) => setNewPassModel({ ...newPassModel, confirmPassword: e.currentTarget.value })}
            />
          </BaseFormInputItem>
          <BaseForm.Item style={{ marginTop: '3.125rem' }}>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Criar senha
            </Button>
          </BaseForm.Item>
        </BaseForm>
      </Row>
    </S.NewPasswordWrapper>
  );
};
