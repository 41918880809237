import React, { FC, useState } from 'react';
import { Modal } from '@app/components/common/Modal/Modal';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { notificationController } from '@app/controllers/notificationController';
import { DownloadOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { Select } from '@app/components/common/selects/Select/Select';
import { TagGroupModel } from '@app/domain/tagGroup/TagGroupModel';
import { ReactComponent as ExportCsvIcon } from '@app/assets/icons/export-csv.svg';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Col, Row } from 'antd';
import { Input } from '@app/components/common/inputs/Input/Input';
import * as S from '@app/components/header/components/HeaderTable/HeaderTable.styles';
import { TagsGroupService } from '@app/services/tagsGroupService';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { TableData } from '@app/pages/tags-group/components/TableData/TableData';
import { genericExportToExcel } from '@app/utils/exportToExcel';

interface IModalGroupTag {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  device: FactoryDeviceModel;
  setDevice: React.Dispatch<React.SetStateAction<FactoryDeviceModel>>;
  tagGroupsOptions: TagGroupModel[];
}
const tagsGroupService = new TagsGroupService();

export const ModalGroupTag: FC<IModalGroupTag> = ({ visible, setVisible, device, setDevice, tagGroupsOptions }) => {
  const [selectedTagGroup, setSelectedTagGroup] = useState<number | undefined>(device?.idTagGrupo);
  const [modalViewTagsVisible, setModalViewTagsVisible] = useState(false);
  const [groupTags, setGroupTags] = useState<TagGroupModel>();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const onConfirm = async () => {
    try {
      const tagGroup = await tagsGroupService.get(`${selectedTagGroup ?? device?.idTagGrupo}`);
      setGroupTags(tagGroup);

      setDevice((prevState) => {
        notificationController.success({ message: 'Tag adicionada na listagem com sucesso!' });
        // remove duplicates
        const uniqueTags = Array.from(new Set(tagGroup.tags));

        return {
          ...prevState,
          idTagGrupo: selectedTagGroup ?? device?.idTagGrupo,
          tagsToSendToDevice: uniqueTags,
        };
      });
      setVisible(false);
    } catch (error) {
      console.error(error);
      notificationController.error({ message: 'Erro ao adicionar tags do grupo na listagem' });
    }
  };

  const openViewGroupTagsModal = () => {
    fetchTagsFromGroup((selectedTagGroup ?? device?.idTagGrupo) as number);
    setModalViewTagsVisible(true);
  };

  const handleExportDataExcel = async () => {
    const columns = [
      { title: 'Nome', dataIndex: 'nome' },
      { title: 'Tag ID', dataIndex: 'tagId' },
    ];

    const data = groupTags?.tags
      ? groupTags.tags.map((tag) => ({
          nome: groupTags.nome,
          tagId: tag,
        }))
      : [];

    await genericExportToExcel('grupo_tags', columns, data);
  };

  const fetchTagsFromGroup = async (id: number) => {
    try {
      setLoading(true);
      const tagGroup = await tagsGroupService.get(`${id}`);
      setGroupTags(tagGroup);
    } catch (error) {
      notificationController.error({ message: 'Erro ao buscar tags do grupo' });
    } finally {
      setLoading(false);
    }
  };

  const handleSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    const inputValue = event.currentTarget.value.toLowerCase();
    setSearchValue(inputValue);
  };

  return (
    <Spinner spinning={loading}>
      <Modal
        open={visible}
        title="Vincular grupo de tags"
        onOk={onConfirm}
        onCancel={() => {
          setVisible(false);
          setSelectedTagGroup(undefined);
        }}
        cancelText="Cancelar"
        okText="Vincular"
        size="medium"
        okButtonProps={{ disabled: !(selectedTagGroup ?? device?.idTagGrupo) }}
      >
        <Row justify={'space-between'}>
          <BaseForm layout="vertical" style={{ width: '60%' }}>
            <BaseFormInputItem
              label="Grupo de tags"
              supportText="Escolha um grupo por vez para vincular ao dispositivo"
            >
              <Select
                showArrow
                showSearch
                placeholder="Selecione o grupo"
                value={selectedTagGroup ?? device?.idTagGrupo}
                onChange={(value) => {
                  setSelectedTagGroup(value as number);
                }}
                options={tagGroupsOptions.map((g) => ({
                  value: g.id,
                  label: `${g.nome}`,
                }))}
                filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              />
            </BaseFormInputItem>
          </BaseForm>
          <Button style={{ marginTop: '1.4rem', height: '53px', width: '35%' }} onClick={openViewGroupTagsModal}>
            <EyeOutlined />
            Visualizar tags
          </Button>
        </Row>
      </Modal>

      <Modal
        open={modalViewTagsVisible}
        title="Visualizar tags do grupo"
        onOk={() => setModalViewTagsVisible(false)}
        onCancel={() => setModalViewTagsVisible(false)}
        footer={null}
        // size="large"
        style={{ minWidth: '80%' }}
        okButtonProps={{ icon: <DownloadOutlined /> }}
      >
        <Row justify={'space-between'}>
          <BaseForm layout="vertical" style={{ width: '360px' }}>
            <BaseFormInputItem label="Pesquisa">
              <Input
                suffix={<SearchOutlined style={{ marginRight: '1rem', color: '#545454' }} />}
                placeholder="Pesquise por tag ID"
                onChange={handleSearchFilter}
              />
            </BaseFormInputItem>
          </BaseForm>
          <Button type="link" style={{ marginTop: '1.3rem', height: '53px' }} onClick={handleExportDataExcel}>
            <S.ExportButtonContainer>
              {<ExportCsvIcon />}
              Exportar dados excel
            </S.ExportButtonContainer>
          </Button>
        </Row>
        <TableData
          key="1"
          header={
            <Row align={'middle'} justify={'space-between'}>
              <Col>{groupTags?.nome}</Col>
            </Row>
          }
          data={
            searchValue ? groupTags?.tags?.filter((tag) => tag.toLowerCase().includes(searchValue)) : groupTags?.tags
          }
        />
      </Modal>
    </Spinner>
  );
};
