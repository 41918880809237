import { TagProps } from 'antd';
import React from 'react';
import * as S from './Tag.styles';
export interface ITag {
  id: string;
  title: string;
  bgColor?: string;
}

export const Tag: React.FC<TagProps> = (props) => {
  return <S.TagWrapper {...props} />;
};
