import { InfoCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Modal } from '@app/components/common/Modal/Modal';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Col, Form, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { FenceService } from '@app/services/fenceService';
import { FenceGroupModel } from '@app/domain/fence/fenceGroupModel';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Description } from '@app/components/common/Description/Description';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { FenceModel } from '@app/domain/fence/fenceModel';
import { InputObs } from '@app/components/common/ModulesProfileConfig/ModulesProfileConfig.styles';
import * as S from './create.styles';
import { Button } from '@app/components/common/buttons/Button/Button';
import MapComponentCreate from '@app/pages/fence/create/components/MapComponentCreate/MapComponentCreate';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import ModalEventConfigurationComponent, {
  validateEventFields,
} from './components/ModalEventConfigurationComponent/ModalEventConfigurationComponent';
import FencesDashboard from '@app/pages/fence/create/components/FencesDashboard/FencesDashboard';
import { setFooter } from '@app/store/slices/footerSlice';
import { isFenceOverridden } from '@app/pages/fence/create/geometricFencesFunctions';
import { getFenceNameByType, mountFenceProperyValue } from '@app/pages/fence/commonFenceFunctions';
import IModelDeviceService, { ModelDeviceService } from '@app/services/modelDeviceService';
import { ModelDeviceModel } from '@app/domain/modelDevice/modelDeviceModel';
import { Select } from '@app/components/common/selects/Select/Select';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';

const modelService: IModelDeviceService = new ModelDeviceService();

const fenceService = new FenceService();

const defaultFence = {
  pontos: [],
  nome: '',
  raio: undefined,
  largura: undefined,
  eventos: {
    entradaSaida: undefined,
    entradaSaidaPeriferico: 0,
    entradaSaidaCiclos: 0,
    entradaSaidaTempoLigado: 0,
    entradaSaidaTempoDesligado: 0,
    limiteVelocidade: 0,
    limiteVelocidadeValor: 0,
    limiteVelocidadePulsado: 0,
    limiteVelocidadeCiclos: 0,
    limiteVelocidadeTempoLigado: 0,
    limiteVelocidadeTempoDesligado: 0,
  },
};

interface FenceCreateProps {
  isCloning?: boolean;
}

export const FenceCreate: React.FC<FenceCreateProps> = ({ isCloning = false }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const [modalConfirmSave, setModalConfirmSave] = useState(false);
  const [modalEvent, setModalEvent] = useState(false);
  const [fenceGroup, setFenceGroup] = useState<FenceGroupModel>();
  const [fence, setFence] = useState<FenceModel>({
    ...defaultFence,
    idTipoCerca: 1,
  });
  const [fences, setFences] = useState<FenceModel[]>([]);
  const [showInactiveFences, setShowInactiveFences] = useState(false);
  const getUser = JSON.parse(localStorage.getItem('user') ?? '{}');
  const [models, setModels] = useState<ModelDeviceModel[]>([]);

  const fetchGroupData = useCallback(() => {
    setLoading(true);
    fenceService
      .get(`/grupos/${id}`)
      .then((response) => {
        const parsedFences = response.cercas?.map((cerca) => ({
          ...cerca,
          eventos: cerca.eventos,
        }));
        setFenceGroup({
          ...response,
          cercas: parsedFences || [],
          nome: isCloning ? `${response.nome} clone` : response.nome,
        });
        setFences(parsedFences || []);
      })
      .catch((err) => notificationController.error({ message: `Erro ao buscar cerca. ${err}` }))
      .finally(() => setLoading(false));
  }, [id, isCloning]);

  const fetchModels = useCallback(() => {
    setLoading(true);
    modelService
      .getArray('')
      .then((response) => setModels(response.filter((m) => m.permiteCerca)))
      .catch((err) => notificationController.error({ message: `Erro ao buscar cerca. ${err}` }))
      .finally(() => setLoading(false));
  }, []);

  const canSave = useMemo(() => {
    if (!fenceGroup?.modelo?.id) return false;
    if (!fenceGroup?.nome) return false;
    if (!fences?.length) return false;

    if (isCloning) return fences.filter((f) => !f.id).length > 0;

    return true;
  }, [fenceGroup?.modelo?.id, fenceGroup?.nome, fences, isCloning]);

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: isCloning ? 'Clonar grupo' : 'Novo grupo',
        handleBackClick: () => setModalCancel(true),
      }),
    );

    if (Number(id)) fetchGroupData();
    else fetchModels();
  }, [dispatch, fetchGroupData, fetchModels, id, isCloning]);

  useEffect(() => {
    dispatch(
      setFooter({
        confirmButtonDisabled: !canSave,
        confirmButtonText: 'Salvar',
        handleCancelButtonClick: () => setModalCancel(true),
        handleConfirmButtonClick: () => setModalConfirmSave(true),
        cancelButtonText: 'Cancelar',
      }),
    );
  }, [canSave, dispatch]);

  const saveGroup = useCallback(() => {
    setLoading(true);

    const endpoint = isCloning ? '/clonar' : '/criar-completo';
    const payload = {
      nome: fenceGroup?.nome,
      cercas: fences,
      idCliente: getUser.idCliente ?? 1,
      idModelo: fenceGroup?.modelo?.id,
    };

    fenceService
      .post(endpoint, payload)
      .then(() => {
        notificationController.success({ message: 'Grupo cadastrado com sucesso!' });
        navigate('/cercas');
      })
      .catch((err) => {
        notificationController.error({ message: `Erro ao cadastrar grupo.`, description: err.message });
      })
      .finally(() => {
        setLoading(false);
        setModalConfirmSave(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fenceGroup?.modelo?.id, fenceGroup?.nome, fences, navigate]);

  const handleEventConfigButtonClick = () => {
    setModalEvent(true);
  };

  const validatePolygonFence = () => {
    if (!fence.pontos?.length) return notificationController.error({ message: 'Informe os pontos da cerca' });
    if (fence?.pontos?.length < 3) return notificationController.error({ message: 'Informe pelo menos 3 pontos' });
    return true;
  };

  const validatePointFence = () => {
    if (!fence.pontos?.length)
      return notificationController.error({
        message: 'Para cadastrar uma cerca do tipo Raio informe a coordenada no mapa e a distância do raio em metros.',
      });
    if (fence?.pontos?.length > 1) return notificationController.error({ message: 'Informe apenas 1 ponto' });
    if (!fence.raio) return notificationController.error({ message: 'Informe o raio' });
    return true;
  };

  const validateRouteFence = () => {
    if (!fence.pontos?.length)
      return notificationController.error({
        message: 'Para cadastrar uma cerca do tipo Rota informe os pontos no mapa.',
      });
    if (fence?.pontos?.length < 2) return notificationController.error({ message: 'Informe pelo menos 2 pontos' });
    if (!fence.largura) return notificationController.error({ message: 'Informe a largura' });

    return true;
  };

  const validateFenceByType = () => {
    switch (fence.idTipoCerca) {
      case 1:
        return !!validatePolygonFence();
      case 2:
        return !!validatePointFence();
      case 3:
        return !!validateRouteFence();
      default:
        return !!validatePolygonFence();
    }
  };

  const validateFence = () => {
    if (!fence.idTipoCerca) return notificationController.error({ message: 'Informe o tipo da cerca' });
    if (fence.pontos.length > (fenceGroup?.modelo?.quantidadeMaxPontosPorCerca || 0))
      return notificationController.error({ message: 'Limite máximo de pontos excedido' });
    if (fences.length > (fenceGroup?.modelo?.quantidadeMaxCercas || 0))
      return notificationController.error({ message: 'Limite máximo de cercas excedido' });
    if (!fence.nome) return notificationController.error({ message: 'Informe o nome da cerca' });
    if (fences.find((f) => f.nome === fence.nome))
      return notificationController.error({ message: 'Nome de cerca já cadastrado' });

    return true;
  };

  const changeFenceEventsPulsedToMilliseconds = () => {
    if (fence.eventos !== undefined && fence.eventos?.entradaSaidaTempoLigado !== undefined) {
      fence.eventos.entradaSaidaTempoLigado = fence.eventos.entradaSaidaTempoLigado * 1000;
    }

    if (fence.eventos !== undefined && fence.eventos?.entradaSaidaTempoDesligado !== undefined) {
      fence.eventos.entradaSaidaTempoDesligado = fence.eventos.entradaSaidaTempoDesligado * 1000;
    }

    if (fence.eventos !== undefined && fence.eventos?.limiteVelocidadeTempoLigado !== undefined) {
      fence.eventos.limiteVelocidadeTempoLigado = fence.eventos.limiteVelocidadeTempoLigado * 1000;
    }

    if (fence.eventos !== undefined && fence.eventos?.limiteVelocidadeTempoDesligado !== undefined) {
      fence.eventos.limiteVelocidadeTempoDesligado = fence.eventos.limiteVelocidadeTempoDesligado * 1000;
    }
  };

  const addFenceToList = () => {
    if (!validateEventFields(fence)) return setModalEvent(true);
    if (!Boolean(validateFence()) || !validateFenceByType()) return;

    changeFenceEventsPulsedToMilliseconds();

    const activeFences = fences.filter((f) => f.ativo);

    if (isFenceOverridden(fence, activeFences))
      notificationController.warning({
        message:
          'Os eventos podem se comportar de forma inesperada porque existem interseções entre as cercas informadas.',
      });

    const fenceToSave = {
      ...fence,
      tipoCerca: getFenceNameByType(fence.idTipoCerca!),
      idTipoCerca: fence.idTipoCerca,
      ativo: true,
      propriedade: {
        idEmbarcado: 31,
        tipoComando: 's',
        valorPropriedade: mountFenceProperyValue(fence),
      },
    };

    setFences((prevState) => [...prevState, fenceToSave]);
    resetFence();

    notificationController.success({ message: 'Cadastro de cerca realizado com sucesso!' });
  };

  const resetFence = () => {
    setFence((prevState) => ({
      ...prevState,
      ...defaultFence,
    }));
  };

  const renderHelperNotification = useMemo(() => {
    if (isCloning)
      return (
        <HelperNotification style={{ maxWidth: '62rem' }}>
          Após alterar o nome do grupo adicione pelo menos 1 cerca e <strong>clique em cadastrar grupo.</strong>
        </HelperNotification>
      );

    if (!fenceGroup?.modelo?.id || fences.length === 0)
      return (
        <HelperNotification style={{ maxWidth: '62rem' }}>
          O botão para configurar eventos estará disponível assim que informar o nome do grupo e cerca. Finalizando o
          cadastro das cercas, <strong>clique em cadastrar grupo.</strong>
        </HelperNotification>
      );

    if (fences.length === 0)
      return (
        <HelperNotification style={{ maxWidth: '62rem' }}>
          Após cadastrar o nome do grupo e cercas, <strong>clique em cadastrar grupo.</strong>
        </HelperNotification>
      );
  }, [fenceGroup?.modelo?.id, fences.length, isCloning]);

  return (
    <>
      <Modal
        title="Cancelar cadastro de grupo"
        open={modalCancel}
        onOk={() => navigate('/cercas')}
        onCancel={() => setModalCancel(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            <span>
              Deseja realmente <strong> cancelar o cadastro do grupo? </strong>
            </span>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Confirmar cadastro"
        open={modalConfirmSave}
        onOk={() => saveGroup()}
        onCancel={() => setModalConfirmSave(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            <div>
              <div>
                <strong> Deseja realmente salvar o cadastro do grupo?</strong>
              </div>
              <div>Após a confirmação dos dados as cercas não poderão ser deletadas.</div>
            </div>
          </Col>
        </Row>
      </Modal>

      <PageTitle>{isCloning ? `Clonar grupo` : 'Novo grupo'}</PageTitle>
      <ModalEventConfigurationComponent
        fence={fence}
        setFence={setFence}
        visible={modalEvent}
        setVisible={setModalEvent}
      />
      <PageTitle>Novo grupo</PageTitle>
      <Spinner spinning={loading}>
        <PageContainer>
          {renderHelperNotification}
          <Description title="Mapeamento das cercas" />
          <Form layout="vertical">
            <Row gutter={18} style={{ gap: '.5rem', margin: 0 }}>
              <BaseFormInputItem label="Modelo" style={{ width: '310px' }}>
                <Select
                  showArrow
                  showSearch
                  placeholder="Selecione o modelo"
                  value={fenceGroup?.modelo?.id}
                  disabled={fences.length > 0 || isCloning}
                  onChange={(value: unknown) => {
                    setFenceGroup((prevState) => ({
                      ...prevState,
                      modelo: models.find((m) => m.id == value),
                    }));
                  }}
                  options={
                    !isCloning
                      ? models.map((m) => ({
                          label: m.nome,
                          value: m.id,
                        }))
                      : [
                          {
                            label: fenceGroup?.modelo?.nome,
                            value: fenceGroup?.modelo?.id,
                          },
                        ]
                  }
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
              <BaseFormInputItem label="Grupo" style={{ width: '310px' }}>
                <Input
                  placeholder="Digite o nome do grupo"
                  maxLength={50}
                  value={fenceGroup?.nome}
                  onChange={(event) => setFenceGroup((prevState) => ({ ...prevState, nome: event.target.value }))}
                  onBlur={() => setFenceGroup((prevState) => ({ ...prevState, nome: prevState?.nome?.trim() }))}
                />
              </BaseFormInputItem>
              <BaseFormInputItem label="Cerca" style={{ width: '310px' }}>
                <>
                  <Input
                    placeholder="Digite o nome da cerca"
                    maxLength={15}
                    value={fence.nome}
                    onChange={(event) => setFence((prevState) => ({ ...prevState, nome: event.target.value }))}
                    onBlur={() => setFence((prevState) => ({ ...prevState, nome: prevState.nome?.trim() }))}
                  />
                  <InputObs>Cadastre uma cerca por vez</InputObs>
                </>
              </BaseFormInputItem>
              <BaseFormInputItem label="Tipo de cerca">
                <S.FenceTypeWrapper>
                  <S.FenceTypeButton
                    active={fence.idTipoCerca === 1}
                    onClick={() => setFence((prevState) => ({ ...prevState, idTipoCerca: 1 }))}
                  >
                    {getFenceNameByType(1)}
                  </S.FenceTypeButton>
                  <S.FenceTypeButton
                    active={fence.idTipoCerca === 2}
                    onClick={() => setFence((prevState) => ({ ...prevState, idTipoCerca: 2 }))}
                  >
                    {getFenceNameByType(2)}
                  </S.FenceTypeButton>
                  <S.FenceTypeButton
                    active={fence.idTipoCerca === 3}
                    onClick={() => setFence((prevState) => ({ ...prevState, idTipoCerca: 3 }))}
                  >
                    {getFenceNameByType(3)}
                  </S.FenceTypeButton>
                </S.FenceTypeWrapper>
              </BaseFormInputItem>
              <S.ConfigButtonWraper>
                <Button
                  type="ghost"
                  icon={<SettingOutlined style={{ padding: '4px 0 0 7px' }} />}
                  onClick={handleEventConfigButtonClick}
                >
                  Configurar eventos
                </Button>
              </S.ConfigButtonWraper>
            </Row>
          </Form>

          <S.FenceMapWrapper>
            <Checkbox
              key={26}
              checked={showInactiveFences}
              value={showInactiveFences}
              onChange={(event) => setShowInactiveFences(event.target.checked)}
              style={{ width: '13rem' }}
            >
              Mostrar cercas inativas
            </Checkbox>
            <MapComponentCreate
              fence={fence}
              setFence={setFence}
              showInactiveFences={showInactiveFences}
              fences={fences}
              maxFencesQuantity={fenceGroup?.modelo?.quantidadeMaxCercas || 0}
              maxPointsPerFence={fenceGroup?.modelo?.quantidadeMaxPontosPorCerca || 0}
            />
            <S.AddFenceButtonWrapper>
              <Button type="primary" style={{ width: '12.5rem' }} onClick={() => addFenceToList()}>
                Adicionar cerca
              </Button>
            </S.AddFenceButtonWrapper>
          </S.FenceMapWrapper>

          <S.Divider />

          <FencesDashboard
            fences={fences}
            setFences={setFences}
            maxFencesQuantity={fenceGroup?.modelo?.quantidadeMaxCercas || 0}
            isCloning={isCloning}
          />

          <S.Divider />
        </PageContainer>
        <div style={{ height: '100px', width: '100%' }} />
      </Spinner>
    </>
  );
};
