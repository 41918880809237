import styled from 'styled-components';
import { Collapse as AntdCollapse } from 'antd';
import { media } from '@app/styles/themes/constants';

export const CollapseContainer = styled.div`
  margin-left: -16px;

  && .ant-collapse-item {
    border: none;
  }

  box-sizing: border-box;
  width: 100%;
  border: 0.063rem solid var(--disabled-bg-color);
  border-radius: 0.5rem;
  flex: none;
  order: 0;
  flex-grow: 1;
  padding: 1.5rem;
  margin-bottom: 2rem;
`;

export const Collapse = styled(AntdCollapse)`
  background-color: white;
  width: 100%;
`;

export const DisplayContainer = styled.div`
  display: grid;
  grid-template-columns: 14.375rem 1fr;
  gap: 2rem;
  align-items: center;
  margin-top: 1.5rem;

  @media only screen and (${media.md}) {
    grid-template-columns: 12rem 1fr;
  }
`;

export const ImageContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 0.063rem solid var(--disabled-bg-color);
  border-radius: 0.5rem;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeviceInfoContainer = styled.div`
  margin-bottom: 2.5rem;
`;

export const DeviceCardInfoProperty = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1.563rem;
  color: var(--input-placeholder-color);
`;

export const DeviceCardInfoValue = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.563rem;
  color: var(--text-label);
  margin-top: -0.1;
  overflow-wrap: break-word;
`;

export const DeviceTypeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

interface DeviceTypeButtonProps {
  active?: boolean;
}

export const DeviceTypeButton = styled.div<DeviceTypeButtonProps>`
  display: flex;
  height: 3rem;
  padding: 1rem 1rem;
  align-items: center;
  border-radius: 2.5rem;
  cursor: pointer;

  background: ${({ active }) => (active ? 'var(--brand-colors-blue-blue-500, #263668)' : 'white')};
  color: ${({ active }) => (active ? 'white' : 'black')};
  border: ${({ active }) => (active ? 'none' : '1px solid #475582')};

  &:hover {
    background: ${({ active }) => (active ? '#1a2948' : '#f8f8f8')};
    color: ${({ active }) => (active ? '#f0f0f0' : '#2d2d2d')};
    border: ${({ active }) => (active ? 'none' : '1px solid #3d5077')};
  }
`;

export const FilterContainer = styled.div`
  margin-left: -16px;

  box-sizing: border-box;
  width: 100%;
  border: 0.063rem solid var(--disabled-bg-color);
  border-radius: 0.5rem;
  flex: none;
  order: 0;
  flex-grow: 1;
  padding: 1.5rem;
  margin-bottom: 7rem;
`;
