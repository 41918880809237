import { Modal as AntModal } from 'antd';
import styled from 'styled-components';

export const Modal = styled(AntModal)`
  font-family: 'Mulish';

  .ant-modal-header {
    background: var(--background-base-color);
    display: flex;
    align-items: center;
  }

  .ant-modal-title {
    color: var(--text-label);
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.75rem;
    display: flex;
    align-items: center;
    margin-top: 0.4rem;
  }

  .ant-modal-close-icon {
    color: var(--text-label);
    margin-top: 1.625rem;
    margin-right: 1.625rem;
  }

  .ant-modal-body {
    font-family: Mulish;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }

  .ant-modal-content {
    border-radius: 0.5rem;
  }

  .span-bold {
    font-weight: 800;
  }
`;
