import React, { useEffect, useState } from 'react';
import { Modal } from '@app/components/common/Modal/Modal';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Col, Row } from 'antd';
import {
  CardInfoContainer,
  CardInfoTitle,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Tooltip } from '@app/components/common/Tooltip/Tooltip';

interface ISendCommandToDeviceModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedParams: number[];
  setSelectedParams: React.Dispatch<React.SetStateAction<number[]>>;
}

// Em conversa com o Euler (09/08/24), ele explicou que esse parâmetros serão enviados ocasionalmente
// pelo embarcado e precisam sempre ser "escritos" com valor 0, mas devemos exibir para o usuário estar ciente
export const ocasionalParameterEmbarcado = [19, 20, 21];

const ModalParametersPerfilEnvio: React.FC<ISendCommandToDeviceModalProps> = ({
  visible,
  setVisible,
  selectedParams,
  setSelectedParams,
}) => {
  const { parameters } = useAppSelector((state) => state.sendParameters);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>(selectedParams);

  const handleCheckboxChange = (id: number) => {
    if (selectedCheckboxes.includes(id)) {
      setSelectedCheckboxes((prevState) => prevState.filter((item) => item !== id));
    } else {
      setSelectedCheckboxes((prevState) => [...prevState, id]);
    }
  };

  const handleOnChangeSelectAll = () => {
    if (
      selectedCheckboxes.filter((p) => !ocasionalParameterEmbarcado.includes(p)).length ===
      parameters.filter((p) => !ocasionalParameterEmbarcado.includes(p.identificadorEmbarcado)).length
    ) {
      setSelectedCheckboxes(
        parameters
          .filter((p) => p.isObrigatorio && !ocasionalParameterEmbarcado.includes(p.identificadorEmbarcado))
          .map((param) => param.identificadorEmbarcado),
      );
    } else {
      setSelectedCheckboxes(parameters.map((param) => param.identificadorEmbarcado));
    }
  };

  const handleConfirmButtonClick = () => {
    setSelectedParams(selectedCheckboxes.filter((s) => !ocasionalParameterEmbarcado.includes(s)));
    setVisible(false);
  };

  useEffect(
    () => setSelectedCheckboxes(selectedParams.filter((s) => !ocasionalParameterEmbarcado.includes(s))),
    [selectedParams],
  );

  return (
    <>
      <Modal
        title={`Parâmetros de envio`}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '0.25rem' }}>
            <Button
              type="ghost"
              onClick={() => {
                setVisible(false);
                setSelectedCheckboxes(selectedParams);
              }}
            >
              Cancelar
            </Button>
            <Button type="primary" loading={false} onClick={handleConfirmButtonClick}>
              Confirmar
            </Button>
          </div>
        }
        size={'large'}
      >
        <CardInfoContainer>
          <CardInfoTitle>Parâmetros obrigatórios</CardInfoTitle>
          <Row gutter={18}>
            {parameters
              .filter((x) => x.isObrigatorio)
              .map((item) => (
                <Col key={item.identificadorEmbarcado} xs={24} md={8} lg={8} style={{ minHeight: '27px' }}>
                  {/*<CardInfoValue>*/}
                  {item.nome}
                  {/*</CardInfoValue>*/}
                </Col>
              ))}
          </Row>
        </CardInfoContainer>
        <CardInfoTitle>Selencione os parâmetros</CardInfoTitle>
        <Row gutter={18} style={{ marginBottom: '0.5rem' }}>
          <Col xs={24} sm={24} md={24}>
            <CardInfoValue>Parâmetros opcionais</CardInfoValue>
          </Col>
        </Row>
        <Row gutter={18} style={{ marginBottom: '1rem' }}>
          <Col xs={24} sm={24} md={24}>
            <Checkbox
              checked={
                selectedCheckboxes.filter((p) => !ocasionalParameterEmbarcado.includes(p)).length ===
                parameters.filter((p) => !ocasionalParameterEmbarcado.includes(p.identificadorEmbarcado)).length
              }
              onChange={() => handleOnChangeSelectAll()}
            >
              Selecionar todos
            </Checkbox>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {parameters
            .filter((x) => !x.isObrigatorio)
            .map((item) => (
              <Col key={item.identificadorEmbarcado} xs={24} md={8} lg={8}>
                <Checkbox
                  checked={
                    selectedCheckboxes.includes(item.identificadorEmbarcado) &&
                    !ocasionalParameterEmbarcado.includes(item.identificadorEmbarcado)
                  }
                  onChange={() => handleCheckboxChange(item.identificadorEmbarcado)}
                  disabled={ocasionalParameterEmbarcado.includes(item.identificadorEmbarcado)}
                >
                  {ocasionalParameterEmbarcado.includes(item.identificadorEmbarcado) ? (
                    <>
                      <Tooltip content="Parâmetro enviado ocasionalmente">{item.nome}</Tooltip>
                    </>
                  ) : (
                    <>{item.nome}</>
                  )}
                </Checkbox>
              </Col>
            ))}
        </Row>
      </Modal>
    </>
  );
};

export default ModalParametersPerfilEnvio;
