import { UserModel } from '@app/domain/identity/userModel';

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};
export const readToken = (): string | null => {
  return localStorage.getItem('accessToken') || null;
};
export const deleteToken = (): void => localStorage.removeItem('accessToken');

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};
export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');
  return userStr ? JSON.parse(userStr) : null;
};
export const deleteUser = (): void => localStorage.removeItem('user');

export const deleteUserFilter = (): void => {
  localStorage.removeItem('userFilter');
};

export const getClaimValue = (claimType: string): string | undefined => {
  return readUser()?.claims?.find((c) => c.claimType == claimType)?.claimValue;
};

// export const getTypeUserFromClaim = (claims: ClaimModel[]) => {
//   return claims.find((c) => c.claimType == UserType.Master || c.claimType == UserType.ClientAdmin)?.claimType;
// };
