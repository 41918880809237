import React, { useCallback, useEffect, useState } from 'react';
import { BaseForm } from '../../forms/BaseForm/BaseForm';
import { Col, Row } from 'antd';
import { BaseFormInputItem } from '../../forms/components/BaseFormInputItem/BaseFormInputItem';
import ICanVehicleService, { CanVehicleService } from '@app/services/canVehicleService';
import {
  CanVehicleManufacturerModel,
  CanVehicleModel,
  CanVehicleParametersCalculatedModel,
  CanVehicleParametersOnOffModel,
} from '@app/domain/canVehicle/canVehicle';
import { notificationController } from '@app/controllers/notificationController';
// import * as S from '../ModulesProfileConfig.styles';
import { HelperNotification } from '../../HelperNotification/HelperNotification';
import { Select } from '../../selects/Select/Select';
import { Panel } from '../../Collapse/Collapse';
import * as S from './CAN.styles';
import { Button } from '../../buttons/Button/Button';

interface CanProps {
  idManufacturer?: number;
  idVehicle?: number;
  parametersCalculated?: CanVehicleParametersCalculatedModel[];
  setParametersCalculated?: React.Dispatch<React.SetStateAction<CanVehicleParametersCalculatedModel[]>>;
  parametersOnOff?: CanVehicleParametersOnOffModel[];
  setParametersOnOff?: React.Dispatch<React.SetStateAction<CanVehicleParametersOnOffModel[]>>;
}

const canVehicleService: ICanVehicleService = new CanVehicleService();

export const CANView: React.FC<CanProps> = ({ idManufacturer, idVehicle, parametersCalculated, parametersOnOff }) => {
  const [canEnabled, setCanEnabled] = useState<boolean>(false);
  const [vehicle, setVehicle] = useState<CanVehicleModel>();
  const [manufacturer, setManufacturer] = useState<CanVehicleManufacturerModel>();

  const fetchManufactures = useCallback(() => {
    canVehicleService
      .getCanVehicleManufacturer()
      .then((response) => setManufacturer(response.find((m) => m.id == idManufacturer)))
      .catch(() => notificationController.error({ message: 'Erro ao buscar os fabricantes' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchVehicle = useCallback(() => {
    canVehicleService
      .get(`${idVehicle}`)
      .then((response) => setVehicle(response))
      .catch(() => notificationController.error({ message: 'Erro ao buscar os veículo' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (idManufacturer && idVehicle) {
      fetchManufactures();
      fetchVehicle();
      setCanEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idManufacturer, idVehicle]);

  return (
    <BaseForm layout="vertical">
      <Row>
        <Col xs={24} md={12}>
          <HelperNotification>
            Utilize este parâmetro quando a maioria dos modelos de veículos de sua frota forem do mesmo fabricante. Caso
            seja necessário alterar o veículo, utilize o &quot;Enviar Comando&quot; em meus dispositivos.
          </HelperNotification>
        </Col>
      </Row>
      <Row>
        <BaseFormInputItem>
          <S.CheckboxDisabled checked={canEnabled}>
            <S.CheckboxLabel>Habilitar CAN</S.CheckboxLabel>
          </S.CheckboxDisabled>
        </BaseFormInputItem>
      </Row>
      {canEnabled && (
        <>
          <Row gutter={18}>
            <Col xs={24} md={5}>
              <BaseFormInputItem label="Fabricante">
                <Select
                  disabled
                  value={idManufacturer}
                  placeholder="Selecione o fabricante"
                  options={[{ label: manufacturer?.nome, value: manufacturer?.id }]}
                ></Select>
              </BaseFormInputItem>
            </Col>
            <Col xs={24} md={5}>
              <BaseFormInputItem label="Veículo">
                <Select
                  placeholder="Selecione o veículo"
                  value={idVehicle}
                  disabled
                  options={[{ label: `${vehicle?.nome} | ${vehicle?.ano}`, value: vehicle?.id }]}
                ></Select>
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row gutter={[18, 18]} style={{ marginTop: '1rem' }}>
            <Col xs={24}>
              <S.Collapse>
                <Panel
                  key="1"
                  header={
                    <Row align={'middle'} justify={'space-between'}>
                      <Col>
                        <S.CollapseHeaderName>Parâmetros da CAN</S.CollapseHeaderName>
                      </Col>
                      <Button type="ghost" disabled>
                        Selecionar parâmetros
                      </Button>
                    </Row>
                  }
                >
                  {parametersCalculated && parametersCalculated.length > 0 && (
                    <>
                      <S.Subtitle>Calculado</S.Subtitle>
                      <Row style={{ paddingTop: '1rem' }}>
                        <S.ParametersQuantity>{parametersCalculated?.length}</S.ParametersQuantity>&nbsp;
                        <S.ParametersQuantityDescription>
                          {parametersCalculated?.length > 1 ? 'Parâmetros' : 'Parâmetro'}
                        </S.ParametersQuantityDescription>
                      </Row>
                      <Row gutter={[16, 16]} style={{ paddingBottom: '1rem' }}>
                        {parametersCalculated.map((parameter) => (
                          <Col key={parameter.nomeId} xs={24} md={4} lg={4}>
                            <S.CanVehiclesParameterName>{parameter.nomeCAN}</S.CanVehiclesParameterName>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                  {parametersOnOff && parametersOnOff.length > 0 && (
                    <>
                      <S.Subtitle>On/Off</S.Subtitle>
                      <Row style={{ paddingTop: '1rem' }}>
                        <S.ParametersQuantity>{parametersOnOff?.length}</S.ParametersQuantity>&nbsp;
                        <S.ParametersQuantityDescription>
                          {parametersOnOff?.length > 1 ? 'Parâmetros' : 'Parâmetro'}
                        </S.ParametersQuantityDescription>
                      </Row>
                      <Row gutter={[16, 16]}>
                        {parametersOnOff.map((parameter, index) => (
                          <Col key={index} xs={24} md={4} lg={4}>
                            <S.CanVehiclesParameterName>{parameter.nomeCAN}</S.CanVehiclesParameterName>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                </Panel>
              </S.Collapse>
            </Col>
          </Row>
        </>
      )}
    </BaseForm>
  );
};
