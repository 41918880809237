import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { Select } from '@app/components/common/selects/Select/Select';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { DeviceProfileModel } from '@app/domain/deviceProfile/deviceProfileModel';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { Button } from '@app/components/common/buttons/Button/Button';
import { IconDevices } from '@app/assets/slump-icons';
import { useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { DeviceProfileService } from '@app/services/deviceProfileService';
import { isJM15 } from '@app/utils/utils';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';

interface ICardSelectProfileDevice {
  deviceProfiles: DeviceProfileModel[];
  newDevice: FactoryDeviceModel;
  setNewDevice: React.Dispatch<React.SetStateAction<FactoryDeviceModel>>;
  setModalFastTestVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const deviceProfileClass = new DeviceProfileService();

export const CardSelectProfileDevice: React.FC<ICardSelectProfileDevice> = ({
  deviceProfiles,
  newDevice,
  setNewDevice,
  setModalFastTestVisible,
}) => {
  const { id } = useParams();
  const [type, setType] = useState<'profile' | 'config' | ''>('');

  const handleProfileChange = async (value: unknown) => {
    const selectedProfile = deviceProfiles.find((p) => p.id == Number(value));

    setNewDevice({
      ...newDevice,
      idPerfilDispositivo: selectedProfile ? Number(value) : 0,
      perfil: selectedProfile?.nome || '',
      idVeiculoCan: deviceProfiles.find((p) => p.id == Number(value))?.idVeiculoCan,
    });

    if (selectedProfile) {
      const idGrupoFirmware = selectedProfile.idGrupoFirmware;
      const versaoFirmware = newDevice.versaoFirmware;

      if (idGrupoFirmware !== undefined && versaoFirmware !== undefined) {
        const versaoFirmwareNoGrupoFirmware = await deviceProfileClass.thereIsFirmwareVersionre(
          idGrupoFirmware,
          versaoFirmware,
        );

        // TODO: Entender melhor porque essa validação
        // const validacaoIPVersao = isIPValidationVersion(newDevice.versaoFirmware || '');

        if (!versaoFirmwareNoGrupoFirmware) {
          notificationController.warning({
            message:
              'A versão de firmware do dispositivo não está contida no grupo de firmware selecionado. Algumas propriedades podem não funcionar corretamente.',
          });
        }
      }
    }
  };

  return (
    <>
      {/* <S.Card> */}
      {/* <S.Title>Selecione o perfil de configuração</S.Title> */}
      <RadioGroup value={type} onChange={(e) => setType(e.target.value)}>
        <Row style={{ marginBottom: '1rem' }}>
          <Radio value="profile">Perfil de configuração</Radio>
        </Row>
        {type === 'profile' && (
          <Row>
            <Col xs={24} md={24}>
              <BaseFormInputItem label="Nome do perfil de configuração">
                <Select
                  value={newDevice.idPerfilDispositivo == 0 ? undefined : newDevice.idPerfilDispositivo}
                  showArrow
                  showSearch
                  placeholder="Selecione o nome do perfil de configuração"
                  onChange={handleProfileChange}
                  options={deviceProfiles.map((d) => ({ value: d.id, label: d.nome }))}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </BaseFormInputItem>
            </Col>
          </Row>
        )}
        {(!id || !newDevice?.idPerfilDispositivo) && !isJM15(newDevice.modelo) ? (
          <>
            <Row style={{ marginBottom: '1rem' }}>
              <Radio value="config">Configuração simplificada</Radio>
            </Row>
            {type === 'config' && (
              <Row justify={'center'}>
                <Col>
                  <Button
                    type="default"
                    style={{ width: '20rem', marginBottom: '1rem' }}
                    onClick={() => setModalFastTestVisible(true)}
                  >
                    <div style={{ width: '1.5rem', height: '1.5rem' }}>
                      <IconDevices />
                    </div>
                    Configuração simplificada
                  </Button>
                </Col>
              </Row>
            )}
          </>
        ) : (
          <></>
        )}
      </RadioGroup>
      {/* </S.Card> */}
    </>
  );
};
