import SkeletonButton from 'antd/lib/skeleton/Button';
import styled from 'styled-components';

export const CarouselSkeleton = styled(SkeletonButton)`
  height: 18rem;

  & .ant-skeleton-button {
    height: 100%;
  }
`;

export const CarouselNewsContainerWrapper = styled.div`
  max-height: 18rem;
  height: 100%;
  width: 100%;
`;
