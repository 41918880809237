import React from 'react';
import { Input as AntInput, InputProps as AntInputProps, InputRef } from 'antd';
import * as S from './Input.styles';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export interface InputProps extends AntInputProps {
  className?: string;
  isSuccess?: boolean;
}

export const { TextArea } = AntInput;

export const Input = React.forwardRef<InputRef, InputProps>(
  ({ className, isSuccess, children, disabled, style, ...props }, ref) => (
    <S.Input
      ref={ref}
      className={className}
      disabled={disabled}
      suffix={
        !disabled &&
        (isSuccess === true ? (
          <CheckCircleOutlined size={35} style={{ color: 'var(--success-color)' }} />
        ) : isSuccess === false ? (
          <CloseCircleOutlined style={{ color: 'var(--error-color)' }} />
        ) : null)
      }
      {...props}
      style={{ ...style }}
    >
      {children}
    </S.Input>
  ),
);
