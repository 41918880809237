import { FC, useEffect, useState } from 'react';
import * as S from './first-acces.styles';
import logo from '@app/assets/images/j-logo.png';
import { Row } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import IAuthService, { AuthService } from '@app/services/authService';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Button } from '@app/components/common/buttons/Button/Button';
import { AuthComponentEnum } from '@app/constants/enums/auth-components';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { InputPassword } from '@app/components/common/inputs/InputPassword/InputPassword';
import { useTranslation } from 'react-i18next';
import { readUser } from '@app/services/localStorage.service';

interface FirstAccessFormData {
  password: string;
  confirmPassword: string;
}
const initStates = {
  password: 'new-password',
  confirmPassword: 'new-password',
};

const authService: IAuthService = new AuthService();

interface FirstAccessProps {
  handleShowComponent: (index: AuthComponentEnum) => void;
}

export const FirstAccess: FC<FirstAccessProps> = ({ handleShowComponent }) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [passErrorText, setPassErrorText] = useState('');
  const [validPass, setValidPass] = useState<boolean | undefined>();
  const [validConfirmPass, setValidConfirmPass] = useState<boolean | undefined>();
  const [firstAccessModel, setFirstAccessModel] = useState<FirstAccessFormData>({} as FirstAccessFormData);

  const handleSubmit = () => {
    if (validPass || validConfirmPass) {
      notificationController.error({ message: 'Senhas inválidas' });
    }

    setLoading(true);

    const user = readUser();
    authService
      .firstAccess({
        userId: user?.id ?? '',
        password: firstAccessModel.password,
        confirmPassword: firstAccessModel.confirmPassword,
      })
      .then(() => {
        handleShowComponent(AuthComponentEnum.Login);
        notificationController.success({
          message: t('newPassword.successMessage'),
          description: t('newPassword.successDescription'),
        });
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
        setLoading(false);
      });
  };

  const validatePassword = (pass: string): boolean => {
    if (pass?.length < 6) {
      setValidPass(false);
      setPassErrorText('Senha pequena');
      return false;
    }

    if (!/(?=.*[A-Z]).*$/.test(pass)) {
      setValidPass(false);
      setPassErrorText('Senha deve conter letras maiúsculas');
      return false;
    }

    if (!/(?=.*[a-z]).*$/.test(pass)) {
      setValidPass(false);
      setPassErrorText('Senha deve conter letras maiúsculas');
      return false;
    }

    if (!/(?=.*[0-9]).*$/.test(pass)) {
      setValidPass(false);
      setPassErrorText('Senha deve conter números');
      return false;
    }

    if (!/(?=.*\W+)(?=^.{6,50}$).*$/.test(pass)) {
      setValidPass(false);
      setPassErrorText('Senha deve conter caracteres especiais');
      return false;
    }

    setValidPass(undefined);
    return true;
  };

  useEffect(() => {
    if (firstAccessModel.password) {
      validatePassword(firstAccessModel.password);
    }
  }, [firstAccessModel.password]);

  useEffect(() => {
    if (firstAccessModel.password != firstAccessModel.confirmPassword) {
      setValidConfirmPass(false);
    } else {
      setValidConfirmPass(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstAccessModel.confirmPassword]);

  return (
    <S.FirstAccessWrapper>
      <Row justify={'center'} style={{ marginBottom: '2.875rem' }}>
        <img src={logo} width={31} height={72} />
      </Row>
      <Row>
        <BaseForm layout="vertical" style={{ width: '100%' }} onFinish={handleSubmit} initialValues={initStates}>
          <BaseForm.Item>
            <S.FirstAccessFormTitle>Primeiro acesso</S.FirstAccessFormTitle>
            <S.FirstAccessFormSubtitle>Defina uma nova senha para acesso.</S.FirstAccessFormSubtitle>
          </BaseForm.Item>
          <BaseFormInputItem
            label="Nova senha"
            name="password"
            supportText="Ex: mínimo de 6 caracteres com letras, números e caracteres especiais."
            isSuccess={validPass}
            errorText={passErrorText}
          >
            <InputPassword
              placeholder="Digite sua senha"
              type="password"
              onChange={(e) => setFirstAccessModel({ ...firstAccessModel, password: e.currentTarget.value })}
            />
          </BaseFormInputItem>
          <BaseFormInputItem
            label="Confirme sua nova senha"
            name="confirmPassword"
            isSuccess={validConfirmPass}
            supportText="Ex: mínimo de 8 caracteres com letras, números e caracteres especiais."
            errorText="Senhas diferentes"
          >
            <InputPassword
              placeholder="Digite sua senha"
              type="password"
              onChange={(e) => setFirstAccessModel({ ...firstAccessModel, confirmPassword: e.currentTarget.value })}
            />
          </BaseFormInputItem>
          <BaseForm.Item style={{ marginTop: '3.125rem' }}>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Criar senha
            </Button>
          </BaseForm.Item>
        </BaseForm>
      </Row>
    </S.FirstAccessWrapper>
  );
};
