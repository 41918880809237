import React, { useCallback, useRef } from 'react';
import { Col, Divider, Row } from 'antd';
import * as S from '../SendCommandToDeviceModal.styles';
import appSettings from '@app/config/appsettings';
import { GoogleMap, GoogleMapProps, LoadScript, Marker } from '@react-google-maps/api';
import { ZoomButtons } from '../../common/GoogleMapItems/ZoomButtons';
import { Panel } from '../../common/Collapse/Collapse';
import JsonFormatter from 'react-json-formatter';
import { LastTransmissionDataModel } from '../SendCommandToDeviceModal';
import { ReactComponent as IconAccessTime } from '@app/assets/icons/access_time.svg';
import { Spinner } from '@app/components/common/SpinSlump/SpinSlump.styles';

const containerStyle = {
  width: '100%',
  height: '18rem',
};

const jsonStyle = {
  propertyStyle: { color: '#0F162A' },
  stringStyle: { color: '#263668' },
  numberStyle: { color: '#475582' },
};

interface ILastTransmissionProps {
  lastTransmissionData: LastTransmissionDataModel | null;
}

const LastTransmission: React.FC<ILastTransmissionProps> = ({ lastTransmissionData }) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const controlsRef = useRef<HTMLDivElement | null>(null);

  const onMapLoad: GoogleMapProps['onLoad'] = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    map.setOptions({
      gestureHandling: 'cooperative',
      fullscreenControl: true,
      zoomControl: false,
      streetViewControl: false,
      mapTypeControl: false,
    });
    if (map && controlsRef.current) {
      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlsRef.current);
    }
    if (lastTransmissionData && lastTransmissionData.gps) {
      map.panTo({ lat: lastTransmissionData.gps.lat, lng: lastTransmissionData.gps.lng });
      map.setZoom(15);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {lastTransmissionData === null ? (
        <S.Container>
          <Divider />
          <S.Title>Informações da última transmissão</S.Title>
          <Row align="middle" justify="center">
            <Col>
              <Spinner size="large" />
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col>
              <S.TitleProgress>Aguarde enquanto carregamos as informações da última transmissão</S.TitleProgress>
            </Col>
            <Col>
              <S.ContainerModal>
                <S.WapperModal>
                  <IconAccessTime />
                  <S.TimeModal>Tempo estimado: 20 segundos</S.TimeModal>
                </S.WapperModal>
              </S.ContainerModal>
            </Col>
          </Row>
        </S.Container>
      ) : (
        <S.Container>
          <Divider />
          <S.Title>Informações da última transmissão</S.Title>
          {lastTransmissionData.gps && (
            <Row align="middle" justify="center">
              <LoadScript googleMapsApiKey={appSettings().MAPS_API_KEY || ''} libraries={['places', 'geometry']}>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={lastTransmissionData.gps}
                  zoom={14}
                  onLoad={onMapLoad}
                >
                  <S.ButtonsMapWrapper>
                    <ZoomButtons mapRef={mapRef} />
                  </S.ButtonsMapWrapper>
                  <Marker position={lastTransmissionData.gps} />
                </GoogleMap>
              </LoadScript>
            </Row>
          )}
          <S.Collapse bordered={false} defaultActiveKey={'JSON'}>
            <Panel header="JSON" key={'JSON'}>
              <JsonFormatter json={lastTransmissionData.payload} tabWith={4} jsonStyle={jsonStyle} />
            </Panel>
          </S.Collapse>
        </S.Container>
      )}
    </>
  );
};

export default LastTransmission;
