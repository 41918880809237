import appSettings from '@app/config/appsettings';
import axios from 'axios';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const RedirectDeviceSerialNumber: FC = () => {
  const { serial } = useParams();

  useEffect(() => {
    const api = axios.create({
      baseURL: `${appSettings().APIs.JMakAdm.UrlBase}/v${appSettings().APIs.JMakAdm.Version}`,
      headers: { 'Content-Type': 'application/json' },
    });

    api
      .get(`dispositivo/obter-url-modelo-dispositivo/${serial}`)
      .then((res) => (window.location.href = res.data.toString()))
      .catch(() => (window.location.href = 'https://jmak.com.br'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
