import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../../main/MainLayout/MainLayout.styles';
import { ReactComponent as ArrowLeft } from '@app/assets/icons/arrow-left.svg';
import MainSider from '../../main/sider/MainSider/MainSider';
import { HeaderRegistrationPages } from '../header/HeaderRegister';
import MainContent from '../../main/MainContent/MainContent';
import { FooterReagister } from '../footer/FooterRegister';

export const RegisterLayout: React.FC = () => {
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [forceCollapse, setForceCollapse] = useState(false);

  const { isDesktop } = useResponsive();
  const location = useLocation();

  useEffect(() => {
    setIsTwoColumnsLayout(!isDesktop);
  }, [location.pathname, isDesktop]);

  return (
    <S.LayoutMaster>
      <MainSider forceCollapse={forceCollapse} isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      <S.LayoutMain>
        <HeaderRegistrationPages icon={<ArrowLeft />} isTwoColumnsLayout={isTwoColumnsLayout} />
        <MainContent id="main-content" $isTwoColumnsLayout={true}>
          <div style={{ padding: '1rem', height: '100%' }}>
            <Outlet />
          </div>
        </MainContent>
        <FooterReagister />
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};
