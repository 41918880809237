import { Col, Row } from 'antd';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType } from 'antd/lib/table/Table';
import * as S from './viewDevice.styles';
import moment from 'moment';
import { Tag } from '@app/components/common/Tag/Tag';
import { ViewAssociatedTrackersHistoryModel } from '@app/domain/device/viewAssociatedTrackersHistoryModel';

interface IViewAssociatedTrackersHistory {
  associatedTrackersHistory: ViewAssociatedTrackersHistoryModel[];
}

export const ViewAssociatedTrackers: React.FC<IViewAssociatedTrackersHistory> = ({ associatedTrackersHistory }) => {
  const columns: ColumnsType<ViewAssociatedTrackersHistoryModel> = [
    {
      title: 'Equipamento',
      dataIndex: 'idTipoEquipamento',
      showSorterTooltip: false,
      width: '5%',
    },
    {
      title: 'Modelo',
      dataIndex: 'idModelo',
      showSorterTooltip: false,
      width: '15%',
    },

    {
      title: 'Apelido',
      dataIndex: 'apelido',
      showSorterTooltip: false,
      width: '15%',
      render: (_, associatedTracker) => associatedTracker.apelido ?? '-',
    },
    {
      title: 'Grupo',
      dataIndex: 'idGrupo',
      showSorterTooltip: false,
      width: '18%',
    },
    {
      title: 'SN',
      dataIndex: 'idGrupo',
      showSorterTooltip: false,
      width: '18%',
    },
    {
      title: 'IMEI',
      dataIndex: 'imei',
      showSorterTooltip: false,
      width: '18%',
    },
    {
      title: 'Data associação',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      width: '12%',
      render: (_, updateFirmware) =>
        updateFirmware.dataHoraAssociacao ? moment(updateFirmware.dataHoraAssociacao).format('DD/MM/yyyy HH:mm') : '-',
    },
    {
      title: 'Data dissociação',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      width: '12%',
      render: (_, updateFirmware) =>
        updateFirmware.dataHoraDissociacao
          ? moment(updateFirmware.dataHoraDissociacao).format('DD/MM/yyyy HH:mm')
          : '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      showSorterTooltip: false,
      width: '13%',
      render: (_, updateFirmware) => {
        if (updateFirmware.status === 'Desassociado') {
          return (
            <Tag color="#E8E8E8" style={{ color: '#383838', width: 'auto', textAlign: 'center' }}>
              {updateFirmware.status}
            </Tag>
          );
        } else if (updateFirmware.status === 'Associado') {
          return (
            <Tag color="#E9F4EE" style={{ color: '#083F18', width: 'auto', textAlign: 'center' }}>
              {updateFirmware.status}
            </Tag>
          );
        } else {
          return '-';
        }
      },
    },
  ];

  return (
    <>
      {associatedTrackersHistory.length > 0 ? (
        <S.Container>
          <Table columns={columns} dataSource={associatedTrackersHistory} scroll={{ x: 800 }} bordered />
        </S.Container>
      ) : (
        <Row justify="center" align="middle">
          <Col xs={24} md={24} style={{ color: '#707070', fontSize: '1rem', fontWeight: 700, textAlign: 'center' }}>
            Nenhum rastreador associado
          </Col>
        </Row>
      )}
    </>
  );
};
