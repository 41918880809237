import { Collapse } from '@app/components/common/Collapse/Collapse';
import { Col, Row } from 'antd';
import * as S from './table-collapsible.styles';

interface ITableCollapsible {
  key: string;
  header: JSX.Element;
  subtitle: JSX.Element;
  data: string[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TableCollapsible: React.FC<ITableCollapsible> = ({ key, header, subtitle, data }) => {
  return (
    <>
      {data?.length > 0 ? (
        <Collapse defaultActiveKey={['1']}>
          <S.TableHeader header={header} key={'1'}>
            <Row gutter={[18, 18]} style={{ padding: '1rem' }}>
              <Row style={{ width: '100%' }}>{subtitle}</Row>
              <Row gutter={[18, 18]} style={{ overflowY: 'auto', maxHeight: '30rem', width: '100%' }}>
                {data?.map((item, index) => {
                  return (
                    <Col key={index} xs={12} xl={8}>
                      {item}
                    </Col>
                  );
                })}
              </Row>
            </Row>
          </S.TableHeader>
        </Collapse>
      ) : (
        <S.NoContent>
          <div className="title">Nenhum cadastro realizado</div>
          <div className="subtitle">Cadastre o(s) equipamento(s) para exibir os resultados</div>
        </S.NoContent>
      )}
    </>
  );
};
