import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { FenceService } from '@app/services/fenceService';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { FenceModel } from '@app/domain/fence/fenceModel';
import MapComponentView from '@app/pages/fence/view/components/MapComponentView/MapComponentView';
import * as S from '@app/pages/fence/view/UniqueFenceView/UniqueFenceView.styles';
import { setFooter } from '@app/store/slices/footerSlice';
import { FenceMapWrapper } from '@app/pages/fence/view/view.styles';
import CardDetails from '@app/components/common/Card/CardDetails/CardDetails';
import { RadioGroup } from '@app/components/common/Radio/Radio';
import { Radio, Row, Space } from 'antd';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { propertyFenceValueToEventos } from '@app/pages/fence/commonFenceFunctions';

const fenceService = new FenceService();

export const UniqueFenceView: React.FC = () => {
  const navigate = useNavigate();
  const { fenceGroupId, id } = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const [fence, setFence] = useState<FenceModel>({
    pontos: [],
  });

  const fetchGroupData = useCallback(() => {
    setLoading(true);
    fenceService
      .get(`/grupos/${fenceGroupId}`)
      .then((response) => {
        const _fence = response?.cercas?.find((fence) => fence.id === Number(id));
        if (_fence) _fence.eventos = propertyFenceValueToEventos(_fence?.propriedade?.valorPropriedade || '');

        setFence(_fence || { pontos: [] });
      })
      .catch((err) => {
        notificationController.error({ message: `Erro ao buscar cerca. ${err}` });
      })
      .finally(() => setLoading(false));
  }, [fenceGroupId, id]);

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: 'Visualizar cerca',
        handleBackClick: () => navigate(`/cercas/${Number(fenceGroupId)}/`),
      }),
    );
    dispatch(setFooter({}));

    if (Number(id) && Number(fenceGroupId)) fetchGroupData();
  }, [dispatch, fenceGroupId, fetchGroupData, id, navigate]);

  return (
    <>
      <PageTitle>Visualizar grupo</PageTitle>
      <PageTitle>Visualizar grupo</PageTitle>
      <Spinner spinning={loading}>
        <PageContainer>
          {/* Header */}
          <S.HeaderContainer>
            <S.HeaderItem>
              <S.HeaderTitle>Cerca</S.HeaderTitle>
              <S.HeaderTitleValue>{fence.nome}</S.HeaderTitleValue>
            </S.HeaderItem>

            <S.HeaderItem>
              <S.HeaderTitle>Tipo da cerca</S.HeaderTitle>
              <S.HeaderTitleValue>{fence.tipoCerca}</S.HeaderTitleValue>
            </S.HeaderItem>

            <S.HeaderItem>
              <S.HeaderTitle>Quantidade de pontos</S.HeaderTitle>
              <S.HeaderTitleValue>{fence?.pontos?.length}</S.HeaderTitleValue>
            </S.HeaderItem>
          </S.HeaderContainer>

          {/* Map component */}
          <FenceMapWrapper>
            <MapComponentView showInactiveFences={true} fences={[fence]} isUniqueFenceView={true} />
          </FenceMapWrapper>

          {/* Card with infos */}
          <BaseForm layout="vertical" style={{ width: '100%', marginTop: '1rem' }}>
            <CardDetails title={'Configuração do evento'}>
              <S.CardSection>
                <S.CardSectionTitle>Evento entrada/saída</S.CardSectionTitle>
                <S.CardSectionFields>
                  <BaseFormInputItem label="Tipo">
                    <RadioGroup value={fence.eventos?.entradaSaida} disabled={true}>
                      <Space size={0} direction="vertical">
                        <Radio value={1}>Entrada</Radio>
                        <Radio value={2}>Saída</Radio>
                        <Radio value={3}>Entrada e Saida</Radio>
                        <Radio value={0}>Não se aplica</Radio>
                      </Space>
                    </RadioGroup>
                  </BaseFormInputItem>
                  <BaseFormInputItem
                    label="Escolher períférico"
                    style={{
                      width: '100%', // Faz com que o Row se ajuste ao espaço disponível
                      maxWidth: '44rem',
                    }}
                  >
                    <RadioGroup value={fence.eventos?.entradaSaidaPeriferico} disabled={true} style={{ width: '100%' }}>
                      <Space size={0} direction="vertical" style={{ width: '100%' }}>
                        <Radio value={1}>Pulsado</Radio>
                        <Row
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '0.5rem 0',
                            width: '100%',
                          }}
                        >
                          <S.FieldWrapper>
                            <S.FieldLabel>Quantidade de ciclos</S.FieldLabel>
                            <S.FieldValue>
                              {!!fence.eventos?.entradaSaidaCiclos ? fence.eventos.entradaSaidaCiclos : '-'}
                            </S.FieldValue>
                          </S.FieldWrapper>
                          <S.FieldWrapper>
                            <S.FieldLabel>Tempo ligado (s)</S.FieldLabel>
                            <S.FieldValue>
                              {!!fence.eventos?.entradaSaidaTempoLigado
                                ? `${fence.eventos.entradaSaidaTempoLigado}s`
                                : '-'}
                            </S.FieldValue>
                          </S.FieldWrapper>
                          <S.FieldWrapper>
                            <S.FieldLabel>Tempo desligado (s)</S.FieldLabel>
                            <S.FieldValue>
                              {!!fence.eventos?.entradaSaidaTempoDesligado
                                ? `${fence.eventos.entradaSaidaTempoDesligado}s`
                                : '-'}
                            </S.FieldValue>
                          </S.FieldWrapper>
                        </Row>
                        <Radio value={2}>Corta corrente</Radio>
                        <Radio value={0}>Não se aplica</Radio>
                      </Space>
                    </RadioGroup>
                  </BaseFormInputItem>
                </S.CardSectionFields>
              </S.CardSection>
              <S.CardSection>
                <S.CardSectionTitle>Evento entrada/saída</S.CardSectionTitle>
                <S.CardSectionFields>
                  <S.FieldWrapper>
                    <Checkbox key={21} checked={fence.eventos?.limiteVelocidade === 1} disabled={true}>
                      <S.FieldLabel>Limite de velocidade (km/h)</S.FieldLabel>
                    </Checkbox>
                    <S.FieldValue>
                      {!!fence.eventos?.limiteVelocidadeValor ? fence.eventos.limiteVelocidadeValor : '-'}
                    </S.FieldValue>
                  </S.FieldWrapper>
                  <S.FieldWrapper>
                    <S.FieldLabel>Periférico</S.FieldLabel>
                    <S.FieldValue>
                      {
                        {
                          1: 'Pulsado',
                          0: '-',
                        }[fence.eventos?.limiteVelocidadePulsado || 0]
                      }
                    </S.FieldValue>
                  </S.FieldWrapper>
                  <S.FieldWrapper>
                    <S.FieldLabel>Quantidade de ciclos</S.FieldLabel>
                    <S.FieldValue>
                      {!!fence.eventos?.limiteVelocidadeCiclos ? fence.eventos.limiteVelocidadeCiclos : '-'}
                    </S.FieldValue>
                  </S.FieldWrapper>
                  <S.FieldWrapper>
                    <S.FieldLabel>Tempo ligado (s)</S.FieldLabel>
                    <S.FieldValue>
                      {!!fence.eventos?.limiteVelocidadeTempoLigado
                        ? `${fence.eventos.limiteVelocidadeTempoLigado}`
                        : '-'}
                    </S.FieldValue>
                  </S.FieldWrapper>
                  <S.FieldWrapper>
                    <S.FieldLabel>Tempo desligado (s)</S.FieldLabel>
                    <S.FieldValue>
                      {!!fence.eventos?.limiteVelocidadeTempoDesligado
                        ? `${fence.eventos.limiteVelocidadeTempoDesligado}`
                        : '-'}
                    </S.FieldValue>
                  </S.FieldWrapper>
                </S.CardSectionFields>
              </S.CardSection>
            </CardDetails>
          </BaseForm>
        </PageContainer>
        <div style={{ height: '100px', width: '100%' }} />
      </Spinner>
    </>
  );
};
