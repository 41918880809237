import { FC, useCallback, useEffect, useState } from 'react';
import * as SC from '../../components.styles';
import { Col, Row, Tooltip } from 'antd';
import { SkeletonButton } from '@app/components/common/Skeleton/Skeleton';
import * as S from './equipments-container.styles';
import { readUser } from '@app/services/localStorage.service';
import { Select } from '@app/components/common/selects/Select/Select';
import { Tooltip as IconTooltip } from '../../tooltip/tooltip';
import { UserType } from '@app/constants/enums/userType';
import { notificationController } from '@app/controllers/notificationController';
import IClientService, { ClientService } from '@app/services/clientService';
import IDeviceGroupService, { DeviceGroupService } from '@app/services/deviceGroupService';
import { BaseOptionType } from 'antd/lib/select';
import IFactoryDeviceService, { FactoryDeviceService } from '@app/services/factoryDeviceService';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { groupBy } from 'lodash';
import { EquipmentDashboardDeviceGroupedModel, EquipmentDashboardModel } from '@app/domain/home/equipments';
import { Button } from '@app/components/common/buttons/Button/Button';
import SvgIconCleaning from '@app/assets/slump-icons/IconCleaning';

const deviceService: IFactoryDeviceService = new FactoryDeviceService();
const clientService: IClientService = new ClientService();
const deviceGroupService: IDeviceGroupService = new DeviceGroupService();

interface EquipmentsContainerProps {
  setIdFilterSelected: (id?: number) => void;
}

export const EquipmentsContainer: FC<EquipmentsContainerProps> = ({ setIdFilterSelected }) => {
  const user = readUser();
  const [loading, setLoading] = useState<boolean>(true);
  const [devices, setDevices] = useState<FactoryDeviceModel[]>([]);
  const [equipmentsData, setEquipmentsData] = useState<EquipmentDashboardModel>();
  const [filter, setFilter] = useState<number | undefined>();
  const [filterData, setFilterData] = useState<BaseOptionType[]>([]);

  const handleOnChangeFilter = (id?: number) => {
    setFilter(id);
    setIdFilterSelected(id);
  };

  const handleCleanFilter = () => {
    setFilter(undefined);
    setIdFilterSelected(undefined);
  };

  const fetch = useCallback(async () => {
    deviceService
      .getArray('')
      .then((res) => {
        setDevices(res);
        setLoading(false);
      })
      .catch(() => {
        notificationController.error({
          message: 'Erro!',
          description: 'Erro ao obter os dados de equipamentos e indicadores.',
        });
        setLoading(false);
      });
  }, []);
  const fetchSelectData = useCallback(async () => {
    if (user?.type == UserType.Client) {
      deviceGroupService
        .getArray('')
        .then((res) => setFilterData(res.map((c) => ({ label: c.nome, value: c.id }))))
        .catch(() =>
          notificationController.error({
            message: 'Erro!',
            description: 'Erro ao buscar os dados de filtro de cliente',
          }),
        );
    } else {
      clientService
        .getArray('')
        .then((res) => setFilterData(res.map((c) => ({ label: c.razaoSocial, value: c.id }))))
        .catch(() =>
          notificationController.error({
            message: 'Erro!',
            description: 'Erro ao buscar os dados de filtro de cliente',
          }),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSelectData();
    fetch();
  }, [fetch, fetchSelectData]);

  useEffect(() => {
    let filtered: FactoryDeviceModel[] = [];

    if (filter) {
      filtered =
        user?.type == UserType.Client
          ? devices.filter((d) => d.idGrupo == filter)
          : devices.filter((d) => d.idCliente == filter);
    } else {
      filtered = devices;
    }

    const online =
      filtered?.filter((d) => d.idCliente && d.connectionState == 'Connected' && d.permiteConexao).length ?? 0;
    const offline =
      filtered?.filter((d) => d.idCliente && d.connectionState != 'Connected' && d.permiteConexao).length ?? 0;
    const acessory = filtered?.filter((d) => !d.permiteConexao).length ?? 0;
    const unlinked = filtered?.filter((d) => !d.idCliente).length ?? 0;
    const total = online + offline + unlinked + acessory;

    //agrupa por modelo
    let groupedByModel: EquipmentDashboardDeviceGroupedModel[] = [];
    Object.keys(groupBy(filtered, (d) => d.idModelo)).map((id) => {
      const devicesFiltered = filtered.filter((d) => d.idModelo == Number(id));

      groupedByModel = [
        ...groupedByModel,
        {
          id: Number(id),
          nome: devicesFiltered[0].modelo,
          online: devicesFiltered?.filter((d) => d.permiteConexao && d.connectionState == 'Connected').length ?? 0,
          offline: devicesFiltered?.filter((d) => d.permiteConexao && d.connectionState != 'Connected').length ?? 0,
          acessory: devicesFiltered?.filter((d) => !d.permiteConexao).length ?? 0,
          total: devicesFiltered.length,
        },
      ];
    });

    //agrupa por cliente ou grupo
    let grouped: EquipmentDashboardDeviceGroupedModel[] = [];
    const devicesGrouped =
      user?.type == UserType.Client
        ? Object.keys(
            groupBy(
              filtered.filter((d) => d.grupo),
              (d) => d.idGrupo,
            ),
          )
        : Object.keys(
            groupBy(
              filtered.filter((d) => d.idCliente),
              (d) => d.idCliente,
            ),
          );

    devicesGrouped.map((id) => {
      const devicesFiltered =
        user?.type == UserType.Client
          ? filtered.filter((d) => d.idGrupo == Number(id))
          : filtered.filter((d) => d.idCliente == Number(id));

      grouped = [
        ...grouped,
        {
          id: Number(id),
          nome: user?.type == UserType.Client ? devicesFiltered[0]?.grupo : devicesFiltered[0]?.cliente,
          online: devicesFiltered?.filter((d) => d.permiteConexao && d.connectionState == 'Connected').length ?? 0,
          offline: devicesFiltered?.filter((d) => d.permiteConexao && d.connectionState != 'Connected').length ?? 0,
          acessory: devicesFiltered?.filter((d) => !d.permiteConexao).length ?? 0,
          total: devicesFiltered.length,
        },
      ];
    });

    setEquipmentsData({
      online: online,
      offline: offline,
      unlinked: unlinked,
      acessory: acessory,
      total: total,
      grouped: grouped,
      groupedByModel: groupedByModel,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, filter]);

  return (
    <Row>
      <Col span={24}>
        <Row gutter={24} justify={'space-between'}>
          <Col>
            <SC.TitleCardHeader>Equipamentos</SC.TitleCardHeader>
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <Select
              width={'100%'}
              showArrow
              showSearch
              allowClear
              placeholder={user?.type == UserType.Client ? 'Pesquisar grupo' : 'Pesquisar cliente'}
              options={filterData}
              filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={filter}
              onChange={(value) => handleOnChangeFilter(value as number | undefined)}
            ></Select>
            <div>
              <Button type="link" onClick={handleCleanFilter}>
                <SvgIconCleaning />
                Limpar filtro
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
      {loading ? (
        <>
          <Col span={24} style={{ marginTop: '1.25rem' }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <SkeletonButton active block size="large" shape="default" />
              </Col>
            </Row>
          </Col>
          <SC.TitleCardHeader style={{ marginTop: '1.5rem' }}>Indicadores</SC.TitleCardHeader>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <S.IndicatorCardSkeleton active block size="large" shape="default" />
              </Col>
              <Col span={12}>
                <S.IndicatorCardSkeleton active block size="large" shape="default" />
              </Col>
            </Row>
          </Col>
        </>
      ) : (
        <>
          <Col span={24}>
            <S.EquipmentsContainerHeader>
              <S.EquipmentsContainerHeaderCol lg={4}>
                <Row gutter={8} align={'middle'} justify={'center'}>
                  <Col>
                    <SC.LegendIndicator color="#159D3B" />
                  </Col>
                  <Col>
                    <S.EquipmentsContainerHeaderTitle>Online</S.EquipmentsContainerHeaderTitle>
                  </Col>
                  <Col>
                    <S.EquipmentsContainerHeaderSubtitle>{equipmentsData?.online}</S.EquipmentsContainerHeaderSubtitle>
                  </Col>
                </Row>
              </S.EquipmentsContainerHeaderCol>
              <Col>
                <S.EquipmentsContainerHeaderDivider type="vertical" />
              </Col>
              <S.EquipmentsContainerHeaderCol lg={4}>
                <Row gutter={8} align={'middle'} justify={'center'}>
                  <Col>
                    <SC.LegendIndicator color="#A71916" />
                  </Col>
                  <Col>
                    <S.EquipmentsContainerHeaderTitle>Offline</S.EquipmentsContainerHeaderTitle>
                  </Col>
                  <Col>
                    <S.EquipmentsContainerHeaderSubtitle>{equipmentsData?.offline}</S.EquipmentsContainerHeaderSubtitle>
                  </Col>
                </Row>
              </S.EquipmentsContainerHeaderCol>
              <Col>
                <S.EquipmentsContainerHeaderDivider type="vertical" />
              </Col>
              <S.EquipmentsContainerHeaderCol lg={5}>
                <Row gutter={8} align={'middle'} justify={'center'}>
                  <Col>
                    <SC.LegendIndicator color="#263668" />
                  </Col>
                  <Col>
                    <S.EquipmentsContainerHeaderTitle>Acessórios</S.EquipmentsContainerHeaderTitle>
                  </Col>
                  <Col>
                    <S.EquipmentsContainerHeaderSubtitle>
                      {equipmentsData?.acessory}
                    </S.EquipmentsContainerHeaderSubtitle>
                  </Col>
                </Row>
              </S.EquipmentsContainerHeaderCol>
              {user?.type != UserType.Client && (
                <>
                  <Col>
                    <S.EquipmentsContainerHeaderDivider type="vertical" />
                  </Col>
                  <S.EquipmentsContainerHeaderCol lg={5}>
                    <Row gutter={8} align={'middle'} justify={'center'}>
                      <Col>
                        <S.EquipmentsContainerHeaderTitle>Desvinculados</S.EquipmentsContainerHeaderTitle>
                      </Col>
                      <Col>
                        <S.EquipmentsContainerHeaderSubtitle>
                          {equipmentsData?.unlinked}
                        </S.EquipmentsContainerHeaderSubtitle>
                      </Col>
                    </Row>
                  </S.EquipmentsContainerHeaderCol>
                </>
              )}
              <Col>
                <S.EquipmentsContainerHeaderDivider type="vertical" />
              </Col>
              <S.EquipmentsContainerHeaderCol lg={3}>
                <Row gutter={8} align={'middle'} justify={'center'}>
                  <Col>
                    <S.EquipmentsContainerHeaderTitle>Total</S.EquipmentsContainerHeaderTitle>
                  </Col>
                  <Col>
                    <S.EquipmentsContainerHeaderSubtitle>{equipmentsData?.total}</S.EquipmentsContainerHeaderSubtitle>
                  </Col>
                </Row>
              </S.EquipmentsContainerHeaderCol>
            </S.EquipmentsContainerHeader>
          </Col>
          <SC.TitleCardHeader style={{ marginTop: '1.5rem' }}>Indicadores</SC.TitleCardHeader>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <S.EquipmentsContainerContentCard>
                  <S.EquipmentsContainerContentCardHeader>
                    <S.EquipmentsContainerContentCardHeaderTitle>
                      {user?.type == UserType.Client ? 'Grupos' : 'Clientes'}
                    </S.EquipmentsContainerContentCardHeaderTitle>
                    <S.EquipmentsContainerContentCardHeaderSubtitle>
                      Total <span>{equipmentsData?.grouped.length ?? 0}</span>
                    </S.EquipmentsContainerContentCardHeaderSubtitle>
                  </S.EquipmentsContainerContentCardHeader>
                  <S.EquipmentsContainerContentCardList>
                    {equipmentsData?.grouped.map((g) => {
                      const onlinePercent = (g.online / g.total) * 100;
                      const offlinePercent = (g.offline / g.total) * 100;
                      const acessoryPercent = (g.acessory / g.total) * 100;

                      return (
                        <Col key={g.id} span={24}>
                          <Row gutter={16} justify={'space-between'} align={'middle'}>
                            <Col span={18}>
                              <S.EquipmentsContainerContentProgressContainer>
                                <IconTooltip
                                  icon={<S.EquipmentsContainerContentProgressLegendTooltip color="#159D3B" />}
                                  title="Online"
                                  subtitle={`${g.online} equipamentos`}
                                >
                                  <S.EquipmentsContainerContentProgressBarOnline
                                    onlinePercent={onlinePercent}
                                    offlinePercent={offlinePercent}
                                    acessoryPercent={acessoryPercent}
                                  />
                                </IconTooltip>
                                <IconTooltip
                                  icon={<S.EquipmentsContainerContentProgressLegendTooltip color="#A71916" />}
                                  title="Offline"
                                  subtitle={`${g.offline} equipamentos`}
                                >
                                  <S.EquipmentsContainerContentProgressBarOffline
                                    onlinePercent={onlinePercent}
                                    offlinePercent={offlinePercent}
                                    acessoryPercent={acessoryPercent}
                                  />
                                </IconTooltip>
                                <IconTooltip
                                  icon={<S.EquipmentsContainerContentProgressLegendTooltip color="#263668" />}
                                  title="Acessórios"
                                  subtitle={`${g.acessory} equipamentos`}
                                >
                                  <S.EquipmentsContainerContentProgressBarAcessory
                                    onlinePercent={onlinePercent}
                                    offlinePercent={offlinePercent}
                                    acessoryPercent={acessoryPercent}
                                  />
                                </IconTooltip>
                              </S.EquipmentsContainerContentProgressContainer>
                            </Col>
                            <Tooltip
                              title={g.nome}
                              showArrow={false}
                              color="white"
                              overlayInnerStyle={{ color: '#545454', border: '1px solid #bababa' }}
                            >
                              <Col span={6}>{g.nome?.split(' ')[0]}</Col>
                            </Tooltip>
                          </Row>
                        </Col>
                      );
                    })}
                  </S.EquipmentsContainerContentCardList>
                </S.EquipmentsContainerContentCard>
              </Col>
              <Col span={12}>
                <S.EquipmentsContainerContentCard>
                  <S.EquipmentsContainerContentCardHeader>
                    <S.EquipmentsContainerContentCardHeaderTitle>Modelos</S.EquipmentsContainerContentCardHeaderTitle>
                    <S.EquipmentsContainerContentCardHeaderSubtitle>
                      Total <span>{equipmentsData?.groupedByModel?.length ?? 0}</span>
                    </S.EquipmentsContainerContentCardHeaderSubtitle>
                  </S.EquipmentsContainerContentCardHeader>
                  <S.EquipmentsContainerContentCardList gutter={[0, 0]}>
                    {equipmentsData?.groupedByModel.map((g) => {
                      const onlinePercent = (g.online / g.total) * 100;
                      const offlinePercent = (g.offline / g.total) * 100;
                      const acessoryPercent = (g.acessory / g.total) * 100;

                      return (
                        <Col key={g.id} span={24}>
                          <Row gutter={16} justify={'space-between'} align={'middle'}>
                            <Col span={18}>
                              <S.EquipmentsContainerContentProgressContainer>
                                <IconTooltip
                                  icon={<S.EquipmentsContainerContentProgressLegendTooltip color="#159D3B" />}
                                  title="Online"
                                  subtitle={`${g.online} equipamentos`}
                                >
                                  <S.EquipmentsContainerContentProgressBarOnline
                                    onlinePercent={onlinePercent}
                                    offlinePercent={offlinePercent}
                                    acessoryPercent={acessoryPercent}
                                  />
                                </IconTooltip>
                                <IconTooltip
                                  icon={<S.EquipmentsContainerContentProgressLegendTooltip color="#A71916" />}
                                  title="Offline"
                                  subtitle={`${g.offline} equipamentos`}
                                >
                                  <S.EquipmentsContainerContentProgressBarOffline
                                    onlinePercent={onlinePercent}
                                    offlinePercent={offlinePercent}
                                    acessoryPercent={acessoryPercent}
                                  />
                                </IconTooltip>
                                <IconTooltip
                                  icon={<S.EquipmentsContainerContentProgressLegendTooltip color="#263668" />}
                                  title="Acessórios"
                                  subtitle={`${g.acessory} equipamentos`}
                                >
                                  <S.EquipmentsContainerContentProgressBarAcessory
                                    onlinePercent={onlinePercent}
                                    offlinePercent={offlinePercent}
                                    acessoryPercent={acessoryPercent}
                                  />
                                </IconTooltip>
                              </S.EquipmentsContainerContentProgressContainer>
                            </Col>
                            <Tooltip
                              title={g.nome}
                              showArrow={false}
                              color="white"
                              overlayInnerStyle={{ color: '#545454', border: '1px solid #bababa' }}
                            >
                              <Col span={6}>{g.nome?.split(' ')[0]}</Col>
                            </Tooltip>
                          </Row>
                        </Col>
                      );
                    })}
                  </S.EquipmentsContainerContentCardList>
                </S.EquipmentsContainerContentCard>
              </Col>
            </Row>
          </Col>
        </>
      )}
    </Row>
  );
};
