import { Row } from 'antd';
import styled from 'styled-components';

export const PerformanceContainerWrapper = styled(Row)`
  max-height: 26rem;
  margin-top: 1.25rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const PerformanceContainerCard = styled.div`
  font-family: Mulish;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-align: left;
  border: 1px solid #d1d1d1;
  border-radius: 0.5rem;
  height: 3.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  .performance-card-title {
    transition: transform 0.3s ease;
    text-overflow: ellipsis;
  }
  .performance-card-legend {
    transition: opacity 0.3s ease;
  }

  &:hover {
    background-color: #e6ecf1;
    border-radius: 0.5rem;
    border: 2px solid #263668;

    .performance-card-title {
      transform: translateX(-3rem);
    }
    .performance-card-legend {
      opacity: 0;
    }
  }
`;

export const PerformanceContainerCardRow = styled(Row)`
  height: 100%;
  padding: 1rem 1.5rem 1rem 1.5rem;
  align-items: center;
  justify-content: space-between;
`;

export const PerformanceContainerModal = styled.div``;
