import { Tag } from '@app/components/common/Tag/Tag';
import styled from 'styled-components';

export const RedTag = styled(Tag)`
  width: 100%;
  text-align: center;
  color: #620e12;
  background-color: #fee9ea;
`;

export const Container = styled.div`
  padding: 0 1rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const InputFilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 1rem;
`;
