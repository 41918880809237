import { FC, useState } from 'react';
import * as S from './forgot-password.styles';
import logo from '@app/assets/images/j-logo.png';
import { Row } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import IAuthService, { AuthService } from '@app/services/authService';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { Button } from '@app/components/common/buttons/Button/Button';
import { AuthComponentEnum } from '@app/constants/enums/auth-components';

interface ForgotPasswordFormData {
  email: string;
}
const authService: IAuthService = new AuthService();

interface ForgotPasswordProps {
  handleShowComponent: (index: AuthComponentEnum) => void;
}

export const ForgotPassword: FC<ForgotPasswordProps> = ({ handleShowComponent }) => {
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: ForgotPasswordFormData) => {
    setLoading(true);
    authService
      .requestNewPassword(values.email)
      .then(() => {
        handleShowComponent(AuthComponentEnum.ForgotPasswordSuccess);
      })
      .catch((err: Error) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      });
  };

  return (
    <S.ForgotPasswordWrapper>
      <Row justify={'center'} style={{ marginBottom: '2.875rem' }}>
        <img src={logo} width={31} height={72} />
      </Row>
      <Row>
        <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={{ remember: true }}>
          <BaseForm.Item>
            <S.ForgotPasswordFormTitle>Esqueceu a senha?</S.ForgotPasswordFormTitle>
            <S.ForgotPasswordFormSubtitle>
              Informe seu e-mail cadastrado no Jmak para enviarmos as instruções para trocar sua senha
            </S.ForgotPasswordFormSubtitle>
          </BaseForm.Item>
          <BaseForm.Item label="E-mail" name="email" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input placeholder="Digite seu e-mail" />
          </BaseForm.Item>
          <BaseForm.Item style={{ marginTop: '3.125rem' }}>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Enviar
            </Button>
            <Row justify={'end'}>
              <S.ForgotPasswordFormLabelBack onClick={() => handleShowComponent(AuthComponentEnum.Login)}>
                Voltar
              </S.ForgotPasswordFormLabelBack>
            </Row>
          </BaseForm.Item>
        </BaseForm>
      </Row>
    </S.ForgotPasswordWrapper>
  );
};
