/* eslint-disable @typescript-eslint/no-unused-vars */
import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Description } from '@app/components/common/Description/Description';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Modal } from '@app/components/common/Modal/Modal';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import { Col, Divider, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { ClientModel } from '@app/domain/client/clientModel';
import { setFooter } from '@app/store/slices/footerSlice';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import axios from 'axios';
import appSettings from '@app/config/appsettings';
import { ClientService } from '@app/services/clientService';
import { ColumnsType } from 'antd/lib/table';
import { Button } from '@app/components/common/buttons/Button/Button';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { IconMore } from '@app/assets/slump-icons';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { SectorService } from '@app/services/sectorService';
import { SectorModel } from '@app/domain/sectorModel/sectorModel';
import { ClientContactModel } from '@app/domain/clientContact/clientContactModel';
import { ArgsProps } from 'antd/lib/notification';
import { emailPattern } from '@app/constants/patterns';
import { TableWithoutPagination } from '@app/components/common/TableWithoutPagination/TableWithoutPagination';
import { Select } from '@app/components/common/selects/Select/Select';
import { Input } from '@app/components/common/inputs/Input/Input';
import { Spinner } from '@app/components/common/Spinner/Spinner';

const clientService = new ClientService();
const sectorService = new SectorService();

const ClientsCreate: React.FC = () => {
  const { isMounted } = useMounted();
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [client, setClient] = useState<ClientModel>({
    id: 0,
    cnpj: '',
    razaoSocial: '',
    createdAt: new Date(),
    updateAt: new Date(),
  });
  const [cnpjValido, setCnpjValido] = useState<boolean | undefined>(undefined);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [newEmailUpdating, setNewEmailUpdating] = useState<string>();
  const [modalEdit, setModalEdit] = useState(false);
  const [contactEdit, setContactEdit] = useState<ClientContactModel>({} as ClientContactModel);
  const [cepValido, setCepValido] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(true);
  const [sectorTypesOptions, setSectorTypesOptions] = useState<SectorModel[]>([]);
  const [contact, setContact] = useState<ClientContactModel>({} as ClientContactModel);
  const [emailValido, setEmailValido] = useState<boolean | undefined>(undefined);
  const [contactToDelete, setContactToDelete] = useState<ClientContactModel | null>(null);

  const columns: ColumnsType<ClientContactModel> = [
    {
      title: 'Setor',
      dataIndex: 'idSetor',
      showSorterTooltip: true,
      defaultSortOrder: 'descend',
      ellipsis: true,
      width: '15%',
      render: (idSetor: number) => {
        const setor = sectorTypesOptions.find((sector) => sector.id === idSetor);
        return setor?.nome ?? '';
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      showSorterTooltip: true,
      ellipsis: true,
      width: '30%',
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      showSorterTooltip: true,
      ellipsis: true,
      width: '30%',
    },
    {
      title: '',
      dataIndex: 'id',
      showSorterTooltip: false,
      width: '8%',
      defaultSortOrder: 'descend',
      render: (_, rowData) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <DropdownTable
              buttonText=""
              iconD={
                <div style={{ display: 'flex', color: '#545454', alignItems: 'center' }}>
                  <IconMore />
                </div>
              }
              trigger={['click']}
              placement="bottomRight"
              overlay={
                <Menu>
                  <MenuItem onClick={() => handleEditClick(rowData)}>
                    <Button type="text">Editar</Button>
                  </MenuItem>
                  <MenuItem onClick={() => handleDeleteClick(rowData)}>
                    <Button type="text">Excluir</Button>
                  </MenuItem>
                </Menu>
              }
            ></DropdownTable>
          </div>
        );
      },
    },
  ];

  const handleEditClick = (contactUpdate: ClientContactModel) => {
    setModalEdit(true);
    setContactEdit({
      ...contactUpdate,
      telefone: formatarTelefone(contactUpdate?.telefone ?? ''),
    });
    setNewEmailUpdating(contactUpdate.email);
  };

  const handleTelefoneEditOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const telefoneFormatado = formatarTelefone(e.currentTarget.value);
    setContactEdit({ ...contactEdit, telefone: telefoneFormatado });
  };

  const handleEmailEditOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.currentTarget.value;
    setContactEdit({ ...contactEdit, email });
    setEmailValido(emailPattern.test(email));
  };

  const handleDeleteClick = (contactDelete: ClientContactModel) => {
    setContactToDelete(contactDelete);
    setModalDeleteVisible(true);
  };

  const handleConfirmDelete = () => {
    if (contactToDelete) {
      const deletedContact = client.contatos?.filter((c) => c.id !== contactToDelete.id);
      setClient({ ...client, contatos: deletedContact });

      notificationController.success({
        message: 'Contato removido da lista',
        description: 'Finalize a edição do contato para salvar as alterações.',
      });
      setModalDeleteVisible(false);
    }
  };

  const handleSaveModelClick = () => {
    if (!isValidContact(contactEdit)) {
      notificationController.error({ message: 'Erro!', description: 'Preencha todos os campos' });
      return;
    }
    setClient({
      ...client,
      contatos: client.contatos?.map((m) =>
        m.email === contactEdit.email
          ? {
              ...m,
              ...contactEdit,
              email: contactEdit.email,
              idSetor: contactEdit.idSetor,
              telefone: contactEdit.telefone,
            }
          : m,
      ),
    });
    setModalEdit(false);
    setContactEdit({} as ClientContactModel);
    setNewEmailUpdating(undefined);

    notificationController.success({
      message: 'Contato atualizado',
      description: 'Finalize a edição do contato para salvar as alterações.',
    });
  };

  const handleBackClient = () => {
    navigate('/clientes');
  };

  const isValidNewContact = (newEmail: string, contacts: ClientContactModel[]) => {
    return contacts.find((c) => c.email === newEmail) === undefined;
  };

  const isValidContact = (contact: ClientContactModel) => {
    return contact.idSetor && contact.email;
  };

  const handleCnpjOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const x = e.currentTarget.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);

    if (x !== null) {
      e.currentTarget.value = !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
      setClient({ ...client, cnpj: e.currentTarget.value });
    }
  };

  const handleCnpjOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && client.cnpj.endsWith('/')) {
      setClient({ ...client, cnpj: client.cnpj.slice(0, -2) });
    }
  };

  const handleAddContactClick = () => {
    if (!isValidNewContact(contact.email, client.contatos ?? [])) {
      notificationController.error({ message: 'Erro!', description: 'Já existe um contato cadastrado com esse email' });
      return;
    }

    if (!isValidContact(contact)) {
      notificationController.error({ message: 'Erro!', description: 'Preencha todos os campos' });
      return;
    }

    setClient({ ...client, contatos: [...(client.contatos ?? []), contact] });
    setContact({} as ClientContactModel);

    const notification: ArgsProps = { message: 'Contato adicionado á lista' };
    if (Number(id)) notification.description = 'Finalize a edição do contato para salvar as alterações.';

    notificationController.success(notification);
  };

  const handleOnChangeCep = async (e: React.FormEvent<HTMLInputElement>) => {
    const x = e.currentTarget.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);

    if (x !== null) {
      const cep = !x[2] ? x[1] : x[1] + '-' + x[2];

      try {
        if (cep?.length === 9) {
          setLoading(true);
          const cepResponse = await axios.get(appSettings().APIs.Consulta.CEP + cep.replace('-', '') + '/json');

          if (cepResponse.data.erro == 'true') {
            throw new Error('CEP Inválido');
          }

          setClient({
            ...client,
            cep: cep,
            logradouro: cepResponse.data.logradouro,
            cidade: cepResponse.data.localidade,
            estado: cepResponse.data.uf,
            bairro: cepResponse.data.bairro,
            complemento: cepResponse.data.complemento,
          });
          setCepValido(true);
          setLoading(false);
          return;
        } else {
          throw new Error('CEP Inválido');
        }
      } catch (error) {
        setCepValido(false);
        setClient({
          ...client,
          cep: cep,
          logradouro: '',
          cidade: '',
          estado: '',
          bairro: '',
          complemento: '',
        });
      }
    }
  };

  const fetchSectorTypesOptions = useCallback(() => {
    return sectorService
      .getArray('')
      .then((res: SectorModel[]) => {
        setSectorTypesOptions(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });
  }, []);

  const handleTelefoneOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const telefoneFormatado = formatarTelefone(e.currentTarget.value);
    setContact({ ...contact, telefone: telefoneFormatado });
  };

  const handleSalvarCliente = async (e: React.MouseEvent) => {
    if (client.complemento === null || client.complemento === undefined) {
      client.complemento = ' ';
    }

    const clientToSave = {
      ...client,
      contatos: client.contatos?.map((contact) => ({
        ...contact,
        setor: sectorTypesOptions.find((sector) => sector.id === contact.idSetor)?.nome || contact.setor,
      })),
    };
    setLoading(true);

    try {
      if (!client.id) {
        await clientService.post('', clientToSave);
        notificationController.success({ message: 'Cadastro de novo cliente realizado com sucesso!' });
      } else {
        await clientService.update('', clientToSave);
        notificationController.success({ message: 'Edição de cliente realizada com sucesso!' });
      }
      handleBackClient();
    } catch (err) {
      setLoading(false);
      console.error(err);
      notificationController.error({
        message: `Erro ao ${client.id ? 'atualizar' : 'cadastrar'} cliente, tente novamente. ${err}`,
      });
    }
  };

  useEffect(() => {
    async function buscaCnpj() {
      const rawCnpj = client.cnpj.replace(/[^\w\s]/gi, '');
      if (client.cnpj.length === 18) {
        setLoading(true);
        try {
          const cnpjResponse = await axios.get(`${appSettings().APIs.Consulta.CNPJ}/${rawCnpj}`);
          if (cnpjResponse.status === 200) {
            setCnpjValido(true);
            setClient({ ...client, razaoSocial: cnpjResponse.data.razao_social });
          }
        } catch (error) {
          setCnpjValido(false);
          notificationController.error({ message: 'CNPJ Inválido.' });
        }
        setLoading(false);
      }
    }

    if (!id) {
      buscaCnpj();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.cnpj]);

  const formatarTelefone = (value: string) => {
    const telefone = value.replace(/\D/g, '');

    if (telefone.length <= 2) {
      return telefone;
    } else if (telefone.length <= 6) {
      return `(${telefone.slice(0, 2)}) ${telefone.slice(2)}`;
    } else if (telefone.length <= 10) {
      return `(${telefone.slice(0, 2)}) ${telefone.slice(2, 6)}-${telefone.slice(6)}`;
    } else {
      return `(${telefone.slice(0, 2)}) ${telefone.slice(2, 7)}-${telefone.slice(7, 11)}`;
    }
  };

  const handleNumeroChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numero = e.target.value;
    const regex = /^[a-zA-Z0-9]*$/;

    if (numero.length <= 10 && regex.test(numero)) {
      setClient({ ...client, numero: numero });
    }
  };

  const handleEmailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.currentTarget.value;
    setContact({ ...contact, email: email });
    setEmailValido(emailPattern.test(email));
  };

  const handleComplementoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const complemento = e.target.value;

    if (complemento.length <= 45) {
      setClient({ ...client, complemento: complemento });
    }
  };

  useEffect(() => {
    if (id) {
      setCepValido(true);
      setCnpjValido(true);
      fetchClient(parseInt(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchClient = (id: number) => {
    if (!client.id) {
      clientService.get(`${id}`).then((res) => {
        setClient(res);
      });
    }
  };
  useEffect(() => {
    if (isMounted.current) {
      dispatch(
        setHeaderRegister({
          title: !id ? 'Novo Cliente' : 'Editar Cliente',
          handleBackClick: handleBackClient,
        }),
      );
      dispatch(
        setFooter({
          confirmButtonDisabled: !(cnpjValido && cepValido),
          confirmButtonText: id ? 'Salvar' : 'Cadastrar',
          handleCancelButtonClick: () => setModalCancelVisible(true),
          handleConfirmButtonClick: handleSalvarCliente,
          cancelButtonText: 'Cancelar',
        }),
      );
    }
    fetchSectorTypesOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, fetchSectorTypesOptions]);

  useEffect(() => {
    if (client.logradouro !== undefined && client.razaoSocial?.length > 0 && cepValido && cnpjValido) {
      setValidation(false);
    } else {
      setValidation(true);
    }
  }, [client.logradouro, client.razaoSocial, cnpjValido, cepValido]);

  return (
    <>
      <Modal
        title={`Cancelar ${id ? 'edição' : 'cadastro'} do cliente`}
        open={modalCancelVisible}
        onOk={handleBackClient}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            Deseja realmente cancelar {id ? 'a edição' : 'o cadastro'} do cliente?
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Deletar contato"
        open={modalDeleteVisible}
        onOk={handleConfirmDelete}
        onCancel={() => setModalDeleteVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <CloseCircleOutlined size={20} style={{ color: '#C41B24', marginRight: '1rem' }} />
            Tem certeza que deseja <strong>&nbsp; deletar o contato &nbsp;</strong> selecionado?
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Editar contato"
        open={modalEdit}
        onOk={() => handleSaveModelClick()}
        onCancel={() => setModalEdit(false)}
        cancelText="Cancelar"
        okText="Salvar"
        size="large"
      >
        <BaseForm>
          <Row gutter={16}>
            <Col span={24}>
              <BaseFormInputItem label="Setor" style={{ width: '100%' }}>
                <Select
                  showArrow
                  placeholder="Selecione o setor"
                  value={contactEdit.idSetor}
                  onChange={(value) =>
                    setContactEdit((prevState) => ({
                      ...prevState,
                      idSetor: value as number,
                    }))
                  }
                  options={sectorTypesOptions.map((c) => ({
                    value: c.id,
                    label: `${c.nome}`,
                  }))}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row gutter={5}>
            <Col span={12}>
              <BaseFormInputItem
                label="E-mail"
                supportText=""
                errorText="Campo inválido"
                successText=""
                style={{ width: '100%' }}
              >
                <Input placeholder="Digite o e-mail" value={contactEdit.email} onChange={handleEmailEditOnChange} />
              </BaseFormInputItem>
            </Col>
            <Col span={12}>
              <BaseFormInputItem
                label="Telefone"
                supportText=""
                style={{ width: '100%' }}
                errorText="Campo inválido"
                successText=""
              >
                <Input
                  placeholder="Digite o telefone"
                  value={contactEdit.telefone}
                  onChange={handleTelefoneEditOnChange}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
        </BaseForm>
      </Modal>
      <PageContainer>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Spinner spinning={loading}>
            <Description title="Informações do cliente" subtitle="Preencha os campos para cadastrar um novo cliente">
              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="CNPJ"
                    supportText="14 caracteres somente números"
                    isSuccess={cnpjValido}
                    errorText={cnpjValido ? `CNPJ informado já foi cadastrado.` : ' CNPJ informado está inválido '}
                    successText="14 caracteres somente números"
                  >
                    <Input
                      placeholder="Digite o número do CNPJ"
                      value={client.cnpj}
                      disabled={id ? true : false}
                      onChange={handleCnpjOnChange}
                      onKeyDown={handleCnpjOnKeyDown}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Razão Social" supportText="" errorText="Campo inválido" successText="">
                    <Input disabled placeholder="Digite o nome da razão social" value={client.razaoSocial} />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
            <Description title="Contato do cliente" subtitle="Preencha os campos para cadastrar os contatos do cliente">
              <Row gutter={18}>
                <Col xs={24} md={6}>
                  <BaseFormInputItem label="Setor">
                    <Select
                      showArrow
                      placeholder="Selecione o setor"
                      value={contact.idSetor}
                      onChange={(value) =>
                        setContact((prevState) => ({
                          ...prevState,
                          idSetor: value as number,
                        }))
                      }
                      options={sectorTypesOptions.map((c) => ({
                        value: c.id,
                        label: `${c.nome}`,
                      }))}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={6}>
                  <BaseFormInputItem label="E-mail" supportText="" errorText="Campo inválido" successText="">
                    <Input placeholder="Digite o e-mail" value={contact.email} onChange={handleEmailOnChange} />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={6}>
                  <BaseFormInputItem label="Telefone" supportText="" errorText="Campo inválido" successText="">
                    <Input
                      maxLength={20}
                      placeholder="Digite o telefone"
                      value={contact?.telefone}
                      onChange={handleTelefoneOnChange}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={6}>
                  <Button
                    type="primary"
                    onClick={handleAddContactClick}
                    style={{ marginTop: 30, marginLeft: '7rem', width: '236px' }}
                    disabled={!isValidContact(contact)}
                  >
                    {client.contatos && client.contatos.length > 0 ? `Incluir` : `Adicionar Contato`}
                  </Button>
                </Col>
              </Row>
            </Description>
            {client.contatos && client.contatos.length > 0 && (
              <>
                <Divider />
                <Description title="Contatos cadastrados" subtitle="">
                  <TableWithoutPagination
                    columns={columns}
                    dataSource={client.contatos?.map((c) => ({
                      ...c,
                      setor: sectorTypesOptions.find((sector) => sector.id === c.idSetor)?.nome,
                    }))}
                    bordered={true}
                    style={{ padding: 0, width: '100%' }}
                  />
                </Description>
              </>
            )}

            <Description title="Endereço" subtitle="Preencha os campos abaixo para informar o endereço do cliente">
              <Row gutter={18}>
                <Col xs={24} md={5}>
                  <BaseFormInputItem
                    label="CEP"
                    supportText="8 caracteres somente números"
                    isSuccess={!id ? cepValido : undefined}
                    errorText="CEP informado não existe"
                    successText="8 caracteres somente números"
                  >
                    <Input placeholder="Digite o CEP" value={client.cep} onChange={handleOnChangeCep} />
                  </BaseFormInputItem>
                </Col>
              </Row>

              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Cidade" supportText="" errorText="Campo inválido" successText="">
                    <Input
                      placeholder="Digite a cidade"
                      disabled
                      value={client.cidade}
                      onChange={(e) => setClient({ ...client, cidade: e.target.value })}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Estado" supportText="" errorText="Campo inválido" successText="">
                    <Input
                      placeholder="Digite o estado"
                      disabled
                      value={client.estado}
                      onChange={(e) => setClient({ ...client, estado: e.target.value })}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Rua/Avenida" supportText="" errorText="Campo inválido" successText="">
                    <Input
                      placeholder="Digite a rua/avenida"
                      value={client.logradouro}
                      onChange={(e) => setClient({ ...client, logradouro: e.target.value })}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>

              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Bairro" supportText="" errorText="Campo inválido" successText="">
                    <Input
                      placeholder="Digite o bairro"
                      value={client.bairro}
                      onChange={(e) => setClient({ ...client, bairro: e.target.value })}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={4}>
                  <BaseFormInputItem label="Número" supportText="" errorText="Campo inválido" successText="">
                    <Input placeholder="Digite o número" value={client.numero} onChange={handleNumeroChange} />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={4}>
                  <BaseFormInputItem label="Complemento" supportText="" errorText="Campo inválido" successText="">
                    <Input
                      placeholder="Digite o complemento"
                      value={client.complemento}
                      onChange={handleComplementoChange}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
          </Spinner>
        </BaseForm>
      </PageContainer>
    </>
  );
};
export default ClientsCreate;
