import { FC } from 'react';
import * as S from './viewDevice.styles';
import { ColumnsType } from 'antd/lib/table';
import { DeviceProfileHistoryModel } from '@app/domain/device-history/device-profile-history-model';
import { formatarData } from '@app/utils/utils';
import { Row } from 'antd';
import { Table } from '@app/components/common/Table/Table';

interface ViewDeviceProfileHistoryProps {
  profileHistory: DeviceProfileHistoryModel[];
}

export const ViewDeviceProfileHistory: FC<ViewDeviceProfileHistoryProps> = ({ profileHistory }) => {
  const columns: ColumnsType<DeviceProfileHistoryModel> = [
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      sorter: (a, b) => new Date(a.dataHora ?? '').getTime() - new Date(b.dataHora ?? '').getTime(),
      render: (dataHora) => formatarData(dataHora),
    },
    {
      title: 'Perfil de Configuração',
      dataIndex: 'perfilDispositivo',
      showSorterTooltip: false,
      sorter: (a, b) => a.perfilDispositivo.localeCompare(b.perfilDispositivo),
    },
    {
      title: 'Usuário responsável',
      dataIndex: 'usuario',
      showSorterTooltip: false,
      sorter: (a, b) => a.usuario.localeCompare(b.usuario),
    },
  ];

  return (
    <>
      {profileHistory.length > 0 ? (
        <S.Container>
          <h1>Histórico</h1>
          <Table columns={columns} dataSource={profileHistory} scroll={{ x: 800 }} bordered />
        </S.Container>
      ) : (
        <Row justify="center" align="middle">
          <S.TableNoContentCol xs={24} md={24}>
            Nenhuma alteração de perfil realizada
          </S.TableNoContentCol>
        </Row>
      )}
    </>
  );
};
