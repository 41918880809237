import { ViewConfigUpdateHistoryGroupedByModuleModel } from '@app/domain/device/viewConfigUpdateHistoryModel';
import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';

export interface ViewDeviceSlice {
  updateConfigHistory: ViewConfigUpdateHistoryGroupedByModuleModel | null;
}

const initialState: ViewDeviceSlice = {
  updateConfigHistory: null,
};

export const setViewDeviceConfigUpdate = createAction<PrepareAction<ViewDeviceSlice>>(
  'viewDevice/setViewDeviceConfigUpdate',
  (newViewDeviceSlice: ViewDeviceSlice) => {
    return {
      payload: newViewDeviceSlice,
    };
  },
);

export const viewDeviceSlice = createSlice({
  name: 'viewDevice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setViewDeviceConfigUpdate, (state, action) => {
      state.updateConfigHistory = action.payload.updateConfigHistory;
    });
  },
});

export default viewDeviceSlice.reducer;
