import { InfoCircleOutlined } from '@ant-design/icons';
import { Description } from '@app/components/common/Description/Description';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { Select } from '@app/components/common/selects/Select/Select';
import { notificationController } from '@app/controllers/notificationController';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { DeviceGroupModel } from '@app/domain/deviceGroup/deviceGroupModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IDeviceGroupService, { DeviceGroupService } from '@app/services/deviceGroupService';
import IFactoryDeviceService, { FactoryDeviceService } from '@app/services/factoryDeviceService';
import { setFooter } from '@app/store/slices/footerSlice';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Modal, Row, Col, Tooltip } from 'antd';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { Table } from '@app/components/common/Table/Table';
import { TableNoContent } from '@app/components/common/TableNoContent/TableNoContent';
import { IconDeleteOutlined } from '@app/assets/slump-icons';
import { readUser } from '@app/services/localStorage.service';

const deviceService: IFactoryDeviceService = new FactoryDeviceService();
const deviceGroupService: IDeviceGroupService = new DeviceGroupService();

export const DeviceGroupCreate: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [deviceSelected, setDeviceSelected] = useState<FactoryDeviceModel>();
  const [group, setGroup] = useState<DeviceGroupModel>();
  const [devices, setDevices] = useState<FactoryDeviceModel[]>([]);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [modalRemoveDeviceVisible, setModalRemoveDeviceVisible] = useState(false);

  const handleBackClick = () => setModalCancelVisible(true);

  const handleRemoveDeviceFromGroupClick = (device: FactoryDeviceModel) => {
    setDeviceSelected(device);
    setModalRemoveDeviceVisible(true);
  };
  const handleRemoveDeviceFromGroupConfirm = () => {
    if (deviceSelected) {
      setGroup({
        ...group,
        dispositivos: group?.dispositivos?.filter((d) => d.id !== deviceSelected.id),
      });
      setDevices([...devices, deviceSelected]);
    }
    setModalRemoveDeviceVisible(false);
    setDeviceSelected(undefined);
  };

  const handleAddDeviceToGroup = () => {
    if (deviceSelected?.id) {
      setGroup({
        ...group,
        dispositivos: [...(group?.dispositivos ?? []), deviceSelected],
      });
      setDevices(devices.filter((d) => d.id !== deviceSelected.id));
      setDeviceSelected(undefined);
      notificationController.success({ message: 'Dispositivo adicionado com sucesso!' });
    }
  };

  const handleSaveClick = () => {
    setLoading(true);
    if (group) {
      const groupReq = {
        ...group,
        idCliente: readUser()?.idCliente ?? 1,
        idsDispositivo: group.dispositivos?.map((d) => Number(d.id)),
      };

      if (id) {
        deviceGroupService
          .update('', groupReq)
          .then(() => {
            setLoading(false);
            notificationController.success({
              message: 'Edição de grupo de dispositivo realizado com sucesso!',
            });
            navigate('/dispositivos/grupos');
          })
          .catch((e) => {
            notificationController.error(e);
            setLoading(false);
          });
      } else {
        deviceGroupService
          .post('', groupReq)
          .then(() => {
            setLoading(false);
            notificationController.success({
              message: 'Cadastro de novo grupo de dispositivo realizado com sucesso!.',
            });
            navigate('/dispositivos/grupos');
          })
          .catch((e) => {
            notificationController.error(e);
            setLoading(false);
          });
      }
    }
  };

  const fetchDevices = useCallback(async () => {
    try {
      setDevices(await deviceService.getArray('obter-todos-sem-vinculo-grupo'));
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um problema ao buscar os dispositivos',
      });
    }
  }, []);
  const fetch = useCallback(async (id: number) => {
    try {
      setLoading(true);
      setGroup(await deviceGroupService.get(`${id}`));
      setLoading(false);
    } catch (error) {
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um problema ao buscar o grupo de dispositivo',
      });
      setLoading(false);
    }
  }, []);

  const columns = (): ColumnsType<FactoryDeviceModel> => [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: false,
      width: '3%',
    },
    {
      title: 'Equipamento',
      dataIndex: 'tipoEquipamento',
      showSorterTooltip: false,
      width: 'auto',
    },
    {
      title: 'Modelo',
      dataIndex: 'modelo',
      showSorterTooltip: false,
      width: 'auto',
    },
    {
      title: 'Apelido',
      dataIndex: 'apelido',
      showSorterTooltip: false,
      width: 'auto',
    },
    {
      title: '',
      dataIndex: 'id',
      showSorterTooltip: false,
      width: '3%',
      render: (id, device) => (
        <Tooltip title="Deletar">
          <div
            onClick={() => handleRemoveDeviceFromGroupClick(device)}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', cursor: 'pointer' }}
          >
            <IconDeleteOutlined />
          </div>
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: id ? 'Editar grupo de dispositivo(s)' : 'Novo grupo de dispositivo(s)',
        handleBackClick: handleBackClick,
      }),
    );

    fetchDevices();
    if (id) fetch(Number(id));
  }, [fetch, fetchDevices, dispatch, id]);

  useEffect(() => {
    dispatch(
      setFooter({
        confirmButtonText: id ? 'Salvar' : 'Cadastrar grupo',
        confirmButtonDisabled: !group?.nome,
        handleConfirmButtonClick: handleSaveClick,
        cancelButtonText: 'Cancelar',
        handleCancelButtonClick: () => setModalCancelVisible(true),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, dispatch]);

  return (
    <Spinner spinning={loading}>
      <Modal
        title={`Cancelar ${id ? 'edição' : 'cadastro'} do grupo`}
        open={modalCancelVisible}
        onOk={() => navigate('/dispositivos/grupos')}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            Deseja realmente <strong>&nbsp;cancelar {id ? 'a edição' : 'o cadastro'} do grupo?</strong>
          </Col>
        </Row>
      </Modal>
      <Modal
        title={`Remover dispositivo`}
        open={modalRemoveDeviceVisible}
        onOk={handleRemoveDeviceFromGroupConfirm}
        onCancel={() => setModalRemoveDeviceVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={22}>
            Deseja realmente <strong>&nbsp;remover o dispositivo</strong> do grupo?
          </Col>
        </Row>
      </Modal>
      <PageContainer>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Description
            title="Informações do grupo"
            subtitle={`Preencha o campo para ${id ? 'editar' : 'cadastrar'} um novo grupo`}
          />
          <Row gutter={18}>
            <Col xs={24} md={8}>
              <BaseFormInputItem label="Grupo">
                <Input
                  placeholder="Digite o nome do grupo"
                  value={group?.nome}
                  onChange={(e) => setGroup({ ...group, nome: e.currentTarget.value })}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
          <Description
            title="Informações dos dispositivos"
            subtitle={`Preencha os campos para cadastrar o apelido do dispositivo no grupo`}
          />
          <Row gutter={18} align={'bottom'}>
            <Col xs={24} md={8}>
              <BaseFormInputItem label="SN / Apelido">
                <Select
                  showArrow
                  showSearch
                  placeholder="Digite o SN ou Apelido"
                  value={deviceSelected?.id}
                  disabled={!group?.nome}
                  onChange={(value) => setDeviceSelected(devices?.find((d) => d.id == Number(value)))}
                  options={devices?.map((d) => ({
                    value: d.id,
                    label: `${d.apelido ? `${d.apelido} - ` : ''}${d.numeroSerie}`,
                  }))}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </BaseFormInputItem>
            </Col>
            <Col>
              <Button
                disabled={!deviceSelected}
                style={{ marginBottom: '1.2rem' }}
                type="primary"
                onClick={handleAddDeviceToGroup}
              >
                Adicionar dispositivo
              </Button>
            </Col>
          </Row>
          {group?.dispositivos?.length ? (
            <>
              <Description title="Equipamentos adicionados" />
              <Table columns={columns()} dataSource={group?.dispositivos} style={{ marginLeft: '-1rem' }} bordered />
            </>
          ) : (
            <TableNoContent
              title="Nenhum cadastro realizado"
              subTitleBlue="Cadastre o(s) dispositivo(s)"
              subTitleGray="para exibir os resultados"
            />
          )}
        </BaseForm>
      </PageContainer>
    </Spinner>
  );
};
