import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 800px;
  column-gap: 2rem;
  row-gap: 0.5rem;
  padding-bottom: 1.5rem;

  font-family: Mulish;
  font-size: 1.25rem;
  font-style: normal;
  line-height: normal;
`;

export const HeaderItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  font-family: Mulish;
  font-size: 1.25rem;
  font-style: normal;
  line-height: normal;
`;

export const HeaderTitle = styled.div`
  color: #8c8c8c;
  font-weight: 400;
`;

export const HeaderTitleValue = styled.div`
  color: #383838;

  font-weight: 600;
`;

export const CardSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
`;

export const CardSectionTitle = styled.div`
  color: #383838;
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const CardSectionFields = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 8rem;
`;

export const FieldLabel = styled.div`
  width: 100%;
  color: #8c8c8c;
  leading-trim: both;
  text-edge: cap;
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const FieldValue = styled.div`
  width: 100%;
  font-size: 0.925rem;
  font-weight: 600;
  font-style: normal;
  font-family: 'Mulish';
  line-height: 18px;
  color: #545454;
`;
