import { styled } from 'styled-components';

export const Text = styled.span`
  color: var(--neutral-gray-gray-600, #707070);
  font-family: 'Mulish';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Bold = styled.span`
  color: var(--neutral-gray-gray-600, #707070);
  font-family: 'Mulish';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-left: 0.125rem;
`;

export const Subtitle = styled.span`
  color: var(--neutral-gray-gray-800, #383838);
  font-family: 'Mulish';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5625rem;

  margin-bottom: 0.875rem;
`;
