import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as S from './ProfileOverlay.styles';
import { DropdownMenu } from '../../../Header.styles';

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();

  const handleLogout = () => {
    localStorage.removeItem('deviceSearch');
    localStorage.removeItem('factoryDeviceSearch');
    localStorage.removeItem('devicePage');
    localStorage.removeItem('factoryDevicePage');
  };

  return (
    <>
      <DropdownMenu
        selectable={false}
        {...props}
        style={{ overflowX: 'hidden', overflowY: 'hidden', minWidth: '150px' }}
      >
        <S.MenuItem key={1}>
          <Link to="/logout" onClick={handleLogout}>
            <S.Text>{t('header.logout')}</S.Text>
          </Link>
        </S.MenuItem>
      </DropdownMenu>
    </>
  );
};
