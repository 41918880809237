import { Col, Row } from 'antd';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType } from 'antd/lib/table/Table';
import * as S from './viewDevice.styles';
import moment from 'moment';
import { ViewCommandHistoryModel } from '@app/domain/device/viewCommandHistoryModel';
import { Tag } from '@app/components/common/Tag/Tag';

interface IViewCommandHistory {
  commandHistory: ViewCommandHistoryModel[];
}

export const ViewCommandHistory: React.FC<IViewCommandHistory> = ({ commandHistory }) => {
  const columns: ColumnsType<ViewCommandHistoryModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: false,
      width: '12%',
    },
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      width: '12%',
      render: (_, command) => moment(command.dataHora).format('DD/MM/yyyy HH:mm'),
    },
    {
      title: 'Usuário',
      dataIndex: 'usuario',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: 'Comando enviado',
      dataIndex: 'comandoEnviado',
      showSorterTooltip: false,
      width: '18%',
    },
    {
      title: 'Valor anterior',
      dataIndex: 'valorAnterior',
      showSorterTooltip: false,
      width: '15%',
      render: (_, command) => (command.valorAnterior.length <= 0 ? '-' : command.valorAnterior),
    },
    {
      title: 'Valor atualizado',
      dataIndex: 'valorAtualizado',
      showSorterTooltip: false,
      width: '15%',
      render: (_, command) => (command.valorAtualizado.length <= 0 ? '-' : command.valorAtualizado),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      showSorterTooltip: false,
      width: '15%',
      render: (_, command) => {
        if (command.status === false) {
          return (
            <Tag color="#FEF7E7" style={{ color: '#620E12', width: 'auto', textAlign: 'center' }}>
              Falha
            </Tag>
          );
        } else if (command.status === true) {
          return (
            <Tag color="#E9F4EE" style={{ color: '#083F18', width: 'auto', textAlign: 'center' }}>
              Concluído
            </Tag>
          );
        } else {
          return '-';
        }
      },
    },
  ];

  return (
    <>
      {commandHistory.length > 0 ? (
        <S.Container>
          <Table columns={columns} dataSource={commandHistory} scroll={{ x: 800 }} bordered />
        </S.Container>
      ) : (
        <Row justify="center" align="middle">
          <Col xs={24} md={24} style={{ color: '#707070', fontSize: '1rem', fontWeight: 700, textAlign: 'center' }}>
            Nenhum comando realizado
          </Col>
        </Row>
      )}
    </>
  );
};
