import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DescriptionWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 1.5rem;
`;

export const Title = styled.span`
  color: var(--Neutral-Gray-Gray500, #8c8c8c);
  font-family: 'Mulish';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Subtitle = styled.span`
  color: #545454;
  font-family: 'Mulish';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
