import styled from 'styled-components';

export const Card = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid var(--neutral-gray-gray-300, #bababa);
  padding: 1rem;
  padding-left: 1.5;
`;

export const Title = styled.h2`
  margin-bottom: 1rem;

  color: var(--neutral-gray-gray-800, #383838);
  font-family: Mulish;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const LoadingContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;

  color: var(--neutral-gray-gray-500, #8c8c8c);
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
