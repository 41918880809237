import { media } from '@app/styles/themes/constants';
import { Button as AntButton } from 'antd';
import { ButtonProps } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
`;
export const DescriptionWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 1.5rem;
`;

export const Title = styled.span`
  font-family: 'Mulish';
  font-size: 1.5rem !important;
  font-weight: 600;
  line-height: 30px;
  color: var(--tooltip-background);
  margin-top: 1.5rem;

  @media only screen and (${media.md}) {
    font-family: 'Mulish';
    font-size: 1.125rem;
    flex-wrap: wrap;
    word-wrap: break-word;
  }
  @media only screen and (${media.xs}) {
    font-size: 1.325rem;
  }
  @media only screen and (${media.xxl}) {
    font-size: 1.5rem;
  }
`;

export const Subtitle = styled.span`
  font-family: 'Mulish';
  font-size: 1rem !important;
  font-weight: 600;
  line-height: 20px;
  color: var(--input-placeholder-color);
  margin-top: 0.5rem;

  @media only screen and (${media.md}) {
    font-family: 'Mulish';
    font-size: 0.625rem;
    flex-wrap: wrap;
    word-wrap: break-word;
  }
  @media only screen and (${media.xs}) {
    font-size: 0.925rem;
  }
  @media only screen and (${media.xxl}) {
    font-size: 1rem;
  }
`;

export const Button = styled(AntButton)<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 1.25rem;
  background-color: #545454;
  border-color: #545454;
  border-radius: 15rem;

  &:hover {
    background-color: #686868;
    border-color: #686868;
  }
`;
