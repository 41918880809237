import React from 'react';
import { DeviceProfilePropertyModel } from '@app/domain/deviceProfile/deviceProfileModel';

interface ShouldRenderInputProps {
  children: React.ReactNode;
  propertyGroupedData: DeviceProfilePropertyModel[];
  idEmbarcado: number;
}

const ShouldRenderInput: React.FC<ShouldRenderInputProps> = ({ children, propertyGroupedData, idEmbarcado }) => {
  // Função para verificar se a idEmbarcado está dentro do array de PropertyGroupedData
  const shouldRender = (idEmbarcado: number, propertyGroupedData: DeviceProfilePropertyModel[]): boolean => {
    return propertyGroupedData.some((property) => property.idEmbarcado === idEmbarcado);
  };

  return shouldRender(idEmbarcado, propertyGroupedData) ? <>{children}</> : null;
};

export default ShouldRenderInput;
