import styled from 'styled-components';
import { Collapse as AntdCollapse } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CollapseContainer = styled.div`
  margin-left: -16px;

  && .ant-collapse-item {
    border: none;
  }
`;

export const Collapse = styled(AntdCollapse)`
  background-color: white;
  padding-left: -16px;

  && .ant-collapse-header-text {
    color: var(--Neutral-Gray-Gray800, #383838);
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const Divisor = styled.div`
  height: 0.0625rem;
  width: 100%;
  margin: 1rem 0;
`;
