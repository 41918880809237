/* eslint-disable react-hooks/exhaustive-deps */
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import * as S from './view.styles';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { notificationController } from '@app/controllers/notificationController';
import { useMounted } from '@app/hooks/useMounted';
import { ViewDeviceProfile } from './components/ViewDeviceFilters/ViewDeviceProfile';
import { ViewDeviceHeader } from './components/ViewDeviceHeader/ViewDeviceHeader';
import { DeviceProfileProperty } from '@app/domain/deviceProfile/deviceProfileModel';
import { setFooter } from '@app/store/slices/footerSlice';
import { CANView } from '@app/components/common/ModulesProfileConfig/CAN/CANView';
import { CercaView } from '@app/components/common/ModulesProfileConfig/Cerca/CercaView';
import { CanVehicleParametersCalculatedModel, CanVehicleParametersOnOffModel } from '@app/domain/canVehicle/canVehicle';
import { DeviceProfileService } from '@app/services/deviceProfileService';
import { ViewAssociatedTrackers } from './components/ViewDeviceFilters/ViewAssociatedTrackers';
import { GrupoTagView } from '@app/components/common/ModulesProfileConfig/GrupoTags/GrupoTagsView';
import { ViewTagIndividualHistory } from './components/ViewDeviceFilters/ViewIndividualTagHistory';
import { ViewTagDeviceRelationshioHistoryModel } from '@app/domain/device/viewTagDeviceRelationshioHistoryModel';
import { ViewAssociatedTrackersHistoryModel } from '@app/domain/device/viewAssociatedTrackersHistoryModel';
import { isJM15, isJR11, isJR12 } from '@app/utils/utils';
import { ViewHistorics } from './components/ViewHistorics/ViewHistorics';
import IDeviceHistoryService, { DeviceHistoryService } from '@app/services/deviceHistoryService';
import IFactoryDeviceService, { FactoryDeviceService } from '@app/services/factoryDeviceService';

const filters = [
  { idFilter: 1, filter: 'Perfil do dispositivo' },
  { idFilter: 2, filter: 'Históricos' },
  { idFilter: 3, filter: 'Veículo CAN' },
  { idFilter: 4, filter: 'Grupo de cercas' },
  { idFilter: 5, filter: 'Grupo de tags' },
  { idFilter: 6, filter: 'Tags individuais' },
  { idFilter: 7, filter: 'Rastreadores associados' },
];

const deviceService: IFactoryDeviceService = new FactoryDeviceService();
const deviceHistoryService: IDeviceHistoryService = new DeviceHistoryService();
const deviceProfileService = new DeviceProfileService();

interface DeviceViewProps {
  goBackTo: string;
}

export const DeviceView: React.FC<DeviceViewProps> = ({ goBackTo }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMounted } = useMounted();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(0);
  const [device, setDevice] = useState<FactoryDeviceModel | null>(null);
  const [deviceProfileHistory, setDeviceProfileHistory] = useState<DeviceProfileProperty[]>([]);
  const [parametersCalculated, setParametersCalculated] = useState<CanVehicleParametersCalculatedModel[]>([]);
  const [parametersOnOff, setParametersOnOff] = useState<CanVehicleParametersOnOffModel[]>([]);
  const [individualTagHistory, setIndividualTagHistory] = useState<ViewTagDeviceRelationshioHistoryModel[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [associatedTrackersHistory, setAssociatedTrackers] = useState<ViewAssociatedTrackersHistoryModel[]>([]);

  const getFilter = (idFilter: number) => {
    const filter = filters.find((f) => f.idFilter === idFilter);
    return filter?.filter ?? '';
  };

  const getFilterComponent = (idFilter: number) => {
    switch (idFilter) {
      case 1:
        return <ViewDeviceProfile deviceProfileHistory={deviceProfileHistory} device={device} />;
      case 2:
        return <ViewHistorics />;
      case 3:
        return (
          <>
            <h1>
              <strong>Veículos CAN</strong>
            </h1>
            <CANView
              idManufacturer={device?.idFabricante}
              idVehicle={device?.idVeiculoCan}
              parametersCalculated={parametersCalculated}
              parametersOnOff={parametersOnOff}
            />
          </>
        );
      case 4:
        return (
          <>
            <h1>
              <strong>Cercas</strong>
            </h1>
            <CercaView idGroup={device?.idGrupoCerca} />
          </>
        );
      case 5:
        return (
          <>
            <GrupoTagView idGroupTag={device?.idTagGrupo} />
          </>
        );
      case 6:
        return (
          <>
            <ViewTagIndividualHistory individualTagHistory={individualTagHistory} device={device} />
          </>
        );
      case 7:
        return (
          <>
            <ViewAssociatedTrackers associatedTrackersHistory={associatedTrackersHistory} />
          </>
        );
      default:
        break;
    }
  };

  const handleBackClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(goBackTo);
  };

  const fetchDeviceProfileHistory = async () => {
    setLoading(true);

    deviceHistoryService
      .getDeviceProfileParameterHistory(`${id}`)
      .then((res: DeviceProfileProperty[]) => {
        if (isMounted.current) {
          setDeviceProfileHistory(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        // notificationController.error({ message: `Erro ao obter perfil de configuração. ${err}` });
        setDeviceProfileHistory([]);
      });
  };

  const fetchTagHistory = async () => {
    setLoading(true);

    deviceHistoryService
      .getTagHistory(`${id}`)
      .then((res) => {
        if (isMounted.current) {
          setIndividualTagHistory(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({ message: `Erro ao obter tag individual. ${err}` });
        setIndividualTagHistory([]);
      });
  };

  const fetchDevice = useCallback(async () => {
    setLoading(true);

    deviceService
      .get(`${id}`)
      .then((res) => {
        if (isMounted.current) {
          setDevice(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({ message: `Erro ao obter dispositivos. ${err}` });
      });
  }, [isMounted]);

  const fetchSelectedParametersCalculatedByPerfil = useCallback(() => {
    setLoading(true);
    return deviceProfileService
      .getSelectedCalculatedParameters(Number(id ?? '0'), device?.idPerfilDispositivo ?? 0)
      .then((res) => {
        setParametersCalculated(res as CanVehicleParametersCalculatedModel[]);
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const fetchSelectedParametersOnOffByPerfil = useCallback(() => {
    setLoading(true);
    return deviceProfileService
      .getSelectedOnOffParameters(Number(id ?? '0'), device?.idPerfilDispositivo ?? 0)
      .then((res) => {
        setParametersOnOff(res as CanVehicleParametersOnOffModel[]);
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(setFooter({}));
    dispatch(
      setHeaderRegister({
        title: 'Visualizar dispositivo',
        handleBackClick: handleBackClick,
      }),
    );
    if (Number(id)) fetchDevice();
  }, []);

  useEffect(() => {
    switch (filter) {
      case 1:
        fetchDeviceProfileHistory();
        break;
      case 3:
        fetchSelectedParametersCalculatedByPerfil();
        fetchSelectedParametersOnOffByPerfil();
        break;
      case 5:
        fetchTagHistory();
        break;
      // case 9:
      //   fetchAssociatedTrackersHistory();
      //   break;
      default:
        break;
    }
  }, [filter]);

  return (
    <>
      <Spinner spinning={loading}>
        <PageContainer>
          <ViewDeviceHeader device={device ?? ({} as FactoryDeviceModel)} />
          <Form layout="vertical" style={{ marginLeft: '-1rem' }}>
            <BaseFormInputItem label="Filtro">
              <S.DeviceTypeWrapper>
                <S.DeviceTypeButton active={filter === 1} onClick={() => setFilter(1)}>
                  {getFilter(1)}
                </S.DeviceTypeButton>
                {isJR11(device?.modelo) || isJR12(device?.modelo) ? (
                  <>
                    <S.DeviceTypeButton active={filter === 2} onClick={() => setFilter(2)}>
                      {getFilter(2)}
                    </S.DeviceTypeButton>
                    {!isJR11(device?.modelo) && (
                      <S.DeviceTypeButton active={filter === 3} onClick={() => setFilter(3)}>
                        {getFilter(3)}
                      </S.DeviceTypeButton>
                    )}
                    <S.DeviceTypeButton active={filter === 4} onClick={() => setFilter(4)}>
                      {getFilter(4)}
                    </S.DeviceTypeButton>
                  </>
                ) : (
                  isJM15(device?.modelo) && (
                    <>
                      <S.DeviceTypeButton active={filter === 5} onClick={() => setFilter(5)}>
                        {getFilter(5)}
                      </S.DeviceTypeButton>
                      <S.DeviceTypeButton active={filter === 6} onClick={() => setFilter(6)}>
                        {getFilter(6)}
                      </S.DeviceTypeButton>
                      <S.DeviceTypeButton active={filter === 7} onClick={() => setFilter(7)}>
                        {getFilter(7)}
                      </S.DeviceTypeButton>
                    </>
                  )
                )}
              </S.DeviceTypeWrapper>
            </BaseFormInputItem>
          </Form>
          {filter > 0 && <S.FilterContainer>{getFilterComponent(filter)}</S.FilterContainer>}
        </PageContainer>
      </Spinner>
    </>
  );
};
