import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as CheckIcon } from '@app/assets/icons/check_circle.svg';
import { ReactComponent as SyncIcon } from '@app/assets/icons/blue-sync.svg';
import { Spin } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import * as CS from '../components.styles';
import * as S from './USBConnector.styles';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { Button } from '@app/components/common/buttons/Button/Button';
import DeviceSerialPort from '@app/services/deviceSerialPortClass';
import { notificationController } from '@app/controllers/notificationController';
import IModelDeviceService, { ModelDeviceService } from '@app/services/modelDeviceService';
import { isJM15 } from '@app/utils/utils';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const deviceSerialPortClass = DeviceSerialPort.getInstance();
const modelService: IModelDeviceService = new ModelDeviceService();

type USBConnectorProps = {
  newFactoryDevice: FactoryDeviceModel;
  setNewFactoryDevice: React.Dispatch<React.SetStateAction<FactoryDeviceModel>>;
  handleAddTag?: (tag: string) => void;
  type?: number;
};

export const USBConnector: FC<USBConnectorProps> = ({ newFactoryDevice, setNewFactoryDevice, handleAddTag, type }) => {
  const [connected, setConnected] = useState(false);
  const [hasStartedReading, setHasStartedReading] = useState(false);

  const startRead = async () => {
    if (!hasStartedReading) {
      if (handleAddTag === undefined) return;
      if (!deviceSerialPortClass.connected) {
        await deviceSerialPortClass.connect();
      }

      setHasStartedReading(true);
      await deviceSerialPortClass.startContinuousRead(handleAddTag);
      setHasStartedReading(false);
    }
  };

  const stopRead = async () => {
    try {
      if (!deviceSerialPortClass.connected) {
        await deviceSerialPortClass.connect();
      }

      await deviceSerialPortClass.stopContinuousRead();
    } catch (error) {
      console.error(error);
      // notificationController.error({ message: 'Erro ao desativar o modo de leitura de tags' });
    }
  };

  const loadData = useCallback(async () => {
    const connected = await deviceSerialPortClass.connect();

    if (connected) {
      setConnected(true);

      const imei = await deviceSerialPortClass.get(DevicePropertyType.Imei);

      if (imei.length < 15 || imei.length > 18 || imei.toUpperCase().includes('ERROR')) {
        notificationController.error({
          message: 'Imei inválido, reconecte o dispositivo e selecione a porta serial correta',
        });
        return;
      }

      const nomeModelo = await deviceSerialPortClass.get(DevicePropertyType.ModeloDispositivo);
      const modelo = await modelService.get(`obter-por-nome/${nomeModelo}`);
      if (!modelo) {
        return notificationController.error({
          message: 'Erro!',
          description: 'Modelo do dispositivo não encontrado, por favor, contate um administrador do sistema',
        });
      }

      const versaoFirmwareDispositivo = await deviceSerialPortClass.get(DevicePropertyType.VersaoFirmware);

      if (versaoFirmwareDispositivo.toUpperCase().includes('ERROR')) {
        return notificationController.error({
          message: 'Erro!',
          description:
            'Erro ao obter a versão do firmware do dispositivo, por favor, contate um administrador do sistema',
        });
      }

      setNewFactoryDevice({
        ...newFactoryDevice,
        imei: imei,
        idTipoEquipamento: modelo.idEquipamento,
        tipoEquipamento: modelo.equipamento,
        modelo: modelo.nome,
        idModelo: modelo.id,
        anatel: modelo.anatel,
        versaoFirmware: versaoFirmwareDispositivo,
      });

      if (isJM15(modelo.nome)) {
        await startRead();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Cleanup
    return () => {
      if (isJM15(newFactoryDevice?.modelo ?? '')) stopRead();
    };
  }, [newFactoryDevice?.modelo]);

  useEffect(() => {
    const verify = async () => {
      if (type && type == 0 && !hasStartedReading && isJM15(newFactoryDevice?.modelo ?? '')) {
        await startRead();
      }

      if (type && type == 1 && isJM15(newFactoryDevice?.modelo ?? '')) {
        await stopRead();
      }
    };

    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <>
      {/* <CS.Card>
        <CS.Title>Conector USB</CS.Title> */}
      <>
        <CS.LoadingContainer>
          <S.ConnectedContainer>
            {connected ? (
              <S.Row>
                <CheckIcon />
                USB conectado com sucesso!
              </S.Row>
            ) : (
              <S.Row>
                <Spin indicator={antIcon} />
                Aguardando reconhecimento do USB.
              </S.Row>
            )}
            {newFactoryDevice?.imei && connected ? (
              <S.Row>
                <CheckIcon />
                Leitura realizada com sucesso!
              </S.Row>
            ) : (
              <S.Row>
                <Spin indicator={antIcon} />
                Realizando leitura no dispositivo
              </S.Row>
            )}
            {newFactoryDevice?.imei && isJM15(newFactoryDevice?.modelo) && handleAddTag && connected && (
              <>
                <S.Row>
                  <CheckIcon />
                  Modo de leitura ativado com sucesso!
                </S.Row>
                <S.Row>
                  <Spin indicator={antIcon} />
                  Aguardando a aproximação da tag ao dispositivo
                </S.Row>
              </>
            )}
          </S.ConnectedContainer>
        </CS.LoadingContainer>
        <S.ReadDevice>
          <Button
            type="default"
            icon={<SyncIcon />}
            style={{ width: '14.75rem' }}
            onClick={() => loadData()}
            disabled={connected}
          >
            Sincronizar
          </Button>
        </S.ReadDevice>
        <S.BottomInfo>
          <S.InfoItem>
            <S.InfoTitle>Equipamento</S.InfoTitle>
            <S.InfoValue>{newFactoryDevice?.tipoEquipamento ?? '-'}</S.InfoValue>
          </S.InfoItem>
          <S.InfoItem>
            <S.InfoTitle>Modelo</S.InfoTitle>
            <S.InfoValue>{newFactoryDevice?.modelo ?? '-'}</S.InfoValue>
          </S.InfoItem>
          <S.InfoItem>
            <S.InfoTitle>Versão de firmware</S.InfoTitle>
            <S.InfoValue>{newFactoryDevice?.versaoFirmware ?? '-'}</S.InfoValue>
          </S.InfoItem>
        </S.BottomInfo>
        <S.BottomInfo>
          <S.InfoItem>
            <S.InfoTitle>IMEI</S.InfoTitle>
            <S.InfoValue>{newFactoryDevice?.imei ?? '-'}</S.InfoValue>
          </S.InfoItem>
        </S.BottomInfo>
      </>
      {/* </CS.Card> */}
    </>
  );
};
