import { Description } from '@app/components/common/Description/Description';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Col, Form, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from '@app/components/common/inputs/Input/Input';
import * as S from './create.styles';
import { setFooter } from '@app/store/slices/footerSlice';
import { Modal } from '@app/components/common/Modal/Modal';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { LabelService } from '@app/services/labelService';
import { LabelModel } from '@app/domain/label/labelModel';
import { notificationController } from '@app/controllers/notificationController';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { EquipmentModel } from '@app/domain/equipment/equipmentModel';
import { ModelDeviceModel } from '@app/domain/modelDevice/modelDeviceModel';
import { EquipmentService } from '@app/services/equipmentService';
import { ModelDeviceService } from '@app/services/modelDeviceService';

interface IBatch {
  idEquipamento?: number;
  idModelo?: number;
  quantidade?: number;
}

const labelService = new LabelService();
const equipmentTypeService = new EquipmentService();
const modelDeviceService = new ModelDeviceService();

export const LabelCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalSaveVisible, setModalSaveVisible] = useState(false);
  const [equipmentTypesOptions, setEquipmentTypesOptions] = useState<EquipmentModel[]>([]);
  const [modelOptions, setModelOptions] = useState<ModelDeviceModel[]>([]);
  const [batch, setBatch] = useState<IBatch>({});
  const [loading, setLoading] = useState(false);

  const handleSaveLabelBatch = () => {
    setLoading(true);
    labelService
      .post('', { quantidade: batch.quantidade!, idModelo: batch.idModelo! } as LabelModel)
      .then(() => {
        notificationController.success({
          message: 'Lote de etiquetas gerado com sucesso',
        });
        navigate('/etiquetas');
      })
      .catch((err) => {
        notificationController.error({
          message: 'Erro ao gerar lote de etiquetas',
          description: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
        setModalSaveVisible(false);
      });
  };

  const verifyBatchToSave = useCallback(() => {
    return !!(batch.idEquipamento && batch.idModelo && batch.quantidade);
  }, [batch]);

  const fetchEquipmentTypesOptions = useCallback(() => {
    return equipmentTypeService
      .getArray('')
      .then((res: EquipmentModel[]) => {
        setEquipmentTypesOptions(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });
  }, []);

  const fetchModelOptions = useCallback(() => {
    return modelDeviceService
      .getArray('')
      .then((res: ModelDeviceModel[]) => {
        setModelOptions(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });
  }, []);

  const fetchComboData = useCallback(async () => {
    setLoading(true);

    try {
      await Promise.all([fetchEquipmentTypesOptions(), fetchModelOptions()]);
    } catch (error) {
      notificationController.error({
        message: 'Erro ao buscar dados das versões de firmware',
      });
    } finally {
      setLoading(false);
    }
  }, [fetchEquipmentTypesOptions, fetchModelOptions]);

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: 'Gerar novo lote',
        handleBackClick: () => navigate('/etiquetas'),
      }),
    );
    fetchComboData();
    // eslint-disable-next-line
  }, [fetchComboData]);

  useEffect(() => {
    dispatch(
      setFooter({
        confirmButtonText: 'Gerar lote',
        confirmButtonDisabled: !verifyBatchToSave(),
        handleConfirmButtonClick: () => setModalSaveVisible(true),
        cancelButtonText: 'Cancelar',
        handleCancelButtonClick: () => navigate('/etiquetas'),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyBatchToSave]);

  const handleQuantityOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Allow only positive integers
    if (!/^[1-9]\d*$/.test(inputValue) && inputValue !== '') return;

    const numberValue = Number(inputValue) > 1000 ? 1000 : Number(inputValue);

    const quantity = numberValue === 0 ? undefined : numberValue;

    setBatch((prevState) => ({ ...prevState, quantidade: quantity }));
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !/^[0-9]$/.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight' &&
      e.key !== 'Delete' &&
      e.key !== 'Tab'
    ) {
      e.preventDefault();
    }
  };

  return (
    <Spinner spinning={loading} text="Gerando lote de etiquetas">
      <PageContainer>
        <Modal
          title="Cadastrar novo lote"
          open={modalSaveVisible}
          onOk={handleSaveLabelBatch}
          onCancel={() => setModalSaveVisible(false)}
          cancelText="Cancelar"
          okText="Confirmar"
        >
          <Row align="middle">
            <Col>
              <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
              Deseja realmente cadastrar um novo lote de etiquetas? Serão geradas {batch.quantidade} etiquetas.
            </Col>
          </Row>
        </Modal>

        <Description
          title="Informações do novo lote de etiquetas"
          subtitle="Preencha os campos abaixo para gerar o novo lote"
        >
          <Form layout="vertical" style={{ width: '100%' }}>
            <S.Container>
              <Col style={{ width: '300px' }}>
                <BaseFormInputItem label="Equipamento">
                  <Select
                    showArrow
                    showSearch
                    placeholder="Selecione o equipamento"
                    value={batch.idEquipamento}
                    onChange={(value) =>
                      setBatch((prevState) => ({
                        ...prevState,
                        idEquipamento: value as number,
                      }))
                    }
                    options={equipmentTypesOptions.map((c) => ({
                      value: c.id,
                      label: `${c.id}: ${c.nome}`,
                    }))}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </BaseFormInputItem>
              </Col>
              <Col style={{ width: '300px' }}>
                <BaseFormInputItem label="Modelo">
                  <Select
                    showArrow
                    showSearch
                    placeholder="Selecione o modelo"
                    value={batch.idModelo}
                    style={{ width: '100%' }}
                    onChange={(value) =>
                      setBatch((prevState) => ({
                        ...prevState,
                        idModelo: value as number,
                      }))
                    }
                    disabled={!batch.idEquipamento}
                    options={modelOptions
                      .filter((model) => model.idEquipamento === batch.idEquipamento)
                      .map((model) => ({
                        value: model.id,
                        label: `${model.id}: ${model.nome}`,
                      }))}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </BaseFormInputItem>
              </Col>
              <Col style={{ width: '300px' }}>
                <BaseFormInputItem label="Quantidade de etiquetas">
                  <Input
                    placeholder="Digite a quantidade de etiquetas"
                    value={batch.quantidade}
                    onChange={handleQuantityOnChange}
                    onKeyDown={handleKeyPress}
                  />
                </BaseFormInputItem>
              </Col>
            </S.Container>
          </Form>
        </Description>
      </PageContainer>
    </Spinner>
  );
};
