import { FC, useEffect, useState } from 'react';
import * as S from './index.styles';
import { Col, Row } from 'antd';
import logo from '@app/assets/images/logo.png';
import { Login } from './components/login/login';
import { ForgotPassword } from './components/forgot-password/forgot-password';
import { AuthComponentEnum } from '@app/constants/enums/auth-components';
import { ForgotPasswordSuccess } from './components/forgot-password/forgot-password-success';
import { NewPassword } from './components/new-password/new-password';
import { FirstAccess } from './components/first-access/first-access';
import { useParams } from 'react-router-dom';
import { CarouselNews } from '@app/components/common/CarouselNews/CarouselNews';

export const Auth: FC = () => {
  const { newpassword } = useParams();
  const [currentAuthComponent, setCurrentAuthComponent] = useState<AuthComponentEnum>(AuthComponentEnum.Login);
  const handleShowComponent = (pageIndex: AuthComponentEnum) => setCurrentAuthComponent(pageIndex);

  const AuthComponentArray = [
    <Login key={AuthComponentEnum.Login} handleShowComponent={handleShowComponent} />,
    <ForgotPassword key={AuthComponentEnum.ForgotPassword} handleShowComponent={handleShowComponent} />,
    <ForgotPasswordSuccess key={AuthComponentEnum.ForgotPasswordSuccess} handleShowComponent={handleShowComponent} />,
    <NewPassword key={AuthComponentEnum.Newpassword} handleShowComponent={handleShowComponent} />,
    <FirstAccess key={AuthComponentEnum.FirstAccess} handleShowComponent={handleShowComponent} />,
  ];

  useEffect(() => {
    if (newpassword) setCurrentAuthComponent(AuthComponentEnum.Newpassword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.AuthWrapper>
      <Row>
        <Col>
          <img src={logo} width={202} height={83} />
        </Col>
      </Row>
      <Row>
        <S.AuthCard>
          <S.AuthRow>
            <Col xs={0} md={11}>
              <S.AuthTitle>Bem-vindo!</S.AuthTitle>
              <S.AuthSubtitle>Utilize seus dados cadastrados para acessar a plataforma</S.AuthSubtitle>
              <S.AuthCarouselContainer>
                <CarouselNews />
              </S.AuthCarouselContainer>
            </Col>
            <Col xs={0} md={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <S.AuthDivider />
            </Col>
            <Col xs={24} md={11}>
              {AuthComponentArray[currentAuthComponent]}
            </Col>
          </S.AuthRow>
        </S.AuthCard>
      </Row>
    </S.AuthWrapper>
  );
};
