import { styled } from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1.5rem;
  align-items: center;
  width: 100%;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.625rem;
`;

export const TagProperty = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.563rem;
  color: var(--input-placeholder-color);
`;

export const TagValue = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.563rem;
  color: var(--text-label);
  margin-top: -0.1;
  overflow-wrap: break-word;
`;

export const NoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 100% !important;
  width: 100%;

  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--input-placeholder-color);
  text-align: center;
`;

export const NoTagsTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 100% !important;
  width: 100%;

  font-family: 'Mulish';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--text-main-color);
  text-align: center;
`;

export const ExportButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  gap: 0.5rem;
  color: var(--secondary-color);
`;
