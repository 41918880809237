import { CloseCircleOutlined } from '@ant-design/icons';
import { IconMore } from '@app/assets/slump-icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Modal } from '@app/components/common/Modal/Modal';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import Dashboard from '@app/components/dashboard/Dashboard';
import { UserType } from '@app/constants/enums/userType';
import { hasAccessByRole } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import { SystemParameterModel } from '@app/domain/systemParameters/systemParameterModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import ISystemParameterService, { SystemParameterService } from '@app/services/systemParameterService';
import { setHeader } from '@app/store/slices/headerSlice';
import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const parameterService: ISystemParameterService = new SystemParameterService();

export const SystemParametersDashboard: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [idParameterSelected, setIdParameterSelected] = useState<number | undefined>();
  const [parameters, setParameters] = useState<SystemParameterModel[]>([]);
  const [parametersFiltered, setParametersFiltered] = useState<SystemParameterModel[]>([]);

  const handleSearchParameter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();

      setParametersFiltered(
        parameters.filter(
          (item) =>
            item.id?.toString().toLowerCase()?.includes(inputValue) ||
            item.tipo?.toLowerCase()?.includes(inputValue) ||
            item.chave?.toLowerCase()?.includes(inputValue) ||
            item.valor?.toLowerCase()?.includes(inputValue),
        ),
      );
    } else {
      setParametersFiltered(parameters);
    }
  };
  const handleDeleteClick = () => {
    setLoading(true);

    parameterService
      .delete(`${idParameterSelected}`)
      .then(() => {
        setLoading(false);
        setModalDelete(false);
        setParameters(parameters.filter((p) => p.id != idParameterSelected));
        setIdParameterSelected(undefined);
        notificationController.success({ message: 'Sucesso!', description: 'Parâmetro excluído.' });
      })
      .catch(() => {
        setLoading(false);
        setModalDelete(false);
        notificationController.error({ message: 'Erro!', description: 'Houve um problema ao excluir o parâmetro.' });
      });
  };

  const fetch = useCallback(() => {
    setLoading(true);
    parameterService
      .getArray('')
      .then((res) => {
        setParameters(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        notificationController.error({ message: 'Erro!', description: 'Não foi possível obter os parâmetros.' });
      });
  }, []);

  useEffect(() => {
    fetch();
    dispatch(
      setHeader({
        title: 'Parâmetros do Sistema',
      }),
    );
  }, [fetch, dispatch]);
  useEffect(() => setParametersFiltered(parameters), [parameters]);

  const columns: ColumnsType<SystemParameterModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: true,
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      width: '10%',
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      showSorterTooltip: true,
      sorter: (a, b) => (a.tipo ?? '').localeCompare(b.tipo ?? ''),
      width: '15%',
    },
    {
      title: 'Chave',
      dataIndex: 'chave',
      showSorterTooltip: true,
      sorter: (a, b) => (a.chave ?? '').localeCompare(b.chave ?? ''),
      width: '30%',
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      showSorterTooltip: true,
      sorter: (a, b) => (a.valor ?? '').localeCompare(b.valor ?? ''),
      ellipsis: true,
      width: '70%',
    },
    {
      title: '',
      dataIndex: 'id',
      showSorterTooltip: true,
      defaultSortOrder: 'descend',
      width: '5%',
      render: (id) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
          }}
        >
          {hasAccessByRole(UserType.FactoryAdmin) && (
            <DropdownTable
              buttonText=""
              iconD={
                <div style={{ color: 'var(--text-label)' }}>
                  <IconMore />
                </div>
              }
              trigger={['click']}
              placement="bottomRight"
              overlay={
                <Menu>
                  <MenuItem>
                    <Button type="text" onClick={() => navigate(`editar/${id}`)}>
                      Editar
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button
                      type="text"
                      onClick={() => {
                        setModalDelete(true);
                        setIdParameterSelected(id);
                      }}
                    >
                      Deletar
                    </Button>
                  </MenuItem>
                </Menu>
              }
            ></DropdownTable>
          )}
        </div>
      ),
    },
  ];

  return (
    <Spinner spinning={loading}>
      <Modal
        title="Deletar "
        open={modalDelete}
        onOk={() => handleDeleteClick()}
        onCancel={() => setModalDelete(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <CloseCircleOutlined size={20} style={{ color: '#C41B24', marginRight: '1rem' }} />
            Tem certeza que<strong>&nbsp;deseja&nbsp;</strong>deletar o parâmetro selecionado?
          </Col>
        </Row>
      </Modal>
      <Dashboard
        buttonText={hasAccessByRole(UserType.FactoryAdmin) ? 'Cadastrar novo parâmetro' : ''}
        handleButtonClick={() => navigate('cadastrar')}
        table={<Table bordered={true} columns={columns} dataSource={parametersFiltered} loading={loading} />}
        placeholderTextSearch="Pesquisar"
        handleSearchOnChange={handleSearchParameter}
      />
    </Spinner>
  );
};
