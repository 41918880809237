import * as S from './ScheduleConfig.styles';
import { Select } from '@app/components/common/selects/Select/Select';
import React, { useState } from 'react';
import { ScheduleParams } from '@app/domain/scheduleFactory/scheduleParams';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Radio, RadioChangeEvent, RadioGroup } from '@app/components/common/Radio/Radio';
import { DatePicker } from '@app/components/common/pickers/DatePicker';
import dayjs from 'dayjs';
import { TimePicker } from '@app/components/common/pickers/TimePicker';
import { EquipmentModel } from '@app/domain/equipment/equipmentModel';
import { ModelDeviceModel } from '@app/domain/modelDevice/modelDeviceModel';
import { FirmwareVersionModel } from '@app/domain/firmwareVersion/firmwareVersionModel';
import { ReactComponent as IconAddBox } from '@app/assets/icons/add_box.svg';
import { CardTitle } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';

interface IScheduleConfig {
  scheduleParams: ScheduleParams;
  setScheduleParams: React.Dispatch<React.SetStateAction<ScheduleParams>>;
  equipmentTypesOptions: EquipmentModel[];
  modelOptions: ModelDeviceModel[];
  versionsOptions: FirmwareVersionModel[];
  setModalClientEquipment: React.Dispatch<React.SetStateAction<{ visible: boolean; title: string }>>;
}
export const ScheduleConfig: React.FC<IScheduleConfig> = ({
  scheduleParams,
  setScheduleParams,
  equipmentTypesOptions,
  modelOptions,
  versionsOptions,
  setModalClientEquipment,
}) => {
  const [modelDevice, setModelDevice] = useState<ModelDeviceModel | null>();

  return (
    <S.ContainerConfigNewDevice>
      <S.Card>
        <S.Notification>
          <InfoCircleOutlined style={{ color: 'var(--primary-color)', fontSize: '1.5rem' }} />
          <S.TextInfo>
            Os equipamentos desse agendamento serão atualizados baseados nos parâmetros selecionados.
          </S.TextInfo>
        </S.Notification>
        <CardTitle>Parâmetros da atualização</CardTitle>
        <S.TwoColumnsInputContainer>
          <S.InputWrapper>
            <S.InputLabel>Equipamento</S.InputLabel>
            <Select
              showArrow
              showSearch
              placeholder="Selecione o equipamento"
              value={scheduleParams.idEquipamento}
              onChange={(value) =>
                setScheduleParams((prevState) => ({
                  ...prevState,
                  idEquipamento: value as number,
                }))
              }
              options={equipmentTypesOptions.map((c) => ({
                value: c.id,
                label: `${c.id}: ${c.nome}`,
              }))}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <S.InputLabel>Modelo</S.InputLabel>
            <Select
              showArrow
              showSearch
              placeholder="Selecione o modelo"
              value={scheduleParams.idModelo}
              // style={{ width: '45%' }}
              onChange={(value) => {
                setScheduleParams((prevState) => ({
                  ...prevState,
                  idModelo: value as number,
                }));
                setModelDevice(modelOptions.filter((m) => m.id === (value as number))[0]);
              }}
              disabled={!scheduleParams.idEquipamento}
              options={modelOptions
                .filter((model) => model.idEquipamento === scheduleParams.idEquipamento)
                .map((model) => ({
                  value: model.id,
                  label: `${model.nome}`,
                }))}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
            />
          </S.InputWrapper>
        </S.TwoColumnsInputContainer>
        <S.InputWrapper>
          <S.InputLabel>Versão</S.InputLabel>
          <Select
            showArrow
            showSearch
            placeholder="Selecione o tipo"
            value={scheduleParams.idVersaoFirmware}
            onChange={(value) =>
              setScheduleParams((prevState) => ({
                ...prevState,
                idVersaoFirmware: value as number,
                versao: versionsOptions.find((v) => v.id === value)?.versao ?? '',
              }))
            }
            options={versionsOptions.map((c) => ({
              value: c.id,
              label: `${c.versao}`,
            }))}
            filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          />
        </S.InputWrapper>
      </S.Card>
      <S.Card>
        <CardTitle>Adicionar clientes e equipamentos</CardTitle>
        <S.AddButtonWrapper>
          <S.Button
            disabled={!scheduleParams.idEquipamento || !scheduleParams.idModelo || !scheduleParams.versao}
            onClick={() => setModalClientEquipment({ visible: true, title: 'Adicionar clientes e equipamentos' })}
            icon={<IconAddBox />}
          >
            Adicionar
          </S.Button>
        </S.AddButtonWrapper>
      </S.Card>
      <S.Card>
        <CardTitle>Programar agendamento</CardTitle>
        {modelDevice?.permiteDeltaOta && (
          <BaseFormInputItem label="Utilizar Delta OTA">
            <RadioGroup
              name="deltaOta"
              onChange={(e: RadioChangeEvent) =>
                setScheduleParams((prevState) => ({
                  ...prevState,
                  deltaOTA: e.target.value,
                }))
              }
              value={scheduleParams.deltaOTA ?? false}
              style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
            >
              <Radio value={true}>Sim</Radio>
              <Radio value={false}>Não</Radio>
            </RadioGroup>
          </BaseFormInputItem>
        )}
        <RadioGroup
          name="tipo"
          onChange={(e: RadioChangeEvent) =>
            setScheduleParams((prevState) => ({
              ...prevState,
              atualizarAgora: e.target.value,
            }))
          }
          value={scheduleParams.atualizarAgora}
          style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
        >
          <Radio value={true}>Atualizar agora</Radio>
          <Radio value={false}>Agendar</Radio>
        </RadioGroup>
        {!scheduleParams.atualizarAgora && (
          <>
            <S.TwoColumnsInputContainer>
              <S.InputWrapper>
                <S.InputLabel>Data</S.InputLabel>
                <DatePicker
                  format="DD/MM/YYYY"
                  disabledDate={(current) => {
                    const now = dayjs();

                    if (!current) {
                      return false;
                    }

                    // Disable today, the next day, and all previous days
                    return current.isBefore(now.add(2, 'day'), 'day');
                  }}
                  value={!!scheduleParams.dataHoraProgramada ? dayjs(scheduleParams.dataHoraProgramada) : undefined}
                  onChange={(value) => {
                    setScheduleParams((prevState) => ({
                      ...prevState,
                      dataHoraProgramada: value?.toISOString().substring(0, 10),
                    }));
                  }}
                />
              </S.InputWrapper>

              <S.InputWrapper>
                <S.InputLabel>Hora</S.InputLabel>
                <TimePicker
                  value={!!scheduleParams.dataHoraProgramada ? dayjs(scheduleParams.dataHoraProgramada) : undefined}
                  disabled={!scheduleParams.dataHoraProgramada}
                  showNow={false}
                  format="HH:00"
                  onChange={(value) =>
                    setScheduleParams((prevState) => ({
                      ...prevState,
                      dataHoraProgramada: value?.toISOString(),
                    }))
                  }
                />
              </S.InputWrapper>
            </S.TwoColumnsInputContainer>
          </>
        )}
      </S.Card>
    </S.ContainerConfigNewDevice>
  );
};
