import React, { useEffect, useState } from 'react';
import { DeviceProfilePropertyModel, PropertyValue } from '@app/domain/deviceProfile/deviceProfileModel';
import * as S from '../ModulesProfileConfig.styles';
import ShouldRenderInput from '@app/components/common/ModulesProfileConfig/ShouldRenderInput';
import { Form } from 'antd';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';

interface EntradaProps {
  properties: PropertyValue[];
  setProperties: React.Dispatch<React.SetStateAction<PropertyValue[]>>;
  setPropertyValues?: (data: PropertyValue[]) => void;
  moduleData: DeviceProfilePropertyModel[];
  disableAllFields?: boolean;
}

const valoresDefaultPorId: { [key: string]: string } = {
  '26': 'false',
};

const Entrada: React.FC<EntradaProps> = ({
  properties,
  setProperties,
  moduleData,
  disableAllFields = false,
  setPropertyValues,
}) => {
  const [idMapping, setIdMapping] = useState<{ [key: number]: number }>({});

  useEffect(() => {
    setProperties([
      ...properties.map((p) => {
        if (p.idEmbarcado.toString() in valoresDefaultPorId) {
          p.valorPropriedade = valoresDefaultPorId[p.idEmbarcado.toString()];
        }

        return p;
      }),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newIdMapping: { [key: number]: number } = {};
    moduleData.forEach((module) => {
      if (module.idEmbarcado && module.idPropriedade) {
        newIdMapping[module.idEmbarcado] = module.idPropriedade;
      }
    });
    setIdMapping(newIdMapping);
  }, [moduleData]);

  useEffect(() => {
    if (Object.keys(idMapping).length > 0 && properties.length === 0) {
      const newProperties = Object.keys(idMapping).map((idEmbarcado) => {
        const idStr = idEmbarcado.toString();
        const valorPadrao = idStr in valoresDefaultPorId ? valoresDefaultPorId[idStr] : '';

        return {
          idPropriedade: idMapping[Number(idEmbarcado)],
          valorPropriedade: valorPadrao,
          idEmbarcado: Number(idEmbarcado),
        };
      });
      setProperties((prevState) => [...prevState, ...newProperties]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMapping, setProperties, properties.length]);

  const handleInputChange = (idEmbarcado: number, value: string) => {
    const idPropriedade = idMapping[idEmbarcado];
    if (idPropriedade !== undefined) {
      setProperties((prevValue) => {
        const newProperties = [...prevValue];
        const index = newProperties.findIndex((property) => property.idPropriedade === idPropriedade);
        if (index !== -1) newProperties[index].valorPropriedade = value;
        if (setPropertyValues) {
          setPropertyValues(newProperties);
        }
        return newProperties;
      });
    }
  };

  const propertyValue = (idEmbarcado: number) => {
    const idPropriedade = idMapping[idEmbarcado];
    return properties.find((property) => property.idPropriedade === idPropriedade)?.valorPropriedade || '';
  };

  return (
    <Form layout="vertical">
      <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={26}>
        <Checkbox
          key={26}
          checked={propertyValue(26) === 'true'}
          disabled={disableAllFields}
          onChange={(e) => {
            const value = e.target.checked ? 'true' : 'false';
            handleInputChange(26, value);
          }}
          style={{ width: '13rem' }}
        >
          <S.CheckboxLabel>Habilitar entrada analógica</S.CheckboxLabel>
        </Checkbox>
      </ShouldRenderInput>
    </Form>
  );
};

export default Entrada;
