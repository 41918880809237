import styled from 'styled-components';

interface DeviceTypeButtonProps {
  active?: boolean;
  blocked?: boolean;
}

export const FlowTypeButton = styled.button<DeviceTypeButtonProps>`
  display: flex;
  height: 3rem;
  padding: 1rem 1rem;
  align-items: center;
  border-radius: 2.5rem;
  cursor: ${({ blocked }) => (blocked ? 'not-allowed' : 'pointer')};

  background: ${({ active }) => (active ? 'var(--brand-colors-blue-blue-500, #263668)' : 'white')};
  color: ${({ active }) => (active ? 'white' : 'black')};
  border: ${({ active }) => (active ? 'none' : '1px solid #475582')};

  &:hover {
    background: ${({ active }) => (active ? '#1a2948' : '#f8f8f8')};
    color: ${({ active }) => (active ? '#f0f0f0' : '#2d2d2d')};
    border: ${({ active }) => (active ? 'none' : '1px solid #3d5077')};
  }
`;
