import styled from 'styled-components';

export const Card = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid var(--neutral-gray-gray-300, #bababa);
  padding: 1rem;
  padding-left: 1.5;
`;

export const Title = styled.h2`
  margin-bottom: 1rem;

  color: var(--neutral-gray-gray-800, #383838);
  font-family: 'Mulish';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const TagValue = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.563rem;
  color: var(--text-label);
  margin-top: -0.1;
  overflow-wrap: break-word;
`;

export const NoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 100% !important;
  width: 100%;

  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--input-placeholder-color);
  text-align: center;
`;

export const NoTagsTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 100% !important;
  width: 100%;

  font-family: 'Mulish';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--text-main-color);
  text-align: center;
`;
