import React, { useCallback, useEffect, useState } from 'react';
import { Description } from '@app/components/common/Description/Description';
import { Col, RadioChangeEvent, Row } from 'antd';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { Button, ButtonGroup } from '@app/components/common/buttons/Button/Button';
import { notificationController } from '@app/controllers/notificationController';
import { CardInfoContainer, CardTitle } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import {
  CanVehicleModel,
  CanVehicleNameId,
  CanVehicleParametersCalculatedModel,
  CanVehicleParametersOnOffModel,
} from '@app/domain/canVehicle/canVehicle';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import { CanVehicleBitsSize, CanVehicleInitialBit } from '@app/constants/canVehicleData';
import ICanVehicleService, { CanVehicleService } from '@app/services/canVehicleService';
import MaskedInput from 'react-text-mask';

interface ICreateStepThree {
  canVehicle: CanVehicleModel;
  setCanVehicle: React.Dispatch<React.SetStateAction<CanVehicleModel>>;
}

const canVehicleService: ICanVehicleService = new CanVehicleService();

export const CreateCanVehicleStepThree: React.FC<ICreateStepThree> = (props) => {
  const { canVehicle, setCanVehicle } = props;
  const [loading, setLoading] = useState(false);
  const [parameterType, setParameterType] = useState<'Calculado' | 'OnOff'>('Calculado');
  const [nameIdSelected, setNameIdSelected] = useState<CanVehicleNameId | null>(null);
  const [measureUnit, setMeasureUnit] = useState<string>('');
  const [nameIds, setNameIds] = useState<CanVehicleNameId[]>([]);
  const [calculated, setCalculated] = useState<CanVehicleParametersCalculatedModel>(
    {} as CanVehicleParametersCalculatedModel,
  );
  const [onOff, setOnOff] = useState<CanVehicleParametersOnOffModel>({} as CanVehicleParametersOnOffModel);

  const fetchCanVehiclesNameId = useCallback(async () => {
    try {
      setLoading(true);
      const response = await canVehicleService.getCanVehicleNameId();
      setNameIds(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notificationController.error({
        message: 'Erro!',
        description: 'Houve um problema ao buscar a lista de fabricantes dos veículos CAN',
      });
    }
  }, []);

  const handleAddParameters = async () => {
    if (parameterType === 'Calculado') {
      const index = canVehicle.parametroCalculado.findIndex((p) => p.nomeCAN === nameIdSelected?.nome);

      if (index > -1) {
        notificationController.warning({
          message: `Não é possível adicionar dois nomeIds iguais. O parâmetro ${nameIdSelected?.nome} já existe na lista.`,
        });
        return;
      }

      setCanVehicle({
        ...canVehicle,
        parametroCalculado: [
          ...canVehicle.parametroCalculado,
          {
            ...calculated,
            nomeId: nameIdSelected?.id ?? 0,
            unidadeMedida: measureUnit,
            idVeiculoCAN: canVehicle.id ?? 0,
            nomeCAN: nameIdSelected?.nome ?? '',
          },
        ],
      });
      setCalculated({} as CanVehicleParametersCalculatedModel);
      setNameIdSelected(null);
    } else {
      const index = canVehicle.parametroLigadoDesligado.findIndex((p) => p.nomeCAN === nameIdSelected?.nome);

      if (index > -1) {
        notificationController.warning({
          message: `Não é possível adicionar dois nomeIds iguais. O parâmetro ${nameIdSelected?.nome} já existe na lista.`,
        });
        return;
      }

      setCanVehicle({
        ...canVehicle,
        parametroLigadoDesligado: [
          ...canVehicle.parametroLigadoDesligado,
          {
            ...onOff,
            nomeId: nameIdSelected?.id ?? 0,
            unidadeMedida: measureUnit,
            idVeiculoCAN: canVehicle.id ?? 0,
            nomeCAN: nameIdSelected?.nome ?? '',
          },
        ],
      });
      setOnOff({} as CanVehicleParametersOnOffModel);
      setNameIdSelected(null);
    }
  };

  useEffect(() => {
    fetchCanVehiclesNameId();
  }, [fetchCanVehiclesNameId]);

  useEffect(() => {
    if (nameIdSelected?.unidadeMedida !== undefined && nameIdSelected.unidadeMedida.length > 0) {
      setMeasureUnit(nameIdSelected.unidadeMedida.split(';')[0]);
      setParameterType('Calculado');
    } else {
      setMeasureUnit('');
      setParameterType('OnOff');
    }
  }, [nameIdSelected]);

  return (
    <>
      <Spinner spinning={loading}></Spinner>
      <Description title="Etapa 3 de 3" subtitle="Após preencher os campos, clique em finalizar." />
      <CardInfoContainer>
        <Row justify={'start'} style={{ width: '100%', marginBottom: '1rem' }}>
          <CardTitle>Parâmetros do veículo</CardTitle>
        </Row>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Row gutter={6}>
            <Col xs={12}>
              <BaseFormInputItem label="Nome ID" errorText="Campo obrigatório">
                <Select
                  showArrow
                  showSearch
                  placeholder="Selecione o tipo"
                  value={nameIdSelected?.nome}
                  onChange={(value) =>
                    setNameIdSelected(nameIds.find((n) => n.id == value) ?? ({} as CanVehicleNameId))
                  }
                  options={nameIds.map((c) => ({
                    value: c.id,
                    label: `${c.nome}`,
                  }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </BaseFormInputItem>
            </Col>
            {nameIdSelected?.unidadeMedida !== undefined && (nameIdSelected?.unidadeMedida.length ?? 0) > 0 && (
              <Col xs={12}>
                <BaseFormInputItem label=" " errorText="">
                  <ButtonGroup>
                    {nameIdSelected?.unidadeMedida !== undefined &&
                      nameIdSelected?.unidadeMedida?.split(';').map((u) => (
                        <Button
                          key={u}
                          type={measureUnit.length > 0 && measureUnit === u ? 'primary' : 'default'}
                          onClick={() => setMeasureUnit(u)}
                        >
                          {u}
                        </Button>
                      ))}
                  </ButtonGroup>
                </BaseFormInputItem>
              </Col>
            )}
          </Row>
          {nameIdSelected !== null && (
            <Row gutter={6}>
              <Col xs={24} md={12}>
                <BaseFormInputItem label="Tipo de dado">
                  <RadioGroup
                    name="parameterType"
                    disabled={true}
                    onChange={(e: RadioChangeEvent) => setParameterType(e.target.value)}
                    value={parameterType}
                    style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                  >
                    <Radio value="Calculado">Calculado</Radio>
                    <Radio value="OnOff">ON/OFF</Radio>
                  </RadioGroup>
                </BaseFormInputItem>
              </Col>
            </Row>
          )}
          {nameIdSelected !== null && parameterType === 'Calculado' && (
            <>
              <Row gutter={6}>
                <Col xs={6}>
                  <BaseFormInputItem label="ID" errorText="Campo obrigatório">
                    <MaskedInput
                      className="ant-input"
                      mask={() => Array(24).fill(/[0-9]/)}
                      guide={false}
                      type="text"
                      key={1}
                      placeholder="Digite o ID"
                      value={calculated.parametroId}
                      onChange={(event) => {
                        setCalculated({
                          ...calculated,
                          parametroId: event.target.value,
                        });
                      }}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={9}>
                  <BaseFormInputItem label="Offset" errorText="Campo obrigatório">
                    <MaskedInput
                      className="ant-input"
                      mask={() => Array(24).fill(/[0-9.-]/)}
                      guide={false}
                      type="text"
                      key={2}
                      placeholder="Digite o offset"
                      value={calculated.offset}
                      onChange={(event) => {
                        setCalculated({
                          ...calculated,
                          offset: event.target.value,
                        });
                      }}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={9}>
                  <BaseFormInputItem label="Resolução" errorText="Campo obrigatório">
                    <MaskedInput
                      className="ant-input"
                      mask={() => Array(24).fill(/[0-9.-]/)}
                      guide={false}
                      type="text"
                      key={3}
                      placeholder="Digite a resolução"
                      value={calculated.resolucao}
                      onChange={(event) => {
                        setCalculated({
                          ...calculated,
                          resolucao: event.target.value,
                        });
                      }}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
              <Row gutter={6}>
                <Col xs={12}>
                  <BaseFormInputItem label="Bit inicial" errorText="Campo obrigatório">
                    <Select
                      showArrow
                      showSearch
                      placeholder="0 a 63"
                      value={calculated.bitInicial}
                      onChange={(value) => setCalculated({ ...calculated, bitInicial: Number(value as string) })}
                      options={CanVehicleInitialBit.map((c) => ({
                        value: c,
                        label: `${c}`,
                      }))}
                      filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={12}>
                  <BaseFormInputItem label="Tamanho em bits" errorText="Campo obrigatório">
                    <Select
                      showArrow
                      showSearch
                      placeholder="1 a 63"
                      value={calculated.tamanhoBits}
                      onChange={(value) => setCalculated({ ...calculated, tamanhoBits: Number(value as string) })}
                      options={CanVehicleBitsSize.map((c) => ({
                        value: c,
                        label: `${c}`,
                      }))}
                      filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </>
          )}
          {nameIdSelected !== null && parameterType === 'OnOff' && (
            <>
              <Row gutter={6}>
                <Col xs={6}>
                  <BaseFormInputItem label="ID" errorText="Campo obrigatório">
                    <MaskedInput
                      className="ant-input"
                      mask={() => Array(24).fill(/[0-9]/)}
                      guide={false}
                      type="text"
                      key={1}
                      placeholder="Digite o ID"
                      value={onOff.parametroId}
                      onChange={(event) => {
                        setOnOff({
                          ...onOff,
                          parametroId: event.target.value,
                        });
                      }}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={9}>
                  <BaseFormInputItem label="Bit inicial" errorText="Campo obrigatório">
                    <Select
                      showArrow
                      showSearch
                      placeholder="0 a 63"
                      value={onOff.bitInicial}
                      onChange={(value) => setOnOff({ ...onOff, bitInicial: Number(value as string) })}
                      options={CanVehicleInitialBit.map((c) => ({
                        value: c,
                        label: `${c}`,
                      }))}
                      filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={9}>
                  <BaseFormInputItem label="Tamanho em bits" errorText="Campo obrigatório">
                    <Select
                      showArrow
                      showSearch
                      placeholder="1 a 63"
                      value={onOff.tamanhoBits}
                      onChange={(value) => setOnOff({ ...onOff, tamanhoBits: Number(value as string) })}
                      options={CanVehicleBitsSize.map((c) => ({
                        value: c,
                        label: `${c}`,
                      }))}
                      filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
              <Row gutter={6}>
                <Col xs={6}>
                  <BaseFormInputItem label="Ligado" errorText="Campo obrigatório">
                    <RadioGroup
                      name="ligado"
                      onChange={(e: RadioChangeEvent) =>
                        setOnOff((prevState) => ({
                          ...prevState,
                          ligado: e.target.value ? 1 : 0,
                          desligado: e.target.value ? 0 : 1,
                        }))
                      }
                      value={onOff.ligado === 1 ? true : false}
                      style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                    >
                      <Radio value={true}>1</Radio>
                      <Radio value={false}>0</Radio>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
                <Col xs={6}>
                  <BaseFormInputItem label="Desligado" errorText="Campo obrigatório">
                    <RadioGroup
                      name="desligado"
                      onChange={(e: RadioChangeEvent) =>
                        setOnOff((prevState) => ({
                          ...prevState,
                          desligado: e.target.value ? 1 : 0,
                          ligado: e.target.value ? 0 : 1,
                        }))
                      }
                      value={onOff.desligado === 1 ? true : false}
                      style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                    >
                      <Radio value={true}>1</Radio>
                      <Radio value={false}>0</Radio>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
              </Row>
            </>
          )}
        </BaseForm>
      </CardInfoContainer>
      <Row style={{ height: '100%' }} align={'top'} justify={'end'}>
        <Col xs={24} sm={12} md={6}>
          <Button
            block
            type="primary"
            onClick={handleAddParameters}
            disabled={
              !(
                nameIdSelected !== null &&
                (parameterType === 'Calculado'
                  ? calculated.bitInicial !== undefined &&
                    calculated.tamanhoBits !== undefined &&
                    calculated.offset !== undefined &&
                    calculated.parametroId !== undefined &&
                    calculated.resolucao !== undefined
                  : onOff.bitInicial !== undefined &&
                    onOff.tamanhoBits !== undefined &&
                    onOff.desligado !== undefined &&
                    onOff.ligado !== undefined &&
                    onOff.parametroId !== undefined)
              )
            }
          >
            Adicionar
          </Button>
        </Col>
      </Row>
    </>
  );
};
