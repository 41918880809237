/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col, Row } from 'antd';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType } from 'antd/lib/table/Table';
import * as S from './viewDevice.styles';
import { ViewConfigUpdateHistoryGroupedByModuleModel } from '@app/domain/device/viewConfigUpdateHistoryModel';
import { ViewDeviceModules } from '@app/constants/enums/viewDevice/viewDeviceModules';
import moment from 'moment';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { useNavigate } from 'react-router-dom';
import ArrowRightOutlined from '@ant-design/icons/lib/icons/ArrowRightOutlined';
import { Tag } from '@app/components/common/Tag/Tag';
import { ViewDeviceModuleStatus } from '@app/constants/enums/viewDevice/viewDeviceModuleStatus';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setViewDeviceConfigUpdate } from '@app/store/slices/viewDeviceSlice';

interface IViewFirmwareUpdateHistory {
  updateConfigHistory: ViewConfigUpdateHistoryGroupedByModuleModel[];
}

export const ViewConfigUpdateHistory: React.FC<IViewFirmwareUpdateHistory> = ({ updateConfigHistory }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const columns: ColumnsType<ViewConfigUpdateHistoryGroupedByModuleModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: false,
      width: '3%',
    },
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      width: '13%',
      render: (_, updateConfig) =>
        updateConfig.dataHora ? moment(updateConfig.dataHora).format('DD/MM/yyyy HH:mm') : '-',
    },
    {
      title: 'Usuário',
      dataIndex: 'usuario',
      showSorterTooltip: false,
      width: '10%',
    },
    {
      title: 'Rede',
      dataIndex: 'idModulo',
      showSorterTooltip: false,
      width: '12%',
      render: (_, updateConfig) => getUpdateConfigModule(updateConfig.id ?? 0, ViewDeviceModules.Rede),
    },
    {
      title: 'Entrada',
      dataIndex: 'idModulo',
      showSorterTooltip: false,
      width: '12%',
      render: (_, updateConfig) => getUpdateConfigModule(updateConfig.id ?? 0, ViewDeviceModules.Entrada),
    },
    {
      title: 'Perfil de envio',
      dataIndex: 'idModulo',
      showSorterTooltip: false,
      width: '12%',
      render: (_, updateConfig) => getUpdateConfigModule(updateConfig.id ?? 0, ViewDeviceModules.Perfil),
    },
    {
      title: 'Eventos',
      dataIndex: 'idModulo',
      showSorterTooltip: false,
      width: '12%',
      render: (_, updateConfig) => getUpdateConfigModule(updateConfig.id ?? 0, ViewDeviceModules.Eventos),
    },
    {
      title: 'Veículos CAN',
      dataIndex: 'idModulo',
      showSorterTooltip: false,
      width: '12%',
      render: (_, updateConfig) => getUpdateConfigModule(updateConfig.id ?? 0, ViewDeviceModules.CAN),
    },
    {
      title: 'Cercas',
      dataIndex: 'idModulo',
      showSorterTooltip: false,
      width: '12%',
      render: (_, updateConfig) => getUpdateConfigModule(updateConfig.id ?? 0, ViewDeviceModules.Cercas),
    },
    {
      title: '',
      dataIndex: 'id',
      width: '1%',
      showSorterTooltip: false,
      sortDirections: [],
      defaultSortOrder: 'descend',
      render: (_, updateConfig) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <Menu style={{ backgroundColor: 'transparent' }}>
              <MenuItem
                key="edit"
                onClick={() => {
                  navigate(`parametros-atualizados/${updateConfig.id}`);
                }}
              >
                <ArrowRightOutlined />
              </MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ];

  const getUpdateConfigModule = (id: number, modulo: string) => {
    const updateConfig = updateConfigHistory.find((u) => u.id === id);

    if (updateConfig === undefined) {
      return '-';
    }

    const moduleExists = updateConfig.modulos?.find((m) => m.modulo === modulo);

    if (moduleExists === undefined) {
      return (
        <Tag color="#E8E8E8" style={{ color: '#383838', width: 'auto', textAlign: 'center' }}>
          {ViewDeviceModuleStatus.SemAlteracao}
        </Tag>
      );
    }

    switch (moduleExists.status) {
      case ViewDeviceModuleStatus.Atualizado:
        return (
          <Tag color="#E9F4EE" style={{ color: '#083F18', width: 'auto', textAlign: 'center' }}>
            {ViewDeviceModuleStatus.Atualizado}
          </Tag>
        );
        break;
      case ViewDeviceModuleStatus.Pendente:
        return (
          <Tag color="#FEF7E7" style={{ color: '#644508', width: 'auto', textAlign: 'center' }}>
            {ViewDeviceModuleStatus.Pendente}
          </Tag>
        );
        break;
      case ViewDeviceModuleStatus.Expirado:
        return (
          <Tag color="#F4EAFF" style={{ color: '#52025F', width: 'auto', textAlign: 'center' }}>
            {ViewDeviceModuleStatus.Expirado}
          </Tag>
        );
        break;
      default:
        return (
          <Tag color="#E8E8E8" style={{ color: '#383838', width: 'auto', textAlign: 'center' }}>
            {ViewDeviceModuleStatus.SemAlteracao}
          </Tag>
        );
        break;
    }
  };

  return (
    <>
      {updateConfigHistory.length > 0 ? (
        <S.Container>
          <h1>Histórico</h1>
          <Table columns={columns} dataSource={updateConfigHistory} scroll={{ x: 800 }} bordered />
        </S.Container>
      ) : (
        <Row justify="center" align="middle">
          <Col xs={24} md={24} style={{ color: '#707070', fontSize: '1rem', fontWeight: 700, textAlign: 'center' }}>
            Nenhuma atualização de parâmetros do perfil realizada
          </Col>
        </Row>
      )}
    </>
  );
};
