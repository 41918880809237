import { DeviceBlockModel } from '@app/domain/deviceBlock/device-block-model';
import { ApiServiceBase, IApiServiceBase } from './base/apiServiceBase';

export default interface IDeviceBlockService extends IApiServiceBase<DeviceBlockModel, DeviceBlockModel> {
  Aprovar(id: number, aprovado: boolean): Promise<void>;
  Reenviar(id: number): Promise<void>;
  Desbloquear(id: number): Promise<void>;
}

export class DeviceBlockService extends ApiServiceBase<DeviceBlockModel, DeviceBlockModel> {
  constructor() {
    super('Bloqueio');
  }

  async Aprovar(id: number, aprovado: boolean): Promise<void> {
    await this.api.post(`${id}/aprovar/${aprovado}`);
  }
  async Reenviar(id: number): Promise<void> {
    await this.api.post(`${id}/reenviar`);
  }
  async Desbloquear(id: number): Promise<void> {
    await this.api.post(`${id}/desbloquear`);
  }
}
