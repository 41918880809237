import { FenceEventPropertyModel, FenceModel } from '@app/domain/fence/fenceModel';

export const propertyFenceValueToEventos = (value: string): FenceEventPropertyModel => {
  const parts = value.split(',');

  // Removendo 's;31;' e o ponto e vírgula final
  parts.shift();
  parts.pop();

  const eventos = {
    entradaSaida: 0,
    entradaSaidaPeriferico: 0,
    entradaSaidaCiclos: 0,
    entradaSaidaTempoLigado: 0,
    entradaSaidaTempoDesligado: 0,

    limiteVelocidade: 0,
    limiteVelocidadeValor: 0,
    limiteVelocidadePulsado: 0,
    limiteVelocidadeCiclos: 0,
    limiteVelocidadeTempoLigado: 0,
    limiteVelocidadeTempoDesligado: 0,
  };

  eventos.entradaSaidaPeriferico = Number(parts[0]);
  eventos.entradaSaidaCiclos = Number(parts[1]);
  eventos.entradaSaidaTempoLigado = Number(parts[2]);
  eventos.entradaSaidaTempoDesligado = Number(parts[3]);

  if (parts[0] === '0') {
    eventos.entradaSaidaPeriferico = Number(parts[4]);
    eventos.entradaSaidaCiclos = Number(parts[5]);
    eventos.entradaSaidaTempoLigado = Number(parts[6]);
    eventos.entradaSaidaTempoDesligado = Number(parts[7]);
  }

  if (parts[0] !== '0' && parts[4] !== '0') eventos.entradaSaida = 3; // entrada e saída
  if (parts[0] === '0' && parts[4] === '0') eventos.entradaSaida = 0; // desabilitado - Não se aplica
  if (parts[0] !== '0' && parts[4] === '0') eventos.entradaSaida = 1; // entrada
  if (parts[0] === '0' && parts[4] !== '0') eventos.entradaSaida = 2; // saída

  if (parts[8] === '1') eventos.limiteVelocidade = 1;
  if (parts[9] !== '0') eventos.limiteVelocidadeValor = Number(parts[9]);
  if (parts[10] !== '0' || parts[10] !== '0' || parts[11] !== '0') eventos.limiteVelocidadePulsado = 1;
  if (parts[10] !== '0') eventos.limiteVelocidadeCiclos = Number(parts[10]);
  if (parts[11] !== '0') eventos.limiteVelocidadeTempoLigado = Number(parts[11]);
  if (parts[12] !== '0') eventos.limiteVelocidadeTempoDesligado = Number(parts[12]);

  return eventos;
};

export const mountFenceProperyValue = (fence: FenceModel) => {
  // o nome da cerca, com tamanho máximo de 15 caracteres;
  // let value = fence.nome + ','; retirado, pois agora no lugar do nome é enviado o idCercaEmbarcado que é gerado após a inclusao da cerca
  let value = '';

  // o evento de entrada na cerca, com valor 0, 1 ou 2, sendo 0 desabilitado, 1 habilitado com a possibilidade de uma saída pulsada e 2 habilitado com o corta corrente
  value +=
    (fence.eventos?.entradaSaida === 1 || fence.eventos?.entradaSaida === 3) &&
    fence.eventos.entradaSaidaPeriferico === 2
      ? '2,'
      : (fence.eventos?.entradaSaida === 1 || fence.eventos?.entradaSaida === 3) &&
        fence.eventos.entradaSaidaPeriferico === 1
      ? '1,'
      : '0,';

  //saída pulsada evento de entrada, número de repetições (parâmetro opcional, se não for ser usado ou se for selecionado corta corrente mandar valor zero);
  value +=
    (fence.eventos?.entradaSaida === 1 || fence.eventos?.entradaSaida === 3) &&
    fence.eventos?.entradaSaidaPeriferico === 1
      ? `${fence.eventos?.entradaSaidaCiclos},`
      : '0,';

  // saída pulsada evento de entrada, tempo ligado em milisegundos (parâmetro opcional, se não for ser usado ou se for selecionado corta corrente mandar valor zero);
  value +=
    (fence.eventos?.entradaSaida === 1 || fence.eventos?.entradaSaida === 3) &&
    fence.eventos?.entradaSaidaPeriferico === 1
      ? `${fence.eventos?.entradaSaidaTempoLigado},`
      : '0,';

  // saída pulsada evento de entrada, tempo desligado em milisegundos (parâmetro opcional, se não for ser usado ou se for selecionado corta corrente mandar valor zero);
  value +=
    (fence.eventos?.entradaSaida === 1 || fence.eventos?.entradaSaida === 3) &&
    fence.eventos?.entradaSaidaPeriferico === 1
      ? `${fence.eventos?.entradaSaidaTempoDesligado},`
      : '0,';

  // o evento de saída da cerca, com valor 0, 1 ou 2, sendo 0 desabilitado, 1 habilitado com a possibilidade de uma saída pulsada, 2 habilitado com o corta corrente;
  value +=
    (fence.eventos?.entradaSaida === 2 || fence.eventos?.entradaSaida === 3) &&
    fence.eventos.entradaSaidaPeriferico === 2
      ? '2,'
      : (fence.eventos?.entradaSaida === 2 || fence.eventos?.entradaSaida === 3) &&
        fence.eventos.entradaSaidaPeriferico === 1
      ? `1,`
      : '0,';

  // saída pulsada evento de saída, número de repetições (parâmetro opcional, se não for ser usado ou se for selecionado corta corrente mandar valor zero);
  value +=
    (fence.eventos?.entradaSaida === 2 || fence.eventos?.entradaSaida === 3) &&
    fence.eventos?.entradaSaidaPeriferico === 1
      ? `${fence.eventos?.entradaSaidaCiclos},`
      : '0,';

  // saída pulsada evento de saída, tempo ligado em milisegundos (parâmetro opcional, se não for ser usado ou se for selecionado corta corrente mandar valor zero);
  value +=
    (fence.eventos?.entradaSaida === 2 || fence.eventos?.entradaSaida === 3) &&
    fence.eventos?.entradaSaidaPeriferico === 1
      ? `${fence.eventos?.entradaSaidaTempoLigado},`
      : '0,';

  // saída pulsada evento de saída, tempo desligado em milisegundos (parâmetro opcional, se não for ser usado ou se for selecionado corta corrente mandar valor zero);
  value +=
    (fence.eventos?.entradaSaida === 2 || fence.eventos?.entradaSaida === 3) &&
    fence.eventos?.entradaSaidaPeriferico === 1
      ? `${fence.eventos?.entradaSaidaTempoDesligado},`
      : '0,';

  // o evento de limite de velocidade dentro da cerca, com valor 0 ou 1, sendo 0 desabilitado e 1 habilitado;
  value += fence.eventos?.limiteVelocidade ? '1,' : '0,';

  // limite de velocidade (parâmetro opcional se não for ser usado mandar valor zero);
  value += fence.eventos?.limiteVelocidadeValor ? `${fence.eventos?.limiteVelocidadeValor},` : '0,';

  // saída pulsada evento de velocidade, número de repetições (parâmetro opcional se não for ser usado mandar valor zero);
  value += fence.eventos?.limiteVelocidadePulsado ? `${fence.eventos?.limiteVelocidadeCiclos},` : '0,';

  // saída pulsada evento de velocidade, tempo ligado em milisegundos (parâmetro opcional se não for ser usado mandar valor zero);
  value += fence.eventos?.limiteVelocidadePulsado ? `${fence.eventos?.limiteVelocidadeTempoLigado},` : '0,';

  // saída pulsada evento de velociade, tempo desligado em milisegundos (parâmetro opcional se não for ser usado mandar valor zero);
  value += fence.eventos?.limiteVelocidadePulsado ? `${fence.eventos?.limiteVelocidadeTempoDesligado},` : '0,';

  // a borda da cerca, caso seja uma cerca polígono (geográfica) deverá ter valor igual a zero;
  if (fence.idTipoCerca === 1) value += '0,';
  if (fence.idTipoCerca === 2) value += `${fence.raio},`;
  if (fence.idTipoCerca === 3) value += `${fence.largura},`;

  // o número de coordenadas dessa cerca, no máximo 300;
  value += `${fence.pontos?.length * 2},`;

  // as coordenadas
  value += fence.pontos?.map((p) => `${p.lat.toFixed(5)},${p.lng.toFixed(5)}`).join(',');

  return value;
};

export const getFenceNameByType = (idTipoCerca: number) => {
  switch (idTipoCerca) {
    case 1:
      return 'Polígono';
    case 2:
      return 'Ponto e raio';
    case 3:
      return 'Rota';
    default:
      return 'Polígono';
  }
};
