import { Collapse } from '@app/components/common/Collapse/Collapse';
import { Col, Row } from 'antd';
import * as S from './TableData.styles';

interface ITableCollapsible {
  key: string;
  header: JSX.Element;
  data?: string[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TableData: React.FC<ITableCollapsible> = ({ key, header, data }) => {
  return (
    <>
      <Collapse defaultActiveKey={['1']}>
        <S.TableHeader header={header} key={'1'} showArrow={false} collapsible="disabled">
          <Row gutter={[18, 18]} style={{ padding: '1rem' }}>
            <Row gutter={[18, 18]} style={{ overflowY: 'auto', maxHeight: '30rem', width: '100%' }}>
              {data?.map((item, index) => {
                return (
                  <Col key={index} xs={12} xl={6}>
                    {item}
                  </Col>
                );
              })}
            </Row>
          </Row>
        </S.TableHeader>
      </Collapse>
    </>
  );
};
