import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import { Button } from '@app/components/common/buttons/Button/Button';
import Dashboard from '@app/components/dashboard/Dashboard';
import { notificationController } from '@app/controllers/notificationController';
import { LabelModel } from '@app/domain/label/labelModel';
import { LabelService } from '@app/services/labelService';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import { useDispatch } from 'react-redux';
import { setHeader } from '@app/store/slices/headerSlice';
import { useNavigate } from 'react-router-dom';
import { useDownloadLabels } from './components/LabelGenerator';
import { hasAccessByRoles } from '@app/controllers/accessController';
import { UserType } from '@app/constants/enums/userType';
import DropdownTable from '@app/components/common/DropdownTable/DropdownTable';
import IconMore from '@app/assets/slump-icons/IconMore';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';

const labelService = new LabelService();

export const LabelDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleDownloadSerialNumbersPdf, handleDownloadSerialNumbersExcel } = useDownloadLabels();
  const [loading, setLoading] = useState(true);
  const [spinningText, setSpinningText] = useState('Carregando...');
  const [labelsList, setLabelsList] = useState<LabelModel[]>([]);
  const [filteredData, setFilteredData] = useState<LabelModel[]>([]);

  const handleDownloadSerialNumbersPdfClick = (label: LabelModel) => {
    setLoading(true);
    setSpinningText('Fazendo o download das etiquetas. Aguarde por favor...');

    setTimeout(() => {
      handleDownloadSerialNumbersPdf(label)
        .catch((error) => {
          console.error(error.message);
          notificationController.error({
            message: 'Erro ao gerar etiquetas',
            description: `${error.message || ''}`,
          });
        })
        .finally(() => {
          setLoading(false);
          setSpinningText('Carregando...');
        });
    }, 1000);
  };

  const handleDownloadSerialNumbersExcelClick = async (label: LabelModel) => {
    setLoading(true);
    setSpinningText('Fazendo o download das etiquetas. Aguarde por favor...');

    setTimeout(() => {
      handleDownloadSerialNumbersExcel(label)
        .catch((error) => {
          console.error(error);
          notificationController.error({
            message: 'Erro ao gerar etiquetas',
            description: `${error.message || ''}`,
          });
        })
        .finally(() => {
          setLoading(false);
          setSpinningText('Carregando...');
        });
    }, 1000);
  };

  const columns: ColumnsType<LabelModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: true,
      sorter: (a: LabelModel, b: LabelModel) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
      defaultSortOrder: 'descend',
      ellipsis: true,
      width: '87px',
    },
    {
      title: 'Equipamento',
      dataIndex: 'equipamento',
      showSorterTooltip: true,
      width: '150px',
      sorter: (a, b) => (a.equipamento && b.equipamento ? a.equipamento.localeCompare(b.equipamento) : 0),
      ellipsis: true,
    },
    {
      title: 'Modelo',
      dataIndex: 'modelo',
      showSorterTooltip: true,
      width: '100px',
      sorter: (a, b) => (a.modelo && b.modelo ? a.modelo.localeCompare(b.modelo) : 0),
      ellipsis: true,
    },
    {
      title: 'SN',
      dataIndex: 'faixaSerial',
      showSorterTooltip: true,
      sorter: (a, b) => (a.primeiroSerial && b.primeiroSerial ? a.primeiroSerial.localeCompare(b.primeiroSerial) : 0),
      ellipsis: false,
      width: '360px',
    },
    {
      title: 'Lote',
      dataIndex: 'lote',
      showSorterTooltip: true,
      width: '120px',
      sorter: (a, b) => (a.lote && b.lote ? a.lote.localeCompare(b.lote) : 0),
      ellipsis: true,
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantidade',
      showSorterTooltip: true,
      sorter: (a, b) => (a.quantidade && b.quantidade ? a.quantidade - b.quantidade : 0),
      ellipsis: true,
      width: '120px',
    },
    {
      title: '',
      dataIndex: 'id',
      width: '10%',
      showSorterTooltip: false,
      render: (_, label) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <DropdownTable
              buttonText=""
              iconD={
                <div style={{ color: '#545454' }}>
                  <IconMore />
                </div>
              }
              trigger={['click']}
              placement="bottomRight"
              overlay={
                <Menu>
                  <MenuItem key="download" onClick={() => handleDownloadSerialNumbersPdfClick(label)}>
                    <Button type="text">Exportar números (PDF)</Button>
                  </MenuItem>
                  <MenuItem key="download-zip" onClick={() => handleDownloadSerialNumbersExcelClick(label)}>
                    <Button type="text">Exportar números (Excel)</Button>
                  </MenuItem>
                </Menu>
              }
            />
          </div>
        );
      },
    },
  ];

  const sanitizeInput = useCallback((input: string) => {
    return input.toLowerCase().replaceAll('.', '').replace('/', '').trim() ?? '';
  }, []);

  const handleSearchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();

      setFilteredData(
        labelsList.filter((item) => {
          return (
            sanitizeInput(item.faixaSerial ?? '').includes(sanitizeInput(inputValue)) ||
            sanitizeInput(item.equipamento ?? '').includes(sanitizeInput(inputValue)) ||
            sanitizeInput(item.modelo ?? '').includes(sanitizeInput(inputValue)) ||
            (item?.id?.toString() || '').includes(sanitizeInput(inputValue)) ||
            item.lote.toLowerCase().includes(inputValue) ||
            item.quantidade.toString().includes(inputValue)
          );
        }),
      );
    } else {
      setFilteredData(labelsList);
    }
  };

  const fetchData = () => {
    setLoading(true);
    labelService
      .getArray('')
      .then((response) => {
        setLabelsList(response);
        setFilteredData(response);
      })
      .catch(() => {
        notificationController.error({
          message: 'Erro ao buscar etiquetas',
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    dispatch(
      setHeader({
        title: 'Etiquetas',
      }),
    );
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewLabelClick = () => {
    navigate('/etiquetas/cadastrar');
  };

  const handleExportToExcel = () => {
    genericExportToExcel('registro_lotes', columns, filteredData ?? []);
  };

  return (
    <>
      <PageTitle>Etiquetas</PageTitle>
      <Spinner spinning={loading} text={spinningText}>
        <Dashboard
          title=""
          buttonText={hasAccessByRoles([UserType.FactoryAdmin]) ? 'Gerar novo lote' : ''}
          handleButtonClick={handleNewLabelClick}
          placeholderTextSearch="Pesquisar"
          handleSearchOnChange={handleSearchFilter}
          table={<Table columns={columns} dataSource={filteredData} bordered={true} />}
          exportToExcel={handleExportToExcel}
        />
      </Spinner>
    </>
  );
};
