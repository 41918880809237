import { notificationController } from '@app/controllers/notificationController';
import { ConfigUpdateModel } from '@app/domain/deviceConfigUpdate/deviceConfigUpdate';
import { addData, createWorkbook, saveToFile, setColumnWidths, setHeaders } from '@app/utils/exportToExcel';
import moment from 'moment';

const getMaxColumnWidth = (headers: string[], scheduleRow: string[]) => {
  const firstColumnWidth = 24;

  const maxColumnWidths = headers.map((header, index) => {
    if (index === 0) return firstColumnWidth;
    return Math.max(header.length, (scheduleRow[index]?.toString() || '').length);
  });

  return maxColumnWidths.map((width) => width + 4);
};

export const exportDeviceConfigUpdateToExcel = async (configUpdate: ConfigUpdateModel) => {
  if (!configUpdate || !configUpdate.dispositivos || !configUpdate.dispositivos.length) {
    notificationController.error({
      message: 'Erro ao exportar para Excel: Dados vazios.',
    });
    return;
  }
  const { workbook, worksheet } = createWorkbook('AtualizacaoConfiguracao');

  const headers = ['Equipamento', 'Modelo', 'Status', 'Data/Hora', 'Usuário Responsável'];
  setHeaders(worksheet, headers);

  const scheduleRow = [
    configUpdate.tipoEquipamento,
    configUpdate.modelo,
    configUpdate.status,
    moment(configUpdate.dataHora).format('DD/MM/YYYY HH:mm') + 'h',
    configUpdate.usuario,
  ];
  addData(worksheet, [scheduleRow]);

  addData(worksheet, [['Equipamentos:']]);
  configUpdate.dispositivos.forEach((device) => {
    const equipmentRow = [
      device.numeroSerie,
      configUpdate.status === 'Cancelado'
        ? 'Cancelado'
        : device.propriedadesAtualizadas.map((d) => d.atualizado)
        ? 'Atualizado'
        : 'Pendente',
    ];
    addData(worksheet, [equipmentRow]);
  }); // erro aqui

  const computedMaxColumnWidths = getMaxColumnWidth(headers, scheduleRow);
  const columnWidths = headers.map((header, index) => ({
    key: header,
    width: computedMaxColumnWidths[index],
  }));
  setColumnWidths(worksheet, columnWidths);

  await saveToFile(
    workbook,
    `atualizacao-configuracoes-dispositivos-${moment(new Date()).format('DD-MM-YYYY-HH-mm-ss')}`,
  );
};
