import {
  DeviceLogDetailedClientModel,
  DeviceLogModel,
  DeviceLogResumeModel,
  DeviceLogSendEmailModel,
} from '@app/domain/deviceLog/DeviceLogModel';
import { ApiServiceBase, IApiServiceBase } from './base/apiServiceBase';

export default interface IDeviceLogService extends IApiServiceBase<DeviceLogModel, DeviceLogModel> {
  getDeviceLogResumeAdmin(): Promise<DeviceLogResumeModel[]>;
  getDeviceLogDetailedByIdClientOrIdGroupAdmin(id: number): Promise<DeviceLogDetailedClientModel>;
  sendEmailDeviceLog(sendEmail: DeviceLogSendEmailModel, file: File): Promise<void>;

  // CLIENTE
  getDeviceLogResume(): Promise<DeviceLogResumeModel[]>;
  getDeviceLogDetailedByIdClientOrIdGroup(id: number): Promise<DeviceLogDetailedClientModel>;
}

export class DeviceLogService extends ApiServiceBase<DeviceLogModel, DeviceLogModel> {
  constructor() {
    super('LogDispositivo');
  }

  async getDeviceLogResumeAdmin(): Promise<DeviceLogResumeModel[]> {
    return (await this.api.get('obter-log-dispositivo-resumo-admin'))?.data;
  }

  async getDeviceLogDetailedByIdClientOrIdGroupAdmin(id: number): Promise<DeviceLogDetailedClientModel> {
    try {
      return (await this.api.get(`obter-log-dispositivo-detalhado-admin/${id}`))?.data;
    } catch (error) {
      throw error;
    }
  }

  async sendEmailDeviceLog(sendEmail: DeviceLogSendEmailModel, file: File): Promise<void> {
    const formData = new FormData();
    formData.append('dto', JSON.stringify(sendEmail));
    formData.append('file', file);

    await this.api.post('enviar-email-suporte', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  // CLIENTE

  async getDeviceLogResume(): Promise<DeviceLogResumeModel[]> {
    return (await this.api.get('obter-log-dispositivo-resumo'))?.data;
  }

  async getDeviceLogDetailedByIdClientOrIdGroup(id: number): Promise<DeviceLogDetailedClientModel> {
    try {
      return (await this.api.get(`obter-log-dispositivo-detalhado/${id}`))?.data;
    } catch (error) {
      throw error;
    }
  }
}
