import { DeviceGroupModel } from '@app/domain/deviceGroup/deviceGroupModel';
import { ApiServiceBase, IApiServiceBase } from '@app/services/base/apiServiceBase';

export default interface IDeviceGroupService extends IApiServiceBase<DeviceGroupModel, DeviceGroupModel> {}

export class DeviceGroupService extends ApiServiceBase<DeviceGroupModel, DeviceGroupModel> {
  constructor() {
    super('DispositivoGrupo');
  }
}
