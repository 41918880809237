import { setUser } from './userSlice';
import { decodeToken } from 'react-jwt';
import { LoginModel } from '@app/domain/auth/auth/loginModel';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteToken, deleteUser, persistToken, readToken } from '@app/services/localStorage.service';
import IAuthService, { AuthService } from '@app/services/authService';
import { UserType } from '@app/constants/enums/userType';
import IUserIdentityService, { UserIdentityService } from '@app/services/identity/userIdentityService';
import { ClaimModel } from '@app/domain/identity/claimModel';

export interface AuthSlice {
  token: string | null;
}

const initialState: AuthSlice = {
  token: readToken(),
};

interface IDecodedUserToken {
  name: string;
  sub: string | null;
  client_admin: string | null;
  master: string | null;
  user_filial: string | null;
  role: string | null;
  idClient: string | null;
  beta: string | null;
}

const authService: IAuthService = new AuthService();
const userService: IUserIdentityService = new UserIdentityService();

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginModel, { dispatch }) =>
  authService.login(loginPayload).then(async (res) => {
    const data = decodeToken(res.access_token) as IDecodedUserToken;

    const user = await userService.get(`${data.sub}`);

    if (!user.lockoutEnabled) throw Error('Usuário inativo');

    dispatch(
      setUser({
        userName: data.name,
        type: data.role as UserType,
        id: data.sub,
        idCliente: data.idClient,
        emailConfirmed: user.emailConfirmed,
        claims: data.beta && data.beta === '1' ? [{ claimType: 'beta', claimValue: '1' } as ClaimModel] : [],
      }),
    );
    persistToken(res.access_token);
    return res.access_token;
  }),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  deleteToken();
  deleteUser();
  dispatch(setUser(null));
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
