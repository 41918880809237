import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  /* align-items: center;
  justify-content: center; */
  height: 100% !important;
  width: 100%;
`;

export const NoDevicesContainer = styled.div`
  padding-top: 7rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  /* align-items: center;
  justify-content: center; */
  height: 100% !important;
  width: 100%;

  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #8c8c8c;
  text-align: center;
`;

export const NoDevicesTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 100% !important;
  width: 100%;

  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #707070;
  text-align: center;
`;
