import { MenuItem as MenuItemBase } from '@app/components/common/Menu/Menu';
import { media } from '@app/styles/themes/constants';
import { Divider, Typography } from 'antd';
import styled from 'styled-components';

export const Text = styled(Typography.Text)`
  font-size: 0.875rem;
  font-weight: 600;

  & > a {
    display: block;
  }

  @media only screen and ${media.md} {
    font-size: 1rem;
  }
`;

export const MenuItem = styled(MenuItemBase)`
  height: 50px;
  font-size: 0.875rem;
  text-align: justify;
`;

export const ItemsDivider = styled(Divider).withConfig({
  shouldForwardProp: (prop) => !['eventKey', 'warnKey'].includes(prop),
})`
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
  width: 50px;
`;
