import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Col, Row, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { DeviceProfileService } from '@app/services/deviceProfileService';
import {
  DeviceProfileModel,
  DeviceProfilePropertyModel,
  PropertyGroupedData,
  PropertyValue,
} from '@app/domain/deviceProfile/deviceProfileModel';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Description } from '@app/components/common/Description/Description';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import {
  CardInfoContainer,
  CardInfoProperty,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { DividerLine } from '@app/components/common/divider/DividerLine.styles';
import ModuleViewComponent from './components/ModuleViewComponent';
import { setFooter } from '@app/store/slices/footerSlice';
import { CanVehicleParametersCalculatedModel, CanVehicleParametersOnOffModel } from '@app/domain/canVehicle/canVehicle';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import IFirmwareGroupService, { FirmwareGroupService } from '@app/services/firmwareGroupService';

const firmwareGroupService: IFirmwareGroupService = new FirmwareGroupService();
const deviceProfileService = new DeviceProfileService();

export const DeviceProfileView: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [deviceProfile, setDeviceProfile] = useState<DeviceProfileModel>({
    ativo: true,
  });
  const [data, setData] = useState<DeviceProfilePropertyModel[]>([]);
  const [properties, setProperties] = useState<PropertyValue[]>([]);
  const [parametersCalculated, setParametersCalculated] = useState<CanVehicleParametersCalculatedModel[]>([]);
  const [parametersOnOff, setParametersOnOff] = useState<CanVehicleParametersOnOffModel[]>([]);

  const fetchDeviceProfile = useCallback((deviceProfileId: number) => {
    setLoading(true);
    return deviceProfileService
      .get(`/${deviceProfileId}`)
      .then((res) => {
        setDeviceProfile(res);
        setProperties(res.propriedades ?? []);
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
        setLoading(false);
      });
  }, []);

  const fetchPropertiesByModelAndFirmwareGroup = useCallback((idModelo: number, idGrupoFirmware: number) => {
    setLoading(true);
    return firmwareGroupService
      .getArray(`/${idGrupoFirmware}/${idModelo}/propriedades`)
      .then((res: DeviceProfilePropertyModel[]) => {
        setData(res);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const fetchSelectedParametersCalculatedByPerfil = useCallback((idPerfil: number) => {
    setLoading(true);
    return deviceProfileService
      .getSelectedCalculatedParameters(0, idPerfil)
      .then((res) => {
        setParametersCalculated(res as CanVehicleParametersCalculatedModel[]);
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const fetchSelectedParametersOnOffByPerfil = useCallback((idPerfil: number) => {
    setLoading(true);
    return deviceProfileService
      .getSelectedOnOffParameters(0, idPerfil)
      .then((res) => {
        setParametersOnOff(res as CanVehicleParametersOnOffModel[]);
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (deviceProfile.idVeiculoCan) {
      setLoading(true);
      fetchSelectedParametersCalculatedByPerfil(deviceProfile.id ?? 0);
      fetchSelectedParametersOnOffByPerfil(deviceProfile.id ?? 0);
    } else {
      setData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceProfile]);

  const handleBackClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate('/perfil-configuracao');
  };

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: 'Visualizar perfil de configuração',
        handleBackClick: handleBackClick,
      }),
    );
    dispatch(
      setFooter({
        confirmButtonText: '',
        cancelButtonText: '',
      }),
    );
    if (Number(id)) fetchDeviceProfile(Number(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deviceProfile.idModelo && deviceProfile.idGrupoFirmware) {
      setLoading(true);
      fetchPropertiesByModelAndFirmwareGroup(deviceProfile.idModelo, deviceProfile.idGrupoFirmware);
    } else {
      setData([]);
    }
  }, [deviceProfile.idModelo, deviceProfile.idGrupoFirmware, fetchPropertiesByModelAndFirmwareGroup, deviceProfile.id]);

  const groupedData = data.reduce((acc: PropertyGroupedData, item: DeviceProfilePropertyModel) => {
    const key = item.modulo || 'unknown';
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {} as PropertyGroupedData);

  return (
    <>
      <Spinner spinning={loading}>
        <PageContainer>
          <CardInfoContainer style={{ marginBottom: '5rem' }}>
            <Description title="Informações do perfil" />
            <Row gutter={24} justify={'space-between'}>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Equipamento</CardInfoProperty>
                <CardInfoValue>{deviceProfile.equipamento}</CardInfoValue>
              </Col>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Modelo</CardInfoProperty>
                <CardInfoValue>{deviceProfile.modelo}</CardInfoValue>
              </Col>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Nome do perfil</CardInfoProperty>
                <CardInfoValue>{deviceProfile.nome}</CardInfoValue>
              </Col>
              <Col xs={12} md={8} lg={6} xxl={4}>
                <CardInfoProperty>Status</CardInfoProperty>
                <CardInfoValue>
                  {deviceProfile.ativo ? (
                    <Tag color="#E9F4EE" style={{ color: '#083F18', width: '64px', textAlign: 'center' }}>
                      Ativo
                    </Tag>
                  ) : (
                    <Tag color="#FEE9EA" style={{ color: '#620E12', width: '64px', textAlign: 'center' }}>
                      Inativo
                    </Tag>
                  )}
                </CardInfoValue>
              </Col>
            </Row>
            <DividerLine />
            <Description title="Abas de configuração" />
            <BaseForm layout="vertical" style={{ width: '100%' }}>
              {Object.keys(groupedData).map((moduleName, index) => (
                <ModuleViewComponent
                  idGroup={deviceProfile.idGrupoCerca}
                  idVehicle={deviceProfile.idVeiculoCan}
                  idManufacturer={deviceProfile.idFabricante}
                  key={moduleName}
                  properties={properties}
                  setProperties={setProperties}
                  parametersCalculated={parametersCalculated}
                  setParametersCalculated={setParametersCalculated}
                  parametersOnOff={parametersOnOff}
                  setParametersOnOff={setParametersOnOff}
                  moduleData={groupedData[moduleName]}
                  moduleName={moduleName}
                  hasBorderBottom={Object.keys(groupedData).length !== index + 1}
                  device={deviceProfile as FactoryDeviceModel}
                />
              ))}
            </BaseForm>
          </CardInfoContainer>
        </PageContainer>
      </Spinner>
    </>
  );
};
