import { Row } from 'antd';
import styled from 'styled-components';

export const UpdatesContainerRow = styled(Row)`
  max-height: 18rem;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 0.5rem;
  border: 0.063rem solid #bababa;
  padding: 0.5rem;
`;
