import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { isValidIP, maskIdProps } from '@app/utils/utils';
import { DeviceProfilePropertyModel, PropertyValue } from '@app/domain/deviceProfile/deviceProfileModel';
import * as S from '../ModulesProfileConfig.styles';
import ShouldRenderInput from '@app/components/common/ModulesProfileConfig/ShouldRenderInput';
import { RadioGroup, Radio } from '@app/components/common/Radio/Radio';
import { Form, Space } from 'antd';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';

interface RedeProps {
  properties: PropertyValue[];
  setProperties: React.Dispatch<React.SetStateAction<PropertyValue[]>>;
  moduleData: DeviceProfilePropertyModel[];
  disableAllFields?: boolean;
  setPropertyValues?: (data: PropertyValue[]) => void;
}

const valoresDefaultPorId: { [key: string]: string } = {
  '7': '',
  '8': '',
  '9': '',
  '13': '',
  '43': '',
  '12': '',
  '11': '1',
  '44': '',
  '45': '',
  '46': '',
  '47': 'TCP',
};

const Rede: React.FC<RedeProps> = ({
  properties,
  setProperties,
  moduleData,
  disableAllFields = false,
  setPropertyValues,
}) => {
  const [idMapping, setIdMapping] = useState<{ [key: number]: number }>({});
  const [primaryServer, setPrimaryServer] = useState<'IP' | 'DNS'>('IP');
  const [existsSecondaryServer, setExistsSecondaryServer] = useState<boolean>(true);
  const [secondaryServer, setSecondaryServer] = useState<'IP' | 'DNS'>('DNS');

  useEffect(() => {
    const newIdMapping: { [key: number]: number } = {};
    moduleData.forEach((module) => {
      if (module.idEmbarcado && module.idPropriedade) {
        newIdMapping[module.idEmbarcado] = module.idPropriedade;
      }
    });
    setIdMapping(newIdMapping);

    setProperties([
      ...properties.map((p) => {
        if (p.idEmbarcado.toString() in valoresDefaultPorId) {
          p.valorPropriedade = valoresDefaultPorId[p.idEmbarcado.toString()];
        }

        return p;
      }),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(idMapping).length > 0 && properties.length === 0) {
      const newProperties = Object.keys(idMapping).map((idEmbarcado) => {
        const idStr = idEmbarcado.toString(); // Converte o ID para string
        const valorPadrao = idStr in valoresDefaultPorId ? valoresDefaultPorId[idStr] : '';

        return {
          idPropriedade: idMapping[Number(idEmbarcado)],
          valorPropriedade: valorPadrao,
          idEmbarcado: Number(idEmbarcado),
        };
      });
      setProperties((prevState) => [...prevState, ...newProperties]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMapping]);

  const handleInputChange = (idEmbarcado: number, value: string) => {
    const idPropriedade = idMapping[idEmbarcado];
    if (idPropriedade !== undefined) {
      setProperties((prevValue) => {
        const newProperties = [...prevValue];
        const index = newProperties.findIndex((property) => property.idPropriedade === idPropriedade);
        // se propriedade existir, alterar
        if (index !== -1) {
          newProperties[index].valorPropriedade = value;
          return newProperties;
        }
        // se não (caso de edição), criar nova propriedade no array
        newProperties.push({
          idPropriedade,
          valorPropriedade: value,
          idEmbarcado: idEmbarcado,
        });
        if (setPropertyValues) {
          setPropertyValues(newProperties);
        }
        return newProperties;
      });
    }
  };

  useEffect(() => {
    if (Object.keys(idMapping).length === 0) return;

    if (propertyValue(43)) {
      handleInputChange(13, '');
      setPrimaryServer('DNS');
    } else if (propertyValue(13)) {
      handleInputChange(43, '');
      setPrimaryServer('IP');
    }

    if (!propertyValue(44) && !propertyValue(45) && !propertyValue(46) && !propertyValue(47)) {
      setExistsSecondaryServer(false);
      return;
    }

    if (propertyValue(45)) {
      handleInputChange(44, '');
      setSecondaryServer('DNS');
    } else if (propertyValue(44)) {
      handleInputChange(45, '');
      setSecondaryServer('IP');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMapping]);

  useEffect(() => {
    if (!existsSecondaryServer) {
      handleInputChange(44, '');
      handleInputChange(45, '');
      handleInputChange(46, '');
      handleInputChange(47, '');
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existsSecondaryServer]);

  const propertyValue = (idEmbarcado: number) => {
    const idPropriedade = idMapping[idEmbarcado];
    return properties.find((property) => property.idPropriedade === idPropriedade)?.valorPropriedade ?? '';
  };

  return (
    <Form layout="vertical">
      <S.Row>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={7}>
          <BaseFormInputItem label="APN" style={{ minWidth: '400px', width: 'fit-content' }}>
            <MaskedInput
              key={7}
              className="ant-input"
              mask={() => Array(24).fill(/[a-zA-Z0-9-_.:/?&=]/)}
              guide={false}
              placeholder="Digite o APN"
              maxLength={50}
              disabled={disableAllFields}
              value={propertyValue(7)}
              onChange={(e) => handleInputChange(7, e.target.value)}
              onBlur={(e) => {
                const value = e.target.value;
                if (value === '') return handleInputChange(7, valoresDefaultPorId['7']);
                handleInputChange(7, value);
              }}
            />
          </BaseFormInputItem>
        </ShouldRenderInput>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={8}>
          <BaseFormInputItem label="Usuário APN" style={{ minWidth: '400px', width: 'fit-content' }}>
            <>
              <Input
                key={8}
                type="text"
                className="ant-input"
                disabled={disableAllFields}
                placeholder="Informe o usuário"
                maxLength={50}
                value={propertyValue(8) === 'null' ? '' : propertyValue(8)}
                onChange={(e) => handleInputChange(8, e.target.value)}
              />
              <S.InputObs>Opcional</S.InputObs>
            </>
          </BaseFormInputItem>
        </ShouldRenderInput>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={9}>
          <BaseFormInputItem label="Senha APN" style={{ minWidth: '400px', width: 'fit-content' }}>
            <>
              <Input
                key={9}
                type="text"
                className="ant-input"
                placeholder="Informe a senha"
                disabled={disableAllFields}
                maxLength={50}
                value={propertyValue(9) === 'null' ? '' : propertyValue(9)}
                onChange={(e) => handleInputChange(9, e.target.value)}
              />
              <S.InputObs>Opcional</S.InputObs>
            </>
          </BaseFormInputItem>
        </ShouldRenderInput>
      </S.Row>
      <S.Row>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={13}>
          <BaseFormInputItem label="Servidor primário" style={{ width: '10rem' }}>
            <RadioGroup
              value={primaryServer}
              onChange={(e) => {
                setPrimaryServer(e.target.value);
                if (e.target.value === 'IP') {
                  handleInputChange(13, valoresDefaultPorId['13']);
                  handleInputChange(43, '');
                  return;
                }
                if (e.target.value === 'DNS') {
                  handleInputChange(13, '');
                  handleInputChange(43, valoresDefaultPorId['43']);
                  return;
                }
              }}
              disabled={disableAllFields}
            >
              <Space direction="vertical">
                <Radio value={'IP'}>IP</Radio>
                <Radio value={'DNS'}>DNS</Radio>
              </Space>
            </RadioGroup>
          </BaseFormInputItem>
        </ShouldRenderInput>
        {
          {
            IP: (
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={13}>
                <BaseFormInputItem label="IP do servidor primário" style={{ minWidth: '350px' }}>
                  <MaskedInput
                    key={13}
                    {...maskIdProps}
                    className="ant-input"
                    placeholder="Informe o IP do servidor primário"
                    value={propertyValue(13)}
                    onChange={(e) => handleInputChange(13, e.target.value)}
                    onBlur={(e) => {
                      let value = e.target.value;
                      value = value.replace(/_/g, '');
                      handleInputChange(13, isValidIP(value) ? value : valoresDefaultPorId[13]);
                    }}
                    disabled={disableAllFields}
                  />
                </BaseFormInputItem>
              </ShouldRenderInput>
            ),
            DNS: (
              <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={43}>
                <BaseFormInputItem label="DNS do servidor primário" style={{ minWidth: '350px' }}>
                  <Input
                    key={43}
                    {...maskIdProps}
                    className="ant-input"
                    placeholder="Informe o DNS do servidor primário"
                    value={propertyValue(43)}
                    onChange={(e) => handleInputChange(43, e.target.value)}
                    onBlur={(e) => {
                      const value = e.target.value.replaceAll('_', '');
                      handleInputChange(43, value);
                    }}
                    disabled={disableAllFields}
                  />
                </BaseFormInputItem>
              </ShouldRenderInput>
            ),
          }[primaryServer]
        }
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={12}>
          <BaseFormInputItem label="Porta do servidor primário" style={{ minWidth: '350px' }}>
            <>
              <Input
                key={12}
                type="text"
                className="ant-input"
                placeholder="Informe a porta do servidor primário"
                value={propertyValue(12)}
                onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                  const char = e.data;
                  if (char && !char.match(/[0-9]/)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || (/^\d+$/.test(value) && Number(value) <= 65535)) {
                    handleInputChange(12, value);
                  }
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (value === '') {
                    handleInputChange(12, valoresDefaultPorId[12]);
                  } else if (/^\d+$/.test(value) && Number(value) <= 65535) {
                    handleInputChange(12, value);
                  } else {
                    handleInputChange(12, valoresDefaultPorId[12]);
                  }
                }}
                disabled={disableAllFields}
              />
              <S.InputObs>0 a 65535</S.InputObs>
            </>
          </BaseFormInputItem>
        </ShouldRenderInput>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={11}>
          <BaseFormInputItem label="Tipo servidor primário">
            <RadioGroup
              key={11}
              defaultValue={
                disableAllFields ? properties[properties.findIndex((p) => p.idEmbarcado === 11)].valorPropriedade : '1'
              }
              onChange={(e) => handleInputChange(11, e.target.value)}
              disabled={disableAllFields}
            >
              <Space direction="vertical">
                <Radio value={'1'}>TCP</Radio>
                <Radio value={'2'}>UDP</Radio>
              </Space>
            </RadioGroup>
          </BaseFormInputItem>
        </ShouldRenderInput>
      </S.Row>
      <S.Row>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={44}>
          <BaseFormInputItem style={{ minWidth: '400px', width: 'fit-content' }}>
            <Checkbox
              value={existsSecondaryServer}
              checked={existsSecondaryServer}
              onChange={(e) => {
                setExistsSecondaryServer(e.target.checked);
                if (e.target.checked) {
                  handleInputChange(44, valoresDefaultPorId['44']);
                  handleInputChange(45, valoresDefaultPorId['45']);
                  handleInputChange(46, valoresDefaultPorId['46']);
                  handleInputChange(47, valoresDefaultPorId['47']);
                  setSecondaryServer('DNS');
                } else {
                  handleInputChange(44, '');
                  handleInputChange(45, '');
                  handleInputChange(46, '');
                  handleInputChange(47, '');
                }
              }}
              disabled={disableAllFields}
            >
              <S.CheckboxLabel>Servidor secundário</S.CheckboxLabel>
            </Checkbox>
          </BaseFormInputItem>
        </ShouldRenderInput>
      </S.Row>
      {existsSecondaryServer && (
        <S.Row>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={44}>
            <BaseFormInputItem label="Servidor secundário" style={{ width: '10rem' }}>
              <RadioGroup
                value={secondaryServer}
                onChange={(e) => {
                  setSecondaryServer(e.target.value);
                  if (e.target.value === 'IP') {
                    handleInputChange(44, valoresDefaultPorId['44']);
                    handleInputChange(45, '');
                    return;
                  }
                  if (e.target.value === 'DNS') {
                    handleInputChange(44, '');
                    handleInputChange(45, valoresDefaultPorId['45']);
                    return;
                  }
                }}
                disabled={disableAllFields}
              >
                <Space direction="vertical">
                  <Radio value={'IP'}>IP</Radio>
                  <Radio value={'DNS'}>DNS</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </ShouldRenderInput>
          {
            {
              IP: (
                <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={44}>
                  <BaseFormInputItem label="IP do servidor secundário" style={{ minWidth: '350px' }}>
                    <MaskedInput
                      key={44}
                      {...maskIdProps}
                      className="ant-input"
                      placeholder="Informe o IP do servidor secundário"
                      value={propertyValue(44)}
                      onChange={(e) => handleInputChange(44, e.target.value)}
                      onBlur={(e) => {
                        const value = e.target.value;
                        handleInputChange(44, isValidIP(value) ? value : valoresDefaultPorId[44]);
                      }}
                      disabled={disableAllFields}
                    />
                  </BaseFormInputItem>
                </ShouldRenderInput>
              ),
              DNS: (
                <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={45}>
                  <BaseFormInputItem label="DNS do servidor secundário" style={{ minWidth: '350px' }}>
                    <MaskedInput
                      key={45}
                      {...maskIdProps}
                      className="ant-input"
                      placeholder="Informe o DNS do servidor secundário"
                      value={propertyValue(45)}
                      onChange={(e) => handleInputChange(45, e.target.value)}
                      onBlur={(e) => {
                        const value = e.target.value.replaceAll('_', '');
                        handleInputChange(45, value);
                      }}
                      disabled={disableAllFields}
                    />
                  </BaseFormInputItem>
                </ShouldRenderInput>
              ),
            }[secondaryServer]
          }
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={46}>
            <BaseFormInputItem label="Porta do servidor secundário" style={{ minWidth: '350px' }}>
              <>
                <Input
                  key={46}
                  type="text"
                  className="ant-input"
                  placeholder="Informe a porta do servidor secundário"
                  value={propertyValue(46)}
                  onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                    const char = e.data;
                    if (char && !char.match(/[0-9]/)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || (/^\d+$/.test(value) && Number(value) <= 65535)) {
                      handleInputChange(46, value);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (value === '') {
                      handleInputChange(46, valoresDefaultPorId[46]);
                    } else if (/^\d+$/.test(value) && Number(value) <= 65535) {
                      handleInputChange(46, value);
                    } else {
                      handleInputChange(46, valoresDefaultPorId[46]);
                    }
                  }}
                  disabled={disableAllFields}
                />
                <S.InputObs>0 a 65535</S.InputObs>
              </>
            </BaseFormInputItem>
          </ShouldRenderInput>
          <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={47}>
            <BaseFormInputItem label="Tipo servidor secundário">
              <RadioGroup
                key={47}
                defaultValue={
                  disableAllFields
                    ? properties[properties.findIndex((p) => p.idEmbarcado === 11)].valorPropriedade
                    : '1'
                }
                onChange={(e) => handleInputChange(47, e.target.value)}
                disabled={disableAllFields}
              >
                <Space direction="vertical">
                  <Radio value={'1'}>TCP</Radio>
                  <Radio value={'2'}>UDP</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </ShouldRenderInput>
        </S.Row>
      )}
    </Form>
  );
};

export default Rede;
