import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 1rem;
  //gap: 1.5rem;
  width: 100%;
`;

export const EventsItem = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 31rem;
  min-height: 3.5rem;
  box-sizing: border-box;
  //padding: 10px;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
