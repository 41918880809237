export const encode = (selectedCheckboxes: number[]): string => {
  const encodedArray = '0'.repeat(64).split('');

  for (const value of selectedCheckboxes) {
    encodedArray[64 - value] = '1';
  }

  return parseInt(encodedArray.join(''), 2).toString(16).toUpperCase().padStart(16, '0');
};

export const decode = (encoded: string): number[] => {
  const binary = parseInt(encoded, 16).toString(2).padStart(64, '0');
  const binaryArray = binary.split('');
  const selectedParams: number[] = [];

  for (let i = 0; i < binaryArray.length; i++) {
    if (binaryArray[i] === '1') {
      selectedParams.push(64 - i);
    }
  }

  return selectedParams.reverse();
};
