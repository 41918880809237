import { InfoCircleOutlined } from '@ant-design/icons';
import { Description } from '@app/components/common/Description/Description';
import { Modal } from '@app/components/common/Modal/Modal';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setFooter } from '@app/store/slices/footerSlice';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Col, Radio, RadioChangeEvent, Row, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CardDetails from '@app/components/common/Card/CardDetails/CardDetails';
import { RadioGroup } from '@app/components/common/Radio/Radio';
import * as S from './index.styles';
import { IconDeleteOutlined } from '@app/assets/slump-icons';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';
import { TagsGroupService } from '@app/services/tagsGroupService';
import { TagGroupModel } from '@app/domain/tagGroup/TagGroupModel';
import { FactoryDeviceModel } from '@app/domain/device/factoryDeviceModel';
import { USBConnector } from '@app/pages/factory-device/components/USBConnector/USBConnector';

const tagGroupService = new TagsGroupService();

interface TagCreateProps {
  isCloning?: boolean;
  isEdit?: boolean;
}

export const TagGroupCreate: React.FC<TagCreateProps> = ({ isCloning, isEdit }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [tagGroup, setTagGroup] = useState<TagGroupModel>({} as TagGroupModel);
  const [tag, setTag] = useState<string>('');
  const [type, setType] = useState<0 | 1>(0);
  const [connectedDeviceInfo, setConnectedDeviceInfo] = useState<FactoryDeviceModel>({} as FactoryDeviceModel);

  const { id } = useParams();

  const handleTypeChange = (e: RadioChangeEvent) => {
    setType(e.target.value);
    setTag('');
  };

  const fetchCloneById = useCallback(() => {
    setLoading(true);
    tagGroupService
      .get(`${id}`)
      .then((response) => {
        setTagGroup(response);
      })
      .catch((err) => notificationController.error({ message: `Erro ao buscar o grupo de tags. ${err}` }))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isCloning]);

  const handleAddTag = (tag: string) => {
    const newTag: string = tag;

    setTagGroup((prevState) => {
      if (prevState?.tags?.find((t) => t === newTag)) {
        notificationController.error({ message: 'Já existe uma tag igual cadastrada' });
        return prevState;
      }

      notificationController.success({ message: 'Tag adicionada na listagem com sucesso!' });
      setTag('');

      return {
        ...prevState,
        tags: [...(prevState.tags || []), newTag],
      };
    });
  };

  const saveTagGroup = useCallback(() => {
    setLoading(true);
    if (!isEdit) {
      tagGroupService
        .post('', {
          nome: isCloning ? `${tagGroup.nome} clone` : tagGroup.nome,
          tags: tagGroup.tags,
        })
        .then(() => {
          notificationController.success({ message: 'Grupo cadastrado com sucesso!' });
          navigate('/grupo-tags');
        })
        .catch((err) => {
          notificationController.error({ message: `Erro ao cadastrar grupo.`, description: err.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      tagGroupService
        .update(``, {
          id: Number(id),
          nome: isEdit ? `${tagGroup.nome}` : tagGroup.nome,
          tags: tagGroup.tags,
        })
        .then(() => {
          notificationController.success({ message: 'Grupo editado com sucesso!' });
          navigate('/grupo-tags');
        })
        .catch((err) => {
          notificationController.error({ message: `Erro ao editar grupo.`, description: err.message });
        })
        .finally(() => {
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagGroup, navigate, tagGroupService, isEdit]);

  const handleBackClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate('/grupo-tags');
  };

  const handleTagsOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && e.currentTarget.value.trim() !== '') {
      e.preventDefault();
      handleAddTag(e.currentTarget.value);
    }
  };

  const handleValidateInputTag = (value: string, setTag: React.Dispatch<React.SetStateAction<string>>) => {
    const validatedTag = value.replace(/\s/g, '').replace(/[^a-zA-Z0-9]/g, '');
    setTag(validatedTag);
  };

  const handleDeleteTag = (tagToDelete: string) => {
    setTagGroup((prevState) => ({
      ...prevState,
      tags: prevState.tags?.filter((tag) => tag !== tagToDelete) || [],
    }));
    notificationController.success({ message: 'Tag deletada da listagem com sucesso!' });
  };

  useEffect(() => {
    dispatch(
      setHeaderRegister({
        title: isEdit ? 'Editar grupo' : !isCloning ? 'Novo grupo' : 'Clonar Grupo',
        handleBackClick: handleBackClick,
      }),
    );
    if (Number(id)) fetchCloneById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, dispatch, id, isCloning]);

  useEffect(() => {
    const isConfirmButtonDisabled = !tagGroup.nome || (tagGroup.tags && tagGroup.tags.length === 0);

    dispatch(
      setFooter({
        confirmButtonText: isEdit ? 'Salvar' : !isCloning ? 'Cadastrar grupo' : 'Salvar',
        confirmButtonDisabled: isConfirmButtonDisabled,
        handleConfirmButtonClick: saveTagGroup,
        cancelButtonText: 'Cancelar',
        handleCancelButtonClick: () => setModalCancelVisible(true),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagGroup, dispatch]);

  return (
    <>
      <Modal
        title={`Cancelar cadastro do grupo`}
        open={modalCancelVisible}
        onOk={() => navigate('/grupo-tags')}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            Deseja realmente <strong>&nbsp;cancelar o cadastro do grupo?</strong>
          </Col>
        </Row>
      </Modal>

      <Spinner spinning={loading}>
        <PageContainer>
          {isCloning ? (
            <HelperNotification style={{ width: '50%' }}>
              Após alterar o nome do grupo adicione ou remova pelo menos 1 tag e <strong>clique em salvar.</strong>
            </HelperNotification>
          ) : null}
          <Description
            title="Informações do grupo"
            subtitle="Preencha o campo para cadastrar o grupo e vincular as tags nele."
          />
          <BaseForm layout="vertical" style={{ width: '100%' }}>
            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={6}>
                <BaseFormInputItem label="Grupo" style={{ width: '100%' }}>
                  <Input
                    placeholder="Digite o nome do grupo"
                    value={tagGroup.nome}
                    onChange={(event) => setTagGroup((prevState) => ({ ...prevState, nome: event.target.value }))}
                    disabled={!!isEdit}
                  />
                </BaseFormInputItem>
              </Col>
            </Row>
            <CardDetails title="Cadastro da tag">
              <BaseForm layout="vertical" style={{ width: '100%' }}>
                <Row gutter={10}>
                  <Col span={8} style={{ marginRight: '1rem' }}>
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <div>
                          <RadioGroup value={type} defaultValue={null} onChange={handleTypeChange}>
                            <Space size={0} direction="vertical">
                              <Radio value={0}>Aproximação</Radio>
                            </Space>
                          </RadioGroup>
                        </div>
                        <S.Div>
                          {type == 0 && (
                            <USBConnector
                              newFactoryDevice={connectedDeviceInfo}
                              setNewFactoryDevice={setConnectedDeviceInfo}
                              handleAddTag={handleAddTag}
                              type={type}
                            />
                          )}
                        </S.Div>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={10} style={{ borderLeft: '1px solid #D9D9D9', paddingLeft: '1.5rem' }}>
                    <Row gutter={[10, 10]}>
                      <Col span={24}>
                        <RadioGroup value={type} defaultValue={0} onChange={handleTypeChange}>
                          <Radio value={1}>Manual</Radio>
                        </RadioGroup>
                      </Col>
                      <Col span={24}>
                        <Row gutter={8}>
                          <Col span={18}>
                            <BaseFormInputItem
                              label=""
                              supportText="Pressione a tecla “enter” ou clique no botão adicionar."
                              style={{ width: '100%' }}
                            >
                              <Input
                                placeholder="Digite o ID da tag"
                                maxLength={17}
                                value={tag}
                                onChange={(e) => handleValidateInputTag(e.target.value, setTag)}
                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleTagsOnKeyDown(e)}
                                disabled={type !== 1}
                              />
                            </BaseFormInputItem>
                          </Col>
                          <Col span={6}>
                            <Button type="primary" onClick={() => handleAddTag(tag)} disabled={type !== 1}>
                              Adicionar
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </BaseForm>
            </CardDetails>
          </BaseForm>
          <CardDetails title="Tags vinculadas">
            {tagGroup.tags && tagGroup.tags.length > 0 ? (
              <Row gutter={[8, 8]} style={{ maxHeight: '30rem', width: '100%' }}>
                {tagGroup.tags.map((tag, index) => (
                  <Col key={index} xs={4} xl={4}>
                    <S.Row>
                      <S.TagValue>{tag}</S.TagValue>
                      <Button type="link" style={{ marginLeft: '-0.875rem' }} onClick={() => handleDeleteTag(tag)}>
                        <IconDeleteOutlined />
                      </Button>
                    </S.Row>
                  </Col>
                ))}
              </Row>
            ) : (
              <>
                <S.NoContainer>
                  <S.NoTagsTitle>Nenhum cadastro realizado</S.NoTagsTitle>
                  <span>Cadastre a(s) tag(s) para exibir os resultados</span>
                </S.NoContainer>
              </>
            )}
          </CardDetails>
        </PageContainer>
      </Spinner>
    </>
  );
};
