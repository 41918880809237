import styled, { css } from 'styled-components';
import { Input as AntInput } from 'antd';
import { FONT_WEIGHT, FONT_SIZE } from '@app/styles/themes/constants';

interface InputProps {
  isSuccess?: boolean;
  bordered?: boolean;
}

export const Input = styled(AntInput)<InputProps>`
  display: flex;

  &.ant-input-affix-wrapper-disabled {
    background-color: var(--disabled-input-color) !important;
  }

  .ant-input-group-addon:first-child,
  .ant-input-group-addon:last-child {
    min-width: 3.5rem;
    color: var(--secondary-color);
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  .ant-input-group-addon .ant-select {
    .ant-select-selection-item {
      min-width: 5.5rem;
      color: var(--secondary-color);
      font-weight: ${FONT_WEIGHT.semibold};
      font-size: ${FONT_SIZE.lg};
    }
  }

  .ant-select-arrow {
    color: var(--input-placeholder-color);
  }

  &.ant-input-borderless {
    border: none !important;
  }

  border-radius: 6px;
  font-weight: 700 !important;

  ::placeholder {
    font-weight: 400 !important;
  }

  ${({ isSuccess }) => {
    switch (isSuccess) {
      case true:
        return css`
          border: 2px solid var(--success-color);
          color: var(--input-placeholder-color);
        `;
      case false:
        return css`
          border: 2px solid var(--error-color);
          color: var(--input-placeholder-color);
        `;
      default:
        return css`
          border: 1px solid var(--border-base);
        `;
    }
  }}
`;
