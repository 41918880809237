import { ClientModel } from '@app/domain/client/clientModel';
import { ApiServiceBase, IApiServiceBase } from './base/apiServiceBase';

export default interface IClientService extends IApiServiceBase<ClientModel, ClientModel> {}

export class ClientService extends ApiServiceBase<ClientModel, ClientModel> {
  constructor() {
    super('Clientes');
  }

  // async getArray(endpoint: string): Promise<ClientModel[]> {
  //   try {
  //     const response = await this.api.get(endpoint);
  //     return response?.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
}
